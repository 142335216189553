import { Drawer, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 1,

  '& .MuiDrawer-paper': {
    width: '40%',
  },

  '& .form-drawer-button-wrapper': {
    '& .MuiButton-root': {
      margin: '10px',
      backgroundColor: '#f5f5f5',
      padding: '2px 2px',
      minWidth: '50px',
      borderRadius: 100,
      '&:hover': {
        backgroundColor: '#dfdfdf',
      },
    },
  },
  '& .form-drawer-wrapper': {
    padding: '0px 20px',
  },
  '& .form-drawer-title-wrapper': {
    paddingBottom: '40px',
    '& .MuiTypography-root': {
      fontSize: '50px',
    },
  },
  '& .form-drawer-footer-wrapper': {
    marginTop: `auto`,
    paddingTop: '20px',
    paddingBottom: '20px',
    boxShadow: '0px -8px 22px 1px rgb(0 0 0 / 30%)',
  },
}));

export const StyledDrawerActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
  paddingBottom: '25px',
  paddingRight: '25px',
  gap: '10px',
}));
