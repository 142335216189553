import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import {
  IScheduleOfWorksCosts,
  IScheduleOfWorksFinalPaymentItem,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { CostProfileSectionBox } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileSectionBox';
import { hasDecimal } from 'util/AppUtils';

export const CostProfileFinalPaymentSection = ({
  finalPayment,
  control,
  readOnly = false,
}: {
  finalPayment: IScheduleOfWorksFinalPaymentItem | null | undefined;
  control: Control<IScheduleOfWorksCosts, any>;
  readOnly?: boolean;
}) => {
  return (
    <>
      <CostProfileSectionBox>
        <Typography variant="h3" fontWeight={800} fontSize="1.3em">
          Final payment
        </Typography>
      </CostProfileSectionBox>
      <CostProfileSectionBox>
        <ScheduleOfWorksFinalPaymentItemRow
          scheduleOfWorksFinalPaymentItem={finalPayment}
          readOnly={readOnly}
        />
      </CostProfileSectionBox>
    </>
  );
};

const ScheduleOfWorksFinalPaymentItemRow = ({
  scheduleOfWorksFinalPaymentItem,
  readOnly = false,
}: {
  scheduleOfWorksFinalPaymentItem:
    | IScheduleOfWorksFinalPaymentItem
    | null
    | undefined;
  readOnly?: boolean;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={2}
      wrap="nowrap"
      sx={{
        mb: 1,
      }}
    >
      <Grid item xs={8}>
        <Typography variant="subtitle1" fontSize="1em">
          {scheduleOfWorksFinalPaymentItem?.itemName}
        </Typography>
      </Grid>
      <Grid item xs={4} textAlign={'right'}>
        <Controller
          name={'finalPayment.confirmedCost'}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="number"
              error={!!fieldState.error}
              required
              helperText={fieldState.error?.message}
              style={{ width: '12rem' }}
              disabled
              onChange={e => {
                if (hasDecimal(Number(e.target.value))) return;
                return field.onChange(e);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
                inputProps: {
                  min: 0,
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};