import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z, ZodType } from 'zod';
import {
  useAddApplicationInterventionMutation,
  useGetInterventionCategoriesQuery,
} from 'api/application';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawerActions } from 'styles/globalStyles/drawer';
import {
  IApplicationIntervention,
  IApplicationInterventionCategories,
} from 'types/applications/ApplicationTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationIntervention) =>
  nameof<IApplicationIntervention>(fieldName);

interface IApplicationInterventionCategoryModalProps {
  onClose: () => void;
}

export function ApplicationInterventionCategoryModal({
  onClose,
}: IApplicationInterventionCategoryModalProps) {
  return (
    <Dialog open fullWidth>
      <DialogTitle>Intervention Category</DialogTitle>
      <DialogContent>
        <ApplicationInterventionCategoryModalForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
}

interface IApplicationInterventionCategoryModalFormProps {
  onClose: () => void;
}

export const ApplicationInterventionCategoryModalForm = ({
  onClose,
}: IApplicationInterventionCategoryModalFormProps) => {
  const { applicationId } = useApplicationContext();

  const {
    data: interventionCategories,
    isLoading: interventionCategoriesLoading,
  } = useGetInterventionCategoriesQuery();

  const [addApplicationIntervention, addApplicationInterventionResult] =
    useAddApplicationInterventionMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationIntervention>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zInterventionCategorySchema),
  });
  const { handleSubmit, control, reset } = form;

  const onSubmit = async (formData: IApplicationIntervention) => {
    try {
      await addApplicationIntervention({
        applicationId,
        interventionCategoryId: (
          formData.interventionCategory as IApplicationInterventionCategories
        ).id,
        comment: formData.comment,
      })
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Intervention category updated successfully`);
          onClose();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit intervention category`);
    }
  };

  return interventionCategoriesLoading ? (
    <Loading isOpen />
  ) : (
    <form
      noValidate
      onSubmit={event => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      style={{ width: '100%' }}
    >
      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="interventionCategory"
              rules={{ required: 'Required' }}
              defaultValue={undefined}
              render={({ field: { onChange, ...fieldRest }, fieldState }) => {
                return (
                  <Autocomplete
                    options={interventionCategories ?? []}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...fieldRest}
                        label="Intervention category *"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={getName('comment')}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  required
                  label="Comments"
                  helperText={fieldState.error?.message}
                  multiline
                  autoComplete="off"
                  inputProps={{ maxLength: 1000 }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <StyledDrawerActions>
        <Grid container justifyContent="flex-end" gap={1}>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={addApplicationInterventionResult.isLoading}
          >
            Update Answers
          </LoadingButton>
        </Grid>
      </StyledDrawerActions>
    </form>
  );
};

export const zInterventionCategorySchema: ZodType<
  Pick<IApplicationIntervention, 'interventionCategory' | 'comment'>
> = z.object({
  interventionCategory: z.object(
    {
      id: z.number(),
      name: z.string(),
    },
    {
      required_error: 'Intervention category is required',
      invalid_type_error: 'Intervention category is required',
    }
  ),
  comment: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});
