import { applicationApi } from 'api/application/applicationApi';
import {
  GetApplicationHoldLatestResponse,
  IPutApplicationOnHoldParams,
  ITakeApplicationOffHoldParams,
} from 'types/applications/ApplicationHoldTypes';
import { QueryTags } from 'util/ApiUtils';

export const applicationHoldEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    putApplicationOnHold: builder.mutation<void, IPutApplicationOnHoldParams>({
      invalidatesTags: [
        QueryTags.ApplicationOnHoldLatest,
        QueryTags.ApplicationStatus,
      ],
      query: ({ applicationId, ...body }) => {
        return {
          url: `${applicationId}/hold/on`,
          method: 'PUT',
          body,
        };
      },
    }),
    takeApplicationOffHold: builder.mutation<
      void,
      ITakeApplicationOffHoldParams
    >({
      invalidatesTags: [
        QueryTags.ApplicationOnHoldLatest,
        QueryTags.ApplicationStatus,
      ],
      query: ({ applicationId, ...body }) => {
        return {
          url: `${applicationId}/hold/off`,
          method: 'PUT',
          body,
        };
      },
    }),
    getApplicationHoldLatest: builder.query<
      GetApplicationHoldLatestResponse,
      string
    >({
      providesTags: [QueryTags.ApplicationOnHoldLatest],
      query: applicationId => `${applicationId}/hold/latest`,
    }),
  }),
});

export const {
  usePutApplicationOnHoldMutation,
  useTakeApplicationOffHoldMutation,
  useGetApplicationHoldLatestQuery,
} = applicationHoldEndpoints;
