import { ComponentProps, ReactNode } from 'react';
import { Box, Chip, Grid, Stack, SxProps, Typography } from '@mui/material';
import { ICostsSchedule } from 'types/applications/ApplicationCostScheduleTypes';
import { getGbpAmount, isNumeric } from 'util/AppUtils';

export const costScheduleEditFormId = 'costSchdeduleEditForm';

export const getAmountString = (amountNum: number | undefined) => {
  return amountNum?.toString() ?? '';
};

export const getDiff = (
  originalValue: number | string | null,
  newValue: number | string | null
) => {
  const originalValueNum =
    typeof originalValue === 'number'
      ? originalValue
      : originalValue !== null && isNumeric(originalValue)
      ? parseFloat(originalValue)
      : 0;

  const newValueNum =
    typeof newValue === 'number'
      ? newValue
      : newValue !== null && isNumeric(newValue)
      ? parseFloat(newValue)
      : 0;

  const diff = newValueNum - originalValueNum;
  return diff;
};

export const getValueFromCostsSchedule = (
  cs: ICostsSchedule | undefined,
  fieldName: keyof ICostsSchedule
) => {
  if (cs) {
    return cs[fieldName];
  }

  return null;
};

export const getAmountFromCostsSchedule = (
  cs: ICostsSchedule | undefined,
  amountFieldName: keyof ICostsSchedule
) => {
  if (cs) {
    const val = cs[amountFieldName];
    if (!!val && typeof val === 'number') {
      return getGbpAmount({ value: val });
    }
  }

  return '-';
};

export const getDiffBetweenPreviousAndCurrent = (
  prev: ICostsSchedule | undefined,
  curr: ICostsSchedule | undefined,
  amountFieldName: keyof ICostsSchedule
): number | null => {
  if (!prev || !curr) {
    return null;
  }

  const amountPrev = prev[amountFieldName] ?? 0;
  const amountCurr = curr[amountFieldName] ?? 0;
  if (typeof amountPrev === 'number' && typeof amountCurr === 'number') {
    const diff = amountPrev - amountCurr;
    if (diff === 0) {
      return null;
    }

    return diff * -1;
  }

  return null;
};

export const VersionAndLabel = ({
  version,
  label,
}: {
  version: number;
  label: string;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent={'flex-end'}
      alignItems={'center'}
      columnGap={1}
    >
      <Chip
        label={`v${version}`}
        size="small"
        sx={{ fontSize: '0.9em', color: 'grey.600' }}
      />

      <HeaderLabel label={label} />
    </Stack>
  );
};

export const HeaderLabel = ({ label }: { label: string }) => {
  return (
    <Typography color={'grey.600'} fontSize="0.9em">
      {label}
    </Typography>
  );
};

export const GridItemRight = (props: ComponentProps<typeof Grid>) => {
  return <Grid item textAlign={'right'} {...props} />;
};

export const BoxRight = (props: ComponentProps<typeof Box>) => {
  return <Box textAlign={'right'} {...props} />;
};

export const FooterTotal = ({
  value,
  label,
  color = 'black',
  valueSx,
}: {
  value: number;
  label: ReactNode;
  color?: 'red' | 'grey' | 'black';
  valueSx?: SxProps;
}) => {
  return (
    <Box>
      {typeof label === 'string' ? (
        <Typography variant="body1" fontSize={'0.9em'} color={'grey.600'}>
          {label}
        </Typography>
      ) : (
        label
      )}
      <Typography
        variant="body1"
        fontSize={'1.2em'}
        fontWeight={600}
        color={
          color === 'black'
            ? 'black'
            : color === 'red'
            ? 'red'
            : color === 'grey'
            ? 'grey.500'
            : 'unset'
        }
        textAlign={'right'}
        sx={valueSx}
      >
        {getGbpAmount({ value })}
      </Typography>
    </Box>
  );
};
