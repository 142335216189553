import { ComponentProps } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { GovUkTag } from 'common/components/govUkTag';
import { EApplicationDetailsMenuType } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsTypes';
import { ApplicationDetailsGridRow } from 'pages/applicationPage/content/applicationDetails/components';
import { useApplicationDetailsContext } from 'pages/applicationPage/content/applicationDetails/context';
import { isItemClickable } from 'pages/applicationPage/content/applicationDetails/utils/applicationDetailsUtils';
import {
  EApplicationDetailsItemStatus,
  EApplicationDetailsItemStatusLanguage,
  IApplicationDetailsItem,
} from 'types/applications/ApplicationDetailTypes';

export const getTagVariant = (
  status: EApplicationDetailsItemStatus
): ComponentProps<typeof GovUkTag>['variant'] => {
  switch (status) {
    case EApplicationDetailsItemStatus.NotStarted:
    case EApplicationDetailsItemStatus.CannotStartYet:
      return 'grey';

    case EApplicationDetailsItemStatus.InProgress:
      return 'default';

    case EApplicationDetailsItemStatus.Completed:
      return 'green';

    default:
      return 'red';
  }
};

export const ApplicationDetailsItem = ({
  item,
}: {
  item: IApplicationDetailsItem;
}) => {
  const {
    handleCloseContextMenu,
    handleShowContextMenu,
    contextMenuAnchorEl,
    handleContextMenuButtonClick,
    showContextMenuForItemId,
  } = useApplicationDetailsContext();
  return (
    <>
      <ApplicationDetailsGridRow
        col1={<Typography>{item.sectionName}</Typography>}
        col2={null}
        col3={
          <GovUkTag
            text={EApplicationDetailsItemStatusLanguage[item.taskStatus]}
            variant={getTagVariant(item.taskStatus)}
          />
        }
        onButtonClick={
          isItemClickable(item)
            ? e => {
                handleShowContextMenu(e.currentTarget, item.id);
              }
            : undefined
        }
      />
      {Boolean(contextMenuAnchorEl) && showContextMenuForItemId === item.id ? (
        <Menu
          anchorEl={contextMenuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(contextMenuAnchorEl)}
          onClose={() => handleCloseContextMenu()}
        >
          <MenuItem
            onClick={() =>
              handleContextMenuButtonClick(
                EApplicationDetailsMenuType.ViewItem,
                item.id
              )
            }
            aria-label="View"
          >
            View
          </MenuItem>
        </Menu>
      ) : null}
    </>
  );
};
