import { ReactNode } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { getGbpAmount } from 'util/AppUtils';

export const CostProfileFooterSection = ({
  totalGrantFunding = 0,
  totalSubmittedCosts = 0,
  totalConfirmedCosts = 0,
  totalPaidCosts = 0,
  readOnly = false,
}: {
  totalGrantFunding: number;
  totalSubmittedCosts: number;
  totalConfirmedCosts: number;
  totalPaidCosts: number;
  readOnly?: boolean;
}) => {
  const {
    reset,
    formState: { defaultValues },
  } = useFormContext();

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.ScheduleOfWorks,
  });

  return (
    <Box
      sx={{
        p: 3,
        mt: 20,
      }}
    >
      <Grid
        container
        wrap="nowrap"
        columnGap={8}
        sx={{
          borderBottom: '1px solid lightgray',
          pb: 2,
        }}
      >
        <Grid item xs={5}>
          <TitleValueBlock>
            <TitleText>Unprofiled costs</TitleText>
            <Typography
              variant="subtitle1"
              fontSize="1em"
              textAlign={'right'}
              color={
                totalGrantFunding - totalConfirmedCosts - totalPaidCosts > 0
                  ? '#ff0000'
                  : '#000000'
              }
            >
              {getGbpAmount({
                value: totalGrantFunding - totalConfirmedCosts - totalPaidCosts,
              })}
            </Typography>
          </TitleValueBlock>
        </Grid>
        <Grid item xs={3.5}>
          <TitleValueBlock>
            <TitleText>Total submitted schedule of works</TitleText>
            <ValueText>
              {getGbpAmount({ value: totalSubmittedCosts })}
            </ValueText>
          </TitleValueBlock>
        </Grid>
        <Grid item xs={3.5}>
          <TitleValueBlock>
            <TitleText>Total confirmed submitted schedule of works</TitleText>
            <Typography
              variant="subtitle1"
              fontSize="1em"
              fontWeight={800}
              textAlign={'right'}
            >
              {getGbpAmount({ value: totalConfirmedCosts })}
            </Typography>
          </TitleValueBlock>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap">
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Box display="flex" justifyContent="end" p={3} bgcolor="white.100">
              <Box gap={2} display="flex">
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset(defaultValues);
                  }}
                  disabled={isOnHoldForThisType}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    (totalGrantFunding - totalPaidCosts - totalConfirmedCosts !==
                      0 || isOnHoldForThisType) || readOnly
                  }
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const TitleValueBlock = ({ children }: { children: ReactNode }) => (
  <Stack justifyContent="space-between" height="100%" width="fit-content">
    {children}
  </Stack>
);

const TitleText = ({ children }: { children: ReactNode }) => (
  <Typography variant="subtitle1" fontSize="1em">
    {children}
  </Typography>
);

const ValueText = ({ children }: { children: ReactNode }) => (
  <Typography variant="subtitle1" fontSize="1em" textAlign={'right'}>
    {children}
  </Typography>
);
