import { ICostsSchedule } from 'types/applications/ApplicationCostScheduleTypes';

export enum ECostScheduleParentType {
  WorksPackage,
  Variation,
}

export type ICostScheduleFormProps<TForm> = {
  parentType: ECostScheduleParentType;
  data: ICostsSchedule;
  originalData?: ICostsSchedule | null;
  handleCancelClick: () => void;
  saveFn: (formData: TForm) => void;
  saveStatus: { isLoading: boolean; isError: boolean; error?: unknown };
};
