import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import {
  useGetVendorAccountSetupSummaryQuery,
  useGetVendorPaymentDetailsQuery,
} from 'api/application/paymentApi';
import { ErrorAlert } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  EVendorAccountSetupStatus,
  EVendorAccountSetupStatusLanguage,
} from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import {
  VendorAccountSentConfirmationSection,
  PaymentOverviewSection,
  PaymentPdfSection,
  VendorAccountApprovalSection,
} from 'pages/applicationPage/content/payments/sections/VendorSetupSection';
import { VendorAccountApprovalHistorySection } from 'pages/applicationPage/content/payments/sections/VendorSetupSection/VendorAccountApprovalHistorySection';
import { extractErrorMessages } from 'util/ApiUtils';

export const VendorSetupSection = () => {
  const { applicationId } = useApplicationContext();

  const { isLoading, isError, error, isSuccess, data } =
    useGetVendorPaymentDetailsQuery(applicationId);

  return (
    <RoundBorderBox>
      <Header />
      <Divider />
      <Box p={3}>
        {isLoading ? <FormSkeleton /> : null}
        {isError ? <ErrorAlert msg={extractErrorMessages(error)} /> : null}
        {isSuccess ? (
          <Grid container columnSpacing={4}>
            <Grid item xs={8} display={'flex'} flexDirection={'column'} gap={3}>
              <PaymentPdfSection />
              <VendorAccountSentConfirmationSection />

              {data?.detailsSentToHomesEngland ? (
                <>
                  <VendorAccountApprovalSection />
                  <VendorAccountApprovalHistorySection />
                </>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <PaymentOverviewSection />
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </RoundBorderBox>
  );
};

const Header = () => {
  const { applicationId } = useApplicationContext();
  const summaryQuery = useGetVendorAccountSetupSummaryQuery(applicationId);
  const statusQuery = useGetVendorPaymentDetailsQuery(applicationId);
  const isSuccess = summaryQuery.isSuccess && statusQuery.isSuccess;

  return (
    <Box bgcolor="grey.100" p={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="1.2em" fontWeight={600}>
          Vendor Set-up Details
        </Typography>
        {isSuccess ? (
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Typography variant="body1" color="grey.700" fontSize="0.9em">
              Status
            </Typography>
            <Chip
              label={
                EVendorAccountSetupStatusLanguage[summaryQuery.data?.status]
              }
              color={
                summaryQuery.data?.status === EVendorAccountSetupStatus.Complete
                  ? 'success'
                  : statusQuery.data?.detailsSentToHomesEngland
                  ? 'primary'
                  : 'warning'
              }
              variant={'filled'}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
