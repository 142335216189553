import {
  IWorksPackageDeedCardDetail,
  IWorksPackageDeedCounterSignedStatus,
  IWorksPackageDeedDetail,
  IWorksPackageDeedSentStatus,
  IWorksPackageDeedSignedStatus,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection/WorksPackageDeedTypes';
import { getGuid } from 'util/AppUtils';

export const worksPackageDeedSentStatus: IWorksPackageDeedSentStatus = {
  sentEvidenceFile: {
    name: 'Sent Works Package Deed File',
    id: getGuid(),
    extension: 'pdf',
  },
  worksPackageDeedSent: true,
  dateSent: new Date(),
  applicationId: '123',
};

export const worksPackageDeedSentSignedStatus: IWorksPackageDeedSignedStatus = {
  signedWorksPackageDeedFile: {
    name: 'Signed Works Package Deed File',
    id: getGuid(),
    extension: 'pdf',
  },
  worksPackageDeedSigned: true,
  dateSigned: new Date(),
  applicationId: '123',
};

export const worksPackageDeedCardDetail: IWorksPackageDeedCardDetail = {
  startDate: new Date(),
  dueDate: new Date(),
  status: 2,
};

export const worksPackageDeedDetail: IWorksPackageDeedDetail = {
  responsibleEntity: {
    name: 'Joey Bloggs',
    email: 'bigjoe@hotmail.com',
  },
  dateLastChecked: '2023-09-10',
  signatoryChanges: true,
  representative: {
    name: 'Keith Clarks',
    email: 'keithclarke@hotmail.com',
  },
  projectDetail: {
    startDate: '2023-09-30',
    endDate: '2023-10-30',
    approvedGrantAmount: 127000,
    duration: '6 months',
  },
};

export const worksPackageDeedSentCounterSignedStatus: IWorksPackageDeedCounterSignedStatus =
  {
    counterSignedWorkPackageDeedFile: {
      name: 'Counter Signed Works Package Deed File',
      id: getGuid(),
      extension: 'pdf',
    },
    workPackageDeedCounterSigned: false,
    dateCounterSigned: null,
    applicationId: '123',
  };
