import { Button, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  useDeleteSignatoryMutation,
  useGetSignatoryQuery,
} from 'api/application/signatoriesApi';
import { Loading } from 'common/components/loading/Loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IDeleteSignatoryDialogProps {
  signatoryId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function DeleteSignatoryDialog({
  signatoryId,
  onConfirm,
  onCancel,
}: IDeleteSignatoryDialogProps) {
  const { applicationId } = useApplicationContext();
  const { data: signatoryData, isLoading } = useGetSignatoryQuery(
    {
      applicationId,
      signatoryId,
    } ?? skipToken
  );
  const [deleteSignatory, deleteSignatoryResult] = useDeleteSignatoryMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onDeleteUser = async () => {
    if (signatoryId) {
      try {
        await deleteSignatory({ applicationId, id: signatoryId });
        createSuccessSnackbar('Successfully deleted signatory');
        onConfirm();
      } catch (err) {
        createErrorSnackbar(`Failed to delete signatory`);
      }
    }
  };

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you want to delete this signatory: ${signatoryData?.firstName} ${signatoryData?.lastName}?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onDeleteUser}
          disabled={deleteSignatoryResult.isLoading}
        >
          Delete signatory
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
