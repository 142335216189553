import { ReactNode } from 'react';
import { Stack } from '@mui/material';

export const ListRow = ({ children }: { children: ReactNode }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={1}>
      {children}
    </Stack>
  );
};
