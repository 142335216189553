import { rest } from 'msw';

///
/// Mock all files that come out of media
///
export const localHostHandlers = [
  rest.all(`/static/media/*`, (req, res, ctx) => {
    return req.passthrough();
  }),
  rest.all(`/main.*`, (req, res, ctx) => {
    return req.passthrough();
  }),
];
