import { Chip } from '@mui/material';
import {
  EProjectPrepItemStatus,
  EProjectPrepItemStatusLanguage,
} from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';

const statusMap = {
  success: [
    EProjectPrepItemStatus.Complete,
    EProjectPrepItemStatus.Submitted,
    EProjectPrepItemStatus.Approved,
  ],
  info: [
    EProjectPrepItemStatus.InProgress,
    EProjectPrepItemStatus.DocumentSent,
  ],
  rejected: [EProjectPrepItemStatus.Rejected],
};

export const ProgressReportChip = ({
  status,
}: {
  status: EProjectPrepItemStatus;
}) => {
  return (
    <Chip
      label={EProjectPrepItemStatusLanguage[status]}
      size="small"
      color={
        statusMap.success.includes(status)
          ? 'success'
          : statusMap.rejected.includes(status)
          ? 'warning'
          : statusMap.info.includes(status)
          ? 'info'
          : 'default'
      }
    />
  );
};
