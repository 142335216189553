import { z } from 'zod';
import { ERegion } from 'enums/ERegion';
import { IAdministrationAssessorPanelListFormDto } from 'types/administration/AdministrationAssessorPanelListTypes';

const RegionsEnum = z.nativeEnum(ERegion);

export const admininstrationAssessorPanelListFormSchema: z.ZodType<
  Omit<IAdministrationAssessorPanelListFormDto, 'id'>
> = z.object({
  name: z
    .string()
    .min(1, { message: 'Company Name is required' })
    .max(150, { message: 'Must be less than 150 characters' }),
  regions: z.array(RegionsEnum).refine(arg => arg.length, {
    message: 'Please select at least one Region',
  }),
  emailAddress: z
    .string()
    .min(1, { message: 'Email is required' })
    .max(150, { message: 'Must be less than 150 characters' }),
  telephone: z
    .string()
    .min(1, { message: 'Telephone is required' })
    .max(150, { message: 'Must be less than 150 characters' }),
});
