import { useCallback } from 'react';
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  useGetApplicationDocumentCategoriesQuery,
  useGetApplicationDocumentTypesQuery,
  useGetApplicationDocumentsQuery,
} from 'api/application/documentsApi';
import { GridSearchField } from 'common/components/gridSearchField';
import { useAppDispatch } from 'state';
import { selectApplicationDocumentsGridState } from 'state/selectors/applications/applicationsSelectors';
import { documentsGridStateChanged } from 'state/slices/applications/applicationsReducer';
import {
  StyledFilter,
  StyledFiltersGridLeft,
  StyledFiltersGridRight,
} from 'styles/globalStyles/filterStyles';

export const DocumentsFilters = () => {
  const dispatch = useAppDispatch();

  const categoriesQuery = useGetApplicationDocumentCategoriesQuery();

  const typesQuery = useGetApplicationDocumentTypesQuery();

  const applicationDocumentsGridState = useSelector(
    selectApplicationDocumentsGridState
  );
  const { isLoading } = useGetApplicationDocumentsQuery(
    applicationDocumentsGridState
  );

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      dispatch(
        documentsGridStateChanged({
          searchValue,
          skip: 0,
        })
      );
    },
    [dispatch]
  );

  return (
    <Grid container pt={4}>
      <StyledFiltersGridLeft item xs={8}>
        <StyledFilter>
          <GridSearchField
            isLoading={isLoading}
            defaultValue={applicationDocumentsGridState.searchValue}
            handleSearch={handleSearchCallback}
          />
        </StyledFilter>
      </StyledFiltersGridLeft>
      <StyledFiltersGridRight item xs={4}>
        <StyledFilter>
          <FormControlLabel
            control={
              <Switch
                checked={applicationDocumentsGridState.keyDocumentsOnly}
                onChange={(_, val) => {
                  dispatch(
                    documentsGridStateChanged({
                      keyDocumentsOnly: val,
                      skip: 0,
                    })
                  );
                }}
              ></Switch>
            }
            label="'Key Docs' only"
            labelPlacement="start"
          />
        </StyledFilter>
        <StyledFilter>
          <Autocomplete
            multiple
            data-testid="category"
            disabled={isLoading}
            onChange={(_, selectedValues) => {
              dispatch(
                documentsGridStateChanged({
                  categories: selectedValues.map(
                    selectedValue => selectedValue.id
                  ),
                  skip: 0,
                })
              );
            }}
            options={categoriesQuery.data ? categoriesQuery.data : []}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label="Category" />}
            size="small"
          />
        </StyledFilter>
        <StyledFilter>
          <Autocomplete
            multiple
            data-testid="type"
            disabled={isLoading}
            onChange={(_, selectedValues) => {
              dispatch(
                documentsGridStateChanged({
                  types: selectedValues.map(
                    selectedValue => selectedValue.id
                  ),
                  skip: 0,
                })
              );
            }}
            options={typesQuery.data ? typesQuery.data : []}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label="Types" />}
            size="small"
          />
        </StyledFilter>
      </StyledFiltersGridRight>
    </Grid>
  );
};
