import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  DialogActions,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  getDiff,
  costScheduleEditFormId,
  getAmountString,
  getAmountFromCostsSchedule,
  getValueFromCostsSchedule,
} from 'common/components/costSchedule';
import { ECostScheduleParentType } from 'common/components/costSchedule/forms/types';
import { useLocalSnackbar } from 'hooks';
import { useCostsScheduleContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CostsScheduleSection/context';
import {
  CostScheduleAmountProperties,
  CostScheduleAmountFields,
  CostScheduleDescriptionFields,
  CostScheduleDescriptionProperties,
  ICostsSchedule,
} from 'types/applications/ApplicationCostScheduleTypes';
import { getGbpAmount, isNumeric } from 'util/AppUtils';

export const Label = ({ value }: { value: string }) => {
  return (
    <Typography variant="body1" fontWeight={600} mb={2}>
      {value}
    </Typography>
  );
};

export const EligibleCostField = ({
  fieldName,
  originalAmount,
  newAmount,
  isRequired = true,
  fieldLabel = 'Total Eligible Cost',
}: {
  fieldName: keyof CostScheduleAmountFields;
  originalAmount?: number;
  newAmount: string;
  isRequired?: boolean;
  fieldLabel?: string;
}) => {
  const { control } = useFormContext();

  const rules = {
    ...(isRequired ? { required: 'Required' } : null),
    min: { value: 0, message: 'Must be greater than zero' },
    pattern: {
      value: /^[0-9]+$/,
      message: 'Please enter a number',
    },
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Controller
          control={control}
          name={fieldName}
          rules={rules}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={fieldLabel}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <AmendmentSection
          originalValue={getAmountString(originalAmount)}
          newValue={newAmount}
        />
      </Grid>
    </Grid>
  );
};

export const AmendmentSection = ({
  originalValue,
  newValue,
}: {
  originalValue: string | null;
  newValue: string | null;
}) => {
  if (originalValue === newValue) {
    return null;
  }

  if (!isNumeric(newValue)) {
    return null;
  }

  const diff = getDiff(originalValue, newValue);

  if (diff === 0) {
    return null;
  }

  return (
    <Box>
      <Typography fontSize={'0.9em'} color="grey.300">
        Amendment
      </Typography>
      <Typography fontSize={'0.9em'}>
        {getGbpAmount({ value: diff })}
      </Typography>
    </Box>
  );
};

export const OriginalDataAccordion = ({
  label = 'Total Eligible Cost',
  costSchedule,
  amountFieldName,
  descriptionFieldName,
}: {
  costSchedule: ICostsSchedule | null | undefined;
  amountFieldName: keyof CostScheduleAmountProperties;
  descriptionFieldName?: keyof CostScheduleDescriptionProperties;
  label?: string;
}) => {
  if (!costSchedule) {
    return null;
  }

  const amount = getAmountFromCostsSchedule(costSchedule, amountFieldName);
  const description = descriptionFieldName
    ? getValueFromCostsSchedule(costSchedule, descriptionFieldName)
    : null;

  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        '&:before': {
          display: 'none',
        },
        mb: 2,
      }}
    >
      <AccordionSummary
        id={`${amountFieldName}OriginalValueAccordion`}
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: 'row-reverse',
          px: 0,
          mt: -1,
        }}
      >
        <Typography color={'grey.600'} fontSize={'0.9em'}>
          Show original cost and description
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          gap={2}
          sx={{
            borderLeftWidth: '3px',
            borderLeftStyle: 'solid',
            borderColor: 'grey.400',
            ml: 2,
            px: 2,
          }}
        >
          <TextField
            label={label}
            value={amount}
            disabled
            sx={{ color: 'grey.700' }}
          />
          {descriptionFieldName ? (
            <TextField
              label={'Description'}
              value={description}
              disabled
              multiline
              rows={5}
              sx={{ mb: 0 }}
            />
          ) : null}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

type DescriptionFieldProps = {
  fieldName: keyof CostScheduleDescriptionFields;
} & (
  | {
      isValidationBasedOnAmountField: true;
      associatedAmountFieldName: keyof CostScheduleAmountFields;
    }
  | {
      isValidationBasedOnAmountField?: false;
      shouldValidate?: boolean;
    }
);

const _descriptionValidationMessage = 'Description is required';

export const DescriptionField = (props: DescriptionFieldProps) => {
  const { control, getValues } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.fieldName}
      rules={{
        validate: value => {
          if (
            !props.isValidationBasedOnAmountField &&
            props.shouldValidate !== undefined
          ) {
            // Validation is not based on an associated amount field having a value
            // It's based upon whether the `shouldValidate` property is true
            // If `shouldValidate` is true then this field is invalid if it doesn't have a value
            // If `shouldValidate` is false then don't validate this field (i.e. return true)

            if (props.shouldValidate === true) {
              if (!value) {
                return _descriptionValidationMessage;
              }
            }

            if (props.shouldValidate === false) {
              return true; // This means we don't care whether the field has a value or not
            }
          }

          // Check if validation is based on an associated amount field
          if (
            !value &&
            props.isValidationBasedOnAmountField &&
            Boolean(getValues(props.associatedAmountFieldName))
          ) {
            return _descriptionValidationMessage;
          }

          if (!value && !props.isValidationBasedOnAmountField) {
            return _descriptionValidationMessage;
          }

          return true;
        },

        maxLength: {
          value: 500,
          message: '500 characters is the maximum allowed.',
        },
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          multiline
          fullWidth
          rows={5}
          label="Description"
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  );
};

export const FormActions = ({
  originalValue,
  newValues,
  isSaving,
  totalCostLabel = 'Total eligible cost',
  handleCancelClick,
}: {
  originalValue: number;
  newValues: (string | null)[];
  isSaving: boolean;
  totalCostLabel?: string;
  handleCancelClick: () => void;
}) => {
  return (
    <>
      <Divider sx={{ mb: 2 }} />
      <DialogActions sx={{ pb: 2 }}>
        <Grid container gap={1} justifyContent={'space-between'} sx={{ mx: 2 }}>
          <Grid item>
            <TotalCostSection
              originalValueTotal={originalValue}
              newValues={newValues}
              totalCostLabel={totalCostLabel}
            />
          </Grid>
          <Grid item gap={1} display={'flex'}>
            <Button variant="outlined" onClick={handleCancelClick}>
              Cancel
            </Button>
            <LoadingButton
              loading={isSaving}
              variant="contained"
              type="submit"
              form={costScheduleEditFormId}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const TotalCostSection = ({
  originalValueTotal,
  newValues,
  totalCostLabel,
}: {
  originalValueTotal: number;
  newValues: (string | null)[];
  totalCostLabel: string;
}) => {
  if (newValues.some(val => !isNumeric(val))) {
    return null;
  }

  const newValuesTotalNum = newValues.reduce<number>((total, current) => {
    var numToAdd = current === null ? 0 : parseFloat(current);
    return (total = total + numToAdd);
  }, 0);

  const diff = getDiff(
    originalValueTotal.toString(),
    newValuesTotalNum.toString()
  );

  const diffDisplay =
    diff === 0 ? null : (
      <Typography fontSize={'0.9em'} color={'grey.700'} component={'span'}>
        ({getGbpAmount({ value: diff })})
      </Typography>
    );

  return (
    <Box>
      <Typography fontSize={'0.9em'} color="grey.700">
        {totalCostLabel}
      </Typography>
      <Typography fontSize={'0.9em'} component={'span'}>
        {getGbpAmount({ value: newValuesTotalNum })}
        {diffDisplay}
      </Typography>
    </Box>
  );
};

export const useCostScheduleEditPermissionCheck = () => {
  const { canEditCostSchedule } = useCostsScheduleContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const costSchedulePermissionCheck = () => {
    if (!canEditCostSchedule) {
      createErrorSnackbar(
        'You do not have permission to edit the Cost Schedule'
      );
      return false;
    }

    return true;
  };

  return { costSchedulePermissionCheck };
};

export const shouldValidateDescription = (
  parentType: ECostScheduleParentType,
  originalAmount: number | undefined,
  newAmount: string
) => {
  // If the parent type is a variation then perform extra logic to decide if the description field should be validated
  // Otherwise, return undefined

  switch (parentType) {
    case ECostScheduleParentType.Variation:
      // If the values do not match then the description field should be validated
      if ((originalAmount?.toString() ?? '') !== newAmount) {
        return true;
      }

      // If the values match, the description field should not be validated
      return false;

    default:
      return undefined;
  }
};
