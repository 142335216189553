import { Box, Button, Divider } from '@mui/material';
import { SectionHeader } from 'common/components/sectionHeader/SectionHeader';
import { usePaymentRequestContext } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/context';
import { PaymentRequestReferralsGrid } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/sections/Referrals/PaymentRequestReferralsGrid';
import { EPaymentRequestSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';

export const PaymentRequestReferralsSection = () => {
  const { setSelectedItemId } = usePaymentRequestContext();
  return (
    <>
      <SectionHeader
        title="Homes England Referrals"
        rightContent={
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedItemId(EPaymentRequestSection.Overview);
            }}
          >
            Close
          </Button>
        }
      />
      <Divider />
      <Box p={2}>
        <PaymentRequestReferralsGrid />
      </Box>
    </>
  );
};
