import { faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { useGetApplicationSummaryQuery } from 'api/application';
import { useGetLiveProjectOverviewQuery } from 'api/application/liveProjectApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { EQuestionType } from 'enums/EQuestionType';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ApplicationDetailsAnswerEditedIndicator } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsAnswerEditedIndicator';
import { ApplicationInfoPanelContextMenu } from 'pages/applicationPage/content/applicationDetails/components/ApplicationInfoPanelContextMenu';
import {
  BoxWithDuration,
  CardTitle,
  GrayIcon,
} from 'pages/applicationPage/content/liveProject/common';
import { ListView } from 'pages/applicationPage/content/liveProject/sections/OverviewSection/ListView';
import { VariationInProgressNotificationBanner } from 'pages/applicationPage/content/liveProject/sections/OverviewSection/VariationInProgressNotificationBanner';
import {
  formatDateShortMonthYear,
  formatDateTimeDayMonthHoursMins,
  getDifferenceInMonths,
} from 'util/DateUtils';

const getProjectTimeFrameProgressData = (
  startDate: string,
  endDate: string
): number => {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  const now = new Date().getTime();

  const totalDuration = end - start;
  const elapsedDuration = now - start;
  return (elapsedDuration / totalDuration) * 100;
};

const getProjectSpendProgressData = (
  currentSpend: number,
  eligibleSpend: number
): number => (currentSpend / eligibleSpend) * 100;

const getProjectDuration = (startDate: string, endDate: string) => {
  const numberOfMonths = getDifferenceInMonths(startDate, endDate);
  if (numberOfMonths === 1) {
    return `${numberOfMonths} month`;
  }
  return `${numberOfMonths} months`;
};

export const OverviewSection = () => {
  const { applicationId } = useApplicationContext();
  const { data: applicationSummary } =
    useGetApplicationSummaryQuery(applicationId);
  const { isLoading, isSuccess, isError, error, data } =
    useGetLiveProjectOverviewQuery(applicationId);

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={3}>
          <RoundBorderBox sx={{ p: 2, mb: 2 }}>
            <CardTitle title="Live Project" fontSize="1.2em" />
          </RoundBorderBox>

          <BoxWithDuration
            title="Project Timeframe"
            isLoading={isLoading}
            isSuccess={isSuccess}
            summaryItemTitle={`Projected duration: ${
              data?.startDate && data?.endDate
                ? getProjectDuration(data.startDate, data.endDate)
                : 'Unknown months'
            }`}
            progressData={{
              leftSide: data?.startDate
                ? formatDateShortMonthYear(parseISO(data.startDate))
                : 'Unknown start date',
              rightSide: data?.endDate
                ? formatDateShortMonthYear(parseISO(data.endDate))
                : 'Unknown end date',
              progressBarData:
                data?.startDate && data?.endDate
                  ? getProjectTimeFrameProgressData(
                      data.startDate,
                      data.endDate
                    )
                  : 0,
            }}
          />

          <BoxWithDuration
            title="Project Spend"
            isLoading={isLoading}
            isSuccess={isSuccess}
            summaryItemTitle={`Total eligible cost (£): ${
              data?.eligibleSpend
                ? `£${data?.eligibleSpend?.toLocaleString()}`
                : '-'
            }`}
            progressData={{
              leftSide: data?.eligibleSpend
                ? `£${data?.eligibleSpend?.toLocaleString()}`
                : '-',
              currentVal: data?.currentSpend
                ? `£${data?.currentSpend?.toLocaleString()}`
                : '£0',
              rightSide: '£0',
              progressBarData:
                data?.eligibleSpend && data?.currentSpend
                  ? getProjectSpendProgressData(
                      data.currentSpend,
                      data.eligibleSpend
                    )
                  : 0,
            }}
          />

          <RoundBorderBox sx={{ p: 2, mt: 2 }}>
            {isLoading && <FormSkeleton />}
            {isSuccess && (
              <Stack>
                <Stack direction="row" justifyContent="space-between">
                  <CardTitle title="Works package" sx={{ mb: 1 }} />
                  {data?.worksPackage && (
                    <Typography>
                      Version {data?.worksPackage.version}
                    </Typography>
                  )}
                </Stack>
                {data?.worksPackage?.lastModifiedBy &&
                  data?.worksPackage?.lastModifiedDate && (
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row" gap={1}>
                        <Typography>Last Modified</Typography>
                        <GrayIcon
                          icon={faClock}
                          style={{
                            alignSelf: 'center',
                          }}
                        />
                        <Typography>
                          {data.worksPackage.lastModifiedDate
                            ? formatDateTimeDayMonthHoursMins(
                                new Date(data?.worksPackage.lastModifiedDate)
                              )
                            : ''}
                        </Typography>
                      </Stack>
                      <Stack direction="row" gap={1}>
                        <GrayIcon
                          icon={faUser}
                          style={{ alignSelf: 'center' }}
                        />
                        <Typography>
                          {data.worksPackage.lastModifiedBy}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
              </Stack>
            )}
          </RoundBorderBox>

          <RoundBorderBox sx={{ p: 2, mt: 2 }}>
            {isLoading && <FormSkeleton />}
            {isSuccess && (
              <Stack direction="column">
                <Stack direction="row" justifyContent="space-between" gap={1}>
                  <CardTitle title="Project Milestones" />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                    }}
                  >
                    <ApplicationDetailsAnswerEditedIndicator
                      questionType={EQuestionType.ProjectMilestones}
                    />
                    <ApplicationInfoPanelContextMenu
                      questionType={EQuestionType.ProjectMilestones}
                    />
                  </Box>
                </Stack>
                {data?.milestones && (
                  <Stack direction="column">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography>Start on site date</Typography>
                      <Typography>
                        {data.milestones.startOnSite
                          ? new Date(
                              data.milestones.startOnSite
                            ).toLocaleDateString('en-GB')
                          : '-'}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography>Practical completion date</Typography>
                      <Typography>
                        {data.milestones.practicalCompletion
                          ? new Date(
                              data.milestones.practicalCompletion
                            ).toLocaleDateString('en-GB')
                          : '-'}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}
          </RoundBorderBox>
        </Grid>
        <Grid item xs={9}>
          {applicationSummary?.isVariationInProgress && (
            <VariationInProgressNotificationBanner
              variationStartDate={new Date(
                applicationSummary?.variationStartDate
              ).toLocaleDateString('en-GB')}
            />
          )}
          <ListView />
        </Grid>
      </Grid>
    </>
  );
};
