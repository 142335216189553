import { ReactNode } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { parseISO } from 'date-fns';
import {
  useGetClosingPaymentRequestChecksDetailsQuery,
  useGetClosingPaymentRequestClosingChecksQuery,
} from 'api/application/paymentRequestApi';
import { FormSkeleton } from 'common/components/skeletons';
import { ERiskStatusLanguage } from 'enums/ERiskStatus';
import {
  ApplicationFileDownloadWrapper,
  ColumnContainer,
} from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { ClosingPaymentRequestChecksSectionInfoPanelReports } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/sections/ClosingPaymentRequestChecksSection/ClosingPaymentRequestChecksSectionInfoPanelReports';
import { IFRAEWChip } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { VarianceCard } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common';
import { getPercentage } from 'util/AppUtils';
import { formatDateTime } from 'util/DateUtils';

export const ClosingPaymentRequestChecksSectionInfoPanel = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const query = useGetClosingPaymentRequestChecksDetailsQuery(
    request ?? skipToken
  );

  const getFilesQuery = useGetClosingPaymentRequestClosingChecksQuery(
    request ?? skipToken
  );

  const getDateString = (date: string) => {
    return formatDateTime(parseISO(date), 'dd/MM/yyyy');
  };

  if (query.isError) {
    return (
      <Typography>Failed getting closing payment checks details.</Typography>
    );
  }

  const { data, isLoading } = query;
  const { data: fileData, isLoading: isFilesQueryLoading } = getFilesQuery;

  if (isLoading) {
    return <FormSkeleton />;
  }

  const paymentAmount = data?.recommendedAmount ?? data?.requestedAmount;

  const displayCompletionDate =
    data?.projectedCompletionDate !== undefined &&
    data.projectedCompletionDate !== '0001-01-01T00:00:00';

  const displayCostReport =
    data?.buildingName !== undefined ||
    displayCompletionDate ||
    data?.fraewRiskAssessment !== undefined ||
    data?.fraewRiskAssessment !== 0;

  return (
    <Stack>
      <Card title="Payment Details">
        <Box>
          {data?.recommendedAmount !== undefined &&
          data.recommendedAmount !== data?.requestedAmount ? (
            <InnerCard title="Recommended Amount">
              <Stack spacing={1}>
                <LabelValueContainer>
                  <Typography>Closing Payment</Typography>
                  <ValueAndSubLabel
                    value={
                      data?.recommendedAmount
                        ? `£${data?.recommendedAmount.toLocaleString()}`
                        : ' - '
                    }
                    subLabel={
                      data?.recommendedAmount !== undefined &&
                      data?.totalGrantFunding !== undefined
                        ? `${getPercentage(
                            data.recommendedAmount,
                            data.totalGrantFunding
                          ).toFixed(1)}% of total grant funding`
                        : ''
                    }
                  />
                </LabelValueContainer>
                {data?.additionalRecommendedAmount !== undefined &&
                  data.additionalRecommendedAmount !== 0 && (
                    <>
                      <LabelValueContainer>
                        <Typography>
                          {data.additionalRecommendedAmountTitle}
                        </Typography>
                        <Typography>
                          £{data.additionalRecommendedAmount.toLocaleString()}
                        </Typography>
                      </LabelValueContainer>
                      <LabelValueContainer>
                        <Typography fontWeight={700}>Total</Typography>
                        <Typography fontWeight={700}>{`£${(
                          data.recommendedAmount +
                          data.additionalRecommendedAmount
                        ).toLocaleString()}`}</Typography>
                      </LabelValueContainer>
                    </>
                  )}
              </Stack>
            </InnerCard>
          ) : null}

          {data?.requestedAmount ? (
            <InnerCard title="Requested Amount">
              <Stack spacing={1}>
                <LabelValueContainer>
                  <Typography>Closing Payment</Typography>
                  <ValueAndSubLabel
                    value={
                      data?.requestedAmount
                        ? `£${data?.requestedAmount.toLocaleString()}`
                        : ' - '
                    }
                    subLabel={
                      data?.requestedAmount !== undefined &&
                      data?.totalGrantFunding !== undefined
                        ? `${getPercentage(
                            data.requestedAmount,
                            data.totalGrantFunding
                          ).toFixed(1)}% of total grant funding`
                        : ''
                    }
                  />
                </LabelValueContainer>
                {data?.additionalRequestedAmount !== undefined &&
                  data.additionalRequestedAmount !== 0 && (
                    <>
                      <LabelValueContainer>
                        <Typography>
                          {data.additionalRequestedAmountTitle}
                        </Typography>
                        <Typography>
                          £{data.additionalRequestedAmount.toLocaleString()}
                        </Typography>
                      </LabelValueContainer>
                      <LabelValueContainer>
                        <Typography fontWeight={700}>Total</Typography>
                        <Typography fontWeight={700}>{`£${(
                          data.requestedAmount + data.additionalRequestedAmount
                        ).toLocaleString()}`}</Typography>
                      </LabelValueContainer>
                    </>
                  )}
              </Stack>
            </InnerCard>
          ) : null}
          {data?.scheduledAmount &&
          data?.scheduledAmount !== data?.requestedAmount ? (
            <InnerCard title="Scheduled Amount">
              <LabelValueContainer>
                <Typography>Closing Payment</Typography>
                <Typography fontWeight="600">
                  £{data?.scheduledAmount.toLocaleString()}
                </Typography>
              </LabelValueContainer>
            </InnerCard>
          ) : null}
          <VarianceCard
            paymentAmount={paymentAmount ?? 0}
            scheduledAmount={data?.scheduledAmount ?? 0}
            title="Variance"
          />
          {displayCostReport && (
            <InnerCard title="Cost report">
              {data?.buildingName && (
                <CostReportLabelValueContainer
                  label="Building Name"
                  value={data.buildingName}
                />
              )}
              {displayCompletionDate && (
                <CostReportLabelValueContainer
                  label="Projected completion date"
                  value={getDateString(data.projectedCompletionDate)}
                />
              )}
              {data?.fraewRiskAssessment ? (
                <>
                  <Typography py={1}>FRAEW risk assessment</Typography>
                  <FRAEWChip riskData={data.fraewRiskAssessment} />
                </>
              ) : null}
              {fileData?.worksContractFiles && (
                <>
                  {fileData.worksContractFiles.map(file => (
                    <ApplicationFileDownloadWrapper
                      key={file.id}
                      fileName={file.name}
                      fileId={file.id}
                      fileWrapperStyle={{
                        backgroundColor: '#FAFAFA',
                        marginTop: '1rem',
                      }}
                    />
                  ))}
                </>
              )}
            </InnerCard>
          )}
          <ClosingPaymentRequestChecksSectionInfoPanelReports
            fileData={fileData}
            isLoading={isFilesQueryLoading}
          />
        </Box>
      </Card>
    </Stack>
  );
};

const Card = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <ColumnContainer sx={{ p: 1, rowGap: 2 }}>
      <Typography variant="h2" mb={2}>
        {title}
      </Typography>
      {children}
    </ColumnContainer>
  );
};

const InnerCard = ({
  title,
  children,
}: {
  title?: string;
  children: ReactNode;
}) => {
  return (
    <Box pt={2} p={2} mb={2} bgcolor={'grey.100'}>
      {title && (
        <Typography variant="h3" fontWeight="600">
          {title}
        </Typography>
      )}
      <>{children}</>
    </Box>
  );
};

const ValueAndSubLabel = ({
  value,
  subLabel,
}: {
  value: string;
  subLabel: string;
}) => {
  return (
    <Stack>
      <Typography fontWeight="600" textAlign="right">
        {value}
      </Typography>
      <Typography fontSize="0.7em">{subLabel}</Typography>
    </Stack>
  );
};

const LabelValueContainer = ({ children }: { children: ReactNode }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
    {children}
  </Box>
);

const CostReportLabelValueContainer = ({
  label,
  value,
}: {
  label: string;
  value?: string;
}) => (
  <Box borderBottom={'1px solid lightgray'}>
    <Typography py={1}>{label}</Typography>
    <Typography fontWeight="600" paddingBottom={1}>
      {value}
    </Typography>
  </Box>
);

const FRAEWChip = ({ riskData }: IFRAEWChip) => {
  return <Chip label={riskData ? ERiskStatusLanguage[riskData] : '-'} />;
};
