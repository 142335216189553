import { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useGetGrantFundingSummaryQuery } from 'api/application';
import { EQuestionType } from 'enums/EQuestionType';
import {
  ColumnContainer,
  DetailItem,
  EmailContact,
} from 'pages/applicationPage/common/components';
import { ApplicationDetailsAnswerEditedIndicator } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsAnswerEditedIndicator';
import { ApplicationInfoPanelContextMenu } from 'pages/applicationPage/content/applicationDetails/components/ApplicationInfoPanelContextMenu';
import { useApplicationGrantFundingContext } from 'pages/applicationPage/content/applicationGrantFunding/context';

export const GrantFundingDetailsChecksInfoPanel = () => {
  const { applicationId, readOnly: gfaComplete } =
    useApplicationGrantFundingContext();

  const query = useGetGrantFundingSummaryQuery(applicationId);

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Application Details
        </Typography>
        {query.data ? (
          <>
            <DetailItem
              label="CompanyName"
              value={query.data.companyName ? query.data.companyName : '-'}
            />

            <DetailItem
              label="Company Registered Address"
              value={
                query.data.companyRegisteredAddress
                  ?.filter(Boolean)
                  .join(', ') ?? -'-'
              }
            />
            <DetailItem
              label="Building Address"
              value={
                query.data.buildingAddress?.filter(Boolean).join(', ') ?? -'-'
              }
            />
            <DetailItem
              label="Company Number"
              value={
                query.data.companyRegistrationNumber
                  ? query.data.companyRegistrationNumber
                  : '-'
              }
            />
            <DetailItem
              label="Land Registry Title Number"
              value={
                query.data.landRegistryTitleNumber
                  ? query.data.landRegistryTitleNumber
                  : '-'
              }
            />
            <DetailItem
              label="Responsible Entity Type"
              value={
                query.data.responsibleEntityType
                  ? query.data.responsibleEntityType
                  : '-'
              }
            />
            <Box>
              <Grid container gap={1} justifyContent={'space-between'}>
                <Grid item>
                  <DetailItem
                    label="Total Cladding Area"
                    value={
                      query.data.totalCladdingArea
                        ? `${query.data.totalCladdingArea}m²`
                        : '-'
                    }
                  />
                </Grid>
                <Grid item gap={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                    }}
                  >
                    <ApplicationDetailsAnswerEditedIndicator
                      questionType={EQuestionType.TotalCladdingArea}
                    />
                    <ApplicationInfoPanelContextMenu
                      questionType={EQuestionType.TotalCladdingArea}
                      currentValue={
                        query.data.totalCladdingArea
                          ? `${query.data.totalCladdingArea}`
                          : '-'
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container gap={1} justifyContent={'space-between'}>
                <Grid item>
                  <DetailItem
                    label="Requested PTFS?"
                    value={
                      query.data.requestedPtfs !== undefined
                        ? query.data.requestedPtfs
                          ? 'Yes'
                          : 'No'
                        : '-'
                    }
                  />
                </Grid>
                <Grid item gap={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                    }}
                  >
                    <ApplicationDetailsAnswerEditedIndicator
                      questionType={EQuestionType.PtfsRequired}
                    />
                    <ApplicationInfoPanelContextMenu
                      questionType={EQuestionType.PtfsRequired}
                      currentValue={
                        query.data.requestedPtfs
                          ? `${query.data.requestedPtfs ? 'Yes' : 'No'}`
                          : '-'
                      }
                      readOnly={gfaComplete}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container gap={1} justifyContent={'space-between'}>
                <Grid item>
                  <DetailItem
                    label="PTS Funding Amount"
                    value={
                      query.data.ptsFundingAmount
                        ? `£${query.data.ptsFundingAmount.toLocaleString()}`
                        : '-'
                    }
                  />
                </Grid>

                <Grid item gap={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                    }}
                  >
                    <ApplicationDetailsAnswerEditedIndicator
                      questionType={EQuestionType.PtfsAmount}
                    />
                    <ApplicationInfoPanelContextMenu
                      questionType={EQuestionType.PtfsAmount}
                      currentValue={
                        query.data.ptsFundingAmount
                          ? `£${query.data.ptsFundingAmount.toLocaleString()}`
                          : '-'
                      }
                      readOnly={gfaComplete}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <DetailItem
              label="Additional PTS Funding Amount"
              value={
                query.data.additionalPtsFundingAmount
                  ? `£${query.data.additionalPtsFundingAmount.toLocaleString()}`
                  : '-'
              }
            />
            <DetailItem
              label="Benchmark Figure"
              value={
                query.data.benchmarkFigure
                  ? `£${query.data.benchmarkFigure.toLocaleString()}`
                  : '-'
              }
            />
            <DetailItem
              label="Is applicant one of the signatories?"
              value={query.data.isApplicantOneOfSignatories ? 'Yes' : 'No'}
            />
          </>
        ) : null}
      </ColumnContainer>
      <ColumnContainer margin="10px">
        <Grid container gap={1} justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="h2" padding="16px">
              Bank Details
            </Typography>
          </Grid>
          <Grid item gap={1}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '2px',
              }}
            >
              <ApplicationDetailsAnswerEditedIndicator
                questionType={EQuestionType.UkBankAccountDetails}
              />
              <ApplicationInfoPanelContextMenu
                questionType={EQuestionType.UkBankAccountDetails}
              />
            </Box>
          </Grid>
        </Grid>
        {query.data ? (
          query.data?.bankDetails && query.data?.bankDetails.length > 0 ? (
            <>
              {query.data?.bankDetails.map((details, index) => {
                return (
                  <Fragment key={index}>
                    <DetailItem
                      label="Sort Code"
                      value={details.sortCode ? details.sortCode : '-'}
                      key={details.sortCode}
                    />
                    <DetailItem
                      label="Account Number"
                      value={
                        details.accountNumber ? details.accountNumber : '-'
                      }
                      key={details.accountNumber}
                    />
                    <DetailItem
                      label="Name"
                      value={details.name ? details.name : '-'}
                    />
                  </Fragment>
                );
              })}
            </>
          ) : (
            <DetailItem label="Bank Details" value="None found" />
          )
        ) : null}
      </ColumnContainer>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Responsible Entity Contacts
        </Typography>
        {query.data?.responsibleEntityContacts ? (
          <>
            {query.data?.responsibleEntityContacts?.map((item, index) => (
              <EmailContact
                key={item.emailAddress ?? index}
                label={item.name}
                value={item.emailAddress}
              />
            ))}
          </>
        ) : (
          <EmailContact label={''}>-</EmailContact>
        )}
      </ColumnContainer>
    </>
  );
};
