import { IWorksContractFile } from 'common/types/File';
import { EScheduleOfWorksReferralStatus } from 'enums/EScheduleOfWorksReferralStatus';
import { EScheduleOfWorksStatus } from 'enums/EScheduleOfWorksStatus';

export enum EScheduleOfWorksSection {
  Overview,
  CostProfile,
  LatestPaymentRequest,
  ScheduleOfWorksChecks,
}

export interface IScheduleOfWorksRequest {
  applicationId: string;
  scheduleOfWorksId: string;
}

export interface IScheduleOfWorksOverview {
  totalGrantFunding: number;
  totalGrantPaidToDate: number;
  totalUnClaimedGrant: number;
  projectDurationInMonths: number;
  startDate: string;
  endDate: string;
  dateSubmitted: string;
  status: EScheuduleOfWorksStatus;
  worksContractDocuments: Array<IWorksContractFile> | null;
  approvedDate: Date;
  approvedBy: string;
  latestPaymentRequestId?: string;
  isFinalPayment?: boolean;
}

export enum EScheuduleOfWorksStatus {
  NotStarted = 1,
  InProgress = 2,
  Submitted = 3,
  Approved = 4,
}

export interface IScheduleOfWorksPaymentItem {
  itemName: string;
  amount: number;
  paid: boolean;
}

export interface IScheduleOfWorksFinalPaymentItem {
  itemName: string;
  confirmedCost: number;
}

export interface IScheduleOfWorksCostItem {
  id: string;
  itemName: string;
  submittedCost: number;
  confirmedCost?: number;
}

export interface IScheduleOfWorksCosts {
  id: string;
  scheduleOfWorksId?: string;
  showUpfrontPercentage: boolean;
  applyUpfrontPercentage: boolean;
  upfrontPercentage?: number;
  grantPaidToDate: Array<IScheduleOfWorksPaymentItem> | null;
  initialPayments: Array<IScheduleOfWorksCostItem> | null;
  monthlyCosts: Array<IScheduleOfWorksCostItem> | null;
  finalPayment: IScheduleOfWorksFinalPaymentItem | null;
}

export interface IScheduleOfWorksChecksDetails {
  startDate: string;
  endDate: string;
  buildingHeightMetres: number;
  eligibleCosts: number;
  otherCosts: number;
  totalCosts: number;
  leadContractorCompany: string;
  worksContractDocuments: Array<IWorksContractFile> | null;
}

export interface IScheduleOfWorksChecks {
  id?: string;
  scheduleOfWorksId?: string;
  siteStartDateMatchesContract: boolean | null;
  siteStartDateMatchesContractReason: string | null;
  contractProvidesSufficientTime: boolean | null;
  contractProvidesSufficientTimeReason: string | null;
  workPackageCostsMatchSubmittedCosts: boolean | null;
  workPackageCostsMatchSubmittedCostsReason: string | null;
  hasFullPlansApproval: EScheduleOfWorksChecksAnswer | null;
  hasFullPlansApprovalReason: string | null;
  companyNameMatchLeadContractor: boolean | null;
  companyNameMatchLeadContractorReason: string | null;
  monthlyPaymentsGreaterThanMaximumPermitted: boolean | null;
  monthlyPaymentsGreaterThanMaximumPermittedReason: string | null;
  status: EScheduleOfWorksStatus;
  isScheduleOfWorksCostsConfirmed?: boolean;
  approve?: boolean;
}

export interface IScheduleOfWorksReferApplicationForm {
  reason: string;
}

export interface IGetScheduleOfWorksReferralsResponse {
  results: IScheduleOfWorksReferral[];
}

export interface IScheduleOfWorksReferral {
  id: string;
  reason: string;
  status: EScheduleOfWorksReferralStatus;
  completeReason: string | null;
  referredByUserId: string;
}

export interface IScheduleOfWorksReferralRequest {
  applicationId: string;
  scheduleOfWorksId: string;
}

export interface IAddScheduleOfWorksReferral
  extends IScheduleOfWorksReferralRequest {
  reason: string;
}

export interface IScheduleOfWorksCompleteReferralRequest
  extends IScheduleOfWorksCompleteReferral {
  applicationId: string;
  scheduleOfWorksId: string;
  referralId: string;
}

export interface IScheduleOfWorksCompleteReferral {
  completeReason: string;
}

export enum EScheduleOfWorksChecksAnswer {
  Yes = 1,
  No = 2,
  NA = 3,
}