import { Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetGrantCertifyingOfficerAuthorisedSignatoriesQuery } from 'api/application/worksPackageApi';
import { FormSkeleton } from 'common/components/skeletons';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const DutyOfCareSectionInfoPanel = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetGrantCertifyingOfficerAuthorisedSignatoriesQuery(
    applicationId ?? skipToken
  );

  const { data, isLoading, isError } = query;

  if (isError) {
    return (
      <Typography>Failed getting authorised signatories details.</Typography>
    );
  }

  if (isLoading) {
    return <FormSkeleton />;
  }

  return (
    <ColumnContainer margin="10px">
      <Typography variant="h2" padding="16px">
        Authorised signatories
      </Typography>
      {data ? (
        <>
          <DetailItem
            label="Authorised signatory"
            value={
              query.data.authorisedSignatory1
                ? query.data.authorisedSignatory1
                : '-'
            }
          />
          <DetailItem
            label="Email address"
            value={
              query.data.authorisedSignatory1EmailAddress
                ? query.data.authorisedSignatory1EmailAddress
                : '-'
            }
          />
          <DetailItem
            label="Date of appointment"
            value={
              query.data.companiesDateOfAppointment
                ? new Date(query.data.companiesDateOfAppointment).toLocaleDateString("en-GB")
                : '-'
            }
          />
        </>
      ) : null}
    </ColumnContainer>
  );
};
