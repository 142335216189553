import { Fragment } from 'react';
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
  styled,
} from '@mui/material';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { routePaths } from 'routing/IndexRoutes';
import { TAddBuildingRecordAddressInfo } from 'types/pullInProcess/addBuildingRecordTypes';
import { stringAppendWithValueOrEmpty } from 'util/stringAppendWithValueOrEmpty';

interface IBuildingRecordExistingAddressesProps {
  data: TAddBuildingRecordAddressInfo | undefined;
}

const StyledListItem = styled(ListItem)({
  padding: 0,
  width: '100%',
});
const StyledDivider = styled(Divider)({
  margin: '0.8rem 0',
});

export function BuildingRecordExistingAddresses({
  data,
}: IBuildingRecordExistingAddressesProps) {
  if (
    (!data?.existingAddresses?.applications ||
      data?.existingAddresses?.applications?.length === 0) &&
    (!data?.existingAddresses?.applications ||
      data?.existingAddresses?.pullInProcess?.length === 0)
  ) {
    return null;
  }

  return (
    <RoundBorderBox mb="1rem" p="24px" borderRadius="9px">
      <>
        {!!data?.existingAddresses?.pullInProcess?.length ? (
          <List sx={{ p: 0, m: 0 }}>
            <StyledListItem>
              <Typography
                component="h3"
                fontWeight="600"
                fontSize="14px"
              >{`${data.existingAddresses.pullInProcess.length} Existing addresses (Pull in process)`}</Typography>
            </StyledListItem>
            {data.existingAddresses.pullInProcess.map(
              ({ address, pullInProcessId }) => (
                <Fragment key={pullInProcessId}>
                  <StyledDivider />
                  <StyledListItem>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography fontSize="14px">{`${stringAppendWithValueOrEmpty(
                        address.line1,
                        ','
                      )} ${stringAppendWithValueOrEmpty(
                        address.townCity,
                        ','
                      )} ${stringAppendWithValueOrEmpty(
                        address.postCode,
                        ''
                      )}`}</Typography>
                      <Link
                        underline="always"
                        color="inherit"
                        href={`${routePaths.pullInProcesses}/${pullInProcessId}`}
                        fontSize="14px"
                      >
                        View
                      </Link>
                    </Box>
                  </StyledListItem>
                </Fragment>
              )
            )}
          </List>
        ) : null}
        {!!data?.existingAddresses?.applications?.length ? (
          <List
            sx={{
              p: 0,
              mt: !!data?.existingAddresses?.pullInProcess?.length ? '2rem' : 0,
            }}
          >
            <StyledListItem>
              <Typography
                component="h3"
                fontWeight="600"
                fontSize="14px"
              >{`${data.existingAddresses.applications.length} Existing addresses (Applications)`}</Typography>
            </StyledListItem>
            {data.existingAddresses.applications.map(
              ({ address, applicationId }) => (
                <Fragment key={applicationId}>
                  <StyledDivider />
                  <StyledListItem>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography fontSize="14px">{`${stringAppendWithValueOrEmpty(
                        address.line1,
                        ','
                      )} ${stringAppendWithValueOrEmpty(
                        address.townCity,
                        ','
                      )} ${stringAppendWithValueOrEmpty(
                        address.postCode
                      )}`}</Typography>
                      <Link
                        underline="always"
                        color="inherit"
                        href={`${routePaths.applications}/${applicationId}`}
                        fontSize="14px"
                      >
                        View
                      </Link>
                    </Box>
                  </StyledListItem>
                </Fragment>
              )
            )}
          </List>
        ) : null}
      </>
    </RoundBorderBox>
  );
}
