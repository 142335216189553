import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  GetApplicationCategoriesQueryResult,
  useGetApplicationCategoriesQuery,
} from 'api/application';
import { EQuestionType } from 'enums/EQuestionType';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EApplicationDetailsMenuType } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsTypes';

interface IApplicationDetailsContext {
  applicationId: string | undefined;
  applicationCategoriesQuery: GetApplicationCategoriesQueryResult;
  selectedItemId: string | null;
  setSelectedItemId: (itemId: string | null) => void;
  handleContextMenuButtonClick: (
    menuType: EApplicationDetailsMenuType,
    itemId: string
  ) => void;
  showContextMenuForItemId: string | null;
  showContextMenuForQuestionTypeId: EQuestionType | null;
  contextMenuAnchorEl: HTMLElement | null;
  handleShowContextMenu: (anchorEl: HTMLElement | null, itemId: string) => void;
  handleShowQuestionContextMenu: (
    anchorEl: HTMLElement | null,
    questionType: EQuestionType
  ) => void;
  handleCloseContextMenu: () => void;
}

const ApplicationDetailsContext = createContext<
  IApplicationDetailsContext | undefined
>(undefined);

interface IApplicationDetailsContextProviderProps {
  children: ReactNode;
}

export const ApplicationDetailsContextProvider = ({
  children,
}: IApplicationDetailsContextProviderProps) => {
  const { applicationId } = useApplicationContext();
  const applicationCategoriesQuery = useGetApplicationCategoriesQuery(
    applicationId ?? skipToken
  );

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [showContextMenuForItemId, setShowContextMenuForItemId] = useState<
    string | null
  >(null);
  const [
    showContextMenuForQuestionTypeId,
    setShowContextMenuForQuestionTypeId,
  ] = useState<EQuestionType | null>(null);

  const [contextMenuAnchorEl, setContextMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleShowContextMenu = (
    anchorEl: HTMLElement | null,
    itemId: string
  ) => {
    setContextMenuAnchorEl(anchorEl);
    setShowContextMenuForItemId(itemId);
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchorEl(null);
    setShowContextMenuForItemId(null);
  };

  const handleContextMenuButtonClick = (
    menuType: EApplicationDetailsMenuType,
    itemId: string
  ) => {
    switch (menuType) {
      case EApplicationDetailsMenuType.ViewItem:
        setSelectedItemId(itemId);
        break;
    }

    setContextMenuAnchorEl(null);
  };

  const handleShowQuestionContextMenu = (
    anchorEl: HTMLElement | null,
    questionType: EQuestionType
  ) => {
    setContextMenuAnchorEl(anchorEl);
    setShowContextMenuForQuestionTypeId(questionType);
  };

  const value = useMemo(
    () => ({
      applicationId,
      applicationCategoriesQuery,
      selectedItemId: selectedItemId,
      setSelectedItemId: setSelectedItemId,
      handleContextMenuButtonClick,
      contextMenuAnchorEl,
      handleShowContextMenu,
      handleShowQuestionContextMenu,
      handleCloseContextMenu,
      showContextMenuForItemId,
      showContextMenuForQuestionTypeId,
    }),
    [
      applicationCategoriesQuery,
      applicationId,
      contextMenuAnchorEl,
      selectedItemId,
      showContextMenuForItemId,
      showContextMenuForQuestionTypeId,
    ]
  );

  return (
    <ApplicationDetailsContext.Provider value={value}>
      {children}
    </ApplicationDetailsContext.Provider>
  );
};

export const useApplicationDetailsContext = () => {
  const context = useContext(ApplicationDetailsContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationDetailsContext must be used within a ApplicationDetailsContextProvider'
    );
  }
  return context;
};
