import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@mui/material';
import { useGetAdditionalPaymentRecommendationDetailsQuery } from 'api/application/paymentApi';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const ReleaseAdditionalPaymentStatusBanner = () => {
  const { applicationId } = useApplicationContext();

  const additionalPaymentDetails = useGetAdditionalPaymentRecommendationDetailsQuery(applicationId);

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 1,
        bgcolor: 'grey.100',
      }}
    >
      <Grid container spacing={0.5}>
        <Grid xs={12} item>
          <Box display="flex" alignItems="center">
            <FontAwesomeIcon
              icon={
                additionalPaymentDetails?.data?.isApproved
                  ? solid('check-circle')
                  : solid('circle-xmark')
              }
              color="black"
              size="lg"
            />
            <Box
              sx={{
                marginLeft: 2,
              }}
            >
              <Typography fontSize="0.9em" fontWeight={700} color="black">
                {additionalPaymentDetails?.data?.isApproved
                  ? 'Payment Recommendation approved'
                  : 'Payment Recommendation rejected'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          xs={12}
          item
          sx={{
            marginLeft: 4.5,
          }}
        >
          {!additionalPaymentDetails?.data?.isApproved &&
            additionalPaymentDetails?.data?.rejectionReason && (
              <Typography
                fontSize="0.7em"
                sx={{
                  marginBottom: 0.5,
                }}
                fontWeight={800}
                color="grey.600"
              >
                {additionalPaymentDetails?.data?.rejectionReason
                  ? additionalPaymentDetails?.data?.rejectionReason
                  : ''}
              </Typography>
            )}
          <Typography fontSize="0.7em" fontWeight={600} color="grey.600">
            This Recommendation was{' '}
            {additionalPaymentDetails?.data?.isApproved ? 'approved' : 'rejected'} at{' '}
            {additionalPaymentDetails?.data?.dateRecommendationApproved &&
              getUkDateStringFromJsonDateString(
                additionalPaymentDetails?.data?.dateRecommendationApproved.toString()
              )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
