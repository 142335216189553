import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  useEditAlternativeFundingRoutesMutation,
  useGetAlternativeFundingRoutesQuery,
} from 'api/application/applicationEditApi';
import { EFundingStillPursuing } from 'enums/EFundingStillPursuing';
import { EPursuedSourcesFundingType } from 'enums/EPursuedSourcesFundingType';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IdName } from 'types/GeneralTypes';
import { zAlternateFundingRoutesSchema } from 'types/applications/ApplicationEdit.zod';
import { IAlternativeFundingRoutesForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditAlternateFundingRoutesDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditAlternateFundingRoutesDrawer = ({
  onClose,
  onSuccess,
}: IEditAlternateFundingRoutesDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">Edit alternate routes of funding</Typography>
        </DialogTitle>
        <EditAlternateFundingRoutesForm
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Box>
    </StyledDrawer>
  );
};

interface IEditAlternateFundingRoutesFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

const EditAlternateFundingRoutesForm = ({
  onClose,
  onSuccess,
}: IEditAlternateFundingRoutesFormProps) => {
  const { applicationId } = useApplicationContext();

  const { data, isLoading } =
    useGetAlternativeFundingRoutesQuery(applicationId);

  const [editAlternateFundingRoutes, editAlternateFundingRoutesResult] =
    useEditAlternativeFundingRoutesMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IAlternativeFundingRoutesForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: data || undefined,
    resolver: zodResolver(zAlternateFundingRoutesSchema),
  });
  const { handleSubmit, reset, control, watch, setValue } = form;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const pursuedOtherFundingSourcesOptions: IdName<EPursuedSourcesFundingType>[] =
    [
      {
        id: EPursuedSourcesFundingType.ExhaustedAllRoutes,
        name: 'Exhausted All Routes',
      },
      {
        id: EPursuedSourcesFundingType.NotExhaustedAllRoutes,
        name: 'Not Exhausted All Routes',
      },
      {
        id: EPursuedSourcesFundingType.PursuingOtherRoutes,
        name: 'Pursuing Other Routes',
      },
    ];

  const whichFundingRoutesOptions: IdName<EFundingStillPursuing>[] = [
    {
      id: EFundingStillPursuing.ClaimAgainstContracter,
      name: 'Claim Against Contracter',
    },
    {
      id: EFundingStillPursuing.NotSignedUpDevelopersPledge,
      name: 'Not Signed Up Developers Pledge',
    },
    {
      id: EFundingStillPursuing.SignedUpDevelopersPledge,
      name: 'Signed Up Developers Pledge',
    },
    {
      id: EFundingStillPursuing.Other,
      name: 'Other',
    },
  ];

  const onSubmit = async (formData: IAlternativeFundingRoutesForm) => {
    try {
      formData.applicationId = applicationId;
      await editAlternateFundingRoutes(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar(`Part of development updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit part of development`);
    }
  };

  return isLoading ? (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="pursuedOtherFundingSources"
                render={({
                  fieldState,
                  field: { onChange, value, ...fieldProps },
                }) => (
                  <Autocomplete
                    {...fieldProps}
                    onChange={(_, val) => {
                      onChange(val);
                      if (
                        val !== EPursuedSourcesFundingType.PursuingOtherRoutes
                      )
                        setValue('whichFundingRoutes', []);
                    }}
                    value={value}
                    options={pursuedOtherFundingSourcesOptions.map(
                      ({ id }) => id
                    )}
                    getOptionLabel={opt =>
                      pursuedOtherFundingSourcesOptions.find(o => o.id === opt)
                        ?.name || ''
                    }
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!fieldState.error}
                          label="Have you pursued other sources of funding?"
                          helperText={fieldState.error?.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            {watch('pursuedOtherFundingSources') ===
            EPursuedSourcesFundingType.PursuingOtherRoutes ? (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="whichFundingRoutes"
                  render={({
                    fieldState,
                    field: { onChange, value, ...fieldProps },
                  }) => (
                    <Autocomplete
                      {...fieldProps}
                      onChange={(_, val) => {
                        onChange(val);
                      }}
                      value={value}
                      options={whichFundingRoutesOptions.map(({ id }) => id)}
                      getOptionLabel={opt =>
                        whichFundingRoutesOptions.find(o => o.id === opt)
                          ?.name || ''
                      }
                      multiple
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!fieldState.error}
                            label="Which routes of funding are you still pursuing?"
                            helperText={fieldState.error?.message}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editAlternateFundingRoutesResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
