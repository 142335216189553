import jwtDecode from 'jwt-decode';
import { IDecodedAccessToken } from 'types/DecodedToken';
import { UserPermissions } from 'types/UserPermissions';

export const getDecodedToken = <T>(token: Nullable<string>) => {
  const decodedToken = token !== null ? jwtDecode<T>(token) : null;

  if (!decodedToken) {
    return null;
  }

  return decodedToken;
};

export const getDecodedAccessToken = (token: Nullable<string>) => {
  const decodedToken = getDecodedToken<IDecodedAccessToken>(token);
  if (!decodedToken) {
    return null;
  }

  if (!decodedToken.api_permissions) {
    return {
      ...decodedToken,
      api_permissions: [] as UserPermissions[],
    };
  }

  if (!Array.isArray(decodedToken.api_permissions)) {
    return {
      ...decodedToken,
      api_permissions: [decodedToken.api_permissions] as UserPermissions[],
    };
  }

  return decodedToken;
};
