import { Button, Typography } from '@mui/material';
import { useDeleteDocumentMutation } from 'api/application/documentsApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { IDeleteApplicationDocument } from 'types/applications/ApplicationDocumentTypes';

interface IDeleteDocumentDialogProps {
  documentId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function DeleteDocumentDialog({
  documentId,
  onConfirm,
  onCancel,
}: IDeleteDocumentDialogProps) {
  const { applicationId } = useApplicationContext();
  const [deleteDocument] = useDeleteDocumentMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onDeleteDocument = async () => {
    const deleteApplicationDocument: IDeleteApplicationDocument = {
      fileId: documentId,
      applicationId: applicationId,
    };

    try {
      await deleteDocument(deleteApplicationDocument);
      createSuccessSnackbar('Successfully deleted document');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to delete document`);
    }
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you wish to permanently delete this file?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button aria-label="cancel" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          aria-label="Delete File"
          variant="contained"
          onClick={onDeleteDocument}
        >
          Delete document
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
