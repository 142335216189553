import { rest } from 'msw';
import { countriesData } from 'mocks/data/common/countryData';

export const countryHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/api/country`, (req, res, ctx) => {
    if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
      // const postCode = req.params.postcode;
      return res(ctx.status(200), ctx.json(countriesData));
    }
    return req.passthrough();
  }),
];
