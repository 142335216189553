import { ELiveProjectItemType } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import {
  ApplicationLiveProjectContextProvider,
  useApplicationLiveProjectContext,
} from 'pages/applicationPage/content/liveProject/context';
import {
  OverviewSection,
  PaymentRequestSection,
  ScheduleOfWorksSection,
  VariationSection,
} from 'pages/applicationPage/content/liveProject/sections';
import { ClosingPaymentRequestSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/ClosingPaymentRequestSection';

export const LiveProject = () => {
  return (
    <ApplicationLiveProjectContextProvider>
      <LiveProjectMain />
    </ApplicationLiveProjectContextProvider>
  );
};
const LiveProjectMain = () => {
  const { selectedItem } = useApplicationLiveProjectContext();

  if (!selectedItem) {
    return <OverviewSection />;
  }

  switch (selectedItem.type) {
    case ELiveProjectItemType.ScheduleOfWorks:
      return <ScheduleOfWorksSection />;

    case ELiveProjectItemType.PaymentRequest:
      return <PaymentRequestSection />;

    case ELiveProjectItemType.Variation:
      return <VariationSection />;

    case ELiveProjectItemType.ClosingPaymentRequest:
      return <ClosingPaymentRequestSection />;

    default:
      return null;
  }
};
