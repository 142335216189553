import { Box, Chip, Typography } from '@mui/material';
import { EScheduleOfWorksReferralStatus } from 'enums/EScheduleOfWorksReferralStatus';
import { useScheduleOfWorksContext } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/context/ScheduleOfWorksContext';
import { ScheduleOfWorksSummaryGrid } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/Referrals/ScheduleOfWorksSummaryGrid';

export const ScheduleOfWorksReferrals = () => {
  const { referrals, showReferralsSection, isReferralsSectionShowing } =
    useScheduleOfWorksContext();

  const activeReferrals = referrals.filter(
    item => item.status !== EScheduleOfWorksReferralStatus.Complete
  ).length;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" fontWeight={600} fontSize="1em">
          Referrals
        </Typography>
        <Box
          sx={{
            mb: 2,
            ml: 2,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexDirection: 'row-reverse',
          }}
        >
          {!isReferralsSectionShowing && (
            <Chip
              label={`${referrals.length} ${
                referrals.length > 0
                  ? isReferralsSectionShowing
                    ? ''
                    : '...Click here to view'
                  : ''
              }`}
              color={'warning'}
              variant={isReferralsSectionShowing ? 'filled' : 'outlined'}
              sx={{ cursor: 'pointer', transition: 'all 250ms linear' }}
              onClick={() => {
                showReferralsSection();
              }}
            />
          )}
          <Chip
            label={`${activeReferrals} Active`}
            color={'primary'}
            variant="filled"
            sx={{ cursor: 'pointer', transition: 'all 250ms linear' }}
            onClick={() => {
              showReferralsSection();
            }}
          />
        </Box>
      </Box>
      <ScheduleOfWorksSummaryGrid />
    </>
  );
};
