export interface IPutApplicationOnHoldParams {
  applicationId: string;
  reasonId: number,
  otherReason: string;
  holdType: EOnHoldType;
}

export interface ITakeApplicationOffHoldParams {
  applicationId: string;
  reason: string;
}

export type GetApplicationHoldLatestResponse =
  | {
      holdStatus: EHoldStatus.None;
    }
  | {
      holdStatus: EHoldStatus.OnHold | EHoldStatus.NotOnHold;
      reason: string;
      user: string;
      date: string;
      holdType?: EOnHoldType;
    };

export enum EOnHoldType {
  WorksPackage = 1,
  ScheduleOfWorks = 2,
  PrimaryReport = 3,
  PaymentRequest = 4,
  Variation = 5,
  ClosingReport = 6,
  GrantFundingAgreement = 7,
  Eligibility = 8
}

export enum EHoldStatus {
  None = 1,
  OnHold = 2,
  NotOnHold = 3,
}
