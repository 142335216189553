import { Button, Stack, Typography } from '@mui/material';

interface IPaymentReferralsGridTitleProps {
  showReferralsSection: () => void;
}

export const PaymentReferralsGridTitle = ({
  showReferralsSection,
}: IPaymentReferralsGridTitleProps) => {
  return (
    <Stack direction={'row'} gap={2} alignItems={'center'}>
      <Typography fontWeight={600}>All Referrals</Typography>
      <Button
        onClick={showReferralsSection}
        variant="outlined"
        size="small"
        sx={{ height: 'unset', py: 0, px: 2, m: 0 }}
      >
        HE Referrals &raquo;
      </Button>
    </Stack>
  );
};
