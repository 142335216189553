import { useEffect } from 'react';
import { usePrevious } from 'hooks';
import { EApplicationGrantFundingSectionType } from 'pages/applicationPage/content/applicationGrantFunding/ApplicationGrantFundingTypes';
import { useApplicationGrantFundingContext } from 'pages/applicationPage/content/applicationGrantFunding/context';

const _sectionTitleMap = {
  details: EApplicationGrantFundingSectionType.CheckGrantFundingDetails,
  docs: EApplicationGrantFundingSectionType.UploadSignedDocs,
  counter: EApplicationGrantFundingSectionType.UploadCounterSignedGrantFunding,
} as const;

export const getSectionId = (sectionName: string | null) => {
  if (!sectionName) {
    return null;
  }

  for (const [key, value] of Object.entries(_sectionTitleMap)) {
    if (sectionName.toLocaleLowerCase().includes(key)) {
      // Found a matching section, return the value
      return value;
    }
  }

  // No section found, return null
  return null;
};

export const useGrantFundingSectionOnOpen = (
  type: EApplicationGrantFundingSectionType,
  fn: () => void
) => {
  const { selectedItemId } = useApplicationGrantFundingContext();
  const previousSelectedItemId = usePrevious(selectedItemId);

  useEffect(() => {
    if (
      getSectionId(selectedItemId) === type &&
      getSectionId(previousSelectedItemId) !== type
    ) {
      fn();
    }
  }, [fn, previousSelectedItemId, selectedItemId, type]);
};
