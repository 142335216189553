import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { organisationEndpoints } from 'api/administration/organisations';
import { ESortDirection } from 'enums/ESortDirection';
import { IGetUsersListQuery , IUsersGridListItem } from 'types/administration/AdministrationUsersTypes';
import { nameof } from 'util/formUtils';

export const administrationSortByTypes = [
  nameof<IUsersGridListItem>('firstName'),
  nameof<IUsersGridListItem>('lastName'),
  nameof<IUsersGridListItem>('emailAddress'),
  nameof<IUsersGridListItem>('organisation'),
  nameof<IUsersGridListItem>('role'),
  nameof<IUsersGridListItem>('lastLogin'),
] as const;
export type AdministrationSortByTypes =
  (typeof administrationSortByTypes)[number];

export interface IAdministrationUsersState {
  isGridLoading: boolean;
  gridState: IGetUsersListQuery;
}

const initialState: IAdministrationUsersState = {
  isGridLoading: true,
  gridState: {
    skip: 0,
    take: 25,
    roleId: [],
    organisationId: [],
    searchValue: null,
    sortBy: nameof<IUsersGridListItem>('firstName'),
    sortDirection: ESortDirection.Asc,
  },
};

const administrationUsersSlice = createSlice({
  name: 'administrationUsers',
  initialState,
  reducers: {
    gridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetUsersListQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
    userLeftAdministrationUsersPage: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        organisationEndpoints.endpoints.getOrganisations.matchPending,
        state => {
          state.isGridLoading = true;
        }
      )
      .addMatcher(
        organisationEndpoints.endpoints.getOrganisations.matchFulfilled,
        state => {
          state.isGridLoading = false;
        }
      )
      .addMatcher(
        organisationEndpoints.endpoints.getOrganisations.matchRejected,
        state => {
          state.isGridLoading = false;
        }
      );
  },
});

export const administrationUsersReducer = administrationUsersSlice.reducer;
export const { gridStateChanged, userLeftAdministrationUsersPage } =
  administrationUsersSlice.actions;
