import { Box } from '@mui/material';
import { useGetApplicationSummaryQuery } from 'api/application';
import { ErrorAlert } from 'common/components/alerts';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { Loading } from 'common/components/loading/Loading';
import { MainContentContainer } from 'common/components/mainContentContainer';
import { ApplicationTabs } from 'pages/applicationPage/common/components';
import {
  ApplicationContextProvider,
  useApplicationContext,
} from 'pages/applicationPage/common/context';
import { TitleAndSummarySection } from 'pages/applicationPage/common/summary';
import { ApplicationRagRating } from 'pages/applicationPage/common/summary/ApplicationRagRating';
import { extractErrorMessages } from 'util/ApiUtils';

export const ApplicationPage = () => {
  return (
    <ApplicationContextProvider>
      <ApplicationPageMain />
    </ApplicationContextProvider>
  );
};

const ApplicationPageMain = () => {
  const { applicationId } = useApplicationContext();
  const query = useGetApplicationSummaryQuery(applicationId);

  const crumbs: readonly IBreadcrumbItem[] = [
    { text: 'Applications', url: '/applications' },
    { text: query.data ? query.data.buildingName : '...', url: undefined },
  ] as const;

  return (
    <MainContentContainer>
      <Box display="flex" alignItems="center" gap={2}>
        <Breadcrumbs crumbs={crumbs} />
        <ApplicationRagRating />
      </Box>

      {query.isLoading ? <Loading isOpen /> : null}

      {query.isError ? (
        <ErrorAlert msg={extractErrorMessages(query.error)} />
      ) : null}

      {query.data ? (
        <>
          <TitleAndSummarySection data={query.data} />
          <ApplicationTabs />
        </>
      ) : null}
    </MainContentContainer>
  );
};
