import { ReactNode } from 'react';
import { Stack } from '@mui/material';

export const GrantPercentageValueContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Stack
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      gap={3}
    >
      {children}
    </Stack>
  );
};
