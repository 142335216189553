import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  TextField,
  Button,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useEditCaveatsOrLimitationsMutation } from 'api/application/applicationEditApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { FieldLabel } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zCaveatsOrLimitationsSchema } from 'types/applications/ApplicationEdit.zod';
import { ICaveatsOrLimitationsForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditCaveatsOrLimitationsDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
  currentValue?: string;
}

export const EditCaveatsOrLimitationsDrawer = ({
  onClose,
  onSuccess,
  currentValue = '-',
}: IEditCaveatsOrLimitationsDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Caveats or limitations on the conclusions in your FRAEW?
          </Typography>
          <Typography variant="h3">{currentValue}</Typography>
        </DialogTitle>
        <EditCaveatsOrLimitationsForm onClose={onClose} onSuccess={onSuccess} />
      </Box>
    </StyledDrawer>
  );
};

interface IEditCaveatsOrLimitationsFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditCaveatsOrLimitationsForm = ({
  onClose,
  onSuccess,
}: IEditCaveatsOrLimitationsFormProps) => {
  const { applicationId } = useApplicationContext();

  const [editCaveatsOrLimitations, editCaveatsOrLimitationsResult] =
    useEditCaveatsOrLimitationsMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<ICaveatsOrLimitationsForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zCaveatsOrLimitationsSchema),
  });
  const { handleSubmit, reset, control } = form;

  const onSubmit = async (formData: ICaveatsOrLimitationsForm) => {
    try {
      formData.applicationId = applicationId;
      await editCaveatsOrLimitations(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar(`Caveats or limitations updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit caveats or limitations`);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <FieldLabel label="Please state any caveats or limitations on the conclusions in your FRAEW" />
              <Controller
                control={control}
                defaultValue=""
                name="summary"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editCaveatsOrLimitationsResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
