import { useCallback } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  useGetApplicationCommunicationCategoriesQuery,
  useGetApplicationCommunicationsQuery,
} from 'api/application/communicationsApi';
import { GridSearchField } from 'common/components/gridSearchField';
import { useAppDispatch } from 'state';
import { selectApplicationCommunicationsGridState } from 'state/selectors/applications/applicationsSelectors';
import { communicationsGridStateChanged } from 'state/slices/applications';
import {
  StyledFilter,
  StyledFiltersGridLeft,
  StyledFiltersGridRight,
} from 'styles/globalStyles/filterStyles';

export const CommunicationsFilters = () => {
  const dispatch = useAppDispatch();

  const categoriesQuery = useGetApplicationCommunicationCategoriesQuery();

  const applicationCommunicationsGridState = useSelector(
    selectApplicationCommunicationsGridState
  );
  const { isLoading } = useGetApplicationCommunicationsQuery(
    applicationCommunicationsGridState
  );

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      dispatch(
        communicationsGridStateChanged({
          searchValue,
          skip: 0,
        })
      );
    },
    [dispatch]
  );

  return (
    <Grid container pt={4}>
      <StyledFiltersGridLeft item xs={8}>
        <StyledFilter>
          <GridSearchField
            isLoading={isLoading}
            defaultValue={applicationCommunicationsGridState.searchValue}
            handleSearch={handleSearchCallback}
          />
        </StyledFilter>
      </StyledFiltersGridLeft>
      <StyledFiltersGridRight item xs={4}>
        <StyledFilter>
          <Autocomplete
            multiple
            data-testid="category"
            disabled={isLoading}
            onChange={(_, selectedValues) => {
              dispatch(
                communicationsGridStateChanged({
                  categories: selectedValues.map(
                    selectedValue => selectedValue.id
                  ),
                  skip: 0,
                })
              );
            }}
            options={categoriesQuery.data ? categoriesQuery.data : []}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label="Category" />}
            size="small"
          />
        </StyledFilter>
      </StyledFiltersGridRight>
    </Grid>
  );
};
