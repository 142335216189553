export enum EVeriphyStatus {
  NotStarted = 1,
  InProgress = 2,
  Completed = 3,
  NotFound = 4,
  Failed = 5,
  Error = 6
}

export const EVeriphyStatusLanguage: {
  [key in EVeriphyStatus]: string;
} = {
  [EVeriphyStatus.NotStarted]: 'Not Started',
  [EVeriphyStatus.InProgress]: 'In Progress',
  [EVeriphyStatus.Completed]: 'Completed',
  [EVeriphyStatus.NotFound]: 'Not Found',
  [EVeriphyStatus.Failed]: 'Failed',
  [EVeriphyStatus.Error]: 'Error',
} as const;
