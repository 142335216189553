import { createContext, useContext } from 'react';
import { useModalState } from 'hooks';

export const pullInDocumentContext = createContext<
  ReturnType<typeof useModalState<string | null>> | undefined
>(undefined);

export function usePullInDocumentContext() {
  const pullInDocumentModalContext = useContext(pullInDocumentContext);
  if (pullInDocumentModalContext === undefined) {
    throw new Error('pullInDocumentContext is undefined');
  }
  return pullInDocumentModalContext;
}
