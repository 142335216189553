import { Grid, Typography } from '@mui/material';

export const CostProfileHeaderSection = () => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={8}
      wrap="nowrap"
      sx={{
        p: 1,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em" fontWeight={800}>
          Cost Type
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle1" fontSize="1em" fontWeight={800}>
          Submitted schedule of works (£)
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle1" fontSize="1em" fontWeight={800}>
          Confirmed schedule of works (£)
        </Typography>
      </Grid>
    </Grid>
  );
};
