import {
  Divider,
  Typography,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  useGetBuildingDetailsQuery,
  useSaveGrantPercentageMutation,
} from 'api/application/worksPackageApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { IWorksPackageChecksForm } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import {
  GrantPercentageValueContainer,
  FieldLabel,
  QuestionContainer,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { getGbpAmount } from 'util/AppUtils';

export const GrantPercentageField = ({
  readOnly,
  worksPackageCost,
  saveGrantPercentageStatus,
}: {
  readOnly: boolean;
  worksPackageCost: number;
  saveGrantPercentageStatus: ReturnType<
    typeof useSaveGrantPercentageMutation
  >[1];
}) => {
  const { applicationId } = useWorksPackageContext();
  const { isSuccess, data, isError, error } =
    useGetBuildingDetailsQuery(applicationId);

  const { control, watch } = useFormContext<IWorksPackageChecksForm>();

  const [percentage] = watch(['grantPercentage']);

  if (isSuccess) {
    const multiplier = percentage / 100;
    const recommendPayCalc = isNaN(multiplier)
      ? 0
      : worksPackageCost * multiplier;

    return (
      <QuestionContainer>
        <FieldLabel label={'Confirm the percentage of grant available'} />

        {isError ? (
          <AlertForQueryErrorOrNull isError={isError} error={error} />
        ) : null}

        {readOnly ? (
          <>
            <Divider sx={{ my: 3 }} />
            <GrantPercentageValueContainer>
              <Typography fontWeight={'600'}>
                {data.percentageOfCosts}%
              </Typography>
              <Typography color={'grey.500'}>
                ({getGbpAmount({ value: data.recommendedPayAmount })})
              </Typography>
            </GrantPercentageValueContainer>
          </>
        ) : null}

        {!readOnly ? (
          <Stack rowGap={2}>
            <GrantPercentageValueContainer>
              <Controller
                control={control}
                name={`grantPercentage`}
                rules={{
                  required: 'Required',
                  min: {
                    value: 0,
                    message: 'Must be greater than zero',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter a number',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={readOnly}
                    error={!!fieldState.error}
                    rows={3}
                    helperText={fieldState.error?.message}
                    sx={{ width: '100px', mb: 0 }}
                    inputProps={{ maxLength: 5 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Typography color={'grey.500'}>
                ({getGbpAmount({ value: recommendPayCalc ?? '0' })})
              </Typography>
            </GrantPercentageValueContainer>

            <AlertForQueryErrorOrNull
              isError={saveGrantPercentageStatus.isError}
              error={saveGrantPercentageStatus.error}
            />
          </Stack>
        ) : null}
      </QuestionContainer>
    );
  }

  return null;
};
