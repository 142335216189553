import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useAddAssessorMutation,
  useEditAssessorMutation,
  useGetAssessorQuery,
} from 'api/administration/assessorPanelList';
import { TransactionError } from 'common/types/TransactionResult';
import { ERegion } from 'enums/ERegion';
import { useLocalSnackbar } from 'hooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IAdministrationAssessorPanelListFormDto } from 'types/administration/AdministrationAssessorPanelListTypes';
import { admininstrationAssessorPanelListFormSchema } from 'types/administration/administrationAssessorPanelListForm.zod';
import { setServerSideFormErrors } from 'util/formUtils';

const options: { id: ERegion; name: string }[] = [
  {
    id: ERegion.EastMidlands,
    name: 'East Midlands',
  },
  {
    id: ERegion.EastOfEngland,
    name: 'East of England',
  },
  {
    id: ERegion.London,
    name: 'London',
  },
  {
    id: ERegion.NorthEast,
    name: 'North East',
  },
  {
    id: ERegion.NorthWest,
    name: 'North West',
  },
  {
    id: ERegion.SouthEast,
    name: 'South East',
  },
  {
    id: ERegion.SouthWest,
    name: 'South West',
  },
  {
    id: ERegion.WestMidlands,
    name: 'West Midlands',
  },
  {
    id: ERegion.YorkshireAndTheHumber,
    name: 'Yorkshire and the Humber',
  },
];

const allOptionClickResult: ERegion[] = options.map(opt => opt.id);

export function AdministrationAssessorPanelListForm({
  onSuccess,
  onClose,
  selectedAssessorId = undefined,
}: {
  onSuccess: () => void;
  onClose: () => void;
  selectedAssessorId?: number | null;
}) {
  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const [addAssessor, addAssessorState] = useAddAssessorMutation();
  const [editAssessor, editAssessorState] = useEditAssessorMutation();

  const isAdding = selectedAssessorId == null;

  const { data, isLoading } = useGetAssessorQuery(
    selectedAssessorId ? selectedAssessorId?.toString() : '',
    { skip: isAdding }
  );

  const isSubmitting =
    addAssessorState.isLoading || editAssessorState.isLoading || isLoading;

  const form = useForm<IAdministrationAssessorPanelListFormDto>({
    resolver: zodResolver(admininstrationAssessorPanelListFormSchema),
  });

  const { control, handleSubmit, reset } = form;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const handleSuccess = () => {
    createSuccessSnackbar(`Assesser details ${isAdding ? 'added' : 'updated'}`);
    onSuccess();
  };

  const handleCaughtErrors = (error: { data: TransactionError; }) => {
    setServerSideFormErrors(form, error.data);
    createErrorSnackbar(
      `Failed to  ${isAdding ? 'added' : 'updated'} assessor details`
    );
  };

  const onValidSubmit = (formData: IAdministrationAssessorPanelListFormDto) => {
    if (isAdding) {
      addAssessor(formData)
        .unwrap()
        .then(handleSuccess)
        .catch(error => {handleCaughtErrors(error)});
    } else {
      if (selectedAssessorId) {
        editAssessor({ formData, id: selectedAssessorId })
          .unwrap()
          .then(handleSuccess)
          .catch(error => {handleCaughtErrors(error)});
      }
    }
  };

  const onInvalidSubmimt = () => {
    createWarningSnackbar('Please correct any form validation errors');
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onValidSubmit, onInvalidSubmimt)}>
        <Grid display="flex" flexDirection="column" p={4}>
          <Controller
            control={control}
            name="name"
            defaultValue={data?.name ?? ''}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Company Name"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                disabled={isSubmitting}
              />
            )}
          />

          <Controller
            control={control}
            name="regions"
            defaultValue={data?.regions ?? []}
            render={({ field: { value, onChange }, fieldState }) => {
              return (
                <Box mb={3}>
                  <FormGroup
                    sx={theme => ({
                      // not sure where this border color '#b0bec5' can be pulled from,
                      // set somewhere in mui's default as input border color
                      border: `1px solid ${
                        fieldState.error ? theme.palette.error.main : '#b0bec5'
                      }`,
                      borderRadius: '0.25rem',
                      padding: '0.85rem',
                    })}
                  >
                    <FormLabel>Regions Covered</FormLabel>
                    <FormControlLabel
                      label="Select All"
                      sx={{ height: '2rem', mt: '1rem', pl: 1 }}
                      control={
                        <Checkbox
                          disabled={isSubmitting}
                          checked={value.length === options.length}
                          onChange={(_, newChecked) => {
                            onChange(newChecked ? allOptionClickResult : []);
                          }}
                        />
                      }
                    />
                    {options.map(option => {
                      const isChecked = value?.includes(option.id);
                      return (
                        <FormControlLabel
                          key={`${option.id}-${option.name}`}
                          label={option.name}
                          sx={{ height: '2rem', pl: 1 }}
                          control={
                            <Checkbox
                              disabled={isSubmitting}
                              checked={isChecked}
                              name={option.name}
                              onChange={(_, newChecked) => {
                                if (newChecked) {
                                  onChange([...value, option.id]);
                                } else {
                                  onChange(
                                    value?.filter(item => item !== option.id)
                                  );
                                }
                              }}
                            />
                          }
                        />
                      );
                    })}
                  </FormGroup>
                  {!!fieldState.error ? (
                    <FormHelperText sx={{ ml: 1.6 }} error>
                      {fieldState.error?.message}
                    </FormHelperText>
                  ) : null}
                </Box>
              );
            }}
          />
          <Controller
            control={control}
            name="emailAddress"
            defaultValue={data?.emailAddress ?? ''}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Email"
                disabled={isSubmitting}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="telephone"
            defaultValue={data?.telephone ?? ''}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Telephone"
                disabled={isSubmitting}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              {isAdding ? 'Add' : 'Update'} Assessor
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
}
interface Props {
  isShowing: boolean;
  onClose: () => void;
  onSuccess: () => void;
  selectedAssessorId?: number | null;
}

export function AdministrationAssessorPanelListDrawer({
  isShowing,
  onClose,
  onSuccess,
  selectedAssessorId = undefined,
}: Props) {
  return (
    <StyledDrawer
      anchor="right"
      open={isShowing}
      ModalProps={{
        // when drawer is hidden, the contents will not be rendered to the dom
        keepMounted: false,
      }}
      onClose={onClose}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            {selectedAssessorId ? 'Update' : 'Add'} Assessor Details
          </Typography>
        </DialogTitle>
        <AdministrationAssessorPanelListForm
          onSuccess={onSuccess}
          onClose={onClose}
          selectedAssessorId={selectedAssessorId}
        />
      </Box>
    </StyledDrawer>
  );
}
