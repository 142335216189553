import { useUploadFileMutation } from 'api/file';
import { IFile, IFileUpload } from 'common/types/File';
import { useLocalSnackbar } from 'hooks';
import { extractErrorMessages } from 'util/ApiUtils';

type UploadFileParams = IFileUpload & {
  onSuccess?: (payload: IFile) => void;
  onError?: (error: unknown) => void;
  showSuccessMessage?: boolean;
  successMessage?: string;
  showErrorMessage?: boolean;
};

const useDefaultErrorFn = () => {
  const { createErrorSnackbar } = useLocalSnackbar();
  const defaultErrorFn = (error: any) => {
    createErrorSnackbar(extractErrorMessages(error));
  };
  return { defaultErrorFn };
};

export const useFileUpload = () => {
  const { defaultErrorFn } = useDefaultErrorFn();
  const [uploadFileMutation, uploadFileStatus] = useUploadFileMutation();
  const { createSuccessSnackbar } = useLocalSnackbar();

  const uploadFile = async (params: UploadFileParams) => {
    const {
      file,
      uploadSection,
      showSuccessMessage = true,
      successMessage = 'File uploaded successfully',
      showErrorMessage = true,
    } = params;

    const fileFormData = new FormData();
    fileFormData.append('file', file);

    try {
      let fileUpload: IFileUpload = {
        file,
        uploadSection,
      };

      await uploadFileMutation(fileUpload)
        .unwrap()
        .then(payload => {
          if (showSuccessMessage) {
            createSuccessSnackbar(successMessage);
          }
          if (params.onSuccess) {
            params.onSuccess(payload);
          }
        })
        .catch(error => {
          if (showErrorMessage) {
            defaultErrorFn(error);
          }

          if (params.onError) {
            params.onError(error);
          }
        });
    } catch (error) {
      if (showErrorMessage) {
        defaultErrorFn(error);
      }

      if (params.onError) {
        params.onError(error);
      }
    }
  };

  return { uploadFile, uploadFileStatus };
};
