import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { SortDirection } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import { useGetPaginatedUsersRolesListQuery } from 'api/administration/users';
import { Loading } from 'common/components/loading';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import { ESortDirection } from 'enums/ESortDirection';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { AddEditUserDrawer } from 'pages/administrationPage/components/administrationUsersTab/components/addEditUserDrawer/AddEditUserDrawer';
import { DeleteUserDialog } from 'pages/administrationPage/components/administrationUsersTab/components/deleteUserDialog/DeleteUserDialog';
import { ResetUserPasswordDialog } from 'pages/administrationPage/components/administrationUsersTab/components/resetUserPasswordDialog/ResetUserPasswordDialog';
import { ToggleUserBlockDialog } from 'pages/administrationPage/components/administrationUsersTab/components/toggleUserBlockDialog/ToggleUserBlockDialog';
import { EAdminUsersMenuType } from 'pages/administrationPage/components/administrationUsersTab/types/EAdminUsersMenuType';
import { useAppDispatch } from 'state';
import {
  selectAdministrationUsersGridState,
  selectIsAdministrationUsersGridLoading,
} from 'state/selectors/administration/administrationUsersSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationUsersReducer';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { IUsersGridListItem } from 'types/administration/AdministrationUsersTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export function AdministrationUsersGrid() {
  const dispatch = useAppDispatch();
  const administrationUsersGridState = useSelector(
    selectAdministrationUsersGridState
  );
  const usersListQuery = useGetPaginatedUsersRolesListQuery(
    administrationUsersGridState
  );

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminUsers = doesUserHaveSinglePermission('admin.users');

  const { skip, take, sortBy, sortDirection } = administrationUsersGridState;
  const isAdministrationUsersGridLoading = useSelector(
    selectIsAdministrationUsersGridLoading
  );

  const {
    isShowing: isAddEditUserDrawerShowing,
    showModal: showAddEditUserDrawer,
    hideModal: hideAddEditUserDrawer,
  } = useModalState();

  const {
    isShowing: isToggleUserBlockDialogShowing,
    showModal: showToggleUserBlockDialog,
    hideModal: hideToggleUserBlockDialog,
  } = useModalState();

  const {
    isShowing: isResetUserPasswordDialogShowing,
    showModal: showResetUserPasswordDialog,
    hideModal: hideResetUserPasswordDialog,
  } = useModalState();

  const {
    isShowing: isDeleteUserDialogShowing,
    showModal: showDeleteUserDialog,
    hideModal: hideDeleteUserDialog,
  } = useModalState();

  const [selectedRowUserId, setSelectedRowUserId] = useState<string | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const noResultsFound =
    usersListQuery.data?.count === 0 &&
    !!usersListQuery.originalArgs?.searchValue;

  const handleClose = (menuType: EAdminUsersMenuType) => {
    switch (menuType) {
      case EAdminUsersMenuType.ViewEditUser:
        showAddEditUserDrawer(true);
        break;
      case EAdminUsersMenuType.BlockUser:
        showToggleUserBlockDialog(true);
        break;
      case EAdminUsersMenuType.ViewActivityLog:
        break;
      case EAdminUsersMenuType.ResetPassword:
        showResetUserPasswordDialog(true);
        break;
      case EAdminUsersMenuType.DeleteUser:
        showDeleteUserDialog(true);
        break;
    }

    setAnchorEl(null);
  };

  const onEllipsisClick = (
    rowId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedRowUserId(rowId);
    setAnchorEl(event.currentTarget);
  };

  const currentPage = skip / take;

  const sortHandler = (property: keyof IUsersGridListItem): void => {
    dispatch(
      gridStateChanged({
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      })
    );
  };

  const getAriaSortDirection = (
    property: keyof IUsersGridListItem,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getCurrentSortDirection = (
    property: keyof IUsersGridListItem,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof IUsersGridListItem,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  return (
    <>
      <Loading isOpen={isAdministrationUsersGridLoading} />
      <NoResultsFoundText show={noResultsFound} />
      <StyledGenericTable data-testid="Administration-Users-Table">
        <TableHead>
          <TableRow>
            {getHeaderTableCell('firstName', 'First Name')}
            {getHeaderTableCell('lastName', 'Last Name')}
            {getHeaderTableCell('emailAddress', 'Email Address')}
            {getHeaderTableCell('organisation', 'Organisation')}
            {getHeaderTableCell('role', 'Role')}
            {getHeaderTableCell('lastLogin', 'Last Log In')}
            {getHeaderTableCell('isBlocked', 'Blocked Status')}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {usersListQuery.data?.results?.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.firstName}</TableCell>
              <TableCell>{row.lastName}</TableCell>
              <TableCell>{row.emailAddress}</TableCell>
              <TableCell>{row.organisation}</TableCell>
              <TableCell>{row.role}</TableCell>

              <TableCell>
                {row.lastLogin
                  ? getUkDateStringFromJsonDateString(row.lastLogin)
                  : ''}
              </TableCell>
              <TableCell>{row.isBlocked ? 'Blocked' : 'Unblocked'}</TableCell>
              <TableCell align="right">
                {hasAdminUsers && (
                  <>
                    <IconButton
                      aria-label="edit"
                      name="edit"
                      onClick={event => onEllipsisClick(row.id, event)}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                    {selectedRowUserId === row.id ? (
                      <Menu
                        id={`admin-user-menu-${row.id}`}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem
                          onClick={() =>
                            handleClose(EAdminUsersMenuType.ViewEditUser)
                          }
                        >
                          View/Edit user
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleClose(EAdminUsersMenuType.BlockUser)
                          }
                        >
                          {row.isBlocked ? 'Unblock user' : 'Block user'}
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleClose(EAdminUsersMenuType.ViewActivityLog)
                          }
                        >
                          View Activity Log
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleClose(EAdminUsersMenuType.ResetPassword)
                          }
                        >
                          Reset password
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleClose(EAdminUsersMenuType.DeleteUser)
                          }
                        >
                          Delete User
                        </MenuItem>
                      </Menu>
                    ) : null}
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={usersListQuery.data?.count || 0}
              rowsPerPage={take}
              rowsPerPageOptions={[take]}
              page={currentPage}
              onPageChange={(e, newPage) => {
                const newSkip = newPage * take;
                dispatch(gridStateChanged({ skip: newSkip }));
              }}
              onRowsPerPageChange={e => {
                dispatch(
                  gridStateChanged({
                    take: +e.target.value,
                  })
                );
              }}
            />
          </TableRow>
        </TableFooter>
      </StyledGenericTable>
      {isAddEditUserDrawerShowing && selectedRowUserId && (
        <AddEditUserDrawer
          userId={selectedRowUserId}
          onSuccess={() => {
            setSelectedRowUserId(null);
            hideAddEditUserDrawer();
          }}
          onClose={() => {
            setSelectedRowUserId(null);
            hideAddEditUserDrawer();
          }}
        />
      )}
      {isToggleUserBlockDialogShowing && selectedRowUserId && (
        <ToggleUserBlockDialog
          userId={selectedRowUserId}
          onConfirm={() => {
            setSelectedRowUserId(null);
            hideToggleUserBlockDialog();
            usersListQuery.refetch();
          }}
          onCancel={() => {
            setSelectedRowUserId(null);
            hideToggleUserBlockDialog();
          }}
        />
      )}
      {isResetUserPasswordDialogShowing && selectedRowUserId && (
        <ResetUserPasswordDialog
          userId={selectedRowUserId}
          onConfirm={() => {
            setSelectedRowUserId(null);
            hideResetUserPasswordDialog();
          }}
          onCancel={() => {
            setSelectedRowUserId(null);
            hideResetUserPasswordDialog();
          }}
        />
      )}
      {isDeleteUserDialogShowing && selectedRowUserId && (
        <DeleteUserDialog
          userId={selectedRowUserId}
          onConfirm={() => {
            setSelectedRowUserId(null);
            hideDeleteUserDialog();
            usersListQuery.refetch();
          }}
          onCancel={() => {
            setSelectedRowUserId(null);
            hideDeleteUserDialog();
          }}
        />
      )}
    </>
  );
}
