import { SxProps, Typography } from '@mui/material';

export const CardTitle = ({
  title,
  fontSize = '1em',
  sx,
}: {
  title: string;
  fontSize?: string;
  sx?: SxProps;
}) => {
  return (
    <Typography variant="h3" fontWeight={600} fontSize={fontSize} sx={sx}>
      {title}
    </Typography>
  );
};
