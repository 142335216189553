import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography } from '@mui/material';

export function VendorPaymentApprovalWaitingBanner() {
  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 1,
        bgcolor: 'grey.100',
      }}
    >
      <Box>
        <Stack direction="row" spacing={2}>
          <FontAwesomeIcon
            icon={faExclamation}
            size="xs"
            fixedWidth
            style={{
              marginLeft: '0.3rem',
              background: 'rgba(255, 0, 0, 0.22)',
              borderRadius: '22px',
              padding: '3px',
              color: '#d32f2f',
              width: '10px',
              height: '10px',
            }}
          />
          <Box>
            <Typography fontSize="1em" fontWeight={700} color="black">
              Vendor Payment Approval Waiting
            </Typography>
            <Typography>
              Please complete the vendor payment approval process, and then you
              can continue with the payment
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
