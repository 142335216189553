import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { TAddBuildingRecordAddressInfo } from 'types/pullInProcess/addBuildingRecordTypes';
import { pullInProcessQueryTags } from 'util/ApiUtils';
import { customPrepareHeaders } from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/pullinprocess`;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
  credentials: 'include',
});

export const buildingRecordsAddressApi = createApi({
  reducerPath: 'buildingRecordsAddressApi',
  baseQuery,
  tagTypes: [pullInProcessQueryTags.GetAddress],
  endpoints: builder => ({
    getAddressByPostCode: builder.query<
      TAddBuildingRecordAddressInfo,
      { postCode: string; isBuildingLookup: boolean }
    >({
      providesTags: [pullInProcessQueryTags.GetAddress],
      query: ({ postCode, isBuildingLookup = true }) =>
        `${process.env.REACT_APP_API_URL}/api/address/${postCode}?isBuildingLookup=${isBuildingLookup}`,
    }),
  }),
});

export const { useLazyGetAddressByPostCodeQuery } = buildingRecordsAddressApi;
