import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  MenuItem,
  DialogActions,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useCreateSupportTicketMutation } from 'api/application';
import { useResolveSupportRequestMutation } from 'api/application/applicationSupportApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { useModalState, useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  INewSupportRequestData,
  IResolveSupportForm,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { useResolutionReasons } from 'pages/applicationPage/content/support/supportHooks';

interface IShowResolveModalConfig {
  config:
    | {
        type: 'existingTicket';
        supportTicketId: string;
      }
    | {
        type: 'newTicket';
        newTicketData: INewSupportRequestData;
        onSuccess: () => void;
      };
}

const _resolveFormId = 'resolve-form';

export const useResolveSupportRequestModal = () => {
  const {
    isShowing: isResolveModalShowing,
    showModal,
    hideModal,
  } = useModalState();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();
  const { applicationId } = useApplicationContext();
  const { options: resolutionOptions } = useResolutionReasons();
  const [resolve, resolveStatus] = useResolveSupportRequestMutation();
  const [createSupportRequest, createSupportRequestStatus] =
    useCreateSupportTicketMutation();
  const [params, setParams] = useState<IShowResolveModalConfig | null>(null);

  const form = useForm<IResolveSupportForm>({
    defaultValues: {
      supportTicketResolutionReasonId: '',
      resolutionDescription: '',
    },
  });
  const { handleSubmit, control, reset } = form;

  const showResolveModal = (params: IShowResolveModalConfig) => {
    setParams(params);
    showModal();
  };

  const hideResolveModal = () => {
    setParams(null);
    reset();
    hideModal();
  };

  const onSubmit = async (values: IResolveSupportForm) => {
    if (!params) {
      createErrorSnackbar(
        'Could not get configuration. Please try again later.'
      );
      return;
    }

    const { config } = params;

    if (config.type === 'existingTicket') {
      resolve({
        applicationId,
        supportTicketId: config.supportTicketId,
        values,
      })
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Support request has been resolved`);
          hideResolveModal();
          reset();
        });
    }

    if (config.type === 'newTicket') {
      // If this is a new ticket then we first need to create the ticket
      // Then get the supportTicketId from the response and use that to resolve
      createSupportRequest(config.newTicketData)
        .unwrap()
        .then(payload => {
          // The support request was created ok, now resolve it

          resolve({
            applicationId,
            supportTicketId: payload.supportTicketId,
            values,
          })
            .unwrap()
            .then(payload => {
              createSuccessSnackbar(`Support request has been resolved`);
              hideResolveModal();
              reset();
              config.onSuccess();
            });
        })
        .catch(err => {
          createErrorSnackbar(
            'Something went wrong creating the support request. Please try again later.'
          );
        });
    }
  };

  const renderResolveModal = () => {
    const isLoading =
      resolveStatus.isLoading || createSupportRequestStatus.isLoading;
    return (
      <Dialog open={isResolveModalShowing} fullWidth maxWidth="md">
        <DialogTitle>Resolve Support Request</DialogTitle>
        <DialogContent>
          <Box pt={3}>
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ width: '100%' }}
                id={_resolveFormId}
              >
                {resolutionOptions.length > 0 ? (
                  <Controller
                    control={control}
                    name="supportTicketResolutionReasonId"
                    rules={{ required: 'Resolution reason is required' }}
                    render={({ field, formState: { errors } }) => (
                      <TextField
                        {...field}
                        select
                        fullWidth
                        label="Reason for resolving"
                        error={Boolean(errors.supportTicketResolutionReasonId)}
                        helperText={
                          errors.supportTicketResolutionReasonId?.message
                        }
                      >
                        {resolutionOptions.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ) : null}

                <Controller
                  control={control}
                  name="resolutionDescription"
                  rules={{
                    required: 'Resolution description is required',
                    maxLength: {
                      value: 1000,
                      message: '1000 characters is the maximum allowed.',
                    },
                  }}
                  render={({ field, formState: { errors } }) => (
                    <TextField
                      {...field}
                      multiline
                      fullWidth
                      rows={5}
                      label="How was the request resolved?"
                      error={!!errors.resolutionDescription}
                      helperText={errors.resolutionDescription?.message}
                    />
                  )}
                />
              </form>
            </FormProvider>
            <AlertForQueryErrorOrNull
              isError={resolveStatus.isError}
              error={resolveStatus.error}
            />
            <AlertForQueryErrorOrNull
              isError={createSupportRequestStatus.isError}
              error={createSupportRequestStatus.error}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="outlined"
            onClick={hideResolveModal}
            loading={isLoading}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            form={_resolveFormId}
            loading={isLoading}
          >
            Resolve
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  return {
    showResolveModal,
    renderResolveModal,
  };
};
