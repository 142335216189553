import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useGetWorksPackageDeedCounterSignedStatusQuery,
  useUpdateWorksPackageDeedCounterSignedStatusMutation,
} from 'api/application/worksPackageDeedApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { FormSkeleton } from 'common/components/skeletons';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { Intro } from 'pages/applicationPage/content/payments/common';
import { IWorksPackageDeedCounterSignedStatusForm } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection/WorksPackageDeedTypes';
import { InformationCard } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection/common';
import { useWorksPackageDeedContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection/context';

export const DeedConfirmationUploadSectionCounterSigned = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetWorksPackageDeedCounterSignedStatusQuery(applicationId);

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isSuccess) {
    const initialData: IWorksPackageDeedCounterSignedStatusForm = {
      counterSigned: data?.workPackageDeedCounterSigned ?? false,
      counterSignedFile: data?.counterSignedWorkPackageDeedFile,
      counterSignedFileId: data?.counterSignedWorkPackageDeedFile?.id ?? '',
    };

    return <DeedConfirmationUploadSectionMain initialFormData={initialData} />;
  }

  return <AlertForQueryErrorOrNull isError={isError} error={error} />;
};

const _fileInputName = 'counterSignedFile';

const DeedConfirmationUploadSectionMain = ({
  initialFormData,
}: {
  initialFormData: IWorksPackageDeedCounterSignedStatusForm;
}) => {
  const { applicationId } = useApplicationContext();
  const { readOnly } = useWorksPackageDeedContext();
  const { data } = useGetWorksPackageDeedCounterSignedStatusQuery(applicationId);

  const isFileSent = data?.workPackageDeedCounterSigned;
  const [updateStatus, updateStatusResult] =
    useUpdateWorksPackageDeedCounterSignedStatusMutation();

  const { createSuccessSnackbar } = useLocalSnackbar();

  const { uploadFile, uploadFileStatus } = useFileUpload();

  const form = useForm<IWorksPackageDeedCounterSignedStatusForm>({
    defaultValues: initialFormData,
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting },
  } = form;

  const [
    watchworksPackageDeedCounterSignedLocal,
    watchworksPackageDeedCounterSignedEvidenceFile,
  ] = watch(['counterSigned', 'counterSignedFile']);

  const onSubmit = async () => {
    const formData = getValues();

    await updateStatus({
      counterSignedFileId: formData.counterSignedFileId,
      counterSigned: formData.counterSigned,
      applicationId,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Status updated');
        reset({}, { keepValues: true });
      });
  };

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'WorksPackageDeedInfoCounterSigned',
      onSuccess: payload => {
        form.setValue(`counterSignedFileId`, payload.id);
        form.setValue(_fileInputName, payload);
      },
    });
  };

  const handleOnDeleteFileUpload = async () => {
    form.setValue(`counterSignedFileId`, '');
    form.setValue(_fileInputName, null);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={4} p={4}>
          <Grid item xs={8} display={'flex'} flexDirection={'column'} gap={3}>
            <LeavePageGuard
              blockNavigationIf={isDirty && !isSubmitting}
              message="Are you sure you want to leave? Any unsaved changes to the send works package section will be lost."
            />

            <Box>
              <Box>
                <Intro heading="Has the works package deed been counter signed by all parties?" />
              </Box>

              <Box bgcolor="grey.100" p={2} mb={3} mt={1}>
                <Controller
                  control={control}
                  name={'counterSigned'}
                  render={({ field: { onChange, value, ...fieldProps } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...fieldProps}
                          checked={value || false}
                          onChange={(_, val) => {
                            if (val !== null) {
                              onChange(val);
                            }
                          }}
                          disabled={readOnly}
                        />
                      }
                      label="The works package deed has been counter signed"
                    />
                  )}
                />
              </Box>
            </Box>

            {watchworksPackageDeedCounterSignedLocal ? (
              <Controller
                control={control}
                name={'counterSignedFileId'}
                rules={{
                  required:
                    'Evidence that the file has been counter signed is required.',
                }}
                render={props => {
                  return (
                    <Box>
                      <Intro
                        heading={
                          props.field.value
                            ? 'Evidence of file being sent'
                            : 'Upload evidence'
                        }
                      />
                      {props.field.value &&
                      watchworksPackageDeedCounterSignedEvidenceFile?.id &&
                      watchworksPackageDeedCounterSignedEvidenceFile?.id ===
                        data?.counterSignedWorkPackageDeedFile?.id ? (
                        <Box display="flex" flexDirection="row">
                          <ApplicationFileDownloadWrapper
                            fileName={
                              watchworksPackageDeedCounterSignedEvidenceFile?.name ??
                              ''
                            }
                            fileId={
                              watchworksPackageDeedCounterSignedEvidenceFile?.id ?? ''
                            }
                            fileWrapperStyle={{ mb: 0 }}
                          />
                          {!readOnly ? (
                            <IconButton
                              size="small"
                              onClick={() => handleOnDeleteFileUpload()}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          ) : null}
                        </Box>
                      ) : (
                        <>
                          {!isFileSent ? (
                            <>
                              <FileUploadInputSelect
                                id={_fileInputName}
                                inputName={_fileInputName}
                                label="Click to upload or drag and drop file (max. 30MB)"
                                accept=".pdf,.docx,.xlsx,.msg,.eml,.jpg,.jpeg,.png"
                                onFileUpload={file => handleOnFileUpload(file)}
                                onFileDelete={() => handleOnDeleteFileUpload()}
                                isUploading={uploadFileStatus.isLoading}
                                uploaded={uploadFileStatus.isSuccess}
                              />

                              {props.fieldState.error && (
                                <FormHelperText error>
                                  {props.fieldState.error.message}
                                </FormHelperText>
                              )}
                            </>
                          ) : null}
                        </>
                      )}
                    </Box>
                  );
                }}
              />
            ) : null}

            <AlertForQueryErrorOrNull
              isError={updateStatusResult.isError}
              error={updateStatusResult.error}
            />
          </Grid>
          <Grid item xs={4}>
            <InformationCard />
          </Grid>
        </Grid>
        {!readOnly && (
          <Grid container mt={10} spacing={1}>
            <Grid xs={12} item>
              <Box
                display="flex"
                justifyContent="flex-end"
                p={3}
                bgcolor="grey.100"
              >
                <Box gap={2} display="flex">
                  <LoadingButton
                    loading={false}
                    onClick={() => reset()}
                    variant="outlined"
                  >
                    Cancel
                  </LoadingButton>

                  <LoadingButton
                    loading={updateStatusResult.isLoading}
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </form>
    </FormProvider>
  );
};
