import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { QueryTags } from 'util/ApiUtils';
import { customFetchBaseQuery } from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/admin`;

export const administrationApi = createApi({
  reducerPath: 'administrationApi',
  baseQuery: customFetchBaseQuery(baseUrl),
  tagTypes: [
    QueryTags.User,
    QueryTags.Users,
    QueryTags.Role,
    QueryTags.Roles,
    QueryTags.SystemNotifications,
    QueryTags.PTFS,
    QueryTags.AssessorPanelList,
    QueryTags.BenchmarkFigures,
  ],
  endpoints: () => ({}),
});
