import { ReactNode } from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useGetCurrentBenchmarkFiguresQuery } from 'api/administration/benchmarkFigures';
import { Loading } from 'common/components/loading/Loading';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { EditBenchmarkFiguresDrawer } from 'pages/administrationPage/components/administrationBenchmarkFiguresTab/components/EditBenchmarkFiguresDrawer';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export function AdministrationBenchmarkFiguresCurrent() {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();

  const canEditBenchmarkFigures = doesUserHaveSinglePermission(
    'admin.benchmark-figures.edit'
  );

  const { data, isLoading } = useGetCurrentBenchmarkFiguresQuery();

  const {
    isShowing: isEditBenchmarkFiguresDrawerShowing,
    showModal: showEditBenchmarkFiguresDrawer,
    hideModal: hideEditBenchmarkFiguresDrawer,
  } = useModalState();

  return (
    <>
      <Loading isOpen={isLoading} />

      {data && (
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={3} mt={2}>
                <Typography variant="body2" fontWeight="bold" align="center">
                  Full Remediation
                </Typography>
              </Grid>
              <Grid item xs={3} mt={2}>
                <Typography variant="body2" fontWeight="bold" align="center">
                  Partial Remediation
                </Typography>
              </Grid>
              <Grid item xs={3} mt={2}>
                <Typography variant="body2" fontWeight="bold" align="center">
                  Mitigation
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {canEditBenchmarkFigures && (
                  <Tooltip
                    id="button-edit-benchmark-figures"
                    title="Edit benchmark figures"
                  >
                    <IconButton
                      size="medium"
                      aria-label="Edit benchmark figures"
                      name="Edit benchmark figures"
                      onClick={() => showEditBenchmarkFiguresDrawer()}
                    >
                      <FontAwesomeIcon icon={faPen} size="xs" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="body2" fontWeight="bold">
                  <AdministrationBenchmarkFiguresRowIndicatorItem name="18+" />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <AdministrationBenchmarkFigureItem
                  value={data?.eighteenPlusFullRemediation}
                  lastEdited={data?.eighteenPlusFullRemediationDate}
                />
              </Grid>
              <Grid item xs={3}>
                <AdministrationBenchmarkFigureItem
                  value={data?.eighteenPlusPartialRemediation}
                  lastEdited={data?.eighteenPlusPartialRemediationDate}
                />
              </Grid>
              <Grid item xs={3}>
                <AdministrationBenchmarkFigureItem
                  value={data?.eighteenPlusMitigationRemediation}
                  lastEdited={data?.eighteenPlusMitigationRemediationDate}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="body2" fontWeight="bold">
                  <AdministrationBenchmarkFiguresRowIndicatorItem name="11-18" />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <AdministrationBenchmarkFigureItem
                  value={data?.underEighteenFullRemediation}
                  lastEdited={data?.underEighteenFullRemediationDate}
                />
              </Grid>
              <Grid item xs={3}>
                <AdministrationBenchmarkFigureItem
                  value={data?.underEighteenPartialRemediation}
                  lastEdited={data?.underEighteenPartialRemediationDate}
                />
              </Grid>
              <Grid item xs={3}>
                <AdministrationBenchmarkFigureItem
                  value={data?.underEighteenMitigationRemediation}
                  lastEdited={data?.underEighteenMitigationRemediationDate}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {isEditBenchmarkFiguresDrawerShowing && (
        <EditBenchmarkFiguresDrawer
          onSuccess={() => {
            hideEditBenchmarkFiguresDrawer();
          }}
          onClose={() => {
            hideEditBenchmarkFiguresDrawer();
          }}
        />
      )}
    </>
  );
}

const AdministrationBenchmarkFigureItemWrapper = ({
  darkBackground = false,
  children,
}: {
  darkBackground?: boolean;
  children: ReactNode;
}) => {
  const theme = useTheme();
  return (
    <Grid>
      <Box
        sx={{
          background: darkBackground
            ? theme.palette.grey[200]
            : theme.palette.common.white,
          borderRadius: 1,
          border: 1,
          borderColor: theme.palette.divider,
          padding: 2,
          margin: 1,
          textAlign: 'center',
          height: '100%',
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

const AdministrationBenchmarkFiguresRowIndicatorItem = ({
  name,
}: {
  name: string;
}) => {
  return (
    <AdministrationBenchmarkFigureItemWrapper darkBackground={true}>
      <Typography variant="widgetNumber">{name}</Typography>
      <Typography mt="0.25rem">Metres</Typography>
    </AdministrationBenchmarkFigureItemWrapper>
  );
};

const AdministrationBenchmarkFigureItem = ({
  value,
  lastEdited,
}: {
  value: number;
  lastEdited: string;
}) => {
  return (
    <AdministrationBenchmarkFigureItemWrapper>
      <Typography variant="widgetNumber">
        {`£${value?.toLocaleString()}`}
      </Typography>
      <Typography mt="0.25rem">
        {`Last edited ${getUkDateStringFromJsonDateString(lastEdited)}`}
      </Typography>
    </AdministrationBenchmarkFigureItemWrapper>
  );
};
