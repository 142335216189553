import { Grid, Stack } from '@mui/material';
import { useGetProjectPrepOverviewQuery } from 'api/application/projectPrepApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  CardTitle,
  OverviewSummaryItem,
} from 'pages/applicationPage/content/projectPrep/common';
import { ListView } from 'pages/applicationPage/content/projectPrep/sections/OverviewSection/ListView';

export const OverviewSection = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, isError, error, data } =
    useGetProjectPrepOverviewQuery(applicationId);

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={3}>
          <RoundBorderBox sx={{ p: 2, mb: 2 }}>
            <CardTitle title="Project Set-up" fontSize="1.2em" />
          </RoundBorderBox>

          <RoundBorderBox sx={{ p: 2 }}>
            <CardTitle title="Project Details" sx={{ mb: 3 }} />

            {isLoading ? <FormSkeleton /> : null}

            {isSuccess ? (
              <Stack gap={2}>
                <OverviewSummaryItem
                  title="Open support requests"
                  value={data.openSupportRequests.toString()}
                />
              </Stack>
            ) : null}
          </RoundBorderBox>
        </Grid>
        <Grid item xs={9}>
          <ListView />
        </Grid>
      </Grid>
    </>
  );
};
