import { ReactNode } from 'react';
import { Typography } from '@mui/material';

export const ProjectTeamValue = ({ children }: { children: ReactNode }) => {
  return (
    <Typography fontSize={'0.9em'} fontWeight={600} component={'div'}>
      {children}
    </Typography>
  );
};
