import { ReactNode, createContext, useContext } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useTheme } from '@mui/material';
import { IApplicationDetailsItemGroup } from 'pages/applicationPage/common/types';

interface IApplicationItemGroupNavigatorContext {
  overrideSelected: boolean;
}

const ApplicationItemGroupNavigatorContext = createContext<
  IApplicationItemGroupNavigatorContext | undefined
>(undefined);

const ApplicationItemGroupNavigatorContextProvider = ({
  overrideSelected,
  children,
}: {
  overrideSelected: boolean;
  children: ReactNode;
}) => {
  return (
    <ApplicationItemGroupNavigatorContext.Provider value={{ overrideSelected }}>
      {children}
    </ApplicationItemGroupNavigatorContext.Provider>
  );
};

export const useApplicationItemGroupNavigatorContext = () => {
  const context = useContext(ApplicationItemGroupNavigatorContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationItemGroupNavigatorContext must be used within an ApplicationItemGroupNavigatorContextProvider'
    );
  }
  return context;
};

interface IApplicationItemGroupNavigatorProps {
  overrideSelected?: boolean;
  itemGroups: IApplicationDetailsItemGroup[];
}

export const ApplicationItemGroupNavigator = ({
  itemGroups,
  overrideSelected = false,
}: IApplicationItemGroupNavigatorProps) => {
  return (
    <ApplicationItemGroupNavigatorContextProvider
      overrideSelected={overrideSelected}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        {itemGroups.map(itemGroup => (
          <ItemGroup key={itemGroup.title} {...itemGroup} />
        ))}
      </Box>
    </ApplicationItemGroupNavigatorContextProvider>
  );
};

const ItemGroup = ({
  title,
  items,
  isEnabled = true,
}: IApplicationDetailsItemGroup) => {
  return (
    <Box>
      {!!title ? (
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="1em"
          color={!isEnabled ? 'grey.400' : 'inherit'}
        >
          {title}
        </Typography>
      ) : null}
      <Box display="flex" flexDirection="column" gap={1.5}>
        {items.map(item => (
          <GroupItem key={item.title} {...item} isEnabled={isEnabled} />
        ))}
      </Box>
    </Box>
  );
};

type GroupItemProps = IApplicationDetailsItemGroup['items'][number] &
  Pick<IApplicationDetailsItemGroup, 'isEnabled'>;

const GroupItem = ({
  title,
  isComplete,
  isSelected,
  isEnabled = true,
  onClick,
}: GroupItemProps) => {
  const theme = useTheme();
  const { overrideSelected } = useApplicationItemGroupNavigatorContext();
  const isItemSelected = !overrideSelected && isSelected;
  return (
    <Box
      display="flex"
      role="button"
      justifyContent="space-between"
      p={1.5}
      border={2}
      borderRadius={0.75}
      borderColor={isItemSelected ? 'grey.900' : 'divider'}
      bgcolor={isItemSelected ? 'white' : 'grey.100'}
      sx={{
        cursor: isEnabled ? 'pointer' : 'default',
        ...(isEnabled
          ? {
              ':hover': {
                bgcolor: 'white',
              },
            }
          : null),
        transition: 'all 250ms linear',
      }}
      onClick={onClick}
    >
      <Box flexBasis="90%">
        <Typography
          fontSize="0.9em"
          color={!isEnabled ? 'grey.700' : 'inherit'}
        >
          {title}
        </Typography>
      </Box>
      {isComplete !== undefined ? (
        <Box display="flex" alignItems="center">
          <FontAwesomeIcon
            icon={isComplete ? solid('check-circle') : solid('circle')}
            color={
              isComplete ? '#2e7d32' : theme.palette.grey[700]
            }
            size="lg"
          />
        </Box>
      ) : null}
    </Box>
  );
};
