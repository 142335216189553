import {
  ESupportTicketAreaType,
  ESupportTicketContactMethod,
  ESupportTicketDetailType,
  ESupportTicketStatus,
  ESupportTicketType,
  IReferToRemediationAdviser,
  IReferralType,
  IResolutionReason,
  ISupportApplicationSummary,
  ISupportListItem,
  ISupportRemediationAdviser,
  ISupportTicketInfo,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { getGuid } from 'util/AppUtils';

export const applicationSupportListData: ISupportListItem[] = [
  {
    description: 'abcdef',
    id: getGuid(),
    referenceNumber: 223344,
    startDate: '2023-07-10 10:00:00',
    status: ESupportTicketStatus.ToDo,
    supportTicketType: ESupportTicketType.ProgressReport,
    remediationThirdPartyType: '',
    cost: 1345,
  },
  {
    description: 'lorem ipsum',
    id: getGuid(),
    referenceNumber: 223345,
    startDate: '2023-07-11 10:00:00',
    status: ESupportTicketStatus.ToDo,
    supportTicketType: ESupportTicketType.ProgressReport,
    remediationThirdPartyType: '',
    cost: 4223,
  },
  {
    description: 'lorem ipsum some more stuff',
    id: getGuid(),
    referenceNumber: 223347,
    startDate: '2023-07-11 10:00:00',
    status: ESupportTicketStatus.AppointedRemediationAdviser,
    supportTicketType: ESupportTicketType.ProgressReport,
    remediationThirdPartyType: '',
  },
];

export const applicationSupportListTicketInfo: ISupportTicketInfo = {
  id: getGuid(),
  referenceNumber: 855673,
  description: 'The building is falling down',
  status: ESupportTicketStatus.ReferredToHomesEngland,
  applicantPhone: '+44776534562',
  applicantEmail: 'roycropper123@hotmail.com',
  raisedByHomesEngland: false,
  supportAreas: [
    {
      supportAreaType: ESupportTicketAreaType.LeadDesigner,
      reason: 'Permission is needed',
    },
  ],
  details: [
    {
      contactMethod: ESupportTicketContactMethod.Phone,
      doesApplicantHaveCapacityGap: true,
      description: 'Referred to RA',
      supportDetailType: ESupportTicketDetailType.RemediationAdviserAssigned,
      timestamp: new Date().toISOString(),
      user: {
        id: getGuid(),
        firstName: 'Roy',
        lastName: 'Cropper',
      },
      remediationAdviser: 'Ray Purchase',
      remediationThirdPartyType: 'CSSP',
      remediationAdviserContactNumber: '07891 787877',
      remediationAdviserEmailAddress: 'ray@purchasewithalongdomainname.com',
      remediationCompany: 'Raymondos Ltd',
      remediationCost: 45123,
      remediationEndDate: '2023-08-01',
      remediationStartDate: '2023-07-23',
    },
    {
      contactMethod: ESupportTicketContactMethod.Phone,
      doesApplicantHaveCapacityGap: true,
      description: 'Looking for money to fix it up',
      supportDetailType: ESupportTicketDetailType.Referred,
      timestamp: new Date().toISOString(),
      resolutionReason: 1,
      resolutionDescription: 'The money was paid',
      user: {
        id: getGuid(),
        firstName: 'Roy',
        lastName: 'Cropper',
      },
      evidenceFile: {
        id: getGuid(),
        name: 'Building file',
      },
    },
  ],
};

export const applicationDetails: ISupportApplicationSummary = {
  applicantName: 'Clem Fandango',
  emailAddress: 'clem@fandango.com',
  contactNumber: '07711 112233',
  buildingAddress: {
    nameNumber: 'Soho Studios',
    addressLine1: '1 Soho Square',
    county: 'Londonshire',
    postcode: 'SW1 1WS',
    townCity: 'London',
    country: '',
    localAuthority: 'London LA',
  },
  responsibleEntityType: 'Company',
};

export const applicationSupportReferralTypes: IReferralType[] = [
  {
    id: 1,
    name: 'Cost consultant',
  },
  {
    id: 2,
    name: 'FRAEW Assessor',
  },
  {
    id: 3,
    name: 'FRAEW Audit',
  },
  {
    id: 4,
    name: 'FRAEW Hardship Cases',
  },
  {
    id: 5,
    name: 'Legal',
  },
  {
    id: 6,
    name: 'CSSP',
  },
];

export const applicationSupportRemediationAdvisers: ISupportRemediationAdviser[] =
  [
    {
      id: 1,
      firstName: 'Lee',
      lastName: 'Durnford',
      company: 'Mott MacDonald',
      emailAddress: 'Lee.Durnford@mottmac.com',
      telephone: '07949788052',
    },
    {
      id: 2,
      firstName: 'Oliver',
      lastName: 'Straw',
      company: 'Gleeds',
      emailAddress: 'Oliver.Straw@gleeds.com ',
      telephone: '07791 998901',
    },
    {
      id: 3,
      firstName: 'Matthew',
      lastName: 'Stringer',
      company: 'Ridge and Partners',
      emailAddress: 'matthewstringer@ridge.co.uk',
      telephone: '07740 423045',
    },
    {
      id: 4,
      firstName: 'Sam',
      lastName: 'Lambert',
      company: 'Turner and Townsend',
      emailAddress: 'Sam.Lambert@turntown.co.uk',
      telephone: '07977 396842 ',
    },
  ];

export const applicationSupportResolutionReasons: IResolutionReason[] = [
  {
    id: 1,
    name: 'Referred to guidance',
  },
  {
    id: 2,
    name: 'Answered from previous escalations',
  },
  {
    id: 3,
    name: 'Request still in progress',
  },
  {
    id: 4,
    name: 'Withdrawn',
  },
  {
    id: 5,
    name: 'Decline',
  },
  {
    id: 6,
    name: 'Other',
  },
];

export const applicationSupportRemediationAdviserReferral: IReferToRemediationAdviser = {
  referralTypeId: 1,
  remediationAdviserId: 1,
  remediationAdviserStartDate: '2024-01-01',
  remediationAdviserEndDate: '2024-03-31',
  remediationAdviserCost: 999.99,
}
