import { useState } from 'react';
import { Box, Grid, Tabs, Typography } from '@mui/material';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { CustomTab } from 'common/components/customTab';
import { CustomTabPanel } from 'common/components/customTabPanel';
import { MainContentContainer } from 'common/components/mainContentContainer';
import { PageTitle } from 'common/components/pageTitle';
import { MyDetailsTab } from 'pages/myAccount/components/myDetailsTab';

const enum EMyAccountTabType {
  MyDetails = '1',
  MyActivity = '2',
}

export const MyAccountPage = () => {
  const [selectedTab, setSelectedTab] = useState<EMyAccountTabType>(
    EMyAccountTabType.MyDetails
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue as EMyAccountTabType);
  };
  return (
    <MainContentContainer>
      <Breadcrumbs
        crumbs={[{ text: 'My Account', url: undefined }] as IBreadcrumbItem[]}
      />

      <PageTitle title="My Account" />

      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <nav>
            <Tabs
              onChange={handleChange}
              aria-label="My Account Tabs"
              value={selectedTab}
            >
              <CustomTab
                label="My Details"
                value={EMyAccountTabType.MyDetails}
                currentSelectedTab={selectedTab}
              />
              <CustomTab
                label="My Activity"
                value={EMyAccountTabType.MyActivity}
                currentSelectedTab={selectedTab}
              />
            </Tabs>
          </nav>
        </Box>
        <Box>
          <CustomTabPanel
            currentSelectedTab={selectedTab}
            value={EMyAccountTabType.MyDetails}
            sx={{
              py: 3,
            }}
          >
            <MyDetailsTab />
          </CustomTabPanel>
          <CustomTabPanel
            currentSelectedTab={selectedTab}
            value={EMyAccountTabType.MyActivity}
            sx={{
              py: 3,
            }}
          >
            <Typography>My Activity</Typography>
          </CustomTabPanel>
        </Box>
      </Grid>
    </MainContentContainer>
  );
};
