import { ReactNode } from 'react';
import { Typography, Divider, Grid } from '@mui/material';
import { parseISO } from 'date-fns';
import { useGetworksPackageDeedDetailQuery } from 'api/application/worksPackageDeedApi';
import { FormSkeleton } from 'common/components/skeletons';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';
import { formatDateDayShortMonthYear } from 'util/DateUtils';

interface IDetailItemOptionalProps {
  title: string;
  label?: string;
  value?: string;
  children?: ReactNode;
}

const CardItem = (props: IDetailItemOptionalProps) => {
  const { title, label, value, children } = props;
  return (
    <ColumnContainer sx={{ padding: '5px' }} margin="5px">
      <Typography variant="h2" fontSize="1em" fontWeight={700} padding="16px">
        {title}
      </Typography>
      {label && value ? <DetailItem label={label} value={value} /> : null}
      {children}
    </ColumnContainer>
  );
};

export const InformationCard = () => {
  const { applicationId } = useApplicationContext();

  const { isLoading, isSuccess, data } =
    useGetworksPackageDeedDetailQuery(applicationId);

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isSuccess) {
    return (
      <>
        <CardItem title="Responsible Entity contacts">
          <DetailItem
            label={data.responsibleEntity?.name ?? '-'}
            value={data.responsibleEntity?.email ?? '-'}
          />
          <Divider />
          <Grid container>
            <Grid item xs={6}>
              <DetailItem
                label={'Date last checked'}
                value={
                  data.dateLastChecked
                    ? getUkDateStringFromJsonDateString(data.dateLastChecked)
                    : '-'
                }
              />
            </Grid>
            <Grid item xs={6}>
              <DetailItem
                label={'Signatory changes?'}
                value={data.signatoryChanges ? 'Yes' : 'No'}
              />
            </Grid>
          </Grid>
        </CardItem>
        <CardItem
          title="Representative"
          label={data.representative?.name ?? '-'}
          value={data.representative?.email ?? '-'}
        />
        <CardItem title="Project details">
          <Grid container>
            <Grid item xs={6}>
              <DetailItem
                label="Start Date"
                value={
                  data.projectDetail?.startDate
                    ? `${formatDateDayShortMonthYear(
                        parseISO(data.projectDetail.startDate)
                      )}`
                    : '-'
                }
              />
            </Grid>
            <Grid item xs={6}>
              <DetailItem
                label="End Date"
                value={
                  data.projectDetail?.endDate
                    ? `${formatDateDayShortMonthYear(
                        parseISO(data.projectDetail.endDate)
                      )}`
                    : '-'
                }
              />
            </Grid>
          </Grid>

          <Divider />
          <Grid container>
            <Grid item xs={6}>
              <DetailItem
                label="Duration"
                value={
                  data.projectDetail?.duration
                    ? data.projectDetail.duration
                    : '-'
                }
              />
            </Grid>
            <Grid item xs={6}>
              <DetailItem
                label="Approved Grant Amount"
                value={
                  data.projectDetail?.approvedGrantAmount
                    ? `£${data.projectDetail.approvedGrantAmount.toLocaleString()}`
                    : '-'
                }
              />
            </Grid>
          </Grid>
        </CardItem>
      </>
    );
  }

  return null;
};
