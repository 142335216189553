import { Breadcrumbs, Link, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export interface IBreadcrumbItem {
  text: string;
  url?: string;
}

export function BreadcrumbsBuilder({
  crumbs = [],
}: {
  crumbs: readonly IBreadcrumbItem[];
}) {
  return (
    <Breadcrumbs
      sx={{
        paddingTop: '25px',
      }}
    >
      <Link underline="hover" color="inherit" href="/">
        Home
      </Link>
      {crumbs.map((crumb, index) => {
        if (index !== crumbs.length - 1 && crumb.url !== undefined) {
          return (
            <Link
              key={uuidv4()}
              underline="hover"
              color="inherit"
              href={crumb.url}
            >
              {crumb.text}
            </Link>
          );
        } else {
          return (
            <Typography key={uuidv4()} color="inherit">
              {crumb.text}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
}
