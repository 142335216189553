import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { ErrorAlert } from 'common/components/alerts';
import { Loading } from 'common/components/loading';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { useLocalSnackbar, useModalState } from 'hooks';
import {
  ApplicationItemGroupNavigator,
  ColumnContainer,
  SectionProgress,
} from 'pages/applicationPage/common/components';
import { ApplicationEligibilitySectionOutlet } from 'pages/applicationPage/content/applicationEligibility/ApplicationEligibilitySectionOutlet';
import { ApplicationEligibilityReferrals } from 'pages/applicationPage/content/applicationEligibility/components';
import { ReferApplicationModal } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication/ReferApplicationModal';
import { ReferralsSection } from 'pages/applicationPage/content/applicationEligibility/components/ReferralsSection';
import {
  ApplicationEligibilityContextProvider,
  useApplicationEligibilityContext,
} from 'pages/applicationPage/content/applicationEligibility/context';
import {
  IApplicationEligibilityCategory,
  IApplicationEligibilityItem,
} from 'types/applications/ApplicationEligibilityTypes';
import { extractErrorMessages } from 'util/ApiUtils';

export const ApplicationEligibility = () => {
  return (
    <ApplicationEligibilityContextProvider>
      <ApplicationEligibilityMain />
    </ApplicationEligibilityContextProvider>
  );
};

export const ApplicationEligibilityMain = () => {
  const {
    selectedItemId,
    setSelectedItemId,
    applicationEligibilityCategoriesQuery,
    showReferApplicationDialog,
    handleShowReferApplicationDialog,
    isReferralsSectionShowing,
  } = useApplicationEligibilityContext();

  const { createInfoSnackbar } = useLocalSnackbar();

  const { isLoading, error, data } = applicationEligibilityCategoriesQuery;
  const {
    isShowing: isReferApplicationModalShowing,
    showModal: showReferApplicationModal,
    hideModal: hideReferApplicationModal,
  } = useModalState();

  useEffect(() => {
    if (showReferApplicationDialog) {
      showReferApplicationModal();
    } else {
      hideReferApplicationModal();
    }
  }, [
    hideReferApplicationModal,
    showReferApplicationDialog,
    showReferApplicationModal,
  ]);

  if (isLoading) {
    return <Loading isOpen />;
  }

  if (error) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (!data || data.categories.length <= 0) {
    return (
      <ErrorAlert msg="Could not get any application eligibility categories." />
    );
  }

  const stepsCompleted = data.categories.reduce<number>((counter, category) => {
    return (
      counter + category.detailItems.filter(item => item.isComplete).length
    );
  }, 0);

  // Confirm Eligibilty enabled if all other sections completed
  const isCategoryEnabled = (
    categories: IApplicationEligibilityCategory[],
    currentIndex: number
  ) => {
    if (categories[currentIndex]?.categoryTitle !== 'Confirm Eligibility') {
      return true;
    } else {
      let isEnabled = true;

      categories.forEach(category => {
        if (category.categoryTitle !== 'Confirm Eligibility') {
          const categoryCompleted = category.detailItems.every(
            item => item.isComplete
          );
          if (categoryCompleted === false) {
            isEnabled = false;
          }
        }
      });

      return isEnabled;
    }
  };

  const isSectionEnabled = (detailItem: IApplicationEligibilityItem) => {
    if (
      detailItem.sectionName === 'Companies House' ||
      detailItem.sectionName === 'PEP/Sanctions'
    ) {
      const veriphyCompleteStatus = data.categories
        .find(x => x.categoryTitle === 'Company/Organisation Checks')
        ?.detailItems.find(x => x.sectionName === 'Veriphy')?.isComplete;

      return veriphyCompleteStatus;
    }

    return true;
  };

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={3.5}>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <SectionProgress totalSteps={8} currentStep={stepsCompleted} />
          </ColumnContainer>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <Box p={2}>
              <ApplicationItemGroupNavigator
                overrideSelected={isReferralsSectionShowing}
                itemGroups={data.categories.map((category, categoryIndex) => {
                  const isEnabled = isCategoryEnabled(
                    data.categories,
                    categoryIndex
                  );
                  return {
                    title: category.categoryTitle,
                    isEnabled,
                    items: category.detailItems.map(detailItem => {
                      const sectionEnabled = isSectionEnabled(detailItem);
                      return {
                        title: detailItem.sectionName,
                        isSelected: selectedItemId === detailItem.id,
                        isComplete: detailItem.isComplete,

                        onClick: () => {
                          if (isEnabled && sectionEnabled) {
                            setSelectedItemId(detailItem.id);
                          } else {
                            if (
                              detailItem.sectionName === 'Companies House' ||
                              detailItem.sectionName === 'PEP/Sanctions'
                            ) {
                              createInfoSnackbar(
                                `You must complete the 'Veriphy' section first.`,
                                { preventDuplicate: true }
                              );
                            } else {
                              createInfoSnackbar(
                                `You must complete all other sections before you 'Confirm Eligibility'.`,
                                { preventDuplicate: true }
                              );
                            }
                          }
                        },
                      };
                    }),
                  };
                })}
              />
            </Box>
          </ColumnContainer>
          <ColumnContainer>
            <Box p={2}>
              <ApplicationEligibilityReferrals />
            </Box>
          </ColumnContainer>
        </Grid>
        <Grid item xs={8.5}>
          <ColumnContainer>
            <Box display={isReferralsSectionShowing ? 'none' : 'block'}>
              <ApplicationEligibilitySectionOutlet />
            </Box>

            <Box display={isReferralsSectionShowing ? 'block' : 'none'}>
              <ReferralsSection />
            </Box>
          </ColumnContainer>
        </Grid>
      </Grid>
      {isReferApplicationModalShowing ? (
        <ReferApplicationModal
          sectionType={EEligibilitySection.All}
          onConfirm={() => {
            handleShowReferApplicationDialog(false);
          }}
          onCancel={() => {
            handleShowReferApplicationDialog(false);
          }}
        />
      ) : null}
    </>
  );
};
