import { DialogContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import {
  costScheduleEditFormId,
  getAmountString,
} from 'common/components/costSchedule';
import {
  DescriptionField,
  EligibleCostField,
  FormActions,
  Label,
  OriginalDataAccordion,
  shouldValidateDescription,
} from 'common/components/costSchedule/forms/common';
import { ICostScheduleFormProps } from 'common/components/costSchedule/forms/types';
import { getCostScheduleDescription } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections';
import {
  ECostsScheduleSection,
  FieldLabels,
  IPreliminariesForm,
} from 'types/applications/ApplicationCostScheduleTypes';

export const PreliminariesForm = ({
  parentType,
  data,
  originalData,
  handleCancelClick,
  saveFn,
  saveStatus,
}: ICostScheduleFormProps<IPreliminariesForm>) => {
  const array = [data, originalData];
  const form = useForm<IPreliminariesForm>({
    defaultValues: {
      mainContractorPreliminariesAmount: getAmountString(
        data.mainContractorPreliminariesAmount
      ),
      mainContractorPreliminariesDescription: getCostScheduleDescription(
        array,
        'mainContractorPreliminariesDescription'
      ),
      accessAmount: getAmountString(data.accessAmount),
      accessDescription: getCostScheduleDescription(array, 'accessDescription'),
      overheadsAndProfitAmount: getAmountString(
        data.mainContractorOverheadAmount
      ),
      overheadsAndProfitDescription: getCostScheduleDescription(
        array,
        'mainContractorOverheadDescription'
      ),
      contractorContingenciesAmount: getAmountString(
        data.contractorContingenciesAmount
      ),
      contractorContingenciesDescription: getCostScheduleDescription(
        array,
        'contractorContingenciesDescription'
      ),
    },
  });
  const { handleSubmit, watch } = form;

  const [
    mainContractorPreliminariesAmount,
    accessAmount,
    mainContractorOverheadAmount,
    contractorContingenciesAmount,
  ] = watch([
    'mainContractorPreliminariesAmount',
    'accessAmount',
    'overheadsAndProfitAmount',
    'contractorContingenciesAmount',
  ]);

  const onSubmit = (formData: IPreliminariesForm) => {
    saveFn(formData);
  };

  return (
    <>
      <DialogContent>
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
            id={costScheduleEditFormId}
          >
            <Label
              value={
                FieldLabels[ECostsScheduleSection.Preliminaries].mainContractor
              }
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="mainContractorPreliminariesAmount"
              descriptionFieldName="mainContractorPreliminariesDescription"
            />

            <EligibleCostField
              fieldName="mainContractorPreliminariesAmount"
              originalAmount={data.mainContractorPreliminariesAmount}
              newAmount={mainContractorPreliminariesAmount}
            />

            <DescriptionField
              fieldName="mainContractorPreliminariesDescription"
              shouldValidate={shouldValidateDescription(
                parentType,
                data.mainContractorPreliminariesAmount,
                mainContractorPreliminariesAmount
              )}
            />

            <Label
              value={FieldLabels[ECostsScheduleSection.Preliminaries].access}
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="accessAmount"
              descriptionFieldName="accessDescription"
            />

            <EligibleCostField
              fieldName="accessAmount"
              originalAmount={data.accessAmount}
              newAmount={accessAmount}
            />

            <DescriptionField
              fieldName="accessDescription"
              shouldValidate={shouldValidateDescription(
                parentType,
                data.accessAmount,
                accessAmount
              )}
            />

            <Label
              value={FieldLabels[ECostsScheduleSection.Preliminaries].overheads}
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="mainContractorOverheadAmount"
              descriptionFieldName="mainContractorOverheadDescription"
            />

            <EligibleCostField
              fieldName="overheadsAndProfitAmount"
              originalAmount={data.mainContractorOverheadAmount}
              newAmount={mainContractorOverheadAmount}
            />

            <DescriptionField
              fieldName="overheadsAndProfitDescription"
              shouldValidate={shouldValidateDescription(
                parentType,
                data.mainContractorOverheadAmount,
                mainContractorOverheadAmount
              )}
            />

            <Label
              value={
                FieldLabels[ECostsScheduleSection.Preliminaries].contingencies
              }
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="contractorContingenciesAmount"
              descriptionFieldName="contractorContingenciesDescription"
            />

            <EligibleCostField
              fieldName="contractorContingenciesAmount"
              originalAmount={data.contractorContingenciesAmount}
              newAmount={contractorContingenciesAmount}
            />

            <DescriptionField
              fieldName="contractorContingenciesDescription"
              shouldValidate={shouldValidateDescription(
                parentType,
                data.contractorContingenciesAmount,
                contractorContingenciesAmount
              )}
            />
          </form>
        </FormProvider>

        <AlertForQueryErrorOrNull
          isError={saveStatus.isError}
          error={saveStatus.error}
        />
      </DialogContent>

      <FormActions
        originalValue={data.preliminariesTotal ?? 0}
        newValues={[
          mainContractorPreliminariesAmount,
          accessAmount,
          mainContractorOverheadAmount,
          contractorContingenciesAmount,
        ]}
        isSaving={saveStatus.isLoading}
        handleCancelClick={handleCancelClick}
      />
    </>
  );
};
