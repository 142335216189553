import { useSaveVariationOtherCostsValuesMutation } from 'api/application/variationApi';
import {
  ECostScheduleParentType,
  OtherCostsForm,
} from 'common/components/costSchedule/forms';
import { useFormWrapperDependencies } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection/forms';
import { IOtherForm } from 'types/applications/ApplicationCostScheduleTypes';

export const OtheFormWrapper = () => {
  const {
    handleUnwrappedSaveResult,
    queryParams,
    handleCancelClick,
    costScheduleData,
    previousCostScheduleData,
  } = useFormWrapperDependencies();

  const [save, saveStatus] = useSaveVariationOtherCostsValuesMutation();

  const saveFn = (formData: IOtherForm) => {
    if (!queryParams) {
      return false;
    }

    handleUnwrappedSaveResult(save({ ...queryParams, formData }).unwrap());
  };

  if (!costScheduleData) {
    return null;
  }

  return (
    <OtherCostsForm
      parentType={ECostScheduleParentType.Variation}
      data={costScheduleData}
      originalData={previousCostScheduleData}
      handleCancelClick={handleCancelClick}
      saveFn={saveFn}
      saveStatus={saveStatus}
      hasFraewDescription
    />
  );
};
