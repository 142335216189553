import {
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useGetJourneyTimelineQuery } from 'api/application';
import { Loading } from 'common/components/loading';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { getUkDateTimeStringFromJsonDateString } from 'util/AppUtils';

interface IApplicationTimeLineModalProps {
  onClose: () => void;
}

export function ApplicationTimeLineModal({
  onClose,
}: IApplicationTimeLineModalProps) {
  const { applicationId } = useApplicationContext();
  const { data, isLoading } = useGetJourneyTimelineQuery(applicationId);

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open fullWidth maxWidth="lg">
      <StyledDialogTitle>Timeline</StyledDialogTitle>
      <StyledDialogContent>
        <StyledGenericTable>
          <TableHead>
            <TableRow>
              <TableCell>Stage</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Duration (Status)</TableCell>
              <TableCell>Total Duration</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.stage}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell style={{ width: '15rem' }}>
                  {getUkDateTimeStringFromJsonDateString(row.timestamp)}
                </TableCell>
                <TableCell style={{ width: '10rem' }}>
                  {row.durationWorkingDays}
                </TableCell>
                <TableCell style={{ width: '10rem' }}>
                  {row.totalDurationWorkingDays}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledGenericTable>
      </StyledDialogContent>

      <StyledDialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
