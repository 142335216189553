import { applicationApi } from 'api/application/applicationApi';
import {
  IResolveSupportForm,
  ISupportListItem,
  ISupportTicketInfo,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { QueryTags } from 'util/ApiUtils';

export const supportEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getSupportRequestList: builder.query<ISupportListItem[], string>({
      providesTags: [QueryTags.ApplicationSupportList],
      query: applicationId => `${applicationId}/support/list`,
    }),
    getSupportTicketInfo: builder.query<
      ISupportTicketInfo,
      { applicationId: string; supportTicketId: string }
    >({
      providesTags: [QueryTags.ApplicationSupportTicket],
      query: ({ applicationId, supportTicketId }) =>
        `${applicationId}/support/${supportTicketId}`,
    }),

    referSupportRequest: builder.mutation<
      void,
      { applicationId: string; supportTicketId: string }
    >({
      invalidatesTags: [
        QueryTags.ApplicationSupportTicket,
        QueryTags.ApplicationSupportList,
      ],
      query: ({ applicationId, supportTicketId }) => {
        return {
          url: `${applicationId}/support/${supportTicketId}/refer`,
          method: 'POST',
          body: {},
        };
      },
    }),
    resolveSupportRequest: builder.mutation<
      void,
      {
        applicationId: string;
        supportTicketId: string;
        values: IResolveSupportForm;
      }
    >({
      invalidatesTags: [
        QueryTags.ApplicationSupportTicket,
        QueryTags.ApplicationSupportList,
      ],
      query: ({ applicationId, supportTicketId, values }) => {
        return {
          url: `${applicationId}/support/${supportTicketId}/resolve`,
          method: 'POST',
          body: values,
        };
      },
    }),
  }),
});

export const {
  useGetSupportRequestListQuery,
  useGetSupportTicketInfoQuery,
  useReferSupportRequestMutation,
  useResolveSupportRequestMutation,
} = supportEndpoints;
