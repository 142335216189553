import { Stack } from '@mui/material';
import { parseISO } from 'date-fns';
import {
  EVariationType,
  IVariationBoxProps,
  IVariationOverview,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { useEditTimescaleDrawer } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/TimescaleChangesBox/EditTimescaleDrawer';
import {
  Row,
  VariationOverviewBox,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/common';
import { isOfType } from 'util/AppUtils';
import { formatDateMonthYear } from 'util/DateUtils';

const getMonths = (durationInMonths: number) => {
  if (durationInMonths === 1) {
    return `${durationInMonths} month`;
  }
  return `${durationInMonths} months`;
};

export const TimescaleChangesBox = ({
  data,
  isEditable = false,
}: IVariationBoxProps) => {
  const variation = data.variations.find(isOfType(EVariationType.Timescale));

  const timescaleDrawer = useEditTimescaleDrawer({
    initialData: { dateEnd: variation?.completionDateVaried ?? '' },
    readonlyValues: {
      completionDateCurrent: variation?.completionDateCurrent,
      projectDurationMonthsVariance: variation?.projectDurationMonthsVariation,
      projectDurationMonthsVaried: variation?.projectDurationMonthsVaried,
    },
  });

  if (!variation) {
    return null;
  }

  return (
    <VariationOverviewBox
      title="Timescale changes"
      onEditClick={
        !isEditable
          ? undefined
          : () => {
              timescaleDrawer.showDrawer();
            }
      }
    >
      <TimescaleView variation={variation} />
      {timescaleDrawer.renderDrawer()}
    </VariationOverviewBox>
  );
};

const TimescaleView = ({
  variation,
}: {
  variation: Extract<
    IVariationOverview['variations'][number],
    { type: EVariationType.Timescale }
  >;
}) => {
  return (
    <Stack gap={1}>
      {[
        variation.completionDateCurrent
          ? [
              'Current completion date',
              formatDateMonthYear(parseISO(variation.completionDateCurrent)),
            ]
          : [],
        variation.completionDateVaried
          ? [
              'Varied completion date',
              formatDateMonthYear(parseISO(variation.completionDateVaried)),
            ]
          : [],
        variation.projectDurationMonthsVaried
          ? [
              'Varied project duration',
              getMonths(variation.projectDurationMonthsVaried),
            ]
          : [],
        variation.projectDurationMonthsVariation
          ? ['Variance', getMonths(variation.projectDurationMonthsVariation)]
          : [],
      ].map(([label, value], i) => {
        if (!label) {
          return null;
        }

        return <Row key={i} label={label} value={value} />;
      })}
    </Stack>
  );
};
