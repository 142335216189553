import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateVariationTypesMutation } from 'api/application/variationApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { useLocalSnackbar, useModalState } from 'hooks';
import {
  EVariationType,
  EVariationTypeLanguage,
  IEditVariationTypesForm,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { useApplicationAndVariationIdOrNull } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';

const _formId = 'edit-types-form';

const options = [
  EVariationType.Scope,
  EVariationType.Timescale,
  EVariationType.Cost,
] as const;

export const useEditTypesDrawer = ({
  initialTypes,
}: {
  initialTypes: EVariationType[];
}) => {
  const queryParams = useApplicationAndVariationIdOrNull();

  const {
    isShowing,
    showModal: showDrawer,
    hideModal: hideDrawer,
  } = useModalState();

  const form = useForm<IEditVariationTypesForm>({
    defaultValues: {
      types: initialTypes,
    },
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = form;

  const { createErrorSnackbar, createSuccessSnackbar } = useLocalSnackbar();

  const handleShowDrawer = () => {
    showDrawer();
  };

  const handleCancelClick = () => {
    hideDrawer();
    reset();
  };

  const [updateVariationType, updateVariationTypeStatus] =
    useUpdateVariationTypesMutation();

  const onSubmit = async (formData: IEditVariationTypesForm) => {
    if (!queryParams) {
      createErrorSnackbar(
        'Could not get variation data. Please try again later.'
      );
      return;
    }

    updateVariationType({
      ...queryParams,
      formData,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar(
          'The variation type has been successfully edited'
        );
        hideDrawer();
        reset(formData);
      });
  };

  const renderDrawer = () => {
    if (!isShowing) {
      return null;
    }

    return (
      <>
        <StyledDrawer anchor="right" open={isShowing}>
          <Box>
            <DialogTitle component="div">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h1" component="span">
                  Edit Variation Type
                </Typography>
                <IconButton
                  onClick={handleCancelClick}
                  aria-label="Close Drawer"
                  name="Close Drawer"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Typography variant="h3">Variation Type</Typography>

              <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack gap={2}>
                  {options.map((option, index) => (
                    <RoundBorderBox
                      key={index}
                      sx={{ backgroundColor: 'grey.100', p: 1.5 }}
                    >
                      <Controller
                        control={control}
                        name={'types'}
                        render={({
                          field: { onChange, value, ...fieldProps },
                        }) => (
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...fieldProps}
                                  checked={value.includes(option)}
                                  onChange={(_, val) => {
                                    if (val !== null) {
                                      const newArray = [...value];
                                      if (val === true) {
                                        // Ensure it's in the array
                                        if (!newArray.includes(option)) {
                                          newArray.push(option);
                                        }
                                      }
                                      if (val === false) {
                                        // Ensure it's not in the array
                                        const indexOfOption =
                                          newArray.indexOf(option);
                                        if (indexOfOption > -1) {
                                          newArray.splice(indexOfOption, 1);
                                        }
                                      }

                                      onChange(newArray);
                                    }
                                  }}
                                  disabled={initialTypes.includes(option)}
                                />
                              }
                              label={EVariationTypeLanguage[option]}
                            />
                          </FormControl>
                        )}
                      />
                    </RoundBorderBox>
                  ))}

                  <AlertForQueryErrorOrNull
                    isError={updateVariationTypeStatus.isError}
                    error={updateVariationTypeStatus.error}
                  />
                </Stack>
              </form>
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={handleCancelClick}>
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  form={_formId}
                  loading={updateVariationTypeStatus.isLoading}
                  disabled={!isDirty}
                >
                  Update
                </LoadingButton>
              </Grid>
            </StyledDrawerActions>
          </Box>
        </StyledDrawer>
      </>
    );
  };

  return {
    showDrawer: handleShowDrawer,
    renderDrawer,
  };
};
