import * as React from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Menu, MenuItem } from '@mui/material';
import { useModalState } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { AddSupportTicketDrawer } from 'pages/applicationPage/content/support/sections/SupportTicketSection';
import { useAppDispatch } from 'state';
import { createNewTask } from 'state/slices/task';

export const AddButton = () => {
  const { applicationReference } = useApplicationContext();

  const [addmenuShowing, setAddmenuShowing] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(addmenuShowing);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddmenuShowing(event.currentTarget);
  };
  const handleClose = () => {
    setAddmenuShowing(null);
  };

  const dispatch = useAppDispatch();

  const handleAddTaskClick = () => {
    dispatch(createNewTask(applicationReference));
    setAddmenuShowing(null);
  };

  const {
    isShowing: isAddSupportTicketDrawerShowing,
    showModal: showAddSupportTicketDrawer,
    hideModal: hideAddSupportTicketDrawer,
  } = useModalState();

  const handleAddSupportClick = () => {
    showAddSupportTicketDrawer();
    setAddmenuShowing(null);
  };

  return (
    <>
      <Grid item>
        <Button
          id="add-menu-button"
          variant="contained"
          aria-controls={open ? 'add-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            size="sm"
            style={{ marginRight: '10px' }}
            fixedWidth
          />{' '}
          Add
        </Button>
        <Menu
          id="add-menu"
          anchorEl={addmenuShowing}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'add-menu-button',
          }}
        >
          <MenuItem onClick={handleAddTaskClick}>Add Task</MenuItem>
          <MenuItem onClick={handleAddSupportClick}>Add Support</MenuItem>
        </Menu>
      </Grid>
      {isAddSupportTicketDrawerShowing && (
        <>
          <AddSupportTicketDrawer
            onClose={() => {
              hideAddSupportTicketDrawer();
            }}
          />
        </>
      )}
    </>
  );
};
