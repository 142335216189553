import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EPaymentSection } from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';

interface IApplicationPaymentsContext {
  applicationId: string;
  selectedItemId: EPaymentSection;
  setSelectedItemId: (itemId: EPaymentSection) => void;
  readOnly: boolean;
}

const ApplicationPaymentsContext = createContext<
  IApplicationPaymentsContext | undefined
>(undefined);

interface IApplicationPaymentsContextProviderProps {
  children: ReactNode;
}

export const ApplicationPaymentsContextProvider = ({
  children,
}: IApplicationPaymentsContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();

  const [selectedItemId, setSelectedItemId] = useState<EPaymentSection>(
    EPaymentSection.VendorSetup
  );

  const handleSetSelectedItemId = (itemId: EPaymentSection) => {
    setSelectedItemId(itemId);
  };

  const readOnly = closed;

  const value = useMemo(
    () => ({
      applicationId,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      readOnly,
    }),
    [applicationId, readOnly, selectedItemId]
  );

  return (
    <ApplicationPaymentsContext.Provider value={value}>
      {children}
    </ApplicationPaymentsContext.Provider>
  );
};

export const useApplicationPaymentsContext = () => {
  const context = useContext(ApplicationPaymentsContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationPaymentsContext must be used within an ApplicationPaymentsContextProvider'
    );
  }
  return context;
};
