import { Box, Typography } from '@mui/material';
import { DocumentDetails } from 'pages/pullInProcessPage/components/documents/documentDetails';
import { StyledRoundBorderBox } from 'pages/pullInProcessPage/components/pullInProcessPageLayout';

export function PullInProcessDocuments() {
  return (
    <>
      <StyledRoundBorderBox>
        <Box>
          <Typography component="h3" variant="h3" fontWeight={600}>
            Documents
          </Typography>
        </Box>
        <Box
          m="1rem"
          p="1rem 1rem 3rem"
          sx={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
        >
          <DocumentDetails />
        </Box>
      </StyledRoundBorderBox>
    </>
  );
}
