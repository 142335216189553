import { useGetCurrentUserTasksQuery } from 'api/task';
import { useTaskListGridStateDefault } from 'common/components/taskGrid/utils/taskGridUtils';
import { ETaskListConfigType } from 'types/tasks/CmsTaskTypes';

export const useGridQueryAndState = () => {
  const { state, gridStateChanged } = useTaskListGridStateDefault();
  const query = useGetCurrentUserTasksQuery({
    type: ETaskListConfigType.CurrentUserTasks,
    query: state.gridState,
  });

  return { query, state, gridStateChanged };
};
