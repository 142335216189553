import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Typography,
  TextField,
  Grid,
  Box,
  Autocomplete,
  MenuItem,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  useGetPullInProcessBuildingDetailsQuery,
  useGetPullInProcessesSourceOfInformationQuery,
  useUpdatePullInProcessBuildingDetailsMutation,
} from 'api/pullInProcess';
import { EPullInBuildingHeightTypeLanguage } from 'enums/EPullInBuildingHeightType';
import { useLocalSnackbar } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { zAddBuildingRecordSchema } from 'types/pullInProcess/addBuildingRecord.zod';
import {
  TAddBuildingRecord,
  TPostPutBuildingRecord,
} from 'types/pullInProcess/addBuildingRecordTypes';
import { extractErrorMessages } from 'util/ApiUtils';

export function BuildingDetailsForm({ formId }: { formId: string }) {
  const { pullInProcessId } = usePullInProcessContext();
  const { data, isLoading } =
    useGetPullInProcessBuildingDetailsQuery(pullInProcessId);
  const form = useForm<TAddBuildingRecord>({
    resolver: zodResolver(zAddBuildingRecordSchema),
  });
  const [updateBuildingDetails] =
    useUpdatePullInProcessBuildingDetailsMutation();

  const {
    data: sourceInformationData,
    isSuccess: sourceInfoLoadedSuccessfully,
  } = useGetPullInProcessesSourceOfInformationQuery();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = form;
  const sourceInformation = watch('sourceOfInformation');

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onSubmit = async (formData: TAddBuildingRecord) => {
    function mapToPostFormData({
      sourceOfInformation,
      ...formDataToSubmit
    }: TAddBuildingRecord): TPostPutBuildingRecord {
      const formData: TPostPutBuildingRecord = {
        ...formDataToSubmit,
        sourceOfInformationId: sourceOfInformation.id,
        // map building height to an enum value or null, MUI won't accept null as a value
        buildingHeightTypeId: formDataToSubmit.buildingHeightTypeId
          ? formDataToSubmit.buildingHeightTypeId
          : null,
      };
      return formData;
    }
    // CHECK WHY BUILDING HEIGHT IS NOt BEING USED CORRECTLY
    const formDataToSubmit = mapToPostFormData(formData);
    await updateBuildingDetails({
      buildingId: pullInProcessId,
      ...formDataToSubmit,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Successfully updated building details');
      })
      .catch((error: string) => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  useEffect(() => {
    if (data) reset(data);
  }, [data, reset]);

  if (isLoading) return <div>Loading building details</div>;

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Box m="1rem" p="1rem 1rem 3rem">
        <Grid
          container
          spacing="1rem"
          sx={{
            width: 'inherit',
          }}
        >
          <Grid item xs={6}>
            <Typography fontWeight={600} mb="1rem">
              Building details
            </Typography>
            <Controller
              control={control}
              name="address.buildingNameNumber"
              defaultValue=""
              render={({ field: { ...fieldRest } }) => (
                <TextField
                  fullWidth
                  {...fieldRest}
                  label="Address building name number"
                  error={!!errors.address?.buildingNameNumber}
                  helperText={errors.address?.buildingNameNumber?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="address.line1"
              defaultValue={data?.address?.line1 ?? ''}
              render={({ field: fieldRest }) => (
                <TextField
                  fullWidth
                  {...fieldRest}
                  label="Address line one"
                  error={!!errors?.address?.line1}
                  helperText={errors?.address?.line1?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="address.line2"
              defaultValue={data?.address?.line2 ?? ''}
              render={({ field: fieldRest }) => (
                <TextField
                  fullWidth
                  {...fieldRest}
                  label="Address line two"
                  error={!!errors?.address?.line2}
                  helperText={errors?.address?.line2?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="address.townCity"
              defaultValue={data?.address?.townCity ?? ''}
              render={({ field: fieldRest }) => (
                <TextField
                  fullWidth
                  {...fieldRest}
                  label="Town/ City"
                  error={!!errors?.address?.townCity}
                  helperText={errors?.address?.townCity?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="address.county"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  label="County"
                  error={!!errors.address?.county}
                  helperText={errors.address?.county?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="address.country.name"
              defaultValue={data?.address?.country?.name ?? ''}
              render={({ field: fieldRest }) => (
                <TextField
                  disabled
                  fullWidth
                  {...fieldRest}
                  label="Country"
                  error={!!errors?.address?.country?.name}
                  helperText={errors?.address?.country?.name?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="address.postCode"
              defaultValue={data?.address?.postCode ?? ''}
              render={({ field: fieldRest }) => (
                <TextField
                  fullWidth
                  {...fieldRest}
                  label="Postcode"
                  error={!!errors?.address?.postCode}
                  helperText={errors?.address?.postCode?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={600} mb="1rem">
              Company details
            </Typography>
            <Controller
              control={control}
              name="address.localAuthority"
              defaultValue={data?.address?.localAuthority ?? ''}
              render={({ field: fieldRest }) => (
                <TextField
                  fullWidth
                  {...fieldRest}
                  label="Local Authority"
                  error={!!errors?.address?.localAuthority}
                  helperText={errors?.address?.localAuthority?.message}
                />
              )}
            />
            {sourceInfoLoadedSuccessfully && (
              <>
                <Controller
                  control={control}
                  name="sourceOfInformation"
                  defaultValue={data?.sourceOfInformation}
                  render={({ field: { value, onChange, ...fieldRest } }) => {
                    return (
                      <Autocomplete
                        options={sourceInformationData}
                        getOptionLabel={option => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        onChange={(_, value) => {
                          onChange(value);
                        }}
                        value={sourceInformation || null}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...fieldRest}
                            label="Source Information (required)"
                            error={!!errors.sourceOfInformation}
                            helperText={errors.sourceOfInformation?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
                {sourceInformation?.hasFreeText ? (
                  <Controller
                    control={control}
                    name="sourceOfInformationOther"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        label="Source of information other (required)"
                        error={!!errors.sourceOfInformationOther}
                        helperText={errors.sourceOfInformationOther?.message}
                      />
                    )}
                  />
                ) : null}
              </>
            )}
            <Controller
              control={control}
              name="buildingHeightTypeId"
              defaultValue={0}
              render={({ field: { ...fieldRest } }) => (
                <TextField
                  fullWidth
                  select
                  {...fieldRest}
                  label="Building Height"
                  error={!!errors.buildingHeightTypeId}
                  helperText={errors.buildingHeightTypeId?.message}
                >
                  <MenuItem disabled value={0}>
                    <em>Please select a building height</em>
                  </MenuItem>
                  {Object.entries(EPullInBuildingHeightTypeLanguage).map(
                    ([value, label]) => (
                      <MenuItem key={value} value={+value}>
                        {label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        paddingBottom={2}
        paddingRight={2}
        paddingTop={2}
        borderTop={'1px solid rgba(0, 0, 0, 0.08)'}
      >
        <LoadingButton
          variant="outlined"
          sx={{ marginRight: '10px' }}
          className="mr-2"
        >
          Cancel
        </LoadingButton>
        <LoadingButton variant="contained" type="submit" className="mr-2">
          Save
        </LoadingButton>
      </Box>
    </form>
  );
}
