import { Chip } from '@mui/material';
import { ELiveProjectItemStatus, ELiveProjectItemStatusLanguage } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';

export const LiveProjectItemChip = ({
  status,
}: {
  status: ELiveProjectItemStatus;
}) => {
  return (
    <Chip
      label={ELiveProjectItemStatusLanguage[status]}
      size="small"
      color={
        status === ELiveProjectItemStatus.Approved || status === ELiveProjectItemStatus.Paid
          ? 'success'
          : status === ELiveProjectItemStatus.InProgress
          ? 'info'
          : 'default'
      }
    />
  );
};
