import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFiltersGridLeft = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
}));

export const StyledFiltersGridRight = styled(Grid)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  right: '-10px',
  paddingLeft: '30px',
  justifyContent: 'flex-end',
}));

export const StyledFilter = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '300px',
  paddingRight: '10px',
  boxSizing: 'border-box',
}));
