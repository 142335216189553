import {
  Box,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { useModalState } from 'hooks';
import { IMutationStatus } from 'types/GeneralTypes';

export const useReferToHomesEnglandModal = () => {
  const { isShowing, showModal, hideModal } = useModalState();

  const renderReferToHomesEnglandModal = ({
    handleConfirmClick,
    referStatus,
  }: {
    handleConfirmClick: (referralComments: string) => void;
    referStatus: IMutationStatus;
  }) => {
    return (
      <ReferToHomesEnglandModal
        isShowing={isShowing}
        hideModal={hideModal}
        handleConfirmClick={handleConfirmClick}
        status={{
          isLoading: referStatus.isLoading,
          isError: referStatus.isError,
          error: referStatus.error,
        }}
      />
    );
  };

  return {
    showReferToHomesEnglandModal: showModal,
    hideReferToHomesEnglandModal: hideModal,
    renderReferToHomesEnglandModal,
  };
};

export const ReferToHomesEnglandModal = ({
  isShowing,
  handleConfirmClick,
  hideModal,
  status,
}: {
  isShowing: boolean;
  hideModal: () => void;
  handleConfirmClick: (referralComments: string) => void;
  status: IMutationStatus;
}) => {
  const form = useForm<{ referralComments: string }>({
    defaultValues: { referralComments: '' },
  });

  const handleClick = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      const referralComments = form.getValues().referralComments;
      handleConfirmClick(referralComments);
    }
  };

  if (!isShowing) {
    return null;
  }

  return (
    <ConfirmationModal
      isShowing={isShowing}
      confirmButtonText="Send Referral"
      cancelButtonText="Cancel"
      onConfirm={handleClick}
      onCancel={hideModal}
      dialogActionsSx={{ m: 2 }}
      areButtonsLoading={status.isLoading}
      showActionsDivider
      fullWidth
      maxWidth="md"
    >
      <Box p={3}>
        {status.isLoading ? <LinearProgress /> : null}

        <Typography variant="body1" fontWeight={600} fontSize={'1.2em'}>
          Refer application to Homes England
        </Typography>

        <Typography variant="body1" pt={3} fontWeight={600}>
          Provide instructions on what Homes England should look at.
        </Typography>

        <Stack gap={2}>
          <FormProvider {...form}>
            <Controller
              control={form.control}
              name={`referralComments`}
              rules={{
                required: 'Required',
                maxLength: { value: 999, message: 'Max length is 1,000' },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  fullWidth
                  error={
                    !!fieldState.error ||
                    !!(field.value && field.value.length >= 1000)
                  }
                  rows={3}
                  helperText={fieldState.error?.message}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  sx={{ mb: 0 }}
                  label="Provide details"
                />
              )}
            />
          </FormProvider>

          <AlertForQueryErrorOrNull
            isError={status.isError}
            error={status.error}
          />
        </Stack>
      </Box>
    </ConfirmationModal>
  );
};
