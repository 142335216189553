import { ReactNode } from 'react';
import { Box, Grid, List, ListItem, Typography } from '@mui/material';
import { useGetWorksPackageCladdingQuery } from 'api/application/worksPackageApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import {
  ECladdingRemediationType,
  ERiskSafetyMitigationType,
  ERiskSafetyMitigationTypeLanguage,
  IGetCladdingSectionResponse,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import { CladdingSystemsList } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CladdingSection';

export const CladdingSection = () => {
  const { applicationId } = useWorksPackageContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetWorksPackageCladdingQuery(applicationId);

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  if (isLoading) {
    return <FormSkeleton />;
  }

  return isSuccess ? (
    <>
      <HeaderSection data={data} />
      <CladdingSystemsList data={data} />
    </>
  ) : null;
};

const getReplacementTypeValue = (replacementType: ECladdingRemediationType) => {
  const replacementLanguage = 'replacement of cladding';
  switch (replacementType) {
    case ECladdingRemediationType.Full:
      return `Full ${replacementLanguage}`;

    case ECladdingRemediationType.Partial:
      return `Partial ${replacementLanguage}`;

    case ECladdingRemediationType.None:
      return `No ${replacementLanguage}`;

    default:
      return 'Error';
  }
};

const HeaderSection = ({ data }: { data: IGetCladdingSectionResponse }) => {
  return (
    <RoundBorderBox bgcolor={'grey.100'} sx={{ p: 4, borderWidth: 0 }}>
      <Box mb={2}>
        <Typography variant="h2" sx={{ fontSize: '1.05em', fontWeight: 600 }}>
          FRAEW Details
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={7}>
          <TopSectionTitle>
            Recommended courses of action for remediation/mitigation
          </TopSectionTitle>
          <List sx={{ listStyleType: 'disc', pt: 0, pl: 2.5 }}>
            {data.safetyRiskMitigationActions.map(item => {
              const otherText =
                item.safetyRiskMitigationAction ===
                ERiskSafetyMitigationType.Other
                  ? data.safetyRiskOtherText
                  : null;
              return (
                <Li key={item.safetyRiskMitigationAction}>
                  {
                    ERiskSafetyMitigationTypeLanguage[
                      item.safetyRiskMitigationAction
                    ]
                  }
                  {otherText ? `: ${otherText}` : null}
                </Li>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={5}>
          <TopSectionTitle>Remediation Type</TopSectionTitle>
          <Typography variant="body1">
            {getReplacementTypeValue(data.replacementType)}
          </Typography>
        </Grid>
      </Grid>
    </RoundBorderBox>
  );
};

const TopSectionTitle = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      variant="body1"
      sx={{ fontSize: '0.9em', color: 'grey.700', mb: 1 }}
    >
      {children}
    </Typography>
  );
};

const Li = ({ children }: { children: ReactNode }) => {
  return (
    <ListItem sx={{ display: 'list-item', pl: 0, py: 0.5 }}>
      {children}
    </ListItem>
  );
};
