import { DataTableResponse } from 'common/types/DataTableResponse';
import { ISignatoryGridListItem } from 'types/applications/ApplicationSignatoryTypes';


export const applicationSignatoriesData: DataTableResponse<ISignatoryGridListItem> = {
  results: [
    {
      id: '1',
      firstName: 'Test',
      lastName: 'Tester',
      role: 'CFO',
      emailAddress: 'test@test.com',
      isPassed: false,
      failureReason: 'Just because',
      dateAdded: new Date('2023-03-15').toJSON(),
    },
    {
      id: '2',
      firstName: 'Test',
      lastName: 'Tester',
      role: 'Chairman',
      emailAddress: 'test@test.com',
      isPassed: true,
      failureReason: '',
      dateAdded: new Date('2023-03-15').toJSON(),
    },
  ],
  count: 2,
};

export const signatoryData: ISignatoryGridListItem = {
  id: '1',
  firstName: 'Test',
  lastName: 'Tester',
  role: 'CFO',
  emailAddress: 'test@test.com',
  isPassed: false,
  failureReason: 'Just because',
  dateAdded: new Date('2023-03-15').toJSON(),
};