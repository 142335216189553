import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useGetWorksPackageDeedCounterSignedStatusQuery } from 'api/application/worksPackageDeedApi';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EWorksPackageDeedSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection';

interface IWorksPackageDeedContext {
  applicationId: string;
  selectedItemId: EWorksPackageDeedSection;
  setSelectedItemId: (itemId: EWorksPackageDeedSection) => void;
  readOnly: boolean;
}

const WorksPackageDeedContext = createContext<
  IWorksPackageDeedContext | undefined
>(undefined);

interface IWorksPackageDeedContextProviderProps {
  children: ReactNode;
}

export const WorksPackageDeedContextProvider = ({
  children,
}: IWorksPackageDeedContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();

  const [selectedItemId, setSelectedItemId] =
    useState<EWorksPackageDeedSection>(
      EWorksPackageDeedSection.sendWorksPackageDeedDeed
    );

  const handleSetSelectedItemId = (itemId: EWorksPackageDeedSection) => {
    setSelectedItemId(itemId);
  };

  const worksPackageDeedCounterSignedStatusQuery =
    useGetWorksPackageDeedCounterSignedStatusQuery(applicationId);

  const readOnly =
    worksPackageDeedCounterSignedStatusQuery.data
      ?.workPackageDeedCounterSigned === true || closed;

  const value = useMemo(
    () => ({
      applicationId,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      readOnly,
    }),
    [applicationId, readOnly, selectedItemId]
  );

  return (
    <WorksPackageDeedContext.Provider value={value}>
      {children}
    </WorksPackageDeedContext.Provider>
  );
};

export const useWorksPackageDeedContext = () => {
  const context = useContext(WorksPackageDeedContext);
  if (context === undefined) {
    throw new Error(
      'useWorksPackageDeedContext must be used within an WorksPackageDeedContextProvider'
    );
  }
  return context;
};
