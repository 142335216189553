import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Menu,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { SortDirection } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetPaginatedRolesListQuery } from 'api/administration/roles';
import { Loading } from 'common/components/loading/Loading';
import { ESortDirection } from 'enums/ESortDirection';
import { useCurrentUserPermissions, useEllipsisMenu } from 'hooks';
import { EAdminRolesMenuType } from 'pages/administrationPage/components/administrationRolesTab/types/EAdminRolesMenuType';
import { useAppDispatch } from 'state';
import { selectAdministrationRolesGridState } from 'state/selectors/administration/administrationRolesSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationRolesReducer';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { IRoleGridListItem } from 'types/administration/AdministrationRolesTypes';
import { getMenuItemConfig, getMenuItems } from 'util/AppUtils';

export function AdministrationRolesGrid() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const administrationRolesGridState = useSelector(
    selectAdministrationRolesGridState
  );
  const { data: rolesData, isLoading: isRolesGridLoading } =
    useGetPaginatedRolesListQuery(administrationRolesGridState);

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminRoles = doesUserHaveSinglePermission('admin.roles');

  const { skip, take, sortBy, sortDirection } = administrationRolesGridState;

  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const handleMenuItemClick = (menuType: EAdminRolesMenuType) => {
    switch (menuType) {
      case EAdminRolesMenuType.EditRole:
        navigate(`/admin/addEditRole/${selectedId}`);
        break;
    }

    handleMenuClose();
  };

  const currentPage = skip / take;

  const sortHandler = (property: keyof IRoleGridListItem): void => {
    dispatch(
      gridStateChanged({
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      })
    );
  };

  const getAriaSortDirection = (
    property: keyof IRoleGridListItem,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getCurrentSortDirection = (
    property: keyof IRoleGridListItem,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof IRoleGridListItem,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  return (
    <>
      <Loading isOpen={isRolesGridLoading} />
      <StyledGenericTable data-testid="Administration-Roles-Table">
        <TableHead>
          <TableRow>
            {getHeaderTableCell('name', 'Role Name')}
            {getHeaderTableCell('activeUsers', 'Active Users')}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rolesData?.results?.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.activeUsers}</TableCell>
              <TableCell align="right" aria-label="Actions">
                {hasAdminRoles && (
                  <>
                    <IconButton
                      aria-label="edit"
                      name="edit"
                      onClick={event => handleEllipsisClick(row.id, event)}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                    {selectedId === row.id ? (
                      <Menu
                        id={`admin-user-menu-${row.id}`}
                        anchorEl={ellipsisMenuAnchor}
                        open={Boolean(ellipsisMenuAnchor)}
                        onClose={handleMenuClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {getMenuItems(
                          [
                            getMenuItemConfig(
                              'Edit Role',
                              EAdminRolesMenuType.EditRole
                            ),
                          ],
                          handleMenuItemClick
                        )}
                      </Menu>
                    ) : null}
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={rolesData?.count || 0}
              rowsPerPage={take}
              rowsPerPageOptions={[take]}
              page={currentPage}
              onPageChange={(e, newPage) => {
                const newSkip = newPage * take;
                dispatch(gridStateChanged({ skip: newSkip }));
              }}
              onRowsPerPageChange={e => {
                dispatch(
                  gridStateChanged({
                    take: +e.target.value,
                  })
                );
              }}
            />
          </TableRow>
        </TableFooter>
      </StyledGenericTable>
    </>
  );
}
