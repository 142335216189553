import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'state';

export const selectPullInProcessesSelectorsRoot = (state: AppState) =>
  state.pullInProcesses;

export const selectPullInProcessesGridState = createSelector(
  [selectPullInProcessesSelectorsRoot],
  pullInProcesses => pullInProcesses.gridState
);
