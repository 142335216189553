export interface ISelectedLiveProjectItem {
  id: string;
  type: ELiveProjectItemType;
}

export interface ILiveProjectOverview {
  startDate: string;
  endDate: string;
  currentSpend: number;
  eligibleSpend: number;
  worksPackage: ILiveProjectWorkPackage;
  milestones: ILiveProjectMilestones;
}

export interface ILiveProjectWorkPackage {
  id: string;
  version: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export interface ILiveProjectMilestones {
  startOnSite?: Date;
  practicalCompletion?: Date;
}

export interface IGetLiveProjectListResponse {
  results: ILiveProjectReportItem[];
}

export interface ILiveProjectReportItem {
  id: string;
  title: string;
  subTitle: string;
  status: ELiveProjectItemStatus;
  type: ELiveProjectItemType;
  dueDate?: string;
  completedDate?: string;
  startDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export enum ELiveProjectItemType {
  ScheduleOfWorks = 1,
  PaymentRequest = 2,
  Variation = 3,
  ClosingPaymentRequest = 4,
}

export enum ELiveProjectItemStatus {
  ToDo = 1,
  InProgress = 2,
  Submitted = 3,
  Sent = 4,
  Approved = 5,
  Expired = 6,
  Rejected = 7,
  Missed = 8,
  Paid = 9
}

export const ELiveProjectItemStatusLanguage: {
  [key in ELiveProjectItemStatus]: string;
} = {
  [ELiveProjectItemStatus.ToDo]: 'To Do',
  [ELiveProjectItemStatus.InProgress]: 'In Progress',
  [ELiveProjectItemStatus.Submitted]: 'Submitted',
  [ELiveProjectItemStatus.Sent]: 'Sent',
  [ELiveProjectItemStatus.Approved]: 'Approved',
  [ELiveProjectItemStatus.Expired]: 'Expired',
  [ELiveProjectItemStatus.Rejected]: 'Rejected',
  [ELiveProjectItemStatus.Missed]: 'Missed',
  [ELiveProjectItemStatus.Paid]: 'Paid'
} as const;

export enum EVariationSection {
  Overview,
  CostSchedule,
  Checks,
}
