import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from 'common/components/protectedRoute/ProtectedRoute';
import { AdminTasksPage } from 'pages/adminTasksPage';
import { AdministrationPage } from 'pages/administrationPage/AdministrationPage';
import { AddEditRolePage } from 'pages/administrationPage/components/administrationRolesTab/components/addEditRolePage/AddEditRolePage';
import { ApplicationPage } from 'pages/applicationPage';
import { ApplicationsPage } from 'pages/applicationsPage';
import { CurrentUserTasksPage } from 'pages/currentUserTasksPage';
import { ErrorPage } from 'pages/errorPage/ErrorPage';
import { MyAccountPage } from 'pages/myAccount';
import { NoPermissionsPage } from 'pages/noPermissionsPage';
import { PageNotFound } from 'pages/pageNotFound';
import { PullInProcessPage } from 'pages/pullInProcessPage';
import { PullInProcessesPage } from 'pages/pullInProcessesPage';
import { useDocumentTitle } from 'util/AppUtils';

export const routePaths = {
  home: '/',
  admin: 'admin',
  addEditRole: 'admin/addEditRole',
  adminTasks: 'admin/tasks',
  applications: 'applications',
  myAccount: 'myAccount',
  notPermitted: 'notPermitted',
  errorPage: 'errorPage',
  pullInProcesses: 'pullInProcesses',
} as const;

const Home = () => {
  useDocumentTitle('Current User Tasks');
  return <CurrentUserTasksPage />;
};

const Administration = () => {
  useDocumentTitle('Administration');
  return (
    <ProtectedRoute
      requiredPermissions={[
        'admin.users',
        'admin.roles',
        'admin.system-notification',
        'admin.fraew-assessor-list',
      ]}
      children={<AdministrationPage />}
    />
  );
};

const AddEditRole = () => {
  useDocumentTitle('Add edit role');
  return <AddEditRolePage />;
};

const AdminTasks = () => {
  useDocumentTitle('Administration tasks');
  return <AdminTasksPage />;
};

const Applications = () => {
  useDocumentTitle('Applications');
  return <ApplicationsPage />;
};

const Application = () => {
  useDocumentTitle('Application');
  return <ApplicationPage />;
};

const PullInProcess = () => {
  useDocumentTitle('Pull in process');
  return <PullInProcessPage />;
};

const PullInProcesses = () => {
  useDocumentTitle('Pull in processes');
  return <PullInProcessesPage />;
};

const MyAccount = () => {
  useDocumentTitle('My Account');
  return <MyAccountPage />;
};

const NoPermissions = () => {
  useDocumentTitle('No Permissions');
  return <NoPermissionsPage />;
};

const Error = () => {
  useDocumentTitle('Error Page');
  return <ErrorPage />;
};

const PageNotFoundPage = () => {
  useDocumentTitle('Page Not Found');
  return <PageNotFound />;
};

export function IndexRoutes() {
  return (
    <main id="maincontent">
      <Routes>
        <Route path={routePaths.home} element={<Home />} />
        <Route path={`${routePaths.admin}/:tab`} element={<Administration />} />
        <Route path={`${routePaths.addEditRole}`} element={<AddEditRole />} />
        <Route
          path={`${routePaths.addEditRole}/:roleId`}
          element={<AddEditRole />}
        />
        <Route path={routePaths.adminTasks} element={<AdminTasks />} />
        <Route path={routePaths.applications} element={<Applications />} />
        <Route
          path={`${routePaths.applications}/:applicationId`}
          element={<Application />}
        />
        <Route
          path={routePaths.pullInProcesses}
          element={<PullInProcesses />}
        />
        <Route
          path={`${routePaths.pullInProcesses}/:pullInProcessId`}
          element={<PullInProcess />}
        />
        <Route path={routePaths.myAccount} element={<MyAccount />} />
        <Route path={routePaths.notPermitted} element={<NoPermissions />} />
        <Route path={routePaths.errorPage} element={<Error />} />
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </main>
  );
}
