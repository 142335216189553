import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditPtfsAmountMutation } from 'api/application/applicationEditApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zPtfsAmountSchema } from 'types/applications/ApplicationEdit.zod';
import { IPtfsAmount } from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IPtfsAmount) =>
  nameof<IPtfsAmount>(fieldName);

interface IEditPtfsAmountDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
  currentValue: string;
}

export function EditPtfsAmountDrawer({
  onClose,
  onSuccess,
  currentValue,
}: IEditPtfsAmountDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">Edit PTS Funding Amount</Typography>
          <Typography variant="h3">{currentValue}</Typography>
        </DialogTitle>
        <EditPtfsAmountForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditPtfsAmountFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditPtfsAmountForm = ({
  onClose,
  onSuccess,
}: IEditPtfsAmountFormProps) => {
  const maxPtfsAmount = parseInt(
    process.env.REACT_APP_MAX_PTFS_AMOUNT ?? '0'
  ) as number;
  const { applicationId } = useApplicationContext();

  const [editPtfsAmount, editPtfsAmountResult] = useEditPtfsAmountMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IPtfsAmount>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zPtfsAmountSchema),
  });
  const { handleSubmit, reset, watch } = form;

  const watchPtfsAmount = watch('ptfsAmount') as number;

  const onSubmit = async (formData: IPtfsAmount) => {
    try {
      formData.applicationId = applicationId;
      await editPtfsAmount(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`PTFS amount updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          }
          else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit ptfs amount`);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name={getName('ptfsAmount')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="number"
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="PTFS Amount"
                    helperText={fieldState.error?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      inputProps: {
                        min: 1,
                      },
                    }}
                  />
                )}
              />
              {watchPtfsAmount > maxPtfsAmount && (
                <FormHelperText error>
                  You have entered a value above the usual maximum value for
                  PTFS - Please check this is correct before saving
                </FormHelperText>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ marginTop: watchPtfsAmount > maxPtfsAmount ? '2rem' : '0' }}
            >
              <Controller
                name={getName('changeReason')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="Comments"
                    helperText={fieldState.error?.message}
                    multiline
                    autoComplete="off"
                    inputProps={{ maxLength: 1000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editPtfsAmountResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
