import { Typography } from '@mui/material';
import {
  EVariationType,
  IVariationBoxProps,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { useEditScopeDrawer } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/ScopeBox';
import { VariationOverviewBox } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/common';
import { isOfType } from 'util/AppUtils';

export const ScopeBox = ({ data, isEditable = false }: IVariationBoxProps) => {
  const variation = data.variations.find(isOfType(EVariationType.Scope));
  const scopeDrawer = useEditScopeDrawer({
    initialData: { scope: variation?.scopeChangeDescription ?? '' },
  });

  if (!variation) {
    return null;
  }

  return (
    <VariationOverviewBox
      title="Scope changes"
      onEditClick={
        !isEditable
          ? undefined
          : () => {
              scopeDrawer.showDrawer();
            }
      }
    >
      <Typography>{variation.scopeChangeDescription}</Typography>
      {scopeDrawer.renderDrawer()}
    </VariationOverviewBox>
  );
};
