export enum EEligiblityCheckStatus {
  Approved = 1,
  Rejected = 2,
}

export const EEligiblityCheckStatusLanguage: { [key in EEligiblityCheckStatus]: string } =
  {
    [EEligiblityCheckStatus.Approved]: 'Approved',
    [EEligiblityCheckStatus.Rejected]: 'Rejected',
  } as const;
