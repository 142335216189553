import { MouseEvent, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Chip, Grid, Typography } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { MyAccountMenu } from 'common/components/myAccount/MyAccountMenu';
import { useCurrentUser } from 'hooks';

export function MyAccount() {
  const { initials } = useCurrentUser();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const chipContents = (
    <Grid container display="flex" alignItems="center" flexWrap="inherit">
      <Avatar
        sx={({ spacing, palette }) => ({
          border: '1px white solid',
          width: spacing(3),
          height: spacing(3),
          fontSize: '0.75rem',
          fontWeight: 500,
          backgroundColor: deepPurple[500],
          color: palette.common.white,
        })}
      >
        {initials}
      </Avatar>
      <Grid display="flex" alignItems="center">
        <Typography
          variant="caption"
          m={({ spacing }) => `0 ${spacing(1)}`}
          sx={({ palette }) => ({
            color: palette.common.white,
          })}
        >
          My Account
        </Typography>
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{ marginRight: '0.5rem', color: '#FFF' }}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Chip
        label={chipContents}
        id="my-account-menu-button"
        aria-controls={isMenuOpen ? 'my-account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-label="My Account menu"
        onClick={(event: MouseEvent<HTMLElement>) => {
          if (isMenuOpen) {
            setIsMenuOpen(false);
          } else {
            setIsMenuOpen(true);
            setAnchorEl(event.currentTarget);
          }
        }}
        color="default"
        variant="outlined"
        sx={() => ({
          paddingLeft: '0.2rem',
          '& .MuiChip-label': {
            paddingLeft: 0,
          },
        })}
      />
      <MyAccountMenu
        anchorEl={anchorEl}
        clearAnchorEl={() => setAnchorEl(null)}
        closeMenu={() => setIsMenuOpen(false)}
        isMenuOpen={isMenuOpen}
        isMenuOrDrawer={true ? 'menu' : 'drawer'}
      />
    </>
  );
}
