import { ReactNode } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPaymentRequestOverviewQuery } from 'api/application/paymentRequestApi';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import {
  GrayCardContainer,
  QuestionAndAnswer,
} from 'pages/applicationPage/content/liveProject/common';
import { PaymentReferralGrid } from 'pages/applicationPage/content/liveProject/common/paymentReferralGrid/PaymentReferralGrid';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { usePaymentRequestContext } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/context';
import { PaymentRequestNotificationBanner } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/sections/PaymentRequestChecksSection';
import { EPaymentRecommendationStatus } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  PaymentRequestKpiSection,
  UnlockRequestModal,
  VarianceCard,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { getPercentage } from 'util/AppUtils';

export const PaymentRequestOverviewSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const {
    currentStatus,
    referrals: heReferrals,
    showReferralsSection,
  } = usePaymentRequestContext();
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const userHasUnlockPaymentPermission = doesUserHaveSinglePermission(
    'payment-release.unlock'
  );

  const {
    isShowing: isUnlockModalShowing,
    showModal: showUnlockModal,
    hideModal: hideUnlockModal,
  } = useModalState();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.PaymentRequest,
  });

  const { data } = useGetPaymentRequestOverviewQuery(request);

  const isExpired = currentStatus === EPaymentRecommendationStatus.Expired;

  const showRecommendedAmount: boolean = !!(
    data?.recommendedAmount !== undefined &&
    data?.requestedAmount !== undefined &&
    data.recommendedAmount !== data.requestedAmount
  );

  const expiredOnlyView =
    isExpired ||
    currentStatus === EPaymentRecommendationStatus.Todo ||
    currentStatus === EPaymentRecommendationStatus.Missed ||
    currentStatus === EPaymentRecommendationStatus.InProgress;

  return (
    <>
      <Stack gap={2}>
        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.PaymentRequest} />
        )}

        {selectedItem && (
          <PaymentRequestKpiSection paymentRequestId={selectedItem.id} />
        )}

        <RoundBorderBox>
          <Box sx={{ padding: 2, borderBottom: '1px solid lightgray' }}>
            <Typography variant="h3" fontWeight={800} fontSize="1.3em">
              Overview
            </Typography>
          </Box>
          <Stack p={2} gap={2}>
            {selectedItem && (
              <PaymentReferralGrid
                selectedItemId={selectedItem.id}
                heReferrals={heReferrals}
                showReferralsSection={showReferralsSection}
              />
            )}
            {data?.paymentRecommendation &&
              [
                EPaymentRecommendationStatus.Sent,
                EPaymentRecommendationStatus.Approved,
                EPaymentRecommendationStatus.Rejected,
                EPaymentRecommendationStatus.Expired,
              ].includes(data.paymentRecommendation) && (
                <PaymentRequestNotificationBanner
                  status={data.paymentRecommendation}
                />
              )}

            <Grid container flexWrap="nowrap" columnGap={2}>
              <GrayCardContainer>
                <CardTitle title="Payment Details" />
                {!expiredOnlyView && (
                  <Stack spacing={2} mt={2}>
                    {data?.operationsReasonForCostChange &&
                    showRecommendedAmount ? (
                      <InnerPaymentDetailsBox title="Recommended amount">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: '0.5rem 0',
                            gap: '1rem',
                          }}
                        >
                          <Typography>{`${data?.paymentMonth} payment`}</Typography>
                          <Box>
                            <Typography textAlign="end">{`£${data.recommendedAmount.toLocaleString()}`}</Typography>
                            <Typography fontSize="0.8em">{`${getPercentage(
                              data.recommendedAmount,
                              data.totalGrantFunding
                            ).toFixed(1)}% of total grant funding`}</Typography>
                          </Box>
                        </Box>
                        {data?.additionalRecommendedAmount !== undefined &&
                        data.additionalRecommendedAmount !== 0 ? (
                          <>
                            <QuestionAndAnswer
                              question={data.additionalRecommendedAmountTitle}
                              value={`£${data.additionalRecommendedAmount.toLocaleString()}`}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: '0.5rem 0',
                                gap: '1rem',
                              }}
                            >
                              <Typography fontWeight={700}>Total</Typography>
                              <Typography fontWeight={700}>{`£${(
                                data.recommendedAmount +
                                data.additionalRecommendedAmount
                              ).toLocaleString()}`}</Typography>
                            </Box>
                          </>
                        ) : null}
                        <Typography fontSize="1em" py={1}>
                          Reason
                        </Typography>
                        <Typography variant="body1">
                          {data?.operationsReasonForCostChange}
                        </Typography>
                      </InnerPaymentDetailsBox>
                    ) : null}
                    <InnerPaymentDetailsBox title="Requested Amount">
                      {data?.requestedAmount !== undefined ? (
                        <QuestionAndAnswer
                          question={`${data?.paymentMonth} payment`}
                          value={
                            data?.requestedAmount
                              ? `£${data.requestedAmount.toLocaleString()}`
                              : ' - '
                          }
                        />
                      ) : null}
                      {data?.additionalRequestedAmount !== undefined &&
                      data.additionalRequestedAmount !== 0 ? (
                        <QuestionAndAnswer
                          question={data.additionalRequestedAmountTitle}
                          value={`£${data.additionalRequestedAmount.toLocaleString()}`}
                        />
                      ) : null}
                      {data?.requestedAmount !== undefined &&
                      data?.additionalRequestedAmount !== undefined ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: '0.5rem 0',
                            gap: '1rem',
                          }}
                        >
                          <Typography fontWeight={700}>Total</Typography>
                          <Typography fontWeight={700}>{`£${(
                            data.requestedAmount +
                            data.additionalRequestedAmount
                          ).toLocaleString()}`}</Typography>
                        </Box>
                      ) : null}
                    </InnerPaymentDetailsBox>
                    {data?.scheduledAmount !== undefined && (
                      <InnerPaymentDetailsBox title="Scheduled Payment">
                        <QuestionAndAnswer
                          question={`${data?.paymentMonth} payment`}
                          value={`£${data.scheduledAmount.toLocaleString()}`}
                        />
                      </InnerPaymentDetailsBox>
                    )}
                    <VarianceCard
                      title="Change"
                      paymentAmount={
                        data?.recommendedAmount ?? data?.requestedAmount ?? 0
                      }
                      scheduledAmount={data?.scheduledAmount ?? 0}
                    />
                  </Stack>
                )}
                {expiredOnlyView && (
                  <Box mt={2}>
                    {data?.scheduledAmount !== undefined &&
                    data.scheduledAmount !== 0 ? (
                      <InnerPaymentDetailsBox title="Scheduled amount">
                        <QuestionAndAnswer
                          question={
                            data?.paymentMonth
                              ? `${data.paymentMonth} payment`
                              : ''
                          }
                          value={`£${data.scheduledAmount.toLocaleString()}`}
                        />
                      </InnerPaymentDetailsBox>
                    ) : null}
                  </Box>
                )}
              </GrayCardContainer>
              <Grid item xs={6}>
                {!expiredOnlyView && (
                  <Stack spacing={2}>
                    {data?.costReports && (
                      <InnerCard>
                        <CardTitle title="Evidence of costs" />
                        {data?.costReports.map(item => (
                          <ApplicationFileDownloadWrapper
                            key={item.id}
                            fileName={item.name}
                            fileId={item.id}
                            fileWrapperStyle={{
                              backgroundColor: '#FAFAFA',
                              marginTop: '1rem',
                            }}
                          />
                        ))}
                      </InnerCard>
                    )}
                    {data?.applicantReasonForCostChange && (
                      <InnerCard>
                        <Stack spacing={1}>
                          <CardTitle title="Reason for change" />
                          <Typography fontSize="0.9em">Reason</Typography>
                          <Typography variant="body1" fontWeight={600}>
                            {data.applicantReasonForCostChange}
                          </Typography>
                        </Stack>
                      </InnerCard>
                    )}
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Stack>
        </RoundBorderBox>

        {isExpired && userHasUnlockPaymentPermission && (
          <Box
            sx={{
              padding: '2rem 1rem',
              border: '1px solid lightgray',
              display: 'flex',
              justifyContent: 'flex-end',
              borderRadius: '0 0 8px 8px',
            }}
          >
            <Button variant="outlined" onClick={() => showUnlockModal()}>
              Unlock Expired Request
            </Button>
          </Box>
        )}
      </Stack>

      {isUnlockModalShowing && selectedItem?.id && (
        <UnlockRequestModal
          applicationId={applicationId}
          paymentRequestId={selectedItem?.id}
          onCancel={() => hideUnlockModal()}
          onConfirm={() => hideUnlockModal()}
        />
      )}
    </>
  );
};

const InnerCard = ({ children }: { children: ReactNode }) => (
  <Box bgcolor="grey.100" p={2}>
    {children}
  </Box>
);

const CardTitle = ({ title }: { title: string }) => (
  <Typography fontWeight={600}>{title}</Typography>
);

const InnerPaymentDetailsBox = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => (
  <Box bgcolor={'white'} p={2}>
    <CardTitle title={title} />
    {children}
  </Box>
);
