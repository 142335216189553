import { ReactNode } from 'react';
import {
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TaskAccordion } from '.';
import {
  GetAdminTasksQueryResult,
  GetApplicationTasksQueryResult,
  GetCurrentUserTasksQueryResult,
  useLazyGetAdminTasksCsvQuery,
  useLazyGetApplicationTasksCsvQuery,
  useLazyGetCurrentUserTasksCsvQuery,
} from 'api/task';
import { ErrorAlert } from 'common/components/alerts';
import { Loading } from 'common/components/loading';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import {
  TasksGridContextProvider,
  useTasksGridContext,
} from 'common/components/taskGrid/TasksGridContext';
import { AddEditTaskNoteDrawer } from 'common/components/taskNote';
import { useEllipsisMenu, useLocalSnackbar } from 'hooks';
import { useAdminTaskListGridState } from 'pages/adminTasksPage/utils';
import { useApplicationTaskListGridState } from 'pages/applicationPage/content/applicationTasklist/utils';
import { useAppDispatch } from 'state';
import { editTask } from 'state/slices/task';
import {
  ETaskListConfigType,
  ETaskMenuType,
  IAdminTaskListPaginatedQuery,
  ITaskListPaginatedQueryDefault,
} from 'types/tasks/CmsTaskTypes';
import { extractErrorMessages } from 'util/ApiUtils';

type TasksGridPagination = {
  take: number;
  skip: number;
};

type TasksGridConfig =
  | {
      type: 'CurrentUserTasks';
      query: GetCurrentUserTasksQueryResult;
      navigationComponent: ReactNode;
      pagination: {
        gridStateChanged: (
          newGridState: Partial<ITaskListPaginatedQueryDefault>
        ) => void;
      } & TasksGridPagination;
    }
  | {
      type: 'AdminTasks';
      query: GetAdminTasksQueryResult;
      navigationComponent: ReactNode;
      pagination: {
        gridStateChanged: (
          newGridState: Partial<IAdminTaskListPaginatedQuery>
        ) => void;
      } & TasksGridPagination;
    }
  | {
      type: 'ApplicationTasks';
      query: GetApplicationTasksQueryResult;
      navigationComponent: ReactNode;
      pagination: {
        gridStateChanged: (
          newGridState: Partial<ITaskListPaginatedQueryDefault>
        ) => void;
      } & TasksGridPagination;
    };

export const TasksGrid = ({
  config,
  applicationId,
}: {
  config: TasksGridConfig;
  applicationId?: string;
}) => {
  return (
    <TasksGridContextProvider>
      <TasksGridMain config={config} applicationId={applicationId} />
    </TasksGridContextProvider>
  );
};

const TasksGridMain = ({
  config,
  applicationId,
}: {
  config: TasksGridConfig;
  applicationId?: string;
}) => {
  const dispatch = useAppDispatch();
  const { state: taskListGridState } = useApplicationTaskListGridState();
  const { state: adminTaskListGridState } = useAdminTaskListGridState();
  const {
    type,
    query,
    pagination: { take, skip, gridStateChanged },
  } = config;

  const {
    noteModalState: { addNote },
  } = useTasksGridContext();
  const [triggerAdminTasksCsv] = useLazyGetAdminTasksCsvQuery();
  const [triggerCurrentUserTasksCsv] = useLazyGetCurrentUserTasksCsvQuery();
  const [triggerApplicationTasksCsv] = useLazyGetApplicationTasksCsvQuery();

  const navigate = useNavigate();
  const { createErrorSnackbar } = useLocalSnackbar();

  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const handleMenuItemClick = (menuType: ETaskMenuType) => {
    switch (menuType) {
      case ETaskMenuType.EditTask:
      case ETaskMenuType.ChangeStatus:
        dispatch(editTask(selectedId));
        break;

      case ETaskMenuType.AddNote:
        addNote(selectedId);
        break;

      case ETaskMenuType.OpenApplication:
        const applicationId = query.data?.results.find(
          task => task.taskId === selectedId
        )?.applicationId;
        if (!applicationId) {
          createErrorSnackbar(
            `Could not get application id for task with id: ${selectedId}`
          );
        } else {
          navigate(`/applications/${applicationId}`);
        }

        break;
    }

    handleMenuClose();
  };

  const handleDownloadCsvClick = () => {
    switch (type) {
      case 'AdminTasks':
        triggerAdminTasksCsv({
          type: ETaskListConfigType.AdminTasks,
          query: adminTaskListGridState.gridState,
        });
        break;
      case 'CurrentUserTasks':
        triggerCurrentUserTasksCsv({
          type: ETaskListConfigType.CurrentUserTasks,
          query: taskListGridState.gridState,
        });
        break;
      case 'ApplicationTasks':
        triggerApplicationTasksCsv({
          type: ETaskListConfigType.ApplicationTasks,
          query: taskListGridState.gridState,
          applicationId: applicationId as string,
        });
        break;
    }
  };

  const data = query.data?.results ?? [];

  const noResults = data && data.length === 0;
  const noSearchResultsFound =
    noResults && !!query?.originalArgs?.query.searchValue;

  const paginationUi = (
    <Table sx={{ m: 0 }}>
      <TableBody>
        <TableRow>
          <TablePagination
            count={query.data?.count || 0}
            rowsPerPage={take}
            rowsPerPageOptions={[take]}
            page={skip / take}
            onPageChange={(_, newPage) => {
              gridStateChanged({ skip: newPage * take });
            }}
          />
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <Box>
      {query.isLoading && data.length <= 0 ? <Loading isOpen /> : null}

      {query.isError ? (
        <ErrorAlert msg={extractErrorMessages(query.error)} />
      ) : null}

      {query.isSuccess ? (
        <>
          {config.navigationComponent}
          <NoResultsFoundText show={noSearchResultsFound} />
          <Box>
            {paginationUi}

            {query.isFetching ? <LinearProgress /> : null}

            {data.map(task => (
              <TaskAccordion
                key={task.taskId}
                task={task}
                type={type}
                selectedRowId={selectedId}
                ellipsisMenuAnchor={ellipsisMenuAnchor}
                handleEllipsisClick={handleEllipsisClick}
                handleMenuItemClick={handleMenuItemClick}
                handleMenuClose={handleMenuClose}
              />
            ))}

            {paginationUi}

            <Button variant="contained" onClick={handleDownloadCsvClick}>
              Download CSV
            </Button>
          </Box>
        </>
      ) : null}

      <AddEditTaskNoteDrawer />
    </Box>
  );
};
