import { useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditGrantFundingSignedDocsChecksMutation,
  useGetGrantFundingSignedDocsChecksQuery,
  useGetSignatoriesListQuery,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { FormSkeleton } from 'common/components/skeletons';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ReferApplicationHomesEnglandNotificationBanner } from 'pages/applicationPage/content/applicationGrantFunding/components';
import { useApplicationGrantFundingContext } from 'pages/applicationPage/content/applicationGrantFunding/context';
import { IApplicationGrantFundingSignedDocsChecks } from 'types/applications/ApplicationGrantFundingTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

type FileInputPropName = keyof Pick<
  IApplicationGrantFundingSignedDocsChecks,
  'gfaSignedEvidence' | 'deedOfTrustSignedEvidence'
>;

type FileIdInputName = keyof Pick<
  IApplicationGrantFundingSignedDocsChecks,
  'gfaSignedEvidenceFileId' | 'deedOfTrustSignedEvidenceFileId'
>;

const getName = (fieldName: keyof IApplicationGrantFundingSignedDocsChecks) =>
  nameof<IApplicationGrantFundingSignedDocsChecks>(fieldName);

export const GrantFundingSignedDocumentationChecksForm = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetSignatoriesListQuery(applicationId);

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  if (isSuccess) {
    if (data.length <= 0) {
      return (
        <Alert severity="info" sx={{ m: 2 }}>
          You must add at least one signatory in the Signatories tab before you
          can complete this section.
        </Alert>
      );
    }

    if (data.length > 0) {
      return <GrantFundingSignedDocumentationChecksFormWrapper />;
    }
  }

  return null;
};

const GrantFundingSignedDocumentationChecksFormWrapper = () => {
  const { applicationId } = useApplicationGrantFundingContext();
  const query = useGetGrantFundingSignedDocsChecksQuery(applicationId);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData: IApplicationGrantFundingSignedDocsChecks = {
      ...data,
      gfaSignedEvidenceFileId: data.gfaSignedEvidence?.id ?? '',
      deedOfTrustSignedEvidenceFileId: data.deedOfTrustSignedEvidence?.id ?? '',
    };

    return (
      <GrantFundingSignedDocumentationChecksFormMain
        initialData={initialData}
      />
    );
  }

  return null;
};

const GrantFundingSignedDocumentationChecksFormMain = ({
  initialData,
}: {
  initialData: IApplicationGrantFundingSignedDocsChecks;
}) => {
  const { applicationId, readOnly: isGfaReadOnly } =
    useApplicationGrantFundingContext();

  const [
    editGrantFundingSignedDocsChecks,
    editGrantFundingSignedDocsChecksStatus,
  ] = useEditGrantFundingSignedDocsChecksMutation();
  const { uploadFile } = useFileUpload();

  const [data] = useState<IApplicationGrantFundingSignedDocsChecks | undefined>(
    initialData
  );

  const [gfaSignedEvidenceFileInvalid, setGfaSignedEvidenceFileInvalid] =
    useState<boolean>(false);
  const [
    deedOfTrustSignedEvidenceFileInvalid,
    setDeedOfTrustSignedEvidenceFileInvalid,
  ] = useState<boolean>(false);

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationGrantFundingSignedDocsChecks>({
    defaultValues: initialData,
  });
  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting },
  } = form;

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.GrantFundingAgreement,
  });

  const watchGfaSignedByAllSignatories = watch('gfaSignedByAllSignatories');
  const watchGfaSignedEvidence = watch('gfaSignedEvidence');
  const watchGfaSignedEvidenceFileId = watch('gfaSignedEvidenceFileId');
  const watchDeedOfTrustSignedByAllSignatories = watch(
    'deedOfTrustSignedByAllSignatories'
  );
  const watchDeedOfTrustSignedEvidence = watch('deedOfTrustSignedEvidence');
  const watchDeedOfTrustSignedEvidenceFileId = watch(
    'deedOfTrustSignedEvidenceFileId'
  );

  const validForm = (formData: IApplicationGrantFundingSignedDocsChecks) => {
    let valid = true;
    setGfaSignedEvidenceFileInvalid(false);
    setDeedOfTrustSignedEvidenceFileInvalid(false);

    if (
      formData.gfaSignedByAllSignatories === true &&
      (formData.gfaSignedEvidenceFileId === undefined ||
        formData.gfaSignedEvidenceFileId === '')
    ) {
      setGfaSignedEvidenceFileInvalid(true);
      valid = false;
    }

    if (
      formData.deedOfTrustRequired &&
      formData.deedOfTrustSignedByAllSignatories === true &&
      (formData.deedOfTrustSignedEvidenceFileId === undefined ||
        formData.deedOfTrustSignedEvidenceFileId === '')
    ) {
      setDeedOfTrustSignedEvidenceFileInvalid(true);
      valid = false;
    }

    if (!valid) {
      createWarningSnackbar(
        'Please correct any form validation errors shown, and then try again.'
      );
    }

    return valid;
  };

  const onSubmit = async () => {
    const formData = getValues();
    formData.deedOfTrustRequired = initialData.deedOfTrustRequired;
    if (validForm(formData)) {
      try {
        formData.id = applicationId;
        await editGrantFundingSignedDocsChecks(
          formData as IApplicationGrantFundingSignedDocsChecks
        )
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(
              `Grant Funding Signed Documentation Checks updated successfully`
            );
            reset({}, { keepValues: true });
            form.setValue(`gfaResent`, false);
            form.setValue(`deedOfTrustResent`, false);
          })
          .catch(error => {
            if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(
          `Failed to edit Grant Funding Signed Documentation checks`
        );
      }
    }
  };

  const handleOnFileUpload = async (
    file: File,
    fileInputPropName: FileInputPropName,
    fileIdInputName: FileIdInputName
  ) => {
    await uploadFile({
      file,
      uploadSection: 'GrantFundingSignedDocs',
      onSuccess: payload => {
        form.setValue(fileInputPropName, payload);
        form.setValue(fileIdInputName, payload.id);
      },
    });
  };

  const handleOnDeleteFileUpload = async (
    fileInputPropName: FileInputPropName,
    fileIdInputName: FileIdInputName
  ) => {
    form.setValue(fileInputPropName, null);
    form.setValue(fileIdInputName, '');
  };

  const isReadOnly = isGfaReadOnly;

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Land Registry Checks will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.GrantFundingAgreement} />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertForQueryErrorOrNull
            isError={editGrantFundingSignedDocsChecksStatus.isError}
            error={editGrantFundingSignedDocsChecksStatus.error}
          />
          <AlertForChangedData isDirty={isDirty} />

          <Grid container spacing={1}>
            <Grid xs={9} item>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Box p={3}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Has the Grant Funding Agreement been signed by all
                          signatories?
                        </Typography>
                        <Controller
                          defaultValue={undefined}
                          name={getName('gfaSignedByAllSignatories')}
                          render={({
                            fieldState,
                            field: { onChange, ...fieldProps },
                          }) => (
                            <FormControl>
                              <ToggleButtonGroup
                                {...fieldProps}
                                onChange={(_, val) => {
                                  if (val !== null) {
                                    onChange(val);
                                  }
                                }}
                                exclusive
                                disabled={isReadOnly || isOnHoldForThisType}
                              >
                                <ToggleButton value={true}>Yes</ToggleButton>
                                <ToggleButton value={false}>No</ToggleButton>
                              </ToggleButtonGroup>
                              {fieldState.error?.message ? (
                                <FormHelperText error>
                                  {fieldState.error?.message}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Upload Grant Funding Agreement evidence
                        </Typography>
                        {(watchGfaSignedEvidenceFileId !== undefined &&
                          watchGfaSignedEvidenceFileId ===
                            data?.gfaSignedEvidence?.id) ||
                        isReadOnly ? (
                          <Box display="flex" flexDirection="row">
                            <ApplicationFileDownloadWrapper
                              fileName={
                                watchGfaSignedEvidence?.name ?? 'Download'
                              }
                              fileId={watchGfaSignedEvidenceFileId}
                            />
                            {!isReadOnly ? (
                              <IconButton
                                size="small"
                                disabled={isOnHoldForThisType}
                                onClick={() =>
                                  handleOnDeleteFileUpload(
                                    'gfaSignedEvidence',
                                    'gfaSignedEvidenceFileId'
                                  )
                                }
                                aria-label="Delete File"
                                name="Delete File"
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </IconButton>
                            ) : null}
                          </Box>
                        ) : (
                          <>
                            <FileUploadInputSelect
                              id="gfaSignedEvidenceFile"
                              accept=".pdf"
                              inputName="gfaSignedEvidenceFileId"
                              onFileUpload={file =>
                                handleOnFileUpload(
                                  file,
                                  'gfaSignedEvidence',
                                  'gfaSignedEvidenceFileId'
                                )
                              }
                              onFileDelete={() =>
                                handleOnDeleteFileUpload(
                                  'gfaSignedEvidence',
                                  'gfaSignedEvidenceFileId'
                                )
                              }
                              disabled={isOnHoldForThisType}
                            />
                            {gfaSignedEvidenceFileInvalid && (
                              <FormHelperText error>
                                Grant Funding Agreement document is required.
                              </FormHelperText>
                            )}
                          </>
                        )}
                        {data?.gfaSignedEvidenceUploadDate && (
                          <Typography align="right" paddingRight={5}>
                            {`GFA Evidence Uploaded Date: ${new Date(
                              data?.gfaSignedEvidenceUploadDate
                            )?.toLocaleString('en-GB')}`}
                          </Typography>
                        )}
                      </Grid>
                      {watchGfaSignedByAllSignatories === false && (
                        <>
                          <Grid item mb={2}>
                            <Typography
                              variant="h3"
                              fontWeight={600}
                              fontSize="1em"
                            >
                              Provide Reason
                            </Typography>
                            <Controller
                              name={getName(
                                'gfaNotSignedByAllSignatoriesReason'
                              )}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  error={!!fieldState.error}
                                  required
                                  label="Provide Reason"
                                  helperText={fieldState.error?.message}
                                  multiline
                                  disabled={isReadOnly || isOnHoldForThisType}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item mb={2}>
                            <Typography
                              variant="h3"
                              fontWeight={600}
                              fontSize="1em"
                            >
                              Re-send Grant Funding Agreement to signatories
                            </Typography>
                            <Controller
                              defaultValue={false}
                              name={getName('gfaResent')}
                              render={({
                                fieldState,
                                field: { onChange, value, ...fieldProps },
                              }) => (
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        {...fieldProps}
                                        checked={value}
                                        onChange={(_, val) => {
                                          if (val !== null) onChange(val);
                                        }}
                                        disabled={
                                          isReadOnly || isOnHoldForThisType
                                        }
                                      />
                                    }
                                    label="I have resent Grant Funding Agreement"
                                  />
                                  {fieldState.error?.message ? (
                                    <FormHelperText error>
                                      {fieldState.error?.message}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                              )}
                            />
                            {initialData !== undefined &&
                              initialData?.gfaResendAttempts > 0 && (
                                <Typography
                                  color={({ palette }) => palette.warning.main}
                                >
                                  {`Grant Funding Agreement already re-sent: ${initialData?.gfaResendAttempts}`}
                                </Typography>
                              )}
                          </Grid>
                          <ReferApplicationHomesEnglandNotificationBanner />
                        </>
                      )}
                      {initialData.deedOfTrustRequired && (
                        <>
                          <Grid item mb={2}>
                            <Typography
                              variant="h3"
                              fontWeight={600}
                              fontSize="1em"
                            >
                              Has the Deed Of Trust been signed by all
                              signatories?
                            </Typography>
                            <Controller
                              defaultValue={undefined}
                              name={getName(
                                'deedOfTrustSignedByAllSignatories'
                              )}
                              render={({
                                fieldState,
                                field: { onChange, ...fieldProps },
                              }) => (
                                <FormControl>
                                  <ToggleButtonGroup
                                    {...fieldProps}
                                    onChange={(_, val) => {
                                      if (val !== null) {
                                        onChange(val);
                                      }
                                    }}
                                    exclusive
                                    disabled={isReadOnly || isOnHoldForThisType}
                                  >
                                    <ToggleButton value={true}>
                                      Yes
                                    </ToggleButton>
                                    <ToggleButton value={false}>
                                      No
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                  {fieldState.error?.message ? (
                                    <FormHelperText error>
                                      {fieldState.error?.message}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                              )}
                            />
                          </Grid>
                          {watchDeedOfTrustSignedByAllSignatories === false && (
                            <Grid item mb={2}>
                              <Typography
                                variant="h3"
                                fontWeight={600}
                                fontSize="1em"
                              >
                                Provide Reason
                              </Typography>
                              <Controller
                                name={getName(
                                  'deedOfTrustNotSignedByAllSignatoriesReason'
                                )}
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    error={!!fieldState.error}
                                    required
                                    label="Provide Reason"
                                    helperText={fieldState.error?.message}
                                    multiline
                                    disabled={isReadOnly || isOnHoldForThisType}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          <Grid item mb={2}>
                            <Typography
                              variant="h3"
                              fontWeight={600}
                              fontSize="1em"
                            >
                              Upload Deed of Trust evidence
                            </Typography>
                            {(watchDeedOfTrustSignedEvidenceFileId !==
                              undefined &&
                              watchDeedOfTrustSignedEvidenceFileId ===
                                data?.deedOfTrustSignedEvidence?.id) ||
                            isReadOnly ? (
                              <Box display="flex" flexDirection="row">
                                <ApplicationFileDownloadWrapper
                                  fileName={
                                    watchDeedOfTrustSignedEvidence?.name ??
                                    'Download'
                                  }
                                  fileId={watchDeedOfTrustSignedEvidenceFileId}
                                />
                                {!isReadOnly ? (
                                  <IconButton
                                    size="small"
                                    disabled={isOnHoldForThisType}
                                    onClick={() =>
                                      handleOnDeleteFileUpload(
                                        'deedOfTrustSignedEvidence',
                                        'deedOfTrustSignedEvidenceFileId'
                                      )
                                    }
                                    aria-label="Delete File"
                                    name="Delete File"
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </IconButton>
                                ) : null}
                              </Box>
                            ) : (
                              <>
                                <FileUploadInputSelect
                                  id="deedOfTrustSignedEvidenceFile"
                                  accept=".pdf"
                                  inputName="deedOfTrustSignedEvidenceFileId"
                                  onFileUpload={file =>
                                    handleOnFileUpload(
                                      file,
                                      'deedOfTrustSignedEvidence',
                                      'deedOfTrustSignedEvidenceFileId'
                                    )
                                  }
                                  onFileDelete={() =>
                                    handleOnDeleteFileUpload(
                                      'deedOfTrustSignedEvidence',
                                      'deedOfTrustSignedEvidenceFileId'
                                    )
                                  }
                                  disabled={isOnHoldForThisType}
                                />
                                {deedOfTrustSignedEvidenceFileInvalid && (
                                  <FormHelperText error>
                                    Deed Of Trust document is required.
                                  </FormHelperText>
                                )}
                              </>
                            )}
                            {data?.deedOfTrustSignedEvidenceUploadDate && (
                              <Typography align="right" paddingRight={5}>
                                {`Deed of Trust Evidence Uploaded Date: ${new Date(
                                  data?.deedOfTrustSignedEvidenceUploadDate
                                )?.toLocaleString('en-GB')}`}
                              </Typography>
                            )}
                          </Grid>
                          {watchDeedOfTrustSignedByAllSignatories === false && (
                            <>
                              <Grid item mb={2}>
                                <Typography
                                  variant="h3"
                                  fontWeight={600}
                                  fontSize="1em"
                                >
                                  Re-send Deed of Trust to signatories
                                </Typography>
                                <Controller
                                  defaultValue={false}
                                  name={getName('deedOfTrustResent')}
                                  render={({
                                    fieldState,
                                    field: { onChange, value, ...fieldProps },
                                  }) => (
                                    <FormControl>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            {...fieldProps}
                                            checked={value}
                                            onChange={(_, val) => {
                                              if (val !== null) onChange(val);
                                            }}
                                            disabled={
                                              isReadOnly || isOnHoldForThisType
                                            }
                                          />
                                        }
                                        label="I have resent Deed Of Trust"
                                      />
                                      {fieldState.error?.message ? (
                                        <FormHelperText error>
                                          {fieldState.error?.message}
                                        </FormHelperText>
                                      ) : null}
                                    </FormControl>
                                  )}
                                />
                                {initialData !== undefined &&
                                  initialData?.deedOfTrustResendAttempts >
                                    0 && (
                                    <Typography
                                      color={({ palette }) =>
                                        palette.warning.main
                                      }
                                    >
                                      {`Deed Of Trust already re-sent: ${initialData?.deedOfTrustResendAttempts}`}
                                    </Typography>
                                  )}
                              </Grid>
                              <ReferApplicationHomesEnglandNotificationBanner />
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {!isReadOnly && (
              <Grid container p={3} bgcolor="grey.100">
                <Grid xs={6} item>
                  <Box display="flex" justifyContent="right">
                    <OnOffHold holdType={EOnHoldType.GrantFundingAgreement} />
                  </Box>
                </Grid>
                <Grid xs={6} item>
                  <Box display="flex" justifyContent="right" gap={2}>
                    <Button
                      variant="outlined"
                      onClick={() => reset()}
                      disabled={isOnHoldForThisType}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      disabled={isOnHoldForThisType}
                      type="submit"
                      loading={editGrantFundingSignedDocsChecksStatus.isLoading}
                    >
                      {watchGfaSignedByAllSignatories === false ||
                      watchDeedOfTrustSignedByAllSignatories === false
                        ? 'Save and Refer Application'
                        : 'Save'}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
