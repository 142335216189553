export enum ERiskStatus {
  Low = 1,
  MediumActionRequired = 2,
  High = 3,
  MediumTolerable = 4,
}

export const ERiskStatusLanguage: { [key in ERiskStatus]: string } = {
  [ERiskStatus.Low]: 'Low',
  [ERiskStatus.MediumTolerable]: 'Medium (Tolerable)',
  [ERiskStatus.MediumActionRequired]: 'Medium (Action Required)',
  [ERiskStatus.High]: 'High',
};
