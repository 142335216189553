import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditContactDetailsMutation,
  useGetContactDetailsQuery,
} from 'api/application/applicationEditApi';
import { Loading } from 'common/components/loading';
import { EQuestionType } from 'enums/EQuestionType';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zContactDetailsSchema } from 'types/applications/ApplicationEdit.zod';
import {
  IContactDetails,
  IGetContactDetailsRequest,
} from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IContactDetails) =>
  nameof<IContactDetails>(fieldName);

interface IEditContactDetailsDrawerProps {
  questionType: EQuestionType;
  onClose: () => void;
  onSuccess: () => void;
}

export function EditContactDetailsDrawer({
  questionType,
  onClose,
  onSuccess,
}: IEditContactDetailsDrawerProps) {
  const getDrawerTitle = () => {
    switch (questionType) {
      case EQuestionType.ResponsibleEntityContactDetails:
        return 'Edit What are the contact details of the Responsible Entity';
      case EQuestionType.RepresentativeContactDetails:
        return 'Edit What are the contact details of the Representative';
      case EQuestionType.FreeholderContactDetails:
        return 'Edit What are the contact details of the Freeholder';
      default:
        return 'Edit Contact Details';
    }
  };

  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            {getDrawerTitle()}
          </Typography>
        </DialogTitle>
        <EditContactDetailsForm
          onSuccess={onSuccess}
          onClose={onClose}
          questionType={questionType}
        />
      </Box>
    </StyledDrawer>
  );
}

interface IEditContactDetailsFormProps {
  questionType: EQuestionType;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditContactDetailsForm = ({
  questionType,
  onClose,
  onSuccess,
}: IEditContactDetailsFormProps) => {
  const { applicationId } = useApplicationContext();
  const contactDetailsRequest: IGetContactDetailsRequest = {
    applicationId: applicationId,
    questionType: questionType,
  };

  const { data: contactDetails, isLoading: formLoading } =
    useGetContactDetailsQuery(contactDetailsRequest);
  const [editContactDetails, editContactDetailsResult] =
    useEditContactDetailsMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IContactDetails>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zContactDetailsSchema),
  });
  const { handleSubmit, reset, control } = form;

  useEffect(() => {
    const initialFormData = { ...contactDetails, changeReason: '' };
    reset(initialFormData);
  }, [contactDetails, reset]);

  const onSubmit = async (formData: IContactDetails) => {
    try {
      formData.applicationId = applicationId;
      formData.questionType = questionType;

      await editContactDetails(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Contact details updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit contact details`);
    }
  };

  return formLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <Controller
                  name={getName('firstName')}
                  control={control}
                  defaultValue={contactDetails?.firstName ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="First name"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('lastName')}
                  control={control}
                  defaultValue={contactDetails?.lastName ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Last Name"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('emailAddress')}
                  control={control}
                  defaultValue={contactDetails?.emailAddress ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Email Address"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('contactNumber')}
                  control={control}
                  defaultValue={contactDetails?.contactNumber ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Contact Number"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {questionType !== EQuestionType.FreeholderContactDetails && (
                <Grid item xs={12}>
                  <Controller
                    name={getName('additionalContactNumber')}
                    control={control}
                    defaultValue={contactDetails?.additionalContactNumber ?? ''}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        label="Additional Contact Number"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name={getName('changeReason')}
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Comments"
                      helperText={fieldState.error?.message}
                      multiline
                      autoComplete="off"
                      inputProps={{ maxLength: 1000 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={editContactDetailsResult.isLoading}
              >
                Update Answers
              </LoadingButton>
            </Grid>
          </StyledDrawerActions>
        </form>
      </FormProvider>
    </>
  );
};
