import {
  ECladdingRemediationType,
  ERiskSafetyMitigationType,
  ETaskStatus,
  IGetCladdingSectionResponse,
  IGetCostsScheduleResponse,
  IWorksPackageOverview,
  ISubContractor,
  IProjectTeam,
  IWorksPackageChecksForm,
  IGetCladdingSystemsResult,
  IGetCladdingManufacturersResult,
  IGetCladdingInsulationTypesResult,
  IGetWorksPackageBuildingDetails,
  EResponsibleEntityOrganisationType,
  EWorksPackageStatus,
  EWorkPackageChecksAnswer,
  IGrantCertifyingOfficerAuthorisedSignatories,
  IRegulatoryCompliance,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import {
  EThirdPartyReferrerType,
  IReferralListItem,
  EReferralStatus,
  EReferralType,
  IThirdPartyReferral,
} from 'types/applications/ReferralTypes';
import { getGuid } from 'util/AppUtils';

export const workPackagesOverviewData: IWorksPackageOverview = {
  approvalRejectionDate: '2023-09-10 10:00:00',
  approvalRejectionReason: 'test reason',
  approvalRejectionUser: 'Clem Fandango',
  worksPackageCost: 1300000,
  benchmark: 1320000,
  varianceTolerance: 5,
  dateSubmitted: '2023-09-08',
  status: EWorksPackageStatus.Approved,
  grantCertifyingOfficer: {
    name: 'Officer John',
    companyName: 'Company',
  },
  dutyOfCareDeed: {
    name: 'John Deed',
    companyName: 'Company',
    dateSentToGrantCertifyingOfficer: new Date(),
    dateSignedByGrantCertifyingOfficer: new Date(),
    status: ETaskStatus.InProgress,
  },
  keyDates: {
    startDate: new Date(),
    unsafeCladdingRemovalDate: new Date(),
    expectedDateForCompletion: new Date(),
  },
  buildingDetails: {
    buildingHeightMetres: 15.0,
    replacementType: 'Full remediation',
    responsibleEntityType: 'Private Company',
  },
  buildingControl: {
    buildingControlRequired: true,
    buildingControlForecastSubmissionDate: new Date(),
    buildingControlActualSubmissionDate: new Date(),
    buildingControlValidationDate: new Date(),
    buildingControlDecisionDate: new Date(),
    buildingControlDecision: true,
  },
  planningPermission: {
    requirePlanningPermission: true,
    reasonPlanningPermissionNotRequired: 'Reason for the condition here',
  },
  competitiveBids: {
    competitiveBids: false,
    reasonCompetitiveBidsNotRequired: 'Reason for the condition here',
  },
};

export const grantCertifyingOfficerAuthorisedSignatories: IGrantCertifyingOfficerAuthorisedSignatories = {
  authorisedSignatory1: 'Authorised Signatory',
  authorisedSignatory1EmailAddress: 'Email Address',
  companiesDateOfAppointment: new Date(),
}

export const regulatoryCompliance: IRegulatoryCompliance = {
  regulatoryCompliancePerson: 'Regulatory Compliance',
  role: 'Compliant Role'
}

export const subContractorData: ISubContractor[] = [
  {
    id: '123`',
    companyName: 'Company 1',
    companyRegistration: '123456',
  },
  {
    id: '1233`',
    companyName: 'Company 2',
    companyRegistration: '223456',
  },
  {
    id: '1234`',
    companyName: 'Company 3',
    companyRegistration: '323456',
  },
];

export const projectTeamData: IProjectTeam[] = [
  {
    id: '123',
    companyName: 'Company 1',
    companyRegistration: '123456',
    name: 'Bill House',
    role: 'Project Lead',
    primaryContactNumber: '07768765676',
    contractSigned: true,
    emailAddress: 'billhouse@hotmail.com',
    appropriateLevelsOfProfessionalIndemnity: true,
    fireRiskSafetyEngineerInvolved: false,
    isGrantCertifyingOfficer: true,
    isNew: false,
  },
  {
    id: '1234',
    companyName: 'Company 3',
    companyRegistration: '12356',
    name: 'Bill House',
    role: 'Project Lead',
    primaryContactNumber: '07768765676',
    contractSigned: true,
    emailAddress: 'billhouse@hotmail.com',
    appropriateLevelsOfProfessionalIndemnity: true,
    fireRiskSafetyEngineerInvolved: false,
    isGrantCertifyingOfficer: false,
    isNew: true,
  },
  {
    id: '12345',
    companyName: 'Company 2',
    companyRegistration: '123456',
    name: 'Bill House',
    role: 'Project Lead',
    primaryContactNumber: '07768765676',
    contractSigned: true,
    emailAddress: 'billhouse@hotmail.com',
    appropriateLevelsOfProfessionalIndemnity: true,
    fireRiskSafetyEngineerInvolved: false,
    isGrantCertifyingOfficer: false,
    isNew: false,
  },
];

export const claddingData: IGetCladdingSectionResponse = {
  replacementType: ECladdingRemediationType.Partial,
  safetyRiskMitigationActions: [
    {
      safetyRiskMitigationAction:
        ERiskSafetyMitigationType.CompleteCladdingReplacement,
    },
    {
      safetyRiskMitigationAction:
        ERiskSafetyMitigationType.EncapsulationOfCombustibles,
    },
    {
      safetyRiskMitigationAction: ERiskSafetyMitigationType.Other,
    },
  ],
  safetyRiskOtherText: 'Some other important safety work',
  claddingSystemReplacementDetails: [
    {
      workPackageCostsScheduleCladdingSystemsId: getGuid(),
      remediationType: ECladdingRemediationType.Partial,
      currentCladdingManufacturer: 'ZZ Cladding Inc',
      currentCladdingSystemType:
        'Plastics profiled sheet cladding / covering glass fibre reinforced concrete panel cladding / components',
      currentInsulationType: 'Thermoplastics (eg XPS or Polystyrene)',
      currentInsulationManufacturer: 'Thermodine',
      replacingCladdingSystemType: 'Patent Glazing',
      replacingCladdingManufacturer: 'AC Smith',
      replacingInsulationManufacturer: 'AC Smith',
      replacingInsulationType: 'Insulation Type 2',
      isReplacementCladdingAcm: true,
    },
    {
      workPackageCostsScheduleCladdingSystemsId: getGuid(),
      remediationType: ECladdingRemediationType.Partial,
      currentCladdingManufacturer: 'ZZ Cladding Inc',
      currentCladdingSystemType:
        'Plastics profiled sheet cladding / covering glass fibre reinforced concrete panel cladding / components',
      currentInsulationType: 'Thermoplastics (eg XPS or Polystyrene)',
      currentInsulationManufacturer: 'Thermodine',
      replacingCladdingSystemType: 'Cladding System Option 2',
      replacingCladdingManufacturer: 'Cladding manufacturer Option 2',
      replacingInsulationManufacturer: 'Cladding manufacturer Option 3',
      replacingInsulationType: 'Insulation Type 3',
      isReplacementCladdingAcm: false,
    },
  ],
};

export const costsSchedulingData: IGetCostsScheduleResponse = {
  isAvailable: true,
  currentVersion: 1,
  current: {
    accessAmount: 35000,
    accessDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    contractorContingenciesAmount: 25000,
    contractorContingenciesDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    feasibilityStageAmount: 10000,
    feasibilityStageDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    installationTotal: 220000,
    irrecoverableVatAmount: 20000,
    irrecoverableVatDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    mainContractorOverheadAmount: 50000,
    mainContractorOverheadDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    mainContractorPreliminariesAmount: 10000,
    mainContractorPreliminariesDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    otherCostsTotal: 440000,
    overallTotal: 750000,
    postTenderStageAmount: 20000,
    postTenderStageDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    preliminariesTotal: 150000,
    propertyManagerAmount: 10000,
    propertyManagerDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    unsafeCladdingTotal: 350000,
    externalWorksAmount: 100000,
    externalWorksDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    ineligibleAmount: 50000,
    ineligibleDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    internalWorksAmount: 35000,
    internalWorksDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    newCladdingAmount: 46000,
    newCladdingDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
    unsafeCladdingRemovalAmount: 98000,
    unsafeCladdingRemovalDescription:
      'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
  },
};

export const checksDataPartial: Partial<IWorksPackageChecksForm> = {
  varianceToBenchmarkAcceptable: true,
  acmCladdingBeingRemoved: EWorkPackageChecksAnswer.Yes,
  acmCladdingBeingRemovedComments: 'Something about acm',
};

export const referrals: IReferralListItem[] = [
  {
    id: getGuid(),
    companyName: 'Company 1',
    status: EReferralStatus.InProgress,
    type: EReferralType.HomesEngland,
  },
  {
    id: getGuid(),
    companyName: 'Company 2',
    status: EReferralStatus.Complete,
    type: EReferralType.ThirdParty,
    cost: 123000,
    endDate: '2023-09-01',
    startDate: '2023-08-01',
    thirdPartyReferrerType: EThirdPartyReferrerType.CostConsultant,
  },
  {
    id: getGuid(),
    companyName: 'Company 3',
    status: EReferralStatus.Complete,
    type: EReferralType.ThirdParty,
    cost: 25600,
    endDate: '2023-10-30',
    startDate: '2023-08-01',
    thirdPartyReferrerType: EThirdPartyReferrerType.Legal,
  },
];

export const singleReferral: IThirdPartyReferral = {
  id: getGuid(),
  status: EReferralStatus.InProgress,
  companyEmail: 'email@company1.com',
  companyName: 'Company 1',
  companyPhone: '071717171718',
  cost: 123000,
  createdByUser: 'Steven Toast',
  dateCreated: '2023-09-15',
  description:
    'Duis tempor commodo nisl a fermentum. Quisque mollis vehicula leo quis luctus. Etiam laoreet maximus dapibus. Nam ornare metus laoreet lorem porta congue.',
  endDate: '2023-09-01',
  startDate: '2023-08-01',
  thirdPartyType: EThirdPartyReferrerType.CostConsultant,
};

export const claddingSystems: IGetCladdingSystemsResult = {
  claddingSystems: [
    {
      id: 1,
      name: 'Patent Glazing',
    },
    {
      id: 2,
      name: 'Cladding System Option 2',
    },
  ],
};

export const claddingManufacturers: IGetCladdingManufacturersResult = {
  claddingManufacturers: [
    {
      id: 1,
      name: 'AC Smith',
    },
    {
      id: 2,
      name: 'Cladding manufacturer Option 2',
    },
    {
      id: 3,
      name: 'Cladding manufacturer Option 3',
    },
  ],
};

export const claddingInsulationtypes: IGetCladdingInsulationTypesResult = {
  insulationTypes: [
    {
      id: 1,
      name: 'Insulation Type 2',
    },
    {
      id: 2,
      name: 'Insulation Type 3',
    },
  ],
};

export const buildingDetails: IGetWorksPackageBuildingDetails = {
  appliedUnderHardship: false,
  eligibleUnitCount: 60,
  totalUnitCount: 90,
  percentageOfCosts: 60,
  recommendedPayAmount: 954000,
  responsibleEntityOrgansiationType:
    EResponsibleEntityOrganisationType.LocalAuthority,
};
