import { Box, Typography } from '@mui/material';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { IScheduleOfWorksOverview } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';

export const WorksContractInfoPanel = ({
  title,
  data,
}: {
  title: string;
  data?: IScheduleOfWorksOverview['worksContractDocuments'];
}) => {
  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="body1" color="grey.800" fontSize="0.9em">
        {title}
      </Typography>
      {data &&
        data.map(file => (
          <WorkContractFiles
            key={file.fileId}
            fileName={file.filename}
            fileId={file.fileId}
          />
        ))}
    </Box>
  );
};

const WorkContractFiles = ({
  fileName,
  fileId,
}: {
  fileName: string;
  fileId: string;
}) => {
  return (
    <ApplicationFileDownloadWrapper
      fileName={fileName}
      fileId={fileId}
      fileWrapperStyle={{
        backgroundColor: '#FAFAFA',
      }}
    />
  );
};
