import { Box, Chip, Divider, Typography } from '@mui/material';
import { useGetWorksPackageDeedStatusQuery } from 'api/application/worksPackageDeedApi';
import { ErrorAlert } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { DeedConfirmationUploadSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection/sections/WorksPackageDeedSentSection';
import { extractErrorMessages } from 'util/ApiUtils';

export const WorksPackageDeedSentSection = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isError, error, isSuccess } =
    useGetWorksPackageDeedStatusQuery(applicationId);

  return (
    <RoundBorderBox>
      <Header />
      <Divider />
      <Box>
        {isLoading ? <FormSkeleton /> : null}
        {isError ? <ErrorAlert msg={extractErrorMessages(error)} /> : null}
        {isSuccess ? <DeedConfirmationUploadSection /> : null}
      </Box>
    </RoundBorderBox>
  );
};

const Header = () => {
  const { applicationId } = useApplicationContext();
  const { isSuccess, data } = useGetWorksPackageDeedStatusQuery(applicationId);

  return (
    <Box bgcolor="grey.100" p={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="1.2em" fontWeight={600}>
          Send works package deed
        </Typography>
        {isSuccess ? (
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Typography variant="body1" color="grey.500" fontSize="0.9em">
              Status
            </Typography>
            <Chip
              label={data?.worksPackageDeedSent ? 'Complete' : 'Not Started'}
              color={data?.worksPackageDeedSent ? 'success' : 'default'}
              variant={'filled'}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
