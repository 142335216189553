import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetClosingPaymentRequestOverviewQuery } from 'api/application/paymentRequestApi';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { ErrorAlert } from 'common/components/alerts';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { useClosingPaymentRequestContext } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/context';
import { ClosingPaymentRequestOverviewSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/sections';
import { ClosingChecksSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/sections/ClosingChecksSection';
import { ClosingPaymentRequestChecksSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/sections/ClosingPaymentRequestChecksSection';
import { EClosingPaymentRequestSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { PaymentRequestCosts } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCosts';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';

export const ClosingPaymentRequestSectionOutlet = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const { selectedItemId, readOnly } = useClosingPaymentRequestContext();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const { data } = useGetClosingPaymentRequestOverviewQuery(request);

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.ClosingReport,
  });

  switch (selectedItemId) {
    case EClosingPaymentRequestSection.Overview:
      return <ClosingPaymentRequestOverviewSection />;

    case EClosingPaymentRequestSection.PaymentRequest:
      return (
        <PaymentRequestCosts
          paymentRequestTitle={data?.paymentMonth ? data?.paymentMonth : ''}
          isFinalPayment={true}
          readOnly={readOnly || isOnHoldForThisType}
        />
      );

    case EClosingPaymentRequestSection.ClosingChecks:
      return <ClosingChecksSection />;

    case EClosingPaymentRequestSection.ClosingPaymentRequestChecks:
      return <ClosingPaymentRequestChecksSection />;

    default:
      return (
        <ErrorAlert
          msg={`Could not work out which section to display for id: ${selectedItemId}`}
        />
      );
  }
};
