import { createContext, ReactNode, useContext } from 'react';
import { useGetCurrentUserQuery } from 'api/profile/profile';
import { FormSkeleton } from 'common/components/skeletons';
import { useGridQueryAndState } from 'pages/applicationPage/content/applicationTasklist/utils';

interface IApplicationTasksGridContext {
  query: ReturnType<typeof useGridQueryAndState>['query'];
  state: ReturnType<typeof useGridQueryAndState>['state'];
  gridStateChanged: ReturnType<typeof useGridQueryAndState>['gridStateChanged'];
}

const ApplicationTasksGridContext = createContext<
  IApplicationTasksGridContext | undefined
>(undefined);

interface IApplicationTasksGridContextProviderProps {
  children: ReactNode;
}

export const ApplicationTasksGridContextProvider = ({
  children,
}: IApplicationTasksGridContextProviderProps) => {
  const { isLoading, isSuccess } = useGetCurrentUserQuery();
  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isSuccess) {
    return (
      <ApplicationTasksGridContextProviderMain>
        {children}
      </ApplicationTasksGridContextProviderMain>
    );
  }

  return null;
};

export const ApplicationTasksGridContextProviderMain = ({
  children,
}: IApplicationTasksGridContextProviderProps) => {
  const value = useGridQueryAndState();
  return (
    <ApplicationTasksGridContext.Provider value={value}>
      {children}
    </ApplicationTasksGridContext.Provider>
  );
};

export const useApplicationTasksGridContext = () => {
  const context = useContext(ApplicationTasksGridContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationTasksGridContext must be used within an ApplicationTasksGridContextProvider'
    );
  }
  return context;
};
