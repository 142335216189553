import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetPaginatedBenchmarkFiguresQuery } from 'api/administration/benchmarkFigures';
import { GridSearchField } from 'common/components/gridSearchField';
import { useAppDispatch } from 'state';
import { selectAdministrationBenchmarkFiguresGridState } from 'state/selectors/administration/administrationBenchmarkFiguresSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationBenchmarkFiguresReducer';
import {
  StyledFilter,
  StyledFiltersGridLeft,
} from 'styles/globalStyles/filterStyles';

export const AdministrationBenchmarkFiguresHistoryFilter = () => {
  const dispatch = useAppDispatch();
  const administrationBenchmarkFiguresGridState = useSelector(
    selectAdministrationBenchmarkFiguresGridState
  );
  const { isLoading } = useGetPaginatedBenchmarkFiguresQuery(
    administrationBenchmarkFiguresGridState
  );

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      dispatch(
        gridStateChanged({
          searchValue,
          skip: 0,
        })
      );
    },
    [dispatch]
  );

  return (
    <Grid container pt={4}>
      <StyledFiltersGridLeft item xs={4}>
        <StyledFilter>
          <GridSearchField
            isLoading={isLoading}
            defaultValue={administrationBenchmarkFiguresGridState.searchValue}
            handleSearch={handleSearchCallback}
          />
        </StyledFilter>
      </StyledFiltersGridLeft>
    </Grid>
  );
};
