import { ReactNode } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useGetOrganisationUsersQuery,
  useGetOrganisationsQuery,
} from 'api/administration';
import {
  useEditReferApplicationMutation,
  useGetApplicationEligibilityReferralsQuery,
  useGetEligibilitySummaryQuery,
} from 'api/application';
import { Loading } from 'common/components/loading/Loading';
import {
  EEligibilitySection,
  EEligibilitySectionLanguage,
} from 'enums/EEligibilitySection';
import { EReferralStatus } from 'enums/EReferralStatus';
import { EReferralType } from 'enums/EReferralType';
import { useInvalidateTasks, useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ReferralInformationPanel } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication/ReferralInformation';
import {
  IApplicationEligibilityReferral,
  IEligibilityReferApplicationForm,
  IEligibilityReferApplicationRequest,
} from 'types/applications/ApplicationEligibilityTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IReferApplicationModalProps {
  sectionType: EEligibilitySection;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ReferApplicationModal = (props: IReferApplicationModalProps) => {
  const { sectionType } = props;

  const { isLoading, data: organisationsData } = useGetOrganisationsQuery();

  if (isLoading) {
    return <Loading isOpen />;
  }

  // If the section type is ALL then default to Homes England Org id
  const defaultOrganisationId =
    sectionType === EEligibilitySection.All
      ? organisationsData?.find(
          organisation => organisation.name === 'Homes England'
        )?.id
      : '';

  const initialFormData: IEligibilityReferApplicationForm = {
    organisationId: defaultOrganisationId,
    section: sectionType,
    description: '',
    assigneeId: '',
  };

  return (
    <ReferApplicationModalMain {...props} initialFormData={initialFormData} />
  );
};

const _formId = 'referApplicationForm';

const ReferApplicationModalMain = ({
  sectionType,
  onConfirm,
  onCancel,
  initialFormData,
}: IReferApplicationModalProps & {
  initialFormData: IEligibilityReferApplicationForm;
}) => {
  const { applicationId } = useApplicationContext();

  const {
    createSuccessSnackbar,
    createErrorSnackbar,
    createWarningSnackbar,
    createInfoSnackbar,
  } = useLocalSnackbar();

  const form = useForm<IEligibilityReferApplicationForm>({
    defaultValues: initialFormData,
  });
  const { control, handleSubmit, watch, setValue } = form;

  const [editReferApplication, editState] = useEditReferApplicationMutation();

  const { data: organisationsData } = useGetOrganisationsQuery();

  const { data: referralsData } = useGetApplicationEligibilityReferralsQuery(
    applicationId ?? skipToken
  );

  const { data: eligibilitySummaryData } = useGetEligibilitySummaryQuery(
    applicationId ?? skipToken
  );

  const watchOrgId = watch('organisationId');
  const orgUsersQuery = useGetOrganisationUsersQuery(
    typeof watchOrgId === 'number' ? watchOrgId : skipToken
  );
  const assigneeOptions = orgUsersQuery?.data ?? [];

  var pendingReferrals =
    referralsData?.filter(
      item =>
        (sectionType === EEligibilitySection.All &&
          item.referralType === EReferralType.HomesEngland &&
          item.status === EReferralStatus.Pending) ||
        (sectionType !== EEligibilitySection.All &&
          item.section === sectionType &&
          item.referralType === EReferralType.Applicant &&
          item.status === EReferralStatus.Pending)
    ) ?? [];

  const { invalidateTasks } = useInvalidateTasks();

  const onSubmit = async (formData: IEligibilityReferApplicationForm) => {
    if (typeof formData.organisationId !== 'number') {
      createErrorSnackbar(
        'There was an error with the Organisation selection. Please try again.'
      );
      return;
    }
    if (!formData.assigneeId) {
      createErrorSnackbar(
        'There was an error with the Organisation assignee ID. Please try again.'
      );
      return;
    }
    const referApplicationRequest: IEligibilityReferApplicationRequest = {
      applicationId,
      organisationId: formData.organisationId,
      assigneeId: formData.assigneeId,
      description: formData.description,
      section: sectionType === EEligibilitySection.All ? null : sectionType,
      referralType:
        sectionType === EEligibilitySection.All
          ? EReferralType.HomesEngland
          : EReferralType.Applicant,
    };

    try {
      await editReferApplication(referApplicationRequest)
        .unwrap()
        .then(() => {
          createSuccessSnackbar(`Application successfully referred.`);
          invalidateTasks();
          onConfirm();
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to refer application`);
    }
  };

  const orgOptions =
    organisationsData?.map(org => {
      return { label: org.name, value: org.id };
    }) ?? [];

  const descriptionMandatory =
    referralsData &&
    referralsData.length > 0 &&
    referralsData?.every(item => item.reason === null);

  const onOrganisationChange = (event: SelectChangeEvent<any>) => {
    // When the org changes, reset assignee id
    // This is because the assignee options are users from the selected org
    // So if the org changes we need to clear the assignee
    // If we don't then RHF will still hold the previous value
    // Even though it's not a valid options any more
    setValue('assigneeId', '');
  };

  const cancelButton = (
    <Button variant="outlined" onClick={onCancel}>
      Cancel
    </Button>
  );

  // If the section type is all but we don't have an org id
  // Then it means we couldn't find an org called "Homes England"
  // Therefore show an error
  if (
    sectionType === EEligibilitySection.All &&
    typeof watchOrgId !== 'number'
  ) {
    return (
      <ErrorDialog
        sectionType={sectionType}
        pendingReferrals={pendingReferrals}
        errorMessage={'Could not find an organisation called "Homes England"'}
        cancelButton={cancelButton}
      />
    );
  }

  // If the section type is not all but we don't have any orgs
  // Then we can't go any further therefore show an error
  if (sectionType !== EEligibilitySection.All && orgOptions.length <= 0) {
    return (
      <ErrorDialog
        sectionType={sectionType}
        pendingReferrals={pendingReferrals}
        errorMessage={
          'Could not find any organisations. Please try again later.'
        }
        cancelButton={cancelButton}
      />
    );
  }

  return (
    <DialogWrapper
      sectionType={sectionType}
      pendingReferrals={pendingReferrals}
    >
      {pendingReferrals.length > 0 ? (
        <DialogContent>
          <FormProvider {...form}>
            <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
              <Box pt={1}>
                <Controller
                  control={control}
                  name={'organisationId'}
                  rules={{ required: 'Please choose an Organisation' }}
                  render={({
                    field: { onChange, ...fieldProps },
                    fieldState,
                  }) => (
                    <FormControl
                      sx={{ minWidth: '100%', marginBottom: '1rem' }}
                    >
                      <InputLabel>Organisation</InputLabel>
                      <Select
                        {...fieldProps}
                        fullWidth
                        error={!!fieldState.error}
                        labelId="organisation-label"
                        label="Organisation"
                        input={<OutlinedInput label="Organisation" />}
                        disabled={sectionType === EEligibilitySection.All}
                        onChange={e => {
                          onChange(e);
                          onOrganisationChange(e);
                        }}
                      >
                        {orgOptions?.map(option => {
                          return (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {orgUsersQuery.isFetching ? <LinearProgress /> : null}
                      {fieldState.error?.message ? (
                        <FormHelperText error>
                          {fieldState.error?.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="assigneeId"
                  rules={{ required: 'Please choose an Assignee' }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      sx={{ minWidth: '100%', marginBottom: '1rem' }}
                    >
                      <InputLabel id="assignee-label">Assignee</InputLabel>
                      <Select
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        labelId="assignee-label"
                        label="Assignee"
                        input={<OutlinedInput label="Assignee" />}
                        disabled={orgUsersQuery.isFetching}
                      >
                        {assigneeOptions?.map(option => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              {option.fullName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {orgUsersQuery.isFetching ? <LinearProgress /> : null}

                      {fieldState.error?.message ? (
                        <FormHelperText error>
                          {fieldState.error?.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />
                {referralsData &&
                  eligibilitySummaryData &&
                  pendingReferrals.map(referral => {
                    return (
                      <ReferralInformationPanel
                        key={referral.question}
                        referral={referral}
                        summary={eligibilitySummaryData}
                      />
                    );
                  })}
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      required={descriptionMandatory}
                      label="Description"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      ) : (
        <DialogContent>
          <Alert severity="info" sx={{ mt: 2 }}>
            {`There are currently no pending ${EEligibilitySectionLanguage[sectionType]} referrals for this application.`}
          </Alert>
        </DialogContent>
      )}
      {pendingReferrals.length > 0 ? (
        <DialogActions
          sx={{ px: 3, pb: 2, pt: 0, justifyContent: 'space-between' }}
        >
          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                navigator.clipboard.writeText(form.getValues().description);
                createInfoSnackbar('Description copied to clipboard.');
              }}
            >
              Copy to clipboard
            </Button>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {cancelButton}
            <LoadingButton
              variant="contained"
              type="submit"
              form={_formId}
              loading={editState.isLoading}
            >
              Confirm Details
            </LoadingButton>
          </Box>
        </DialogActions>
      ) : (
        <DialogActions>
          <Grid
            container
            justifyContent="flex-end"
            gap={1}
            sx={{ px: 2, pb: 1 }}
          >
            {cancelButton}
          </Grid>
        </DialogActions>
      )}
    </DialogWrapper>
  );
};

const DialogWrapper = ({
  sectionType,
  pendingReferrals,
  children,
}: {
  sectionType: EEligibilitySection;
  pendingReferrals: IApplicationEligibilityReferral[];
  children: ReactNode;
}) => {
  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>
            {`Refer Application To ${
              sectionType === EEligibilitySection.All
                ? 'Homes England'
                : 'Applicant'
            }`}
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              fontWeight={600}
              style={{ display: 'inline-block', marginRight: '1rem' }}
            >
              Pending referrals
            </Typography>
            <Chip
              label={`${pendingReferrals.length} Referral${
                pendingReferrals.length === 1 ? '' : 's'
              }`}
              color={'warning'}
              variant={'outlined'}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

const ErrorDialog = ({
  sectionType,
  pendingReferrals,
  errorMessage,
  cancelButton,
}: {
  sectionType: EEligibilitySection;
  pendingReferrals: IApplicationEligibilityReferral[];
  errorMessage: string;
  cancelButton: ReactNode;
}) => {
  return (
    <DialogWrapper
      sectionType={sectionType}
      pendingReferrals={pendingReferrals}
    >
      <DialogContent>
        <Alert severity="error">{errorMessage}</Alert>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" gap={1} sx={{ px: 2, pb: 1 }}>
          {cancelButton}
        </Grid>
      </DialogActions>
    </DialogWrapper>
  );
};
