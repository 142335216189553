import { ErrorAlert } from 'common/components/alerts';
import { EDutyOfCareSection } from 'pages/applicationPage/content/dutyOfCare';
import { useDutyOfCareContext } from 'pages/applicationPage/content/dutyOfCare/context';
import {
  DutyOfCareSentSection,
  DutyOfCareSignedSection,
} from 'pages/applicationPage/content/dutyOfCare/sections';
import { DutyOfCareCounterSignedSection } from 'pages/applicationPage/content/dutyOfCare/sections/DutyOfCareCounterSignedSection';

export const DutyOfCareSectionOutlet = () => {
  const { selectedItemId } = useDutyOfCareContext();

  switch (selectedItemId) {
    case EDutyOfCareSection.SendDutyOfCareDeed:
      return <DutyOfCareSentSection />;

    case EDutyOfCareSection.UploadDocuments:
      return <DutyOfCareSignedSection />;

    case EDutyOfCareSection.UploadCounterSignedDocuments:
      return <DutyOfCareCounterSignedSection />;

    default:
      return (
        <ErrorAlert
          msg={`Could not work out which section to display for id: ${selectedItemId}`}
        />
      );
  }
};
