import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface INavigationBarState {
  expanded: boolean;
}

const initialState: INavigationBarState = {
  expanded: false,
};

const navigationBarSlice = createSlice({
  name: 'navigationBar',
  initialState,
  reducers: {
    setIsExpanded: (state, action: PayloadAction<boolean>) => {
      state.expanded = action.payload;
    },
  },
});

export const navigationBarReducer = navigationBarSlice.reducer;
export const { setIsExpanded } = navigationBarSlice.actions;
