import { ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';

interface IPageTitleProps {
  title: string;
  count?: string | number;
  rightContent?: ReactNode;
  gridMarginBottom?: number;
}

export const PageTitle = ({
  title,
  count,
  rightContent,
  gridMarginBottom = 4,
}: IPageTitleProps) => {
  return (
    <Grid
      container
      alignItems="baseline"
      mb={gridMarginBottom}
      justifyContent="space-between"
    >
      <Grid item>
        <Typography variant="h1" display="inline-block">
          {title}
        </Typography>
        {count && (
          <Typography
            component="span"
            sx={{ fontSize: '27px' }}
            fontSize="27px"
            fontWeight="600"
          >
            {`: (${count})`}
          </Typography>
        )}
      </Grid>
      {rightContent ? <Grid item>{rightContent}</Grid> : null}
    </Grid>
  );
};
