import { Button, Divider, TextField, Typography } from '@mui/material';
import { Control, Controller, useWatch } from 'react-hook-form';
import { IPaymentRequestCosts } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { nameof } from 'util/formUtils';

const getName = (fieldName: keyof IPaymentRequestCosts) =>
  nameof<IPaymentRequestCosts>(fieldName);

interface IPaymentRequestSaveModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  control: Control<IPaymentRequestCosts, any>;
}

export function PaymentRequestSaveModal({
  onConfirm,
  onCancel,
  control,
}: IPaymentRequestSaveModalProps) {
  const watchReasonForCostChange = useWatch({
    name: 'reasonForCostChange',
    control,
  });
  const reasonValidationRules = {
    maxLength: { value: 999, message: 'Max length is 1,000' },
    required: { value: true, message: 'Please provide a reason.' },
  };
  return (
    <StyledDialog open fullWidth maxWidth="md">
      <StyledDialogTitle
        sx={{
          textAlign: 'start',
          pt: 5,
          px: 8,
        }}
      >
        Save changes to payment request
      </StyledDialogTitle>
      <Typography py={1} px={8}>
        Please provide the reason for changes
      </Typography>
      <Controller
        name={getName('reasonForCostChange')}
        rules={reasonValidationRules}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            helperText={
              fieldState.error?.message || field.value?.length >= 1000
            }
            multiline
            rows={3}
            label="Provide reason"
            inputProps={{
              maxLength: 1000,
            }}
            sx={{
              backgroundColor: 'grey.100',
              margin: '1rem 0',
              mx: 8,
            }}
          />
        )}
      />
      <Divider />
      <StyledDialogActions
        sx={{
          justifyContent: 'end',
          mt: 2,
          mr: 5,
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          disabled={!watchReasonForCostChange}
        >
          Update Payment Request
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
