export enum EReferralStatus {
  Pending = 1,
  Referred = 2,
  Complete = 3,
}

export const EReferralStatusLanguage: { [key in EReferralStatus]: string } = {
  [EReferralStatus.Pending]: 'Pending',
  [EReferralStatus.Referred]: 'Referred',
  [EReferralStatus.Complete]: 'Complete',
};
