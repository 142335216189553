import { Box, Stack, Typography } from '@mui/material';
import { getGbpAmount } from 'util/AppUtils';

export interface InfoCardProps {
  title: string;
  amount?: number;
  additionalInfo?: string;
  borderLeftColor?: string;
}

export const InfoCard = ({
  title,
  amount,
  additionalInfo,
  borderLeftColor,
}: InfoCardProps) => {
  return (
    <Box
      bgcolor={'grey.100'}
      sx={{
        padding: 2,
        paddingTop: 3,
        borderRadius: 1,
        borderLeftStyle: 'solid',
        borderLeftWidth: '5px',
        borderLeftColor: borderLeftColor ?? 'black',
        flex: 1,
      }}
    >
      <Stack rowGap={3}>
        <Typography variant="h3" fontWeight={600} fontSize="1em">
          {title}
        </Typography>

        <Stack direction="row" alignItems={'center'} gap={2}>
          <Typography variant="body1" fontWeight={600} fontSize="1em">
            {getGbpAmount({ value: amount })}
          </Typography>
          {additionalInfo && (
            <Typography variant="body1" color={'grey.700'} fontSize="0.7em">
              {additionalInfo}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
