import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, useFormContext } from 'react-hook-form';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import { CoreInformationChecksApplicantsEvidencePanel } from 'pages/applicationPage/content/applicationEligibility/components/CoreInformationChecks/InfoPanels';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context/ApplicationEligibilityContext';
import { IApplicationCoreInformationChecks } from 'types/applications/ApplicationEligibilityTypes';
import { nameof } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationCoreInformationChecks) =>
  nameof<IApplicationCoreInformationChecks>(fieldName);

interface ICoreInformationLeaseHolderEngagementProps {
  readOnly?: boolean;
}

export const CoreInformationLeaseHolderEngagement = ({
  readOnly,
}: ICoreInformationLeaseHolderEngagementProps) => {
  const { onReferralQuestion } = useApplicationEligibilityContext();

  const { watch } = useFormContext<IApplicationCoreInformationChecks>();

  const watchHasLeaseholderPersonalDetails = watch(
    'hasLeaseholderPersonalDetails'
  );
  const watchHasAcceptableLeaseholderEngagementEvidence = watch(
    'hasAcceptableLeaseholderEngagementEvidence'
  );

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.CoreInformation,
      question,
      referralType,
      refer
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={9} item>
        <Grid container spacing={1}>
          <Box p={2}>
            <Grid xs={12} item>
              <Typography variant="h2" fontWeight={600}>
                Leaseholder Engagement
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography variant="body1">
                Make sure the applicant hasn't sent any of the leaseholders
                details as this will breach GDPR
              </Typography>
            </Grid>
            <Box p={2}>
              <Grid xs={12} item>
                <Grid item mb={2}>
                  <Typography variant="h3" fontWeight={600} fontSize="1em">
                    Has the applicant uploaded leaseholder personal details?
                  </Typography>
                  <Controller
                    defaultValue={''}
                    name={getName('hasLeaseholderPersonalDetails')}
                    render={({
                      fieldState,
                      field: { onChange, ...fieldProps },
                    }) => (
                      <FormControl>
                        <ToggleButtonGroup
                          {...fieldProps}
                          onChange={(_, val) => {
                            if (val !== null) {
                              onChange(val);
                            }
                            handleReferralQuestion(
                              EEligibilityQuestion.Core_HasLeaseholderPersonalDetails,
                              EReferralType.Applicant,
                              val
                            );
                          }}
                          exclusive
                          disabled={readOnly}
                        >
                          <ToggleButton value={true}>Yes</ToggleButton>
                          <ToggleButton value={false}>No</ToggleButton>
                        </ToggleButtonGroup>
                        {fieldState.error?.message ? (
                          <FormHelperText error>
                            {fieldState.error?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              {watchHasLeaseholderPersonalDetails && (
                <>
                  <Grid item mb={2}>
                    <Typography variant="h3" fontWeight={600} fontSize="1em">
                      Add a note (Optional)
                    </Typography>
                    <Controller
                      name={getName('leaseholderPersonalDetailsNotes')}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          error={!!fieldState.error}
                          label="Add a note (Optional)"
                          helperText={fieldState.error?.message}
                          multiline
                          disabled={readOnly}
                        />
                      )}
                    />
                  </Grid>
                  <ReferApplicationNotificationBanner
                    question={
                      EEligibilityQuestion.Core_HasLeaseholderPersonalDetails
                    }
                    referralType={EReferralType.Applicant}
                    disabled={readOnly}
                  />
                </>
              )}
              <Grid xs={12} item>
                <Grid item mb={2}>
                  <Typography variant="h3" fontWeight={600} fontSize="1em">
                    Has the applicant provided acceptable evidence of
                    leaseholder engagment?
                  </Typography>
                  <Controller
                    defaultValue={''}
                    name={getName('hasAcceptableLeaseholderEngagementEvidence')}
                    render={({
                      fieldState,
                      field: { onChange, ...fieldProps },
                    }) => (
                      <FormControl>
                        <ToggleButtonGroup
                          {...fieldProps}
                          onChange={(_, val) => {
                            if (val !== null) {
                              onChange(val);
                            }
                            handleReferralQuestion(
                              EEligibilityQuestion.Core_HasAcceptableLeaseholderEngagementEvidence,
                              EReferralType.Applicant,
                              !val
                            );
                          }}
                          exclusive
                          disabled={readOnly}
                        >
                          <ToggleButton value={true}>Pass</ToggleButton>
                          <ToggleButton value={false}>Fail</ToggleButton>
                        </ToggleButtonGroup>
                        {fieldState.error?.message ? (
                          <FormHelperText error>
                            {fieldState.error?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              {watchHasAcceptableLeaseholderEngagementEvidence && (
                <>
                  <Grid item mb={2}>
                    <Typography variant="h3" fontWeight={600} fontSize="1em">
                      Add a note
                    </Typography>
                    <Controller
                      name={getName('leaseholderEngagementEvidenceNotes')}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          error={!!fieldState.error}
                          required
                          label="Add a note"
                          helperText={fieldState.error?.message}
                          multiline
                          disabled={readOnly}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {watchHasAcceptableLeaseholderEngagementEvidence === false && (
                <>
                  <Grid item mb={2}>
                    <Typography variant="h3" fontWeight={600} fontSize="1em">
                      Add a note
                    </Typography>
                    <Controller
                      name={getName('leaseholderEngagementEvidenceNotes')}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          error={!!fieldState.error}
                          required
                          label="Add a note"
                          helperText={fieldState.error?.message}
                          multiline
                          disabled={readOnly}
                        />
                      )}
                    />
                  </Grid>
                  <ReferApplicationNotificationBanner
                    question={
                      EEligibilityQuestion.Core_HasAcceptableLeaseholderEngagementEvidence
                    }
                    referralType={EReferralType.Applicant}
                    disabled={readOnly}
                  />
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid xs={3} item>
        <CoreInformationChecksApplicantsEvidencePanel />
      </Grid>
    </Grid>
  );
};
