import { administrationApi } from 'api/administration/administrationApi';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IAdministrationBenchmarkFiguresGridItem,
  ICurrentBenchmarkFigures,
  IGetAdministrationBenchmarkFigureListQuery,
} from 'types/administration/AdministrationBenchmarkFiguresTypes';
import { QueryTags } from 'util/ApiUtils';
import { getUrlSearchParamFromQueryData } from 'util/QueryUtils';

const getPaginatedBenchmarkFiguresQueryString = (
  query: IGetAdministrationBenchmarkFigureListQuery
) => {
  const queryParamsStr = getUrlSearchParamFromQueryData({
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  });

  return `/benchmarkfigures/history?${queryParamsStr.toString()}`;
};

const benchmarkFiguresEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getPaginatedBenchmarkFigures: builder.query<
      IPaginationResponse<IAdministrationBenchmarkFiguresGridItem>,
      IGetAdministrationBenchmarkFigureListQuery
    >({
      providesTags: [QueryTags.BenchmarkFigures],
      query: query => {
        return getPaginatedBenchmarkFiguresQueryString(query);
      },
    }),
    getCurrentBenchmarkFigures: builder.query<ICurrentBenchmarkFigures, void>({
      providesTags: [QueryTags.BenchmarkFigures],
      query: () =>
        `${process.env.REACT_APP_API_URL}/api/admin/benchmarkfigures`,
    }),
    editCurrentBenchmarkFigures: builder.mutation<
      unknown,
      ICurrentBenchmarkFigures
    >({
      invalidatesTags: [QueryTags.BenchmarkFigures],
      query: (payload) => ({
        url: `/benchmarkfigures`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaginatedBenchmarkFiguresQuery,
  useGetCurrentBenchmarkFiguresQuery,
  useEditCurrentBenchmarkFiguresMutation,
} = benchmarkFiguresEndpoints;
