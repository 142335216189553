import { useRef } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  Button,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { BuildingRecordForm } from 'pages/pullInProcessesPage/components/buildingRecordForm';
import { usePullInProcessesContext } from 'pages/pullInProcessesPage/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { TAddBuildingRecord } from 'types/pullInProcess/addBuildingRecordTypes';

export function AddBuildingRecord() {
  const { hideModal, isShowing, modalData } = usePullInProcessesContext();
  const formRef = useRef<ReturnType<typeof useForm<TAddBuildingRecord>>>(null);
  const formId = 'addBuildingFormId';

  const isAddMode = modalData === null;
  return (
    <StyledDrawer anchor="right" open={isShowing}>
      <DialogTitle component="div">
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h1" component="span">
            {isAddMode ? 'Add' : 'View'} building record
          </Typography>
          <IconButton
            onClick={hideModal}
            aria-label="Close drawer"
            name="Close drawer"
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <BuildingRecordForm
          formId={formId}
          ref={formRef}
          hideModal={hideModal}
        />
      </DialogContent>
      <StyledDrawerActions>
        <Grid container justifyContent="flex-end" gap={1}>
          <Button variant="outlined" onClick={hideModal}>
            Cancel
          </Button>

          <LoadingButton variant="contained" type="submit" form={formId}>
            Save
          </LoadingButton>
        </Grid>
      </StyledDrawerActions>
    </StyledDrawer>
  );
}
