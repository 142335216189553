import { useState } from 'react';

export const useEllipsisMenu = () => {
  const [selectedId, setSelectedId] = useState('');
  const [ellipsisMenuAnchor, setEllipsisMenuAnchor] =
    useState<null | HTMLElement>(null);

  const handleEllipsisClick = (
    id: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedId(id);
    setEllipsisMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSelectedId('');
    setEllipsisMenuAnchor(null);
  };

  return {
    selectedId,
    setSelectedId,
    ellipsisMenuAnchor,
    handleEllipsisClick,
    handleMenuClose,
  };
};
