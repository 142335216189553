import { applicationApi } from 'api/application/applicationApi';
import {
  IDutyOfCareSentStatus,
  IDutyOfCareSend,
  IDutyOfCareSign,
  IDutyOfCareSignedStatus,
  IDutyOfCareCounterSign,
  IDutyOfCareCounterSignedStatus,
} from 'pages/applicationPage/content/dutyOfCare/DutyOfCareTypes';
import { QueryTags } from 'util/ApiUtils';

export const dutyOfCareEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getDutyOfCareStatus: builder.query<IDutyOfCareSentStatus, string>({
      providesTags: [QueryTags.ApplicationDutyOfCareDetails],
      query: applicationId => `${applicationId}/dutyofcare/sent`,
    }),
    getDutyOfCareSignedStatus: builder.query<IDutyOfCareSignedStatus, string>({
      providesTags: [QueryTags.ApplicationDutyOfCareDetails],
      query: applicationId => `${applicationId}/dutyofcare/signed`,
    }),
    updateDutyOfCareStatus: builder.mutation<void, IDutyOfCareSend>({
      invalidatesTags: [QueryTags.ApplicationDutyOfCareDetails],
      query: ({ applicationId, dutyOfCareSent, sentEvidenceFileId }) => {
        return {
          url: `${applicationId}/dutyofcare/sent`,
          method: 'PUT',
          body: { dutyOfCareSent, sentEvidenceFileId },
        };
      },
    }),
    updateDutyOfCareSignedStatus: builder.mutation<void, IDutyOfCareSign>({
      invalidatesTags: [QueryTags.ApplicationDutyOfCareDetails],
      query: ({ applicationId, dutyOfCareSigned, signedDutyOfCareFileId }) => {
        return {
          url: `${applicationId}/dutyofcare/signed`,
          method: 'PUT',
          body: { dutyOfCareSigned, signedDutyOfCareFileId },
        };
      },
    }),
    getDutyOfCareCounterSignedStatus: builder.query<IDutyOfCareCounterSignedStatus, string>({
      providesTags: [QueryTags.ApplicationDutyOfCareDetails],
      query: applicationId => `${applicationId}/dutyofcare/countersigned`,
    }),
    updateDutyOfCareCounterSignedStatus: builder.mutation<void, IDutyOfCareCounterSign>({
      invalidatesTags: [QueryTags.ApplicationDutyOfCareDetails],
      query: ({ applicationId, dutyOfCareCounterSigned, counterSignedDutyOfCareFileId }) => {
        return {
          url: `${applicationId}/dutyofcare/countersigned`,
          method: 'PUT',
          body: { dutyOfCareCounterSigned, counterSignedDutyOfCareFileId },
        };
      },
    }),
  }),
});

export const {
  useGetDutyOfCareSignedStatusQuery,
  useGetDutyOfCareStatusQuery,
  useUpdateDutyOfCareSignedStatusMutation,
  useUpdateDutyOfCareStatusMutation,

  useGetDutyOfCareCounterSignedStatusQuery,
  useUpdateDutyOfCareCounterSignedStatusMutation,
} = dutyOfCareEndpoints;
