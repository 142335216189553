import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditRecommendCladdingMutation } from 'api/application/applicationEditApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zRecommendCladdingSchema } from 'types/applications/ApplicationEdit.zod';
import { IRecommendCladding } from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IRecommendCladding) =>
  nameof<IRecommendCladding>(fieldName);

interface IEditRecommendCladdingDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
  currentValue: string;
}

export function EditRecommendCladdingDrawer({
  onClose,
  onSuccess,
  currentValue,
}: IEditRecommendCladdingDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">Edit Recommend Cladding</Typography>
          <Typography variant="h3">{currentValue}</Typography>
        </DialogTitle>
        <EditRecommendCladdingForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditRecommendCladdingFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditRecommendCladdingForm = ({
  onClose,
  onSuccess,
}: IEditRecommendCladdingFormProps) => {
  const { applicationId } = useApplicationContext();

  const [editRecommendCladding, editRecommendCladdingResult] =
    useEditRecommendCladdingMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IRecommendCladding>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zRecommendCladdingSchema),
  });
  const { handleSubmit, reset } = form;

  const onSubmit = async (formData: IRecommendCladding) => {
    try {
      formData.applicationId = applicationId;
      await editRecommendCladding(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Recommend cladding updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit recommend cladding`);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" fontWeight={600} fontSize="1em">
                Recommend Cladding?
              </Typography>
              <Controller
                defaultValue={undefined}
                name={getName('recommendCladding')}
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => (
                  <FormControl>
                    <ToggleButtonGroup
                      {...fieldProps}
                      onChange={(_, val) => {
                        if (val !== null) {
                          onChange(val);
                        }
                      }}
                      exclusive
                    >
                      <ToggleButton value={1}>Full</ToggleButton>
                      <ToggleButton value={2}>Partial</ToggleButton>
                      <ToggleButton value={3}>
                        None Or Mitigation Works
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {fieldState.error?.message ? (
                      <FormHelperText error>
                        {fieldState.error?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '2rem' }}>
              <Controller
                name={getName('changeReason')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="Comments"
                    helperText={fieldState.error?.message}
                    multiline
                    autoComplete="off"
                    inputProps={{ maxLength: 1000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editRecommendCladdingResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
