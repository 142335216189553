import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme
} from "@mui/material";

export const muiTabsTheme: {
  defaultProps?: ComponentsProps["MuiTabs"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTabs"];
  variants?: ComponentsVariants["MuiTabs"];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      "& .MuiTabs-indicator": {
        display: "none",
      },
    }),
  },
};
