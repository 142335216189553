import { SxProps, Typography } from '@mui/material';
import { useLazyGetDownloadPullInProcessDocumentByIdQuery } from 'api/pullInProcess';
import { FileDownload } from 'common/components/fileDownload';
import {
  EPullinProcessDocumentType,
  EPullinProcessDocumentTypeLanguage,
} from 'enums/EPullinProcessDocumentTypes';
import { useLocalSnackbar } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';

export const PullInFileDownloadWrapper = ({
  showIconOnly,
  fileName,
  pullInDocumentTypeId,
  fileWrapperStyle,
  pullInDocumentType,
}: {
  showIconOnly?: boolean;
  fileName: string;
  pullInDocumentTypeId: string;
  fileWrapperStyle?: SxProps;
  pullInDocumentType: EPullinProcessDocumentType;
}) => {
  const { pullInProcessId } = usePullInProcessContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const [trigger, result] = useLazyGetDownloadPullInProcessDocumentByIdQuery();

  const handleClick = () => {
    if (!pullInDocumentTypeId) {
      createErrorSnackbar(
        `Could not get file. ${EPullinProcessDocumentTypeLanguage[pullInDocumentType]} id is empty`
      );
      return;
    }

    trigger({
      buildingId: pullInProcessId,
      pullInDocumentTypeId,
      fileName,
      pullInDocumentType,
    });
  };

  return (
    <FileDownload
      isError={result.isError}
      error={result.error}
      isLoading={result.isLoading || result.isFetching}
      displayValue={<AnswerString value={fileName} />}
      showIconOnly={showIconOnly}
      handleClick={handleClick}
      fileWrapperStyle={fileWrapperStyle}
    />
  );
};

const AnswerString = ({ value }: { value: string }) => {
  return <Typography fontSize="0.9em">{value}</Typography>;
};
