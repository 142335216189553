import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { useGetApplicationSummaryQuery } from 'api/application';
import { useLazyGetWorksPackageCostsScheduleCsvQuery } from 'api/application/worksPackageApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export function CostScheduleCSVButton() {
  const { applicationId } = useApplicationContext();

  const applicationSummaryQuery = useGetApplicationSummaryQuery(applicationId);

  const [downloadCsv, { isLoading }] =
    useLazyGetWorksPackageCostsScheduleCsvQuery();

  const { createSuccessSnackbar } = useLocalSnackbar();

  const handleClick = () => {
    downloadCsv({
      applicationId,
      fileName: `${
        applicationSummaryQuery.data?.referenceNumber ?? ''
      }WorksPackageCostsScheduleDownload.csv`,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Your file was downloaded');
      });
  };

  return (
    <LoadingButton
      variant="outlined"
      startIcon={<FontAwesomeIcon icon={faDownload} />}
      loading={isLoading}
      onClick={handleClick}
    >
      Download CSV
    </LoadingButton>
  );
}
