export type TBuildingDocument = {
  documentId: string;
  // title: string;
  documentTypeId: EPullInDocumentType;
  otherDocumentType: string;
  // fileId: string;
  uploadDate: Date;
  uploadedBy: string;
  attachmentFile: { name: string; id: string };
};

export type TBuildingDocumentForm = {
  title: string;
  documentTypeId: EPullInDocumentType;
  otherDocumentType: string;
  fileId: string;
};

export enum EPullInDocumentType {
  TitleDocument = 1,
  QualifyingLease = 2,
  IncorporationDocument = 3,
  Email = 4,
  Other = 5,
}

export const EPullInDocumentTypeLanguage = {
  [EPullInDocumentType.TitleDocument]: 'Title Document',
  [EPullInDocumentType.QualifyingLease]: 'Qualifying lease',
  [EPullInDocumentType.IncorporationDocument]: 'Incorporation Document',
  [EPullInDocumentType.Email]: 'Email',
  [EPullInDocumentType.Other]: 'Other',
} as const;
