import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { PaymentRequestCostsSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsSection';

export const PaymentRequestCosts = ({
  paymentRequestTitle = '',
  isFinalPayment,
  readOnly,
}: {
  paymentRequestTitle: string;
  isFinalPayment: boolean;
  readOnly: boolean;
}) => {
  const { selectedItem } = useApplicationLiveProjectContext();

  return (
    <PaymentRequestCostsSection
      paymentRequestId={selectedItem?.id}
      paymentRequestTitle={paymentRequestTitle}
      isFinalPayment={isFinalPayment}
      readOnly={readOnly}
    />
  );
};
