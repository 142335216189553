import { useState } from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppDispatch } from 'state';
import { createNewTask } from 'state/slices/task';

export const AddTaskIcon = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const isMenuOpen = Boolean(menuAnchorEl);

  const dispatch = useAppDispatch();

  const handleCreateNewTaskClick = () => {
    handleCloseMenu();
    dispatch(createNewTask(null));
  };

  return (
    <Box>
      <IconButton size="small" color="inherit" onClick={handleMenuOpen} aria-label="Add task menu" name="Add task">
        <FontAwesomeIcon icon={faPlusCircle} />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isMenuOpen}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCreateNewTaskClick} aria-label="Add task">Add Task</MenuItem>
      </Menu>
    </Box>
  );
};
