import { rest } from 'msw';
import {
  applicationContactDetailsData,
  applicationInterventionHistory,
  applicationRagRatingHistory,
  applicationReasonsDataJourneyTimeline,
  applicationsData,
  applicationsKpiData,
  applicationSummaryData,
  closeApplicationsReasonsData,
  holdLatestData,
  interventionCategoriesData,
  interventionCategoryData,
  ragRatingData,
  ragRatingsData,
  thirdPartyReferrers,
} from 'mocks/data/application/applicationData';
import {
  applicationDetailsData,
  applicationSectionData,
} from 'mocks/data/application/applicationDetailsData';

export const applicationHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/grid`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationsData));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/kpis`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationsKpiData));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/summary`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationSummaryData));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/applicant/contactDetails`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationContactDetailsData));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/details`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationDetailsData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/section/:sectionId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationSectionData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/file/:fileId`,
    async (_, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.delay(3000),
          ctx.status(200),
          ctx.set('Content-Type', 'application/pdf'),
          ctx.body(new Blob(['testing'], { type: 'application/pdf' }))
        );
      }
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/referrals/thirdpartyreferrers`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(thirdPartyReferrers));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/hold/latest`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(holdLatestData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/close/reasons`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(closeApplicationsReasonsData));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/close`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(2000), ctx.status(204));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/journeyTimeline`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationReasonsDataJourneyTimeline)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/intervention/categories`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(interventionCategoriesData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/intervention`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(interventionCategoryData));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/intervention`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(2000), ctx.status(204));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/intervention/remove`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(2000), ctx.status(204));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/intervention/history`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationInterventionHistory));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/ragRatings`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(ragRatingsData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/ragRating`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(ragRatingData));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/ragRating`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(2000), ctx.status(204));
      }
      return req.passthrough();
    }
  ),


  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/ragRating/history`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationRagRatingHistory));
      }
      return req.passthrough();
    }
  ),
];
