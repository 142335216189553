import { Chip } from '@mui/material';
import { ELiveProjectItemStatus, ELiveProjectItemStatusLanguage } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';

const getChipColour = (status: ELiveProjectItemStatus) => {
  if (status === ELiveProjectItemStatus.Approved) {
    return 'success';
  } else if (status === ELiveProjectItemStatus.InProgress) {
    return 'info';
  } else if (status === ELiveProjectItemStatus.Rejected) {
    return 'error';
  }
  return 'default';
};

export const ProgressReportChip = ({
  status,
}: {
  status: ELiveProjectItemStatus;
}) => {
  return (
    <Chip
      label={ELiveProjectItemStatusLanguage[status]}
      size="small"
      color={getChipColour(status)}
    />
  );
};
