import { applicationApi } from 'api/application/applicationApi';
import {
  IWorksPackageDeedCardDetail,
  IWorksPackageDeedCounterSign,
  IWorksPackageDeedCounterSignedStatus,
  IWorksPackageDeedDetail,
  IWorksPackageDeedSend,
  IWorksPackageDeedSentStatus,
  IWorksPackageDeedSign,
  IWorksPackageDeedSignedStatus,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection/WorksPackageDeedTypes';
import { QueryTags } from 'util/ApiUtils';

export const worksPackageDeedEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getWorksPackageDeedStatus: builder.query<
      IWorksPackageDeedSentStatus,
      string
    >({
      providesTags: [QueryTags.ApplicationWorksPackageDeedDetails],
      query: applicationId => `${applicationId}/workpackage/deed/sent`,
    }),
    getworksPackageDeedSignedStatus: builder.query<
      IWorksPackageDeedSignedStatus,
      string
    >({
      providesTags: [QueryTags.ApplicationWorksPackageDeedDetails],
      query: applicationId => `${applicationId}/workpackage/deed/signed`,
    }),
    getworksPackageDeedCardDetail: builder.query<
      IWorksPackageDeedCardDetail,
      string
    >({
      providesTags: [QueryTags.ApplicationWorksPackageDeedDetails],
      query: applicationId => `${applicationId}/workpackage/deed/cardDetail`,
    }),
    getworksPackageDeedDetail: builder.query<IWorksPackageDeedDetail, string>({
      providesTags: [QueryTags.ApplicationWorksPackageDeedDetails],
      query: applicationId => `${applicationId}/workpackage/deed/detail`,
    }),
    updateWorksPackageDeedStatus: builder.mutation<void, IWorksPackageDeedSend>(
      {
        invalidatesTags: [
          QueryTags.ApplicationWorksPackageDeedDetails,
          QueryTags.ApplicationStatus,
        ],
        query: ({
          applicationId,
          worksPackageDeedSent,
          sentEvidenceFileId,
        }) => {
          return {
            url: `${applicationId}/workpackage/deed/sent`,
            method: 'PUT',
            body: { worksPackageDeedSent, sentEvidenceFileId },
          };
        },
      }
    ),
    updateworksPackageDeedSignedStatus: builder.mutation<
      void,
      IWorksPackageDeedSign
    >({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageDeedDetails,
        QueryTags.ApplicationStatus,
      ],
      query: ({
        applicationId,
        worksPackageDeedSigned,
        signedWorksPackageDeedFileId,
      }) => {
        return {
          url: `${applicationId}/workpackage/deed/signed`,
          method: 'PUT',
          body: { worksPackageDeedSigned, signedWorksPackageDeedFileId },
        };
      },
    }),
    getWorksPackageDeedCounterSignedStatus: builder.query<
      IWorksPackageDeedCounterSignedStatus,
      string
    >({
      providesTags: [QueryTags.ApplicationWorksPackageDeedDetails],
      query: applicationId => `${applicationId}/workpackage/deed/countersigned`,
    }),
    updateWorksPackageDeedCounterSignedStatus: builder.mutation<
      void,
      IWorksPackageDeedCounterSign
    >({
      invalidatesTags: [
        QueryTags.ApplicationWorksPackageDeedDetails,
        QueryTags.ApplicationStatus,
      ],
      query: ({
        applicationId,
        counterSigned,
        counterSignedFileId,
      }) => {
        return {
          url: `${applicationId}/workpackage/deed/countersigned`,
          method: 'PUT',
          body: {
            counterSigned,
            counterSignedFileId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetworksPackageDeedSignedStatusQuery,
  useGetWorksPackageDeedStatusQuery,
  useUpdateworksPackageDeedSignedStatusMutation,
  useUpdateWorksPackageDeedStatusMutation,
  useGetworksPackageDeedCardDetailQuery,
  useGetworksPackageDeedDetailQuery,
  useGetWorksPackageDeedCounterSignedStatusQuery,
  useUpdateWorksPackageDeedCounterSignedStatusMutation,
} = worksPackageDeedEndpoints;
