import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  DialogContent,
  Grid,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Button,
  Box,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditOriginalDeveloperMutation,
  useGetOriginalDeveloperQuery,
} from 'api/application/applicationEditApi';
import { FieldErrorOrNull } from 'common/components/fieldErrorOrNull';
import { EDeveloperInBusinessType } from 'enums/EDeveloperInBusinessType';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { FieldLabel } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zOriginalDeveloperSchema } from 'types/applications/ApplicationEdit.zod';
import { IOriginalDeveloperForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditOriginalDeveloperDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditOriginalDeveloperDrawer = ({
  onClose,
  onSuccess,
}: IEditOriginalDeveloperDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit Do you know who the original developer for this building was?
          </Typography>
          {/* <Typography variant="h3">{currentValue}</Typography> */}
        </DialogTitle>
        <EditOriginalDeveloperForm onClose={onClose} onSuccess={onSuccess} />
      </Box>
    </StyledDrawer>
  );
};

interface IEditOriginalDeveloperFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditOriginalDeveloperForm = ({
  onClose,
  onSuccess,
}: IEditOriginalDeveloperFormProps) => {
  const { applicationId } = useApplicationContext();

  const options: { id: EDeveloperInBusinessType; name: string }[] = [
    { id: EDeveloperInBusinessType.Yes, name: 'Yes' },
    { id: EDeveloperInBusinessType.No, name: 'No' },
    { id: EDeveloperInBusinessType.DontKnow, name: "Don't know" },
  ];

  const { data, isLoading } = useGetOriginalDeveloperQuery(applicationId);

  const [editOriginalDeveloper, editOriginalDeveloperResult] =
    useEditOriginalDeveloperMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IOriginalDeveloperForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: data || undefined,
    resolver: zodResolver(zOriginalDeveloperSchema),
  });
  const { handleSubmit, reset, control, watch, setValue } = form;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const onSubmit = async (formData: IOriginalDeveloperForm) => {
    try {
      formData.applicationId = applicationId;
      await editOriginalDeveloper(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar(`Original developer updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit original developer`);
    }
  };

  return isLoading ? (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} mb={2}>
              <FieldLabel label="Do you know who the original developer for this building was?" />
              <Controller
                control={control}
                name="isOriginalDeveloperKnown"
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => (
                  <>
                    <FormControl>
                      <ToggleButtonGroup
                        {...fieldProps}
                        onChange={(_, val) => {
                          onChange(val);
                          setValue('developerCompanyName', '');
                          setValue('isStillInBusiness', null);
                          setValue('hasContactedDeveloper', null);
                        }}
                        exclusive
                      >
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                    <FieldErrorOrNull
                      fieldState={fieldState}
                      sx={{ ml: '14px' }}
                    />
                  </>
                )}
              />
            </Grid>

            {watch('isOriginalDeveloperKnown') ? (
              <>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name="developerCompanyName"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        label="Developer company name"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <FieldLabel label="Do you know if the original developer of this building is still in business?" />
                  <Controller
                    control={control}
                    name="isStillInBusiness"
                    defaultValue={null}
                    render={({
                      fieldState,
                      field: { onChange, ...fieldProps },
                    }) => (
                      <>
                        <FormControl>
                          <ToggleButtonGroup
                            {...fieldProps}
                            onChange={(_, val) => {
                              onChange(val);
                            }}
                            exclusive
                          >
                            {options.map(opt => (
                              <ToggleButton key={opt.id} value={opt.id}>
                                {opt.name}
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                        </FormControl>
                        <FieldErrorOrNull
                          fieldState={fieldState}
                          sx={{ ml: '14px' }}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <FieldLabel label="Have you contacted the developer about remediation works on this building (addressing fire safety risks related to unsafe non-ACM cladding)?" />
                  <Controller
                    control={control}
                    name="hasContactedDeveloper"
                    defaultValue={null}
                    render={({
                      fieldState,
                      field: { onChange, ...fieldProps },
                    }) => (
                      <>
                        <FormControl>
                          <ToggleButtonGroup
                            {...fieldProps}
                            onChange={(_, val) => {
                              onChange(val);
                            }}
                            exclusive
                          >
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                        </FormControl>
                        <FieldErrorOrNull
                          fieldState={fieldState}
                          sx={{ ml: '14px' }}
                        />
                      </>
                    )}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editOriginalDeveloperResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
