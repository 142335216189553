import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

export const muiTableTheme: {
  defaultProps?: ComponentsProps['MuiTable'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTable'];
  variants?: ComponentsVariants['MuiTable'];
} = {
  styleOverrides: {
    root: {
      borderCollapse: 'separate',
      borderSpacing: '0 9px',
      background: 'white',
      fontSize: '15px',
      overflow: 'visible',
      boxShadow: 'none',
      marginTop: '1rem',
      thead: {
        tr: {
          border: 0,
        },
        th: {
          background: 'none',
          border: 0,
          fontSize: 12,
          textTransform: 'uppercase',
          color: '#5a5a5a',
          paddingTop: 0,
          paddingBottom: 0,
          lineHeight: '1.2em',
          whiteSpace: 'nowrap',
        },
      },
      tbody: {
        tr: {
          boxShadow: '0 0 30px rgba(0,0,0,0.05), 0 0 2px rgba(0,0,0,0.1)',
          borderRadius: 6,
        },
        td: {
          border: 0,
          paddingTop: 5,
          paddingBottom: 5,
          fontSize: '16px',
          '&:first-of-type': {
            borderTopLeftRadius: '6px',
            borderBottomLeftRadius: '6px',
          },
          '&:last-child': {
            borderTopRightRadius: '6px',
            borderBottomRightRadius: '6px',
          },
        },
      },
      tfoot: {
        td: {
          border: 0,
        },
      },
    },
  },
};
