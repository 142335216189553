import { ECheckpointResponses } from 'enums/ECheckpointResponses';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilityRiskStatus } from 'enums/EEligibilityRiskStatus';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EEligiblityCheckStatus } from 'enums/EEligiblityCheckStatus';
import { EReferralStatus } from 'enums/EReferralStatus';
import { EReferralType } from 'enums/EReferralType';
import { ERiskStatus } from 'enums/ERiskStatus';
import { EVeriphyStatus } from 'enums/EVeriphyStatus';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IAddEditDirector,
  IApplicationCompaniesHouseChecks,
  IApplicationConfirmEligibilityChecks,
  IApplicationCoreInformationChecks,
  IApplicationCostCentreValidationChecks,
  IApplicationEligibilityCategories,
  IApplicationEligibilityReferral,
  IApplicationEligibilitySummary,
  IApplicationFRAEWChecks,
  IApplicationLandRegistryChecks,
  IApplicationVeriphyChecks,
  ICostCentreItem,
  IEligibilityReferApplicationForm,
  IPEPSanctionsDirectorsListItem,
} from 'types/applications/ApplicationEligibilityTypes';
import { getGuid } from 'util/AppUtils';

export const applicationEligibilityData: IApplicationEligibilityCategories = {
  categories: [
    {
      categoryTitle: 'Eligibility Checks',
      order: 1,
      detailItems: [
        {
          id: getGuid(),
          sectionName: 'Core Information Checks',
          lastUpdated: '2023-08-18',
          isComplete: true,
          order: 1,
        },
        {
          id: getGuid(),
          sectionName: 'Cost Centre Validation Checks',
          lastUpdated: '2023-08-18',
          isComplete: true,
          order: 1,
        },
        {
          id: getGuid(),
          sectionName: 'FRAEW Checks',
          lastUpdated: '2023-08-18',
          isComplete: false,
          order: 2,
        },
        {
          id: getGuid(),
          sectionName: 'Land Registry Checks',
          lastUpdated: '2023-08-14',
          isComplete: false,
          order: 3,
        },
      ],
    },
    {
      categoryTitle: 'Company/Organisation Checks',
      order: 2,
      detailItems: [
        {
          id: getGuid(),
          sectionName: 'Veriphy',
          lastUpdated: '2023-08-12',
          isComplete: true,
          order: 1,
        },
        {
          id: getGuid(),
          sectionName: 'Companies House',
          lastUpdated: '2023-08-12',
          isComplete: false,
          order: 2,
        },
        {
          id: getGuid(),
          sectionName: 'PEP/Sanctions',
          lastUpdated: '2023-08-13',
          isComplete: false,
          order: 3,
        },
      ],
    },
    {
      categoryTitle: 'Confirm Eligibility',
      order: 3,
      detailItems: [
        {
          id: getGuid(),
          sectionName: 'Approve or Reject',
          lastUpdated: '2023-08-12',
          isComplete: false,
          order: 1,
        },
      ],
    },
  ],
  rejected: false,
};

export const applicationEligibilityReferralsData: Array<IApplicationEligibilityReferral> =
  [
    {
      id: getGuid(),
      section: EEligibilitySection.CoreInformation,
      question:
        EEligibilityQuestion.Core_HasAcceptableLeaseholderEngagementEvidence,
      referralType: EReferralType.HomesEngland,
      reason: 'Has Acceptable Leaseholder Engagement Evidence Reason',
      status: EReferralStatus.Pending,
      completeReason: null,
      referredByUserId: getGuid(),
    },
    {
      id: getGuid(),
      section: EEligibilitySection.CoreInformation,
      question: EEligibilityQuestion.Core_DevelopersPledge,
      referralType: EReferralType.HomesEngland,
      reason: 'This other reason',
      status: EReferralStatus.Complete,
      completeReason: 'Some information here about why this was completed',
      referredByUserId: getGuid(),
    },
    {
      id: getGuid(),
      section: EEligibilitySection.CoreInformation,
      question: EEligibilityQuestion.Core_DevelopersPledge,
      referralType: EReferralType.HomesEngland,
      reason: 'This other reason',
      status: EReferralStatus.Referred,
      completeReason: 'Some information here about why this was completed',
      referredByUserId: getGuid(),
    },
    {
      id: getGuid(),
      section: EEligibilitySection.CoreInformation,
      question: EEligibilityQuestion.Core_DevelopersPledge,
      referralType: EReferralType.HomesEngland,
      reason: 'Core Developers Pledge Reason',
      status: EReferralStatus.Referred,
      completeReason: null,
      referredByUserId: 'a90ca444-db7b-4a4b-a468-3dce205b888b',
    },
  ];

export const applicationCoreInformationChecksData: IApplicationCoreInformationChecks =
  {
    id: '1',
    isCoreCheckComplete: true,
    hasExistingApplication: true,
    existingApplicationNotes: 'Existing Application Notes',
    hasLeaseholderPersonalDetails: true,
    leaseholderPersonalDetailsNotes: 'Leaseholder Personal Details Notes',
    hasAcceptableLeaseholderEngagementEvidence: true,
    leaseholderEngagementEvidenceNotes: 'Leaseholder Engagement Evidence Notes',
    hasDevelopersPledge: ECheckpointResponses.Yes,
    developersPledgeNotes: 'Developers Pledge Notes',
    developersPledgeDocumentFileId: '2',
    developersPledgeDocument: {
      id: '2',
      name: 'Developers Pledge Document',
      extension: 'pdf',
    },
    referrals: null,
  };

export const costCentresData: ICostCentreItem[] = [
  {
    id: '1',
    name: 'Cost Centre England 1',
    isInEngland: true,
  },
  {
    id: '2',
    name: 'Cost Centre England 2',
    isInEngland: true,
  },
  {
    id: '3',
    name: 'Cost Centre England 3',
    isInEngland: true,
  },
  {
    id: '4',
    name: 'Cost Centre Wales 1',
    isInEngland: false,
  },
  {
    id: '5',
    name: 'Cost Centre Wales 2',
    isInEngland: false,
  },
  {
    id: '6',
    name: 'Cost Centre Wales 3',
    isInEngland: false,
  },
];

export const applicationCostCentreCalidationChecksData: IApplicationCostCentreValidationChecks =
  {
    costCentreId: '1',
    isInEngland: true,
    referrals: null,
  };

export const applicationFRAEWChecksData: IApplicationFRAEWChecks = {
  id: '1',
  offPanelFRAEWReport: false,
  assuranceEvidenceAvailable: undefined,
  submittedBuildingHeight: 15,
  assuranceEvidenceDocumentFileId: '2',
  assuranceEvidenceDocument: {
    id: '2',
    name: 'Assurance Evidence Document',
    extension: 'pdf',
  },
  isBothFilesOfCorrectType: true,
  isFRAEWReportsLinkedToCorrectApplication: true,
  doesBuildingAddressMatchApplication: true,
  doesBuildingHeightMatchFRAEWReport: true,
  doesSummaryDetailsMatchTheApplication: true,
  doesRecommendedActionMakeSense: true,
  doesSafetyRiskStatusMatchFRAEWReport: true,
  safetyRiskStatus: ERiskStatus.MediumActionRequired,
  correctSafetyRiskStatus: ERiskStatus.MediumActionRequired,
  applicantConfirmedSafetyRisk: true,
  validReasonForIncorrectSafetyRisk: true,
  reasonForIncorrectSafetyRisk: 'Reason For Incorrect Safety Risk',
  applicantConfirmedBuildingHeight: true,
  validReasonForIncorrectBuildingHeight: true,
  reasonForIncorrectBuildingHeight: 'Reason For Incorrect Building Height',
  buildingHeight: 15,
  isBuildingHeightCorrect: true,
  buildingHeightDocumentFileId: '3',
  buildingHeightDocument: {
    id: '3',
    name: 'Building Height Document',
    extension: 'pdf',
  },
  referrals: null,
  rejectTriggered: false,
};

export const applicationVeriphyChecksData: IApplicationVeriphyChecks =
  {
    id: '1',
    isVeriphyRequired: false,
    veriphyStatus: EVeriphyStatus.NotStarted,
    referrals: null,
  };

export const applicationCompaniesHouseChecksData: IApplicationCompaniesHouseChecks =
  {
    id: '1',
    isActiveOrAcceptable: ECheckpointResponses.Yes,
    isInMutualPublicRegister: ECheckpointResponses.NA,
    referrals: null,
    registeredAddress: {
      id: '1',
      nameNumber: 'Building Name Number',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      townCity: 'City',
      county: 'County',
      postcode: 'Postcode',
    }
  };

export const applicationLandRegistryChecksData: IApplicationLandRegistryChecks =
  {
    id: '1',
    landRegistryDocumentId: '2',
    landRegistryDocument: {
      id: '2',
      name: 'Land Registry',
      extension: 'pdf',
    },
    leaseDocumentId: '3',
    leaseDocument: {
      id: '3',
      name: 'Lease Document',
      extension: 'pdf',
    },
    certificateDocumentId: '6',
    certificateDocument: {
      id: '4',
      name: 'Certificate Document',
      extension: 'pdf',
    },
    isResidentialLeaseLongTerm: ECheckpointResponses.Yes,
    residentialLeaseLongTermNotes: 'Residential Lease Long Term Notes',
    leaseLongTermPageNumber: 10,
    hasNoPendingApplications: ECheckpointResponses.Yes,
    noPendingApplicationsNotes: 'No Pending Applications Notes',
    isCorrectResponsibleEntity: ECheckpointResponses.Yes,
    correctResponsibleNotes: 'Correct Responsible Notes',
    hasAbilityToMakeChanges: ECheckpointResponses.Yes,
    abilityToMakeChangesNotes: 'Ability To Make Changes Notes',
    abilityToMakeChangesPageNumber: 20,
    landRegistryTitle: 'Land Registry Title',
    landRegistryTitleNote: 'Land Registry Title Note',
    referrals: null,
    rejectTriggered: false,
  };

export const applicationConfirmEligibilityChecksData: IApplicationConfirmEligibilityChecks =
  {
    applicationId: '1',
    status: EEligiblityCheckStatus.Approved,
    hasAllChecksComplete: false,
    riskStatus: EEligibilityRiskStatus.Accept,
    riskReason: 'Risk Reason',
    referApplicationToGLA: false,
    referrals: null,
  };

export const applicationEligibilitySummaryData: IApplicationEligibilitySummary =
  {
    buildingName: 'Mock Building',
    buildingAddress: {
      id: '1',
      nameNumber: '23',
      addressLine1: 'Mock Street Name',
      addressLine2: '',
      city: 'Oxford',
      county: 'Oxfordshire',
      postcode: 'OX33 1ER',
      localAuthority: 'Oxford',
    },
    companyName: 'Mock Company',
    companyAddress: {
      id: '1',
      nameNumber: '50',
      addressLine1: 'Mock Street Name 2',
      addressLine2: '',
      city: 'Oxford',
      county: 'Oxfordshire',
      postcode: 'Test postcode',
    },
    registeredAddress: {
      id: '1',
      nameNumber: '50',
      addressLine1: 'Mock Street 2',
      addressLine2: '',
      city: 'Oxford',
      county: 'Oxfordshire',
      postcode: 'OX1 9NZ',
    },
    companyNumber: '12345678',
    companyStatus: 'Active',
    responsibleEntityEvidenceFile: {
      id: '1',
      name: 'My File 1',
      extension: 'txt',
    },
    leaseHolderEngagegmentFile: {
      id: '2',
      name: 'My File 2',
      extension: 'txt',
    },
    fraewReportFile: {
      id: '3',
      name: 'My File 3',
      extension: 'txt',
    },
    fraewSummaryFile: {
      id: '4',
      name: 'My File 4',
      extension: 'txt',
    },
    buildingHeightEvidenceFile: {
      id: '5',
      name: 'My File 5',
      extension: 'txt',
    },
    fundingCapacity: 'Good',
    buildingHeightStoreys: 8,
    buildingHeightMetres: 40,
    localAuthority: 'Local Authority',
    existingApplications: [
      {
        applicationId: '1',
        applicationReference: 'Mock app 1',
        buildingName: 'Test existing building',
        buildingAddress: {
          id: '1',
          nameNumber: '61',
          addressLine1: 'Mock Street Name 3',
          addressLine2: '',
          city: 'Mock city',
          county: 'Mock county',
          postcode: 'Test postcode',
        },
      },
    ],
    hasDevelopersPledge: true,
    recommendedAction: 'Remove cladding',
    requestedPtfs: undefined,
    ptsFundingAmount: undefined,
    gfaComplete: false,
    veriphyReportFile: {
      id: '3',
      name: 'My File 6',
      extension: 'txt',
    },
    responsibleEntityOrganisationType: "Local Authority",
    costCentreLocalAuthority: "Middlesborough",
  };
export const directorData: IAddEditDirector = {
  id: '1',
  applicationId: '',
  firstName: 'Jim',
  lastName: 'Jones',
  dateOfBirth: new Date('1989-03-16'),
  address: {
    addressLine1: '32 High St',
    addressLine2: '',
    townCity: 'London',
    county: 'London',
    postcode: 'SE1 8EX',
  },
};

export const pepSanctionDirectorPaginatedData: IPaginationResponse<IPEPSanctionsDirectorsListItem> =
  {
    count: 3,
    results: [
      {
        id: getGuid(),
        name: 'Ray Purchase',
        address: '21 The Street, London, SW1 1WS',
        dateOfBirth: '1982-01-01',
        isPassed: false,
        failureReason: 'Just because',
      },
      {
        id: getGuid(),
        name: 'Clem Fandango',
        address: '129 The Road, Plymourh, PM1 1MP',
        dateOfBirth: '1987-05-15',
        isPassed: true,
        failureReason: '',
      },
      {
        id: getGuid(),
        name: 'Steven Toast',
        address: '97 The Avenue, Manchester, MC1 1CM',
        dateOfBirth: '1976-08-25',
        isPassed: null,
        failureReason: '',
      },
    ],
  };

export const applicationReferEligibilitySectionData: IEligibilityReferApplicationForm =
  {
    organisationId: 1,
    assigneeId: '556c270b-b09f-4953-a351-47b8c0efabc1',
    description: 'Test description',
    section: EEligibilitySection.ConfirmEligibility,
  };
