import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IAdministrationBenchmarkFiguresGridItem,
  ICurrentBenchmarkFigures,
} from 'types/administration/AdministrationBenchmarkFiguresTypes';

export const currentBenchmarkFiguresData: ICurrentBenchmarkFigures = {
  eighteenPlusFullRemediation: 100000,
  eighteenPlusFullRemediationDate: '2024-04-01 10:00:00',
  eighteenPlusPartialRemediation: 50000,
  eighteenPlusPartialRemediationDate: '2024-04-01 10:00:00',
  eighteenPlusMitigationRemediation: 5000,
  eighteenPlusMitigationRemediationDate: '2024-04-01 10:00:00',
  underEighteenFullRemediation: 50000,
  underEighteenFullRemediationDate: '2024-04-15 15:00:00',
  underEighteenPartialRemediation: 25000,
  underEighteenPartialRemediationDate: '2024-04-15 15:00:00',
  underEighteenMitigationRemediation: 2500,
  underEighteenMitigationRemediationDate: '2024-04-15 15:00:00',
};

export const benchmarkFiguresHistoryData: IPaginationResponse<IAdministrationBenchmarkFiguresGridItem> =
  {
    results: [
      {
        description: 'Full remediation (18 metres +)',
        updatedAmount: 1200,
        previousAmount: 900,
        dateChanged: '2024-04-01 10:00:00',
        changedBy: 'Sabrina John',
      },
      {
        description: 'Full remediation (11 - 18 metres)',
        updatedAmount: 1200,
        previousAmount: 900,
        dateChanged: '2024-02-01 10:00:00',
        changedBy: 'Sabrina John',
      },
    ],
    count: 2,
  };
