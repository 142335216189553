import { Grid, Skeleton, SxProps } from '@mui/material';

const FieldSkeleton = () => {
  return (
    <Skeleton variant="rectangular" height={60} sx={{ borderRadius: '4px' }} />
  );
};

export const FormSkeleton = ({ sx }: { sx?: SxProps }) => {
  return (
    <Grid container spacing={2} sx={sx}>
      <Grid item xs={6}>
        <FieldSkeleton />
      </Grid>
      <Grid item xs={6}>
        <FieldSkeleton />
      </Grid>
      <Grid item xs={12}>
        <FieldSkeleton />
      </Grid>
      <Grid item xs={12}>
        <FieldSkeleton />
      </Grid>
    </Grid>
  );
};
