import { Box, Button, Grid, Typography } from '@mui/material';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';

interface IRejectApplicationNotificationBannerProps {
  question: EEligibilityQuestion;
  onClick: React.MouseEventHandler;
  disabled?: boolean;
}

export const RejectApplicationNotificationBanner = ({
  question,
  onClick,
  disabled,

}: IRejectApplicationNotificationBannerProps) => {
  const getRejectApplicationMessage = (): string => {
    switch (question) {
      case EEligibilityQuestion.FRAEW_BuildingHeightMatchFRAEWReport:
      case EEligibilityQuestion.FRAEW_SubmittedBuildingHeight:
      case EEligibilityQuestion.FRAEW_BuildingHeight: {
        return 'This application needs to be rejected based on the fact that the building height is less than 11m and is not eligible for this scheme';
      }
      case EEligibilityQuestion.LandRegistry_IsResidentialLeaseLongTerm: {
        return 'This application needs to be rejected because the residential lease long term is not over 21 years';
      }
      case EEligibilityQuestion.LandRegistry_HasNoPendingApplications: {
        return 'This application needs to be rejected because there are pending applications on the buildings title';
      }
      case EEligibilityQuestion.LandRegistry_IsCorrectResponsibleEntity: {
        return 'This application needs to be rejected because its not the correct responsible entity or has no qualifying legal interest in the building';
      }
      case EEligibilityQuestion.LandRegistry_HasAbilityToMakeChanges: {
        return 'This application needs to be rejected because has not practical or leage ability to make changes to the building';
      }
      default: {
        return 'Unknown reason for application rejection';
      }
    }
  };

  return (
    <Box
      sx={{
        padding: 1,
        border: '1px solid lightgray',
        borderRadius: 1,
        borderLeft: '5px solid red',
      }}
    >
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            Reject Application
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body1" fontSize="1em">
            {getRejectApplicationMessage()}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Button onClick={onClick} variant="outlined" disabled={disabled}>
            Reject Application
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
