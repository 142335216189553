import { Grid } from '@mui/material';
import { AdministrationPTFSGrid } from 'pages/administrationPage/components/administrationPTFSTab/components/AdministrationPTFSGrid';

export function AdministrationPTFSTab() {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AdministrationPTFSGrid />
        </Grid>
      </Grid>
    </>
  );
}
