import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { TDropdownType } from 'common/types/DropdownType';
import { QueryTags } from 'util/ApiUtils';
import { customPrepareHeaders } from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/`;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
  credentials: 'include',
});

export const countriesApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery,
  tagTypes: [QueryTags.Countries],
  endpoints: builder => ({
    getCountries: builder.query<TDropdownType[], void>({
      providesTags: [QueryTags.Countries],
      query: () => `/country`,
    }),
  }),
});

export const { useGetCountriesQuery } = countriesApi;
