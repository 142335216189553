import { ReactNode } from 'react';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { parseISO } from 'date-fns';
import { useGetClosingPaymentRequestOverviewQuery } from 'api/application/paymentRequestApi';
import { ERiskStatusLanguage } from 'enums/ERiskStatus';
import {
  ApplicationFileDownloadWrapper,
  ColumnContainer,
} from 'pages/applicationPage/common/components';
import {
  GrayCardContainer,
  QuestionAndAnswer,
} from 'pages/applicationPage/content/liveProject/common';
import { PaymentReferralGrid } from 'pages/applicationPage/content/liveProject/common/paymentReferralGrid/PaymentReferralGrid';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { useClosingPaymentRequestContext } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/context';
import {
  EPaymentRecommendationStatus,
  IFRAEWChip,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  PaymentRequestKpiSection,
  VarianceCard,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common';
import { ClosingPaymentRequestNotificationBanner } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/banners/ClosingPaymentRequestNotificationBanner';
import { getPercentage } from 'util/AppUtils';
import { formatDateDayShortMonthYear } from 'util/DateUtils';

export const ClosingPaymentRequestOverviewSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const {
    currentStatus,
    referrals: heReferrals,
    showReferralsSection,
  } = useClosingPaymentRequestContext();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const { data } = useGetClosingPaymentRequestOverviewQuery(request);


  const showRecommendedAmount: boolean = !!(
    data?.recommendedAmount !== undefined &&
    data?.requestedAmount !== undefined &&
    data.recommendedAmount !== data.requestedAmount
  );

  const expiredOnlyView =
    currentStatus === EPaymentRecommendationStatus.Expired;

  return (
    <>
      {selectedItem && (
        <PaymentRequestKpiSection paymentRequestId={selectedItem.id} />
      )}
      <ColumnContainer
        sx={{
          padding: 0,
          border: '1px solid lightgray',
          borderRadius: 2,
          mt: 4,
        }}
      >
        <Box sx={{ padding: 2, borderBottom: '1px solid lightgray' }}>
          <Typography variant="h3" fontWeight={800} fontSize="1.3em">
            Overview
          </Typography>
        </Box>
        <Box p={2}>
          {selectedItem && (
            <PaymentReferralGrid
              selectedItemId={selectedItem.id}
              heReferrals={heReferrals}
              showReferralsSection={showReferralsSection}
            />
          )}
        </Box>
        <Box sx={{ padding: 3 }}>
          <Box
            mb={2}
            sx={{
              width: '100%',
              padding: 2,
              paddingTop: 3,

              borderRadius: 1,
            }}
          >
            {data?.paymentRecommendation &&
              [
                EPaymentRecommendationStatus.Sent,
                EPaymentRecommendationStatus.Approved,
                EPaymentRecommendationStatus.Rejected,
                EPaymentRecommendationStatus.Expired,
              ].includes(data.paymentRecommendation) && (
                <ClosingPaymentRequestNotificationBanner
                  status={data.paymentRecommendation}
                />
              )}

            <Grid container flexWrap="nowrap" columnGap={2}>
              <GrayCardContainer>
                <CardTitle title="Payment Details" />
                {!expiredOnlyView && (
                  <Stack spacing={2} mt={2}>
                    {data?.recommendedAmount && showRecommendedAmount ? (
                      <InnerPaymentDetailsBox title="Recommended amount">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: '0.5rem 0',
                            gap: '1rem',
                          }}
                        >
                          <Typography>{`${data?.paymentMonth} payment`}</Typography>
                          <Box>
                            <Typography textAlign="end">{`£${data.recommendedAmount.toLocaleString()}`}</Typography>
                            <Typography fontSize="0.8em">{`${getPercentage(
                              data.recommendedAmount,
                              data.totalGrantFunding
                            ).toFixed(1)}% of total grant funding`}</Typography>
                          </Box>
                        </Box>
                        {data?.additionalRecommendedAmount !== undefined &&
                        data.additionalRecommendedAmount !== 0 ? (
                          <>
                            <QuestionAndAnswer
                              question={data.additionalRecommendedAmountTitle}
                              value={`£${data.additionalRecommendedAmount.toLocaleString()}`}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: '0.5rem 0',
                                gap: '1rem',
                              }}
                            >
                              <Typography fontWeight={700}>Total</Typography>
                              <Typography fontWeight={700}>{`£${(
                                data.recommendedAmount +
                                data.additionalRecommendedAmount
                              ).toLocaleString()}`}</Typography>
                            </Box>
                          </>
                        ) : null}
                        <Typography fontSize="1em">Reason</Typography>
                        <Typography variant="body1">
                          {data?.operationsReasonForCostChange}
                        </Typography>
                      </InnerPaymentDetailsBox>
                    ) : null}
                    <InnerPaymentDetailsBox title="Requested Amount">
                      {data?.requestedAmount && data?.requestedAmount !== 0 ? (
                        <QuestionAndAnswer
                          question={`Closing payment`}
                          value={
                            data?.requestedAmount
                              ? `£${data.requestedAmount.toLocaleString()}`
                              : ' - '
                          }
                        />
                      ) : null}
                      {data?.additionalRequestedAmount !== undefined &&
                      data.additionalRequestedAmount !== 0 ? (
                        <QuestionAndAnswer
                          question={data.additionalRequestedAmountTitle}
                          value={`£${data.additionalRequestedAmount.toLocaleString()}`}
                        />
                      ) : null}
                      {data?.requestedAmount !== undefined &&
                      data?.additionalRequestedAmount !== undefined ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: '0.5rem 0',
                            gap: '1rem',
                          }}
                        >
                          <Typography fontWeight={700}>Total</Typography>
                          <Typography fontWeight={700}>{`£${(
                            data.requestedAmount +
                            data.additionalRequestedAmount
                          ).toLocaleString()}`}</Typography>
                        </Box>
                      ) : null}
                    </InnerPaymentDetailsBox>
                    {data?.scheduledAmount !== undefined &&
                    data.scheduledAmount !== 0 ? (
                      <InnerPaymentDetailsBox title="Scheduled Payment">
                        <QuestionAndAnswer
                          question={`Closing payment`}
                          value={`£${data.scheduledAmount.toLocaleString()}`}
                        />
                      </InnerPaymentDetailsBox>
                    ) : null}

                    <VarianceCard
                      title="Remaining"
                      paymentAmount={
                        data?.recommendedAmount ?? data?.requestedAmount ?? 0
                      }
                      scheduledAmount={data?.scheduledAmount ?? 0}
                    />
                  </Stack>
                )}
                {expiredOnlyView && (
                  <Box mt={2}>
                    {data?.scheduledAmount !== undefined &&
                    data.scheduledAmount !== 0 ? (
                      <InnerPaymentDetailsBox title="Scheduled amount">
                        <QuestionAndAnswer
                          question={
                            data?.paymentMonth
                              ? `${data.paymentMonth} payment`
                              : ''
                          }
                          value={`£${data.scheduledAmount.toLocaleString()}`}
                        />
                      </InnerPaymentDetailsBox>
                    ) : null}
                  </Box>
                )}
              </GrayCardContainer>
              <Grid item xs={6}>
                {!expiredOnlyView && (
                  <Stack spacing={2}>
                    {(data?.projectedCompletionDate ||
                      data?.fraewRiskAssessment) && (
                      <InnerCard>
                        <CardTitle title="Closing declaration" />
                        {data?.projectedCompletionDate && (
                          <QuestionAndAnswer
                            question="Project completion date"
                            value={formatDateDayShortMonthYear(
                              parseISO(data.projectedCompletionDate)
                            )}
                          />
                        )}
                        {data?.fraewRiskAssessment ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              p: '0.5rem 0',
                              gap: '1rem',
                            }}
                          >
                            <Typography>FRAEW risk assessment</Typography>
                            <FRAEWChip riskData={data.fraewRiskAssessment} />
                          </Box>
                        ) : null}
                      </InnerCard>
                    )}
                    {data?.costReports && (
                      <InnerCard>
                        <CardTitle title="Evidence of project costs" />
                        {data?.costReports.map(item => (
                          <ApplicationFileDownloadWrapper
                            key={item.id}
                            fileName={item.name}
                            fileId={item.id}
                            fileWrapperStyle={{
                              backgroundColor: '#FAFAFA',
                              marginTop: '1rem',
                            }}
                          />
                        ))}
                      </InnerCard>
                    )}
                    {data?.applicantReasonForCostChange && (
                      <InnerCard>
                        <Stack spacing={1}>
                          <CardTitle title="Reason for change" />
                          {/* On Figma it's a light grey colour but if we use that colour it won't meet the accessibility contrast standards */}
                          <Typography fontSize="0.9em">Reason</Typography>
                          <Typography variant="body1" fontWeight={600}>
                            {data.applicantReasonForCostChange}
                          </Typography>
                        </Stack>
                      </InnerCard>
                    )}
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ColumnContainer>
    </>
  );
};

const InnerCard = ({ children }: { children: ReactNode }) => (
  <Box bgcolor="grey.100" p={2}>
    {children}
  </Box>
);

const CardTitle = ({ title }: { title: string }) => (
  <Typography fontWeight={600}>{title}</Typography>
);

const InnerPaymentDetailsBox = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => (
  <Box bgcolor={'white'} p={2}>
    <CardTitle title={title} />
    {children}
  </Box>
);

const FRAEWChip = ({ riskData }: IFRAEWChip) => {
  return <Chip label={riskData ? ERiskStatusLanguage[riskData] : '-'} />;
};
