import { Typography } from '@mui/material';
import { useGetEligibilitySummaryQuery } from 'api/application';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const FRAEWChecksApplicationDetailsPanel = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetEligibilitySummaryQuery(applicationId);

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Application Details
        </Typography>
        {query.data ? (
          <>
            <DetailItem label="Building name" value={query.data.buildingName} />
            <DetailItem
              label="Address of building"
              value={
                query.data.buildingAddress &&
                query.data.buildingAddress.nameNumber
                  ? `${query.data.buildingAddress.nameNumber} ${query.data.buildingAddress.addressLine1}, ${query.data.buildingAddress.postcode}`
                  : '-'
              }
            />
          </>
        ) : null}
      </ColumnContainer>
    </>
  );
};
