import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { ErrorAlert } from 'common/components/alerts';
import { CardTitle } from 'common/components/cardTitle';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import {
  EVariationStatus,
  IVariationOverview,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { formatDateTimeDayMonthYearHourMins } from 'util/DateUtils';

export const ApprovedOrRejectedBanner = ({
  overviewData: {
    status,
    approvalRejectionDate,
    approvalRejectionReason,
    approvalRejectionUser,
  },
}: {
  overviewData: IVariationOverview;
}) => {
  const isApproved = status === EVariationStatus.Approved;
  const statusText = isApproved ? 'approved' : 'rejected';

  if (!approvalRejectionDate || !approvalRejectionUser) {
    return (
      <Box mt={3}>
        <ErrorAlert
          msg={`Could not get the ${
            isApproved ? 'approval' : 'rejection'
          } data from the overview`}
        />
      </Box>
    );
  }

  return (
    <RoundBorderBox bgcolor={'grey.100'} p={3}>
      <Stack direction={'row'} gap={2}>
        <Box>
          <FontAwesomeIcon
            icon={isApproved ? faCheckCircle : faTimesCircle}
            color={isApproved ? 'green' : 'darkred'}
            fontSize={20}
          />
        </Box>

        <Stack gap={2}>
          <Box>
            <CardTitle
              title={`Variation request ${statusText}`}
              fontSize="1.1em"
            />
            <Typography>
              This variation request was {statusText} at{' '}
              {formatDateTimeDayMonthYearHourMins(
                parseISO(approvalRejectionDate)
              )}{' '}
              by {approvalRejectionUser}
            </Typography>
          </Box>
          {!isApproved ? (
            <Box p={2} bgcolor={'white'}>
              <Typography fontWeight={600}>Description</Typography>
              <Typography color={'grey.600'}>
                {approvalRejectionReason}
              </Typography>
            </Box>
          ) : null}
        </Stack>
      </Stack>
    </RoundBorderBox>
  );
};
