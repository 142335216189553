import { applicationApi } from 'api/application';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IAddCommunication,
  IApplicationCommunication,
  IApplicationCommunicationCategories,
  IApplicationCommunicationContactDetails,
  IGetApplicationCommunicationsPaginatedQuery,
} from 'types/applications/ApplicationCommunicationTypes';
import { QueryTags } from 'util/ApiUtils';
import { getSkipTakeFromQueryData } from 'util/QueryUtils';

const getPaginatedApplicationCommunicationsString = (
  query: IGetApplicationCommunicationsPaginatedQuery
) => {
  const queryParamsStr = getSkipTakeFromQueryData({
    skip: query.skip,
    take: query.take,
  });

  if (query.searchValue) {
    queryParamsStr.append('searchText', `${query.searchValue}`);
  }

  if (query.categories.length > 0) {
    for (const category of query.categories) {
      queryParamsStr.append('categories', `${category}`);
    }
  }

  return `${process.env.REACT_APP_API_URL}/api/application/${
    query.applicationId
  }/communication?${queryParamsStr.toString()}`;
};

export const communicationsEndpoints = applicationApi.injectEndpoints({
  endpoints: builder => ({
    getApplicationCommunicationContactDetails: builder.query<
      IApplicationCommunicationContactDetails,
      string
    >({
      query: applicationId => `/${applicationId}/communication/contactdetails`,
    }),
    getApplicationCommunicationCategories: builder.query<
      Array<IApplicationCommunicationCategories>,
      void
    >({
      query: () =>
        `${process.env.REACT_APP_API_URL}/api/communication/categories`,
    }),
    getApplicationCommunications: builder.query<
      IPaginationResponse<IApplicationCommunication>,
      IGetApplicationCommunicationsPaginatedQuery
    >({
      providesTags: [QueryTags.ApplicationCommunications],
      query: query => {
        return getPaginatedApplicationCommunicationsString(query);
      },
    }),
    addCommunication: builder.mutation<any, IAddCommunication>({
      invalidatesTags: [
        QueryTags.ApplicationCommunications,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/communication`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApplicationCommunicationContactDetailsQuery,
  useGetApplicationCommunicationCategoriesQuery,
  useGetApplicationCommunicationsQuery,
  useAddCommunicationMutation,
} = communicationsEndpoints;
