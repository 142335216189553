import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ESortDirection } from 'enums/ESortDirection';
import { IGetRolesListQuery, IRoleGridListItem } from 'types/administration/AdministrationRolesTypes';
import { nameof } from 'util/formUtils';

export const rolesSortByTypes = [
  nameof<IRoleGridListItem>('name'),
  nameof<IRoleGridListItem>('activeUsers'),

] as const;
export type RolesSortByTypes =
  (typeof rolesSortByTypes)[number];

export interface IAdministrationRolesState {
  gridState: IGetRolesListQuery;
}

const initialState: IAdministrationRolesState = {
  gridState: {
    skip: 0,
    take: 25,
    sortBy: nameof<IRoleGridListItem>('name'),
    sortDirection: ESortDirection.Asc,
  },
};

export const administrationRolesSlice = createSlice({
  name: 'administrationRolesSlice',
  initialState,
  reducers: {
    gridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetRolesListQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
    userAdministrationRolesPage: () => initialState,
  },
});

export const administrationRolesReducer = administrationRolesSlice.reducer;
export const { gridStateChanged, userAdministrationRolesPage } =
  administrationRolesSlice.actions;
