import { ReactNode } from 'react';
import { Box, Typography, Divider } from '@mui/material';

export const SummaryField = ({
  label,
  value,
  showDivider = true,
}: {
  label: string;
  value: ReactNode;
  showDivider?: boolean;
}) => {
  return (
    <Box>
      <SummaryLabel label={label} />
      {typeof value === 'string' ? (
        <Typography fontWeight={600}>{value}</Typography>
      ) : (
        value
      )}
      {showDivider ? <Divider sx={{ mt: 3 }} /> : null}
    </Box>
  );
};

export const SummaryLabel = ({ label }: { label: string }) => (
  <Typography color="grey.700">{label}</Typography>
);

export const FieldLabel = ({ label }: { label: string }) => {
  return (
    <Typography variant="h3" marginBottom={1} fontWeight={600} fontSize="0.9em">
      {label}
    </Typography>
  );
};

export const QuestionContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        padding: 3,
        borderRadius: '5px',
        marginBottom: 2,
      }}
    >
      {children}
    </Box>
  );
};
