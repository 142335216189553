import { rest } from 'msw';
import { holdReasonsData } from 'mocks/data/common/holdReasonsData';

export const holdReasonsHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/hold/reasons/:holdType`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(holdReasonsData));
      }
      return req.passthrough();
    }
  ),
];
