import { Box, Typography, Grid, Divider } from '@mui/material';
import { useGetPaymentRequestProjectTeamQuery } from 'api/application/paymentRequestApi';
import { ColumnContainer } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { PaymentRequestProjectTeamAccordionInfo } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/sections/PaymentRequestProjectTeamSection/PaymentRequestProjectTeamAccordionInfo';


export const PaymentRequestProjectTeamSection = () => {
  const { applicationId } = useApplicationContext();
  const { data } = useGetPaymentRequestProjectTeamQuery(applicationId);

  return (
    <Box
      sx={{
        padding: 3,
      }}
    >
      <ColumnContainer
        sx={{
          padding: 0,
          border: '1px solid lightgray',
          borderRadius: 2,
        }}
      >
        <Box
          bgcolor={'grey.100'}
          sx={{ padding: 2, borderBottom: '1px solid lightgray' }}
        >
          <Typography variant="h3" fontWeight={800} fontSize="1.3em">
            Project team
          </Typography>
        </Box>

        <Box
          sx={{
            width: '100%',
            borderRadius: 1,
          }}
        >
          <Grid
            container
            alignItems="center"
            columnSpacing={2}
            padding={2}
            wrap="nowrap"
          >
            <Grid item flexBasis={'48px'} />
            <Grid item xs={3}>
              <Typography fontSize={'0.9em'} fontWeight={600}>
                Role
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontSize={'0.9em'} fontWeight={600}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontSize={'0.9em'} fontWeight={600}>
                Company
              </Typography>
            </Grid>
            <Grid item xs={3} fontSize={'0.9em'} fontWeight={600}>
              Company Registration
            </Grid>
          </Grid>
          <Divider />
          {data && <PaymentRequestProjectTeamAccordionInfo data={data} />}
        </Box>
      </ColumnContainer>
    </Box>
  );
};
