import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { getDateOnlyIsoString } from 'util/DateUtils';

export const DueDateField = ({
  isDisabled = false,
}: {
  isDisabled?: boolean;
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="dueDate"
      rules={{ required: 'Due Date is required' }}
      render={({
        field: { onChange, value, ...field },
        formState: { errors },
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...field}
              disabled={isDisabled}
              format="dd/MM/yyyy"
              label="Due Date"
              value={parseISO(value)}
              onChange={val => {
                return val ? onChange(getDateOnlyIsoString(val)) : null;
              }}
              slotProps={{
                textField: {
                  sx: { mr: '1rem' },
                  error: !!errors.dueDate,
                  helperText: errors.dueDate?.message,
                  fullWidth: true,
                },
              }}
              minDate={new Date()}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
