import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@mui/material';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { AdministrationSystemNotificationsGrid } from 'pages/administrationPage/components/administrationSystemNotificationsTab/components';
import { AddEditSystemNotificationDrawer } from 'pages/administrationPage/components/administrationSystemNotificationsTab/components/addEditSystemNotificationPage/AddEditSystemNotificationDrawer';

export function AdministrationSystemNotificationsTab() {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminSystemNotifications = doesUserHaveSinglePermission('admin.system-notification');

  const {
    isShowing: isAddEditSystemNotificationDrawerShowing,
    showModal: showAddEditSystemNotificationDrawer,
    hideModal: hideAddEditSystemNotificationDrawer,
  } = useModalState();

  return (
    <>
      <Grid container>
        {hasAdminSystemNotifications && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              onClick={showAddEditSystemNotificationDrawer}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
            >
              Add System Notification
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <AdministrationSystemNotificationsGrid />
        </Grid>
      </Grid>
      {isAddEditSystemNotificationDrawerShowing && (
        <AddEditSystemNotificationDrawer
          onSuccess={() => {
            hideAddEditSystemNotificationDrawer();
          }}
          onClose={() => {
            hideAddEditSystemNotificationDrawer();
          }}
        />
      )}
    </>
  );
}
