import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditCurrentBenchmarkFiguresMutation,
  useGetCurrentBenchmarkFiguresQuery,
} from 'api/administration/benchmarkFigures';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar, useModalState } from 'hooks';
import { zBenchmarkFiguresSchema } from 'pages/administrationPage/components/administrationBenchmarkFiguresTab/components/EditBenchmarkFigures.zod';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { ICurrentBenchmarkFigures } from 'types/administration/AdministrationBenchmarkFiguresTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof ICurrentBenchmarkFigures) =>
  nameof<ICurrentBenchmarkFigures>(fieldName);

interface IEditBenchmarkFiguresDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function EditBenchmarkFiguresDrawer({
  onClose,
  onSuccess,
}: IEditBenchmarkFiguresDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            Edit Benchmark Figures
          </Typography>
        </DialogTitle>

        <EditBenchmarkFiguresForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditBenchmarkFiguresFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditBenchmarkFiguresForm = ({
  onClose,
  onSuccess,
}: IEditBenchmarkFiguresFormProps) => {
  const { data, isLoading } = useGetCurrentBenchmarkFiguresQuery();
  const [editCurrentBenchmarkFigures, editCurrentBenchmarkFiguresResult] =
    useEditCurrentBenchmarkFiguresMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<ICurrentBenchmarkFigures>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zBenchmarkFiguresSchema),
  });
  const { handleSubmit, reset, control } = form;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const hasDecimal = (numberToCheck: number) =>
    numberToCheck - Math.floor(numberToCheck) !== 0;

  const {
    isShowing: editCurrentBenchmarkFiguresModalIsShowing,
    hideModal: hideCurrentBenchmarkFiguresModal,
    showModal: showCurrentBenchmarkFiguresModal,
  } = useModalState<string | null>();

  const onSubmit = async (formData: ICurrentBenchmarkFigures) => {
    showCurrentBenchmarkFiguresModal();
  };

  const submitBenchmarkFigures = async () => {
    const formData = form.getValues();

    try {
      await editCurrentBenchmarkFigures(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Benchmark figures updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit benchmark figures`);
    }
  };

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} mb={5}>
                <Typography variant="h2" fontWeight={'bold'}>
                  18 metres +
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('eighteenPlusFullRemediation')}
                  control={control}
                  defaultValue={data?.eighteenPlusFullRemediation ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Full Remediation"
                      helperText={fieldState.error?.message}
                      onChange={e => {
                        if (hasDecimal(Number(e.target.value))) return;
                        return field.onChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('eighteenPlusPartialRemediation')}
                  control={control}
                  defaultValue={data?.eighteenPlusFullRemediation ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Partial Remediation"
                      helperText={fieldState.error?.message}
                      onChange={e => {
                        if (hasDecimal(Number(e.target.value))) return;
                        return field.onChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('eighteenPlusMitigationRemediation')}
                  control={control}
                  defaultValue={data?.eighteenPlusFullRemediation ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Mitigation"
                      helperText={fieldState.error?.message}
                      onChange={e => {
                        if (hasDecimal(Number(e.target.value))) return;
                        return field.onChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} mb={5}>
                <Typography variant="h2" fontWeight={'bold'}>
                  11-18 metres
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('underEighteenFullRemediation')}
                  control={control}
                  defaultValue={data?.underEighteenFullRemediation ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Full Remediation"
                      helperText={fieldState.error?.message}
                      onChange={e => {
                        if (hasDecimal(Number(e.target.value))) return;
                        return field.onChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('underEighteenPartialRemediation')}
                  control={control}
                  defaultValue={data?.underEighteenFullRemediation ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Partial Remediation"
                      helperText={fieldState.error?.message}
                      onChange={e => {
                        if (hasDecimal(Number(e.target.value))) return;
                        return field.onChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={getName('underEighteenMitigationRemediation')}
                  control={control}
                  defaultValue={data?.underEighteenFullRemediation ?? ''}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      fullWidth
                      error={!!fieldState.error}
                      required
                      label="Mitigation"
                      helperText={fieldState.error?.message}
                      onChange={e => {
                        if (hasDecimal(Number(e.target.value))) return;
                        return field.onChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={editCurrentBenchmarkFiguresResult.isLoading}
              >
                Update Benchmark Figures
              </LoadingButton>
            </Grid>
          </StyledDrawerActions>
        </form>
      </FormProvider>
      <ConfirmationModal
        isShowing={editCurrentBenchmarkFiguresModalIsShowing}
        onCancel={hideCurrentBenchmarkFiguresModal}
        confirmButtonType="button"
        onConfirm={submitBenchmarkFigures}
      >
        Are you sure you want to edit the benchmark figures?
      </ConfirmationModal>
    </>
  );
};
