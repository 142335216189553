import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@mui/material';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { AdministrationUsersFilters } from 'pages/administrationPage/components/administrationUsersTab/components/AdministrationUsersFilters';
import { AdministrationUsersGrid } from 'pages/administrationPage/components/administrationUsersTab/components/AdministrationUsersGrid';
import { AddEditUserDrawer } from 'pages/administrationPage/components/administrationUsersTab/components/addEditUserDrawer/AddEditUserDrawer';

export function AdministrationUsersTab() {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminUsers = doesUserHaveSinglePermission('admin.users');

  const {
    isShowing: isAddEditUserDrawerShowing,
    showModal: showAddEditUserDrawer,
    hideModal: hideAddEditUserDrawer,
  } = useModalState();

  return (
    <>
      <Grid container>
        {hasAdminUsers && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              onClick={showAddEditUserDrawer}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
            >
              Add User
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <AdministrationUsersFilters />
        </Grid>
        <Grid item xs={12}>
          <AdministrationUsersGrid />
        </Grid>
      </Grid>
      {isAddEditUserDrawerShowing && (
        <AddEditUserDrawer
          onSuccess={() => {
            hideAddEditUserDrawer();
          }}
          onClose={() => {
            hideAddEditUserDrawer();
          }}
        />
      )}
    </>
  );
}
