import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  DialogContent,
  Grid,
  TextField,
  Button,
  Box,
  DialogTitle,
  Typography,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditFraewCurrentEvacuationStrategyMutation,
  useGetFraewCurrentEvacuationStrategyQuery,
} from 'api/application/applicationEditApi';
import { EEvacuationStrategyType } from 'enums/EEvacuationStrategyType';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zFraewCurrentEvacuationStrategySchema } from 'types/applications/ApplicationEdit.zod';
import { IFraewCurrentEvacuationStrategyForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditFraewCurrentEvacuationStrategyDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewCurrentEvacuationStrategyDrawer = ({
  onClose,
  onSuccess,
}: IEditFraewCurrentEvacuationStrategyDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit What is the current evacuation strategy?
          </Typography>
          {/* <Typography variant="h3">{currentValue}</Typography> */}
        </DialogTitle>
        <EditFraewCurrentEvacuationStrategyForm
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Box>
    </StyledDrawer>
  );
};

interface IEditFraewCurrentEvacuationStrategyFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewCurrentEvacuationStrategyForm = ({
  onClose,
  onSuccess,
}: IEditFraewCurrentEvacuationStrategyFormProps) => {
  const { applicationId } = useApplicationContext();

  const options: { id: EEvacuationStrategyType; name: string }[] = [
    { id: EEvacuationStrategyType.NotCaptured, name: 'Not Captured' },
    { id: EEvacuationStrategyType.Other, name: 'Other' },
    {
      id: EEvacuationStrategyType.SimultaneousEvacuation,
      name: 'Simultaneous Evacuation',
    },
    { id: EEvacuationStrategyType.StayPut, name: 'Stay Put' },
    {
      id: EEvacuationStrategyType.TemporarySimultaneousEvacuation,
      name: 'Temporary Simultaneous Evacuation',
    },
    { id: EEvacuationStrategyType.NotApplicable, name: 'Not Applicable' },
  ];

  const { data, isLoading } =
    useGetFraewCurrentEvacuationStrategyQuery(applicationId);

  const [
    editFraewCurrentEvacuationStrategy,
    editFraewCurrentEvacuationStrategyResult,
  ] = useEditFraewCurrentEvacuationStrategyMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IFraewCurrentEvacuationStrategyForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: data || undefined,
    resolver: zodResolver(zFraewCurrentEvacuationStrategySchema),
  });
  const { handleSubmit, reset, control, watch, setValue } = form;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const onSubmit = async (formData: IFraewCurrentEvacuationStrategyForm) => {
    try {
      formData.applicationId = applicationId;
      await editFraewCurrentEvacuationStrategy(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(
            `Current evacuation stragtegy updated successfully`
          );
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit current evacuation stragtegy`);
    }
  };

  return isLoading ? (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="evacuationStrategy"
                render={({
                  fieldState,
                  field: { onChange, value, ...fieldProps },
                }) => (
                  <Autocomplete
                    {...fieldProps}
                    onChange={(_, val) => {
                      onChange(val);
                      setValue('otherStrategy', '');
                    }}
                    value={value}
                    options={options.map(({ id }) => id)}
                    getOptionLabel={opt =>
                      options.find(o => o.id === opt)?.name || ''
                    }
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!fieldState.error}
                          label="What is the current evacuation strategy?"
                          helperText={fieldState.error?.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            {watch('evacuationStrategy') === EEvacuationStrategyType.Other ? (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  defaultValue={null}
                  name="otherStrategy"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      label="Please enter other current evacuation strategy"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editFraewCurrentEvacuationStrategyResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
