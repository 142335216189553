import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants,
  Theme
} from "@mui/material";
import { blueGrey } from '@mui/material/colors';

export const muiToggleButtonTheme: {
  defaultProps?: ComponentsProps["MuiToggleButton"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiToggleButton"];
  variants?: ComponentsVariants["MuiToggleButton"];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: "none",
      color: theme.palette.textDark?.main,
      border: "none",
      wordSpacing: "normal",
      letterSpacing: "normal",
      "&.Mui-selected": {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        ":hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    }),
    // <ToggleButton size="small" />
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(0, 2),
      background: theme.palette.grey[200],
      fontSize: "0.75rem",
    }),
    // <ToggleButton size="medium" /> -> default size
    sizeMedium: ({ theme }) => ({
      padding: theme.spacing(1, 3),
      fontSize: "0.875rem",
      height: '48px',
      backgroundColor: blueGrey[50],
    }),
  },
};
