import { DialogContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import {
  costScheduleEditFormId,
  getAmountString,
} from 'common/components/costSchedule';
import {
  DescriptionField,
  EligibleCostField,
  FormActions,
  Label,
  OriginalDataAccordion,
  shouldValidateDescription,
} from 'common/components/costSchedule/forms/common';
import {
  ECostScheduleParentType,
  ICostScheduleFormProps,
} from 'common/components/costSchedule/forms/types';
import { getCostScheduleDescription } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections';
import {
  IIneligibleForm,
  FieldLabels,
  ECostsScheduleSection,
} from 'types/applications/ApplicationCostScheduleTypes';

export const IneligibleForm = ({
  parentType,
  data,
  originalData,
  handleCancelClick,
  saveFn,
  saveStatus,
}: ICostScheduleFormProps<IIneligibleForm>) => {
  const array = [data, originalData];
  const form = useForm<IIneligibleForm>({
    defaultValues: {
      ineligibleAmount: getAmountString(data.ineligibleAmount),
      ineligibleDescription: getCostScheduleDescription(
        array,
        'ineligibleDescription'
      ),
    },
  });
  const { handleSubmit, watch } = form;

  const ineligibleAmount = watch('ineligibleAmount');

  const onSubmit = async (formData: IIneligibleForm) => {
    saveFn(formData);
  };
  return (
    <>
      <DialogContent>
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
            id={costScheduleEditFormId}
          >
            <Label
              value={FieldLabels[ECostsScheduleSection.InelgibleCosts].costs}
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="ineligibleAmount"
              descriptionFieldName="ineligibleDescription"
              label="Total Ineligible Cost"
            />

            <EligibleCostField
              fieldName="ineligibleAmount"
              originalAmount={data.ineligibleAmount}
              newAmount={ineligibleAmount}
              isRequired={false}
              fieldLabel="Total Ineligible Cost"
            />

            <DescriptionField
              fieldName="ineligibleDescription"
              isValidationBasedOnAmountField={
                parentType === ECostScheduleParentType.WorksPackage
                  ? true
                  : false
              }
              associatedAmountFieldName="ineligibleAmount"
              shouldValidate={shouldValidateDescription(
                parentType,
                data.ineligibleAmount,
                ineligibleAmount
              )}
            />
          </form>
        </FormProvider>

        <AlertForQueryErrorOrNull
          isError={saveStatus.isError}
          error={saveStatus.error}
        />
      </DialogContent>
      <FormActions
        originalValue={data.ineligibleAmount ?? 0}
        newValues={[ineligibleAmount]}
        isSaving={saveStatus.isLoading}
        totalCostLabel="Total ineligible cost"
        handleCancelClick={handleCancelClick}
      />
    </>
  );
};
