import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import { useGetAdditionalPaymentRecommendationDetailsQuery } from 'api/application/paymentApi';
import { AlertForQueryErrorOrNull, ErrorAlert } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useCurrentUserPermissions } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { SendAdditionalRecommendationBanner } from 'pages/applicationPage/content/payments/sections/AdditionalRecommendPaymentSection/SendAdditionalRecommendationBanner';
import {
  ApplicationOverviewSection,
  FundingSection,
  PaymentDocumentsSection,
} from 'pages/applicationPage/content/payments/sections/common';
import { usePaymentRepresentativeLogic } from 'pages/applicationPage/content/payments/utils/paymentUtils';

export const AdditionalRecommendPaymentSection = () => {
  const { doesUserHaveSomeOfThesePermissions } = useCurrentUserPermissions();
  const canUserViewRecommendSection = doesUserHaveSomeOfThesePermissions([
    'payment-recommend.view',
    'payment-recommend.edit',
  ]);

  if (!canUserViewRecommendSection) {
    return <ErrorAlert msg="Unauthorised" />;
  }

  return <RecommendAdditionalPaymentSectionMain />;
};

const RecommendAdditionalPaymentSectionMain = () => {
  const { applicationId } = useApplicationContext();

  const paymentRepresentativeLogic = usePaymentRepresentativeLogic();
  const paymentDetailsQuery =
    useGetAdditionalPaymentRecommendationDetailsQuery(applicationId);

  const isLoading =
    paymentRepresentativeLogic.isLoading || paymentDetailsQuery.isLoading;
  const isSuccess =
    paymentRepresentativeLogic.isSuccess && paymentDetailsQuery.isSuccess;

  return (
    <RoundBorderBox>
      <Header />
      <Divider />
      <Box p={3}>
        <Grid container columnSpacing={4}>
          <Grid item xs={8} display={'flex'} flexDirection={'column'} gap={3}>
            <FundingSection />

            {isLoading ? <FormSkeleton /> : null}

            {isSuccess ? <SendAdditionalRecommendationBanner /> : null}

            <AlertForQueryErrorOrNull
              isError={paymentRepresentativeLogic.isError}
              error={paymentRepresentativeLogic.error}
            />
            <AlertForQueryErrorOrNull
              isError={paymentDetailsQuery.isError}
              error={paymentDetailsQuery.error}
            />
          </Grid>
          <Grid item xs={4}>
            <ApplicationOverviewSection />
            <PaymentDocumentsSection />
          </Grid>
        </Grid>
      </Box>
    </RoundBorderBox>
  );
};

const Header = () => {
  const { applicationId } = useApplicationContext();

  const paymentDetailsQuery =
    useGetAdditionalPaymentRecommendationDetailsQuery(applicationId);

  const statusValue = paymentDetailsQuery.data?.recommendationSent
    ? 'Recommendation Sent'
    : 'Not Started';
  const statusColour = paymentDetailsQuery.data?.recommendationSent
    ? 'success'
    : 'warning';

  return (
    <Box bgcolor="grey.100" p={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="1.2em" fontWeight={600}>
          Recommend Additional Payment
        </Typography>

        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Typography variant="body1" color="grey.700" fontSize="0.9em">
            Status
          </Typography>
          <Chip label={statusValue} color={statusColour} variant={'filled'} />
        </Box>
      </Box>
    </Box>
  );
};
