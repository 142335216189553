import { Box, Chip, Stack, Typography } from '@mui/material';
import { ErrorAlert } from 'common/components/alerts';
import { EScheduleOfWorksReferralLanguage } from 'enums/EScheduleOfWorksReferralStatus';
import { IScheduleOfWorksReferral } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { useScheduleOfWorksContext } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/context/ScheduleOfWorksContext';
import { extractErrorMessages } from 'util/ApiUtils';

export enum EReferralsMenuType {
  Complete,
}

export const ScheduleOfWorksSummaryGrid = () => {
  const { scheduleOfWorksReferralsQuery } = useScheduleOfWorksContext();

  if (scheduleOfWorksReferralsQuery.isSuccess) {
    return (
      <ReferralTable referrals={scheduleOfWorksReferralsQuery.data.results} />
    );
  }

  if (scheduleOfWorksReferralsQuery.isError) {
    return (
      <ErrorAlert
        msg={extractErrorMessages(scheduleOfWorksReferralsQuery.error)}
      />
    );
  }

  return null;
};

const ReferralTable = ({
  referrals,
}: {
  referrals: IScheduleOfWorksReferral[];
}) => {
  if (!referrals) {
    return null;
  }

  return (
    <Stack rowGap={2}>
      {referrals.map(referral => (
        <Box
          key={referral.id}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '2px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '5px',
            p: 2,
          }}
        >
          <Typography sx={{ fontSize: '0.8em', pr: 2 }}>
            Referred to HE
          </Typography>
          <Typography sx={{ fontSize: '0.8em' }} component={'div'}>
            <Chip
              label={EScheduleOfWorksReferralLanguage[referral.status]}
              color={'primary'}
              size="small"
              sx={{ fontSize: '0.95em' }}
            />
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};
