import { useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { GridSearchField } from 'common/components/gridSearchField';
import { FilterSelect } from 'common/components/taskGrid/TasksGridFilterSelect';
import { ESortDirection } from 'enums/ESortDirection';
import { teamOptions } from 'enums/ETeam';
import { useApplicationTasksGridContext } from 'pages/applicationPage/content/applicationTasklist';
import {
  StyledFiltersGridLeft,
  StyledFiltersGridRight,
} from 'styles/globalStyles/filterStyles';
import {
  TaskListItemDefault,
  sortOptionsDefault,
  statusOptions,
} from 'types/tasks/CmsTaskTypes';
import { numToStr } from 'util/AppUtils';
import { nameof } from 'util/formUtils';

const sortOptions = sortOptionsDefault.filter(
  m => m.value !== nameof<TaskListItemDefault>('applicationReference')
);

export const ApplicationTasksDataGridNavigation = () => {
  const {
    query,
    state: { gridState },
    gridStateChanged,
  } = useApplicationTasksGridContext();

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      gridStateChanged({ searchValue, skip: 0 });
    },
    [gridStateChanged]
  );

  return (
    <Box p={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h2" fontWeight={900}>
            Tasks{query.isSuccess ? ` (${query.data.count})` : ''}
          </Typography>
        </Grid>
        <Grid container>
          <StyledFiltersGridLeft item xs={4}>
            <GridSearchField
              isLoading={query.isLoading}
              defaultValue={gridState.searchValue}
              handleSearch={handleSearchCallback}
            />
          </StyledFiltersGridLeft>
          <StyledFiltersGridRight item xs={8} columnGap={1}>
            <FilterSelect
              name="team"
              label="Team"
              value={gridState.team.map(numToStr)}
              multiple
              onChange={e => {
                if (Array.isArray(e.target.value)) {
                  gridStateChanged({
                    team: e.target.value,
                    skip: 0,
                  });
                }
              }}
              options={teamOptions}
            />
            <FilterSelect
              name="sort"
              label="Sort"
              value={gridState.sortBy}
              multiple={false}
              onChange={e => {
                gridStateChanged({
                  sortBy: e.target.value as keyof TaskListItemDefault,
                  sortDirection: ESortDirection.Asc,
                });
              }}
              options={sortOptions}
            />
            <FilterSelect
              name="status"
              label="Status"
              value={gridState.status.map(numToStr)}
              multiple
              onChange={e => {
                if (Array.isArray(e.target.value)) {
                  gridStateChanged({
                    status: e.target.value,
                    skip: 0,
                  });
                }
              }}
              options={statusOptions}
            />
          </StyledFiltersGridRight>
        </Grid>
      </Grid>
    </Box>
  );
};
