import { useGetPaymentRecommendationApplicationDetailsQuery } from 'api/application/paymentApi';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EResponsibleEntityType } from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';

export const usePaymentRepresentativeLogic = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, isError, error, data } =
    useGetPaymentRecommendationApplicationDetailsQuery(applicationId);

  const isRepresentativeAndPaymentGoingToRepresentative =
    data?.responsibleEntityType === EResponsibleEntityType.Representative &&
    data?.isPaymentGoingToRepresentative;

  return {
    isLoading,
    isSuccess,
    isError,
    error,
    isRepresentativeAndPaymentGoingToRepresentative,
  };
};
