import { ETeam, ETeamLanguage } from 'enums/ETeam';
import {
  ECmsTaskStatus,
  GetAdminTasksListResponse as GetTasksListResponseAdmin,
  ITask,
  IGetTaskTypesResult,
  ITaskStats,
  ITaskSubtype,
  IGetOutcomesResult,
  TaskNoteView,
  TaskNote,
  GetTaskListResponseDefault as GetTasksListResponseDefault,
  ITopicOption,
} from 'types/tasks/CmsTaskTypes';
import { getGuid } from 'util/AppUtils';

const id = getGuid;

const subtypeArray = [
  'Email',
  'Phone',
  'Letter',
  'Referral',
  'Review',
  'Approval',
  'Rejection',
  'Additional information required',
  'Pre-Application',
  'Account Creation',
  'Initial FRAEW',
  'Exit FRAEW',
  'Eligibility',
  'PTFS',
  'GFA',
  'Payments',
  'Final Payment',
  'Work Package',
  'Progress Reporting',
  'Variations',
  'Practical Completion Report',
] as const;

let topicId = 0;
const getTopicId = () => {
  topicId++;
  return topicId;
};

type AllowedSubtypes = (typeof subtypeArray)[number];

const getTopicArray = (topicArray: string[]): ITopicOption[] => {
  return topicArray.map(name => {
    return { id: getTopicId(), name, slaDays: 3 };
  });
};

const getTopicsForSubType = (subTypeValue: AllowedSubtypes) => {
  switch (subTypeValue) {
    case 'Pre-Application':
      return getTopicArray([
        'General query pre-application',
        'Applicant has not responded to Letter 1 after 60 days of automated outreach – initiate pull-in process',
        'Applicant has not responded to Letter 2 after 60 days of automated outreach – initiate pull-in process',
      ]);

    case 'Account Creation':
      return getTopicArray([
        'Applicant states they are not eligible for the CSS – building de-scoped ',
      ]);

    case 'Initial FRAEW':
      return getTopicArray([
        'Applicant does not have an initial FRAEW and states financial hardship – initiate financial hardship Assessor Panel review',
        'Applicant has a FRAEW submitted by someone not on the Panel – initiate FRAEW Assurance process',
        'FRAEW Summary falls within the quality assurance sample – initiate FRAEW Assurance process',
      ]);

    default:
      return [];
  }
};

const subtypeObjects = Object.values(subtypeArray).reduce<
  Record<string, ITaskSubtype>
>((ret, value, i) => {
  ret[value] = {
    id: i,
    name: value,
    active: true,
    isDueDateEditable: true,
    topics: getTopicsForSubType(value),
  };
  return ret;
}, {});

const getSubtypeObjectsFromStrings = (subtypesParam: AllowedSubtypes[]) => {
  return subtypesParam.map(name => subtypeObjects[name]);
};

export const taskTypesResponse: IGetTaskTypesResult = {
  taskTypes: [
    {
      id: 1,
      active: true,
      name: 'Communication received',
      subtypes: getSubtypeObjectsFromStrings(['Email', 'Phone', 'Letter']),
    },
    {
      id: 2,
      active: true,
      name: 'Communication sent',
      subtypes: getSubtypeObjectsFromStrings(['Email', 'Phone', 'Letter']),
    },
    {
      id: 3,
      name: 'Escalations',
      active: true,
      subtypes: getSubtypeObjectsFromStrings([
        'Pre-Application',
        'Account Creation',
        'Initial FRAEW',
      ]),
    },
    {
      id: 4,
      name: 'Application',
      active: true,
      subtypes: getSubtypeObjectsFromStrings(['Email', 'Phone']),
    },
    {
      id: 5,
      name: 'Eligibility',
      active: true,
      subtypes: getSubtypeObjectsFromStrings([
        'Referral',
        'Review',
        'Approval',
        'Rejection',
        'Additional information required',
      ]),
    },
    {
      id: 6,
      name: 'Escalation',
      active: true,
      subtypes: getSubtypeObjectsFromStrings([
        'Referral',
        'Review',
        'Approval',
        'Rejection',
        'Additional information required',
      ]),
    },
    {
      id: 7,
      name: 'Pre-tender support',
      active: true,
      subtypes: getSubtypeObjectsFromStrings([
        'Referral',
        'Review',
        'Approval',
        'Rejection',
        'Additional information required',
      ]),
    },
    {
      id: 8,
      name: 'Work Package application',
      active: true,
      subtypes: getSubtypeObjectsFromStrings([
        'Referral',
        'Review',
        'Approval',
        'Rejection',
        'Additional information required',
      ]),
    },
    {
      id: 9,
      name: 'Work Package eligibility',
      active: true,
      subtypes: getSubtypeObjectsFromStrings([
        'Referral',
        'Review',
        'Approval',
        'Rejection',
        'Additional information required',
      ]),
    },
    {
      id: 10,
      name: 'Payments',
      active: true,
      subtypes: getSubtypeObjectsFromStrings([
        'Referral',
        'Review',
        'Approval',
        'Rejection',
        'Additional information required',
      ]),
    },
  ],
};

export const taskOutcomesResponse: IGetOutcomesResult = {
  outcomes: [
    {
      id: '9976c270b-b09f-4953-a351-47b8c0efade1',
      name: 'Query answered',
    },
    {
      id: id(),
      name: 'Approval granted',
    },
    {
      id: id(),
      name: 'Timeout/no response',
    },
  ],
};

export const taskResponse: ITask = {
  applicationReference: 'ABC123',
  assigneeUserId: '556c270b-b09f-4953-a351-47b8c0efabc0',
  description:
    'Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc ',
  buildingName: 'Building Name',
  applicant: 'Homer Simpson',
  createdBy: 'Job Bloggs',
  createdDate: new Date().toJSON(),
  dueDate: new Date().toJSON(),
  status: ECmsTaskStatus.InProgress,
  taskTypeId: taskTypesResponse.taskTypes[0].id,
  taskSubtypeId: taskTypesResponse.taskTypes[0].subtypes[0].id,
  notes: 'Something in here',
  outcomeId: '9976c270b-b09f-4953-a351-47b8c0efade1',
  assignedToTeamId: ETeam.DaviesOps,
};

export const taskStats: ITaskStats = {
  dueTodayCount: 11,
  dueTomorrowCount: 230,
  inProgressCount: 45,
  notStartedCount: 4,
  overdueCount: 9,
};

const adminTaskListResponseResults: GetTasksListResponseAdmin['results'] = [
  {
    applicationReference: 'ABC123',
    applicationId: id(),
    assignedTo: 'Clem Fandango',
    description:
      'Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc ',
    buildingName: 'Building Nane',
    applicant: 'Homer Simpson',
    createdBy: 'Joe Bloggs',
    createdDate: new Date().toJSON(),  
    taskId: id(),
    dueDate: new Date().toJSON(),
    status: ECmsTaskStatus.InProgress,
    taskType: taskTypesResponse.taskTypes[0].name,
    taskSubtype: taskTypesResponse.taskTypes[0].subtypes[0].name,
    topic: taskTypesResponse.taskTypes[0].subtypes[0].topics[0]?.name,
    noteCount: 0,
    assignedToTeamName: ETeamLanguage[ETeam.DaviesOps],
  },
  {
    applicationReference: 'ABC123',
    applicationId: id(),
    assignedTo: 'Ray Purchase',
    description: 'Lorem ipsum etc',
    buildingName: 'Building Nane',
    applicant: 'Homer Simpson',
    createdBy: 'Joe Bloggs',
    createdDate: new Date().toJSON(),  
    taskId: id(),
    dueDate: new Date().toJSON(),
    status: ECmsTaskStatus.Done,
    taskType: taskTypesResponse.taskTypes[1].name,
    taskSubtype: taskTypesResponse.taskTypes[1].subtypes[0].name,
    topic: taskTypesResponse.taskTypes[1].subtypes[0].topics[0]?.name,
    noteCount: 3,
    assignedToTeamName: ETeamLanguage[ETeam.HomesEnglandOperations],
  },
  {
    applicationReference: 'ABC123',
    applicationId: id(),
    assignedTo: 'Steven Toast',
    description:
      'Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc Lorem ipsum etc ',
    buildingName: 'Building Nane',
    applicant: 'Homer Simpson',
    createdBy: 'Joe Bloggs',
    createdDate: new Date().toJSON(),  
    taskId: id(),
    dueDate: new Date().toJSON(),
    status: ECmsTaskStatus.NotStarted,
    taskType: taskTypesResponse.taskTypes[2].name,
    taskSubtype: taskTypesResponse.taskTypes[2].subtypes[0].name,
    topic: '',
    noteCount: 2,
    assignedToTeamName: ETeamLanguage[ETeam.DaviesOps],
  },
];

export const taskListResponseAdmin: GetTasksListResponseAdmin = {
  results: adminTaskListResponseResults,
  count: adminTaskListResponseResults.length,
};

export const taskListResponseDefault: GetTasksListResponseDefault = {
  results: adminTaskListResponseResults,
  count: adminTaskListResponseResults.length,
};

export const notesData: TaskNoteView[] = [
  {
    subtaskId: id(),
    assigneeName: 'Clem Fandango',
    description: `Steven, this is Clem Fandango, can you hear me?`,
    dueDate: new Date().toJSON(),
    status: ECmsTaskStatus.NotStarted,
  },
  {
    subtaskId: id(),
    assigneeName: 'Steven Toast',
    description: `Yes I can hear you Clem Fandango. Get on with it.`,
    dueDate: new Date().toJSON(),
    status: ECmsTaskStatus.InProgress,
  },
  {
    subtaskId: id(),
    assigneeName: 'Ray Purchase',
    description: `Toast, we meet again.`,
    dueDate: new Date().toJSON(),
    status: ECmsTaskStatus.Done,
  },
];

export const noteData: TaskNote = {
  assigneeUserId: '556c270b-b09f-4953-a351-47b8c0efabc0',
  description: notesData[0].description,
  dueDate: notesData[0].dueDate,
  status: notesData[0].status,
  notes: 'All sorted bruv',
  outcomeId: '9976c270b-b09f-4953-a351-47b8c0efade1',
};
