import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  Button,
  ToggleButton,
  FormControl,
  ToggleButtonGroup,
  Collapse,
  TextField,
  MenuItem,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  useUpdateBuildingToApplicationMutation,
  useUpdateBuildingToDuplicateMutation,
  useUpdateBuildingToNotEligibleMutation,
} from 'api/pullInProcess';
import {
  EPullInNotEligibleReason,
  EPullInNotEligibleReasonTypeLanguage,
} from 'enums/EPullInNotEligibleReason';
import { useLocalSnackbar, useModalState } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import {
  zDuplicateFormSchema,
  zNotEligibleFormSchema,
  zProgressApplicationFormSchema,
} from 'types/pullInProcess/statuses.zod';
import {
  extractErrorMessages,
  instanceOfTransactionErrorWithPropertyErrors,
} from 'util/ApiUtils';
import { setServerSideFormErrors } from 'util/formUtils';

export type TStatusesProps = {
  modalState: ReturnType<typeof useModalState<string | null>>;
};

export function Statuses({
  modalState: { isShowing, hideModal },
}: TStatusesProps) {
  const [toggleButtonValue, setToggleButtonValue] = useState<1 | 2 | 3 | null>(
    null
  );
  const formId = 'form-statuses';

  return (
    <StyledDrawer anchor="right" open={isShowing}>
      <DialogTitle component="div">
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h1" component="span">
            Update status
          </Typography>
          <IconButton
            onClick={hideModal}
            aria-label="Close drawer"
            name="Close drawer"
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <StatusesForm
          formId={formId}
          toggleButtonValue={toggleButtonValue}
          setToggleButtonValue={setToggleButtonValue}
          hideModal={hideModal}
        />
      </DialogContent>
      <StyledDrawerActions>
        <Grid container justifyContent="flex-end" gap={1}>
          <Button variant="outlined" onClick={hideModal}>
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            type="submit"
            disabled={toggleButtonValue === null}
            form={formId + toggleButtonValue}
          >
            Save
          </LoadingButton>
        </Grid>
      </StyledDrawerActions>
    </StyledDrawer>
  );
}

export function StatusesForm({
  formId,
  toggleButtonValue,
  setToggleButtonValue,
  hideModal,
}: {
  formId: string;
  toggleButtonValue: 1 | 2 | 3 | null;
  setToggleButtonValue: Dispatch<SetStateAction<1 | 2 | 3 | null>>;
  hideModal: () => void;
}) {
  useEffect(() => {
    return () => setToggleButtonValue(null);
  }, [setToggleButtonValue]);
  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <ToggleButtonGroup
          value={toggleButtonValue}
          onChange={(_, val) => {
            if (val !== null) {
              setToggleButtonValue(val);
            }
          }}
          exclusive
          sx={{
            display: 'flex',
            gap: '1rem',
            width: '100%',
            boxShadow: 'unset',
          }}
        >
          <ToggleButton
            sx={{
              flexBasis: '50%',
            }}
            key={'progresses'}
            value={1}
          >
            Progressed to application
          </ToggleButton>
          <ToggleButton
            sx={{
              flexBasis: '50%',
            }}
            key={'notEligible'}
            value={2}
          >
            Not Eligible
          </ToggleButton>
          <ToggleButton
            sx={{
              flexBasis: '50%',
            }}
            key={'duplicate'}
            value={3}
          >
            Duplicate
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      <Collapse key={1} in={toggleButtonValue === 1} timeout={1000}>
        <ProgressApplicationForm formId={formId} hideModal={hideModal} />
      </Collapse>
      <Collapse key={2} in={toggleButtonValue === 2} timeout={1000}>
        <NotEligibleForm formId={formId + 2} hideModal={hideModal} />
      </Collapse>
      <Collapse key={3} in={toggleButtonValue === 3} timeout={1000}>
        <DuplicateForm formId={formId + 3} hideModal={hideModal} />
      </Collapse>
    </>
  );
}

export function ProgressApplicationForm({
  formId,
  hideModal,
}: {
  formId: string;
  hideModal: () => void;
}) {
  const form = useForm<{ applicationRef: string }>({
    resolver: zodResolver(zProgressApplicationFormSchema),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();
  const { pullInProcessId } = usePullInProcessContext();

  const [updateApplication] = useUpdateBuildingToApplicationMutation();

  async function onSubmit(formData: { applicationRef: string }) {
    await updateApplication({ buildingId: pullInProcessId, ...formData })
      .unwrap()
      .then(data => {
        if (instanceOfTransactionErrorWithPropertyErrors(data)) throw data;
        createSuccessSnackbar('Successfully progress to application');
        hideModal();
      })
      .catch(e => {
        if (instanceOfTransactionErrorWithPropertyErrors(e)) {
          const tError = e.data;
          tError.propertyErrors.forEach(() => {
            setServerSideFormErrors(form, tError);
          });
        } else {
          createErrorSnackbar(extractErrorMessages(e));
        }
      });
  }

  return (
    <form id={formId + 1} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="applicationRef"
        defaultValue=""
        render={({ field: { ref, ...fieldRest } }) => (
          <TextField
            sx={{ mt: '1rem' }}
            fullWidth
            {...fieldRest}
            label="Application id"
            error={!!errors.applicationRef}
            helperText={errors.applicationRef?.message}
          />
        )}
      />
    </form>
  );
}

type TNotEligibleOrDuplicateForm = {
  notEligibleReasonId: EPullInNotEligibleReason;
  description: string;
};

export function NotEligibleForm({
  formId,
  hideModal,
}: {
  formId: string;
  hideModal: () => void;
}) {
  const form = useForm<TNotEligibleOrDuplicateForm>({
    resolver: zodResolver(zNotEligibleFormSchema),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;
  const { pullInProcessId } = usePullInProcessContext();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const [updateNotEligible] = useUpdateBuildingToNotEligibleMutation();

  async function onSubmit(formData: TNotEligibleOrDuplicateForm) {
    const data = { buildingId: pullInProcessId, ...formData };
    try {
      await updateNotEligible(data).unwrap();
      createSuccessSnackbar(`Eligibility successfully updated`);
      hideModal();
    } catch (e) {
      if (instanceOfTransactionErrorWithPropertyErrors(e)) {
        const tError = e.data;
        tError.propertyErrors.forEach(() => {
          setServerSideFormErrors(form, tError);
        });
      } else {
        createErrorSnackbar(extractErrorMessages(e));
      }
    }
  }
  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <>
        <Typography mt="1rem">Reason</Typography>
        <Controller
          control={control}
          name="notEligibleReasonId"
          defaultValue={0 as 1}
          render={({ field: { ...fieldRest } }) => (
            <TextField
              fullWidth
              select
              {...fieldRest}
              label="Reason"
              error={!!errors.notEligibleReasonId}
              helperText={errors.notEligibleReasonId?.message}
            >
              <MenuItem disabled value={0}>
                <em>Please select a reason</em>
              </MenuItem>
              {Object.entries(EPullInNotEligibleReasonTypeLanguage).map(
                ([value, label]) => (
                  <MenuItem key={value} value={+value}>
                    {label}
                  </MenuItem>
                )
              )}
            </TextField>
          )}
        />
        <Controller
          control={control}
          name="description"
          defaultValue=""
          render={({ field: { ref, ...fieldRest } }) => (
            <TextField
              sx={{ mt: '1rem' }}
              fullWidth
              {...fieldRest}
              label="Description"
              error={!!errors.description}
              helperText={errors.description?.message}
              multiline
              minRows={3}
            />
          )}
        />
      </>
    </form>
  );
}

type TDuplicateForm = {
  reason: string;
};

export function DuplicateForm({
  formId,
  hideModal,
}: {
  formId: string;
  hideModal: () => void;
}) {
  const form = useForm<TDuplicateForm>({
    resolver: zodResolver(zDuplicateFormSchema),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;
  const { pullInProcessId } = usePullInProcessContext();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const [updateDuplicate] = useUpdateBuildingToDuplicateMutation();

  async function onSubmit(formData: TDuplicateForm) {
    const data = { buildingId: pullInProcessId, ...formData };
    try {
      await updateDuplicate(data).unwrap();
      createSuccessSnackbar('Duplicate successfully updated');
      hideModal();
    } catch (e) {
      if (instanceOfTransactionErrorWithPropertyErrors(e)) {
        const tError = e.data;
        tError.propertyErrors.forEach(() => {
          setServerSideFormErrors(form, tError);
        });
      } else {
        createErrorSnackbar(extractErrorMessages(e));
      }
    }
  }
  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <>
        <Typography mt="1rem">Reason</Typography>
        <Controller
          control={control}
          name="reason"
          defaultValue=""
          render={({ field: { ref, ...fieldRest } }) => (
            <TextField
              sx={{ mt: '1rem' }}
              fullWidth
              {...fieldRest}
              label="Reason"
              error={!!errors.reason}
              helperText={errors.reason?.message}
              multiline
              minRows={3}
            />
          )}
        />
      </>
    </form>
  );
}
