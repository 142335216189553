import { useMemo, useState } from 'react';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SortDirection,
  TableSortLabel,
  IconButton,
  Menu,
  Box,
  Button,
  Typography,
  Chip,
} from '@mui/material';
import { useGetPullInProcessContactsQuery } from 'api/pullInProcess';
import { EPullInProcessContactTypeLanguage } from 'enums/EPullInProcessContactType';
import { ESortDirection } from 'enums/ESortDirection';
import { useEllipsisMenu, useModalState } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { AddEditContact } from 'pages/pullInProcessPage/components/overview/addEditContact';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { TPullInProcesscontact } from 'types/pullInProcess/addBuildingRecordTypes';
import { getMenuItems, getMenuItemConfig } from 'util/AppUtils';

enum ContactContextMenu {
  Edit = 1,
  View = 2,
}

export function ContactsPanel() {
  const { pullInProcessId } = usePullInProcessContext();
  const { isLoading, data, isSuccess } =
    useGetPullInProcessContactsQuery(pullInProcessId);

  const modalState = useModalState<string | null>();
  const { showModal } = modalState;

  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const handleMenuItemClick = (menuType: ContactContextMenu) => {
    switch (menuType) {
      case ContactContextMenu.Edit:
        setOpenInReadonly(false);
        showModal(selectedId);
        break;
      case ContactContextMenu.View:
        setOpenInReadonly(true);
        showModal(selectedId);
        break;
    }

    handleMenuClose();
  };

  const [sortBy, setSortBy] =
    useState<keyof TPullInProcesscontact>('companyName');
  const [sortDirection, setSortDirection] = useState<ESortDirection>(
    ESortDirection.Asc
  );
  const [openInReadonly, setOpenInReadonly] = useState(false);

  const getAriaSortDirection = (
    property: keyof TPullInProcesscontact,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getButtonAriaSortDirection = (
    property: keyof TPullInProcesscontact,
    direction: ESortDirection
  ): 'ascending' | 'descending' => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'ascending'
      : 'descending';
  };

  const getCurrentSortDirection = (
    property: keyof TPullInProcesscontact,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof TPullInProcesscontact,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          aria-sort={getButtonAriaSortDirection(property, sortDirection)}
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => {
            if (property === sortBy) {
              setSortDirection(prev =>
                prev === ESortDirection.Asc
                  ? ESortDirection.Desc
                  : ESortDirection.Asc
              );
            } else {
              setSortBy(property);
            }
          }}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  const sortedData = useMemo(() => {
    if (!isSuccess || data?.length === 0) return undefined;
    const newData = structuredClone(data);
    const res = newData.sort((a, b) => {
      const leftValue =
        sortBy === 'companyNumber'
          ? Number.isNaN(Number(a[sortBy]))
            ? -Infinity
            : Number(a[sortBy])
          : a[sortBy] ?? '';
      const rightValue =
        sortBy === 'companyNumber'
          ? Number.isNaN(Number(b[sortBy]))
            ? -Infinity
            : Number(b[sortBy])
          : b[sortBy] ?? '';
      const [left, right] =
        sortDirection === ESortDirection.Asc
          ? [leftValue, rightValue]
          : [rightValue, leftValue];
      if (left < right) return -1;
      if (left > right) return 1;
      return 0;
    });
    return res;
  }, [isSuccess, data, sortBy, sortDirection]);

  if (isLoading) return <div>loading data</div>;
  if (!isSuccess) return <div>issue found</div>;

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600}>Contacts</Typography>
        {sortedData !== undefined ? (
          <Button
            onClick={() => showModal()}
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
          >
            Add Contact
          </Button>
        ) : null}
      </Box>
      {sortedData === undefined ? (
        <Box bgcolor={'#f0f0f0'} textAlign="center" p="1rem" mt="0.5rem">
          <Typography fontWeight={600}>
            There are no contacts to show
          </Typography>
          <Typography>You can add a contact below</Typography>
          <Button
            size="small"
            variant="outlined"
            onClick={() => showModal()}
            sx={{ mt: '0.5rem' }}
          >
            Add a contact
          </Button>
        </Box>
      ) : (
        <StyledGenericTable data-testid="Contacts-Table">
          <TableHead>
            <TableRow>
              {getHeaderTableCell('companyName', 'Company Name')}
              {getHeaderTableCell('contactType', 'Contact Type')}
              {getHeaderTableCell('firstName', 'Contact')}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map(
              ({
                id,
                companyName,
                companyNumber,
                contactType,
                firstName,
                surname,
                isDefaultContact,
              }) => (
                <TableRow key={id}>
                  <TableCell>{`${companyName} ${
                    companyNumber ? `(${companyNumber})` : ''
                  }`}</TableCell>
                  <TableCell>
                    {EPullInProcessContactTypeLanguage[contactType]}
                  </TableCell>
                  <TableCell>
                    {`${firstName ?? ''} ${surname ?? ''}`}{' '}
                    {isDefaultContact ? (
                      <Chip size="small" label="Default Contact" />
                    ) : null}
                  </TableCell>
                  <TableCell style={{ width: '5em' }} aria-label="Actions">
                    <IconButton
                      aria-label="Edit contact details"
                      name="Edit contact details"
                      onClick={event => handleEllipsisClick(id, event)}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                    {selectedId === id ? (
                      <Menu
                        id={`pull-in-process-contact-menu-${id}`}
                        anchorEl={ellipsisMenuAnchor}
                        open={Boolean(ellipsisMenuAnchor)}
                        onClose={handleMenuItemClick}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {getMenuItems(
                          [
                            getMenuItemConfig(
                              'View contact details',
                              ContactContextMenu.View
                            ),
                            getMenuItemConfig(
                              'Edit contact details',
                              ContactContextMenu.Edit
                            ),
                          ],
                          handleMenuItemClick
                        )}
                      </Menu>
                    ) : null}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </StyledGenericTable>
      )}
      <AddEditContact modalState={modalState} readonly={openInReadonly} />
    </>
  );
}
