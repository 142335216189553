import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useGetWorksPackageOverviewQuery } from 'api/application/worksPackageApi';
import { useCurrentUserPermissions } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EWorksPackageSection } from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';

interface IWorksPackageContext {
  applicationId: string;
  selectedItemId: EWorksPackageSection;
  setSelectedItemId: (itemId: EWorksPackageSection) => void;
  readOnly: boolean;
  canUserEditGrantPercentage: boolean;
}

const WorksPackageContext = createContext<IWorksPackageContext | undefined>(
  undefined
);

interface IWorksPackageContextProviderProps {
  children: ReactNode;
}

export const WorksPackageContextProvider = ({
  children,
}: IWorksPackageContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();

  const [selectedItemId, setSelectedItemId] = useState<EWorksPackageSection>(
    EWorksPackageSection.Overview
  );

  const handleSetSelectedItemId = (itemId: EWorksPackageSection) => {
    setSelectedItemId(itemId);
  };

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const canUserEditGrantPercentage = doesUserHaveSinglePermission(
    'workpackage.grantpercentage.edit'
  );

  const { data: overViewData } = useGetWorksPackageOverviewQuery(applicationId);
  const isApprovedOrRejected = Boolean(
    overViewData?.applicationWorkPackageApprovalStatus
  );
  const readOnly = isApprovedOrRejected || closed;

  const value = useMemo(
    () => ({
      applicationId,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      readOnly,
      canUserEditGrantPercentage,
    }),
    [applicationId, canUserEditGrantPercentage, readOnly, selectedItemId]
  );

  return (
    <WorksPackageContext.Provider value={value}>
      {children}
    </WorksPackageContext.Provider>
  );
};

export const useWorksPackageContext = () => {
  const context = useContext(WorksPackageContext);
  if (context === undefined) {
    throw new Error(
      'useWorksPackageContext must be used within an WorksPackageContextProvider'
    );
  }
  return context;
};
