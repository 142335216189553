import { IFile } from 'common/types/File';

export enum EDutyOfCareSection {
  SendDutyOfCareDeed = '1',
  UploadDocuments = '2',
  UploadCounterSignedDocuments = '3',
}

export interface IDutyOfCareSentStatus {
  applicationId: string;
  dutyOfCareSent: boolean | null;
  dateSent: Date | null;
  sentEvidenceFile: IFile | null;
}

export interface IDutyOfCareSend {
  applicationId: string;
  dutyOfCareSent: boolean | null;
  sentEvidenceFileId: IFile | string | null;
}

export interface IDutyOfCareSignedStatus {
  applicationId: string;
  dutyOfCareSigned: boolean | null;
  dateSigned: Date | null;
  signedDutyOfCareFile: IFile | null;
}

export interface IDutyOfCareCounterSignedStatus {
  applicationId: string;
  dutyOfCareCounterSigned: boolean | null;
  dateCounterSigned: Date | null;
  counterSignedDutyOfCareFile: IFile | null;
}

export interface IDutyOfCareSign {
  applicationId: string;
  dutyOfCareSigned: boolean | null;
  signedDutyOfCareFileId: IFile | string | null;
}

export interface IDutyOfCareCounterSign {
  applicationId: string;
  dutyOfCareCounterSigned: boolean | null;
  counterSignedDutyOfCareFileId: IFile | string | null;
}

export interface IDutyOfCareSentStatusForm {
  dutyOfCareSent: boolean | null;
  sentEvidenceFile: IFile | null;
  sentEvidenceFileId: string;
}

export interface IDutyOfCareSignedStatusForm {
  dutyOfCareSigned: boolean | null;
  signedDutyOfCareFile: IFile | null;
  signedDutyOfCareFileId: string;
}

export interface IDutyOfCareCounterSignedStatusForm {
  dutyOfCareCounterSigned: boolean | null;
  counterSignedDutyOfCareFile: IFile | null;
  counterSignedDutyOfCareFileId: string;
}

