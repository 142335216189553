import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { useModalState } from 'hooks';
import { DeleteInterventionCategoryDialog } from 'pages/applicationPage/common/summary/DeleteInterventionCategoryDialog';

interface IInterventionCategoryNotificationBannerProps {
  interventionCategoryName: string;
}

export function InterventionCategoryNotificationBanner({
  interventionCategoryName,
}: IInterventionCategoryNotificationBannerProps) {
  const {
    isShowing: isDeleteInterventionCategoryDialogShowing,
    showModal: showInterventionCategoryDialog,
    hideModal: hideInterventionCategoryDialog,
  } = useModalState();

  return (
    <>
      <Container maxWidth={false}>
        <RoundBorderBox sx={{ bgcolor: '#F9E0BB', mb: 1, p: 3 }}>
          <Box>
            <Grid container direction="row">
              <Grid item xs={10}>
                <Stack direction="row" spacing={2}>
                  <FontAwesomeIcon
                    icon={solid('circle-exclamation')}
                    size="lg"
                    color={'#ED7D31'}
                    style={{
                      marginTop: '0.25rem',
                    }}
                  />
                  <Typography fontSize="1em" color="black">
                    {`Intervention Category - ${interventionCategoryName}`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <Button onClick={showInterventionCategoryDialog}>Remove</Button>
              </Grid>
            </Grid>
          </Box>
        </RoundBorderBox>
      </Container>

      {isDeleteInterventionCategoryDialogShowing && (
        <DeleteInterventionCategoryDialog
          interventionCategoryName={interventionCategoryName}
          onConfirm={() => {
            hideInterventionCategoryDialog();
          }}
          onCancel={() => {
            hideInterventionCategoryDialog();
          }}
        />
      )}
    </>
  );
}
