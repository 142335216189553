import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPullInProcessSummaryQuery } from 'api/pullInProcess';
import {
  EPullInProcessStatus,
  EPullInProcessStatusLanguage,
} from 'enums/EPullInProcessStatus';
import { useModalState } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { ContactsPanel } from 'pages/pullInProcessPage/components/overview/contactsPanel';
import { OverviewSummaryPanel } from 'pages/pullInProcessPage/components/overview/overviewSummaryPanel';
import { StyledRoundBorderBox } from 'pages/pullInProcessPage/components/pullInProcessPageLayout';
import { Statuses } from 'pages/pullInProcessPage/components/statuses/Statuses';

export function PullInProcessPageOverview() {
  const modalState = useModalState<string | null>();
  const { pullInProcessId } = usePullInProcessContext();
  const { data, isSuccess } = useGetPullInProcessSummaryQuery(
    pullInProcessId ?? skipToken
  );
  const { palette } = useTheme();
  return (
    <StyledRoundBorderBox>
      <Box>
        <Typography component="h3" variant="h3" fontWeight={600}>
          Overview
        </Typography>
      </Box>
      <Box>
        <Collapse
          in={
            data &&
            isSuccess &&
            [
              EPullInProcessStatus.NotEligible,
              EPullInProcessStatus.ProgressedToApplication,
              EPullInProcessStatus.Duplicate,
            ].includes(data?.status || 0)
          }
        >
          <Box m="1rem" bgcolor={'#f5f5f5'} p="1rem 1rem 3rem">
            <Box gap="1rem" display="flex" alignItems="center">
              <FontAwesomeIcon
                icon={
                  data?.status === EPullInProcessStatus.ProgressedToApplication
                    ? faCircleCheck
                    : faXmarkCircle
                }
                color={
                  data?.status === EPullInProcessStatus.ProgressedToApplication
                    ? palette.success.light
                    : palette.error.light
                }
                size="2x"
              />
              <Typography fontWeight={600}>
                {
                  EPullInProcessStatusLanguage[
                    data?.status as
                      | EPullInProcessStatus.NotEligible
                      | EPullInProcessStatus.ProgressedToApplication
                      | EPullInProcessStatus.Duplicate
                  ]
                }
              </Typography>
            </Box>

            {data?.statusDescription
              ? data.statusDescription?.map?.((val, idx) => (
                  <Typography
                    m={'1rem 0 0 3rem'}
                    key={idx}
                    sx={{ '&+&': { mt: '0' } }}
                  >
                    {val}
                  </Typography>
                )) ?? 'No description provided'
              : 'No description provided'}
          </Box>
        </Collapse>
        <Box m="1rem" bgcolor={'#f5f5f5'} p="1rem 1rem 3rem">
          <OverviewSummaryPanel />
        </Box>
        <Box m="1rem" bgcolor={'#f5f5f5'} p="1rem 1rem 3rem">
          <ContactsPanel />
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
        {/* <LoadingButton loading={false} variant="outlined"> Not needed until later, update justify content to space-between
          Refer Application
        </LoadingButton> */}

        {![
          EPullInProcessStatus.Duplicate,
          EPullInProcessStatus.NotEligible,
          EPullInProcessStatus.ProgressedToApplication,
        ].includes(data?.status as EPullInProcessStatus) && (
          <Button
            variant="contained"
            type="submit"
            onClick={() => modalState.showModal()}
          >
            Update Status
          </Button>
        )}
        <Statuses modalState={modalState} />
      </Box>
    </StyledRoundBorderBox>
  );
}
