import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { NavigateFunction } from 'react-router-dom';
import { NavItemConfig } from 'common/components/leftHandNav';
import { useAppDispatch } from 'state';
import { resetApplicationsState } from 'state/slices/applications';

interface ILeftHandNavItemProps {
  leftNavItem: NavItemConfig;
  navBarExpanded: boolean;
  navigate: NavigateFunction;
}

export const LeftHandNavItem = ({
  leftNavItem,
  navBarExpanded,
  navigate,
}: ILeftHandNavItemProps) => {
  const dispatch = useAppDispatch();

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: navBarExpanded ? 'initial' : 'center',
          px: 2.5,
        }}
        onClick={() => {
          if (leftNavItem?.href === 'applications') {
            dispatch(resetApplicationsState());
          }

          if (leftNavItem?.href) navigate(leftNavItem.href);
        }}
        aria-label={leftNavItem.name}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: navBarExpanded ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <FontAwesomeIcon icon={leftNavItem.iconDefinition} />
        </ListItemIcon>
        <ListItemText
          primary={leftNavItem.name}
          sx={{ opacity: navBarExpanded ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
};
