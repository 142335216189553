import { ReactNode, useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { ErrorAlert } from 'common/components/alerts';
import { Loading } from 'common/components/loading';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { ApplicationItemGroupNavigator } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ApplicationDetailsSectionOutlet } from 'pages/applicationPage/content/applicationDetails';
import { CloseApplicationModal } from 'pages/applicationPage/content/applicationDetails/CloseApplicationModal';
import { ApplicationDetailsSection } from 'pages/applicationPage/content/applicationDetails/components';
import {
  ApplicationDetailsContextProvider,
  useApplicationDetailsContext,
} from 'pages/applicationPage/content/applicationDetails/context';
import { isItemClickable } from 'pages/applicationPage/content/applicationDetails/utils/applicationDetailsUtils';
import { useAppDispatch } from 'state';
import {
  communicationsGridStateChanged,
  documentsGridStateChanged,
} from 'state/slices/applications';
import { EApplicationDetailsItemStatus } from 'types/applications/ApplicationDetailTypes';
import { extractErrorMessages } from 'util/ApiUtils';

export const ApplicationDetails = () => {
  return (
    <ApplicationDetailsContextProvider>
      <ApplicationDetailsMain />
    </ApplicationDetailsContextProvider>
  );
};

export const ApplicationDetailsMain = () => {
  const dispatch = useAppDispatch();

  const { applicationId, closed } = useApplicationContext();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasApplicationClosePermission =
    doesUserHaveSinglePermission('applications.close');

  const {
    isShowing: isCloseApplicationDialogShowing,
    showModal: showCloseApplicationDialog,
    hideModal: hideCloseApplicationDialog,
  } = useModalState();

  useEffect(() => {
    dispatch(documentsGridStateChanged({ applicationId: applicationId }));
    dispatch(communicationsGridStateChanged({ applicationId: applicationId }));
  }, [applicationId, dispatch]);

  const { selectedItemId, setSelectedItemId, applicationCategoriesQuery } =
    useApplicationDetailsContext();

  const { isLoading, error, data } = applicationCategoriesQuery;

  if (isLoading) {
    return <Loading isOpen />;
  }

  if (error) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (!data || data.categories.length <= 0) {
    return (
      <ErrorAlert msg="Could not get any application detail categories." />
    );
  }

  const isItemSelected = Boolean(selectedItemId);

  return (
    <>
      {!isItemSelected ? (
        <Box>
          {data.categories.map(category => (
            <ApplicationDetailsSection
              key={category.order}
              title={category.categoryTitle}
              items={category.detailItems}
            />
          ))}

          {hasApplicationClosePermission && !closed && (
            <>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={showCloseApplicationDialog}
                >
                  Close Application
                </Button>
              </Box>
              <CloseApplicationModal
                isShowing={isCloseApplicationDialogShowing}
                hideModal={hideCloseApplicationDialog}
              />
            </>
          )}
        </Box>
      ) : null}

      {isItemSelected ? (
        <Grid container columnSpacing={3}>
          <Grid item xs={3.5}>
            <ColumnContainer>
              <Box p={2}>
                <ApplicationItemGroupNavigator
                  itemGroups={data.categories.map(category => {
                    return {
                      title: category.categoryTitle,
                      items: category.detailItems.map(detailItem => {
                        return {
                          title: detailItem.sectionName,
                          isSelected: selectedItemId === detailItem.id,
                          isComplete:
                            detailItem.taskStatus ===
                            EApplicationDetailsItemStatus.Completed,
                          onClick: () => {
                            setSelectedItemId(
                              isItemClickable(detailItem) ? detailItem.id : null
                            );
                          },
                        };
                      }),
                    };
                  })}
                />
              </Box>
            </ColumnContainer>
          </Grid>
          <Grid item xs={8.5}>
            <ColumnContainer>
              <ApplicationDetailsSectionOutlet />
            </ColumnContainer>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

const ColumnContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box borderRadius={1} border={1} borderColor="divider">
      {children}
    </Box>
  );
};
