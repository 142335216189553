import { ReactNode } from 'react';
import { Grid, GridProps } from '@mui/material';

interface IMainContentContainerProps extends GridProps {
  children: ReactNode;
}

export const MainContentContainer = ({
  children,
  ...rest
}: IMainContentContainerProps) => {
  return (
    <Grid container px={2} {...rest}>
      {children}
    </Grid>
  );
};
