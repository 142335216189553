import { Typography } from '@mui/material';

interface IGovUkTagProps {
  text: string;
  variant:
    | 'default'
    | 'grey'
    | 'green'
    | 'turquoise'
    | 'blue'
    | 'purple'
    | 'pink'
    | 'red'
    | 'orange'
    | 'yellow';
  size?: 'sm' | 'lg';
}

type GovUkTageVariant = IGovUkTagProps['variant'];
type StyleMapValue = {
  background: string;
  color: string;
};

const styleMap: Record<GovUkTageVariant, StyleMapValue> = {
  default: { background: '#1d70b8', color: '#fff' },
  grey: { background: '#eeefef', color: '#383f43' },
  green: { background: '#cce2d8', color: '#005a30' },
  turquoise: { background: '#bfe3e0', color: '#10403c' },
  blue: { background: '#d2e2f1', color: '#144e81' },
  purple: { background: '#dbd5e9', color: '#3d2375' },
  pink: { background: '#f7d7e6', color: '#80224d' },
  red: { background: '#f6d7d2', color: '#942514' },
  orange: { background: '#fcd6c3', color: '#6e3619' },
  yellow: { background: '#fff7bf', color: '#594d00' },
};
const getStyleForVariant = (variant: GovUkTageVariant) => {
  return styleMap[variant];
};

// Styling taken from https://design-system.service.gov.uk/components/tag/

export const GovUkTag = ({ text, variant, size = 'lg' }: IGovUkTagProps) => {
  return (
    <Typography
      component="strong"
      fontSize={size === 'sm' ? '0.8em' : '0.95em'}
      fontWeight={700}
      p={size === 'sm' ? '2px 4px 4px' : '5px 8px 4px'}
      sx={getStyleForVariant(variant)}
    >
      {text}
    </Typography>
  );
};
