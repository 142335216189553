import {
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
  Theme,
} from "@mui/material";

export const muiIconButtonTheme: {
  defaultProps?: ComponentsProps["MuiIconButton"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiIconButton"];
  variants?: ComponentsVariants["MuiIconButton"];
} = {
  styleOverrides: {
    // <IconButton color="primary" />
    colorPrimary: ({ theme }) => ({
      background: theme.palette.primary.main,
      border: "2px solid white",
      color: "white",
      ":hover": {
        background: "white",
        color: theme.palette.primary.main,
      },
    }),
    root: {
      borderRadius: "5px",
      margin: "0.5rem",
    },
  },
};
