import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITaskDrawerState {
  isOpen: boolean;
  taskIdToEdit: string | null;
  applicationReference: string | null;
}

const initialState: ITaskDrawerState = {
  isOpen: false,
  taskIdToEdit: null,
  applicationReference: null,
};

const taskDrawerSlice = createSlice({
  name: 'taskDrawer',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    createNewTask: (state, action: PayloadAction<string | null>) => {
      state.isOpen = true;
      state.applicationReference = action.payload;
      state.taskIdToEdit = null;
    },
    editTask: (state, action: PayloadAction<string | null>) => {
      state.taskIdToEdit = action.payload;
      state.isOpen = true;
    },
    closeDrawer: state => {
      state.isOpen = false;
      state.taskIdToEdit = null;
    },
  },
});

export const taskDrawerReducer = taskDrawerSlice.reducer;
export const { setIsOpen, createNewTask, editTask, closeDrawer } =
  taskDrawerSlice.actions;
