import { SxProps } from '@mui/material';
import { ErrorAlert } from 'common/components/alerts';
import { extractErrorMessages } from 'util/ApiUtils';

export const AlertForQueryErrorOrNull = ({
  isError,
  error,
  sx,
}: {
  isError: boolean;
  error: unknown;
  sx?: SxProps;
}) => {
  return isError ? (
    <ErrorAlert msg={extractErrorMessages(error)} sx={{ ...sx }} />
  ) : null;
};
