import { Chip } from '@mui/material';
import {
  EVariationStatus,
  EVariationStatusLanguage,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';

export const StatusChip = ({
  status,
}: {
  status: undefined | EVariationStatus;
}) => {
  if (!status) {
    return null;
  }
  return (
    <Chip
      size="small"
      variant="filled"
      color={
        status === EVariationStatus.ToDo || status === EVariationStatus.InProgress || status === EVariationStatus.Submitted
          ? 'info'
          : status === EVariationStatus.Approved
          ? 'success'
          : status === EVariationStatus.Rejected
          ? 'error'
          : 'default'
      }
      label={EVariationStatusLanguage[status] ?? 'Unknown Status'}
      sx={{ fontSize: '0.9em' }}
    />
  );
};
