import { ComponentProps } from 'react';
import { Box } from '@mui/material';

export const RoundBorderBox = ({
  children,
  sx,
  ...other
}: ComponentProps<typeof Box>) => {
  return (
    <Box borderRadius={1} border={1} borderColor="divider" sx={sx} {...other}>
      {children}
    </Box>
  );
};
