import { ReferralsSidebar } from 'common/components/referrals';
import {
  getReferralsArray,
  useReferralsList,
} from 'common/components/referrals/utils';
import { useClosingPaymentRequestContext } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/context';
import { mapPaymentRequestHeReferrals } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/utils';
import { EReferralRecordType } from 'types/applications/ReferralTypes';

export const ClosingPaymentRequestReferralsSidebar = () => {
  const {
    referrals: heReferrals,
    paymentRequestReferralsQuery,
    paymentRequestId,
  } = useClosingPaymentRequestContext();

  const mappedHeReferrals = mapPaymentRequestHeReferrals(heReferrals);

  // Referrals coming from context is only HE referrals
  // Also get 3rd party referrals to show in the list below
  const { query: thirdPartyReferralQuery } = useReferralsList({
    referralRecordType: EReferralRecordType.PaymentRequest,
    paymentRequestId: paymentRequestId,
    heReferrals: mappedHeReferrals,
  });

  const thirdPartyReferrals = thirdPartyReferralQuery.isSuccess
    ? getReferralsArray(thirdPartyReferralQuery.data)
    : [];

  const referrals = [...mappedHeReferrals, ...thirdPartyReferrals];

  return (
    <>
      <ReferralsSidebar
        query={{
          isLoading:
            paymentRequestReferralsQuery.isLoading ||
            thirdPartyReferralQuery.isLoading,
          isError:
            paymentRequestReferralsQuery.isError ||
            thirdPartyReferralQuery.isError,
          error:
            paymentRequestReferralsQuery.error || thirdPartyReferralQuery.error,
          isSuccess:
            paymentRequestReferralsQuery.isSuccess &&
            thirdPartyReferralQuery.isSuccess,
          data: referrals,
        }}
      />
    </>
  );
};
