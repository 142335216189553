import { Box, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetRegulatoryComplianceQuery } from 'api/application/worksPackageApi';
import { FormSkeleton } from 'common/components/skeletons';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { IRegulatoryCompliance } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';

export const RegulatoryComplianceCard = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetRegulatoryComplianceQuery(
    applicationId ?? skipToken
  );

  if (query.isError) {
    return (
      <Typography>Failed getting regulatory compliance details.</Typography>
    );
  }

  const { data, isLoading } = query;

  if (isLoading) {
    return <FormSkeleton />;
  }

  return (
    <Box
      mb={2}
      bgcolor={'grey.100'}
      sx={{
        width: '100%',
        padding: 2,
        borderRadius: 1,
        marginTop: 2,
      }}
    >
      <Typography
        variant="h3"
        marginBottom={2}
        fontWeight={900}
        fontSize={'1.05em'}
      >
        Regulatory Compliance
      </Typography>
      {data && <RegulatoryComplianceCardData data={data} />}
    </Box>
  );
};

export const RegulatoryComplianceCardData = ({
  data,
}: {
  data: IRegulatoryCompliance;
}) => {
  const stackedData: [string, string][] = [
    [
      'Who is responsible for regulatory compliance',
      `${data?.regulatoryCompliancePerson ? data?.regulatoryCompliancePerson : '-'}`,
    ],
  ];

  return (
    <>
      {stackedData.map(([title, value], i) => (
        <CardDetailsStacked key={i} title={title} value={value} />
      ))}
    </>
  );
};

const CardDetailsStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 1,
      }}
    >
      <CardDataTitle title={title} />

      <Typography fontSize={'0.8em'} fontWeight={600}>
        {value}
      </Typography>
    </Box>
  );
};

const CardDataTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="body1" fontWeight={'600'} fontSize={'0.8em'}>
      {title}
    </Typography>
  );
};
