import { SxProps, Typography } from '@mui/material';
import { useLazyApplicationDetailsFileDownloadQuery } from 'api/application';
import { FileDownload } from 'common/components/fileDownload';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const ApplicationFileDownloadWrapper = ({
  showIconOnly,
  fileName,
  fileId,
  fileWrapperStyle,
}: {
  showIconOnly?: boolean;
  fileName: string;
  fileId: string;
  fileWrapperStyle?: SxProps;
}) => {
  const { applicationId } = useApplicationContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const [trigger, result] = useLazyApplicationDetailsFileDownloadQuery();

  const handleClick = () => {
    if (!fileId) {
      createErrorSnackbar('Could not get file. fileId is empty');
      return;
    }

    trigger({ applicationId, fileId, fileName });
  };

  return (
    <FileDownload
      isError={result.isError}
      error={result.error}
      isLoading={result.isLoading || result.isFetching}
      displayValue={<AnswerString value={fileName} />}
      showIconOnly={showIconOnly}
      handleClick={handleClick}
      fileWrapperStyle={fileWrapperStyle}
    />
  );
};

const AnswerString = ({ value }: { value: string }) => {
  return <Typography fontSize="0.9em">{value}</Typography>;
};
