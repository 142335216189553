import { Button, SxProps } from '@mui/material';
import { PutOnHoldModal } from 'common/components/OnOffHold/PutOnHoldModal';
import { TakeOffHoldModal } from 'common/components/OnOffHold/TakeOffHoldModal';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { useModalState } from 'hooks';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';

interface IOnOffHoldProps {
  holdType: EOnHoldType;
  sx?: SxProps;
}

export const OnOffHold = ({ holdType, sx }: IOnOffHoldProps) => {
  const { isOnHoldForThisType } = useOnOffHold({
    holdType: holdType,
  });

  const {
    isShowing: isPutOnHoldDialogShowing,
    showModal: showPutOnHoldDialog,
    hideModal: hidePutOnHoldDialog,
  } = useModalState();

  const {
    isShowing: isTakeOffHoldDialogShowing,
    showModal: showTakeOffHoldDialog,
    hideModal: hideTakeOffHoldDialog,
  } = useModalState();

  return (
    <>
      {isOnHoldForThisType ? (
        <Button variant="outlined" onClick={showTakeOffHoldDialog}>Remove hold</Button>
      ) : (
        <Button variant="outlined" onClick={showPutOnHoldDialog}>Put on hold</Button>
      )}

      <PutOnHoldModal
        isShowing={isPutOnHoldDialogShowing}
        hideModal={hidePutOnHoldDialog}
        holdType={holdType}
      />

      <TakeOffHoldModal
        isShowing={isTakeOffHoldDialogShowing}
        hideModal={hideTakeOffHoldDialog}
      />
    </>
  );
};
