import { Grid, Typography } from '@mui/material';

interface ISummaryItemProps {
  label: string;
  value: string;
}

export const SummaryItem = ({ label, value }: ISummaryItemProps) => {
  return (
    <Grid item>
      <strong style={{ fontWeight: '900' }}>{label}</strong>
      <Typography variant="body1">{value}</Typography>
    </Grid>
  );
};
