import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useAddApplicationDocumentMutation,
  useGetApplicationDocumentCategoriesQuery,
  useGetApplicationDocumentTypesQuery,
} from 'api/application/documentsApi';
import { useUploadFileMutation } from 'api/file';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { IFileUpload } from 'common/types/File';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { IAddApplicationDocument } from 'types/applications/ApplicationDocumentTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IAddApplicationDocument) =>
  nameof<IAddApplicationDocument>(fieldName);

interface IUploadDocumentDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function UploadDocumentDialog({
  onConfirm,
  onCancel,
}: IUploadDocumentDialogProps) {
  const { applicationId } = useApplicationContext();
  const { data: categoriesData } = useGetApplicationDocumentCategoriesQuery();
  const { data: documentTypesData } = useGetApplicationDocumentTypesQuery();
  const [uploadFile, uploadStatus] = useUploadFileMutation();
  const [addDocument] = useAddApplicationDocumentMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IAddApplicationDocument>();
  const { handleSubmit, control, watch } = form;

  const onSubmit = async (formData: IAddApplicationDocument) => {
    try {
      formData.applicationId = applicationId;
      await addDocument(formData as IAddApplicationDocument)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Successfully document uploaded`);
          onConfirm();
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to upload document`);
    }
  };

  const handleOnFileUpload = async (file: File) => {
    const fileFormData = new FormData();
    fileFormData.append('file', file);

    try {
      let fileUpload: IFileUpload = {
        file: file,
        uploadSection: 'Application',
      };

      await uploadFile(fileUpload)
        .unwrap()
        .then(payload => {
          form.setValue(`fileId`, payload.id);
          createSuccessSnackbar(`File uploaded successfully`);
        })
        .catch(error => {
          createErrorSnackbar(error.data.generalError.errorMessage);
        });
    } catch (err) {
      createErrorSnackbar(`Failed to upload file`);
    }
  };

  const handleOnFileDelete = async () => {
    form.setValue(`fileId`, '');
  };

  const watchFileId = watch('fileId');
  const watchCategoryId = watch('categoryId');
  const isUploading = uploadStatus.isLoading;

  return (
    <StyledDialog open fullWidth maxWidth="md">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledDialogTitle>{`Upload a document`}</StyledDialogTitle>
          <StyledDialogContent>
            <Grid container justifyContent="center">
              <Controller
                control={control}
                rules={{ required: 'Document is required' }}
                name="fileId"
                render={fieldProps => {
                  return (
                    <>
                      <Grid item mb={2} display={'flex'}>
                        <FileUploadInputSelect
                          isUploading={isUploading}
                          id="file"
                          accept=".pdf, .docx, .xlsx, .msg, .png, .jpg"
                          label="Click to upload or drag and drop (max 30MB)"
                          inputName="fileId"
                          onFileUpload={file => handleOnFileUpload(file)}
                          onFileDelete={handleOnFileDelete}
                          uploaded={uploadStatus.isSuccess}
                        />
                      </Grid>
                      {fieldProps.fieldState.error ? (
                        <Box mt={-2} mb={3}>
                          <FormHelperText error>
                            {fieldProps.fieldState.error.message}
                          </FormHelperText>
                        </Box>
                      ) : null}
                    </>
                  );
                }}
              />

              <Grid item xs={12}>
                <Controller
                  rules={{ required: 'Category is required' }}
                  control={control}
                  name={getName('categoryId')}
                  render={({ field, fieldState }) => (
                    <FormControl
                      sx={{ minWidth: '100%', marginBottom: '1rem' }}
                    >
                      <InputLabel id="category-label">Category</InputLabel>
                      <Select
                        {...field}
                        value={field.value ?? ''}
                        fullWidth
                        error={!!fieldState.error}
                        labelId="category-label"
                        label="Category"
                        input={<OutlinedInput label="Category" />}
                      >
                        {categoriesData?.map(category => {
                          return (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {fieldState.error?.message ? (
                        <FormHelperText error>
                          {fieldState.error?.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  rules={{ required: 'Document Type is required' }}
                  control={control}
                  name={getName('typeId')}
                  render={({ field, fieldState }) => (
                    <FormControl
                      sx={{ minWidth: '100%', marginBottom: '1rem' }}
                    >
                      <InputLabel id="type-label">Document Type</InputLabel>
                      <Select
                        {...field}
                        value={field.value ?? ''}
                        fullWidth
                        error={!!fieldState.error}
                        labelId="type-label"
                        label="Document Type"
                        input={<OutlinedInput label="Document Type" />}
                      >
                        {documentTypesData
                          ?.filter(v => v.categoryId === parseInt(watchCategoryId))
                          .map(documentType => {
                            return (
                              <MenuItem
                                key={documentType.id}
                                value={documentType.id}
                              >
                                {documentType.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {fieldState.error?.message ? (
                        <FormHelperText error>
                          {fieldState.error?.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </StyledDialogContent>
          <StyledDialogActions>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isUploading || !watchFileId}
            >
              Upload document
            </Button>
          </StyledDialogActions>
        </form>
      </FormProvider>
    </StyledDialog>
  );
}
