import { Grid, Box, Button, Typography } from '@mui/material';
import { WorksPackageSectionOutlet } from '.';
import { useGetWorksPackageOverviewQuery } from 'api/application/worksPackageApi';
import { ReferralsSidebar } from 'common/components/referrals';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { ApplicationItemGroupNavigator } from 'pages/applicationPage/common/components';
import { EWorksPackageSection } from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import { useApplicationProjectPrepContext } from 'pages/applicationPage/content/projectPrep/context';
import { StatusChip } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/components';
import {
  WorksPackageContextProvider,
  useWorksPackageContext,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import { CostsScheduleFooter } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CostsScheduleSection';
import { useWorksPackageReferrals } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/worksPackageHooks';
import {
  formatDateDayShortMonthYear,
  getDifferenceInDays,
} from 'util/DateUtils';

const getDateString = (date: string) => {
  const daysAgo = Math.abs(getDifferenceInDays(new Date(date)));

  return `${formatDateDayShortMonthYear(new Date(date))} (${daysAgo} ${
    daysAgo === 1 ? 'day' : 'days'
  } ago)`;
};

const useCategories = () => {
  return [
    {
      categoryTitle: null,
      detailItems: [
        {
          id: EWorksPackageSection.Overview,
          sectionName: 'Overview',
        },
        {
          id: EWorksPackageSection.Cladding,
          sectionName: 'Cladding',
        },
        {
          id: EWorksPackageSection.CostScheduling,
          sectionName: 'Cost Schedule',
        },
        {
          id: EWorksPackageSection.ProjectTeam,
          sectionName: 'Project Team',
        },
        {
          id: EWorksPackageSection.SubContractors,
          sectionName: 'Sub-contractors',
        },
      ],
    },
    {
      categoryTitle: 'Confirm works package',
      detailItems: [
        {
          id: EWorksPackageSection.Confirm,
          sectionName: 'Works package checks',
        },
      ],
    },
  ];
};

export const WorksPackageSection = () => {
  return (
    <WorksPackageContextProvider>
      <WorksPackageSectionMain />
    </WorksPackageContextProvider>
  );
};

const WorksPackageSectionMain = () => {
  const { setSelectedItem } = useApplicationProjectPrepContext();
  const {
    selectedItemId: selectedWorksPackageItemId,
    setSelectedItemId: setSelectedWorksPackageItemId,
  } = useWorksPackageContext();
  const categories = useCategories();
  const { applicationId } = useWorksPackageContext();
  const { isSuccess, data } = useGetWorksPackageOverviewQuery(applicationId);
  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={3.5}>
          <RoundBorderBox sx={{ p: 2, mb: 2 }}>
            <Button
              variant="text"
              onClick={() => setSelectedItem(null)}
              sx={{ pl: 0, mb: 0.5 }}
            >
              {'< Back to Project Set-up'}
            </Button>
            <CardTitle title={'Works Package'} fontSize="1.2em" />
            {isSuccess && data?.status ? (
              <StatusChip status={data.status} />
            ) : null}
            <Typography variant="body1" color={'grey.700'} sx={{ pt: 1 }}>
              Date submitted
            </Typography>
            <Typography variant="body1">
              {data?.dateSubmitted ? getDateString(data.dateSubmitted) : '-'}
            </Typography>
          </RoundBorderBox>

          <RoundBorderBox sx={{ mb: 2 }}>
            <Box p={2}>
              <ApplicationItemGroupNavigator
                itemGroups={categories.map(category => {
                  return {
                    title: category.categoryTitle,
                    isEnabled: true,
                    items: category.detailItems.map(detailItem => {
                      return {
                        title: detailItem.sectionName,
                        isSelected:
                          selectedWorksPackageItemId === detailItem.id,
                        onClick: () => {
                          setSelectedWorksPackageItemId(detailItem.id);
                        },
                      };
                    }),
                  };
                })}
              />
            </Box>
          </RoundBorderBox>

          <WorksPackageReferrals />
        </Grid>
        <Grid item xs={8.5}>
          <RoundBorderBox sx={{ border: 0 }}>
            <WorksPackageSectionOutlet />
          </RoundBorderBox>
        </Grid>
      </Grid>

      {selectedWorksPackageItemId === EWorksPackageSection.CostScheduling ? (
        <CostsScheduleFooter />
      ) : null}
    </>
  );
};

const WorksPackageReferrals = () => {
  const { query } = useWorksPackageReferrals();
  return <ReferralsSidebar query={query} />;
};
