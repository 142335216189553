import {
  TextField,
  MenuItem,
  Typography,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  FormHelperText,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useAddNoteMutation, useEditNoteMutation } from 'api/task';
import { AssigneeField, DueDateField } from 'common/components/task/common';
import { useTaskCompletionOptions } from 'common/components/task/utils';
import { useTasksGridContext } from 'common/components/taskGrid';
import { useLocalSnackbar } from 'hooks';
import {
  IAddEditTask,
  ECmsTaskStatus,
  ECmsTaskStatusLanguage,
  TaskNote,
} from 'types/tasks/CmsTaskTypes';
import { extractErrorMessages } from 'util/ApiUtils';

interface IAddEditNoteFormProps {
  formId: string;
  note: TaskNote;
  mutation:
    | { type: 'Add'; fn: ReturnType<typeof useAddNoteMutation>[0] }
    | { type: 'Edit'; fn: ReturnType<typeof useEditNoteMutation>[0] };
}

export const AddEditTaskNoteForm = ({
  formId,
  note,
  mutation,
}: IAddEditNoteFormProps) => {
  const form = useForm<IAddEditTask>({
    defaultValues: note,
  });

  const {
    noteModalState: { parentTaskId, noteIdToEdit, closeNoteDrawer },
  } = useTasksGridContext();

  const { control, handleSubmit, reset, watch } = form;

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onSubmit = async (formData: TaskNote) => {
    const { type, fn } = mutation;

    try {
      if (!parentTaskId) {
        createErrorSnackbar('Could not get parent task id. Not saving.');
        return;
      }
      if (type === 'Edit' && !noteIdToEdit) {
        createErrorSnackbar('Could not get note id. Not saving.');
        return;
      }
      const functionCall =
        type === 'Add'
          ? fn({ taskId: parentTaskId, note: formData })
          : type === 'Edit' && noteIdToEdit
          ? fn({ taskId: parentTaskId, noteId: noteIdToEdit, note: formData })
          : undefined;

      if (functionCall === undefined) {
        createErrorSnackbar('Error api function call could not be made');
        return;
      }

      await functionCall
        .unwrap()
        .then(_ => {
          closeNoteDrawer();
          reset();
          createSuccessSnackbar(!noteIdToEdit ? `Note added` : 'Note updated');
        })
        .catch(error => {
          createErrorSnackbar(extractErrorMessages(error));
        });
    } catch (err) {
      createErrorSnackbar(
        !noteIdToEdit ? `Failed to add note` : `Failed to update note`
      );
    }
  };

  const { taskCompletionOptions } = useTaskCompletionOptions();

  const [status] = watch(['status']);
  const isCompleted = status === ECmsTaskStatus.Done;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%' }}
        id={formId}
      >
        <Controller
          control={control}
          name="description"
          rules={{
            required: 'Description is required',
            maxLength: {
              value: 1000,
              message: '1000 characters is the maximum allowed.',
            },
          }}
          render={({ field, formState: { errors } }) => (
            <TextField
              {...field}
              multiline
              disabled={mutation.type === 'Edit'}
              fullWidth
              rows={5}
              label="Description"
              error={!!errors.description}
              helperText={errors.description?.message}
            />
          )}
        />

        <Typography variant="h2" mb={2}>
          Assigned
        </Typography>

        <AssigneeField />
        <DueDateField />

        <Typography variant="h2" mb={2}>
          Status
        </Typography>
        <Controller
          control={control}
          name="status"
          rules={{ required: 'Status is required' }}
          render={({ fieldState, field: { onChange, ...fieldProps } }) => {
            return (
              <FormControl>
                <ToggleButtonGroup
                  {...fieldProps}
                  value={fieldProps.value.toString()}
                  onChange={(_, val) => {
                    if (val !== null) {
                      if (typeof val === 'string') {
                        onChange(parseInt(val));
                      }
                    }
                  }}
                  exclusive
                  sx={{ mb: 3 }}
                >
                  {Object.entries(ECmsTaskStatusLanguage).map(
                    ([key, value]) => (
                      <ToggleButton key={key} value={key}>
                        {value}
                      </ToggleButton>
                    )
                  )}
                </ToggleButtonGroup>
                {fieldState.error?.message ? (
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
            );
          }}
        />

        {isCompleted ? (
          <>
            <Typography variant="h2" mb={2}>
              Note Completed Details
            </Typography>
            {taskCompletionOptions.length > 0 ? (
              <Controller
                control={control}
                name="outcomeId"
                rules={{ required: 'Outcome Reason is required' }}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Outcome Reason"
                    error={!!errors.outcomeId}
                    helperText={errors.outcomeId?.message}
                  >
                    {taskCompletionOptions.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            ) : null}
            <Controller
              control={control}
              name="notes"
              rules={{ required: 'Outcome Notes are required' }}
              render={({ field, formState: { errors } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={5}
                  label="Notes"
                  error={!!errors.notes}
                  helperText={errors.notes?.message}
                />
              )}
            />
          </>
        ) : null}
      </form>
    </FormProvider>
  );
};
