import { Box, Button, Dialog, Typography } from '@mui/material';
import { useLogout } from 'common/components/autoLogout/autoLogoutUtils';

interface TimeoutWarningModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function TimeoutWarningModal({
  isOpen,
  onRequestClose,
}: TimeoutWarningModalProps) {
  const { logout } = useLogout();

  return (
    <Dialog open={isOpen}>
      <Box p={4}>
        <Typography variant="h1" mb={2}>
          Session Timeout
        </Typography>
        <Typography variant="body1" mb={4}>
          You're being timed out due to inactivity. Please choose to stay logged
          in or to log out. Otherwise, you will be logged out automatically.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button variant="outlined" onClick={logout}>
            Log out
          </Button>
          <Button
            variant="contained"
            onClick={onRequestClose}
            sx={{
              marginLeft: '16px',
            }}
          >
            Stay Logged In
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
