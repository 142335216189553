import { Box, useTheme } from '@mui/material';
import { useGetApplicationsKpisQuery } from 'api/application';
import { StatWidgets } from 'common/components/statWidgets';
import {
  EApplicationStage,
  EApplicationStageLanguage,
} from 'enums/EApplicationStage';
import {
  EApplicationStatus,
  EApplicationStatusLanguage,
} from 'enums/EApplicationStatus';
import { ApplicationsDataGrid } from 'pages/applicationsPage/components';
import { IApplicationKpis } from 'types/applications/ApplicationTypes';

const _kpiTopSpacing = 6 as const;

export const ApplicationsPageContent = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
      px={3}
      mt={_kpiTopSpacing}
    >
      <Stats />
      <ApplicationsDataGrid />
    </Box>
  );
};

const Stats = () => {
  const { data, isLoading } = useGetApplicationsKpisQuery();
  return (
    <StatWidgets
      isLoading={isLoading}
      topSpacing={_kpiTopSpacing}
      stats={getStatsFromData(data)}
    />
  );
};

const getStatsFromData = (data: IApplicationKpis | undefined) => {
  return data
    ? [
        {
          statValue: data.totalApplicationCount,
          title: 'Live Applications',
        },
        {
          statValue: data.applyForGrantCount,
          title: EApplicationStageLanguage[EApplicationStage.ApplyForGrant],
        },
        {
          statValue: data.signGrantFundingAgreementCount,
          title:
            EApplicationStageLanguage[
              EApplicationStage.GrantFundingAgreement
            ],
        },
        {
          statValue: data.addWorksPlanCount,
          title: EApplicationStageLanguage[EApplicationStage.WorksPackage],
        },
        {
          statValue: data.worksStartedCount,
          title: EApplicationStageLanguage[EApplicationStage.WorksDelivery],
        },
        {
          statValue: data.worksCompletedCount,
          title: EApplicationStageLanguage[EApplicationStage.WorksCompleted],
        },
        {
          statValue: data.notEligibleCount,
          title: EApplicationStatusLanguage[EApplicationStatus.ApplicationNotEligible],
        },
      ]
    : [];
};
