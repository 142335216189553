import { rest } from 'msw';
import {
  systemNotificationData,
  systemNotificationsGrid,
} from 'mocks/data/administration/administrationSystemNotificationsData';

export const administrationSystemNotificationsServiceHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/notification/grid`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(systemNotificationsGrid));
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/notification/:systemNotificationsId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(systemNotificationData));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/admin/notification`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/admin/notification/:systemNotificationsId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.delete(
    `${process.env.REACT_APP_API_URL}/api/admin/notification/:systemNotificationsId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
