import { ReactNode } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import {
  getNumberOfActiveReferrals,
  getReferralsArray,
  getReferredToString,
} from 'common/components/referrals/utils';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import {
  EReferralStatusLanguage,
  IReferralListItem,
} from 'types/applications/ReferralTypes';

type ReferralsSidebarQueryProps = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: unknown;
  data?: IReferralListItem[] | { results: IReferralListItem[] };
};

export const ReferralsSidebar = ({
  query,
}: {
  query: ReferralsSidebarQueryProps;
}) => {
  const { isSuccess, isLoading, isError, error, data: response } = query;
  const data = getReferralsArray(response);

  const Wrapper = ({ children }: { children: ReactNode }) => {
    return (
      <RoundBorderBox p={2}>
        <Stack direction={'row'} justifyContent={'space-between'} mb={3}>
          <CardTitle title="Referrals" />
          {isSuccess && data ? (
            <Chip
              label={`${getNumberOfActiveReferrals(data)} Active`}
              size="small"
              sx={{ fontSize: '0.8em' }}
            />
          ) : null}
        </Stack>
        {children}
      </RoundBorderBox>
    );
  };

  if (isLoading) {
    return (
      <Wrapper>
        <FormSkeleton />
      </Wrapper>
    );
  }

  if (isError) {
    return (
      <Wrapper>
        <AlertForQueryErrorOrNull isError={isError} error={error} />
      </Wrapper>
    );
  }

  if (isSuccess && data && data.length) {
    return (
      <Wrapper>
        <Stack gap={2}>
          {data.map(item => {
            return (
              <RoundBorderBox key={item.id} p={2}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography
                    fontSize={'0.9em'}
                    fontWeight={600}
                  >{`Referred to ${getReferredToString(item)}`}</Typography>
                  <Chip
                    label={EReferralStatusLanguage[item.status]}
                    size="small"
                    sx={{ fontSize: '0.8em', fontWeight: 600 }}
                  />
                </Stack>
              </RoundBorderBox>
            );
          })}
        </Stack>
      </Wrapper>
    );
  }

  return null;
};
