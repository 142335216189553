import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useGetVendorPaymentDetailsQuery } from 'api/application/paymentApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FormSkeleton } from 'common/components/skeletons';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { IVendorAccountApproval } from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { Intro } from 'pages/applicationPage/content/payments/common';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const VendorAccountApprovalHistorySection = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isError, error, isSuccess, data } =
    useGetVendorPaymentDetailsQuery(applicationId);
  return (
    <Box>
      <Intro heading="Approval Comment History" />
      {isLoading ? <FormSkeleton /> : null}
      {isSuccess ? <DataGrid data={data?.approvals ?? []} /> : null}

      <AlertForQueryErrorOrNull isError={isError} error={error} sx={{ m: 0 }} />
    </Box>
  );
};

const DataGrid = ({ data }: { data: IVendorAccountApproval[] }) => {
  return (
    <StyledGenericTable>
      <TableHead>
        <TableRow>
          <TableCell>Reason</TableCell>
          <TableCell>Evidence File</TableCell>
          <TableCell>Created</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(item => (
          <TableRow key={item.date}>
            <TableCell>{item.reason}</TableCell>
            <TableCell>
              {item.approvalEvidenceFile ? (
                <ApplicationFileDownloadWrapper
                  fileId={item.approvalEvidenceFile.id}
                  fileName={item.approvalEvidenceFile.name}
                  fileWrapperStyle={{ m: 0, gap: 1, p: 1 }}
                />
              ) : null}
            </TableCell>
            <TableCell>
              {getUkDateStringFromJsonDateString(item.date)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledGenericTable>
  );
};
