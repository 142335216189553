import { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Grid, Tabs } from '@mui/material';
import { CustomTab } from 'common/components/customTab';
import { CustomTabPanel } from 'common/components/customTabPanel';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { PullInProcessPageBuildingDetails } from 'pages/pullInProcessPage/components/buildingDetails/pullInProcessPageBuildingDetails';
import { BuildingDetailsPanel } from 'pages/pullInProcessPage/components/buildingDetailsPanel';
import { PullInProcessCommunications } from 'pages/pullInProcessPage/components/communications/pullInProcessCommunications';
import { DefaultContactDetailsPanel } from 'pages/pullInProcessPage/components/defaultContactDetailsPanel';
import { PullInProcessDocuments } from 'pages/pullInProcessPage/components/documents/pullInProcessDocuments';
import { PullInProcessPageOverview } from 'pages/pullInProcessPage/components/overview/pullInProcessPageOverview';

const StyledCustomTab = styled(CustomTab)({
  backgroundColor: '#f5f5f5',
  borderRadius: '3px',
  border: '2px solid rgba(0,0,0,.12)',
  transition: 'all 150ms linear',
  padding: '12px',
  maxWidth: '100%',
  fontSize: '0.9rem',
  alignItems: 'flex-start',

  '&:hover': {
    backgroundColor: 'white',
  },

  '&:focus-visible': {
    border: '2px solid #212121',
  },
  '&.Mui-selected': {
    border: '2px solid #212121',
  },
});

export const StyledRoundBorderBox = styled(RoundBorderBox)({
  '& > div:not(:last-child)': {
    borderBottom: '1px solid rgba(0,0,0,0.12)',
  },
  '& > div': {
    padding: '1rem',
  },
  borderRadius: '8px',
});

const pullInProcessTabs = {
  overview: {
    key: 'overview',
    title: 'Overview',
    content: <PullInProcessPageOverview />,
  },
  buildingDetails: {
    key: 'buildingDetails',
    title: 'Building Details',
    content: <PullInProcessPageBuildingDetails />,
  },
  communications: {
    key: 'communications',
    title: 'Communications',
    content: <PullInProcessCommunications />,
  },
  documents: {
    key: 'documents',
    title: 'Documents',
    content: <PullInProcessDocuments />,
  },
} as const;

export function PullInProcessPageLayout() {
  const [selectedTab, setSelectedTab] =
    useState<keyof typeof pullInProcessTabs>('overview');
  return (
    <Grid container spacing="1.5rem" mt="1rem">
      <Grid item xs={4} display="flex" flexDirection="column" gap="20px">
        <RoundBorderBox borderRadius="8px">
          <Tabs
            orientation="vertical"
            onChange={(_, value) => setSelectedTab(value)}
            sx={{
              padding: '1rem',
              width: '100%',
              '& .MuiTabs-flexContainer': { gap: '1rem' },
            }}
            value={selectedTab}
          >
            {Object.entries(pullInProcessTabs).map(([_, { key, title }]) => (
              <StyledCustomTab
                key={key}
                currentSelectedTab={selectedTab}
                value={key}
                label={title}
              />
            ))}
          </Tabs>
        </RoundBorderBox>

        <BuildingDetailsPanel />

        <DefaultContactDetailsPanel />
      </Grid>
      <Grid item xs={8}>
        <Box width="100%">
          <CustomTabPanel value={selectedTab} currentSelectedTab={selectedTab}>
            {pullInProcessTabs[selectedTab].content}
          </CustomTabPanel>
        </Box>
      </Grid>
    </Grid>
  );
}
