import { useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useApproveConfirmEligibilityChecksMutation,
  useGetConfirmEligibilityChecksQuery,
  useSaveConfirmEligibilityChecksMutation,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { AlertForChangedData } from 'common/components/alerts';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilityRiskStatus } from 'enums/EEligibilityRiskStatus';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import {
  EEligiblityCheckStatus,
  EEligiblityCheckStatusLanguage,
} from 'enums/EEligiblityCheckStatus';
import { EReferralStatus } from 'enums/EReferralStatus';
import { EReferralType } from 'enums/EReferralType';
import { useLocalSnackbar, useModalState } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EApplicationEligibilitySectionType } from 'pages/applicationPage/content/applicationEligibility/ApplicationEligibilityTypes';
import { ConfirmEligibilityInfoPanel } from 'pages/applicationPage/content/applicationEligibility/components/ConfirmEligibility/ConfirmEligibilityInfoPanel';
import { ReferToGLADialog } from 'pages/applicationPage/content/applicationEligibility/components/ConfirmEligibility/ReferToGLADialog';
import { RejectApplicationDialog } from 'pages/applicationPage/content/applicationEligibility/components/ConfirmEligibility/RejectApplicationDialog';
import { ReferApplicationButton } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context/ApplicationEligibilityContext';
import { useEligibilitySectionOnOpen } from 'pages/applicationPage/content/applicationEligibility/utils';
import {
  IApplicationConfirmEligibilityChecks,
  IApplicationEligibilityReferral,
  IApplicationSaveEligibilityChecks,
} from 'types/applications/ApplicationEligibilityTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationConfirmEligibilityChecks) =>
  nameof<IApplicationConfirmEligibilityChecks>(fieldName);

export const ConfirmEligibilityForm = () => {
  const { applicationId } = useApplicationEligibilityContext();
  const query = useGetConfirmEligibilityChecksQuery(applicationId ?? skipToken);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData = {
      ...data,
      referrals: data.referrals?.map(r => ({ ...r })) ?? [],
    };

    return <ConfirmEligibilityFormMain initialData={initialData} />;
  }

  return null;
};

const _formId = 'confirmEligibilityForm';

const ConfirmEligibilityFormMain = ({
  initialData,
}: {
  initialData: IApplicationConfirmEligibilityChecks;
}) => {
  const { hasApplicationEligibilityConfirm } = useApplicationContext();
  const {
    applicationId,
    onReferralQuestion,
    referrals,
    handleAutoShowReferApplicationDialog,
  } = useApplicationEligibilityContext();

  const [approveConfirmEligibilityChecks] =
    useApproveConfirmEligibilityChecksMutation();
  const [saveConfirmEligibilityChecks] =
    useSaveConfirmEligibilityChecksMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const {
    isShowing: isRejectApplicationDialogShowing,
    showModal: showRejectApplicationDialog,
    hideModal: hideRejectApplicationDialog,
  } = useModalState();

  const {
    isShowing: isReferToGLADialogShowing,
    showModal: showReferToGLADialog,
    hideModal: hideReferToGLADialog,
  } = useModalState();

  // If this section has just been opened, check if we need to auto show the referral dialog
  useEligibilitySectionOnOpen(
    EApplicationEligibilitySectionType.ApproveOrReject,
    () => {
      handleAutoShowReferApplicationDialog();
    }
  );

  const form = useForm<IApplicationConfirmEligibilityChecks>({
    defaultValues: initialData,
  });

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting },
    setError,
  } = form;

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Eligibility,
  });

  const watchRiskStatus = watch('riskStatus');

  useEffect(() => {
    referrals
      .filter(item => item.section === EEligibilitySection.ConfirmEligibility)
      .forEach((referral: IApplicationEligibilityReferral) => {
        form.setValue(`referrals.${referral.question}.reason`, referral.reason);
      });
  }, [form, referrals]);

  const getConfirmEligibilityReferrals = () => {
    return referrals.filter(
      item => item.section === EEligibilitySection.ConfirmEligibility
    );
  };

  const pendingReferralsCount = referrals.filter(
    item =>
      item.status === EReferralStatus.Pending ||
      item.status === EReferralStatus.Referred
  ).length;

  const onSave = async () => {
    try {
      const formData = getValues();

      let applicationApproveEligibilityChecks: IApplicationSaveEligibilityChecks =
        {
          applicationId: applicationId,
          hasAllChecksComplete: formData.hasAllChecksComplete,
          riskStatus: formData.riskStatus,
          riskReason: formData.riskReason,
          referApplicationToGLA: formData.referApplicationToGLA,
          referrals: getConfirmEligibilityReferrals(),
        };

      await saveConfirmEligibilityChecks(applicationApproveEligibilityChecks)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Applicant Eligibility Saved`);
          reset({}, { keepValues: true });
          handleAutoShowReferApplicationDialog();
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed To Save Applicant Eligibility`);
    }
  };

  const onSubmit = async (formData: IApplicationConfirmEligibilityChecks) => {
    if (formData.referApplicationToGLA) {
      onSave().then(() => {
        if (formData.hasAllChecksComplete) {
          showReferToGLADialog();
        } else {
          setError('hasAllChecksComplete', {
            message: 'All checks must be complete to approve.',
          });
        }
      });
    } else {
      try {
        let applicationApproveEligibilityChecks: IApplicationSaveEligibilityChecks =
          {
            applicationId: applicationId,
            hasAllChecksComplete: formData.hasAllChecksComplete,
            riskStatus: formData.riskStatus,
            riskReason: formData.riskReason,
            referApplicationToGLA: false,
            referrals: null,
          };

        await approveConfirmEligibilityChecks(
          applicationApproveEligibilityChecks
        )
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`Applicant Eligibility Approved`);
            reset({}, { keepValues: true });
          })
          .catch(error => {
            if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed To Approve Applicant Eligibility`);
      }
    }
  };

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.ConfirmEligibility,
      question,
      referralType,
      refer
    );
  };

  const isApprovedOrRejected =
    initialData?.status === EEligiblityCheckStatus.Approved ||
    initialData?.status === EEligiblityCheckStatus.Rejected;

  const status =
    initialData?.status === EEligiblityCheckStatus.Approved
      ? EEligiblityCheckStatus.Approved
      : EEligiblityCheckStatus.Rejected;

  return (
    <>
      {isOnHoldForThisType && (
        <OnHoldBanner holdType={EOnHoldType.Eligibility} />
      )}

      {isApprovedOrRejected && (
        <Box padding={2}>
          <Alert
            severity={
              status === EEligiblityCheckStatus.Approved ? 'success' : 'error'
            }
          >
            Application {EEligiblityCheckStatusLanguage[status]}
          </Alert>
        </Box>
      )}

      <Grid container spacing={1}>
        <Grid xs={9} item>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Box p={3}>
                <FormProvider {...form}>
                  <LeavePageGuard
                    blockNavigationIf={isDirty && !isSubmitting}
                    message="Are you sure you want to leave? Any unsaved changes to the 'Accept or Reject'' will be lost."
                  />

                  <form onSubmit={handleSubmit(onSubmit)} id={_formId}>
                    <AlertForChangedData isDirty={isDirty} />

                    <Grid container direction="column" spacing={1}>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                          width="35rem"
                        >
                          Risk
                        </Typography>
                        <Controller
                          defaultValue={''}
                          name={getName('riskStatus')}
                          render={({
                            fieldState,
                            field: { onChange, ...fieldProps },
                          }) => (
                            <FormControl>
                              <ToggleButtonGroup
                                {...fieldProps}
                                onChange={(_, val) => {
                                  if (val !== null) onChange(val);
                                  handleReferralQuestion(
                                    EEligibilityQuestion.Risk_Status,
                                    EReferralType.HomesEngland,
                                    val === EEligibilityRiskStatus.Escalate
                                  );
                                }}
                                exclusive
                                disabled={
                                  isApprovedOrRejected || isOnHoldForThisType
                                }
                              >
                                <ToggleButton
                                  key={EEligibilityRiskStatus.Accept}
                                  value={EEligibilityRiskStatus.Accept}
                                >
                                  Approve
                                </ToggleButton>
                                <ToggleButton
                                  key={EEligibilityRiskStatus.Escalate}
                                  value={EEligibilityRiskStatus.Escalate}
                                >
                                  Escalate
                                </ToggleButton>
                                <ToggleButton
                                  key={EEligibilityRiskStatus.PreApproved}
                                  value={EEligibilityRiskStatus.PreApproved}
                                >
                                  Pre-Approved
                                </ToggleButton>
                              </ToggleButtonGroup>
                              {fieldState.error?.message ? (
                                <FormHelperText error>
                                  {fieldState.error?.message}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                          width="35rem"
                        >
                          Risk Justification Reason
                        </Typography>
                        <Controller
                          name={getName('riskReason')}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              rows={5}
                              label="Risk Justification Reason"
                              disabled={
                                isApprovedOrRejected || isOnHoldForThisType
                              }
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Grid>
                      {watchRiskStatus === EEligibilityRiskStatus.Escalate && (
                        <ReferApplicationNotificationBanner
                          question={EEligibilityQuestion.Risk_Status}
                          referralType={EReferralType.HomesEngland}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          I confirm that all checks have been completed and the
                          applicant is eligible for funding.
                        </Typography>
                        <Controller
                          defaultValue={false}
                          name={getName('hasAllChecksComplete')}
                          render={({
                            fieldState,
                            field: { onChange, value, ...fieldProps },
                          }) => (
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...fieldProps}
                                    checked={value}
                                    onChange={(_, val) => {
                                      if (val !== null) onChange(val);
                                    }}
                                    disabled={
                                      !hasApplicationEligibilityConfirm ||
                                      isApprovedOrRejected ||
                                      isOnHoldForThisType
                                    }
                                  />
                                }
                                label="I confirm that all checks have been completed."
                              />
                              {fieldState.error?.message ? (
                                <FormHelperText error>
                                  {fieldState.error?.message}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Refer this application to GLA?
                        </Typography>
                        <Controller
                          defaultValue={false}
                          name={getName('referApplicationToGLA')}
                          render={({
                            fieldState,
                            field: { onChange, value, ...fieldProps },
                          }) => (
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...fieldProps}
                                    checked={value}
                                    onChange={(_, val) => {
                                      if (val !== null) onChange(val);
                                    }}
                                    disabled={
                                      !hasApplicationEligibilityConfirm ||
                                      isApprovedOrRejected ||
                                      isOnHoldForThisType
                                    }
                                  />
                                }
                                label="I confirm that this application should be referred to GLA for the Building Safety Fund"
                              />
                              {fieldState.error?.message ? (
                                <FormHelperText error>
                                  {fieldState.error?.message}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={3} item>
          <Box p={3}>
            <ConfirmEligibilityInfoPanel />
          </Box>
        </Grid>
        {hasApplicationEligibilityConfirm && !isApprovedOrRejected && (
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Box
                display="flex"
                justifyContent="space-between"
                p={3}
                bgcolor="grey.100"
              >
                <ReferApplicationButton
                  sectionType={EEligibilitySection.ConfirmEligibility}
                  disabled={isOnHoldForThisType}
                />
                <OnOffHold holdType={EOnHoldType.Eligibility} />
                <Box gap={2} display="flex">
                  <Button
                    variant="outlined"
                    disabled={isOnHoldForThisType}
                    onClick={() => {
                      showRejectApplicationDialog();
                    }}
                  >
                    Reject
                  </Button>

                  <Button
                    variant="contained"
                    form={_formId}
                    onClick={onSave}
                    disabled={isOnHoldForThisType}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    form={_formId}
                    disabled={
                      pendingReferralsCount > 0 ||
                      !watchRiskStatus ||
                      watchRiskStatus === EEligibilityRiskStatus.Escalate ||
                      isOnHoldForThisType
                    }
                  >
                    Approve Eligibility
                  </Button>
                  {pendingReferralsCount > 0 && (
                    <Chip
                      label={`${pendingReferralsCount} Referrals Pending`}
                      color={'warning'}
                      variant={'outlined'}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>

      {isRejectApplicationDialogShowing && (
        <RejectApplicationDialog
          onConfirm={() => {
            hideRejectApplicationDialog();
          }}
          onCancel={() => {
            hideRejectApplicationDialog();
          }}
        />
      )}
      {isReferToGLADialogShowing && (
        <ReferToGLADialog
          onConfirm={() => {
            hideReferToGLADialog();
          }}
          onCancel={() => {
            hideReferToGLADialog();
          }}
        />
      )}
    </>
  );
};
