import { ComponentProps } from 'react';
import isValidProp from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import {
  faTimes,
  faPhone,
  faPen,
  faEnvelope,
  faFile,
  faArrowDown,
  faArrowUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  Button,
  TextField,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  BoxProps,
  FormHelperText,
} from '@mui/material';
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import {
  useAddPullInProcessCommunicationsMutation,
  useLazyGetPullInProcessCommunicationByIdQuery,
  useUpdatePullInProcessCommunicationByIdMutation,
} from 'api/pullInProcess';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { EPullinProcessDocumentType } from 'enums/EPullinProcessDocumentTypes';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { PullInFileDownloadWrapper } from 'pages/pullInProcessPage/components';
import { useCommunicationContext } from 'pages/pullInProcessPage/components/communications/communicationDetails';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { TCommunicationsForm } from 'types/pullInProcess/communicationsTypes';
import {
  extractErrorMessages,
  instanceOfTransactionErrorWithPropertyErrors,
} from 'util/ApiUtils';
import { setServerSideFormErrors } from 'util/formUtils';

const RevealioBox = styled(Box, {
  shouldForwardProp: prop => isValidProp(prop) && prop !== 'show',
})<BoxProps & { show: boolean }>(props => ({
  maxHeight: props.show ? '3.5rem' : '0px',
  height: 'auto',
  opacity: props.show ? '1' : '0',
  transition: 'max-height 0.2s ease-in, opacity 0.2s ease-in',
}));

export function AddEditCommunications() {
  const formId = 'comms';

  const { modalData, isShowing, hideModal } = useCommunicationContext();
  const isEdit = !!modalData;

  return (
    <>
      <StyledDrawer anchor="right" open={isShowing}>
        <DialogTitle component="div">
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h1" component="span">
              {isEdit ? 'Edit' : 'Add'} Communications
            </Typography>
            <IconButton
              onClick={hideModal}
              aria-label="Close drawer"
              name="Close drawer"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <PullInProcessCommunicationForm formId={formId} />
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button variant="outlined" onClick={hideModal}>
              Cancel
            </Button>

            <LoadingButton variant="contained" type="submit" form={formId}>
              Save
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </StyledDrawer>
    </>
  );
}

type TPullInProcessCommunicationProps = {
  formId: string;
};

export function PullInProcessCommunicationForm({
  formId,
}: TPullInProcessCommunicationProps) {
  const { pullInProcessId } = usePullInProcessContext();
  const { modalData, hideModal } = useCommunicationContext();

  const [postCommunication] = useAddPullInProcessCommunicationsMutation();
  const [putCommunication] = useUpdatePullInProcessCommunicationByIdMutation();
  const [getCommunication] = useLazyGetPullInProcessCommunicationByIdQuery();

  const form = useForm<TCommunicationsForm>({
    defaultValues: async () => {
      if (modalData) {
        const { attachmentFile, ...result } = await getCommunication({
          communicationId: modalData,
          buildingId: pullInProcessId,
        }).unwrap();
        return {
          ...result,
          attachmentFileId: attachmentFile?.id,
          fileTitle: attachmentFile?.name,
          communicationReceivedDateTime: new Date(
            result.communicationReceivedDateTime
          ),
        } as unknown as TCommunicationsForm; // required to force date to date type not string for MUI datepicker
      }
      return Promise.resolve({} as TCommunicationsForm);
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const CommunicationTypeOptions = [
    {
      label: 'Phone',
      value: 1,
      icon: <Icon icon={faPhone} size="sm" color={_labelColor} />,
    },
    {
      label: 'Letter',
      value: 2,
      icon: <Icon icon={faFile} size="sm" color={_labelColor} />,
    },
    {
      label: 'Email',
      value: 3,
      icon: <Icon icon={faEnvelope} size="sm" color={_labelColor} />,
    },
    {
      label: 'Note',
      value: 4,
      icon: <Icon icon={faPen} size="sm" color={_labelColor} />,
    },
  ];

  const InboundOutboundOptions = [
    {
      label: 'Inbound',
      value: true,
      icon: <Icon icon={faArrowUp} size="sm" color={_labelColor} />,
    },
    {
      label: 'Outbound',
      value: false,
      icon: <Icon icon={faArrowDown} size="sm" color={_labelColor} />,
    },
  ];

  const onSubmit = async (formData: TCommunicationsForm) => {
    try {
      modalData
        ? await putCommunication({
            buildingId: pullInProcessId,
            communicationId: modalData,
            payload: { ...formData, isInbound: formData.isInbound || false },
          }).unwrap()
        : await postCommunication({
            buildingId: pullInProcessId,
            ...{ ...formData, isInbound: formData.isInbound || false },
          }).unwrap();

      createSuccessSnackbar(
        `New Communication ${modalData ? 'Updated' : 'Added'}`
      );
      hideModal();
    } catch (e) {
      if (instanceOfTransactionErrorWithPropertyErrors(e)) {
        const tError = e.data;
        tError.propertyErrors.forEach(() => {
          setServerSideFormErrors(form, tError);
        });
      } else {
        createErrorSnackbar(extractErrorMessages(e));
      }
    }
  };

  const _fileInputName = 'attachmentFileId';

  const { uploadFile, uploadFileStatus } = useFileUpload();

  const watchFileTitle = watch('fileTitle');
  const watchAttachmentFileId = watch('attachmentFileId');
  const communicationTypeId = watch('communicationTypeId');

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'PullInBuildingCommunication',
      onSuccess: payload => {
        setValue(`${_fileInputName}`, payload.id);
      },
    });
  };

  const handleOnDeleteFileUpload = async () => {
    setValue(`${_fileInputName}`, '');
  };

  const deleteEvidenceFile = () => {
    setValue(`${_fileInputName}`, '');
    setValue('fileTitle', '');
  };

  return (
    <FormProvider {...form}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Box paddingTop={1} padding={2}>
          <Box
            marginTop={1}
            marginBottom={2}
            p="1rem"
            sx={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
          >
            <Typography
              variant="subtitle1"
              fontSize={'0.95rem'}
              fontWeight="900"
              marginTop={2}
              marginBottom={2}
              component="span"
            >
              Type of communication (required)
            </Typography>
            <Box marginTop={2}>
              <Controller
                control={control}
                name="communicationTypeId"
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => {
                  return (
                    <FormControl>
                      <ToggleButtonGroup
                        {...fieldProps}
                        value={fieldProps.value}
                        onChange={(_, val) => {
                          if (val !== null) {
                            onChange(val);
                          }
                        }}
                        exclusive
                        sx={{
                          mb: 3,
                          backgroundColor: '#f5f5f5',
                          boxShadow: 'none',
                        }}
                      >
                        {CommunicationTypeOptions.map(type => (
                          <ToggleButton
                            sx={{
                              marginRight: '10px',
                              borderTopRightRadius: '4px',
                              borderBottomRightRadius: '4px',
                            }}
                            key={type.label}
                            value={type.value}
                          >
                            <Box mr={1}>{type.icon}</Box>
                            {type.label}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                      {fieldState.error?.message ? (
                        <FormHelperText error>
                          {fieldState.error?.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  );
                }}
              />
            </Box>
            <Box>
              <Controller
                control={control}
                name="isInbound"
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => {
                  return (
                    <FormControl>
                      <ToggleButtonGroup
                        {...fieldProps}
                        value={fieldProps.value}
                        onChange={(_, val) => {
                          if (val !== null) {
                            onChange(val);
                          }
                        }}
                        exclusive
                        sx={{
                          mb: 1,
                          backgroundColor: '#f5f5f5',
                          boxShadow: 'none',
                        }}
                      >
                        {InboundOutboundOptions.map(type => (
                          <ToggleButton
                            sx={{
                              marginRight: '10px',
                              borderTopRightRadius: '4px',
                              borderBottomRightRadius: '4px',
                            }}
                            key={type.label}
                            value={type.value}
                          >
                            <Box mr={1}>{type.icon}</Box>
                            {type.label}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </FormControl>
                  );
                }}
              />
            </Box>
          </Box>

          <RevealioBox
            marginTop={2}
            display={'flex'}
            show={[1, 2, 3].some(v => v === Number(communicationTypeId))}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name={'communicationReceivedDateTime'}
                defaultValue={null}
                render={({
                  field: { onChange, value, ...field },
                  formState: { errors },
                }) => (
                  <MobileDateTimePicker
                    {...field}
                    format="dd/MM/yyyy - p"
                    label="Date of Communication"
                    value={value}
                    disableFuture
                    onChange={val => (val ? onChange(val) : null)}
                    orientation="landscape"
                    slotProps={{
                      previousIconButton: {
                        sx: { width: '24px', height: '24px', margin: '0px' },
                      },
                      nextIconButton: {
                        sx: { width: '24px', height: '24px', margin: '0px' },
                      },
                      textField: {
                        sx: { mb: 0 },
                        error: !!errors.dateTimeCommunicationReceived,
                        helperText:
                          errors.dateTimeCommunicationReceived?.message,
                        fullWidth: true,
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </RevealioBox>

          <Box
            marginTop={2}
            marginBottom={2}
            p="1rem"
            sx={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
          >
            <Typography
              variant="subtitle1"
              fontSize={'0.95rem'}
              fontWeight="900"
              marginTop={2}
              marginBottom={2}
              component="span"
            >
              Note
            </Typography>
            <Box marginTop={2}>
              <Controller
                control={control}
                name="notes"
                defaultValue={watch('notes') || ''}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={4}
                    label="Notes"
                    error={!!errors.notes}
                    helperText={errors.notes?.message}
                  />
                )}
              />
            </Box>
          </Box>

          <Box
            marginTop={2}
            p="1rem"
            sx={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
          >
            <Typography
              variant="subtitle1"
              fontSize={'0.95rem'}
              fontWeight="900"
              marginTop={2}
              marginBottom={2}
              component="span"
            >
              Upload evidence of communication
            </Typography>
            <Box marginTop={2}>
              {watchFileTitle && watchAttachmentFileId ? (
                <Box display="flex" flexDirection="row">
                  <PullInFileDownloadWrapper
                    fileName={watchFileTitle || 'Unknown file name'}
                    pullInDocumentTypeId={modalData || ''}
                    pullInDocumentType={
                      EPullinProcessDocumentType.Communication
                    }
                  />
                  <IconButton
                    size="small"
                    onClick={deleteEvidenceFile}
                    aria-label="Delete File"
                    name="Delete File"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </Box>
              ) : (
                <>
                  <FileUploadInputSelect
                    id={_fileInputName}
                    inputName={_fileInputName}
                    label="Click to upload or drag and drop file"
                    subLabel="DOC, DOCX, PDF, XLS, XLSX, EML, PNG, JPG, JPEG or MSG(max. 30MB)"
                    accept=".pdf,.docx,.xls,.xlsx,.eml,.jpg,.jpeg,.png, .msg"
                    onFileUpload={file => handleOnFileUpload(file)}
                    onFileDelete={() => handleOnDeleteFileUpload()}
                    isUploading={uploadFileStatus.isLoading}
                    uploaded={uploadFileStatus.isSuccess}
                  />
                  {errors.attachmentFileId?.message ? (
                    <FormHelperText error>
                      {errors.attachmentFileId?.message}
                    </FormHelperText>
                  ) : null}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}

const _labelColor = 'grey.700';

const Icon = (props: ComponentProps<typeof FontAwesomeIcon>) => {
  return <FontAwesomeIcon {...props} color={'#999'} />;
};
