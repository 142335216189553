import { Chip, Grid, Stack } from '@mui/material';
import {
  FooterTotal,
  GridItemRight,
  getDiffBetweenPreviousAndCurrent,
} from 'common/components/costSchedule';
import { useVariationCostsSchedule } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { getLatestCostSchedule } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection';

export const CostsScheduleFooter = () => {
  const { isSuccess, data, hasAmended } = useVariationCostsSchedule();

  if (!data || !isSuccess) {
    return null;
  }

  return (
    <Grid
      container
      sx={{
        borderStyle: 'solid',
        borderColor: 'grey.300',
        borderWidth: '1px 0 0',
        mt: 3,
        pt: 2,
      }}
    >
      <Grid item xs={3.5}>
        {/* Keep for spacing */}
      </Grid>
      <Grid item xs={8.5} display={'flex'} alignItems={'flex-start'} px={2}>
        <Grid container columns={hasAmended ? 14 : 12}>
          <Grid item xs={6}>
            <Stack direction={'row'} gap={3}>
              {data.currentVersion ? (
                <Chip
                  size="small"
                  label={`v${data.currentVersion}`}
                  sx={{ alignSelf: 'center' }}
                />
              ) : null}

              <FooterTotal
                label="Total ineligible costs"
                value={getLatestCostSchedule(data).ineligibleAmount ?? 0}
                color="red"
                valueSx={{ textAlign: 'center' }}
              />
            </Stack>
          </Grid>

          <GridItemRight xs={2}>
            <FooterTotal
              label={'Works package'}
              value={data.original.overallTotal}
              color="grey"
            />
          </GridItemRight>

          <GridItemRight xs={2}>
            <FooterTotal
              label={'Variation'}
              value={
                getDiffBetweenPreviousAndCurrent(
                  data.original,
                  data.variation,
                  'overallTotal'
                ) ?? 0
              }
              color="grey"
            />
          </GridItemRight>

          {hasAmended ? (
            <GridItemRight xs={2}>
              <FooterTotal
                label={'Amendments'}
                value={
                  getDiffBetweenPreviousAndCurrent(
                    data.variation || data.original,
                    data.amended,
                    'overallTotal'
                  ) ?? 0
                }
                color="grey"
              />
            </GridItemRight>
          ) : null}

          <GridItemRight xs={2}>
            <FooterTotal
              label={'Total projected cost'}
              value={getLatestCostSchedule(data).overallTotal}
            />
          </GridItemRight>
        </Grid>
      </Grid>
    </Grid>
  );
};
