import {
  faClock,
  faUser,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, SxProps, Typography } from '@mui/material';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const DateTimeAndUserRow = ({
  timestamp,
  personName,
  sx,
}: {
  timestamp: string;
  personName: string;
  sx?: SxProps;
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ...sx }}>
      <FooterItem
        icon={faClock}
        value={getUkDateStringFromJsonDateString(timestamp)}
      />
      <FooterItem icon={faUser} value={personName} />
    </Box>
  );
};

const FooterItem = ({
  icon,
  value,
}: {
  icon: IconDefinition;
  value: string;
}) => {
  return (
    <Typography
      color="grey.500"
      sx={{ display: 'flex', alignItems: 'center' }}
      variant="h3"
      fontWeight={600}
      fontSize={'0.9em'}
    >
      <FontAwesomeIcon icon={icon} style={{ marginRight: '8px' }} />
      {value}
    </Typography>
  );
};
