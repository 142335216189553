import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Chip,
  Grid,
  IconButton,
  Menu,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { SortDirection } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetPaginatedPullInProcessesQuery } from 'api/pullInProcess';
import { Loading } from 'common/components/loading/Loading';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import { EPullInProcessStatusLanguage } from 'enums/EPullInProcessStatus';
import { ESortDirection } from 'enums/ESortDirection';
import { useEllipsisMenu } from 'hooks';
import { EApplicationsMenuType } from 'pages/applicationsPage/types';
import { AddBuildingRecord } from 'pages/pullInProcessesPage/components/addBuildingRecord';
import { routePaths } from 'routing/IndexRoutes';
import { useAppDispatch } from 'state';
import { selectPullInProcessesGridState } from 'state/selectors/pullInProcessSelectors/pullInProcessSelectors';
import { pullInProcessGridStateChanged } from 'state/slices/pullInProcess';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { TPullInProcessGridListItem } from 'types/pullInProcess/pullInProcessTypes';
import {
  getMenuItemConfig,
  getMenuItems,
  getUkDateStringFromJsonDateString,
} from 'util/AppUtils';

export function PullInProcessesDataGrid() {
  const dispatch = useAppDispatch();
  const pullInProcessesGridState = useSelector(selectPullInProcessesGridState);
  const query = useGetPaginatedPullInProcessesQuery(pullInProcessesGridState);
  const navigate = useNavigate();

  const { skip, take, sortBy, sortDirection } = pullInProcessesGridState;

  const noResultsFound =
    query?.data?.count === 0 && !!query?.originalArgs?.searchValue;

  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const handleMenuItemClick = (menuType: EApplicationsMenuType) => {
    switch (menuType) {
      case EApplicationsMenuType.View:
        navigate(`/${routePaths.pullInProcesses}/${selectedId}`);
        break;
    }

    handleMenuClose();
  };

  const currentPage = skip / take;

  const sortHandler = (property: keyof TPullInProcessGridListItem): void => {
    dispatch(
      pullInProcessGridStateChanged({
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      })
    );
  };

  const getAriaSortDirection = (
    property: keyof TPullInProcessGridListItem,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getButtonAriaSortDirection = (
    property: keyof TPullInProcessGridListItem,
    direction: ESortDirection
  ): 'ascending' | 'descending' => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'ascending'
      : 'descending';
  };

  const getCurrentSortDirection = (
    property: keyof TPullInProcessGridListItem,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof TPullInProcessGridListItem,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          aria-sort={getButtonAriaSortDirection(property, sortDirection)}
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  return (
    <>
      <Loading isOpen={query.isLoading} />
      <Grid item xs={12}></Grid>
      <NoResultsFoundText show={noResultsFound} />
      <StyledGenericTable data-testid="Pull-In-Processes-Table">
        <TableHead>
          <TableRow>
            {getHeaderTableCell('uniqueId', 'Unique ID')}
            {getHeaderTableCell('buildingAddress', 'Building Address')}
            {getHeaderTableCell('defaultContact', 'Default Contact')}
            {getHeaderTableCell('company', 'Company')}
            {getHeaderTableCell('contactType', 'Contact Type')}
            {getHeaderTableCell('stage', 'Stage')}
            {getHeaderTableCell('creationDate', 'Created Date')}
            {getHeaderTableCell('dateLastUpdated', 'Last Updated Date')}
            {getHeaderTableCell('status', 'Status')}
            {getHeaderTableCell('dueDate', 'Due Date')}
            {getHeaderTableCell('progressionDate', 'Progression Date')}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {query.data?.results?.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.uniqueId}</TableCell>
              <TableCell>{row.buildingAddress}</TableCell>
              <TableCell>{row.defaultContact ?? ''}</TableCell>
              <TableCell>{row.company ?? ''}</TableCell>
              <TableCell>{row.contactType ?? ''}</TableCell>
              <TableCell>{row.stage ?? ''}</TableCell>
              <TableCell>
                {getUkDateStringFromJsonDateString(row.creationDate)}
              </TableCell>
              <TableCell>
                {getUkDateStringFromJsonDateString(row.dateLastUpdated)}
              </TableCell>
              <TableCell>
                {EPullInProcessStatusLanguage[row.status] ? (
                  <Chip
                    size="small"
                    label={EPullInProcessStatusLanguage[row.status]}
                  />
                ) : null}
              </TableCell>
              <TableCell>
                {row.dueDate && getUkDateStringFromJsonDateString(row.dueDate)}
              </TableCell>
              <TableCell>
                {row.progressionDate && getUkDateStringFromJsonDateString(row.progressionDate)}
              </TableCell>
              <TableCell style={{ width: '5em' }} aria-label="Actions">
                <IconButton
                  aria-label="edit"
                  name="edit"
                  onClick={event => handleEllipsisClick(row.id, event)}
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
                {selectedId === row.id ? (
                  <Menu
                    id={`pull-in-process-menu-${row.id}`}
                    anchorEl={ellipsisMenuAnchor}
                    open={Boolean(ellipsisMenuAnchor)}
                    onClose={handleMenuItemClick}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {getMenuItems(
                      [getMenuItemConfig('View', EApplicationsMenuType.View)],
                      handleMenuItemClick
                    )}
                  </Menu>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={query.data?.count || 0}
              rowsPerPage={take}
              rowsPerPageOptions={[take]}
              page={currentPage}
              onPageChange={(e, newPage) => {
                const newSkip = newPage * take;
                dispatch(pullInProcessGridStateChanged({ skip: newSkip }));
              }}
              onRowsPerPageChange={e => {
                dispatch(
                  pullInProcessGridStateChanged({
                    take: +e.target.value,
                  })
                );
              }}
            />
          </TableRow>
        </TableFooter>
      </StyledGenericTable>
      <AddBuildingRecord />
    </>
  );
}
