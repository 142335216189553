export enum ESupportTicketType {
  ProgressReport = 1,
}

export enum ESupportTicketStatus {
  ToDo = 1,
  AppointedRemediationAdviser = 2,
  ReferredToHomesEngland = 3,
  ResolvedByDavies = 4,
  ResolvedByHomesEngland = 5,
}

export enum ESupportTicketAreaType {
  LeadDesigner = 1,
  OtherMembers = 2,
  Quotes = 3,
  PlanningPermission = 4,
}

export enum ESupportTicketContactMethod {
  None = 0,
  Phone = 1,
  Email = 2,
}

export enum ESupportTicketDetailType {
  Ticket = 1,
  Referred = 2,
  Resolved = 3,
  RemediationAdviserAssigned = 4,
}

export const ESupportTicketStatusLanguage: {
  [key in ESupportTicketStatus]: string;
} = {
  [ESupportTicketStatus.ToDo]: 'To Do',
  [ESupportTicketStatus.AppointedRemediationAdviser]:
    'Appointed Remediation Adviser',
  [ESupportTicketStatus.ReferredToHomesEngland]: 'Referred to Homes England',
  [ESupportTicketStatus.ResolvedByDavies]: 'Resolved by Davies',
  [ESupportTicketStatus.ResolvedByHomesEngland]: 'Resolved by Homes England',
} as const;

export const ESupportTicketAreaTypeLanguage: {
  [key in ESupportTicketAreaType]: string;
} = {
  [ESupportTicketAreaType.LeadDesigner]: 'Lead Designer',
  [ESupportTicketAreaType.OtherMembers]: 'Other Members',
  [ESupportTicketAreaType.Quotes]: 'Quotes',
  [ESupportTicketAreaType.PlanningPermission]: 'Planning Permission',
} as const;

export const ESupportTicketContactMethodLanguage: {
  [key in ESupportTicketContactMethod]: string;
} = {
  [ESupportTicketContactMethod.None]: 'None',
  [ESupportTicketContactMethod.Phone]: 'Phone',
  [ESupportTicketContactMethod.Email]: 'Email',
} as const;

export const ESupportTicketDetailTypeLanguage: {
  [key in ESupportTicketDetailType]: string;
} = {
  [ESupportTicketDetailType.Ticket]: 'Ticket',
  [ESupportTicketDetailType.Referred]: 'Referred',
  [ESupportTicketDetailType.RemediationAdviserAssigned]: 'Remediation Adviser',
  [ESupportTicketDetailType.Resolved]: 'Resolved',
} as const;

export interface ISupportListItem {
  id: string;
  referenceNumber: number;
  description: string;
  startDate: string;
  cost?: number;
  supportTicketType: ESupportTicketType;
  remediationThirdPartyType: string;
  status: ESupportTicketStatus;
}

export interface IReferralType {
  id: number;
  name: string;
}

export interface IResolutionReason {
  id: number;
  name: string;
}

export interface ISupportTicketInfo {
  id: string;
  referenceNumber: number;
  description: string;
  status: ESupportTicketStatus;
  applicantPhone: string;
  applicantEmail: string;
  raisedByHomesEngland: boolean;
  supportAreas: [
    {
      supportAreaType: ESupportTicketAreaType;
      reason: string;
    }
  ];
  details: {
    contactMethod: ESupportTicketContactMethod;
    doesApplicantHaveCapacityGap: boolean;
    description: string;
    supportDetailType: ESupportTicketDetailType;
    timestamp: string;
    resolutionReason?: number;
    resolutionDescription?: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
    };
    evidenceFile?: {
      id: string;
      name: string;
    };
    remediationAdviser?: string;
    remediationThirdPartyType?: string;
    remediationStartDate?: string;
    remediationEndDate?: string;
    remediationCompany?: string;
    remediationCost?: number;
    remediationAdviserContactNumber?: string;
    remediationAdviserEmailAddress?: string;
  }[];
}

export interface ISupportApplicationSummary {
  applicantName: string;
  emailAddress: string;
  contactNumber: string;
  buildingAddress: {
    nameNumber: string;
    addressLine1: string;
    addressLine2?: string | null;
    townCity: string;
    county?: string | null;
    postcode: string;
    country: string;
    localAuthority: string;
  };
  responsibleEntityType: string;
}

export interface IResolveSupportForm {
  supportTicketResolutionReasonId: string;
  resolutionDescription: string;
}

export interface INewSupportRequestData {
  supportTicketType: ESupportTicketType;
  applicationId: string;
  description: string;
  contactMethod: ESupportTicketContactMethod;
  doesApplicantHaveCapacityGap: boolean;
  evidenceFileId: string;
}

export interface ISupportRemediationAdviser {
  id: number;
  firstName: string;
  lastName: string;
  company: string;
  emailAddress: string;
  telephone: string;
}

export interface IReferToRemediationAdviser {
  referralTypeId: number;
  remediationAdviserId: string | number;
  remediationAdviserStartDate?: string;
  remediationAdviserEndDate?: string;
  remediationAdviserCost?: string | number;
}

export interface IReferToRemediationAdviserMutationProps {
  supportTicketData: {
    applicationId: string;
    supportTicketId: string;
  };
  formData: IReferToRemediationAdviser;
}
