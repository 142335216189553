import { Box, Chip, Divider, Typography } from '@mui/material';
import { ErrorAlert } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import {
  ESupportTicketDetailType,
  ESupportTicketStatus,
  ESupportTicketStatusLanguage,
  ISupportTicketInfo,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { useGetSupportTicketInfo } from 'pages/applicationPage/content/support/supportHooks';
import { extractErrorMessages } from 'util/ApiUtils';

export const SupportTicketDetails = () => {
  return (
    <RoundBorderBox sx={{ p: 2 }}>
      <Box mb={2}>
        <Typography variant="h3" fontWeight={900} fontSize={'1.05em'}>
          Support Details
        </Typography>
      </Box>
      <SupportOverviewSectionContent />
    </RoundBorderBox>
  );
};

const SupportOverviewSectionContent = () => {
  const { query } = useGetSupportTicketInfo();

  const { isSuccess, isError, error, data, isLoading } = query;

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (isSuccess) {
    return <SupportOverviewSectionData data={data} />;
  }

  return null;
};

const SupportOverviewSectionData = ({ data }: { data: ISupportTicketInfo }) => {
  const stackedData: [string, string][] = [
    [
      'Support Status',
      data.status === ESupportTicketStatus.AppointedRemediationAdviser
        ? `${ESupportTicketStatusLanguage[data.status]} - ${
            data.details.find(x => x.supportDetailType === ESupportTicketDetailType.RemediationAdviserAssigned)?.remediationThirdPartyType
          }`
        : ESupportTicketStatusLanguage[data.status],
    ],
    ['Email', data.applicantEmail],
    ['Phone', data.applicantPhone],
  ];

  return (
    <>
      {stackedData.map(([title, value], i) => (
        <SupportDetailsSectionStacked key={i} title={title} value={value} />
      ))}
    </>
  );
};

const SupportDetailsSectionStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <>
      <Box>
        <DataTitle title={title} />
      </Box>
      <Box>
        {title === 'Support Status' ? (
          <Chip
            label={value}
            size="small"
            sx={{
              color: 'grey.700',
              marginTop: '5px',
              fontWeight: '700',
            }}
          />
        ) : (
          <Typography variant="body1" marginTop={'5px'} fontWeight={600}>
            {value}
          </Typography>
        )}
      </Box>
      <Divider sx={{ my: 1.5 }} />
    </>
  );
};

const DataTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      variant="body1"
      color="grey.700"
      fontWeight={'600'}
      fontSize={'0.95em'}
    >
      {title}
    </Typography>
  );
};
