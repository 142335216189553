import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ESortDirection } from 'enums/ESortDirection';
import {
  IAdministrationBenchmarkFiguresGridItem,
  IGetAdministrationBenchmarkFigureListQuery,
} from 'types/administration/AdministrationBenchmarkFiguresTypes';
import { nameof } from 'util/formUtils';

export interface IAdministrationBenchmarkFiguresState {
  gridState: IGetAdministrationBenchmarkFigureListQuery;
}

const initialState: IAdministrationBenchmarkFiguresState = {
  gridState: {
    skip: 0,
    take: 25,
    searchValue: null,
    sortBy: nameof<IAdministrationBenchmarkFiguresGridItem>('dateChanged'),
    sortDirection: ESortDirection.Desc,
  },
};

export const administrationBenchmarkFiguresSlice = createSlice({
  name: 'administrationBenchmarkFiguresSlice',
  initialState,
  reducers: {
    gridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetAdministrationBenchmarkFigureListQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
    userAdministrationBenchmarkFiguresPage: () => initialState,
  },
});

export const administrationBenchmarkFiguresReducer =
  administrationBenchmarkFiguresSlice.reducer;
export const { gridStateChanged, userAdministrationBenchmarkFiguresPage } =
  administrationBenchmarkFiguresSlice.actions;
