import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogTitle,
  Typography,
  CircularProgress,
  DialogContent,
  Grid,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  useGetRecommendedInterimMeasuresQuery,
  useEditRecommendedInterimMeasuresMutation,
} from 'api/application/applicationEditApi';
import { FieldErrorOrNull } from 'common/components/fieldErrorOrNull';
import { EBuildingInterimMeasures } from 'enums/EBuildingInterimMeasures';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { FieldLabel } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IdName } from 'types/GeneralTypes';
import { zRecommendedInterimMeasuresSchema } from 'types/applications/ApplicationEdit.zod';
import { IRecommendedInterimMeasuresForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditRecommendedInterimMeasuresDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditRecommendedInterimMeasuresDrawer = ({
  onClose,
  onSuccess,
}: IEditRecommendedInterimMeasuresDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit recommended interim measures?
          </Typography>
        </DialogTitle>
        <EditRecommendedInterimMeasuresForm
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Box>
    </StyledDrawer>
  );
};

interface IEditRecommendedInterimMeasuresFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditRecommendedInterimMeasuresForm = ({
  onClose,
  onSuccess,
}: IEditRecommendedInterimMeasuresFormProps) => {
  const { applicationId } = useApplicationContext();

  const options: IdName<EBuildingInterimMeasures>[] = [
    { id: EBuildingInterimMeasures.CommonFireAlarm, name: 'Common Fire Alarm' },
    {
      id: EBuildingInterimMeasures.EvacuationManagement,
      name: 'Evacuation Management',
    },
    {
      id: EBuildingInterimMeasures.FireHeatSmokeDetection,
      name: 'Fire Heat Smoke Detection',
    },
    {
      id: EBuildingInterimMeasures.FireSupressionSystem,
      name: 'Fire Supression System',
    },
    { id: EBuildingInterimMeasures.Other, name: 'Other' },
    {
      id: EBuildingInterimMeasures.SimultaneousEvacuationStrategy,
      name: 'Simultaneous Evacuation Strategy',
    },
    { id: EBuildingInterimMeasures.WakingWatch, name: 'Waking Watch' },
  ];

  const { data, isLoading } =
    useGetRecommendedInterimMeasuresQuery(applicationId);

  const [editRecommendedInterimMeasures, editRecommendedInterimMeasuresResult] =
    useEditRecommendedInterimMeasuresMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IRecommendedInterimMeasuresForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: data || undefined,
    resolver: zodResolver(zRecommendedInterimMeasuresSchema),
  });
  const { handleSubmit, reset, control, watch, setValue } = form;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const onSubmit = async (formData: IRecommendedInterimMeasuresForm) => {
    try {
      formData.applicationId = applicationId;
      await editRecommendedInterimMeasures(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar(
            `Recommended interim measures updated successfully`
          );
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit recommended interim measures`);
    }
  };

  return isLoading ? (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} mb={2}>
              <FieldLabel label="Do you recommend the building be put into interim measures?" />
              <Controller
                control={control}
                name="hasRecommendedMeasures"
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => (
                  <>
                    <FormControl>
                      <ToggleButtonGroup
                        {...fieldProps}
                        onChange={(_, val) => {
                          onChange(val);
                          setValue('recommendedMeasures', []);
                          setValue('otherRecommendedMeasure', '');
                        }}
                        exclusive
                      >
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                    <FieldErrorOrNull
                      fieldState={fieldState}
                      sx={{ ml: '14px' }}
                    />
                  </>
                )}
              />
            </Grid>

            {watch('hasRecommendedMeasures') ? (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="recommendedMeasures"
                  render={({
                    fieldState,
                    field: { onChange, value, ...fieldProps },
                  }) => (
                    <Autocomplete
                      {...fieldProps}
                      onChange={(_, val) => {
                        onChange(val);
                        if (!val.includes(EBuildingInterimMeasures.Other)) {
                          setValue('otherRecommendedMeasure', '');
                        }
                      }}
                      value={value}
                      options={options.map(({ id }) => id)}
                      getOptionLabel={opt =>
                        options.find(o => o.id === opt)?.name || ''
                      }
                      multiple
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!fieldState.error}
                            label="If yes, what type of interim measures do you recommend?"
                            helperText={fieldState.error?.message}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            ) : null}
            {watch('recommendedMeasures')?.includes(
              EBuildingInterimMeasures.Other
            ) ? (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="otherRecommendedMeasure"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      label="If other selected, enter other"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Controller
                control={control}
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editRecommendedInterimMeasuresResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
