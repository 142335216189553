import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@mui/material';
import { useGetPullInProcessesKpisQuery } from 'api/pullInProcess/pullInProcessApi';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { MainContentContainer } from 'common/components/mainContentContainer';
import { PageTitle } from 'common/components/pageTitle';
import { useModalState } from 'hooks';
import { PullInProcessesPageContent } from 'pages/pullInProcessesPage/components';
import { PullInProcessesContext } from 'pages/pullInProcessesPage/context';

const _crumbs: readonly IBreadcrumbItem[] = [
  { text: 'Pull in process', url: undefined },
] as const;

export const PullInProcessesPage = () => {
  const query = useGetPullInProcessesKpisQuery();
  const modalState = useModalState<string>();
  return (
    <PullInProcessesContext.Provider value={modalState}>
      <MainContentContainer>
        <Breadcrumbs crumbs={_crumbs} />

        <PageTitle
          title="Pull in process"
          count={query.data ? query.data.preApplications : undefined}
          rightContent={
            <Button
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => modalState.showModal()}
            >
              Add building record
            </Button>
          }
        />

        <Grid item xs={12}>
          <PullInProcessesPageContent />
        </Grid>
      </MainContentContainer>
    </PullInProcessesContext.Provider>
  );
};
