import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaginationQuery } from 'types/PaginationQuery';

export interface IAdministrationAssessorPanelListState {
  gridState: IPaginationQuery;
}

const initialState: IAdministrationAssessorPanelListState = {
  gridState: {
    skip: 0,
    take: 25,
  },
};

const slice = createSlice({
  name: 'administrationAssessorPanelList',
  initialState,
  reducers: {
    assessorPanelListStateChanged: (
      state,
      action: PayloadAction<Partial<IPaginationQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
    userLeftAdmninistrationAssessorPanelList: () => initialState,
  },
});

export const administrationAssessorPanelListReducer = slice.reducer;
export const {
  assessorPanelListStateChanged,
  userLeftAdmninistrationAssessorPanelList,
} = slice.actions;
