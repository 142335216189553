import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Control,
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  useGetBuildingDetailsQuery,
  useGetChecksQuery,
  useGetWorksPackageOverviewQuery,
  useReferToHomesEnglandMutation,
  useSaveChecksFieldsMutation,
  useSaveGrantPercentageMutation,
} from 'api/application/worksPackageApi';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FieldErrorOrNull } from 'common/components/fieldErrorOrNull';
import { useReferToHomesEnglandModal } from 'common/components/referToHomesEnglandModal';
import { useReferToThirdPartyDrawer } from 'common/components/referrals/thirdPartyReferrals';
import { getReferralsArray } from 'common/components/referrals/utils';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useCurrentUser, useInvalidateTasks, useLocalSnackbar } from 'hooks';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import {
  EWorkPackageChecksAnswer,
  IGetWorksPackageBuildingDetails,
  IWorksPackageChecksForm,
  IWorksPackageOverview,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import {
  CostVsBenchmarkSummary,
  StatusChip,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/components';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import {
  ApprovedOrRejectedBanner,
  BuildingDetailsSection,
  FieldLabel,
  GrantPercentageField,
  QuestionContainer,
  SummarySection,
  useApproveModal,
  useRejectModal,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { useWorksPackageReferrals } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/worksPackageHooks';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import {
  EReferralRecordType,
  EReferralStatus,
} from 'types/applications/ReferralTypes';
import { extractErrorMessages } from 'util/ApiUtils';

export const ConfirmSection = () => {
  const { applicationId } = useWorksPackageContext();
  const overview = useGetWorksPackageOverviewQuery(applicationId);
  const checks = useGetChecksQuery(applicationId);
  const buildingDetails = useGetBuildingDetailsQuery(applicationId);

  const isLoading =
    overview.isLoading || checks.isLoading || buildingDetails.isLoading;
  const isSuccess =
    checks.isSuccess && checks.isSuccess && buildingDetails.isSuccess;
  return (
    <RoundBorderBox>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <CardTitle
          title="Confirm works package"
          fontSize="1.2em"
          sx={{ p: 2 }}
        />
        {overview.isSuccess && overview.data.status ? (
          <Stack direction={'row'} gap={3} mr={2}>
            <Typography color={'grey.600'}>Status:</Typography>
            <StatusChip status={overview.data.status} />
          </Stack>
        ) : null}
      </Stack>

      <Divider />
      <Box p={2}>
        {isLoading ? <FormSkeleton /> : null}

        <AlertForQueryErrorOrNull
          isError={overview.isError}
          error={overview.error}
        />

        <AlertForQueryErrorOrNull
          isError={checks.isError}
          error={checks.error}
        />

        {isSuccess && overview.data && checks.data && buildingDetails.data ? (
          <ConfirmSectionMain
            overviewData={overview.data}
            checksData={checks.data}
            buildingDetailsData={buildingDetails.data}
          />
        ) : null}
      </Box>
    </RoundBorderBox>
  );
};

const _formId = 'worksPackageConfirmationForm';

export const ConfirmSectionMain = ({
  overviewData,
  checksData,
  buildingDetailsData,
}: {
  overviewData: IWorksPackageOverview;
  checksData: Partial<IWorksPackageChecksForm>;
  buildingDetailsData: IGetWorksPackageBuildingDetails;
}) => {
  const form = useForm<IWorksPackageChecksForm>({
    defaultValues: {
      ...checksData,
      grantPercentage: buildingDetailsData.percentageOfCosts,
    },
  });

  const { handleSubmit, getValues, watch } = form;
  const [saveChecks, saveStatus] = useSaveChecksFieldsMutation();
  const [saveGrantPercentage, saveGrantPercentageStatus] =
    useSaveGrantPercentageMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const { applicationId, canUserEditGrantPercentage } =
    useWorksPackageContext();

  const { invalidateTasks } = useInvalidateTasks();

  const handleSave = (onSuccess: () => void) => {
    const data = getValues();
    const { grantPercentage, ...formData } = data;
    const saveChecksPromise = saveChecks({
      applicationId,
      formData,
    }).unwrap();

    const saveGrantPercentagePromise = canUserEditGrantPercentage
      ? saveGrantPercentage({
          applicationId,
          formData: { grantPercentage },
        }).unwrap()
      : null;

    Promise.all([saveChecksPromise, saveGrantPercentagePromise])
      .then(() => {
        onSuccess();
      })
      .catch(error => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  const handleSaveClick = () => {
    handleSave(() => {
      createSuccessSnackbar('Works package successfully saved');
    });
  };

  const [referToHe, referToHeStatus] = useReferToHomesEnglandMutation();
  const {
    showReferToHomesEnglandModal,
    hideReferToHomesEnglandModal,
    renderReferToHomesEnglandModal,
  } = useReferToHomesEnglandModal();

  const handleReferToHeClick = () => {
    handleSave(() => {
      showReferToHomesEnglandModal();
    });
  };

  const handleReferToHeConfirmClick = (referralComments: string) => {
    referToHe({
      applicationId,
      referralComments,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar(
          'Works package has been referred to Homes England'
        );
        invalidateTasks();
        hideReferToHomesEnglandModal();
      });
  };

  const thirdPartyReferralDrawer = useReferToThirdPartyDrawer();

  const handleReferToThirdPartyClick = () => {
    handleSave(() => {
      thirdPartyReferralDrawer.showReferDrawer({
        referralRecordType: EReferralRecordType.WorksPackage,
        referralType: 'newReferral',
      });
    });
  };

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.WorksPackage,
  });

  const { renderApproveModal, showApproveModal } = useApproveModal();

  const onSubmit = async () => {
    handleSave(() => {
      showApproveModal();
    });
  };

  const { showRejectModal, renderRejectModal } = useRejectModal();
  const handleRejectClick = () => {
    showRejectModal();
  };

  const { query: referralsQuery } = useWorksPackageReferrals();
  const referralsArray = getReferralsArray(referralsQuery.data);
  const hasActiveReferrals =
    referralsArray.some(item => item.status !== EReferralStatus.Complete) ??
    false;
  const isApprovedOrRejected =
    !!overviewData.applicationWorkPackageApprovalStatus;

  const isSectionValid =
    watch('worksInlineWithFraew') === true &&
    watch('standardCostsBreakdown') === true &&
    watch('projectTeamMembersInvolvedInOriginalCladding') !== undefined &&
    watch('varianceToBenchmarkAcceptable') !== undefined &&
    watch('acmCladdingBeingRemoved') !== undefined &&
    watch('competitiveBidsReasonAcceptable') !== undefined;

  const { isHeUser } = useCurrentUser();
  const isSubmitted = !!overviewData.dateSubmitted;

  return (
    <>
      <CostVsBenchmarkSummary
        applicationId={applicationId}
        data={overviewData}
      />

      {isApprovedOrRejected ? (
        <ApprovedOrRejectedBanner overviewData={overviewData} />
      ) : null}

      {isOnHoldForThisType && (
        <OnHoldBanner holdType={EOnHoldType.WorksPackage} sx={{ mt: 3 }} />
      )}

      <Grid container mt={3} columnSpacing={2}>
        <Grid item xs={7}>
          {isSubmitted ? (
            <FormProvider {...form}>
              <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <YesNoQuestionAndComment
                    label="Is the variance to benchmark within 5%?"
                    fieldName={'varianceToBenchmarkAcceptable'}
                    fieldNameComments={'varianceToBenchmarkAcceptableComments'}
                    readOnly={isApprovedOrRejected || isOnHoldForThisType}
                  />
                  <YesNoQuestionAndComment
                    label="Are the works in the cost schedule inline with the FRAEW recommendations?"
                    fieldName={'worksInlineWithFraew'}
                    fieldNameComments={'worksInlineWithFraewComments'}
                    readOnly={isApprovedOrRejected || isOnHoldForThisType}
                  />
                  <YesNoQuestionAndComment
                    label="Does the works package have a standard breakdown of costs across each section?"
                    fieldName={'standardCostsBreakdown'}
                    fieldNameComments={'standardCostsBreakdownComments'}
                    readOnly={isApprovedOrRejected || isOnHoldForThisType}
                  />
                  <YesNoNaQuestionAndComment
                    label="If the building height is 18 metres or over, is any ACM (cat 2 & cat 3) cladding being fully removed?"
                    fieldName={'acmCladdingBeingRemoved'}
                    fieldNameComments={'acmCladdingBeingRemovedComments'}
                    readOnly={isApprovedOrRejected || isOnHoldForThisType}
                  />
                  <YesNoNaQuestionAndComment
                    label="Was the reason for not obtaining competitive bids acceptable?"
                    fieldName={'competitiveBidsReasonAcceptable'}
                    fieldNameComments={
                      'competitiveBidsReasonAcceptableComments'
                    }
                    readOnly={isApprovedOrRejected || isOnHoldForThisType}
                  />
                  <YesNoQuestionAndComment
                    label="Were any of the project team members involved in the installation of the original cladding?"
                    fieldName={'projectTeamMembersInvolvedInOriginalCladding'}
                    fieldNameComments={
                      'projectTeamMembersInvolvedInOriginalCladdingComments'
                    }
                    commentFieldLabel={'Provide team member name(s)'}
                    readOnly={isApprovedOrRejected || isOnHoldForThisType}
                  />
                  <GrantPercentageField
                    readOnly={
                      isApprovedOrRejected ||
                      !canUserEditGrantPercentage ||
                      isOnHoldForThisType
                    }
                    worksPackageCost={overviewData.worksPackageCost}
                    saveGrantPercentageStatus={saveGrantPercentageStatus}
                  />
                </Box>
              </form>
            </FormProvider>
          ) : (
            <RoundBorderBox bgcolor={'gray.100'} p={3}>
              <CardTitle title="Works package is yet to be submitted" />
            </RoundBorderBox>
          )}
        </Grid>
        <Grid item xs={5}>
          <Stack rowGap={2}>
            <SummarySection data={overviewData} />
            <BuildingDetailsSection />
          </Stack>
        </Grid>
      </Grid>

      <AlertForQueryErrorOrNull
        isError={saveStatus.isError}
        error={saveStatus.error}
      />

      {!isApprovedOrRejected && isSubmitted && (
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Box
              display="flex"
              justifyContent="space-between"
              p={3}
              bgcolor="grey.100"
            >
              {isHeUser ? (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleReferToThirdPartyClick}
                  disabled={saveStatus.isLoading || isOnHoldForThisType}
                >
                  Refer
                </Button>
              ) : null}

              {!isHeUser ? (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleReferToHeClick}
                  disabled={saveStatus.isLoading || isOnHoldForThisType}
                >
                  Refer to HE
                </Button>
              ) : null}

              <OnOffHold holdType={EOnHoldType.WorksPackage} />
              <Box gap={2} display="flex">
                <LoadingButton
                  variant="outlined"
                  size="small"
                  onClick={handleSaveClick}
                  loading={saveStatus.isLoading}
                  disabled={isOnHoldForThisType}
                >
                  Save
                </LoadingButton>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="small"
                  form={_formId}
                  disabled={!isSectionValid || isOnHoldForThisType}
                  loading={saveStatus.isLoading}
                >
                  Approve Works Package
                </LoadingButton>

                {hasActiveReferrals ? (
                  <LoadingButton
                    variant="outlined"
                    size="small"
                    onClick={handleRejectClick}
                    loading={saveStatus.isLoading}
                    disabled={isOnHoldForThisType}
                  >
                    Reject
                  </LoadingButton>
                ) : null}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {renderApproveModal()}

      {renderReferToHomesEnglandModal({
        handleConfirmClick: handleReferToHeConfirmClick,
        referStatus: {
          isLoading: referToHeStatus.isLoading,
          isError: referToHeStatus.isError,
          error: referToHeStatus.error,
        },
      })}

      {thirdPartyReferralDrawer.renderReferDrawer()}

      {renderRejectModal()}
    </>
  );
};

const YesNoQuestionAndComment = ({
  label,
  fieldName,
  fieldNameComments,
  commentFieldLabel = 'Explain Answer',
  readOnly,
}: {
  label: string;
  fieldName: keyof IWorksPackageChecksForm;
  fieldNameComments: keyof IWorksPackageChecksForm;
  commentFieldLabel?: string;
  readOnly: boolean;
}) => {
  const { control, watch } = useFormContext<IWorksPackageChecksForm>();
  const [yesNoAnswer] = watch([fieldName]);
  return (
    <QuestionContainer>
      <Stack gap={3}>
        <Box>
          <FieldLabel label={label} />
          <Controller
            control={control}
            rules={{
              validate: value => {
                if (
                  fieldName === 'varianceToBenchmarkAcceptable' ||
                  fieldName === 'projectTeamMembersInvolvedInOriginalCladding'
                ) {
                  // Both true and false is allowed, just not undefined
                  return value === undefined ? 'Please provide a value' : true;
                } else {
                  if (value !== true) {
                    return 'Yes is the required answer';
                  }
                }

                return true;
              },
            }}
            name={fieldName}
            render={({ fieldState, field: { onChange, ...fieldProps } }) => (
              <>
                <FormControl>
                  <ToggleButtonGroup
                    {...fieldProps}
                    onChange={(_, val) => {
                      onChange(val);
                    }}
                    exclusive
                    disabled={readOnly}
                  >
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
                <FieldErrorOrNull fieldState={fieldState} sx={{ ml: '14px' }} />
              </>
            )}
          />
        </Box>

        {yesNoAnswer !== undefined ? (
          <Comment
            label={commentFieldLabel}
            control={control}
            fieldName={fieldNameComments}
            readOnly={readOnly}
          />
        ) : null}
      </Stack>
    </QuestionContainer>
  );
};

const YesNoNaQuestionAndComment = ({
  label,
  fieldName,
  fieldNameComments,
  commentFieldLabel = 'Explain Answer',
  readOnly,
}: {
  label: string;
  fieldName: keyof IWorksPackageChecksForm;
  fieldNameComments: keyof IWorksPackageChecksForm;
  commentFieldLabel?: string;
  readOnly: boolean;
}) => {
  const { control, watch } = useFormContext<IWorksPackageChecksForm>();
  const [yesNoNaAnswer] = watch([fieldName]);
  return (
    <QuestionContainer>
      <Stack gap={3}>
        <Box>
          <FieldLabel label={label} />
          <Controller
            control={control}
            name={fieldName}
            render={({ fieldState, field: { onChange, ...fieldProps } }) => (
              <>
                <FormControl>
                  <ToggleButtonGroup
                    {...fieldProps}
                    onChange={(_, val) => {
                      onChange(val);
                    }}
                    exclusive
                    disabled={readOnly}
                  >
                    <ToggleButton value={EWorkPackageChecksAnswer.Yes}>
                      Yes
                    </ToggleButton>
                    <ToggleButton value={EWorkPackageChecksAnswer.No}>
                      No
                    </ToggleButton>
                    <ToggleButton value={EWorkPackageChecksAnswer.NA}>
                      Not Applicable
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
                <FieldErrorOrNull fieldState={fieldState} sx={{ ml: '14px' }} />
              </>
            )}
          />
        </Box>

        {yesNoNaAnswer !== undefined ? (
          <Comment
            label={commentFieldLabel}
            control={control}
            fieldName={fieldNameComments}
            readOnly={readOnly}
          />
        ) : null}
      </Stack>
    </QuestionContainer>
  );
};

const Comment = ({
  label = 'Explain Answer',
  control,
  fieldName,
  readOnly,
}: {
  label: string;
  control: Control<IWorksPackageChecksForm, any>;
  fieldName: keyof IWorksPackageChecksForm;
  readOnly: boolean;
}) => {
  return (
    <Box>
      <FieldLabel label={label} />
      <Controller
        control={control}
        name={fieldName}
        rules={{ required: 'Required' }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            multiline
            fullWidth
            disabled={readOnly}
            error={!!fieldState.error}
            rows={3}
            helperText={fieldState.error?.message}
            sx={{
              mb: 0,
              boxShadow: 'none',
              // Selector for error message
              'p:last-child': {
                backgroundColor: 'grey.100',
                margin: 0,
                padding: '1rem',
              },
            }}
          />
        )}
      />
    </Box>
  );
};
