import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'state';

export const selectApplicationsSelectorsRoot = (state: AppState) =>
  state.applications;

export const selectApplicationsGridState = createSelector(
  [selectApplicationsSelectorsRoot],
  applications => applications.gridState
);

export const selectApplicationDocumentsGridState = createSelector(
  [selectApplicationsSelectorsRoot],
  applications => applications.documentsGridState
);

export const selectApplicationCommunicationsGridState = createSelector(
  [selectApplicationsSelectorsRoot],
  applications => applications.communicationsGridState
);