import { Box, Grid } from '@mui/material';
import { ErrorAlert } from 'common/components/alerts';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import {
  ApplicationItemGroupNavigator,
  ColumnContainer,
  SectionProgress,
} from 'pages/applicationPage/common/components';
import { ApplicationGrantFundingSectionOutlet } from 'pages/applicationPage/content/applicationGrantFunding';
import {
  ApplicationGrantFundingContextProvider,
  useApplicationGrantFundingContext,
} from 'pages/applicationPage/content/applicationGrantFunding/context';
import { IApplicationGrantFundingCategory } from 'types/applications/ApplicationGrantFundingTypes';
import { extractErrorMessages } from 'util/ApiUtils';

export const ApplicationGrantFunding = () => {
  return (
    <ApplicationGrantFundingContextProvider>
      <ApplicationGrantFundingMain />
    </ApplicationGrantFundingContextProvider>
  );
};

export const ApplicationGrantFundingMain = () => {
  const {
    selectedItemId,
    setSelectedItemId,
    applicationGrantFundingCategoriesQuery
  } = useApplicationGrantFundingContext();

  const { createInfoSnackbar } = useLocalSnackbar();

  const { isLoading, error, data } = applicationGrantFundingCategoriesQuery;

  if (isLoading) {
    return <Loading isOpen />;
  }

  if (error) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (!data || data.categories.length <= 0) {
    return (
      <ErrorAlert msg="Could not get any application eligbility categories." />
    );
  }

  const stepsCompleted = data.categories.reduce<number>((counter, category) => {
    return (
      counter + category.detailItems.filter(item => item.isComplete).length
    );
  }, 0);

  // Check if a particular category is enabled
  // A category is deemed as enabled if all items from the previous category are complete
  const isCategoryEnabled = (
    categories: IApplicationGrantFundingCategory[],
    currentIndex: number
  ) => {
    // if we are handling the first category, then it's always enabled as there are no dependencies
    if (currentIndex === 0) {
      return true;
    }

    // If we are handling a subsequent category, check all sections in the previous category are complete
    // If they are all complete, this category is enabled
    // If they are not all complete, this category is not enabled
    if (
      categories[currentIndex - 1].detailItems.every(item => item.isComplete)
    ) {
      return true;
    }

    return false;
  };

  // Check if a particular category is enabled
  // A category is deemed as enabled if all items from the previous category are complete
  const isSectionEnabled = (
    category: IApplicationGrantFundingCategory,
    currentIndex: number
  ) => {
    // if we are handling the first section, then it's always enabled as there are no dependencies
    if (currentIndex === 0) {
      return true;
    }

    // If we are handling a subsequent category, check all sections in the previous category are complete
    // If they are all complete, this category is enabled
    // If they are not all complete, this category is not enabled
    if (category.detailItems[currentIndex - 1].isComplete) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={3.5}>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <SectionProgress totalSteps={3} currentStep={stepsCompleted} />
          </ColumnContainer>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <Box p={2}>
              <ApplicationItemGroupNavigator
                itemGroups={data.categories.map((category, categoryIndex) => {
                  const categoryEnabled = isCategoryEnabled(
                    data.categories,
                    categoryIndex
                  );
                  return {
                    title: category.categoryTitle,
                    categoryEnabled,
                    items: category.detailItems.map(
                      (detailItem, detailIndex) => {
                        const sectionEnabled = isSectionEnabled(
                          category,
                          detailIndex
                        );
                        return {
                          title: detailItem.sectionName,
                          isSelected: selectedItemId === detailItem.id,
                          isComplete: detailItem.isComplete,
                          onClick: () => {
                            if (categoryEnabled && sectionEnabled) {
                              setSelectedItemId(detailItem.id);
                            } else {
                              createInfoSnackbar(
                                `You must complete the "${
                                  data.categories[categoryIndex - 1]
                                    .categoryTitle
                                }" section before you can enter this section`,
                                { preventDuplicate: true }
                              );
                            }
                          },
                        };
                      }
                    ),
                  };
                })}
              />
            </Box>
          </ColumnContainer>
        </Grid>
        <Grid item xs={8.5}>
          <ColumnContainer>
            <ApplicationGrantFundingSectionOutlet />
          </ColumnContainer>
        </Grid>
      </Grid>
    </>
  );
};
