import { Box } from '@mui/material';
import {
  ApplicationDetailsItem,
  ApplicationDetailsSectionHeader,
} from 'pages/applicationPage/content/applicationDetails/components';
import { IApplicationDetailsItem } from 'types/applications/ApplicationDetailTypes';

export const ApplicationDetailsSection = ({
  title,
  items,
}: {
  title: string;
  items: IApplicationDetailsItem[];
}) => {
  return (
    <Box mb={3}>
      <ApplicationDetailsSectionHeader title={title} />
      {items.map((item, i) => (
        <ApplicationDetailsItem key={i} item={item} />
      ))}
    </Box>
  );
};
