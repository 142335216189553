import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  DialogContent,
  Grid,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Button,
  Box,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditFraewExternalManagementPoliciesMutation } from 'api/application/applicationEditApi';
import { FieldErrorOrNull } from 'common/components/fieldErrorOrNull';
import { EExternalManagementPolicy } from 'enums/EExternalManagementPolicy';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { FieldLabel } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zFraewExternalManagementPoliciesSchema } from 'types/applications/ApplicationEdit.zod';
import { IFraewExternalManagementPoliciesForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditFraewExternalManagementPoliciesDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewExternalManagementPoliciesDrawer = ({
  onClose,
  onSuccess,
}: IEditFraewExternalManagementPoliciesDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit Are there management policies in place for the external wall
            and balconies?
          </Typography>
          {/* <Typography variant="h3">{currentValue}</Typography> */}
        </DialogTitle>
        <EditFraewExternalManagementPoliciesForm
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Box>
    </StyledDrawer>
  );
};

interface IEditFraewExternalManagementPoliciesFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewExternalManagementPoliciesForm = ({
  onClose,
  onSuccess,
}: IEditFraewExternalManagementPoliciesFormProps) => {
  const { applicationId } = useApplicationContext();

  const options: { id: EExternalManagementPolicy; name: string }[] = [
    { id: EExternalManagementPolicy.Yes, name: 'Yes' },
    { id: EExternalManagementPolicy.No, name: 'No' },
    { id: EExternalManagementPolicy.DontKnow, name: "Don't know" },
    { id: EExternalManagementPolicy.NotApplicable, name: 'Not Applicable' },
  ];

  const [
    editFraewExternalManagementPolicies,
    editFraewExternalManagementPoliciesResult,
  ] = useEditFraewExternalManagementPoliciesMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IFraewExternalManagementPoliciesForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zFraewExternalManagementPoliciesSchema),
  });
  const { handleSubmit, reset, control } = form;

  const onSubmit = async (formData: IFraewExternalManagementPoliciesForm) => {
    try {
      formData.applicationId = applicationId;
      await editFraewExternalManagementPolicies(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(
            `FRAEW external management policies updated successfully`
          );
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit FRAEW external management policies`);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} mb={2}>
              <FieldLabel label="Are there management policies in place for the external wall and balconies?" />
              <Controller
                control={control}
                name="policy"
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => (
                  <>
                    <FormControl>
                      <ToggleButtonGroup
                        {...fieldProps}
                        onChange={(_, val) => {
                          onChange(val);
                        }}
                        exclusive
                      >
                        {options.map(opt => (
                          <ToggleButton key={opt.id} value={opt.id}>
                            {opt.name}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </FormControl>
                    <FieldErrorOrNull
                      fieldState={fieldState}
                      sx={{ ml: '14px' }}
                    />
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editFraewExternalManagementPoliciesResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
