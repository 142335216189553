import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { ConfirmationModal } from 'common/components/confirmationModal/ConfirmationModal';

const usePrompt = (active: boolean) => {
  const [state, setState] = useState({
    blocked: false,
    href: '',
  });
  const { navigator } = useContext(UNSAFE_NavigationContext);
  const unblock = useCallback(
    (navigate: boolean) => {
      if (navigate) {
        navigator.push(state.href);
      }
      setState({
        blocked: false,
        href: '',
      });
    },
    [state, navigator]
  );

  useEffect(() => {
    // setup blocker only when state is unblocked (user not attempted)
    if (!active || state.blocked) return;
    // @ts-ignore navigator has history properties
    const unblocker = (navigator as History).block(tx => {
      // user tried to navigate away from page
      // rather turn blocked to true so consumer
      // of hook can show custom ui.
      setState({
        blocked: true,
        href: tx.location.pathname,
      });
      // immediately remove blocker so when
      // custom ui is submitted navigation is not blocked.
      unblocker();
      // block navigation as custom ui will take over.
      return false;
    });

    return unblocker;
  }, [active, state.blocked, navigator]);

  return [state.blocked, unblock] as const;
};

export function LeavePageGuard({
  blockNavigationIf,
  message = 'Are you sure you want to leave? Any unsaved changes will be lost.',
}: {
  blockNavigationIf: boolean;
  message?: string;
}) {
  const [blocked, unblock] = usePrompt(blockNavigationIf);

  const stayOnPage = () => unblock(false);
  const leavePage = () => unblock(true);

  return (
    <ConfirmationModal
      confirmButtonType="button"
      title="Warning"
      isShowing={blocked}
      onCancel={stayOnPage}
      onConfirm={leavePage}
      cancelButtonText="No"
      confirmButtonText="Yes"
    >
      <Typography variant="body1" sx={theme => ({ p: theme.spacing(5, 0) })}>
        {message}
      </Typography>
    </ConfirmationModal>
  );
}
