import { ErrorAlert } from 'common/components/alerts';
import { EWorksPackageSection } from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context/WorksPackageContext';
import {
  WorksPackageOverviewSection,
  CladdingSection,
  CostsScheduleSection,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections';
import { ConfirmSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { ProjectTeamSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ProjectTeamSection';
import { SubContractorsSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/SubContractorsSection';

export const WorksPackageSectionOutlet = () => {
  const { selectedItemId } = useWorksPackageContext();

  switch (selectedItemId) {
    case EWorksPackageSection.Overview:
      return <WorksPackageOverviewSection />;

    case EWorksPackageSection.Cladding:
      return <CladdingSection />;

    case EWorksPackageSection.CostScheduling:
      return <CostsScheduleSection />;

    case EWorksPackageSection.ProjectTeam:
      return <ProjectTeamSection />;

    case EWorksPackageSection.SubContractors:
      return <SubContractorsSection />;

    case EWorksPackageSection.Confirm:
      return <ConfirmSection />;

    default:
      return (
        <ErrorAlert
          msg={`Could not work out which section to display for id: ${selectedItemId}`}
        />
      );
  }
};
