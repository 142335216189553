import { Box, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { IScheduleOfWorksOverview } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { formatDateMonthYear } from 'util/DateUtils';

interface IKeyDates {
  startDate: IScheduleOfWorksOverview['startDate'];
  endDate: IScheduleOfWorksOverview['endDate'];
}

const displayMonth = (numberOfMonths: number) => {
  const singularMonth = numberOfMonths === 1;
  return singularMonth ? `${numberOfMonths} Month` : `${numberOfMonths} Months`;
};

export const KeyDatesCard = ({
  title,
  data,
  projectDuration,
}: {
  title: string;
  data: IKeyDates;
  projectDuration: number;
}) => {
  return (
    <Box
      mb={2}
      bgcolor={'grey.100'}
      sx={{
        width: '100%',
        padding: 2,
        paddingTop: 3,
        marginRight: 2,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h3"
        marginBottom={4}
        fontWeight={900}
        fontSize={'1.05em'}
      >
        {title}
      </Typography>
      <KeyDatesCardData
        data={data as IKeyDates}
        projectDuration={projectDuration}
      />
    </Box>
  );
};

export const KeyDatesCardData = ({
  data,
  projectDuration,
}: {
  data: IKeyDates;
  projectDuration: number;
}) => {
  const stackedData: [string, string][] = [
    [
      'Start Date',
      data?.startDate ? formatDateMonthYear(parseISO(data.startDate)) : '',
    ],
    [
      'End Date',
      data?.endDate ? formatDateMonthYear(parseISO(data.endDate)) : '',
    ],
    ['Duration', displayMonth(projectDuration)],
  ];

  return (
    <Box
      sx={({ palette }) => ({
        backgroundColor: palette.common.white,
        p: 2,
      })}
    >
      {stackedData.map(([title, value], i) => (
        <KeyDatesSectionStacked key={i} title={title} value={value} />
      ))}
    </Box>
  );
};

const KeyDatesSectionStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
      }}
    >
      <DataTitle title={title} />

      <Typography fontSize={'0.8em'} fontWeight={600}>
        {value}
      </Typography>
    </Box>
  );
};

const DataTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="body1" fontWeight={'600'} fontSize={'0.8em'}>
      {title}
    </Typography>
  );
};
