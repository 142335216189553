import { Button, Grid, Typography } from '@mui/material';
import { useGetApplicationContactDetailsQuery } from 'api/application';
import { Loading } from 'common/components/loading';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IApplicationContactDetailsModalProps {
  onClose: () => void;
}

export function ApplicationContactDetailsModal({
  onClose,
}: IApplicationContactDetailsModalProps) {
  const { applicationId } = useApplicationContext();
  const { data, isLoading } =
    useGetApplicationContactDetailsQuery(applicationId);

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open fullWidth maxWidth="sm">
      <StyledDialogTitle>Applicant Contact Details</StyledDialogTitle>
      <StyledDialogContent>
        <Grid container textAlign="left">
          <Grid item xs={6}>
            <Typography variant="h3" fontWeight={600}>
              Applicant Email:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3">{data?.emailAddress}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3" fontWeight={600}>
              Applciant Phone:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3">{data?.phoneNumber}</Typography>
          </Grid>
        </Grid>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
