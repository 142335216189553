import { Box, Grid, TextField, Typography } from '@mui/material';
import { useGetCurrentUserQuery } from 'api/profile/profile';
import { ErrorAlert } from 'common/components/alerts';
import { FormSkeleton } from 'common/components/skeletons';
import { IMyUserProfile } from 'types/administration/AdministrationUsersTypes';
import { extractErrorMessages } from 'util/ApiUtils';

export const MyDetailsTab = () => {
  return (
    <Box maxWidth={1000}>
      <MyDetailsTabContent />
    </Box>
  );
};

const MyDetailsTabContent = () => {
  const { data, isLoading, error } = useGetCurrentUserQuery();

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (error) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (!data) {
    return (
      <ErrorAlert msg="Could not retrieve current user data. Please contact your system administrator." />
    );
  }

  return (
    <>
      <MyDetailsForm user={data} />
      <Box>
        <Typography variant="body1" mt={2}>
          For any changes, please contact your local administrator.
        </Typography>
      </Box>
    </>
  );
};

interface IMyDetailsFormProps {
  user: IMyUserProfile;
}

const MyDetailsForm = ({ user }: IMyDetailsFormProps) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={6}>
        <TextField
          label="First Name"
          defaultValue={user.firstName}
          variant="filled"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Surname"
          defaultValue={user.lastName}
          variant="filled"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Email Address"
          defaultValue={user.emailAddress}
          variant="filled"
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Role"
          defaultValue={user.role}
          variant="filled"
          fullWidth
          disabled
        />
      </Grid>
    </Grid>
  );
};
