import { rest } from 'msw';
import { applicationDocumentCategoriesData, applicationDocumentTypesData, applicationDocumentsData } from 'mocks/data/application/applicationDocumentData';

export const documentsHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/document/categories`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationDocumentCategoriesData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/document/types`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationDocumentTypesData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/document`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationDocumentsData));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/document`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.delete(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/document/:fileId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
