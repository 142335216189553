import { ComponentProps, useState } from 'react';
import { TextField } from '@mui/material';

// There is a longstanding bug with the standard Mui TextField component when trying to use a start icon
// See: https://github.com/mui/material-ui/issues/13898
// Basically the issue is as follows:
// If there is a start icon then the field starts off in `shrink` mode (label small and at the top) and doesn't show the placeholder text
// This is a workaround until the bug is fixed

export const TextFieldWithStartIcon = (
  props: ComponentProps<typeof TextField>
) => {
  const [shrink, setShrink] = useState(props.defaultValue ? true : false);
  return (
    <TextField
      {...props}
      sx={{
        '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
          transform: 'translate(35px, 8px)',
        },
      }}
      onFocus={e => {
        setShrink(true);
        if (props.onFocus) {
          props.onFocus(e);
        }
      }}
      onBlur={e => {
        !e.target.value && setShrink(false);
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
      InputLabelProps={{
        shrink: shrink,
      }}
    />
  );
};
