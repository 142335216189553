import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useGetWorksPackageCostsScheduleQuery } from 'api/application/worksPackageApi';
import { useCurrentUserPermissions, useLocalSnackbar } from 'hooks';
import { useApplicationProjectPrepContext } from 'pages/applicationPage/content/projectPrep/context';
import { IGetCostsScheduleResponse } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { ECostsScheduleSection } from 'types/applications/ApplicationCostScheduleTypes';

interface ICostsScheduleContext {
  sectionToEdit: ECostsScheduleSection | null;
  setSectionToEdit: (section: ECostsScheduleSection | null) => void;
  handleSuccessfulSave: () => void;
  canEditCostSchedule: boolean;
  data: IGetCostsScheduleResponse | undefined;
  hasPrevious: boolean;
}

const CostsScheduleContext = createContext<ICostsScheduleContext | undefined>(
  undefined
);

interface ICostsScheduleContextProviderProps {
  children: ReactNode;
}

export const CostsScheduleContextProvider = ({
  children,
}: ICostsScheduleContextProviderProps) => {
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const [sectionToEdit, setSectionToEdit] =
    useState<ECostsScheduleSection | null>(null);

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const canEditCostSchedule = doesUserHaveSinglePermission(
    'workpackage.costschedule.edit'
  );

  const handleSetSectionToEdit = useCallback(
    (section: ECostsScheduleSection | null) => {
      if (!canEditCostSchedule) {
        createErrorSnackbar(
          'You do not have permission to edit the Cost Schedule'
        );
        return;
      }
      setSectionToEdit(section);
    },
    [canEditCostSchedule, createErrorSnackbar]
  );

  const { applicationId } = useApplicationProjectPrepContext();
  const { data } = useGetWorksPackageCostsScheduleQuery(applicationId);
  const hasPrevious = Boolean(data?.previous);

  const value = useMemo(() => {
    const handleSuccessfulSave = () => {
      setSectionToEdit(null);
      createSuccessSnackbar(
        'You have successfully edited the works package cost schedule'
      );
    };
    return {
      sectionToEdit,
      setSectionToEdit: handleSetSectionToEdit,
      handleSuccessfulSave,
      canEditCostSchedule,
      data,
      hasPrevious,
    };
  }, [
    canEditCostSchedule,
    createSuccessSnackbar,
    data,
    handleSetSectionToEdit,
    hasPrevious,
    sectionToEdit,
  ]);

  return (
    <CostsScheduleContext.Provider value={value}>
      {children}
    </CostsScheduleContext.Provider>
  );
};

export const useCostsScheduleContext = () => {
  const context = useContext(CostsScheduleContext);
  if (context === undefined) {
    throw new Error(
      'useCostsScheduleContext must be used within an CostsScheduleContextProvider'
    );
  }
  return context;
};
