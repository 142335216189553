import { rest } from 'msw';
import {
  singleReferral,
  variationChecksData,
  variationCostScheduleData,
  variationOverviewData,
  variationReferralsData,
} from 'mocks/data/application/applicationLiveProjectVariationData';
import { EVariationType } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';

export const variationHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/overview`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.delay(500),
          ctx.status(200),
          ctx.json(variationOverviewData)
        );
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/costschedule`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.delay(500),
          ctx.status(200),
          ctx.json(variationCostScheduleData)
        );
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/checks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.delay(500),
          ctx.status(200),
          ctx.json(variationChecksData)
        );
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/checks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/approvalRejection`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/referToHomesEngland`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/referrals`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.delay(500),
          ctx.status(200),
          ctx.json(variationReferralsData)
        );
      }
      return req.passthrough();
    }
  ),

  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/thirdpartyreferral`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/thirdpartyreferral/:thirdPartyReferralId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200), ctx.json(singleReferral));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/thirdpartyreferral/:thirdPartyReferralId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/referToHomesEngland/:referralId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/thirdpartyreferral/:referralId/response`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/type`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/type/${EVariationType.Scope}`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/type/${EVariationType.Timescale}`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/variation/:variationId/reason`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
