import { useEffect, useState } from 'react';
import { SetupWorkerApi } from 'msw';
import { AuthProvider } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import AppWrapper from 'AppWrapper';
import { getOidcConfig } from 'util/AuthUtil';
import { installMuiLicense } from 'util/MuiLicenseKeyInstallation';

installMuiLicense();

export const App = () => {
  const [isPreparing, setIsPreparing] = useState<boolean>(true);
  const navigate = useNavigate();
  const isMock = process.env.REACT_APP_IS_STRICT_MOCKS === 'yes';

  const prepare = async () => {
    if (isMock) {
      // eslint-disable-next-line no-console
      console.info(
        '************************ SETTING UP MOCKS ************************'
      );
      await setupMSW();
      setIsPreparing(false);
      // eslint-disable-next-line no-console
      console.info(
        '************************ FINISHED MOCKS SETUP ************************'
      );
    } else {
      setIsPreparing(false);
    }
  };

  const setupMSW = async () => {
    const { worker } = require('./mocks/browser');
    await (worker as SetupWorkerApi).start();
    // eslint-disable-next-line no-console
    console.info('MSW Started');
  };

  useEffect(() => {
    const prepareApp = async () => {
      await prepare();
    };
    prepareApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isPreparing && (
        <AuthProvider
          {...getOidcConfig()}
          onSignIn={() => {
            navigate('/');
          }}
        >
          <AppWrapper />
        </AuthProvider>
      )}
    </>
  );
};
