import {
  IApplicationGrantFundingCategories,
  IApplicationGrantFundingCounterSignedChecks,
  IApplicationGrantFundingDetailsChecks,
  IApplicationGrantFundingSignedDocsChecks,
  IApplicationGrantFundingSummary,
} from 'types/applications/ApplicationGrantFundingTypes';
import { getGuid } from 'util/AppUtils';

export const applicationGrantFundingData: IApplicationGrantFundingCategories = {
  categories: [
    {
      categoryTitle: 'Grant Funding Checks',
      order: 1,
      detailItems: [
        {
          id: getGuid(),
          sectionName: 'Check GFA Details',
          lastUpdated: '2023-08-18',
          isComplete: true,
          order: 1,
        },
        {
          id: getGuid(),
          sectionName: 'Upload signed docs',
          lastUpdated: '2023-08-18',
          isComplete: false,
          order: 2,
        },
        {
          id: getGuid(),
          sectionName: 'Upload counter signed GFA',
          lastUpdated: '2023-08-14',
          isComplete: false,
          order: 3,
        },
      ],
    },
  ],
};

export const applicationGrantFundingSummaryData: IApplicationGrantFundingSummary =
  {
    responsibleEntityType: 'Responsible Entity Type',
    totalCladdingArea: 18,
    requestedPtfs: undefined,
    benchmarkFigure: 1234.99,
    isApplicantOneOfSignatories: true,
    bankDetails: [
      {
        accountNumber: '12345678',
        sortCode: '12-34-56',
        name: 'Bank Account Name',
      }
    ],
    responsibleEntityContacts: [
      {
        name: 'Responsible Entity Contacts 1',
        emailAddress: 'ResponsibleEntityContacts1@email.com',
      },
      {
        name: 'Responsible Entity Contacts 2',
        emailAddress: 'ResponsibleEntityContacts2@email.com',
      },
    ],
    secretarialEmail: 'secretarialEmail@email.com',
    companyName: 'Cladding Limited',
    companyRegistrationNumber: '8776785',
    companyRegisteredAddress: [
      '1',
       '23',
      'company address street',
      '',
      'Manchester',
      'Greater Manchester',
      'OX33 1ER',
    ],
    buildingAddress: [
      '2',
      '23',
      'Mock Street Name',
      '',
      'Oxford',
      'Oxfordshire',
      'OX33 1ER',
    ],
  landRegistryTitleNumber: '324545',
  ptsFundingAmount: 10000,
  additionalPtsFundingAmount: 20000,
  };

export const applicationGrantFundingDetailsChecksData: IApplicationGrantFundingDetailsChecks =
  {
    id: '1',
    signatoryDetailsAreSatisfactory: true,
    gfaFileId: '2',
    gfaUploadDate: new Date().toLocaleString(),
    gfa: {
      id: '2',
      name: 'Grant Funding',
      extension: 'pdf',
    },
    gfaSent: true,
    deedOfTrustRequired: true,
    deedOfTrustFileId: '2',
    deedOfTrustUploadDate: new Date().toLocaleString(),
    deedOfTrust: {
      id: '2',
      name: 'Deed Of Trust',
      extension: 'pdf',
    },
    deedOfTrustSent: true,
    validReasonForIssuesWithSignatoryDetails: false,
    validReasonForIssuesWithSignatoryDetailsReason:
      'Reason For Issues With Signatory Details Reason',
  };

export const applicationGrantFundingSignedDocsChecksData: IApplicationGrantFundingSignedDocsChecks =
  {
    id: '1',
    gfaSignedByAllSignatories: true,
    gfaNotSignedByAllSignatoriesReason:
      'Grant Funding Not Signed By All Signatories Reason',
    gfaSignedEvidenceFileId: '2',
    gfaSignedEvidenceUploadDate: new Date().toLocaleString(),
    gfaSignedEvidence: {
      id: '2',
      name: 'Grant Funding Signed Evidence',
      extension: 'pdf',
    },
    gfaResendAttempts: 2,
    gfaResent: false,
    deedOfTrustRequired: true,
    deedOfTrustSignedByAllSignatories: true,
    deedOfTrustNotSignedByAllSignatoriesReason:
      'Deed Of Trust Not Signed By All Signatories Reason',
    deedOfTrustSignedEvidenceFileId: '2',
    deedOfTrustSignedEvidenceUploadDate: new Date().toLocaleString(),
    deedOfTrustSignedEvidence: {
      id: '2',
      name: 'Deed Of Trust Signed Evidence',
      extension: 'pdf',
    },
    deedOfTrustResendAttempts: 2,
    deedOfTrustResent: true,
  };

export const applicationGrantFundingCounterSignedChecksData: IApplicationGrantFundingCounterSignedChecks =
  {
    id: '1',
    gfaCounterSigned: true,
    gfaCounterSignedEvidenceFileId: '2',
    gfaCounterSignedEvidenceUploadDate: new Date().toLocaleString(),
    gfaCounterSignedEvidence: {
      id: '2',
      name: 'Counter Signed Documentation',
      extension: 'pdf',
    },
    gfaResendAttempts: 2,
    gfaResent: false,
  };
