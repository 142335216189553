import { useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Control, Controller, useWatch } from 'react-hook-form';
import {
  IScheduleOfWorksCostItem,
  IScheduleOfWorksCosts,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { upfrontPercentageFieldItemId } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileSection';
import { hasDecimal } from 'util/AppUtils';
import { nameof } from 'util/formUtils';

const getName = (fieldName: keyof IScheduleOfWorksCosts) =>
  nameof<IScheduleOfWorksCosts>(fieldName);

const upfrontPercentageBounds = {
  lower: 1,
  upper: 15,
};

function checkUpfrontPercentageBounds(upfrontPercentage: number) {
  return (
    upfrontPercentage >= upfrontPercentageBounds.lower &&
    upfrontPercentage <= upfrontPercentageBounds.upper
  );
}

export const CostProfileUpfrontPercentageSection = ({
  onUpfrontPercentageChange,
  control,
  upfrontPercentageChecked,
  initialPaymentsFields,
  readOnly = false,
}: {
  onUpfrontPercentageChange: (toggleState: boolean, percentage: number) => void;
  control: Control<IScheduleOfWorksCosts, any>;
  upfrontPercentageChecked: boolean;
  initialPaymentsFields?: Array<IScheduleOfWorksCostItem> | null;
  readOnly?: boolean;
}) => {
  const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpfrontPercentageChange(
      event.target.checked,
      watchUpfrontPercentage ?? 10
    );
  };

  const watchUpfrontPercentage = useWatch({
    name: 'upfrontPercentage',
    control,
  });

  const applyUpfrontPercentage = useWatch({
    name: 'applyUpfrontPercentage',
    control,
  });

  const upfrontPercentageCost =
    initialPaymentsFields &&
    initialPaymentsFields.find(
      item => item.id === upfrontPercentageFieldItemId
    );

  useEffect(() => {
    if (!upfrontPercentageCost)
      onUpfrontPercentageChange(
        applyUpfrontPercentage,
        Number(watchUpfrontPercentage)
      );
    // We only want to run this effect if the upfrontPercentageCost is undefined,
    // if the other values change we don't need to re run this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upfrontPercentageCost]);

  return (
    <Grid
      container
      sx={{
        padding: 2,
        backgroundColor: 'grey.200',
      }}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle1" fontSize="1em" fontWeight={800}>
          Upfront Percentage
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Controller
          control={control}
          defaultValue={upfrontPercentageChecked}
          name={getName('applyUpfrontPercentage')}
          render={({ field: { value, onChange, ...fieldProps } }) => (
            <FormControl>
              <Switch
                checked={!!value}
                {...fieldProps}
                onChange={(event, val) => {
                  switchHandler(event);
                  return onChange(val);
                }}
                disabled={readOnly}
              ></Switch>
            </FormControl>
          )}
        />
        <label>Apply upfront percentage</label>
      </Grid>
      {applyUpfrontPercentage && (
        <Grid item xs={4}>
          <Box display="flex" alignItems="center" gap={3}>
            {
              <Controller
                control={control}
                name="upfrontPercentage"
                render={({ field: { onChange, ...fieldRest }, fieldState }) => (
                  <TextField
                    onChange={e => {
                      const upfrontPercentage = Number(e.currentTarget.value);
                      const isInBounds =
                        checkUpfrontPercentageBounds(upfrontPercentage);
                      // Break out early if not in bounds or the value has a decimal point
                      if (!isInBounds || hasDecimal(upfrontPercentage)) return;
                      onUpfrontPercentageChange(
                        true,
                        Number(e.currentTarget.value)
                      );
                      return onChange(e);
                    }}
                    {...fieldRest}
                    type="number"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disabled={readOnly}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                    sx={{
                      mb: 0,
                    }}
                  />
                )}
              />
            }
            {applyUpfrontPercentage && (
              <Typography>{`£${upfrontPercentageCost?.confirmedCost?.toLocaleString()}`}</Typography>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
