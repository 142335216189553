import { applicationApi } from 'api/application/applicationApi';
import {
  IGetProgressReportQueryParams,
  IGetProjectPrepListResponse,
  IProjectPrepOverview,
  IProjectPrepProgressReport,
} from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';
import { QueryTags } from 'util/ApiUtils';

export const projectPrepEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getProjectPrepOverview: builder.query<IProjectPrepOverview, string>({
      providesTags: [QueryTags.ApplicationProjectPrepOverview],
      query: applicationId => `${applicationId}/projectprep/overview`,
      keepUnusedDataFor: 0,
    }),
    getProjectPrepReportList: builder.query<
      IGetProjectPrepListResponse,
      string
    >({
      providesTags: [QueryTags.ApplicationProjectPrepList],
      query: applicationId => `${applicationId}/projectprep/list`,
      keepUnusedDataFor: 0,
    }),
    getProgressReport: builder.query<
      IProjectPrepProgressReport,
      IGetProgressReportQueryParams
    >({
      providesTags: [QueryTags.ApplicationProjectPrepProgressReport],
      query: ({ applicationId, progressReportId }) =>
        `${applicationId}/progressreport/${progressReportId}`,
    }),
  }),
});

export const {
  useGetProjectPrepOverviewQuery,
  useGetProjectPrepReportListQuery,
  useGetProgressReportQuery,
} = projectPrepEndpoints;
