import { useMemo } from 'react';
import { Typography, Chip, Divider, Box, Button } from '@mui/material';
import { useGetPullInProcessContactsQuery } from 'api/pullInProcess';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { EPullInProcessContactTypeLanguage } from 'enums/EPullInProcessContactType';
import { useModalState } from 'hooks/useModalState';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { AddEditContact } from 'pages/pullInProcessPage/components/overview/addEditContact';

type BoxWrapperProps = {
  render: JSX.Element;
};

function BoxWrapper({ render }: BoxWrapperProps) {
  return <Box mt="0.5rem">{render}</Box>;
}

export function DefaultContactDetailsPanel() {
  const modalState = useModalState<string | null>();
  const { showModal } = modalState;

  const { pullInProcessId } = usePullInProcessContext();
  const { data, isSuccess } = useGetPullInProcessContactsQuery(pullInProcessId);

  const defaultContact = useMemo(
    () => data?.find(contact => contact.isDefaultContact),
    [data]
  );

  return (
    <>
      {isSuccess && defaultContact ? (
        <RoundBorderBox borderRadius="8px" p="1rem">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography component="h3" variant="h3" fontWeight="600" m={0}>
              Contact Details
            </Typography>
            <Button
              variant="outlined"
              onClick={() => showModal(defaultContact.id)}
            >
              Edit
            </Button>
          </Box>
          <BoxWrapper render={<Typography color="GrayText">Name</Typography>} />
          <BoxWrapper
            render={
              <Box display="flex" alignItems="center" gap="0.5rem">
                <Typography>{`${defaultContact.firstName} ${defaultContact.surname}`}</Typography>
                <Chip size="small" label="Default Contact" />
              </Box>
            }
          />
          <BoxWrapper render={<Divider />} />
          <BoxWrapper
            render={<Typography color="GrayText">Company</Typography>}
          />
          <BoxWrapper
            render={
              <Typography>{`${defaultContact.companyName} ${
                defaultContact.companyNumber
                  ? `(${defaultContact.companyNumber})`
                  : ''
              }`}</Typography>
            }
          />
          <BoxWrapper render={<Divider />} />
          <BoxWrapper
            render={<Typography color="GrayText">Contact Type</Typography>}
          />
          <BoxWrapper
            render={
              <Typography>
                {EPullInProcessContactTypeLanguage[defaultContact.contactType]}
              </Typography>
            }
          />
        </RoundBorderBox>
      ) : null}
      <AddEditContact modalState={modalState} />
    </>
  );
}
