import { Alert, SxProps } from '@mui/material';

export const WarningAlert = ({
  isShow = true,
  sx,
  alertText,
}: {
  isShow?: boolean;
  sx?: SxProps;
  alertText: string;
}) => {
  return isShow ? (
    <Alert severity="warning" sx={{ m: 2, ...sx }}>
      {alertText}
    </Alert>
  ) : null;
};
