import * as React from 'react';
import { TabPanelProps } from '@mui/lab';
import { Box } from '@mui/material';

interface CustomTabPanelProps extends TabPanelProps {
  currentSelectedTab: string;
}

export function CustomTabPanel(props: CustomTabPanelProps) {
  const { children, value, currentSelectedTab, ...rest } = props;

  if (currentSelectedTab !== value) return null;

  return (
    <Box
      role="tabpanel"
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
    >
      {children}
    </Box>
  );
}
