import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  buildingRecordsAddressApi,
  useAddBuildingRecordMutation,
  useGetPullInProcessesSourceOfInformationQuery,
  useLazyGetAddressByPostCodeQuery,
} from 'api/pullInProcess';
import { useLocalSnackbar } from 'hooks';
import { BuildingRecordExistingAddresses } from 'pages/pullInProcessesPage/components/buildingRecordExistingAddresses';
import { useAppDispatch } from 'state';
import { zAddBuildingRecordSchema } from 'types/pullInProcess/addBuildingRecord.zod';
import {
  TAddBuildingRecord,
  TPostPutBuildingRecord,
} from 'types/pullInProcess/addBuildingRecordTypes';
import { extractErrorMessages } from 'util/ApiUtils';
import { stringAppendWithValueOrEmpty } from 'util/stringAppendWithValueOrEmpty';

interface BuildingRecordFormProps {
  formId: string;
  hideModal: () => void;
}

const inputTitleBorderHeightClearance = '6px';

export const BuildingRecordForm = forwardRef<
  ReturnType<typeof useForm<TAddBuildingRecord>>,
  BuildingRecordFormProps
>(({ formId, hideModal }, ref) => {
  const dispatch = useAppDispatch();
  const form = useForm<TAddBuildingRecord>({
    resolver: zodResolver(zAddBuildingRecordSchema),
  });
  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = form;
  useImperativeHandle(ref, () => form, [form]);

  const [isManualUpdateAddress, setIsManualUpdateAddress] = useState(false);
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const [getAddressByPostCode, { data }] = useLazyGetAddressByPostCodeQuery();

  const {
    data: sourceInformationData,
    isSuccess: sourceInfoLoadedSuccessfully,
  } = useGetPullInProcessesSourceOfInformationQuery();

  const [postBuildingRecord] = useAddBuildingRecordMutation();

  const postCodeInput = watch('address.postCode');
  const sourceInformation = watch('sourceOfInformation');

  const [addressDropDownValue, setAddressDropDownValue] = useState<
    number | string
  >('');

  function handlePostCodeFetch() {
    getAddressByPostCode({ postCode: postCodeInput, isBuildingLookup: true });
  }

  function onSubmit(formData: TAddBuildingRecord) {
    function mapToPostFormData({
      sourceOfInformation,
      ...formDataToSubmit
    }: TAddBuildingRecord): TPostPutBuildingRecord {
      const formData: TPostPutBuildingRecord = {
        ...formDataToSubmit,
        sourceOfInformationId: sourceOfInformation?.id,
      };
      return formData;
    }
    const formDataToSubmit = mapToPostFormData(formData);
    postBuildingRecord(formDataToSubmit)
      .then(() => createSuccessSnackbar(`New building created`))
      .catch(error => createErrorSnackbar(extractErrorMessages(error)))
      .finally(() => hideModal());
  }

  useEffect(() => {
    const { address } = errors;
    if (address?.localAuthority && !isManualUpdateAddress) {
      setIsManualUpdateAddress(true);
    }
  }, [errors, errors.address?.localAuthority, isManualUpdateAddress]);

  return (
    <Box pt={inputTitleBorderHeightClearance}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Typography fontWeight={600} mb="1rem">
          Building details
        </Typography>
        <Box display="flex" gap="1rem">
          <Controller
            control={control}
            name="address.postCode"
            defaultValue=""
            render={({ field: { onChange, ...fieldRest } }) => (
              <TextField
                fullWidth
                {...fieldRest}
                onChange={event => {
                  if (data?.addresses.length) {
                    dispatch(buildingRecordsAddressApi.util.resetApiState());
                    reset();
                  }
                  onChange(event);
                }}
                label="Postcode (required)"
                error={!!errors.address?.postCode}
                helperText={errors.address?.postCode?.message}
              />
            )}
          />
          <Button
            variant="outlined"
            sx={{ minWidth: '160px', minHeight: '56px' }}
            disabled={!postCodeInput}
            onClick={() => handlePostCodeFetch()}
          >
            Find Postcode
          </Button>
        </Box>
        {data?.addresses ? (
          <TextField
            fullWidth
            select
            label="Address"
            defaultValue=""
            value={
              typeof addressDropDownValue === 'number'
                ? addressDropDownValue
                : ''
            }
            onChange={(...args) => {
              const event = args[0];
              const idx = Number(event.target.value);

              if (!Number.isNaN(idx)) {
                setAddressDropDownValue(idx);
                const address = data.addresses[idx];
                setValue('address', address, { shouldValidate: true });
              } else setAddressDropDownValue('');

              return args;
            }}
          >
            {data.addresses.length > 0 ? (
              data.addresses.map((address, idx) => (
                <MenuItem key={idx} value={idx}>
                  {`${stringAppendWithValueOrEmpty(
                    address.buildingNameNumber,
                    ','
                  )} 
                  ${stringAppendWithValueOrEmpty(
                    address.line1,
                    ','
                  )} ${stringAppendWithValueOrEmpty(
                    address.line2,
                    ','
                  )} ${stringAppendWithValueOrEmpty(
                    address.townCity,
                    ','
                  )} ${stringAppendWithValueOrEmpty(address.postCode, '')}`}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={'noResult'}>No results found</MenuItem>
            )}
          </TextField>
        ) : null}
        <>
          <Button
            type="button"
            variant="text"
            sx={{ p: 0, height: 'auto', mb: '1rem' }}
            onClick={() =>
              setIsManualUpdateAddress(previousState => !previousState)
            }
          >
            <Typography component="u">Enter address manually</Typography>
          </Button>
          <Collapse in={isManualUpdateAddress} timeout={500}>
            <>
              <Controller
                control={control}
                name="address.buildingNameNumber"
                defaultValue=""
                render={({ field: { onChange, ...fieldRest } }) => (
                  <TextField
                    fullWidth
                    onChange={(...args) => {
                      setAddressDropDownValue('');
                      return onChange(...args);
                    }}
                    {...fieldRest}
                    label="Address building name number"
                    error={!!errors.address?.buildingNameNumber}
                    helperText={errors.address?.buildingNameNumber?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="address.line1"
                defaultValue=""
                render={({ field: { onChange, ...fieldRest } }) => (
                  <TextField
                    fullWidth
                    onChange={(...args) => {
                      setAddressDropDownValue('');
                      return onChange(...args);
                    }}
                    {...fieldRest}
                    label="Address line 1"
                    error={!!errors.address?.line1}
                    helperText={errors.address?.line1?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="address.line2"
                defaultValue=""
                render={({ field: { onChange, ...fieldRest } }) => (
                  <TextField
                    fullWidth
                    onChange={(...args) => {
                      setAddressDropDownValue('');
                      return onChange(...args);
                    }}
                    {...fieldRest}
                    label="Address line 2"
                    error={!!errors.address?.line2}
                    helperText={errors.address?.line2?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="address.townCity"
                defaultValue=""
                render={({ field: { onChange, ...fieldRest } }) => (
                  <TextField
                    fullWidth
                    onChange={(...args) => {
                      setAddressDropDownValue('');
                      return onChange(...args);
                    }}
                    {...fieldRest}
                    label="Town/City"
                    error={!!errors.address?.townCity}
                    helperText={errors.address?.townCity?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="address.county"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    label="County"
                    error={!!errors.address?.county}
                    helperText={errors.address?.county?.message}
                  />
                )}
              />

              <Controller
                key={isManualUpdateAddress.toString()}
                control={control}
                name="address.country"
                defaultValue={{ id: 1, name: 'United Kingdom' }}
                render={({ field: { onChange, ...fieldRest } }) => {
                  return (
                    <Autocomplete
                      disabled
                      options={[{ id: 1, name: 'United Kingdom' }]}
                      getOptionLabel={option => option.name ?? ''}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value?.id;
                      }}
                      value={{ id: 1, name: 'United Kingdom' }}
                      onChange={(_, value) => {
                        setAddressDropDownValue('');
                        onChange(value);
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          disabled
                          label="Country"
                          error={!!errors.address?.country}
                          helperText={errors.address?.country?.message}
                        />
                      )}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name="address.localAuthority"
                defaultValue=""
                render={({ field: { onChange, ...fieldRest } }) => (
                  <TextField
                    fullWidth
                    onChange={(...args) => {
                      setAddressDropDownValue('');
                      return onChange(...args);
                    }}
                    {...fieldRest}
                    label="Local Authority (required)"
                    error={!!errors.address?.localAuthority}
                    helperText={errors.address?.localAuthority?.message}
                  />
                )}
              />
            </>
          </Collapse>
          <BuildingRecordExistingAddresses data={data} />
        </>

        <Divider sx={{ mb: '1rem' }} />
        {sourceInfoLoadedSuccessfully && (
          <>
            <Controller
              control={control}
              name="sourceOfInformation"
              defaultValue={undefined}
              render={({ field: { onChange, ...fieldRest } }) => {
                return (
                  <Autocomplete
                    options={sourceInformationData}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...fieldRest}
                        label="Source of information (required)"
                        error={!!errors.sourceOfInformation}
                        helperText={errors.sourceOfInformation?.message}
                      />
                    )}
                  />
                );
              }}
            />
            <Collapse in={sourceInformation?.hasFreeText} unmountOnExit>
              <Controller
                control={control}
                name="sourceOfInformationOther"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    label="Source of information other (required)"
                    error={!!errors.sourceOfInformationOther}
                    helperText={errors.sourceOfInformationOther?.message}
                  />
                )}
              />
            </Collapse>
          </>
        )}
      </form>
    </Box>
  );
});
