import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  useEditRiskSafetyMitigationMutation,
  useGetRiskSafetyMitigationQuery,
} from 'api/application/applicationEditApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ERiskSafetyMitigationType } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { FieldLabel } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IdName } from 'types/GeneralTypes';
import { zRiskSafetyMitigationSchema } from 'types/applications/ApplicationEdit.zod';
import { IRiskSafetyMitigationForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditRiskSafetyMitigationDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditRiskSafetyMitigationDrawer = ({
  onClose,
  onSuccess,
}: IEditRiskSafetyMitigationDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit What are the potential courses of action for
            remediation/mitigation to address the life safety risk of the
            building?
          </Typography>
        </DialogTitle>
        <EditRiskSafetyMitigationForm onClose={onClose} onSuccess={onSuccess} />
      </Box>
    </StyledDrawer>
  );
};

interface IEditRiskSafetyMitigationFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditRiskSafetyMitigationForm = ({
  onClose,
  onSuccess,
}: IEditRiskSafetyMitigationFormProps) => {
  const { applicationId } = useApplicationContext();

  const { data, isLoading } = useGetRiskSafetyMitigationQuery(applicationId);

  const [editRiskSafetyMitigation, editRiskSafetyMitigationResult] =
    useEditRiskSafetyMitigationMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IRiskSafetyMitigationForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zRiskSafetyMitigationSchema),
  });
  const { handleSubmit, reset, control, setValue, watch } = form;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const onSubmit = async (formData: IRiskSafetyMitigationForm) => {
    try {
      formData.applicationId = applicationId;
      await editRiskSafetyMitigation(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar(`Potential courses of action for
          remediation/mitigation updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit potential courses of action for
      remediation/mitigation`);
    }
  };

  const options: IdName<ERiskSafetyMitigationType>[] = [
    {
      id: ERiskSafetyMitigationType.CompleteCladdingReplacement,
      name: 'Complete removal and replacement of the unique cladding system or systems',
    },
    {
      id: ERiskSafetyMitigationType.DontKnow,
      name: "Don't know",
    },
    {
      id: ERiskSafetyMitigationType.EncapsulationOfCombustibles,
      name: 'Encapsulation of the combustible materials present in the cladding system',
    },
    {
      id: ERiskSafetyMitigationType.FillingCavaties,
      name: 'Filling cavities with non-combustible insulation',
    },
    {
      id: ERiskSafetyMitigationType.InstallationOfFireAndSmokeAlarms,
      name: 'Installation or upgrades to fire alarms and or smoke detection',
    },
    {
      id: ERiskSafetyMitigationType.Other,
      name: 'Other',
    },
    {
      id: ERiskSafetyMitigationType.PartialCladdingReplacement,
      name: 'Partial removal and replacement of the unique cladding system or systems',
    },
    {
      id: ERiskSafetyMitigationType.WorksToFireBarriers,
      name: 'Works to Fire Barriers',
    },
    {
      id: ERiskSafetyMitigationType.WorksToImproveAccess,
      name: 'Works to improve access and facilities for the fire service',
    },
    {
      id: ERiskSafetyMitigationType.WorksToInternalCompartmentation,
      name: 'Works to internal compartmentation',
    },
    {
      id: ERiskSafetyMitigationType.WorksToMeansOfEscape,
      name: 'Works to means of escape',
    },
    {
      id: ERiskSafetyMitigationType.WorksToSmokeExtraction,
      name: 'Works to smoke extraction',
    },
  ];

  return isLoading ? (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} mb={2}>
              <FieldLabel label="What are the potential courses of action for remediation/mitigation to address the life safety risk of the building? Please note that any works that are not the removal of unsafe cladding will only be funded if they are proportional mitigation works to allow unsafe cladding to remain in place" />
              <Controller
                control={control}
                name="riskSafetyMitigation"
                render={({
                  fieldState,
                  field: { value, onChange, ...fieldProps },
                }) => (
                  <Autocomplete
                    {...fieldProps}
                    onChange={(_, val) => {
                      onChange(val);
                      if (!val.includes(ERiskSafetyMitigationType.Other)) {
                        setValue('otherRiskSafteyMitigation', '');
                      }
                    }}
                    value={value}
                    options={options.map(({ id }) => id)}
                    getOptionLabel={opt =>
                      options.find(o => o.id === opt)?.name || ''
                    }
                    multiple
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            {watch('riskSafetyMitigation')?.includes(
              ERiskSafetyMitigationType.Other
            ) ? (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="otherRiskSafteyMitigation"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      label="Please enter the other potential course of action"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editRiskSafetyMitigationResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
