import { useGetCurrentUserQuery } from 'api/profile/profile';
import { ETeam } from 'enums/ETeam';

export const useCurrentUser = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const isHeUser =
    currentUser?.teamId === ETeam.HomesEnglandOperations ?? false;
  const paymentApprovalLevel = currentUser?.paymentApprovalLevel;

  const name = currentUser
    ? `${currentUser.firstName} ${currentUser.lastName}`.trim()
    : '';
  const initials = name
    .split(' ')
    .map(n => (n.length ? n[0] : ''))
    .join('');

  return { currentUser, isHeUser, name, initials, paymentApprovalLevel };
};
