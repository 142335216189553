import { applicationApi } from 'api/application';
import { IPaymentRequestReferral } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { useAppDispatch } from 'state';
import { EReferralType } from 'types/applications/ReferralTypes';
import { QueryTags } from 'util/ApiUtils';

export const useInvalidatePaymentRequestReferrals = () => {
  const dispatch = useAppDispatch();

  const invalidatePaymentRequestReferrals = () => {
    dispatch(
      applicationApi.util.invalidateTags([QueryTags.ApplicationPaymentRequest])
    );
  };

  return { invalidatePaymentRequestReferrals };
};

export const mapPaymentRequestHeReferrals = (
  heReferrals: IPaymentRequestReferral[]
) => {
  return heReferrals.map(heReferral => {
    return {
      id: heReferral.id,
      status: heReferral.status,
      type: EReferralType.HomesEngland,
    };
  });
};
