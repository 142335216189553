import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetThirdPartyReferralQuery } from 'api/application/referralApi';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  EReferralType,
  EReferralRecordType,
} from 'types/applications/ReferralTypes';

type ThirdPartyReferralConfig =
  | {
      referralRecordType: EReferralRecordType.WorksPackage;
    }
  | {
      referralRecordType: EReferralRecordType.PaymentRequest;
      paymentRequestId: string;
    }
  | {
      referralRecordType: EReferralRecordType.Variation;
      variationId: string;
    };

export const useThirdPartyReferralParams = (
  thirdPartyReferralId: string,
  referralType: EReferralType | undefined,
  props: ThirdPartyReferralConfig
) => {
  const { applicationId } = useApplicationContext();
  if (!thirdPartyReferralId || referralType !== EReferralType.ThirdParty) {
    return skipToken;
  }

  const { referralRecordType } = props;
  switch (referralRecordType) {
    case EReferralRecordType.WorksPackage:
      return {
        type: referralRecordType,
        applicationId,
        thirdPartyReferralId,
      };

    case EReferralRecordType.Variation:
      return {
        type: referralRecordType,
        applicationId,
        thirdPartyReferralId,
        variationId: props.variationId,
      };

    case EReferralRecordType.PaymentRequest:
      return {
        type: referralRecordType,
        applicationId,
        thirdPartyReferralId,
        paymentRequestId: props.paymentRequestId,
      };

    default:
      throw new Error(
        `Could not get referral params using type: ${referralRecordType}`
      );
  }
};

export const useThirdPartyReferralQuery = (
  thirdPartyReferralId: string,
  referralType: EReferralType | undefined,
  config: ThirdPartyReferralConfig
) => {
  const thirdPartyParamsOrSkipToken = useThirdPartyReferralParams(
    thirdPartyReferralId,
    referralType,
    config
  );

  const query = useGetThirdPartyReferralQuery(thirdPartyParamsOrSkipToken);
  return { query };
};
