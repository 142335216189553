import { useGetApplicationHoldLatestQuery } from 'api/application/applicationHoldApi';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  EHoldStatus,
  EOnHoldType,
} from 'types/applications/ApplicationHoldTypes';

export const useOnOffHold = ({ holdType }: { holdType: EOnHoldType }) => {
  const { applicationId } = useApplicationContext();
  const query = useGetApplicationHoldLatestQuery(applicationId);

  const { data } = query;
  const isOnHoldForThisType =
    data?.holdStatus === EHoldStatus.OnHold && data?.holdType === holdType;

  return {
    isOnHoldForThisType,
  };
};
