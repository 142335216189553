import { useReducer } from 'react';
import { ESortDirection } from 'enums/ESortDirection';
import {
  IGetEligibilityPepSanctionDirectorsPaginatedQuery,
  IPEPSanctionsDirectorsListItem,
} from 'types/applications/ApplicationEligibilityTypes';
import { nameof } from 'util/formUtils';

interface IPepSanctionDirectorsState {
  gridState: IGetEligibilityPepSanctionDirectorsPaginatedQuery;
}

const initialState: IPepSanctionDirectorsState = {
  gridState: {
    skip: 0,
    take: 25,
    searchValue: null,
    sortBy: nameof<IPEPSanctionsDirectorsListItem>('name'),
    sortDirection: ESortDirection.Asc,
  },
};

type ActionType = {
  type: 'gridStateChanged';
  payload: Partial<IGetEligibilityPepSanctionDirectorsPaginatedQuery>;
};

const reducer = (state: typeof initialState, action: ActionType) => {
  switch (action.type) {
    case 'gridStateChanged':
      return { gridState: { ...state.gridState, ...action.payload } };

    default:
      throw new Error('invalid action.type');
  }
};

export const usePepSanctionsGridState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridStateChanged = (newGridState: ActionType['payload']) => {
    dispatch({ type: 'gridStateChanged', payload: newGridState });
  };

  return { state, dispatch, gridStateChanged };
};
