import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { differenceInMonths, parseISO } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateVariationTimescaleMutation } from 'api/application/variationApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { CardTitle } from 'common/components/cardTitle';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { useLocalSnackbar, useModalState } from 'hooks';
import { IEditVariationTimescaleForm } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { useApplicationAndVariationIdOrNull } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { getDateOnlyIsoString, isValidDate } from 'util/DateUtils';

const _formId = 'edit-timescale-form';

export const useEditTimescaleDrawer = ({
  initialData,
  readonlyValues,
}: {
  initialData: IEditVariationTimescaleForm;
  readonlyValues: {
    completionDateCurrent: string | undefined;
    projectDurationMonthsVaried: number | undefined;
    projectDurationMonthsVariance: number | undefined;
  };
}) => {
  const queryParams = useApplicationAndVariationIdOrNull();

  const {
    isShowing,
    showModal: showDrawer,
    hideModal: hideDrawer,
  } = useModalState();

  const form = useForm<IEditVariationTimescaleForm>({
    defaultValues: initialData,
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
    reset,
  } = form;

  const { createErrorSnackbar, createSuccessSnackbar } = useLocalSnackbar();

  const handleShowDrawer = () => {
    showDrawer();
  };

  const handleCancelClick = () => {
    hideDrawer();
    reset();
  };

  const [update, updateStatus] = useUpdateVariationTimescaleMutation();

  const onSubmit = async (formData: IEditVariationTimescaleForm) => {
    if (!queryParams) {
      createErrorSnackbar(
        'Could not get variation data. Please try again later.'
      );
      return;
    }

    update({
      ...queryParams,
      formData,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar(
          'The timescale changes have been successfully edited'
        );
        hideDrawer();
        reset(formData);
      });
  };

  const renderDrawer = () => {
    if (!isShowing) {
      return null;
    }

    const watchEndDate = watch('dateEnd');

    const getNewVariance =
      readonlyValues.completionDateCurrent && watchEndDate
        ? differenceInMonths(
            parseISO(watchEndDate),
            parseISO(readonlyValues.completionDateCurrent)
          ) + 1
        : 0;

    const originalDurationInMonths =
      readonlyValues?.projectDurationMonthsVaried ?? 0;
    const originalVarianceValue =
      readonlyValues.projectDurationMonthsVariance ?? 0;

    const durationValueDisplay = isDirty
      ? originalDurationInMonths - originalVarianceValue + getNewVariance
      : originalDurationInMonths;

    const varianceValueDisplay = isDirty
      ? getNewVariance
      : originalVarianceValue;

    return (
      <>
        <StyledDrawer anchor="right" open={isShowing}>
          <Box>
            <DialogTitle component="div">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h1" component="span">
                  Edit dates
                </Typography>
                <IconButton
                  onClick={handleCancelClick}
                  aria-label="Close Drawer"
                  name="Close Drawer"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Stack gap={2}>
                <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
                  <Grid container mt={1} spacing={2}>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="MM/yyyy"
                          label="Start Date"
                          disabled
                          value={
                            readonlyValues.completionDateCurrent &&
                            readonlyValues.completionDateCurrent !== ''
                              ? parseISO(readonlyValues.completionDateCurrent)
                              : null
                          }
                          slotProps={{
                            textField: {
                              fullWidth: true,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="dateEnd"
                        rules={{
                          required: 'Required',
                        }}
                        render={({
                          field: { onChange, value, ...field },
                          fieldState,
                        }) => {
                          return (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                {...field}
                                format="MM/yyyy"
                                label="End Date"
                                views={['month', 'year']}
                                value={
                                  value && value !== '' ? parseISO(value) : null
                                }
                                onChange={val => {
                                  if (isValidDate(val)) {
                                    onChange(getDateOnlyIsoString(val));
                                  } else {
                                    onChange(null);
                                  }
                                }}
                                slotProps={{
                                  textField: {
                                    error: !!fieldState.error,
                                    helperText: fieldState.error?.message,
                                    fullWidth: true,
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          );
                        }}
                      />

                      <AlertForQueryErrorOrNull
                        isError={updateStatus.isError}
                        error={updateStatus.error}
                      />
                    </Grid>
                  </Grid>
                </form>

                <RoundBorderBox sx={{ p: 3 }}>
                  <CardTitle title="Timescale changes" sx={{ mb: 2 }} />
                  <Grid container>
                    <Grid item xs={6}>
                      <Item label="Duration" value={durationValueDisplay} />
                    </Grid>
                    <Grid item xs={6}>
                      <Item label="Variance" value={varianceValueDisplay} />
                    </Grid>
                  </Grid>
                </RoundBorderBox>
              </Stack>
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={handleCancelClick}>
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  form={_formId}
                  loading={updateStatus.isLoading}
                  disabled={!isDirty}
                >
                  Update
                </LoadingButton>
              </Grid>
            </StyledDrawerActions>
          </Box>
        </StyledDrawer>
      </>
    );
  };

  return {
    showDrawer: handleShowDrawer,
    renderDrawer,
  };
};

const Item = ({ label, value }: { label: string; value: number | string }) => {
  return (
    <Stack>
      <Typography color={'grey.500'} fontSize={'0.9em'}>
        {label}
      </Typography>
      <Typography>
        {value} {value === 1 ? 'month' : 'months'}
      </Typography>
    </Stack>
  );
};
