import { z } from 'zod';
import { applicationIdRegex } from 'util/formUtils';

export const zProgressApplicationFormSchema = z.object({
  applicationRef: z
    .string({ required_error: 'Application id is required' })
    .regex(
      applicationIdRegex,
      'Application id must be 3 characters followed by an 7 digit number, e.g. APP1234567'
    )
    .min(1, 'Application id is required'),
});
export const zNotEligibleFormSchema = z.object({
  notEligibleReasonId: z
    .number({ required_error: 'Reason is required' })
    .min(1, 'Reason is required'),
  description: z
    .string({ required_error: 'Description is required' })
    .min(1, 'Description is required'),
});
export const zDuplicateFormSchema = z.object({
  reason: z
    .string({ required_error: 'Reason is required' })
    .min(1, 'Reason is required'),
});
