import { Box } from '@mui/material';
import { CommunicationDetails } from 'pages/pullInProcessPage/components/communications';

export function PullInProcessCommunications() {
  return (
    <Box>
      <CommunicationDetails />
    </Box>
  );
}
