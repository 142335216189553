import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'state';

export const selectAdministrationUserSelectorsRoot = (state: AppState) =>
  state.administration.users;

export const selectIsAdministrationUsersGridLoading = createSelector(
  [selectAdministrationUserSelectorsRoot],
  adminUsers => adminUsers.isGridLoading
);

export const selectAdministrationUsersGridState = createSelector(
  [selectAdministrationUserSelectorsRoot],
  adminUsers => adminUsers.gridState
);
