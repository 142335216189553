import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  Control,
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  useAddScheduleOfWorksReferralMutation,
  useApproveScheduleOfWorksMutation,
  useEditScheduleOfWorksChecksMutation,
  useGetScheduleOfWorksChecksQuery,
} from 'api/application/scheduleOfWorksApi';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
  WarningAlert,
} from 'common/components/alerts';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { useReferToHomesEnglandModal } from 'common/components/referToHomesEnglandModal';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import {
  EScheduleOfWorksStatus,
  EScheduleOfWorksStatusLanguage,
} from 'enums/EScheduleOfWorksStatus';
import { useInvalidateTasks, useLocalSnackbar, useModalState } from 'hooks';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import {
  EScheduleOfWorksChecksAnswer,
  EScheuduleOfWorksStatus,
  IScheduleOfWorksChecks,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { ScheduleOfWorksKpiSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/common';
import { useScheduleOfWorksContext } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/context';
import {
  ApproveScheduleOfWorksModal,
  ApprovedScheduleOfWorksBanner,
  ScheduleOfWorksChecksSectionInfoPanel,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/ScheduleOfWorksChecksSection';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { extractErrorMessages } from 'util/ApiUtils';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IScheduleOfWorksChecks) =>
  nameof<IScheduleOfWorksChecks>(fieldName);

export const ScheduleOfWorksChecksSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const { currentStatus } = useScheduleOfWorksContext();

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        scheduleOfWorksId: selectedItem.id,
      }
    : skipToken;

  const query = useGetScheduleOfWorksChecksQuery(request);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData = {
      ...data,
    };

    return (
      <RoundBorderBox>
        <Box sx={{ padding: 2, borderBottom: '1px solid lightgray' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h3" fontWeight={800} fontSize="1.3em">
              Confirm schedule of works
            </Typography>

            <Box gap={2} display="flex">
              <Typography variant="body1" color="grey.800" fontSize="0.9em">
                Status:
              </Typography>
              <Chip
                label={EScheduleOfWorksStatusLanguage[query.data.status]}
                color={
                  query.data.status === EScheduleOfWorksStatus.Approved
                    ? 'success'
                    : 'warning'
                }
                variant={
                  query.data.status === EScheduleOfWorksStatus.Approved
                    ? 'filled'
                    : 'outlined'
                }
              />
            </Box>
          </Box>
        </Box>

        <Stack p={2} rowGap={2}>
          <ScheduleOfWorksKpiSection />
          {currentStatus === EScheuduleOfWorksStatus.Approved && (
            <ApprovedScheduleOfWorksBanner />
          )}
          <ScheduleOfWorksChecksFormMain initialData={initialData} />
        </Stack>
      </RoundBorderBox>
    );
  }

  return null;
};

const ScheduleOfWorksChecksFormMain = ({
  initialData,
}: {
  initialData: IScheduleOfWorksChecks;
}) => {
  const { applicationId, readOnly } = useScheduleOfWorksContext();
  const { selectedItem } = useApplicationLiveProjectContext();

  const [editScheduleOfWorksChecks, editScheduleOfWorksChecksStatus] =
    useEditScheduleOfWorksChecksMutation();

  const [approveScheduleOfWorks] = useApproveScheduleOfWorksMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IScheduleOfWorksChecks>({
    defaultValues: initialData,
  });

  const {
    reset,
    getValues,
    watch,
    trigger,
    formState: { isDirty, isSubmitting },
  } = form;

  const {
    isShowing: isApproveScheduleOfWorksModalShowing,
    showModal: showApproveScheduleOfWorksModalShowing,
    hideModal: hideApproveScheduleOfWorksModalShowing,
  } = useModalState();

  const [refer, referStatus] = useAddScheduleOfWorksReferralMutation();

  const {
    showReferToHomesEnglandModal,
    hideReferToHomesEnglandModal,
    renderReferToHomesEnglandModal,
  } = useReferToHomesEnglandModal();

  const { invalidateTasks } = useInvalidateTasks();

  const handleReferToHeConfirmClick = async (referralComments: string) => {
    if (!selectedItem?.id) {
      createErrorSnackbar(
        "Couldn't progress refer to HE. No selected id found"
      );
      return;
    }

    await refer({
      applicationId,
      scheduleOfWorksId: selectedItem.id,
      reason: referralComments,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Schedule Of Works successfully referred.');
        invalidateTasks();
        hideReferToHomesEnglandModal();
      })
      .catch(error => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  const onSubmit = async (approve: boolean) => {
    if (!selectedItem?.id) {
      createErrorSnackbar("Couldn't submit. No selected id found");
      return;
    }

    const isValid = await trigger();
    if (!isValid && approve) {
      createErrorSnackbar('Please complete all fields and try again');
      return;
    }

    try {
      const formData = getValues();
      formData.id = applicationId;
      formData.scheduleOfWorksId = selectedItem.id;
      formData.approve = approve;
      await editScheduleOfWorksChecks(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(
            `Schedule Of Works Checks updated successfully`
          );
          reset({}, { keepValues: true });
          if (approve) {
            showApproveScheduleOfWorksModalShowing();
          }
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit Schedule Of Works checks`);
    }
  };

  const handleApproveScheduleOfWorks = async () => {
    if (!selectedItem?.id) {
      createErrorSnackbar(
        "Couldn't approve schedule of works. No selected id found"
      );
      return;
    }

    await approveScheduleOfWorks({
      applicationId,
      scheduleOfWorksId: selectedItem.id,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Schedule Of Works Approved');
        hideApproveScheduleOfWorksModalShowing();
      })
      .catch(error => {
        createErrorSnackbar(error);
      });
  };

  const isFormValid =
    watch('contractProvidesSufficientTime') === true &&
    watch('workPackageCostsMatchSubmittedCosts') === true &&
    (watch('hasFullPlansApproval') === EScheduleOfWorksChecksAnswer.Yes ||
      watch('hasFullPlansApproval') === EScheduleOfWorksChecksAnswer.NA) &&
    watch('siteStartDateMatchesContract') !== undefined &&
    watch('monthlyPaymentsGreaterThanMaximumPermitted') !== undefined &&
    watch('companyNameMatchLeadContractor') !== undefined &&
    initialData.isScheduleOfWorksCostsConfirmed;

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.ScheduleOfWorks,
  });

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Schedule Of Works Checks will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.ScheduleOfWorks} />
        )}

        <form>
          <AlertForQueryErrorOrNull
            isError={editScheduleOfWorksChecksStatus.isError}
            error={editScheduleOfWorksChecksStatus.error}
          />

          <AlertForChangedData isDirty={isDirty} />

          <WarningAlert
            alertText="You cannot approve the schedule of works until the cost profile has been confirmed, and there are no unprofiled costs"
            isShow={initialData.isScheduleOfWorksCostsConfirmed === false}
          />

          <Stack rowGap={2}>
            <Grid container spacing={2}>
              <Grid xs={8.5} item>
                <Stack rowGap={2}>
                  <YesNoQuestionAndAnswer
                    questionLabel="Does the submitted start on site date match the works contract?"
                    questionFieldName="siteStartDateMatchesContract"
                    answerFieldName="siteStartDateMatchesContractReason"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <YesNoQuestionAndAnswer
                    questionLabel="Does the works contract provide sufficient time to cover
                    the schedule of works?"
                    questionFieldName="contractProvidesSufficientTime"
                    answerFieldName="contractProvidesSufficientTimeReason"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <YesNoQuestionAndAnswer
                    questionLabel="Does the approved works package costs match the submitted
                    works contracts?"
                    questionFieldName="workPackageCostsMatchSubmittedCosts"
                    answerFieldName="workPackageCostsMatchSubmittedCostsReason"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <YesNoNaQuestionAndAnswer
                    questionLabel="Has evidence of 'full plans approval' in respect of the
                    building regulations been uploaded? If the building is
                    over 18 metres high this will be a Gateway 2 approval from
                    the Building Safety Regulator."
                    questionFieldName="hasFullPlansApproval"
                    answerFieldName="hasFullPlansApprovalReason"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                  <YesNoQuestionAndAnswer
                    questionLabel="Does the company name on the works contract match the lead
    contractor within the works package?"
                    questionFieldName="companyNameMatchLeadContractor"
                    answerFieldName="companyNameMatchLeadContractorReason"
                    readOnly={readOnly || isOnHoldForThisType}
                  />

                  <YesNoQuestionAndAnswer
                    questionLabel="Are there any monthly payments greater than the maximum %
    permitted in the documented operating procedure?"
                    questionFieldName="monthlyPaymentsGreaterThanMaximumPermitted"
                    answerFieldName="monthlyPaymentsGreaterThanMaximumPermittedReason"
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                </Stack>
              </Grid>
              <Grid xs={3.5} item>
                <ScheduleOfWorksChecksSectionInfoPanel />
              </Grid>
            </Grid>
            {!readOnly && (
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    p={3}
                    bgcolor="grey.100"
                  >
                    <Button
                      variant="outlined"
                      disabled={isOnHoldForThisType}
                      onClick={() => {
                        showReferToHomesEnglandModal();
                      }}
                    >
                      Refer to HE
                    </Button>
                    <OnOffHold holdType={EOnHoldType.ScheduleOfWorks} />
                    <Box gap={2} display="flex">
                      <Button
                        variant="outlined"
                        onClick={() => onSubmit(false)}
                        disabled={isOnHoldForThisType}
                      >
                        Save
                      </Button>
                      {/* If ANY answer is No, disable the Approve Schedule Of Works button */}
                      <Button
                        variant="contained"
                        disabled={!isFormValid || isOnHoldForThisType}
                        onClick={() => onSubmit(true)}
                      >
                        Approve Schedule Of Works
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Stack>
        </form>
      </FormProvider>

      {renderReferToHomesEnglandModal({
        handleConfirmClick: handleReferToHeConfirmClick,
        referStatus: {
          isLoading: referStatus.isLoading,
          isError: referStatus.isError,
          error: referStatus.error,
        },
      })}

      {isApproveScheduleOfWorksModalShowing && (
        <ApproveScheduleOfWorksModal
          onConfirm={() => handleApproveScheduleOfWorks()}
          onCancel={() => hideApproveScheduleOfWorksModalShowing()}
        />
      )}
    </>
  );
};

const YesNoQuestionAndAnswer = ({
  readOnly,
  questionFieldName,
  answerFieldName,
  questionLabel,
}: {
  readOnly: boolean;
  questionFieldName: keyof IScheduleOfWorksChecks;
  answerFieldName: keyof IScheduleOfWorksChecks;
  questionLabel: string;
}) => {
  const { control, watch } = useFormContext<IScheduleOfWorksChecks>();
  const [field] = watch([questionFieldName]);
  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        borderRadius: '5px',
        p: 3,
      }}
    >
      <Stack rowGap={3}>
        <Box>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            {questionLabel}
          </Typography>
          <Controller
            name={getName(questionFieldName)}
            rules={{
              validate: {
                required: value => {
                  if (value === null || value === undefined) {
                    return 'Required';
                  }

                  return true;
                },
              },
            }}
            render={({ fieldState, field: { onChange, ...fieldProps } }) => (
              <FormControl>
                <ToggleButtonGroup
                  {...fieldProps}
                  onChange={(_, val) => {
                    if (val !== null) {
                      onChange(val);
                    }
                  }}
                  exclusive
                  disabled={readOnly}
                  sx={{
                    width: 'fit-content',
                  }}
                >
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
                {fieldState.error?.message ? (
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}
          />
        </Box>

        {field !== null && (
          <Comment
            control={control}
            fieldName={answerFieldName}
            readOnly={readOnly}
          />
        )}
      </Stack>
    </Box>
  );
};

const YesNoNaQuestionAndAnswer = ({
  readOnly,
  questionFieldName,
  answerFieldName,
  questionLabel,
}: {
  readOnly: boolean;
  questionFieldName: keyof IScheduleOfWorksChecks;
  answerFieldName: keyof IScheduleOfWorksChecks;
  questionLabel: string;
}) => {
  const { control, watch } = useFormContext<IScheduleOfWorksChecks>();
  const [field] = watch([questionFieldName]);
  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        borderRadius: '5px',
        p: 3,
      }}
    >
      <Stack rowGap={3}>
        <Box>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            {questionLabel}
          </Typography>
          <Controller
            name={getName(questionFieldName)}
            rules={{
              validate: {
                required: value => {
                  if (value === null || value === undefined) {
                    return 'Required';
                  }

                  return true;
                },
              },
            }}
            render={({ fieldState, field: { onChange, ...fieldProps } }) => (
              <FormControl>
                <ToggleButtonGroup
                  {...fieldProps}
                  onChange={(_, val) => {
                    if (val !== null) {
                      onChange(val);
                    }
                  }}
                  exclusive
                  disabled={readOnly}
                  sx={{
                    width: 'fit-content',
                  }}
                >
                  <ToggleButton value={EScheduleOfWorksChecksAnswer.Yes}>
                    Yes
                  </ToggleButton>
                  <ToggleButton value={EScheduleOfWorksChecksAnswer.No}>
                    No
                  </ToggleButton>
                  <ToggleButton value={EScheduleOfWorksChecksAnswer.NA}>
                    Not Applicable
                  </ToggleButton>
                </ToggleButtonGroup>
                {fieldState.error?.message ? (
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}
          />
        </Box>
        
        {field !== null && (
          <Comment
            control={control}
            fieldName={answerFieldName}
            readOnly={readOnly}
          />
        )}
      </Stack>
    </Box>
  );
};

const Comment = ({
  control,
  fieldName,
  readOnly,
}: {
  control: Control<IScheduleOfWorksChecks, any>;
  fieldName: keyof IScheduleOfWorksChecks;
  readOnly: boolean;
}) => {
  return (
    <Box>
      <Typography variant="h3" fontWeight={600} fontSize="1em">
        Provide Reason
      </Typography>
      <Controller
        control={control}
        name={getName(fieldName)}
        rules={{ required: 'Required' }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            value={field.value ?? ''}
            fullWidth
            error={
              !!fieldState.error &&
              (field.value === undefined || field.value === '')
            }
            required
            helperText={
              fieldState.error &&
              (field.value === undefined || field.value === '')
                ? fieldState.error?.message
                : ''
            }
            multiline
            disabled={readOnly}
            rows={3}
            sx={{
              mb: 0,
              boxShadow: 'none',
              // Selector for error message
              'p:last-child': {
                backgroundColor: 'grey.100',
                margin: 0,
                padding: '1rem',
              },
            }}
          />
        )}
      />
    </Box>
  );
};
