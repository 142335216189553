import { Grid, Typography, Chip } from '@mui/material';

interface ISummaryItemWithChipProps {
  label: string;
  value: string;
}

export const SummaryItemWithChip = ({
  label,
  value,
}: ISummaryItemWithChipProps) => {
  return (
    <Grid item>
      <Typography variant="body1" fontWeight={900}>
        {label}
      </Typography>
      <Chip label={`${value}`} size="small" sx={{ fontSize: '0.9em' }} />
    </Grid>
  );
};
