import { applicationApi } from 'api/application';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IAddEditDirector,
  IApplicationCompaniesHouseChecks,
  IApplicationConfirmEligibilityChecks,
  IApplicationCoreInformationChecks,
  IApplicationCostCentreValidationChecks,
  IApplicationEligibilityCategories,
  IApplicationEligibilityReferral,
  IApplicationEligibilitySummary,
  IApplicationFRAEWChecks,
  IApplicationLandRegistryChecks,
  IApplicationRejectEligibilityChecks,
  IApplicationSaveEligibilityChecks,
  IApplicationVeriphyChecks,
  ICompletReferralRequest,
  ICostCentreItem,
  IDeleteDirector,
  IDirectorStatus,
  IEligibilityReferApplicationRequest,
  IGetDirectorParams,
  IGetEligibilityPepSanctionDirectorsParams,
  IPEPSanctionsDirectorsListItem,
} from 'types/applications/ApplicationEligibilityTypes';
import { QueryTags } from 'util/ApiUtils';
import { sortByOrderAsc } from 'util/AppUtils';
import { getPaginatedQuery } from 'util/QueryUtils';

export const eligibilityEndpoints = applicationApi.injectEndpoints({
  endpoints: builder => ({
    getApplicationEligibilityCategories: builder.query<
      IApplicationEligibilityCategories,
      string
    >({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId => `/${applicationId}/eligibility/overview`,
      transformResponse: (response: IApplicationEligibilityCategories) => {
        // Sort data before it hits the cache

        // First, sort top level categories
        response.categories.sort(sortByOrderAsc);

        // Then iterate each category and sort child items
        for (const category of response.categories) {
          category.detailItems.sort(sortByOrderAsc);

          for (const detailItem of category.detailItems) {
            detailItem.id = detailItem.sectionName.replace(/\s/g, '');
          }
        }

        return response;
      },
    }),
    getEligibilitySummary: builder.query<
      IApplicationEligibilitySummary,
      string
    >({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId => `/${applicationId}/eligibility/details`,
      keepUnusedDataFor: 0,
    }),
    getApplicationEligibilityReferrals: builder.query<
      Array<IApplicationEligibilityReferral>,
      string
    >({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId => `/${applicationId}/eligibility/referrals`,
    }),
    getCoreInformationChecks: builder.query<
      IApplicationCoreInformationChecks,
      string
    >({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId => `/${applicationId}/eligibility/coreinformation`,
    }),
    editCoreInformationChecks: builder.mutation<
      any,
      IApplicationCoreInformationChecks
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.id}/eligibility/coreinformation`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getCostCentreList: builder.query<ICostCentreItem[], void>({
      query: () => `${process.env.REACT_APP_API_URL}/api/costcentres`,
    }),
    getCostCentreValidationChecks: builder.query<
      IApplicationCostCentreValidationChecks,
      string
    >({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId => `/${applicationId}/eligibility/costcentre`,
    }),
    editCostCentreValidationChecks: builder.mutation<
      void,
      IApplicationCostCentreValidationChecks
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.id}/eligibility/costcentre`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getFRAEWChecks: builder.query<IApplicationFRAEWChecks, string>({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId => `/${applicationId}/eligibility/fraew`,
    }),
    editFRAEWChecks: builder.mutation<any, IApplicationFRAEWChecks>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.id}/eligibility/fraew`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getCompaniesHouseChecks: builder.query<
      IApplicationCompaniesHouseChecks,
      string
    >({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId => `/${applicationId}/eligibility/companieshouse`,
    }),
    editCompaniesHouseChecks: builder.mutation<
      any,
      IApplicationCompaniesHouseChecks
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.id}/eligibility/companieshouse`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getLandRegistryChecks: builder.query<
      IApplicationLandRegistryChecks,
      string
    >({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId => `/${applicationId}/eligibility/landregistry`,
    }),
    editLandRegistryChecks: builder.mutation<
      any,
      IApplicationLandRegistryChecks
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.id}/eligibility/landregistry`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getConfirmEligibilityChecks: builder.query<
      IApplicationConfirmEligibilityChecks,
      string
    >({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId =>
        `/${applicationId}/eligibility/confirmeligibility`,
    }),
    saveConfirmEligibilityChecks: builder.mutation<
      any,
      IApplicationSaveEligibilityChecks
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/eligibility/confirmeligibility/save`,
        method: 'PUT',
        body: payload,
      }),
    }),
    approveConfirmEligibilityChecks: builder.mutation<
      any,
      IApplicationSaveEligibilityChecks
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/eligibility/confirmeligibility/approve`,
        method: 'PUT',
        body: payload,
      }),
    }),
    rejectConfirmEligibilityChecks: builder.mutation<
      any,
      IApplicationRejectEligibilityChecks
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/eligibility/confirmeligibility/reject`,
        method: 'PUT',
        body: payload,
      }),
    }),
    referToGLA: builder.mutation<any, string>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: applicationId => ({
        url: `/${applicationId}/eligibility/confirmeligibility/referToGLA`,
        method: 'POST',
      }),
    }),
    getEligibilityPepSanctionDirectors: builder.query<
      IPaginationResponse<IPEPSanctionsDirectorsListItem>,
      IGetEligibilityPepSanctionDirectorsParams
    >({
      providesTags: [QueryTags.PEPSanctions],
      query: query =>
        getPaginatedQuery(
          `/api/application/${query.applicationId}/eligibility/pepsanctions/directors?`,
          {
            skip: query.queryParams.skip,
            take: query.queryParams.take,
            sortBy: query.queryParams.sortBy.toString(),
            sortDirection: query.queryParams.sortDirection.toString(),
            searchValue: query.queryParams.searchValue,
          }
        ),
    }),
    getDirector: builder.query<IAddEditDirector, IGetDirectorParams>({
      query: query =>
        `/${query.applicationId}/eligibility/pepsanctions/director/${query.directorId}`,
    }),
    addDirector: builder.mutation<any, IAddEditDirector>({
      invalidatesTags: [QueryTags.PEPSanctions],
      query: payload => ({
        url: `/${payload.applicationId}/eligibility/pepsanctions/director`,
        method: 'POST',
        body: payload,
      }),
    }),
    editDirector: builder.mutation<any, IAddEditDirector>({
      invalidatesTags: [QueryTags.PEPSanctions],
      query: payload => ({
        url: `/${payload.applicationId}/eligibility/pepsanctions/director/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteDirector: builder.mutation<void, IDeleteDirector>({
      invalidatesTags: [QueryTags.PEPSanctions],
      query: payload => ({
        url: `/${payload.applicationId}/eligibility/pepsanctions/director/${payload.id}`,
        method: 'DELETE',
      }),
    }),
    setDirectorStatus: builder.mutation<any, IDirectorStatus>({
      invalidatesTags: [QueryTags.PEPSanctions],
      query: payload => ({
        url: `/${payload.applicationId}/eligibility/pepsanctions/director/${payload.id}/status`,
        method: 'PUT',
        body: payload,
      }),
    }),
    confirmPEPSanctionsComplete: builder.mutation<any, string>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload}/eligibility/pepsanctions`,
        method: 'PUT',
      }),
    }),
    editReferApplication: builder.mutation<
      any,
      IEligibilityReferApplicationRequest
    >({
      invalidatesTags: [QueryTags.ApplicationEligibility],
      query: params => ({
        url: `/${params.applicationId}/eligibility/refer`,
        method: 'PUT',
        body: params,
      }),
    }),
    completeReferral: builder.mutation<void, ICompletReferralRequest>({
      invalidatesTags: [QueryTags.ApplicationEligibility],
      query: params => ({
        url: `/${params.applicationId}/refer/${params.referralId}/complete`,
        method: 'PUT',
        body: { completeReason: params.completeReason },
      }),
    }),
    getVeriphyChecks: builder.query<IApplicationVeriphyChecks, string>({
      providesTags: [QueryTags.ApplicationEligibility],
      query: applicationId => `/${applicationId}/eligibility/veriphychecks`,
    }),
    editVeriphyChecks: builder.mutation<any, IApplicationVeriphyChecks>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload.id}/eligibility/veriphychecks`,
        method: 'PUT',
        body: payload,
      }),
    }),
    runVeriphyReport: builder.mutation<any, string>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationEligibility,
      ],
      query: payload => ({
        url: `/${payload}/eligibility/veriphychecks/report`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApplicationEligibilityCategoriesQuery,
  useGetEligibilitySummaryQuery,
  useGetApplicationEligibilityReferralsQuery,
  useGetCoreInformationChecksQuery,
  useLazyGetCoreInformationChecksQuery,
  useEditCoreInformationChecksMutation,
  useGetCostCentreListQuery,
  useGetCostCentreValidationChecksQuery,
  useEditCostCentreValidationChecksMutation,
  useGetFRAEWChecksQuery,
  useLazyGetFRAEWChecksQuery,
  useEditFRAEWChecksMutation,
  useGetCompaniesHouseChecksQuery,
  useEditCompaniesHouseChecksMutation,
  useGetLandRegistryChecksQuery,
  useLazyGetLandRegistryChecksQuery,
  useEditLandRegistryChecksMutation,
  useGetConfirmEligibilityChecksQuery,
  useSaveConfirmEligibilityChecksMutation,
  useApproveConfirmEligibilityChecksMutation,
  useRejectConfirmEligibilityChecksMutation,
  useReferToGLAMutation,
  useGetEligibilityPepSanctionDirectorsQuery,
  useGetDirectorQuery,
  useLazyGetDirectorQuery,
  useAddDirectorMutation,
  useEditDirectorMutation,
  useDeleteDirectorMutation,
  useSetDirectorStatusMutation,
  useConfirmPEPSanctionsCompleteMutation,
  useEditReferApplicationMutation,
  useCompleteReferralMutation,
  useGetVeriphyChecksQuery,
  useEditVeriphyChecksMutation,
  useRunVeriphyReportMutation,
} = eligibilityEndpoints;
