import { Box, Stack, Grid, Typography, Chip } from '@mui/material';
import { useGetWorksPackageCladdingQuery } from 'api/application/worksPackageApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import {
  EReplacementCladdingTypeLanguage,
  IWorksPackageOverview,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import {
  SummaryField,
  SummaryLabel,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';

export const SummarySection = ({ data }: { data: IWorksPackageOverview }) => {
  return (
    <RoundBorderBox sx={{ p: 2, pt: 3 }}>
      <CardTitle title="Works package details" sx={{ mb: 2 }} />
      <Stack gap={3}>
        <SummaryField
          label="Building height"
          value={`${data.buildingDetails.buildingHeightMetres} metres`}
        />

        <SummaryField
          label="Responsible Entity type"
          value={`${data.buildingDetails.responsibleEntityType}`}
        />

        <CladdingSystemsSummary />
      </Stack>
    </RoundBorderBox>
  );
};

export const CladdingSystemsSummary = () => {
  const { applicationId } = useWorksPackageContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetWorksPackageCladdingQuery(applicationId);

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  if (isLoading) {
    return <FormSkeleton />;
  }

  return isSuccess ? (
    <Box mb={2}>
      <SummaryLabel label="Cladding systems" />
      <Stack mt={1} rowGap={1}>
        {data.claddingSystemReplacementDetails.map((item, i) => {
          return (
            <RoundBorderBox key={i} p={1}>
              <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Grid item>
                  <Typography fontWeight={600} fontSize={'0.9em'}>
                    Cladding system {i + 1}
                  </Typography>
                </Grid>
                <Grid item>
                  {item.isReplacementCladdingAcm && (
                    <Chip
                      size="small"
                      label="ACM"
                      sx={{
                        mr: 1,
                      }}
                    />
                  )}
                  <Chip
                    size="small"
                    label={
                      EReplacementCladdingTypeLanguage[item.remediationType]
                    }
                  />
                </Grid>
              </Grid>
            </RoundBorderBox>
          );
        })}
      </Stack>
    </Box>
  ) : null;
};
