import { applicationApi } from 'api/application/applicationApi';
import {
  ISupportListItem,
  ISupportTicketInfo,
  ESupportTicketType,
  ESupportTicketContactMethod,
  ISupportApplicationSummary,
  IReferToRemediationAdviserMutationProps,
  IReferToRemediationAdviser,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { QueryTags } from 'util/ApiUtils';

export const supportEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getSupportRequestList: builder.query<ISupportListItem[], string>({
      providesTags: [QueryTags.ApplicationSupportList],
      query: applicationId => `${applicationId}/support/list`,
    }),
    getSupportTicketInfo: builder.query<
      ISupportTicketInfo,
      { applicationId: string; supportTicketId: string }
    >({
      providesTags: [QueryTags.ApplicationSupportList],
      query: ({ applicationId, supportTicketId }) =>
        `${applicationId}/support/${supportTicketId}`,
    }),
    getApplicationSummaryForSupportTicket: builder.query<
      ISupportApplicationSummary,
      { applicationId: string }
    >({
      query: ({ applicationId }) =>
        `${applicationId}/support/applicationdetails`,
    }),
    referSupportTicket: builder.mutation<
      void,
      { applicationId: string; supportTicketId: string }
    >({
      invalidatesTags: [QueryTags.ApplicationSupportList],
      query: ({ applicationId, supportTicketId }) => {
        return {
          url: `${applicationId}/support/${supportTicketId}/refer`,
          method: 'POST',
        };
      },
    }),
    resolveSupportTicket: builder.mutation<
      void,
      {
        applicationId: string;
        supportTicketId: string;
        resolutionDescription: string;
        supportTicketResolutionReasonId: number;
      }
    >({
      invalidatesTags: [QueryTags.ApplicationSupportList],
      query: ({
        applicationId,
        supportTicketId,
        supportTicketResolutionReasonId,
        resolutionDescription,
      }) => {
        return {
          url: `${applicationId}/support/${supportTicketId}/resolve`,
          method: 'POST',
          body: {
            supportTicketResolutionReasonId,
            resolutionDescription,
          },
        };
      },
    }),
    getRemediationAdviserDetails: builder.query<IReferToRemediationAdviser, { applicationId: string; supportTicketId: string }>({
      providesTags: [QueryTags.ApplicationSupportTicket],
      query: ({ applicationId, supportTicketId }) =>
        `${applicationId}/support/${supportTicketId}/remediationadviser/referral`,
    }),
    referToRemediationAdviser: builder.mutation<
      void,
      IReferToRemediationAdviserMutationProps
    >({
      invalidatesTags: [
        QueryTags.ApplicationSupportList,
        QueryTags.ApplicationSupportTicket,
      ],
      query: ({
        formData,
        supportTicketData: { applicationId, supportTicketId },
      }) => {
        return {
          url: `${applicationId}/support/${supportTicketId}/remediationadviser/referral`,
          method: 'PUT',
          body: formData,
        };
      },
    }),
    createSupportTicket: builder.mutation<
      { supportTicketId: string },
      {
        applicationId: string;
        supportTicketType: ESupportTicketType;
        description: string;
        contactMethod: ESupportTicketContactMethod;
        doesApplicantHaveCapacityGap: boolean;
        evidenceFileId?: string;
      }
    >({
      invalidatesTags: [QueryTags.ApplicationSupportList],
      query: ({
        applicationId,
        supportTicketType,
        description,
        contactMethod,
        doesApplicantHaveCapacityGap,
        evidenceFileId,
      }) => {
        return {
          url: `${applicationId}/support/`,
          method: 'POST',
          body: {
            supportTicketType,
            description,
            contactMethod,
            doesApplicantHaveCapacityGap,
            evidenceFileId,
          },
        };
      },
    }),
  }),
});

export const {
  useResolveSupportTicketMutation,
  useReferSupportTicketMutation,
  useGetSupportRequestListQuery,
  useGetSupportTicketInfoQuery,
  useGetApplicationSummaryForSupportTicketQuery,
  useCreateSupportTicketMutation,
  useGetRemediationAdviserDetailsQuery,
  useReferToRemediationAdviserMutation,
} = supportEndpoints;
