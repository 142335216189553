export enum EApplicationEligibilitySectionType {
  CoreInformationChecks = 'core-information-checks',
  CostCentreValidationChecks = 'cost-centre-validation-checks',
  FRAEWChecks = 'fraew-checks',
  Veriphy = 'veriphy',
  CompaniesHouse = 'companies-house ',
  PEPSanctions = 'pep-sanctions',
  LandRegistry = 'land-registry',
  ApproveOrReject = 'approve-or-reject',
}
