import { Box, Typography } from '@mui/material';
import { IWorksPackageOverview } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';

export const BuildingControlCard = ({
  title,
  data,
}: {
  title: string;
  data?: IWorksPackageOverview['buildingControl'];
}) => {
  return (
    <Box
      mb={2}
      bgcolor={'grey.100'}
      sx={{
        width: '100%',
        padding: 2,
        paddingTop: 3,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h3"
        marginBottom={4}
        fontWeight={900}
        fontSize={'1.05em'}
      >
        {title}
      </Typography>

      <BuildingControlCardData
        data={data as IWorksPackageOverview['buildingControl']}
      />
    </Box>
  );
};

export const BuildingControlCardData = ({
  data,
}: {
  data: IWorksPackageOverview['buildingControl'];
}) => {
  const stackedData: [string, string][] = [
    [
      'Is your building over 18m in height?',
      `${data ? (data?.buildingControlRequired ? 'Yes' : 'No') : '-'}`,
    ],
    [
      'Forecast Submission Date',
      `${
        data && data?.buildingControlForecastSubmissionDate
          ? new Date(
              data?.buildingControlForecastSubmissionDate
            ).toLocaleDateString("en-GB")
          : '-'
      }`,
    ],
    [
      'Actual Submission Date',
      `${
        data && data?.buildingControlActualSubmissionDate
          ? new Date(
              data?.buildingControlActualSubmissionDate
            ).toLocaleDateString("en-GB")
          : '-'
      }`,
    ],
    [
      'Validation Date',
      `${
        data && data?.buildingControlValidationDate
          ? new Date(data?.buildingControlValidationDate).toLocaleDateString("en-GB")
          : '-'
      }`,
    ],
    [
      'Decision',
      `${data ? (data?.buildingControlDecision ? 'Yes' : 'No') : '-'}`,
    ],
    [
      'Decision Date',
      `${
        data && data?.buildingControlDecisionDate
          ? new Date(data?.buildingControlDecisionDate).toLocaleDateString("en-GB")
          : '-'
      }`,
    ],
  ];

  return (
    <>
      {stackedData.map(([title, value], i) => (
        <CardDetailsStacked key={i} title={title} value={value} />
      ))}
    </>
  );
};

const CardDetailsStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <CardDataTitle title={title} />

        <Typography fontSize={'0.8em'} fontWeight={600}>
          {value}
        </Typography>
      </Box>
    </>
  );
};

const CardDataTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="body1" fontWeight={'600'} fontSize={'0.8em'}>
      {title}
    </Typography>
  );
};
