import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';

export const muiTabTheme: {
  defaultProps?: ComponentsProps['MuiTab'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTab'];
  variants?: ComponentsVariants['MuiTab'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      background: blueGrey[100],
      color: '#474747',
      fontSize: '1rem',
      fontWeight: '600',
      textTransform: 'none',
      border: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      letterSpacing: 'normal',
      wordSpacing: 'normal',
      '&.Mui-disabled': {
        background: blueGrey[50],
        color: '#474747',
        opacity: 1,
        fontSize: '1rem',
        fontWeight: '400',
        textTransform: 'none',
        border: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
      },
      '&.Mui-selected': {
        borderBottom: 0,
        backgroundColor: 'white',
        fontWeight: '600',
        ':hover': {
          backgroundColor: 'white',
        },
      },
    }),
  },
};
