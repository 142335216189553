import { Grid } from '@mui/material';
import { useGetBuildingDetailsQuery } from 'api/application/worksPackageApi';
import { IWorksPackageOverview } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { InfoCard } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/components/InfoCard';

export const calculateVariancePercentage = (
  benchmark: number,
  worksPackageCost: number
): number => {
  const val = worksPackageCost - benchmark;
  const percentage = (val / Math.abs(benchmark)) * 100;
  return percentage;
};

export const isVarianceTolerable = (
  variancePercentage: number,
  varianceTolerance: number
) => {
  const lowerRangeVariance = varianceTolerance ? -varianceTolerance : 0;
  if (
    variancePercentage >= lowerRangeVariance &&
    variancePercentage <= varianceTolerance
  ) {
    return true;
  }

  return false;
};

export const CostVsBenchmarkSummary = ({
  applicationId,
  data,
}: {
  applicationId: string;
  data: IWorksPackageOverview;
}) => {
  const variancePercentage =
    (data.benchmark &&
      calculateVariancePercentage(data.benchmark, data.worksPackageCost)) ||
    0;

  const getIsVarianceTolerable = isVarianceTolerable(
    variancePercentage,
    data.varianceTolerance
  );

  const { data: buildingDetailsData } =
    useGetBuildingDetailsQuery(applicationId);

  return (
    <Grid container spacing={3}>
      <Grid item xs={2.5}>
        <InfoCard
          title={'Works package cost'}
          cost={`£${data.worksPackageCost.toLocaleString()}`}
        />
      </Grid>
      <Grid item xs={2.5}>
        <InfoCard
          title={'Benchmark'}
          cost={`£${data.benchmark.toLocaleString()}`}
        />
      </Grid>
      <Grid item xs={2.5}>
        <InfoCard
          title={'% Grant available'}
          cost={
            buildingDetailsData?.percentageOfCosts
              ? `${buildingDetailsData?.percentageOfCosts}%`
              : '-'
          }
        />
      </Grid>
      <Grid item xs={2.5}>
        <InfoCard
          title={'Amount Grant available'}
          cost={
            buildingDetailsData?.recommendedPayAmount
              ? `£${buildingDetailsData?.recommendedPayAmount.toLocaleString()}`
              : '-'
          }
        />
      </Grid>
      <Grid item xs={2}>
        <InfoCard
          title={'Variance'}
          cost={`${variancePercentage?.toFixed(1)}%`}
          additionalInfo={`£${(
            data.worksPackageCost - data.benchmark
          ).toLocaleString()}`}
          isVarianceTolerable={getIsVarianceTolerable}
        />
      </Grid>
    </Grid>
  );
};
