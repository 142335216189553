import { ErrorAlert } from 'common/components/alerts';
import { useWorksPackageDeedContext } from 'pages/applicationPage/content//projectPrep/sections/WorksPackageDeedSection/context';
import {
  WorksPackageDeedSentSection,
  WorksPackageDeedSignedSection,
} from 'pages/applicationPage/content//projectPrep/sections/WorksPackageDeedSection/sections';
import { EWorksPackageDeedSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection';
import { WorksPackageDeedCounterSignedSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection/sections/WorksPackageDeedCounterSignedSection';

export const WorksPackageDeedSectionOutlet = () => {
  const { selectedItemId } = useWorksPackageDeedContext();

  switch (selectedItemId) {
    case EWorksPackageDeedSection.sendWorksPackageDeedDeed:
      return <WorksPackageDeedSentSection />;

    case EWorksPackageDeedSection.uploadDocuments:
      return <WorksPackageDeedSignedSection />;

    case EWorksPackageDeedSection.uploadCounterSignedDocuments:
      return <WorksPackageDeedCounterSignedSection />;

    default:
      return (
        <ErrorAlert
          msg={`Could not work out which section to display for id: ${selectedItemId}`}
        />
      );
  }
};
