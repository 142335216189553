import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetSupportTicketInfoQuery } from 'api/application/applicationSupportApi';
import { useGetResolutionReasonsQuery } from 'api/support';
import { useApplicationSupportContext } from 'pages/applicationPage/content/support/context';

export const useResolutionReasons = () => {
  const query = useGetResolutionReasonsQuery();

  const getResolutionReasonName = (resolutionReasonId: number) => {
    return (
      query.data?.find(
        resolutionReason => resolutionReason.id === resolutionReasonId
      )?.name ?? '-'
    );
  };

  const options =
    query.data?.map(reason => {
      return { value: reason.id, label: reason.name };
    }) ?? [];

  return { query, getResolutionReasonName, options };
};

export const useGetSupportTicketInfo = () => {
  const { applicationId, selectedItemId } = useApplicationSupportContext();
  const key =
    applicationId && selectedItemId
      ? {
          applicationId,
          supportTicketId: selectedItemId,
        }
      : skipToken;
  const query = useGetSupportTicketInfoQuery(key);
  return { query };
};
