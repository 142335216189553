import { Box, Grid, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import {
  IPaymentRequestCostItem,
  IPaymentRequestCosts,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { PaymentRequestConfirmedCostField } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestConfirmedCostField';
import { getGbpAmount } from 'util/AppUtils';

export const PaymentRequestCostsMonthlyCostsSection = ({
  monthlyCostsFields,
  form,
  readOnly = false,
}: {
  monthlyCostsFields?: Array<IPaymentRequestCostItem> | null;
  form: UseFormReturn<IPaymentRequestCosts, any>;
  readOnly?: boolean;
}) => {
  return (
    <Box p={2}>
      <Typography variant="h3" fontWeight={800} fontSize="1.3em">
        Monthly cost profile
      </Typography>
      {monthlyCostsFields?.map((row, index) => (
          <div key={`div.monthlyCosts.${index}`}>
          <input
            key={`monthlyCostsIsDirty.${index}`}
            type="hidden"
            {...form.register(`monthlyCosts.${index}.isDirty`)}
          />
          <PaymentRequestCostItemRow
            key={index}
            form={form}
            index={index}
            paymentRequestCostItem={row}
            readOnly={readOnly}
          />
        </div>
      ))}
    </Box>
  );
};

const PaymentRequestCostItemRow = ({
  index,
  form,
  paymentRequestCostItem,
  readOnly = false,
}: {
  index: number;
  form: UseFormReturn<IPaymentRequestCosts, any>;
  paymentRequestCostItem: IPaymentRequestCostItem;
  readOnly?: boolean;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={8}
      wrap="nowrap"
      sx={{
        mb: 1,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em">
          {paymentRequestCostItem.itemName}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign={'right'}>
        {!!paymentRequestCostItem.scheduledCost
          ? getGbpAmount({ value: paymentRequestCostItem.scheduledCost })
          : '-'}
      </Grid>
      <Grid item xs={3} textAlign={'right'}>
        <PaymentRequestConfirmedCostField
          form={form}
          arrayName={'monthlyCosts'}
          index={index}
          scheduledCost={paymentRequestCostItem.scheduledCost}
          readOnly={readOnly}
        />
      </Grid>
    </Grid>
  );
};
