import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditApplicationFileMutation } from 'api/application/applicationEditApi';
import { useUploadFileMutation } from 'api/file';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect';
import { IFileUpload } from 'common/types/File';
import { EQuestionType } from 'enums/EQuestionType';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zApplicationFileSchema } from 'types/applications/ApplicationEdit.zod';
import { IEditApplicationFile } from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IEditApplicationFile) =>
  nameof<IEditApplicationFile>(fieldName);

interface IEditApplicationFileDrawerProps {
  questionType: EQuestionType;
  onClose: () => void;
  onSuccess: () => void;
}

export function EditApplicationFileDrawer({
  questionType,
  onClose,
  onSuccess,
}: IEditApplicationFileDrawerProps) {
  const getDrawerTitle = () => {
    switch (questionType) {
      case EQuestionType.FireRiskReport:
        return 'Update Fire Risk Report';
      case EQuestionType.FireRiskSummary:
        return 'Update Fire Risk Summary';
      default:
        return 'Update Document';
    }
  };

  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">{getDrawerTitle()}</Typography>
        </DialogTitle>
        <EditApplicationFileForm
          questionType={questionType}
          onSuccess={onSuccess}
          onClose={onClose}
        />
      </Box>
    </StyledDrawer>
  );
}

interface IEditApplicationFileFormProps {
  questionType: EQuestionType;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditApplicationFileForm = ({
  questionType,
  onClose,
  onSuccess,
}: IEditApplicationFileFormProps) => {
  const { applicationId } = useApplicationContext();

  const [uploadFile, uploadStatus] = useUploadFileMutation();
  const [editApplicationFile, editApplicationFileResult] =
    useEditApplicationFileMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IEditApplicationFile>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zApplicationFileSchema),
  });
  const { handleSubmit, control, watch, reset } = form;

  const onSubmit = async (formData: IEditApplicationFile) => {
    try {
      formData.applicationId = applicationId;
      formData.questionType = questionType;
      await editApplicationFile(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Document updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to update document`);
    }
  };

  const handleOnFileUpload = async (file: File) => {
    const fileFormData = new FormData();
    fileFormData.append('file', file);

    try {
      let fileUpload: IFileUpload = {
        file: file,
        uploadSection: 'Application',
      };

      await uploadFile(fileUpload)
        .unwrap()
        .then(payload => {
          form.setValue(`fileId`, payload.id);
          createSuccessSnackbar(`File uploaded successfully`);
        })
        .catch(error => {
          createErrorSnackbar(error.data.generalError.errorMessage);
        });
    } catch (err) {
      createErrorSnackbar(`Failed to upload file`);
    }
  };

  const handleOnFileDelete = async () => {
    form.setValue(`fileId`, '');
  };

  const watchFileId = watch('fileId');
  const isUploading = uploadStatus.isLoading;

  const getFileAcceptTypes = () => {
    switch (questionType) {
      case EQuestionType.FireRiskReport:
        return '.pdf';
      case EQuestionType.FireRiskSummary:
        return '.xlsx';
      default:
        return '.pdf, .docx, .xlsx, .msg, .png, .jpg';
    }
  };

  const getFileLabel = () => {
    switch (questionType) {
      case EQuestionType.FireRiskReport:
        return 'Click to upload or drag and drop (max 50MB)';
      case EQuestionType.FireRiskSummary:
        return 'Click to upload or drag and drop (max 50MB)';
      default:
        return 'Click to upload or drag and drop (max 30MB)';
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                rules={{ required: 'Document is required' }}
                name="fileId"
                render={fieldProps => {
                  return (
                    <>
                      <Grid item mb={2} display={'flex'}>
                        <FileUploadInputSelect
                          isUploading={isUploading}
                          id="file"
                          accept={getFileAcceptTypes()}
                          label={getFileLabel()}
                          inputName="fileId"
                          onFileUpload={file => handleOnFileUpload(file)}
                          onFileDelete={handleOnFileDelete}
                          uploaded={uploadStatus.isSuccess}
                        />
                      </Grid>
                      {fieldProps.fieldState.error ? (
                        <Box mt={-2} mb={3}>
                          <FormHelperText error>
                            {fieldProps.fieldState.error.message}
                          </FormHelperText>
                        </Box>
                      ) : null}
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '2rem' }}>
              <Controller
                name={getName('changeReason')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="Comments"
                    helperText={fieldState.error?.message}
                    multiline
                    autoComplete="off"
                    inputProps={{ maxLength: 1000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={
                editApplicationFileResult.isLoading ||
                isUploading ||
                !watchFileId
              }
            >
              Update Document
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
