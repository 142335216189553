import { Button, Typography } from '@mui/material';
import {
  useGetUserQuery,
  useResetUserPasswordMutation,
} from 'api/administration/users';
import { Loading } from 'common/components/loading/Loading';
import { useLocalSnackbar } from 'hooks';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IResetUserPasswordDialogProps {
  userId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ResetUserPasswordDialog({
  userId,
  onConfirm,
  onCancel,
}: IResetUserPasswordDialogProps) {
  const { data: userData, isLoading } = useGetUserQuery(userId);
  const [resetUserPassword] = useResetUserPasswordMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onResetUserPassword = async () => {
    try {
      resetUserPassword(userId);
      createSuccessSnackbar('Reset password email has been sent');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to send reset password email`);
    }
  };

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you want to send a reset password email to this user: ${userData?.firstName} ${userData?.lastName}?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onResetUserPassword}>
          Reset Password
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
