import { ErrorAlert } from 'common/components/alerts';
import { EPaymentSection } from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { useApplicationPaymentsContext } from 'pages/applicationPage/content/payments/context';
import {
  VendorSetupSection,
  RecommendPaymentSection,
  ReleasePaymentSection,
  AdditionalRecommendPaymentSection,
  AdditionalReleasePaymentSection,
} from 'pages/applicationPage/content/payments/sections';

export const PaymentsSectionOutlet = () => {
  const { selectedItemId } = useApplicationPaymentsContext();

  switch (selectedItemId) {
    case EPaymentSection.VendorSetup:
      return <VendorSetupSection />;

    case EPaymentSection.RecommendPayment:
      return <RecommendPaymentSection />;

    case EPaymentSection.ReleasePayment:
      return <ReleasePaymentSection />;

      case EPaymentSection.AdditionalRecommendPayment:
        return <AdditionalRecommendPaymentSection />;
  
      case EPaymentSection.AdditionalReleasePayment:
        return <AdditionalReleasePaymentSection />;

    default:
      return (
        <ErrorAlert
          msg={`Could not work out which section to display for id: ${selectedItemId}`}
        />
      );
  }
};
