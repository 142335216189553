import { LicenseInfo } from '@mui/x-license-pro';

export const installMuiLicense = () => {
  const envKey = 'REACT_APP_MUI_LICENSE_KEY';
  const key = process.env[envKey];
  if (!key) {
    // eslint-disable-next-line no-console
    console.error(
      `Could not retrieve MUI licese from config. The key within the config is called: ${envKey}`
    );
    return;
  }

  LicenseInfo.setLicenseKey(key);
};
