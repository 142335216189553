import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import {
  useGetDutyOfCareStatusQuery,
  useGetDutyOfCareSignedStatusQuery,
  useGetDutyOfCareCounterSignedStatusQuery,
} from 'api/application/dutyOfCareApi';
import { useLocalSnackbar } from 'hooks';
import {
  ApplicationItemGroupNavigator,
  ColumnContainer,
  SectionProgress,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  EDutyOfCareSection,
  DutyOfCareSectionOutlet,
} from 'pages/applicationPage/content/dutyOfCare';
import {
  useDutyOfCareContext,
  DutyOfCareContextProvider,
} from 'pages/applicationPage/content/dutyOfCare/context';

export const DutyOfCare = () => {
  return (
    <DutyOfCareContextProvider>
      <DutyOfCareMain />
    </DutyOfCareContextProvider>
  );
};

const useCategories = () => {
  const { applicationId } = useApplicationContext();
  const dutyOfCareSentStatus = useGetDutyOfCareStatusQuery(applicationId);
  const dutyOfCareSignedStatus =
    useGetDutyOfCareSignedStatusQuery(applicationId);
  const dutyOfCareCounterSignedStatus =
    useGetDutyOfCareCounterSignedStatusQuery(applicationId);

  return [
    {
      categoryTitle: 'Duty of Care deed',
      detailItems: [
        {
          id: EDutyOfCareSection.SendDutyOfCareDeed,
          sectionName: 'Send Duty of Care deed',
          isComplete: dutyOfCareSentStatus.data?.dutyOfCareSent ?? false,
        },
        {
          id: EDutyOfCareSection.UploadDocuments,
          sectionName: 'Upload signed document',
          isComplete: dutyOfCareSignedStatus.data?.dutyOfCareSigned ?? false,
          isDisplayed: true,
        },
        {
          id: EDutyOfCareSection.UploadCounterSignedDocuments,
          sectionName: 'Upload counter signed deed',
          isComplete: dutyOfCareCounterSignedStatus.data?.dutyOfCareCounterSigned ?? false,
          isDisplayed: true,
        },
      ],
    },
  ];
};

const DutyOfCareMain = () => {
  const { selectedItemId, setSelectedItemId } = useDutyOfCareContext();
  const categories = useCategories();
  const { createInfoSnackbar } = useLocalSnackbar();

  const stepsCompleted = categories.reduce<number>((counter, category) => {
    return (
      counter + category.detailItems.filter(item => item.isComplete).length
    );
  }, 0);

  const isSectionEnabled = (
    category: { categoryTitle?: string; detailItems: any; },
    currentIndex: number
  ) => {
    // if we are handling the first section, then it's always enabled as there are no dependencies
    if (currentIndex === 0) {
      return true;
    }

    // If we are handling a subsequent category, check all sections in the previous category are complete
    // If they are all complete, this category is enabled
    // If they are not all complete, this category is not enabled
    if (category.detailItems[currentIndex - 1].isComplete) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={3.5}>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <SectionProgress totalSteps={3} currentStep={stepsCompleted} />
          </ColumnContainer>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <Box p={2}>
              <ApplicationItemGroupNavigator
                itemGroups={categories.map((category, categoryIndex) => {
                  return {
                    title: category.categoryTitle,
                    isEnabled: true,
                    items: category.detailItems.map(
                      (detailItem, detailIndex) => {
                        const sectionEnabled = isSectionEnabled(
                          category,
                          detailIndex
                        );
                        return {
                          title: detailItem.sectionName,
                          isSelected: selectedItemId === detailItem.id,
                          isComplete: detailItem.isComplete,
                          onClick: () => {
                            if (sectionEnabled) {
                              setSelectedItemId(detailItem.id);
                            } else {
                              createInfoSnackbar(
                                `You must complete the "${
                                  category.detailItems[detailIndex - 1].sectionName
                                }" section before you can enter this section`,
                                { preventDuplicate: true }
                              );
                            }
                          },
                        };
                      }
                    ),
                  };
                })}
              />
            </Box>
          </ColumnContainer>
        </Grid>
        <Grid item xs={8.5}>
          <ColumnContainer>
            <DutyOfCareSectionOutlet />
          </ColumnContainer>
        </Grid>
      </Grid>
    </>
  );
};
