import { Box, Stack, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { ErrorAlert } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import {
  EWorksPackageApprovalStatus,
  IWorksPackageOverview,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { formatDateTimeDayMonthYearHourMins } from 'util/DateUtils';

export const ApprovedOrRejectedBanner = ({
  overviewData: {
    applicationWorkPackageApprovalStatus,
    approvalRejectionDate,
    approvalRejectionReason,
    approvalRejectionUser,
  },
}: {
  overviewData: IWorksPackageOverview;
}) => {
  const isApproved =
    applicationWorkPackageApprovalStatus ===
    EWorksPackageApprovalStatus.Approved;
  const statusText = isApproved ? 'approved' : 'rejected';

  if (!approvalRejectionDate || !approvalRejectionUser) {
    return (
      <Box mt={3}>
        <ErrorAlert
          msg={`Could not get the ${
            isApproved ? 'approval' : 'rejection'
          } data from the overview`}
        />
      </Box>
    );
  }

  return (
    <RoundBorderBox bgcolor={'grey.100'} mt={3} p={3}>
      <Stack rowGap={2}>
        <Box>
          <CardTitle title={`Works package ${statusText}`} fontSize="1.1em" />
          <Typography>
            This works package checks were {statusText} at{' '}
            {formatDateTimeDayMonthYearHourMins(
              parseISO(approvalRejectionDate)
            )}{' '}
            by {approvalRejectionUser}
          </Typography>
        </Box>
        {!isApproved ? (
          <Box p={2} bgcolor={'white'}>
            <Typography fontWeight={600}>Description</Typography>
            <Typography color={'grey.600'}>
              {approvalRejectionReason}
            </Typography>
          </Box>
        ) : null}
      </Stack>
    </RoundBorderBox>
  );
};
