import {
  addMinutes,
  endOfMonth,
  format,
  parseISO,
  startOfMonth,
  subDays,
  subMonths,
  differenceInDays,
  differenceInCalendarMonths,
  isValid,
} from 'date-fns';

export const GetStartDateOfLastMonth = () => {
  const timeZoneOffset = new Date().getTimezoneOffset();

  let startDateMonth = startOfMonth(subMonths(new Date(), 1));
  startDateMonth = addMinutes(startDateMonth, timeZoneOffset * -1);

  return startDateMonth;
};

export const GetEndDateOfLastMonth = () => {
  const endDateMonth = endOfMonth(subMonths(new Date(), 1));

  return endDateMonth;
};

export const GetDateDaysAgo = (daysAgo: number) => {
  const timeZoneOffset = new Date().getTimezoneOffset();

  let dateDaysAgo = subDays(new Date(), daysAgo);
  dateDaysAgo = addMinutes(dateDaysAgo, timeZoneOffset * -1);

  return dateDaysAgo;
};

export const getDifferenceInMonths = (startDate: string, endDate: string) =>
  differenceInCalendarMonths(parseISO(endDate), parseISO(startDate)) + 1;

export const getDateOnlyIsoString = (val: Date) => format(val, 'yyyy-MM-dd');

export const getDifferenceInDays = (val: Date) =>
  differenceInDays(val, new Date());

export const formatDateTime = (val: Date, formatStr: string) =>
  format(val, formatStr);

export const formatDateDayMonthYear = (val: Date) => {
  if (val instanceof Date) {
    return format(val, 'do MMMM yyyy');
  }
  return format(parseISO(val), 'do MMMM yyyy');
};

export const formatDateDayShortMonthYear = (val: Date) =>
  format(val, 'do MMM yyyy');

export const formatDateMonthYear = (val: Date) => {
  if (val instanceof Date) {
    return format(val, 'MMMM yyyy');
  }
  return format(parseISO(val), 'MMMM yyyy');
};

export const formatDateShortMonthYear = (val: Date) => format(val, 'MMM yy');

export const formatDateShortMonthLongYear = (val: Date) =>
  format(val, 'MMM yyyy');

export const formatDateTimeDayMonthHoursMins = (val: Date) =>
  format(val, 'd MMM HH:mm');

export const formatDateTimeDayMonthYearHourMins = (val: Date | string) =>
  format(typeof val === 'string' ? parseISO(val) : val, 'd/M/yyyy, h:mmaaa');

export function isValidDate(maybeDateObj: unknown): maybeDateObj is Date {
  return isValid(maybeDateObj);
}
