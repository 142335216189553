import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  Button,
  TextField,
  MenuItem,
  Box,
  Collapse,
  FormHelperText,
} from '@mui/material';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import {
  useAddPullInProcessDocumentsMutation,
  useLazyGetPullInProcessDocumentByIdQuery,
  useUpdatePullInProcessDocumentByIdMutation,
} from 'api/pullInProcess';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { EPullinProcessDocumentType } from 'enums/EPullinProcessDocumentTypes';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { usePullInDocumentContext } from 'pages/pullInProcessPage/common/context/pullInDocumentContext';
import { PullInFileDownloadWrapper } from 'pages/pullInProcessPage/components/pullInFileDownloadWrapper';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zDocumentTypeSchema } from 'types/pullInProcess/documentDetails.zod';
import {
  EPullInDocumentType,
  EPullInDocumentTypeLanguage,
  TBuildingDocumentForm,
} from 'types/pullInProcess/documentDetailsTypes';
import {
  extractErrorMessages,
  instanceOfTransactionErrorWithPropertyErrors,
} from 'util/ApiUtils';
import { setServerSideFormErrors } from 'util/formUtils';

export function AddEditDocument() {
  const { isShowing, hideModal } = usePullInDocumentContext();
  const formId = 'temp';

  return (
    <>
      <StyledDrawer anchor="right" open={isShowing}>
        <DialogTitle component="div">
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h1" component="span">
              Add Document
            </Typography>
            <IconButton
              onClick={hideModal}
              aria-label="Close drawer"
              name="Close drawer"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <PullInProcessDocumentForm formId={formId} />
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button variant="outlined" onClick={hideModal}>
              Cancel
            </Button>

            <LoadingButton variant="contained" type="submit" form={formId}>
              Save
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </StyledDrawer>
    </>
  );
}

type TPullInProcessBuildingDocumentProps = {
  formId: string;
};

export function PullInProcessDocumentForm({
  formId,
}: TPullInProcessBuildingDocumentProps) {
  const { pullInProcessId } = usePullInProcessContext();
  const { modalData, hideModal } = usePullInDocumentContext();

  const [postBuildingDocument] = useAddPullInProcessDocumentsMutation();
  const [putBuildingDocument] = useUpdatePullInProcessDocumentByIdMutation();
  const [getBuildingDocument] = useLazyGetPullInProcessDocumentByIdQuery();

  const form = useForm<TBuildingDocumentForm>({
    defaultValues: async () => {
      if (modalData) {
        const { attachmentFile, ...rest } = await getBuildingDocument({
          documentId: modalData,
          buildingId: pullInProcessId,
        }).unwrap();
        return {
          ...rest,
          fileId: attachmentFile.id,
          title: attachmentFile.name,
        };
      }
      return Promise.resolve({} as TBuildingDocumentForm);
    },
    resolver: zodResolver(zDocumentTypeSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = form;

  const title = watch('title');
  const fileId = watch('fileId');
  const documentTypeId = watch('documentTypeId');

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onSubmit = async (formData: TBuildingDocumentForm) => {
    try {
      modalData
        ? await putBuildingDocument({
            buildingId: pullInProcessId,
            documentId: modalData,
            payload: formData,
          }).unwrap()
        : await postBuildingDocument({
            buildingId: pullInProcessId,
            ...formData,
          }).unwrap();

      createSuccessSnackbar(`New Document ${modalData ? 'Updated' : 'Added'}`);
      hideModal();
    } catch (e) {
      if (instanceOfTransactionErrorWithPropertyErrors(e)) {
        const tError = e.data;
        tError.propertyErrors.forEach(() => {
          setServerSideFormErrors(form, tError);
        });
      } else {
        createErrorSnackbar(extractErrorMessages(e));
      }
    }
  };

  const _fileInputName = 'fileId';

  const { uploadFile, uploadFileStatus } = useFileUpload();

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'PullInBuildingDocument',
      onSuccess: payload => {
        setValue(`${_fileInputName}`, payload.id, {
          shouldValidate: true,
        });
      },
    });
  };

  const handleOnDeleteFileUpload = async () => {
    setValue(`${_fileInputName}`, '');
  };

  const deleteEvidenceFile = () => {
    setValue(`${_fileInputName}`, '');
    setValue('title', '');
  };

  return (
    <FormProvider {...form}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Box paddingTop={1} padding={2}>
          <Controller
            control={control}
            name="documentTypeId"
            defaultValue={EPullInDocumentType.TitleDocument}
            render={({ field: fieldRest }) => (
              <TextField
                {...fieldRest}
                select
                fullWidth
                label="Document type"
                error={!!errors.documentTypeId}
                helperText={errors.documentTypeId?.message}
                onChange={e => {
                  setValue('documentTypeId', +e.target.value);
                }}
              >
                {Object.entries(EPullInDocumentTypeLanguage).map(
                  ([value, label], i) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  )
                )}
              </TextField>
            )}
          />
          <Collapse
            in={documentTypeId === EPullInDocumentType.Other}
            unmountOnExit
          >
            <Controller
              control={control}
              name="otherDocumentType"
              defaultValue=""
              render={({ field: fieldRest }) => (
                <TextField
                  fullWidth
                  {...fieldRest}
                  label="Please provide more information"
                  error={!!errors.otherDocumentType}
                  helperText={errors.otherDocumentType?.message}
                />
              )}
            />
          </Collapse>
          <Box
            marginTop={2}
            p="1rem 1rem 3rem"
            sx={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
          >
            <Typography
              variant="subtitle1"
              fontSize={'0.95rem'}
              fontWeight="900"
              marginTop={2}
              marginBottom={2}
              component="span"
            >
              Upload evidence of communication
            </Typography>
            <Box marginTop={2}>
              {title && fileId ? (
                <Box display="flex" flexDirection="row">
                  <PullInFileDownloadWrapper
                    fileName={title}
                    pullInDocumentTypeId={modalData || ''}
                    pullInDocumentType={EPullinProcessDocumentType.Document}
                  />

                  <IconButton
                    size="small"
                    name="Delete File"
                    onClick={deleteEvidenceFile}
                    aria-label="Delete File"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </Box>
              ) : (
                <>
                  <FileUploadInputSelect
                    id={_fileInputName}
                    inputName={_fileInputName}
                    label="Click to upload or drag and drop file"
                    subLabel="DOC, DOCX, PDF, XLS, XLSX, EML, PNG, JPG, JPEG or MSG (max. 30MB)"
                    accept=".pdf,.docx,.xls,.xlsx,.eml,.jpg,.jpeg,.png,.msg"
                    onFileUpload={file => handleOnFileUpload(file)}
                    onFileDelete={() => handleOnDeleteFileUpload()}
                    isUploading={uploadFileStatus.isLoading}
                    uploaded={uploadFileStatus.isSuccess}
                  />
                  {errors.fileId?.message ? (
                    <FormHelperText error>
                      {errors.fileId?.message}
                    </FormHelperText>
                  ) : null}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
