export enum ERejectedPaymentStatus {
  Missed = 1,
  Rejected = 2,
}

export const EERejectedPaymentStatusLanguage: {
  [key in ERejectedPaymentStatus]: string;
} = {
  [ERejectedPaymentStatus.Missed]: 'Missed',
  [ERejectedPaymentStatus.Rejected]: 'Rejected',
} as const;
