import { Button, Typography } from '@mui/material';
import { useDeleteSystemNotificationMutation } from 'api/administration/systemNotifications';
import { useLocalSnackbar } from 'hooks';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IDeleteSystemNotificationDialogProps {
  systemNotificationId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function DeleteSystemNotificationDialog({
  systemNotificationId,
  onConfirm,
  onCancel,
}: IDeleteSystemNotificationDialogProps) {
  const [deleteSystemNotification] = useDeleteSystemNotificationMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onDeleteSystemNotification = async () => {
    try {
      await deleteSystemNotification(systemNotificationId);
      createSuccessSnackbar('Successfully deleted system notification');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to delete system notification`);
    }
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you want to delete this system notification?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDeleteSystemNotification}>
          Delete System Notification
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
