import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';

export const muiTextFieldTheme: {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
  variants?: ComponentsVariants['MuiTextField'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      background: 'white',
      boxShadow: 'rgba(38, 50, 56, 0.05) 0px 1px 2px;',
      fontSize: '16px',
      marginBottom: theme.spacing(3),
      '& > label': {
        fontSize: '16px',
        '&.MuiFormLabel-filled': {
          top: '0',
        },
        '&.Mui-focused': {
          top: '0',
        },
      },
      '& input': {
        fontSize: '16px',
      },
      '& input:-webkit-autofill::first-line, & input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
        {
          WebkitBoxShadow: '0 0 0 30px white inset !important',
          fontSize: '16px',
          fontFamily: 'Open Sans, Arial',
        },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: blueGrey[200],
      },
      '& .MuiOutlinedInput-root button': {
        width: 'auto',
        minWidth: '1px',
        margin: 0,
        padding: 0,
        '& svg': {
          width: '1.25rem',
        },
      },
      '& .MuiInputBase-sizeSmall input': {
        paddingTop: '7.5px',
        paddingBottom: '7.5px',
      },
      '& .Mui-disabled': {
        background: '#f5f5f5',
        borderRadius: '4px',
      },
      '& label.Mui-disabled': {
        background: 'none',
      },
      '& label.Mui-error': {
        color: '#666666',
      },
    }),
  },
};
