import { ReactNode } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab';
import {
  Alert,
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetNotesQuery } from 'api/task';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FormSkeleton } from 'common/components/skeletons';
import { useTasksGridContext } from 'common/components/taskGrid';
import { useEllipsisMenu } from 'hooks';
import {
  ECmsTaskStatusLanguage,
  ENoteMenuType,
} from 'types/tasks/CmsTaskTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const ViewTaskNoteContent = ({
  taskId,
  isDisplayed,
  description,
  buildingName,
  applicant,
  createdBy,
  createdDate,
}: {
  taskId: string;
  isDisplayed: boolean;
  description: string;
  buildingName: string;
  applicant: string;
  createdBy: string;
  createdDate: string;
}) => {
  const {
    noteModalState: { editNote },
    canUserCreateTasks,
  } = useTasksGridContext();

  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const handleMenuItemClick = (menuType: ENoteMenuType) => {
    switch (menuType) {
      case ENoteMenuType.EditNote:
        editNote({ taskId, noteId: selectedId });
        break;
    }

    handleMenuClose();
  };

  const param = isDisplayed ? taskId : skipToken;
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: getNotesQueryData,
  } = useGetNotesQuery(param);

  const data = getNotesQueryData ?? [];

  return (
    <Box>
      <Box mx={2} mb={2}>
        <Grid container>
          <Grid item xs={5}>
            <Typography variant="h2" fontSize={'0.95rem'} fontWeight="900">
              Description
            </Typography>

            <Typography
              variant="body1"
              style={{ whiteSpace: 'pre-line' }}
              sx={{
                mb: 2,
              }}
            >
              {description}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h2" fontSize={'0.95rem'} fontWeight="900">
              Building Name
            </Typography>

            <Typography
              variant="body1"
              style={{ whiteSpace: 'pre-line' }}
              sx={{
                mb: 2,
              }}
            >
              {buildingName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h2" fontSize={'0.95rem'} fontWeight="900">
              Applicant
            </Typography>

            <Typography
              variant="body1"
              style={{ whiteSpace: 'pre-line' }}
              sx={{
                mb: 2,
              }}
            >
              {applicant}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h2" fontSize={'0.95rem'} fontWeight="900">
              Created By
            </Typography>

            <Typography
              variant="body1"
              style={{ whiteSpace: 'pre-line' }}
              sx={{
                mb: 2,
              }}
            >
              {createdBy ? createdBy : 'System Generated'}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h2" fontSize={'0.95rem'} fontWeight="900">
              Created Date
            </Typography>
            <Typography
              variant="body1"
              style={{ whiteSpace: 'pre-line' }}
              sx={{
                mb: 2,
              }}
            >
              {createdDate
                ? getUkDateStringFromJsonDateString(createdDate)
                : '-'}
            </Typography>
          </Grid>
        </Grid>

        <Typography
          variant="h2"
          fontSize={'0.95rem'}
          fontWeight="900"
          style={{ whiteSpace: 'pre-line' }}
        >
          Task Notes
        </Typography>

        {isSuccess && data.length <= 0 ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            No notes found.
          </Alert>
        ) : null}
      </Box>

      {isLoading ? <FormSkeleton /> : null}

      <AlertForQueryErrorOrNull isError={isError} error={error} />

      {isSuccess && data.length > 0 ? (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {data.map((note, i) => {
            return (
              <TimelineItem key={note.subtaskId} sx={{ mt: 0 }}>
                <TimelineSeparator>
                  <TimelineDot />
                  {i === data.length - 1 ? null : <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="nowrap"
                    columnGap={5}
                    sx={{
                      p: 1,
                      pr: 3,
                      transition: 'all 250ms linear',
                      borderRadius: 1,
                      '&:hover': {
                        backgroundColor: 'grey.50',
                      },
                    }}
                  >
                    <Grid item flexGrow={1}>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ wordBreak: 'break-all' }}
                        >
                          {note.description}
                        </Typography>
                      </Box>
                      <Grid container gap={5} mt={2} mb={3} alignItems="center">
                        {[
                          {
                            value: getUkDateStringFromJsonDateString(
                              note.dueDate
                            ),
                            icon: solid('calendar'),
                          },
                          {
                            value: note.assigneeName,
                            icon: solid('person'),
                          },
                          {
                            value: (
                              <Box>
                                <Typography
                                  display="inline"
                                  variant="caption"
                                  sx={{ mr: 1 }}
                                >
                                  Status
                                </Typography>
                                <Chip
                                  size="small"
                                  label={ECmsTaskStatusLanguage[note.status]}
                                  sx={{ fontSize: '0.85rem' }}
                                />
                              </Box>
                            ),
                          },
                        ].map((item, i) => (
                          <GridItem key={i} item={item} />
                        ))}
                      </Grid>
                    </Grid>
                    {canUserCreateTasks ? (
                      <Grid item flexBasis="30px">
                        <IconButton
                          onClick={e => {
                            handleEllipsisClick(note.subtaskId, e);
                          }}
                          sx={{
                            pointerEvents: 'auto',
                            flexGrow: 1,
                            backgroundColor: 'grey.200',
                            p: 1,
                            m: 0,
                          }}
                          name="Actions"
                          aria-label="Actions"
                        >
                          <FontAwesomeIcon
                            icon={solid('ellipsis')}
                            size="xs"
                            fixedWidth
                          />
                        </IconButton>
                        {selectedId === note.subtaskId ? (
                          <Menu
                            id={`note-menu-${note.subtaskId}`}
                            anchorEl={ellipsisMenuAnchor}
                            open={Boolean(ellipsisMenuAnchor)}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                handleMenuItemClick(ENoteMenuType.EditNote)
                              }
                            >
                              Edit Note
                            </MenuItem>
                          </Menu>
                        ) : null}
                      </Grid>
                    ) : null}
                  </Grid>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      ) : null}
    </Box>
  );
};

const GridItem = ({
  item: { value, icon },
}: {
  item: { value: ReactNode; icon?: IconDefinition };
}) => {
  return (
    <Grid item>
      <Typography variant="caption" color="grey.700">
        {icon ? <FontAwesomeIcon icon={solid('calendar')} /> : null}
        {typeof value === 'string' ? (
          <Typography variant="caption" ml={1}>
            {value}
          </Typography>
        ) : (
          value
        )}
      </Typography>
    </Grid>
  );
};
