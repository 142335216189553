import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { differenceInDays, parseISO } from 'date-fns';
import { useGetScheduleOfWorksOverviewQuery } from 'api/application/scheduleOfWorksApi';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import {
  ApplicationItemGroupNavigator,
  ColumnContainer,
} from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { ScheduleOfWorksSectionOutlet } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksSectionOutlet';
import {
  EScheduleOfWorksSection,
  EScheuduleOfWorksStatus,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import {
  ScheduleOfWorksContextProvider,
  useScheduleOfWorksContext,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/context/ScheduleOfWorksContext';
import { ScheduleOfWorksReferrals } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/Referrals/ScheduleOfWorksReferrals';
import { ScheduleOfWorksReferralsSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/Referrals/ScheduleOfWorksReferralsSection';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import { formatDateDayShortMonthYear } from 'util/DateUtils';

const getDateString = (date: string) => {
  const daysAgo = differenceInDays(new Date(), parseISO(date));

  return `${formatDateDayShortMonthYear(parseISO(date))} (${daysAgo} ${
    daysAgo === 1 ? 'day' : 'days'
  } ago)`;
};

const getChipLabel = (status: number): string => {
  if (status === EScheuduleOfWorksStatus.NotStarted) {
    return 'Not Started';
  } else if (status === EScheuduleOfWorksStatus.InProgress) {
    return 'In Progress';
  } else if (status === EScheuduleOfWorksStatus.Submitted) {
    return 'Submitted ';
  } else if (status === EScheuduleOfWorksStatus.Approved) {
    return 'Approved';
  }
  return 'Unknown Status';
};

const useCategories = () => {
  return [
    {
      categoryTitle: null,
      detailItems: [
        {
          id: EScheduleOfWorksSection.Overview,
          sectionName: 'Overview',
        },
        {
          id: EScheduleOfWorksSection.CostProfile,
          sectionName: 'Cost profile',
        },
        {
          id: EScheduleOfWorksSection.LatestPaymentRequest,
          sectionName: 'Latest payment request',
        },
      ],
    },
    {
      categoryTitle: 'Confirm schedule of works',
      detailItems: [
        {
          id: EScheduleOfWorksSection.ScheduleOfWorksChecks,
          sectionName: 'Schedule of works checks',
        },
      ],
    },
  ];
};

export const ScheduleOfWorksSection = () => {
  return (
    <ScheduleOfWorksContextProvider>
      <ScheduleOfWorksSectionMain />
    </ScheduleOfWorksContextProvider>
  );
};

const ScheduleOfWorksSectionMain = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const { isReferralsSectionShowing, currentStatus } = useScheduleOfWorksContext();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        scheduleOfWorksId: selectedItem.id,
      }
    : skipToken;

  const { data } = useGetScheduleOfWorksOverviewQuery(request);
  const { setSelectedItem } = useApplicationLiveProjectContext();

  const {
    selectedItemId: selectedScheduleOfWorksItemId,
    setSelectedItemId: setSelectedScheduleOfWorksItemId,
  } = useScheduleOfWorksContext();

  const categories = useCategories();

  const isSectionEnabled = (detailItemId: EScheduleOfWorksSection) => {
    if (detailItemId === EScheduleOfWorksSection.Overview) {
      return true;
    }

    if (
      currentStatus !== EScheuduleOfWorksStatus.NotStarted &&
      currentStatus !== EScheuduleOfWorksStatus.InProgress
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={3.5}>
          <RoundBorderBox sx={{ p: 2, mb: 2 }}>
            <Button
              variant="text"
              onClick={() => setSelectedItem(null)}
              sx={{ pl: 0, mb: 0.5 }}
            >
              {'< Back to Live Project'}
            </Button>
            <CardTitle title={'Schedule of works'} fontSize="1.2em" />
            <Chip
              label={
                data?.status ? getChipLabel(data.status) : 'Unknown Status'
              }
              sx={{
                mb: 2,
              }}
            />
            {data?.dateSubmitted && (
              <Stack direction="column" gap={2}>
                <Typography fontWeight={600}>Date Submitted</Typography>
                <Typography>
                  {data.dateSubmitted &&
                  parseISO(data.dateSubmitted).getFullYear() > 2020
                    ? getDateString(data.dateSubmitted)
                    : '-'}
                </Typography>
              </Stack>
            )}
          </RoundBorderBox>

          <ColumnContainer sx={{ mb: 2 }}>
            <Box p={2}>
              <ApplicationItemGroupNavigator
                overrideSelected={isReferralsSectionShowing}
                itemGroups={categories.map((category, categoryIndex) => {
                  return {
                    title: category.categoryTitle,
                    isEnabled: true,
                    items: category.detailItems.map(
                      (detailItem, detailIndex) => {
                        const sectionEnabled = isSectionEnabled(detailItem.id);
                        return {
                          title: detailItem.sectionName,
                          isSelected:
                            selectedScheduleOfWorksItemId === detailItem.id,
                          onClick: () => {
                            if (sectionEnabled) { 
                              setSelectedScheduleOfWorksItemId(detailItem.id);
                            }
                          },
                        };
                      }
                    ),
                  };
                })}
              />
            </Box>
          </ColumnContainer>
          <ColumnContainer>
            <Box p={2}>
              <ScheduleOfWorksReferrals />
            </Box>
          </ColumnContainer>
        </Grid>
        <Grid item xs={8.5}>
          <ColumnContainer
            sx={{
              border: 'none',
            }}
          >
            <Box display={isReferralsSectionShowing ? 'none' : 'block'}>
              <ScheduleOfWorksSectionOutlet />
            </Box>
            <Box display={isReferralsSectionShowing ? 'block' : 'none'}>
              <ScheduleOfWorksReferralsSection />
            </Box>
          </ColumnContainer>
        </Grid>
      </Grid>
    </>
  );
};
