import { tupleToSelectOption } from 'util/AppUtils';

export enum ETeam {
  DaviesOps = 1,
  HomesEnglandOperations = 2,
  HomesEnglandCommercial = 3,
  HomesEnglandProduct = 4,
  HomesEnglandData = 5,
  HomesEnglandTechnical = 6,
}

export const ETeamLanguage: { [key in ETeam]: string } = {
  [ETeam.DaviesOps]: 'Davies Ops',
  [ETeam.HomesEnglandOperations]: 'Homes England Operations',
  [ETeam.HomesEnglandCommercial]: 'Homes England Commercial',
  [ETeam.HomesEnglandProduct]: 'Homes England Product',
  [ETeam.HomesEnglandData]: 'Homes England Data',
  [ETeam.HomesEnglandTechnical]: 'Homes England Technical',
} as const;

export const teamLanguageTuple = Object.entries(ETeamLanguage);

export const teamOptions = teamLanguageTuple.map(tupleToSelectOption);