import { Typography } from '@mui/material';

export const Intro = ({
  heading,
  subHeading,
}: {
  heading: string;
  subHeading?: string;
}) => {
  return (
    <>
      <Typography variant="h3" fontWeight={600} fontSize="1em">
        {heading}
      </Typography>
      {subHeading ? (
        <Typography
          variant="h3"
          fontWeight={400}
          fontSize="0.9em"
          color="grey.400"
        >
          {subHeading}
        </Typography>
      ) : null}
    </>
  );
};
