import { DataTableResponse } from 'common/types/DataTableResponse';
import { EPullInBuildingHeightType } from 'enums/EPullInBuildingHeightType';
import { EPullInProcessContactType } from 'enums/EPullInProcessContactType';
import { EPullInProcessStatus } from 'enums/EPullInProcessStatus';
import {
  TPullInProcessOverview,
  TPullInProcessSummary,
  TPullInProcesscontacts,
  TSourceOfInformationList,
  TAddBuildingRecord,
} from 'types/pullInProcess/addBuildingRecordTypes';
import {
  EPullInCommunicationType,
  IPullinProcessCommunication,
} from 'types/pullInProcess/communicationsTypes';
import { TContactDetails } from 'types/pullInProcess/contactDetailsTypes';
import {
  TBuildingDocument,
  EPullInDocumentType,
} from 'types/pullInProcess/documentDetailsTypes';
import {
  TPullInProcessGridListItem,
  TPullInProcessKpis,
} from 'types/pullInProcess/pullInProcessTypes';

export const pullInProcessGridData: DataTableResponse<TPullInProcessGridListItem> =
  {
    results: [
      {
        id: '1',
        uniqueId: '100000',
        buildingAddress: 'Example line 1, SE1 WTG',
        defaultContact: 'Richard Mounty',
        company: 'Johns ltd (230203212)',
        contactType: 'Freeholder',
        stage: 'stage 1 - letter 4',
        creationDate: new Date().toLocaleDateString("en-GB"),
        dateLastUpdated: new Date().toLocaleDateString("en-GB"),
        status: EPullInProcessStatus.Live,
      },
      {
        id: '2',
        uniqueId: '100001',
        buildingAddress: 'Example line 2, SE1 WTG',
        defaultContact: 'Carl B',
        company: 'Matariki ltd (440203212)',
        contactType: 'Freeholder',
        stage: 'stage 2 - letter 1',
        creationDate: new Date().toLocaleDateString("en-GB"),
        dateLastUpdated: new Date().toLocaleDateString("en-GB"),
        status: EPullInProcessStatus.NotEligible,
        dueDate: new Date().toLocaleDateString("en-GB"),
      },
      {
        id: '3',
        uniqueId: '100000',
        buildingAddress: 'Example line 1, SE1 WTG',
        contactType: 'Freeholder',
        stage: 'stage 1 - letter 4',
        creationDate: new Date().toLocaleDateString("en-GB"),
        dateLastUpdated: new Date().toLocaleDateString("en-GB"),
        status: EPullInProcessStatus.Live,
      },
    ],
    count: 3,
  };

export const pullInProcessBuildingDetailsData: TAddBuildingRecord = {
  address: {
    id: '1',
    buildingNameNumber: 'hilton 2',
    line1: 'line 1',
    line2: 'line 2',
    county: 'county',
    country: { name: 'Wales', id: 2 },
    townCity: 'town City',
    postCode: '123',
    localAuthority: 'local authority',
  },
  sourceOfInformation: {
    id: 1,
    name: 'Source Info',
    hasFreeText: false,
  },
  sourceOfInformationOther: 'Other souce',
  buildingHeightTypeId: EPullInBuildingHeightType.EighteenMetresPlus,
};

export const pullInProcessKpisData: TPullInProcessKpis = {
  preApplications: 100,
  noContactDetails: {
    totalCount: 20,
    totalPercentage: 20,
  },
  live: {
    totalCount: 20,
    totalPercentage: 20,
  },
  notEligible: {
    totalCount: 20,
    totalPercentage: 20,
  },
};

export const pullInProcessSourceOfInformationData: TSourceOfInformationList = [
  {
    id: 1,
    name: 'souce 1',
    hasFreeText: false,
  },
  {
    id: 2,
    name: 'souce 2',
    hasFreeText: true,
  },
];

export const pullInProcessSummary: TPullInProcessSummary = {
  address: {
    id: '1',
    buildingNameNumber: 'hilton 2',
    line1: 'line 1',
    line2: 'line 2',
    county: 'county',
    country: { name: 'Wales', id: 2 },
    townCity: 'town City',
    postCode: '123',
    localAuthority: 'local authority',
  },
  creationDate: new Date().toString(),
  lastCommunication: new Date().setDate(new Date().getDate() + 1).toString(),
  stage: 'Stage 2 - Letter 3',
  status: EPullInProcessStatus.NoContactDetails,
  uniqueId: '123',
};
export const pullInProcessOverview: TPullInProcessOverview = {
  address: {
    id: '1',
    buildingNameNumber: 'hilton 3',
    line1: 'line 1',
    line2: 'line 2',
    county: 'county',
    country: { name: 'Wales', id: 1 },
    townCity: 'town City',
    postCode: '123',
    localAuthority: 'local authority',
  },
  buildingHeight: EPullInBuildingHeightType.EighteenMetresPlus,
  freeholder: {
    companyName: 'free holder company',
    firstName: 'Free',
    surname: 'Holder name',
  },
  managingAgent: {
    companyName: 'managing agent company',
    firstName: 'Managing',
    surname: 'Agent name',
  },
  responsibleEntity: {
    companyName: 'responsibileEntity company',
    firstName: 'Responsibility',
    surname: 'Entity name',
  },
  sourceOfInformation: {
    hasFreeText: true,
    id: 12,
    name: 'source of info name',
  },
};

export const pullInProcessCommunication = {
  communicationId: '25a98406-7547-40fe-bc9b-6ecfb860c0ae',
  communicationTypeId: 2,
  communicationReceivedDateTime: '2023-11-08T01:49:00Z',
  isInbound: false,
  isAutomated: false,
  text: 'letters for all the contacts',
  attachmentFile: {
    id: '0bc3a367-68e0-4f4c-9257-1bf641dd802f',
    name: 'Image_created_with_a_mobile_phone.png',
  },
};
export const pullInProcessCommunications: IPullinProcessCommunication[] = [
  {
    communicationId: '999',
    communicationTypeId: EPullInCommunicationType.Phone,
    addedOnDateTime: new Date(),
    sentOnDateTime: new Date(),
    sentByName: 'John',
    recipientName: 'tim',
    text: 'Notes relevent to communication type',
    isAutomated: true,
    isInbound: false,
    communicationStageTypeId: 1,
  },
  {
    communicationId: '11',
    communicationTypeId: EPullInCommunicationType.Letter,
    addedOnDateTime: new Date(),
    sentOnDateTime: new Date(),
    sentByName: 'Sarah',
    recipientName: 'Atlas',
    text: 'Notes relevent to communication type',
    isAutomated: false,
    isInbound: true,
    communicationStageTypeId: 1,
  },
  {
    communicationId: '22',
    communicationTypeId: EPullInCommunicationType.Email,
    addedOnDateTime: new Date(),
    sentOnDateTime: new Date(),
    sentByName: 'Sarah',
    recipientName: 'Atlas',
    text: 'Notes relevent to communication type',
    isAutomated: false,
    isInbound: true,
    communicationStageTypeId: 2,
  },
  {
    communicationId: '44',
    communicationTypeId: EPullInCommunicationType.Note,
    addedOnDateTime: new Date(),
    sentOnDateTime: new Date(),
    sentByName: 'Sarah',
    recipientName: 'Atlas',
    text: 'Notes relevent to communication type',
    isAutomated: false,
    isInbound: true,
    communicationStageTypeId: 1,
  },
];

export const pullInProcessDocuments: TBuildingDocument[] = [
  {
    documentId: '123',
    attachmentFile: { id: 'FILE2765', name: 'Process Doc 1' },
    uploadDate: new Date(),
    uploadedBy: 'string',
    documentTypeId: EPullInDocumentType.TitleDocument,
    otherDocumentType: '',
  },
  {
    documentId: '456',
    attachmentFile: { id: 'FILE1234', name: 'Process Doc 2' },
    uploadDate: new Date(),
    uploadedBy: 'string',
    documentTypeId: EPullInDocumentType.IncorporationDocument,
    otherDocumentType: '',
  },
  {
    documentId: '789',
    attachmentFile: { id: 'FILE9999', name: 'Process Doc 3' },
    uploadDate: new Date(),
    uploadedBy: 'string',
    documentTypeId: EPullInDocumentType.Email,
    otherDocumentType: '',
  },
];

export const pullInProcessContacts: TPullInProcesscontacts = [
  {
    id: '123',
    companyName: '',
    companyNumber: '1232132',
    contactType: EPullInProcessContactType.ManagingAgent,
    isDefaultContact: true,
    firstName: 'Timothy',
    surname: 'Whackrow',
  },
  {
    id: '456',
    companyName: 'aest company name',
    companyNumber: '132132',
    contactType: EPullInProcessContactType.RegisteredProvider,
    isDefaultContact: false,
  },
  {
    id: '789',
    companyName: 'xest company name',
    companyNumber: '1432132',
    contactType: EPullInProcessContactType.Freeholder,
    isDefaultContact: false,
  },
];

export const pullInProcessContactDetails: TContactDetails = {
  companyRegistrationNumber: '12354',
  companyName: 'test name company',
  contactTypeId: EPullInProcessContactType.Other,
  otherContactType: 'more info',
  defaultContact: true,
  communicationStageTypeId: 1,
  address: {
    id: 'guid-qweoir-sdlfjk',
    line1: 'line 1 test',
    line2: 'line 2 test',
    townCity: 'Cardiff',
    county: 'Caerphilly',
    country: { name: 'Wales', id: 1 },
    postCode: 'I shouldnt have 2 post codes',
  },
  firstName: 'Jon',
  surname: 'Wilts',
  emailAddress: 'JWilts@hmeal.com',
  telephoneNumber: '192920930291',
};
