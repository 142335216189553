import { applicationApi } from 'api/application/applicationApi';
import {
  ISignatoriesResults,
  IAddEditSignatory,
  IDeleteSignatory,
  ISignatoryStatus,
} from 'types/applications/ApplicationSignatoryTypes';
import { QueryTags } from 'util/ApiUtils';

export const signatoryEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getSignatoriesList: builder.query<ISignatoriesResults, string>({
      providesTags: [QueryTags.ApplicationSignatories],
      query: applicationId => `/${applicationId}/grantfunding/signatory/list`,
    }),
    getSignatory: builder.query<IAddEditSignatory, any>({
      providesTags: [QueryTags.ApplicationSignatories],
      query: payload => ({
        url: `/${payload.applicationId}/grantfunding/signatory/${payload.signatoryId}`,
        method: 'GET',
      }),
    }),
    addSignatory: builder.mutation<void, IAddEditSignatory>({
      invalidatesTags: [QueryTags.ApplicationSignatories],
      query: payload => ({
        url: `/${payload.applicationId}/grantfunding/signatory`,
        method: 'POST',
        body: payload,
      }),
    }),
    editSignatory: builder.mutation<void, IAddEditSignatory>({
      invalidatesTags: [QueryTags.ApplicationSignatories],
      query: payload => ({
        url: `/${payload.applicationId}/grantfunding/signatory/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteSignatory: builder.mutation<void, IDeleteSignatory>({
      invalidatesTags: [QueryTags.ApplicationSignatories],
      query: payload => ({
        url: `/${payload.applicationId}/grantfunding/signatory/${payload.id}`,
        method: 'DELETE',
      }),
    }),
    setSignatoryStatus: builder.mutation<any, ISignatoryStatus>({
      invalidatesTags: [QueryTags.ApplicationSignatories],
      query: payload => ({
        url: `/${payload.applicationId}/grantfunding/signatory/${payload.id}/status`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetSignatoriesListQuery,
  useGetSignatoryQuery,
  useLazyGetSignatoryQuery,
  useAddSignatoryMutation,
  useEditSignatoryMutation,
  useDeleteSignatoryMutation,
  useSetSignatoryStatusMutation,
} = signatoryEndpoints;
