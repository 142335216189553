import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useApplicationContext } from 'pages/applicationPage/common/context';

interface IApplicationSupportContext {
  applicationId: string;
  selectedItemId: string | null;
  setSelectedItemId: (supportItemId: string | null) => void;
  readOnly: boolean;
}

const ApplicationSupportContext = createContext<
  IApplicationSupportContext | undefined
>(undefined);

interface IApplicationSupportContextProviderProps {
  children: ReactNode;
}

export const ApplicationSupportContextProvider = ({
  children,
}: IApplicationSupportContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const handleSetSelectedItemId = (itemId: string | null) => {
    setSelectedItemId(itemId);
  };

  const readOnly = closed;

  const value = useMemo(
    () => ({
      applicationId,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      readOnly,
    }),
    [applicationId, readOnly, selectedItemId]
  );

  return (
    <ApplicationSupportContext.Provider value={value}>
      {children}
    </ApplicationSupportContext.Provider>
  );
};

export const useApplicationSupportContext = () => {
  const context = useContext(ApplicationSupportContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationSupportContext must be used within an ApplicationSupportContextProvider'
    );
  }
  return context;
};
