import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { DateTimeAndUserRow } from 'common/components/dateTimeAndUserRow';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import {
  ESupportTicketAreaTypeLanguage,
  ESupportTicketContactMethod,
  ESupportTicketDetailType,
  ESupportTicketDetailTypeLanguage,
  ISupportTicketInfo,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { ReferToRemediationAdviserContextMenu } from 'pages/applicationPage/content/support/sections/SupportTicketSection/ReferToRemediationAdviserContextMenu';
import {
  DataTitle,
  DataValue,
  DetailItemTitle,
} from 'pages/applicationPage/content/support/sections/SupportTicketSection/common';
import { useResolutionReasons } from 'pages/applicationPage/content/support/supportHooks';
import { getGbpAmount, getUkDateStringFromJsonDateString } from 'util/AppUtils';

type SupportTicketDetailItemProps = ISupportTicketInfo['details'][number];

const getTitle = (
  supportTicketDetailType: ESupportTicketDetailType,
  remediationThirdPartyType?: string
) => {
  switch (supportTicketDetailType) {
    case ESupportTicketDetailType.Ticket:
      return 'Applicant Requested Support';
    case ESupportTicketDetailType.Referred:
      return 'Support request has been referred to Homes England';
    case ESupportTicketDetailType.RemediationAdviserAssigned:
      return `Support request has been referred to ${
        remediationThirdPartyType
          ? remediationThirdPartyType === "Legal" ? remediationThirdPartyType : `a ${remediationThirdPartyType}`
          : 'a remediation adviser'
      }`;
    case ESupportTicketDetailType.Resolved:
      return 'Support request has been resolved';
    default:
      return 'Support ticket';
  }
};

export const SupportTicketDetailItem = ({
  supportDetailItem,
}: {
  supportDetailItem: SupportTicketDetailItemProps;
}) => {
  return (
    <RoundBorderBox sx={{ p: 2 }}>
      <Box>
        <DetailItemTitle
          title={getTitle(
            supportDetailItem.supportDetailType,
            supportDetailItem.remediationThirdPartyType
          )}
        >
          <Chip
            label={
              supportDetailItem.supportDetailType !==
              ESupportTicketDetailType.RemediationAdviserAssigned
                ? ESupportTicketDetailTypeLanguage[
                    supportDetailItem.supportDetailType
                  ]
                : `${
                    ESupportTicketDetailTypeLanguage[
                      supportDetailItem.supportDetailType
                    ]
                  } - ${supportDetailItem.remediationThirdPartyType}`
            }
            size="small"
            sx={{
              color: 'grey.700',
            }}
          />
        </DetailItemTitle>
        {supportDetailItem.supportDetailType ===
        ESupportTicketDetailType.RemediationAdviserAssigned ? (
          <RemediationAdviserSection supportDetailItem={supportDetailItem} />
        ) : (
          <>
            {supportDetailItem.resolutionReason ? (
              <ResolutionDetailsSection
                resolutionReasonId={supportDetailItem.resolutionReason}
                resolutionDescription={supportDetailItem.resolutionDescription}
              />
            ) : null}

            <ContactSection supportDetailItem={supportDetailItem} />
            <CapacityGapSection supportDetailItem={supportDetailItem} />

            {supportDetailItem.description ? (
              <>
                <DataTitle title="Provide further explanation" />
                <DataValue>{supportDetailItem.description}</DataValue>
              </>
            ) : null}

            <FileSection supportDetailItem={supportDetailItem} />
          </>
        )}

        {supportDetailItem.user ? (
          <DateTimeAndUserRow
            timestamp={supportDetailItem.timestamp}
            personName={`${supportDetailItem.user.firstName} ${supportDetailItem.user.lastName}`}
            sx={{ mt: 4 }}
          />
        ) : null}
      </Box>
    </RoundBorderBox>
  );
};

const RemediationAdviserSection = ({
  supportDetailItem,
}: {
  supportDetailItem: SupportTicketDetailItemProps;
}) => {
  const getDateString = () => {
    const startDate = supportDetailItem.remediationStartDate
      ? getUkDateStringFromJsonDateString(
          supportDetailItem.remediationStartDate
        )
      : '';
    const endDate = supportDetailItem.remediationEndDate
      ? getUkDateStringFromJsonDateString(supportDetailItem.remediationEndDate)
      : '';
    if (startDate || endDate) {
      return [startDate, endDate].join(' - ');
    }

    return '';
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <DataTitle title="Third Party Type" />
          <DataValue>{supportDetailItem.remediationThirdPartyType}</DataValue>
          <Divider />
        </Grid>
        <Grid item xs={5}>
          <DataTitle title="Company" />
          <DataValue>{supportDetailItem.remediationCompany}</DataValue>
          <Divider />
        </Grid>
        <Grid item xs={1}>
          {supportDetailItem.supportDetailType ===
          ESupportTicketDetailType.RemediationAdviserAssigned ? (
            <ReferToRemediationAdviserContextMenu />
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <DataTitle title="Start/End Date" />
          <DataValue>{getDateString()}</DataValue>
          <Divider />
        </Grid>
        <Grid item xs={5}>
          <DataTitle title="Cost" />
          <DataValue>
            {getGbpAmount({
              value: supportDetailItem.remediationCost,
            })}
          </DataValue>
          <Divider />
        </Grid>
      </Grid>
    </>
  );
};

const ContactSection = ({
  supportDetailItem,
}: {
  supportDetailItem: SupportTicketDetailItemProps;
}) => {
  return supportDetailItem.contactMethod !== undefined &&
    supportDetailItem.contactMethod !== ESupportTicketContactMethod.None ? (
    <>
      <DataTitle title="How did you contact the applicant?" />
      <DataValue>
        {supportDetailItem.contactMethod ===
        ESupportTicketContactMethod.Phone ? (
          <>
            <FontAwesomeIcon icon={faPhone} style={{ marginRight: '10px' }} />
            Phone
          </>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ marginRight: '10px' }}
            />
            Email
          </>
        )}
      </DataValue>
    </>
  ) : null;
};

const CapacityGapSection = ({
  supportDetailItem,
}: {
  supportDetailItem: SupportTicketDetailItemProps;
}) => {
  return supportDetailItem.doesApplicantHaveCapacityGap !== undefined ? (
    <>
      <DataTitle
        title="Does the applicant have a knowledge gap, resource
                              issue or inexperience?"
      />
      <DataValue>
        {supportDetailItem.doesApplicantHaveCapacityGap ? 'Yes' : 'No'}
      </DataValue>
    </>
  ) : null;
};

const ResolutionDetailsSection = ({
  resolutionReasonId,
  resolutionDescription,
}: {
  resolutionReasonId: number;
  resolutionDescription?: string;
}) => {
  const { getResolutionReasonName } = useResolutionReasons();
  return (
    <>
      <DataTitle title={'Reason for resolving'} />
      <DataValue>{getResolutionReasonName(resolutionReasonId)}</DataValue>
      <DataTitle title={'How was the request resolved?'} />
      <DataValue>{resolutionDescription}</DataValue>
    </>
  );
};

const FileSection = ({
  supportDetailItem,
}: {
  supportDetailItem: SupportTicketDetailItemProps;
}) => {
  return supportDetailItem.evidenceFile ? (
    <>
      <DataTitle title="Evidence of communication" />
      <ApplicationFileDownloadWrapper
        fileName={supportDetailItem.evidenceFile.name}
        fileId={supportDetailItem.evidenceFile.id}
      />
    </>
  ) : null;
};

export const SupportTicketItem = ({
  data,
  user,
  timestamp,
  supportDetailItem,
}: {
  data: ISupportTicketInfo;
  user: SupportTicketDetailItemProps['user'];
  timestamp: SupportTicketDetailItemProps['timestamp'];
  supportDetailItem: SupportTicketDetailItemProps;
}) => {
  return (
    <RoundBorderBox sx={{ p: 2 }}>
      <DetailItemTitle
        title={getTitle(ESupportTicketDetailType.Ticket)}
      ></DetailItemTitle>

      <ContactSection supportDetailItem={supportDetailItem} />
      <CapacityGapSection supportDetailItem={supportDetailItem} />

      <DataTitle title="Explanation of support request" />
      <DataValue>{supportDetailItem.description}</DataValue>

      {supportDetailItem.evidenceFile ? (
        <>
          <DataTitle title="Evidence of communication" />
          <ApplicationFileDownloadWrapper
            fileName={supportDetailItem.evidenceFile.name}
            fileId={supportDetailItem.evidenceFile.id}
          />
        </>
      ) : null}

      {data.supportAreas.length > 0 ? (
        <>
          <DataTitle title="Areas support requested" />
          {data.supportAreas.map(supportArea => (
            <Accordion key={supportArea.supportAreaType}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontSize={'0.9em'} fontWeight={600}>
                  {ESupportTicketAreaTypeLanguage[supportArea.supportAreaType]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{supportArea.reason}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ) : null}

      {user ? (
        <DateTimeAndUserRow
          timestamp={timestamp}
          personName={`${user.firstName} ${user.lastName}`}
          sx={{ mt: 4 }}
        />
      ) : null}
    </RoundBorderBox>
  );
};
