import { ReactNode } from 'react';
import {
  Box,
  FormControl,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { FieldErrorOrNull } from 'common/components/fieldErrorOrNull';
import {
  VariationChecksBooleans,
  VariationkChecksForm,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';

export const QuestionAndComment = ({
  label,
  fieldName,
  commentFieldLabel = 'Explain Answer',
  readOnly,
}: {
  label: string;
  fieldName: keyof VariationChecksBooleans;
  commentFieldLabel?: string;
  readOnly: boolean;
}) => {
  const { control, watch } = useFormContext<VariationkChecksForm>();
  const [yesNoAnswer] = watch([fieldName]);
  return (
    <QuestionContainer>
      <Stack gap={3}>
        <Box>
          <FieldLabel label={label} />
          <Controller
            control={control}
            rules={{
              validate: value => {
                if (typeof value === 'undefined') {
                  return 'Required';
                }
              },
            }}
            name={fieldName}
            render={({ fieldState, field: { onChange, ...fieldProps } }) => (
              <>
                <FormControl>
                  <ToggleButtonGroup
                    {...fieldProps}
                    onChange={(_, val) => {
                      onChange(val);
                    }}
                    exclusive
                    disabled={readOnly}
                  >
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
                <FieldErrorOrNull fieldState={fieldState} sx={{ ml: '14px' }} />
              </>
            )}
          />
        </Box>

        {yesNoAnswer !== undefined ? (
          <Box>
            <FieldLabel label={commentFieldLabel} />
            <Controller
              control={control}
              name={`${fieldName}Comments`}
              rules={{
                required: 'Required',
                maxLength: {
                  value: 1000,
                  message: '1000 characters is the maximum allowed.',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  fullWidth
                  disabled={readOnly}
                  error={!!fieldState.error}
                  rows={3}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 0 }}
                />
              )}
            />
          </Box>
        ) : null}
      </Stack>
    </QuestionContainer>
  );
};

export const FieldLabel = ({ label }: { label: string }) => {
  return (
    <Typography variant="h3" marginBottom={1} fontWeight={600} fontSize="0.9em">
      {label}
    </Typography>
  );
};

export const QuestionContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        padding: 3,
        borderRadius: '5px',
        marginBottom: 2,
      }}
    >
      {children}
    </Box>
  );
};
