export enum EEligibilityRiskStatus {
  Accept = 1,
  Escalate = 2,
  PreApproved = 3,
}

export const EEligibilityRiskStatusStatusLanguage: {
  [key in EEligibilityRiskStatus]: string;
} = {
  [EEligibilityRiskStatus.Accept]: 'Accept',
  [EEligibilityRiskStatus.Escalate]: 'Escalate',
  [EEligibilityRiskStatus.PreApproved]: 'PreApproved',
} as const;
