import { TasksGrid } from 'common/components/taskGrid';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ApplicationTasksDataGridNavigation } from 'pages/applicationPage/content/applicationTasklist/ApplicationTasksDataGridNavigation';
import {
  ApplicationTasksGridContextProvider,
  useApplicationTasksGridContext,
} from 'pages/applicationPage/content/applicationTasklist/ApplicationTasksGridContext';

export const ApplicationTasksDataGrid = () => {
  return (
    <ApplicationTasksGridContextProvider>
      <TasksGridMain />
    </ApplicationTasksGridContextProvider>
  );
};

const TasksGridMain = () => {
  const { applicationId } = useApplicationContext();
  const {
    query,
    state: {
      gridState: { take, skip },
    },
    gridStateChanged,
  } = useApplicationTasksGridContext();
  return (
    <TasksGrid
      config={{
        type: 'ApplicationTasks',
        query,
        navigationComponent: <ApplicationTasksDataGridNavigation />,
        pagination: {
          gridStateChanged,
          take,
          skip,
        },
      }}
      applicationId={applicationId}
    />
  );
};
