import { profileApi } from 'api/profile';
import { IMyUserProfile } from 'types/administration/AdministrationUsersTypes';

const profileEndpoints = profileApi.injectEndpoints({
  endpoints: builder => ({
    getCurrentUser: builder.query<IMyUserProfile, void>({
      query: _ => `/user`,
    }),

    resetMyPassword: builder.mutation<void, void>({
      query: () => ({
        url: `/requestUpdatePassword`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCurrentUserQuery, useResetMyPasswordMutation } =
  profileEndpoints;
