import { useLocalSnackbar } from 'hooks';
import { useApplicationAndVariationIdOrNull } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { useCostsScheduleContext } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection/context';
import {
  getLatestCostSchedule,
  getPreviousCostSchedule,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection/utils';
import { extractErrorMessages } from 'util/ApiUtils';

export const useCostScheduleEditPermissionCheck = () => {
  const { canEditCostSchedule } = useCostsScheduleContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const costSchedulePermissionCheck = () => {
    if (!canEditCostSchedule) {
      createErrorSnackbar(
        'You do not have permission to edit the Cost Schedule'
      );
      return false;
    }

    return true;
  };

  return { costSchedulePermissionCheck };
};

export const useFormWrapperDependencies = () => {
  const { setSectionToEdit, data, handleSuccessfulSave } =
    useCostsScheduleContext();
  const queryParams = useApplicationAndVariationIdOrNull();
  const { createErrorSnackbar } = useLocalSnackbar();

  const costScheduleData = !data ? null : getLatestCostSchedule(data);
  const previousCostScheduleData = !data ? null : getPreviousCostSchedule(data);
  const handleCancelClick = () => setSectionToEdit(null);
  const handleUnwrappedSaveResult = (unwrappedSaveResult: Promise<void>) => {
    unwrappedSaveResult
      .then(() => {
        handleSuccessfulSave();
      })
      .catch(error => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  return {
    queryParams,
    costScheduleData,
    previousCostScheduleData,
    handleCancelClick,
    handleUnwrappedSaveResult,
  };
};
