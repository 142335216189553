export enum EApplicationStatus {
  ApplicationNotStarted = 1,
  ApplicationInProgress = 2,
  ApplicationSubmitted = 3,
  ApplicationInReview = 4,
  ApplicationApproved = 5,
  ApplicationNotEligible = 6,
  GfaIssued = 7,
  GfaSubmitted = 8,
  GfaComplete = 9,
  Completed = 10,
  Rejected = 11,
  WorksPackageInProgress = 12,
  WorksPackageSubmitted = 13,
  WorksPackageInReview = 14,
  WorksPackageApproved = 15,
  WorksPackageRejected = 16,
  ProgressReporting = 17,
  ScheduleOfWorksInProgress = 18,
  ScheduleOfWorksSubmitted = 19,
  ScheduleOfWorksInReview = 20,
  ScheduleOfWorksApproved = 21,
  PaymentRequestInProgress = 22,
  PaymentRequestSubmitted = 23,
  PaymentRequestInReview = 24,
  PaymentRequestApproved = 25,
  VariationInProgress = 26,
  VariationSubmitted = 27,
  VariationInReview = 28,
  VariationApproved = 29,
  ClosingReportInProgress = 30,
  ClosingReportSubmitted = 31,
  ClosingReportInReview = 32,
  ClosingReportApproved = 33,
  PaymentRequestRejected = 34,
  ClosingReportRejected = 35,
  WorksPackageDeedIssued = 36,
  WorksPackageDeedSubmitted = 37,
  WorksPackageDeedSigned = 38,
  VariationRejected = 39,
  WorksComplete = 40,
  WorksPackageDeedComplete = 41,
  ApplicationClosed = 42,
  ApplicationClosedReferToGla = 43
}

export const EApplicationStatusLanguage: {
  [key in EApplicationStatus]: string;
} = {
  [EApplicationStatus.ApplicationNotStarted]: 'Not Started',
  [EApplicationStatus.ApplicationInProgress]: 'Application In Progress',
  [EApplicationStatus.ApplicationSubmitted]: 'Application Submitted',
  [EApplicationStatus.ApplicationInReview]: 'Application In Review',
  [EApplicationStatus.ApplicationApproved]: 'Application Approved',
  [EApplicationStatus.ApplicationNotEligible]: 'Application Not Eligible',
  [EApplicationStatus.GfaIssued]: 'GFA Issued',
  [EApplicationStatus.GfaSubmitted]: 'GFA Submitted',
  [EApplicationStatus.GfaComplete]: 'GFA Complete',
  [EApplicationStatus.Completed]: 'Completed',
  [EApplicationStatus.Rejected]: 'Rejected',
  [EApplicationStatus.WorksPackageInProgress]: 'Works Package In Progress',
  [EApplicationStatus.WorksPackageSubmitted]: 'Works Package Submitted',
  [EApplicationStatus.WorksPackageInReview]: 'Works Package In Review',
  [EApplicationStatus.WorksPackageApproved]: 'Works Package Approved',
  [EApplicationStatus.WorksPackageRejected]: 'Works Package Rejected',
  [EApplicationStatus.ProgressReporting]: 'Progress Reporting',
  [EApplicationStatus.ScheduleOfWorksInProgress]: '	Schedule of Works In Progress',
  [EApplicationStatus.ScheduleOfWorksSubmitted]: 'Schedule of Works Submitted',
  [EApplicationStatus.ScheduleOfWorksInReview]: 'Schedule of Works In Review',
  [EApplicationStatus.ScheduleOfWorksApproved]: 'Schedule of Works Approved',
  [EApplicationStatus.PaymentRequestInProgress]: 'Payment Request In Progress',
  [EApplicationStatus.PaymentRequestSubmitted]: 'Payment Request Submitted',
  [EApplicationStatus.PaymentRequestInReview]: 'Payment Request In Review',
  [EApplicationStatus.PaymentRequestApproved]: 'Payment Request Approved',
  [EApplicationStatus.VariationInProgress]: 'Variation In Progress',
  [EApplicationStatus.VariationSubmitted]: 'Variation Submitted',
  [EApplicationStatus.VariationInReview]: 'Variation In Review',
  [EApplicationStatus.VariationApproved]: 'Variation Approved',
  [EApplicationStatus.ClosingReportInProgress]: 'Closing Report In Progress',
  [EApplicationStatus.ClosingReportSubmitted]: 'Closing Report Submitted',
  [EApplicationStatus.ClosingReportInReview]: '	Closing Report In Review',
  [EApplicationStatus.ClosingReportApproved]: 'Closing Report Approved',
  [EApplicationStatus.PaymentRequestRejected]: 'Payment Request Rejected',
  [EApplicationStatus.ClosingReportRejected]: 'Closing Report Rejected',
  [EApplicationStatus.WorksPackageDeedIssued]: 'Works Package Deed Issued',
  [EApplicationStatus.WorksPackageDeedSubmitted]: 'Works Package Deed Submitted',
  [EApplicationStatus.WorksPackageDeedSigned]: 'Works Package Deed Signed',
  [EApplicationStatus.WorksPackageDeedComplete]: 'Works Package Deed Complete',
  [EApplicationStatus.VariationRejected]: 'Variation Rejected',
  [EApplicationStatus.WorksComplete]: 'Works Complete',
  [EApplicationStatus.ApplicationClosed]: 'Application Closed',
  [EApplicationStatus.ApplicationClosedReferToGla]: 'Application Closed (Refer to GLA)',
} as const;
