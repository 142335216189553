import { useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, IconButton, Button } from '@mui/material';
import {
  useGetPaymentRecommendationDetailsQuery,
  useUpdatePaymentRecommendationEvidenceMutation,
} from 'api/application/paymentApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect';
import { FormSkeleton } from 'common/components/skeletons';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const RepresentativeFileSection = () => {
  const { createSuccessSnackbar } = useLocalSnackbar();

  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetPaymentRecommendationDetailsQuery(applicationId);

  const file = data?.moneyProtectionSchemeEvidenceFile;

  const { uploadFile, uploadFileStatus } = useFileUpload();
  const [associateEvidenceFile, associateEvidenceFileStatus] =
    useUpdatePaymentRecommendationEvidenceMutation();

  const [showFileUpload, setShowFileUpload] = useState(false);

  const handleOnDeleteFileUpload = () => {
    setShowFileUpload(true);
  };
  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'ClientMoneyProtectionEvidence',
      onSuccess: payload => {
        // File uploaded
        // Now send the id to the payment api
        if (!data?.paymentRecommendationId) return;

        associateEvidenceFile({
          applicationId,
          paymentRecommendationId: data.paymentRecommendationId,
          evidenceFileId: payload.id,
        }).then(() => {
          createSuccessSnackbar('File associated successfully');
          setShowFileUpload(false);
        });
      },
    });
  };

  const isFileUploading =
    uploadFileStatus.isLoading || associateEvidenceFileStatus.isLoading;

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  if (isSuccess) {
    return (
      <Box>
        <Typography variant="h3" fontWeight={600} fontSize="1em">
          Provide evidence of the client money protection scheme
        </Typography>

        {file && !showFileUpload ? (
          <Box display="flex" flexDirection="row">
            <ApplicationFileDownloadWrapper
              fileName={file.name}
              fileId={file.id}
            />

            {!data.recommendationSent ? (
              <IconButton
                size="small"
                onClick={() => handleOnDeleteFileUpload()}
                aria-label="Delete File"
                name="Delete File"
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            ) : null}
          </Box>
        ) : null}

        {!file || showFileUpload ? (
          <>
            <FileUploadInputSelect
              id="moneyProtectionSchemeEvidenceFile"
              accept=".pdf"
              inputName="moneyProtectionSchemeEvidenceFileId"
              isUploading={isFileUploading}
              uploadingMsg={
                associateEvidenceFileStatus.isLoading
                  ? 'Associating file with payment recommendation...'
                  : undefined
              }
              onFileUpload={file => handleOnFileUpload(file)}
              uploaded={uploadFileStatus.isSuccess}
            />
          </>
        ) : null}

        {file && showFileUpload && !isFileUploading ? (
          <Button
            variant="contained"
            onClick={() => setShowFileUpload(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        ) : null}

        <AlertForQueryErrorOrNull
          isError={uploadFileStatus.isError}
          error={uploadFileStatus.error}
        />

        <AlertForQueryErrorOrNull
          isError={associateEvidenceFileStatus.isError}
          error={associateEvidenceFileStatus.error}
        />
      </Box>
    );
  }

  return null;
};
