import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'state';

export const selectAdministrationAssessorPanelListRoot = (state: AppState) =>
  state.administration.assessorPanelList;

export const selectAdministrationAssessorPanelListGridState = createSelector(
  [selectAdministrationAssessorPanelListRoot],
  state => state.gridState
);
