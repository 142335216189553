import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ISelectedLiveProjectItem } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';

interface IApplicationLiveProjectContext {
  applicationId: string;
  selectedItem: ISelectedLiveProjectItem | null;
  setSelectedItem: (item: ISelectedLiveProjectItem | null) => void;
  readOnly: boolean;
}

const ApplicationLiveProjectContext = createContext<
  IApplicationLiveProjectContext | undefined
>(undefined);

interface IApplicationLiveProjectContextProviderProps {
  children: ReactNode;
}

export const ApplicationLiveProjectContextProvider = ({
  children,
}: IApplicationLiveProjectContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();

  const [selectedItem, setSelectedItem] =
    useState<ISelectedLiveProjectItem | null>(null);

  const handleSetSelectedItem = (item: ISelectedLiveProjectItem | null) => {
    setSelectedItem(item);
  };

  const readOnly = closed;

  const value = useMemo(
    () => ({
      applicationId,
      selectedItem,
      setSelectedItem: handleSetSelectedItem,
      readOnly,
    }),
    [applicationId, readOnly, selectedItem]
  );

  return (
    <ApplicationLiveProjectContext.Provider value={value}>
      {children}
    </ApplicationLiveProjectContext.Provider>
  );
};

export const useApplicationLiveProjectContext = () => {
  const context = useContext(ApplicationLiveProjectContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationLiveProjectContext must be used within an ApplicationLiveProjectContextProvider'
    );
  }
  return context;
};
