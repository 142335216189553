import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

interface IPullInProcessContext {
  pullInProcessId: string;
}

const pullInProcessContext = createContext<IPullInProcessContext | undefined>(
  undefined
);

interface IPullInProcessContextProviderProps {
  children: ReactNode;
}

export const PullInProcessContextProvider = ({
  children,
}: IPullInProcessContextProviderProps) => {
  const { pullInProcessId } = useParams();
  if (!pullInProcessId) {
    throw new Error('Could not get pull in id');
  }

  const value = useMemo(() => ({ pullInProcessId }), [pullInProcessId]);

  return (
    <pullInProcessContext.Provider value={value}>
      {children}
    </pullInProcessContext.Provider>
  );
};

export const usePullInProcessContext = () => {
  const context = useContext(pullInProcessContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationContext must be used within an ApplicationContextProvider'
    );
  }
  return context;
};
