import { useReducer } from 'react';
import FilterIcon from '@mui/icons-material/FilterList';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  TextField,
  Chip,
  MenuItem,
  Grid,
  Autocomplete,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import {
  useAssigneeOptions,
  useTaskTypeUtils,
} from 'common/components/task/utils';
import { useModalState } from 'hooks';
import { useAdminTasksGridContext } from 'pages/adminTasksPage';
import { mapGridStateToFilterModalState } from 'pages/adminTasksPage/utils';
import { IAdminTaskListFilters, statusOptions } from 'types/tasks/CmsTaskTypes';
import { numToStr } from 'util/AppUtils';
import { getDateOnlyIsoString } from 'util/DateUtils';

export const FilterButton = () => {
  const { isShowing, showModal, hideModal } = useModalState();
  return (
    <>
      <Button startIcon={<FilterIcon />} onClick={() => showModal()}>
        Filters
      </Button>
      {isShowing ? (
        <FilterModal
          onConfirm={() => {
            hideModal();
          }}
          onCancel={() => {
            hideModal();
          }}
        />
      ) : null}
    </>
  );
};

type ActionType = {
  type: 'dataChanged';
  payload: Partial<IAdminTaskListFilters>;
};

const reducer = (state: IAdminTaskListFilters, action: ActionType) => {
  switch (action.type) {
    case 'dataChanged':
      return { ...state, ...action.payload };

    default:
      throw new Error('invalid action.type');
  }
};

const FilterModal = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  // Current grid state
  const { state, gridStateChanged } = useAdminTasksGridContext();

  // useReducer to hold the temp state for this modal
  const [filterState, filterDispatch] = useReducer(
    reducer,
    mapGridStateToFilterModalState(state.gridState)
  );

  // Function to update the temp state via the reducer
  const updateState = (newState: Partial<IAdminTaskListFilters>) => {
    filterDispatch({
      type: 'dataChanged',
      payload: newState,
    });
  };

  const { taskTypeOptions } = useTaskTypeUtils('', '', '');
  const { assigneeOptions } = useAssigneeOptions();

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>Filters</DialogTitle>
      <DialogContent>
        <Grid container pt={1} flexDirection="column" gap={3}>
          <FilterField
            id="status"
            label="Status"
            options={statusOptions}
            payloadKey="status"
            value={filterState.status.map(numToStr)}
            updateState={updateState}
          />

          <Grid item>
            <Autocomplete
              multiple
              value={assigneeOptions.filter(ao =>
                filterState.assigneeId.includes(ao.id)
              )}
              options={assigneeOptions}
              getOptionLabel={option => option.value}
              onChange={(_, value) => {
                if (Array.isArray(value)) {
                  updateState({
                    assigneeId: value.map(v => v.id),
                  });
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.value}
                  </li>
                );
              }}
              renderInput={params => (
                <TextField {...params} label="Assignee" sx={{ mb: 0 }} />
              )}
            />
          </Grid>

          <Grid container gap={1} alignItems="center">
            <Grid item flex={1}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  format="dd/MM/yyyy"
                  slotProps={{ textField: { sx: { mb: 0 } } }}
                  value={filterState.dateRange}
                  onChange={newValue => {
                    updateState({
                      dateRange: newValue,
                    });
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  updateState({
                    dateRange: [null, null],
                  });
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>

          <FilterField
            id="taskType"
            label="Task Type"
            options={taskTypeOptions}
            payloadKey="taskType"
            value={filterState.taskType.map(numToStr)}
            updateState={updateState}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2, pt: 0, justifyContent: 'flex-end' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              // Create new temp object with dateRange removed
              const { dateRange, ...tempGridState } = filterState;

              // Create object containing grid state in the correct shape
              const newGridState = {
                ...tempGridState,
                startDate: getDateForGridState(filterState.dateRange[0]),
                endDate: getDateForGridState(filterState.dateRange[1]),
              };

              // Update grid state with selected filters
              gridStateChanged(newGridState);
              onConfirm();
            }}
          >
            Apply Filters
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const getDateForGridState = (date: Date | null) =>
  date ? getDateOnlyIsoString(date) : '';

interface IFilterFieldProps {
  id: string;
  value: string[];
  label: string;
  options: { value: string; label: string }[];
  payloadKey: string;
  updateState: (newState: Partial<IAdminTaskListFilters>) => void;
}

const FilterField = ({
  id,
  value,
  label,
  options,
  payloadKey,
  updateState,
}: IFilterFieldProps) => {
  return (
    <Grid item>
      <TextField
        id={id}
        label={label}
        select
        fullWidth
        value={value}
        sx={{ mb: 0 }}
        SelectProps={{
          onChange: ({ target: { value } }) => {
            // All fields expected to return an array
            // Check for return value and update state
            if (Array.isArray(value)) {
              updateState({
                [payloadKey]: value,
              });
            }
          },
          multiple: true,
          renderValue: selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {(selected as IFilterFieldProps['value']).map(value => {
                return (
                  <Chip
                    key={value}
                    label={
                      options.find(option => option.value === value.toString())
                        ?.label
                    }
                  />
                );
              })}
            </Box>
          ),
        }}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};
