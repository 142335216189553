import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  GetApplicationEligibilityCategoriesQueryResult,
  GetApplicationEligibilityReferralsQueryResult,
  useGetApplicationEligibilityCategoriesQuery,
  useGetApplicationEligibilityReferralsQuery,
} from 'api/application';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralStatus } from 'enums/EReferralStatus';
import { EReferralType } from 'enums/EReferralType';
import { useUserId } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  IApplicationEligibilityCategory,
  IApplicationEligibilityItem,
  IApplicationEligibilityReferral,
} from 'types/applications/ApplicationEligibilityTypes';

interface IApplicationEligibilityContext {
  applicationId: string;
  applicationEligibilityCategoriesQuery: GetApplicationEligibilityCategoriesQueryResult;
  applicationEligibilityReferralsQuery: GetApplicationEligibilityReferralsQueryResult;
  selectedItemId: string | null;
  setSelectedItemId: (itemId: string) => void;
  selectedItem: IApplicationEligibilityItem | undefined;
  onReferralQuestion: (
    section: EEligibilitySection,
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => void;
  onReferralQuestionReasonChange: (
    reason: string,
    question: EEligibilityQuestion
  ) => void;
  rejectedQuestion: EEligibilityQuestion | null;
  onRejectedQuestion: (question: EEligibilityQuestion | null) => void;
  readOnly: boolean;
  showReferApplicationDialog: boolean;
  handleShowReferApplicationDialog: (show: boolean) => void;
  handleAutoShowReferApplicationDialog: () => void;
  referrals: IApplicationEligibilityReferral[];
  showReferralsSection: () => void;
  isReferralsSectionShowing: boolean;
}

const ApplicationEligibilityContext = createContext<
  IApplicationEligibilityContext | undefined
>(undefined);

interface IApplicationEligibilityContextProviderProps {
  children: ReactNode;
}

export const ApplicationEligibilityContextProvider = ({
  children,
}: IApplicationEligibilityContextProviderProps) => {
  const { applicationId, hasApplicationsEligibilityEdit, rejected, closed } =
    useApplicationContext();
  const applicationEligibilityCategoriesQuery =
    useGetApplicationEligibilityCategoriesQuery(applicationId);
  const applicationEligibilityReferralsQuery =
    useGetApplicationEligibilityReferralsQuery(applicationId);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [isReferralsSectionShowing, setIsReferralsSectionShowing] =
    useState(false);

  const [referrals, setReferrals] = useState<IApplicationEligibilityReferral[]>(
    []
  );

  const flatItems = getFlattenedItems(
    applicationEligibilityCategoriesQuery.data?.categories ?? []
  );

  useDefaultItemId(flatItems, selectedItemId, setSelectedItemId);

  const selectedItem = flatItems.find(item => item.id === selectedItemId);

  const showReferralsSection = () => {
    setIsReferralsSectionShowing(true);
  };

  const handleSetSelectedItemId = (itemId: string) => {
    setIsReferralsSectionShowing(false);
    setSelectedItemId(itemId);
  };

  useEffect(() => {
    if (applicationEligibilityReferralsQuery.data) {
      const newReferrals = applicationEligibilityReferralsQuery.data.map(d => ({
        ...d,
      }));

      setReferrals(newReferrals);
    }
  }, [applicationEligibilityReferralsQuery.data]);

  const { userId } = useUserId();

  const onReferralQuestion = useCallback(
    (
      section: EEligibilitySection,
      question: EEligibilityQuestion,
      referralType: EReferralType,
      refer: boolean
    ) => {
      const referralQuestion = referrals?.filter(
        (referral: IApplicationEligibilityReferral) =>
          referral.section === section && referral.question === question
      );

      const applicationEligibilityReferral: IApplicationEligibilityReferral = {
        id: '',
        section: section,
        question: question,
        referralType: referralType,
        reason: '',
        status: EReferralStatus.Pending,
        completeReason: '',
        referredByUserId: userId,
      };

      let newReferrals: IApplicationEligibilityReferral[];
      if (refer) {
        if (referralQuestion === undefined || referralQuestion.length === 0) {
          newReferrals = [...referrals];
          newReferrals.push(applicationEligibilityReferral);
          setReferrals(newReferrals);
        }
      } else {
        if (
          section === EEligibilitySection.Fraew &&
          question === EEligibilityQuestion.FRAEW_BuildingHeightMatchFRAEWReport
        ) {
          newReferrals = referrals.filter(
            item =>
              !(
                item.section === section &&
                (item.question === question ||
                  item.question ===
                    EEligibilityQuestion.FRAEW_ValidReasonForIncorrectBuildingHeight)
              )
          );
          setReferrals(newReferrals);
        } else if (
          section === EEligibilitySection.CompaniesHouse &&
          question === EEligibilityQuestion.CompanyOrg_IsActive
        ) {
          newReferrals = referrals.filter(
            item =>
              !(
                item.section === section &&
                (item.question === question ||
                  item.question ===
                    EEligibilityQuestion.CompanyOrg_IsInMutualPublicRegister)
              )
          );
          setReferrals(newReferrals);
        } else {
          newReferrals = referrals.filter(
            item => !(item.section === section && item.question === question)
          );
          setReferrals(newReferrals);
        }
      }
    },
    [referrals, userId]
  );

  const onReferralQuestionReasonChange = useCallback(
    (reason: string, question: EEligibilityQuestion) => {
      let newReferrals = referrals.map(referral =>
        referral.question === question
          ? { ...referral, reason: reason }
          : referral
      );
      setReferrals(newReferrals);
    },
    [referrals]
  );

  const [rejectedQuestion, setRejectedQuestion] =
    useState<EEligibilityQuestion | null>(null);

  const onRejectedQuestion = useCallback(
    (question: EEligibilityQuestion | null) => {
      setRejectedQuestion(question);
    },
    []
  );

  const eligibilityCompleted = flatItems.find(
    item => item.sectionName === 'Approve or Reject'
  )?.isComplete;

  const readOnly =
    !hasApplicationsEligibilityEdit ||
    eligibilityCompleted ||
    rejected ||
    rejectedQuestion !== null ||
    closed;

  const [showReferApplicationDialog, setShowReferApplicationDialog] =
    useState<boolean>(false);

  const handleShowReferApplicationDialog = useCallback((show: boolean) => {
    setShowReferApplicationDialog(show);
  }, []);

  const handleAutoShowReferApplicationDialog = useCallback(() => {
    if (referrals.some(item => item.status === EReferralStatus.Pending)) {
      setShowReferApplicationDialog(true);
    }
  }, [referrals]);

  const value = useMemo(
    () => ({
      applicationId,
      applicationEligibilityCategoriesQuery,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      selectedItem,
      referrals,
      onReferralQuestion,
      onReferralQuestionReasonChange,
      rejectedQuestion,
      onRejectedQuestion,
      readOnly,
      showReferApplicationDialog,
      handleShowReferApplicationDialog,
      handleAutoShowReferApplicationDialog,
      showReferralsSection,
      isReferralsSectionShowing,
      applicationEligibilityReferralsQuery,
    }),
    [
      applicationId,
      applicationEligibilityCategoriesQuery,
      selectedItemId,
      selectedItem,
      referrals,
      onReferralQuestion,
      onReferralQuestionReasonChange,
      rejectedQuestion,
      onRejectedQuestion,
      readOnly,
      showReferApplicationDialog,
      handleShowReferApplicationDialog,
      handleAutoShowReferApplicationDialog,
      isReferralsSectionShowing,
      applicationEligibilityReferralsQuery,
    ]
  );

  return (
    <ApplicationEligibilityContext.Provider value={value}>
      {children}
    </ApplicationEligibilityContext.Provider>
  );
};

export const useApplicationEligibilityContext = () => {
  const context = useContext(ApplicationEligibilityContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationEligibilityContext must be used within an ApplicationEligibilityContextProvider'
    );
  }
  return context;
};

const useDefaultItemId = (
  flatItems: IApplicationEligibilityItem[],
  selectedItemId: IApplicationEligibilityContext['selectedItemId'],
  setSelectedItemId: IApplicationEligibilityContext['setSelectedItemId']
) => {
  useEffect(() => {
    if (!selectedItemId && flatItems.length > 0) {
      // There is no selected id but we have some items so use the first one
      setSelectedItemId(flatItems[0].id);
    }
  }, [flatItems, selectedItemId, setSelectedItemId]);
};

const getFlattenedItems = (categories: IApplicationEligibilityCategory[]) => {
  return categories.reduce<IApplicationEligibilityItem[]>((prev, curr) => {
    return [...prev, ...curr.detailItems];
  }, []);
};
