import { useCallback } from 'react';
import {
  Alert,
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { GridSearchField } from 'common/components/gridSearchField';
import { FilterSelect } from 'common/components/taskGrid/TasksGridFilterSelect';
import { ESortDirection } from 'enums/ESortDirection';
import { useCurrentUserTasksGridContext } from 'pages/currentUserTasksPage';
import {
  StyledFiltersGridLeft,
  StyledFiltersGridRight,
} from 'styles/globalStyles/filterStyles';
import {
  ECmsTaskView,
  TaskListItemDefault,
  sortOptionsDefault,
  statusOptions,
  taskViewOptions,
} from 'types/tasks/CmsTaskTypes';
import { numToStr } from 'util/AppUtils';

export const CurrentUserTasksDataGridNavigation = () => {
  const {
    query,
    state: { gridState },
    gridStateChanged,
  } = useCurrentUserTasksGridContext();

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      gridStateChanged({ searchValue, skip: 0 });
    },
    [gridStateChanged]
  );

  return (
    <Box p={2}>
      <Grid container justifyContent="space-between">
        <Grid container alignItems="baseline">
          <Typography variant="h1">My Tasks</Typography>
          <Typography
            sx={{ fontSize: '27px' }}
            fontSize="27px"
            fontWeight="600"
          >
            {query.isSuccess ? `: (${query.data.count})` : ''}
          </Typography>
        </Grid>
        <Grid container>
          <StyledFiltersGridLeft item xs={4}>
            <GridSearchField
              isLoading={query.isLoading}
              defaultValue={gridState.searchValue}
              handleSearch={handleSearchCallback}
            />
          </StyledFiltersGridLeft>
          <StyledFiltersGridRight item xs={8} columnGap={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={gridState.showCreatedByMe}
                  onChange={(_, val) => {
                    gridStateChanged({
                      showCreatedByMe: val,
                      skip: 0,
                    });
                  }}
                ></Switch>
              }
              label="Include 'Created by me'"
              labelPlacement="start"
            />
            <FilterSelect
              name="sort"
              label="Sort"
              value={gridState.sortBy}
              multiple={false}
              onChange={e => {
                gridStateChanged({
                  skip: 0,
                  sortBy: e.target.value as keyof TaskListItemDefault,
                  sortDirection: ESortDirection.Asc,
                });
              }}
              options={sortOptionsDefault}
            />
            <FilterSelect
              name="status"
              label="Status"
              value={gridState.status.map(numToStr)}
              multiple
              onChange={e => {
                if (Array.isArray(e.target.value)) {
                  gridStateChanged({
                    status: e.target.value,
                    skip: 0,
                  });
                }
              }}
              options={statusOptions}
            />
            <FilterSelect
              name="view"
              label="View"
              value={gridState.viewType.toString()}
              multiple={false}
              onChange={e => {
                if (e.target.value) {
                  gridStateChanged({
                    viewType: parseInt(e.target.value),
                    skip: 0,
                  });
                }
              }}
              options={taskViewOptions}
            />
          </StyledFiltersGridRight>
        </Grid>
      </Grid>

      {gridState.viewType === ECmsTaskView.Notes ? (
        <Alert severity="info">
          You're viewing all tasks where you are listed as the assigned user on
          an associated Note.
        </Alert>
      ) : null}
    </Box>
  );
};
