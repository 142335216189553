import { ReactNode, MouseEvent as ReactMouseEvent } from 'react';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, useTheme } from '@mui/material';

export const ApplicationDetailsGridRow = ({
  col1,
  col2,
  col3,
  onButtonClick,
  hasBorder = true,
}: {
  col1: ReactNode;
  col2: ReactNode;
  col3: ReactNode;
  onButtonClick?: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hasBorder?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent="space-between"
      p={2}
      pl={0}
      mb={hasBorder ? 1 : 0}
      border={hasBorder ? 1 : 0}
      borderColor={theme.palette.divider}
    >
      <Grid item flexBasis="55%" flexGrow={3} pl={hasBorder ? 2 : 0}>
        {col1}
      </Grid>
      <Grid item flexBasis="20%" flexGrow={1}>
        {col2}
      </Grid>
      <Grid item flexBasis="20%" flexGrow={1} display="flex">
        {col3}
      </Grid>
      <Grid item flexBasis="5%" flexShrink={0} m={-2} display="flex">
        {Boolean(onButtonClick) ? (
          <IconButton
            onClick={e => {
              if (onButtonClick) {
                onButtonClick(e);
              }
            }}
            sx={{
              borderRadius: 0,
              flexGrow: 1,
              background: theme.palette.grey[100],
              p: 1,
              m: 0,
            }}
            aria-label="Details menu"
            name="Details menu"
          >
            <FontAwesomeIcon icon={faEllipsis} size="xs" fixedWidth />
          </IconButton>
        ) : null}
      </Grid>
    </Grid>
  );
};
