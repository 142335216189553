import { ReactNode } from 'react';
import { Typography } from '@mui/material';

export const DetailItemTitle = ({
  title,
  children,
}: {
  title: string;
  children?: ReactNode;
}) => {
  return (
    <Typography
      variant="h2"
      sx={{
        marginBottom: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      fontWeight={900}
      fontSize={'1.2em'}
    >
      <>{title}</>
      {children}
    </Typography>
  );
};

export const DataTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      variant="h3"
      sx={{ marginBottom: 1 }}
      fontWeight={900}
      fontSize={'1.05em'}
    >
      {title}
    </Typography>
  );
};

export const DataValue = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      color="grey.600"
      sx={{
        marginBottom: 3,
        display: 'flex',
        alignItems: 'center',
        overflowWrap: 'anywhere',
      }}
      variant="h3"
      fontWeight={600}
      fontSize={'0.9em'}
    >
      {children}
    </Typography>
  );
};
