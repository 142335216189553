import { useEffect, useState } from 'react';
import { TimeoutWarningModal, useLogout } from 'common/components/autoLogout';
import { addEventListeners, removeEventListeners } from 'util/AutoLogoutUtils';

// Helpers
const _1Sec = 1000;
const _1Min = _1Sec * 60;
const _60Mins = 60 * _1Min;

// Total time before auto signed out
const _autoLogoutAfter = _60Mins;

// Amount of time user has to respond to modal before they're auto signed out
const _delayBeforeSignOut = _1Sec * 20;

// Amount of time before the modal is shown
// i.e. 15 minutes minus 20 seconds to wait for user to respond
const _showModalAfter = _autoLogoutAfter - _delayBeforeSignOut;

export const AutoLogout = () => {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const { logout } = useLogout();

  useEffect(() => {
    const showModalTimeout = () => {
      return setTimeout(() => {
        setWarningModalOpen(true);
      }, _showModalAfter);
    };

    const autoLogoutTimeout = () => {
      return setTimeout(() => {
        logout();
      }, _delayBeforeSignOut);
    };

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = showModalTimeout();
      }
    };

    let timeout = isWarningModalOpen ? autoLogoutTimeout() : showModalTimeout();
    addEventListeners(listener);

    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen, logout]);

  if (!isWarningModalOpen) {
    return null;
  }

  return (
    <TimeoutWarningModal
      isOpen={isWarningModalOpen}
      onRequestClose={() => setWarningModalOpen(false)}
    />
  );
};
