import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z, ZodType } from 'zod';
import {
  useGetRagRatingsQuery,
  useUpdateApplicationRagRatingMutation,
} from 'api/application';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IApplicationRagRating } from 'types/applications/ApplicationTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationRagRating) =>
  nameof<IApplicationRagRating>(fieldName);

interface IApplicationRagRatingModalProps {
  onClose: () => void;
}

export function ApplicationRagRatingModal({
  onClose,
}: IApplicationRagRatingModalProps) {
  return (
    <Dialog open fullWidth>
      <DialogTitle>RAG Rating</DialogTitle>
      <DialogContent>
        <ApplicationRagRatingModalForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
}

interface IApplicationRagRatingModalFormProps {
  onClose: () => void;
}

export const ApplicationRagRatingModalForm = ({
  onClose,
}: IApplicationRagRatingModalFormProps) => {
  const { applicationId } = useApplicationContext();

  const { data: ragRatings, isLoading: ragRatingsLoading } =
    useGetRagRatingsQuery();

  const [updateApplicationRagRating, updateApplicationRagRatingResult] =
    useUpdateApplicationRagRatingMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationRagRating>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zRagRatingSchema),
  });
  const { handleSubmit, control, reset } = form;

  const onSubmit = async (formData: IApplicationRagRating) => {
    try {
      await updateApplicationRagRating(
        formData.ragRating?.id
          ? {
              applicationId,
              ratingId: formData.ragRating.id,
              comment: formData.comment,
            }
          : { applicationId, comment: formData.comment }
      )
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`RAG rating updated successfully`);
          onClose();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit RAG rating category`);
    }
  };

  return ragRatingsLoading ? (
    <Loading isOpen />
  ) : (
    <form
      noValidate
      onSubmit={event => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      style={{ width: '100%' }}
    >
      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color={'grey.700'}>
              RAG Rating
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="ragRating"
              defaultValue={null}
              render={({ field: { onChange, ...fieldRest }, fieldState }) => {
                return (
                  <Autocomplete
                    options={ragRatings ?? []}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...fieldRest}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={getName('comment')}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  required
                  label="Comments"
                  helperText={fieldState.error?.message}
                  multiline
                  autoComplete="off"
                  inputProps={{ maxLength: 1000 }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <StyledDrawerActions>
        <Grid container justifyContent="flex-end" gap={1}>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={updateApplicationRagRatingResult.isLoading}
          >
            Update RAG Rating
          </LoadingButton>
        </Grid>
      </StyledDrawerActions>
    </form>
  );
};

export const zRagRatingSchema: ZodType<
  Pick<IApplicationRagRating, 'ragRating' | 'comment'>
> = z.object({
  ragRating: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
  comment: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});
