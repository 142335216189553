import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AppState } from 'state';

const selectTaskDrawerRoot = (state: AppState) => state.taskDrawer;

export const selectTaskDrawerState = createSelector(
  [selectTaskDrawerRoot],
  taskDrawerState => taskDrawerState
);

export const useTaskDrawerState = () => {
  return useSelector(selectTaskDrawerState);
};
