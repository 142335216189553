import { TypographyProps, Typography } from '@mui/material';

export type TTitleValueProps = {
  title: string;
  value?: string;
  titleProps?: TypographyProps;
  valueProps?: TypographyProps;
};
export function TitleValue({
  title,
  value,
  titleProps,
  valueProps,
}: TTitleValueProps) {
  return (
    <>
      <Typography color="GrayText" mb={'6px'} {...titleProps}>
        {title}
      </Typography>
      <Typography fontWeight={600} {...valueProps}>
        {value}
      </Typography>
    </>
  );
}
