import { Box, Grid, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPaymentRequestVariationQuery } from 'api/application/paymentRequestApi';
import { ProjectDateBox } from 'common/components/projectDateBox/ProjectDateBox';
import { ColumnContainer } from 'pages/applicationPage/common/components';
import {
  GrayCardContainer,
  QuestionAndAnswer,
} from 'pages/applicationPage/content/liveProject/common';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { IPaymentRequestVariations } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  formatDateMonthYear,
  formatDateShortMonthLongYear,
  getDifferenceInMonths,
} from 'util/DateUtils';

const displayDateDiff = (currentDate: string, originalDate: string) => {
  const diffInMonths = getDifferenceInMonths(originalDate, currentDate) - 1;
  if (diffInMonths === 0) {
    return `+ 0 months (${formatDateShortMonthLongYear(
      new Date(originalDate)
    )})`;
  }
  return `${
    diffInMonths > 1 ? `+${diffInMonths} months` : `+${diffInMonths} month`
  } (${formatDateShortMonthLongYear(new Date(originalDate))})`;
};

const defaultNoDate: string = '0001-01-01T00:00:00';

export const PaymentRequestVariationChecksSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const { data } = useGetPaymentRequestVariationQuery(request);

  const datesExist = !!(
    data?.currentStartDate !== defaultNoDate &&
    !!data?.currentStartDate &&
    data?.currentEndDate !== defaultNoDate &&
    !!data?.currentEndDate &&
    data?.originalStartDate !== defaultNoDate &&
    !!data?.originalStartDate &&
    data?.originalEndDate !== defaultNoDate &&
    !!data?.originalEndDate
  );

  const displayDates = datesExist && data.projectDatesChanged;

  return (
    <>
      <ColumnContainer
        sx={{
          padding: 0,
          border: '1px solid lightgray',
          borderRadius: 2,
        }}
      >
        <Box sx={{ padding: 2, borderBottom: '1px solid lightgray' }}>
          <Typography variant="h2" fontWeight={800} fontSize="1.3em">
            Variation checks
          </Typography>
        </Box>
        <Box sx={{ padding: 3 }}>
          <Box
            mb={2}
            sx={{
              padding: 2,
              paddingTop: 3,
              borderRadius: 1,
            }}
          >
            <Grid container flexWrap="nowrap" columnGap={2}>
              <VariationChecksCard data={data} displayDates={displayDates} />
              {displayDates && (
                <DatesCardContainer
                  currentStartDate={data.currentStartDate}
                  currentEndDate={data.currentEndDate}
                  originalStartDate={data.originalStartDate}
                  originalEndDate={data.originalEndDate}
                />
              )}
            </Grid>
          </Box>
        </Box>
      </ColumnContainer>
    </>
  );
};

const CardTitle = ({ title }: { title: string }) => (
  <Typography variant="h3" fontWeight={600}>
    {title}
  </Typography>
);

const VariationChecksCard = ({
  data,
  displayDates,
}: {
  data: IPaymentRequestVariations | undefined;
  displayDates: boolean;
}) => {
  return (
    <Grid item xs={displayDates ? 6 : 12} p={2} bgcolor={'grey.100'}>
      <CardTitle title="Variation checks" />
      <QuestionAndAnswer
        question="Have your project dates changed?"
        value={data?.projectDatesChanged ? 'Yes' : 'No'}
      />
      <QuestionAndAnswer
        question="Has the unsafe cladding been removed?"
        value={data?.unsafeCladdingBeenRemoved ? 'Yes' : 'No'}
      />
      {data?.monthOfUnsafeCladdingRemoval &&
        data?.unsafeCladdingBeenRemoved && (
          <QuestionAndAnswer
            question="What date was the unsafe cladding removed?"
            value={
              data?.monthOfUnsafeCladdingRemoval
                ? formatDateMonthYear(
                    new Date(data.monthOfUnsafeCladdingRemoval)
                  )
                : '-'
            }
          />
        )}
      <QuestionAndAnswer
        question="Have your costs changed?"
        value={data?.costsChanged ? 'Yes' : 'No'}
      />
    </Grid>
  );
};

const DatesCardContainer = ({
  currentStartDate,
  currentEndDate,
  originalStartDate,
  originalEndDate,
}: {
  currentStartDate: string;
  currentEndDate: string;
  originalStartDate: string;
  originalEndDate: string;
}) => {
  const endDatesDifferenceInInMonths = getDifferenceInMonths(
    originalEndDate,
    currentEndDate
  );

  return (
    <GrayCardContainer>
      <CardTitle title="Updated project dates" />
      <Grid container columnSpacing={2} mt="1.5rem">
        <Grid item xs={6}>
          <ProjectDateBox
            title="Start on site"
            date={`${formatDateMonthYear(new Date(currentStartDate))}`}
            additionalInfo={displayDateDiff(
              currentStartDate,
              originalStartDate
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <ProjectDateBox
            title="End date"
            date={`${formatDateMonthYear(new Date(currentEndDate))}`}
            additionalInfo={displayDateDiff(currentEndDate, originalEndDate)}
            projectDateBoxStatus={
              endDatesDifferenceInInMonths >= 2 ? 'warning' : 'success'
            }
          />
        </Grid>
      </Grid>
    </GrayCardContainer>
  );
};
