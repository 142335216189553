import { Box, Chip, Grid, Typography } from '@mui/material';
import { EERejectedPaymentStatusLanguage } from 'enums/ERejectedPaymentsStatus';
import {
  IPaymentRequestPaymentItem,
  IRejectedPaymentRequestItem,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { PaymentRequestCostsSectionBox } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsSectionBox';
import { getGbpAmount } from 'util/AppUtils';

export const PaymentRequestCostsPaymentsPaidToDateSection = ({
  paymentsPaidToDate,
  paymentTitle,
}: {
  paymentsPaidToDate?: Array<
    IPaymentRequestPaymentItem | IRejectedPaymentRequestItem
  > | null;
  paymentTitle: string;
}) => {
  return (
    <>
      <PaymentRequestCostsSectionBox>
        <Typography variant="h3" fontWeight={800} fontSize="1.3em">
          {paymentTitle}
        </Typography>
      </PaymentRequestCostsSectionBox>
      <PaymentRequestCostsSectionBox>
        {paymentsPaidToDate && paymentsPaidToDate.length > 0 ? (
          paymentsPaidToDate?.map((row, index) => (
            <PaymentRequestPaymentItemRow
              key={index}
              paymentRequestPaymentItem={row}
            />
          ))
        ) : (
          <Typography variant="subtitle1" fontSize="1em">
            No Grant Paid To Date
          </Typography>
        )}
      </PaymentRequestCostsSectionBox>
      {paymentsPaidToDate && paymentsPaidToDate.length > 0 && (
        <Box p={2}>
          <PaymentRequestPaymentTotalRow
            total={paymentsPaidToDate?.reduce(
              (sum, current) => sum + current.amount,
              0
            )}
          />
        </Box>
      )}
    </>
  );
};

const PaymentRequestPaymentItemRow = ({
  paymentRequestPaymentItem,
}: {
  paymentRequestPaymentItem:
    | IPaymentRequestPaymentItem
    | IRejectedPaymentRequestItem;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={2}
      wrap="nowrap"
      sx={{
        mb: 1,
        ml: 4,
      }}
    >
      <Grid item xs={8}>
        <Typography variant="subtitle1" fontSize="1em">
          {paymentRequestPaymentItem.itemName}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {!!paymentRequestPaymentItem.amount
          ? getGbpAmount({ value: paymentRequestPaymentItem.amount })
          : '-'}
      </Grid>
      <Grid item xs={2} textAlign={'left'}>
        {'paid' in paymentRequestPaymentItem ? (
          <Chip
            sx={{
              ml: 2,
            }}
            label={paymentRequestPaymentItem.paid ? 'Paid' : 'Approved'}
            color={'success'}
            variant={'filled'}
          />
        ) : (
          <Chip
            sx={{
              ml: 2,
            }}
            label={
              EERejectedPaymentStatusLanguage[paymentRequestPaymentItem.status]
            }
            color="warning"
            variant="outlined"
          />
        )}
      </Grid>
    </Grid>
  );
};

const PaymentRequestPaymentTotalRow = ({ total }: { total?: number }) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={2}
      wrap="nowrap"
      sx={{
        mb: 1,
        ml: 4,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em">
          Total
        </Typography>
      </Grid>
      <Grid item xs={4} textAlign={'right'}>
        {!!total ? getGbpAmount({ value: total }) : '-'}
      </Grid>
    </Grid>
  );
};
