import { Box, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { parseISO } from 'date-fns';
import { useGetScheduleOfWorksOverviewQuery } from 'api/application/scheduleOfWorksApi';
import { Loading } from 'common/components/loading';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { formatDateTimeDayMonthYearHourMins } from 'util/DateUtils';

export const ApprovedScheduleOfWorksBanner = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        scheduleOfWorksId: selectedItem.id,
      }
    : skipToken;

  const { isLoading, data } = useGetScheduleOfWorksOverviewQuery(request);

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 1,
        bgcolor: 'grey.100',
      }}
    >
      <Box>
        <Typography fontSize="0.9em" fontWeight={700} color="black">
          Schedule Of Works approved
        </Typography>
        {isLoading ? (
          <Loading isOpen />
        ) : (
          <Typography>
            {data?.approvedBy
              ? `Schedule of works was approved by ${data?.approvedBy}`
              : ''}
            {data?.approvedDate
              ? ` at ${formatDateTimeDayMonthYearHourMins(
                  parseISO(data.approvedDate.toString())
                )}`
              : ''}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
