export interface IProjectPrepOverview {
  stageStartDate: string;
  openSupportRequests: number;
  totalSupportRequests: number;
  totalSupportCost: number;
  csspAssigned: boolean;
}

export interface IGetProjectPrepListResponse {
  results: IProjectPrepReportItem[];
}

export interface IProjectPrepReportItem {
  id: string;
  title: string;
  subTitle: string;
  status: EProjectPrepItemStatus;
  type: EProgressReportItemType;
  dueDate?: string;
  startDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  completedDate?: string;
}

interface QuestionBase {
  title: string;
}

interface QuestionString extends QuestionBase {
  type: 'string';
  value: string;
}
interface QuestionDate extends QuestionBase {
  type: 'date';
  value: string;
}
interface QuestionStringArray extends QuestionBase {
  type: 'string-array';
  value: string[];
}
interface Questionboolean extends QuestionBase {
  type: 'boolean';
  value: boolean;
}
export interface QuestionFileArray extends QuestionBase {
  type: 'file-array';
  value: { fileName: string; fileUri: string }[];
}
export interface QuestionTable extends QuestionBase {
  type: 'table';
  value: { columnHeaders: string[]; rows: string[][] };
}

export interface IProjectPrepProgressReport {
  sectionTitle: string;
  sectionSubTitle: string;
  dateOpened: string;
  dateDue: string;
  lastUpdated: string;
  completedDate?: string;
  status: EProjectPrepItemStatus;
  questions: Array<
    | QuestionString
    | QuestionStringArray
    | QuestionDate
    | Questionboolean
    | QuestionFileArray
    | QuestionTable
  >;
}

export enum EProgressReportItemType {
  WorkPackage = 1,
  DutyOfCare = 2,
  MonthlyReport = 3,
  WorkPackageDeed = 4,
}

export enum EProgressReportItemStatus {
  NotStarted = 1,
  InProgress = 2,
  Complete = 3,
  Rejected = 4,
}

export const EProgressReportItemStatusLanguage: {
  [key in EProgressReportItemStatus]: string;
} = {
  [EProgressReportItemStatus.NotStarted]: 'To Do',
  [EProgressReportItemStatus.InProgress]: 'In Progress',
  [EProgressReportItemStatus.Complete]: 'Complete',
  [EProgressReportItemStatus.Rejected]: 'Rejected',
} as const;

export enum EProjectPrepItemStatus {
  NotStarted = 1,
  InProgress = 2,
  Complete = 3,
  Rejected = 4,
  Submitted = 5,
  DocumentSent = 6,
  Approved = 7,
}

export const EProjectPrepItemStatusLanguage: {
  [key in EProjectPrepItemStatus]: string;
} = {
  [EProjectPrepItemStatus.NotStarted]: 'To Do',
  [EProjectPrepItemStatus.InProgress]: 'In Progress',
  [EProjectPrepItemStatus.Complete]: 'Complete',
  [EProjectPrepItemStatus.Rejected]: 'Rejected',
  [EProjectPrepItemStatus.Submitted]: 'Submitted',
  [EProjectPrepItemStatus.DocumentSent]: 'Document Sent',
  [EProjectPrepItemStatus.Approved]: 'Approved',
} as const;

export interface IGetProgressReportQueryParams {
  applicationId: string;
  progressReportId: string;
}

export enum EWorksPackageSection {
  Overview,
  SubContractors,
  Cladding,
  CostScheduling,
  ProjectTeam,
  Confirm,
}
