import { Box, Grid, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EReferralType } from 'enums/EReferralType';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context/ApplicationEligibilityContext';

interface IReferApplicationNotificationBannerProps {
  question: EEligibilityQuestion;
  referralType: EReferralType;
  disabled?: boolean;
}

export const ReferApplicationNotificationBanner = ({
  question,
  referralType,
  disabled
}: IReferApplicationNotificationBannerProps) => {
  const { onReferralQuestionReasonChange } = useApplicationEligibilityContext();

  const handleReferralQuestionReasonChange = (
    reason: string,
    question: EEligibilityQuestion
  ) => {
    onReferralQuestionReasonChange(reason, question);
  };

  return (
    <Box
      sx={{
        padding: 1,
        border: '1px solid lightgray',
        borderRadius: 1,
        borderLeft: '5px solid orange',
      }}
    >
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            {referralType === EReferralType.HomesEngland
              ? 'Requires referral to Homes England once initial checks complete'
              : 'Requires referral to the Applicant once initial checks complete'}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body1" fontSize="1em">
            {referralType === EReferralType.HomesEngland
              ? 'This application needs to be referred for a decision from Homes England once the initial eligibility checks have all been completed'
              : 'This application needs to be referred to the Applicant once the initial eligibility checks have all been completed'}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            Reason (Optional)
          </Typography>
          <Controller
            name={`referrals.${question}.reason`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                value={field.value ?? ''}
                fullWidth
                label="Reason (Optional)"
                helperText={fieldState.error?.message}
                multiline
                disabled={disabled}
                onChange={e => {
                  handleReferralQuestionReasonChange(e.target.value, question);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
