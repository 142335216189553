import { taskApi } from 'api/task';
import { useAppDispatch } from 'state';
import { QueryTags } from 'util/ApiUtils';

export const useInvalidateTasks = () => {
  const dispatch = useAppDispatch();

  const invalidateTasks = () => {
    dispatch(taskApi.util.invalidateTags([QueryTags.Tasks]));
  };

  return { invalidateTasks };
};
