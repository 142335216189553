import { Stack } from '@mui/material';
import { InfoCard, InfoCardProps } from '.';

export const InfoCardRow = ({ cards }: { cards: InfoCardProps[] }) => {
  return (
    <Stack direction={'row'} gap={1}>
      {cards.map((cardProps, i) => (
        <InfoCard key={i} {...cardProps} />
      ))}
    </Stack>
  );
};
