import { z } from 'zod';

export const zBenchmarkFiguresSchema = z.object({
  eighteenPlusFullRemediation: z.coerce
    .number()
    .gt(0, { message: 'Figure must be greater than 0' }),
  eighteenPlusPartialRemediation: z.coerce
    .number()
    .gt(0, { message: 'Figure must be greater than 0' }),
  underEighteenFullRemediation: z.coerce
    .number()
    .gt(0, { message: 'Figure must be greater than 0' }),
  underEighteenPartialRemediation: z.coerce
    .number()
    .gt(0, { message: 'Figure must be greater than 0' }),
});
