import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@mui/material';

export const BuildingHeightChecksCompleteNotificationBanner = () => {
  return (
    <Box
      sx={{
        padding: 1,
        border: '1px solid lightgray',
        borderRadius: 1,
        borderLeft: '5px solid green',
      }}
    >
      <Grid container spacing={1}>
        <Grid item>
          <FontAwesomeIcon icon={faCircleCheck} color={'#2e7d32'} />
        </Grid>
        <Grid item>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            Building checks complete, please continue with the remaining
            sections
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
