import { Grid } from '@mui/material';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { MainContentContainer } from 'common/components/mainContentContainer';
import { PageTitle } from 'common/components/pageTitle';
import { AdminTasksPageContent } from 'pages/adminTasksPage';

const _crumbs: readonly IBreadcrumbItem[] = [
  { text: 'Tasks', url: undefined },
] as const;

export const AdminTasksPage = () => {
  return (
    <MainContentContainer>
      <Breadcrumbs crumbs={_crumbs} />

      <PageTitle title="Task Management" />

      <Grid item xs={12}>
        <AdminTasksPageContent />
      </Grid>
    </MainContentContainer>
  );
};
