import { Box, Divider, Grid, Typography } from '@mui/material';
import { ISubContractor } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';

export const SubContractorCard = ({ data }: { data?: ISubContractor[] }) => {
  return (
    <Box
      mb={2}
      sx={{
        width: '100%',
        padding: 2,
        paddingTop: 3,
        marginRight: 2,
        borderRadius: 1,
      }}
    >
      <SubContractSectionStacked
        title="Company Name"
        value="Company Registration"
      />
      {data?.map(contractor => {
        return <CardData key={contractor.id} data={contractor} />;
      })}
    </Box>
  );
};

export const CardData = ({ data }: { data: ISubContractor }) => {
  const stackedData: [string, string][] = [
    [data?.companyName ?? '', data?.companyRegistration ?? ''],
  ];

  return (
    <>
      {stackedData.map(([title, value], i) => (
        <SubContractSectionStacked key={i} title={title} value={value} />
      ))}
    </>
  );
};

const SubContractSectionStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <DataTitle title={title} />
        </Grid>

        <Grid item xs={8}>
          <Typography fontSize={'0.8em'} fontWeight={600}>
            {value}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1.5 }} />
    </>
  );
};

const DataTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="body1" fontWeight={'600'} fontSize={'0.8em'}>
      {title}
    </Typography>
  );
};
