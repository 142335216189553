import { administrationApi } from 'api/administration';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IGetSystemNotificationsListQuery,
  ISystemNotification,
  ISystemNotificationGridListItem,
} from 'types/administration/AdministrationSystemNotificationTypes';
import { QueryTags } from 'util/ApiUtils';

const getPaginatedSystemNotificationsQueryString = (
  query: IGetSystemNotificationsListQuery
) => {
  const queryParams = new URLSearchParams({
    skip: query.skip.toString(),
    take: query.take.toString(),
  });

  return `/notification/grid?${queryParams.toString()}`;
};

const systemNotificationsEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getPaginatedSystemNotificationsList: builder.query<
      IPaginationResponse<ISystemNotificationGridListItem>,
      IGetSystemNotificationsListQuery
    >({
      providesTags: [QueryTags.SystemNotifications],
      query: query => {
        return getPaginatedSystemNotificationsQueryString(query);
      },
    }),
    getSystemNotification: builder.query<ISystemNotification, string>({
      providesTags: [QueryTags.SystemNotifications],
      query: query => `/notification/${query}`,
    }),
    addSystemNotification: builder.mutation<any, ISystemNotification>({
      invalidatesTags: [QueryTags.SystemNotifications],
      query: payload => ({
        url: `/notification`,
        method: 'POST',
        body: payload,
      }),
    }),
    editSystemNotification: builder.mutation<any, ISystemNotification>({
      invalidatesTags: [QueryTags.SystemNotifications],
      query: payload => ({
        url: `/notification/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteSystemNotification: builder.mutation<void, string>({
      invalidatesTags: [QueryTags.Users],
      query: id => ({
        url: `/notification/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaginatedSystemNotificationsListQuery,
  useGetSystemNotificationQuery,
  useLazyGetSystemNotificationQuery,
  useAddSystemNotificationMutation,
  useEditSystemNotificationMutation,
  useDeleteSystemNotificationMutation,
} = systemNotificationsEndpoints;
