import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

export const SectionHeader = ({
  title,
  rightContent,
}: {
  title: string;
  rightContent?: ReactNode;
}) => {
  return (
    <Box bgcolor="grey.100" p={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="1.2em">
          {title}
        </Typography>
        <Box gap={2} display="flex">
          {rightContent}
        </Box>
      </Box>
    </Box>
  );
};