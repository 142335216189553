import { InfoCardRow } from 'common/components/infoCard/InfoCardRow';
import { useVariationOverview } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';

export const SummaryCardRow = () => {
  const {
    query: { data },
  } = useVariationOverview();

  if (!data) {
    return null;
  }

  return (
    <InfoCardRow
      cards={[
        {
          title: 'Original works package',
          amount: data.worksPackageTotalOriginal,
        },
        {
          title: 'Current works package',
          amount: data.worksPackageTotalCurrent,
        },
        {
          title: 'Variation request',
          amount: data.worksPackageVariationAmount,
        },
        {
          title: 'Total requested',
          amount: data.worksPackageTotalNew,
        },
      ]}
    />
  );
};
