import { applicationApi } from 'api/application/applicationApi';
import {
  IAddScheduleOfWorksReferral,
  IGetScheduleOfWorksReferralsResponse,
  IScheduleOfWorksChecks,
  IScheduleOfWorksChecksDetails,
  IScheduleOfWorksCompleteReferralRequest,
  IScheduleOfWorksCosts,
  IScheduleOfWorksOverview,
  IScheduleOfWorksReferralRequest,
  IScheduleOfWorksRequest,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { QueryTags } from 'util/ApiUtils';

export const scheduleOfWorksEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getScheduleOfWorksOverview: builder.query<
      IScheduleOfWorksOverview,
      IScheduleOfWorksRequest
    >({
      providesTags: [QueryTags.ApplicationScheduleOfWorksOverview],
      query: params =>
        `${params.applicationId}/scheduleOfWorks/${params.scheduleOfWorksId}/overview`,
      keepUnusedDataFor: 0,
    }),
    getScheduleOfWorksChecksDetails: builder.query<
      IScheduleOfWorksChecksDetails,
      IScheduleOfWorksRequest
    >({
      providesTags: [QueryTags.ApplicationScheduleOfWorks],
      query: params =>
        `/${params.applicationId}/scheduleOfWorks/${params.scheduleOfWorksId}/checks/details`,
    }),
    getScheduleOfWorksCosts: builder.query<
      IScheduleOfWorksCosts,
      IScheduleOfWorksRequest
    >({
      providesTags: [QueryTags.ApplicationScheduleOfWorks],
      query: params =>
        `/${params.applicationId}/scheduleOfWorks/${params.scheduleOfWorksId}/costs`,
    }),
    editScheduleOfWorksCosts: builder.mutation<void, IScheduleOfWorksCosts>({
      invalidatesTags: [QueryTags.ApplicationScheduleOfWorks],
      query: payload => ({
        url: `/${payload.id}/scheduleOfWorks/${payload.scheduleOfWorksId}/costs`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getScheduleOfWorksChecks: builder.query<
      IScheduleOfWorksChecks,
      IScheduleOfWorksRequest
    >({
      providesTags: [QueryTags.ApplicationScheduleOfWorks],
      query: params =>
        `/${params.applicationId}/scheduleOfWorks/${params.scheduleOfWorksId}/checks`,
    }),
    editScheduleOfWorksChecks: builder.mutation<any, IScheduleOfWorksChecks>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationScheduleOfWorks,
      ],
      query: payload => ({
        url: `/${payload.id}/scheduleOfWorks/${payload.scheduleOfWorksId}/checks`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getScheduleOfWorksReferrals: builder.query<
      IGetScheduleOfWorksReferralsResponse,
      IScheduleOfWorksReferralRequest
    >({
      providesTags: [QueryTags.ApplicationScheduleOfWorks],
      query: params =>
        `/${params.applicationId}/scheduleOfWorks/${params.scheduleOfWorksId}/referrals`,
    }),
    addScheduleOfWorksReferral: builder.mutation<
      any,
      IAddScheduleOfWorksReferral
    >({
      invalidatesTags: [QueryTags.ApplicationScheduleOfWorks],
      query: payload => ({
        url: `/${payload.applicationId}/scheduleOfWorks/${payload.scheduleOfWorksId}/refer`,
        method: 'PUT',
        body: payload,
      }),
    }),
    completeScheduleOfWorksReferral: builder.mutation<
      void,
      IScheduleOfWorksCompleteReferralRequest
    >({
      invalidatesTags: [QueryTags.ApplicationScheduleOfWorks],
      query: params => ({
        url: `/${params.applicationId}/scheduleOfWorks/${params.scheduleOfWorksId}/refer/${params.referralId}/complete`,
        method: 'PUT',
        body: { completeReason: params.completeReason },
      }),
    }),
    approveScheduleOfWorks: builder.mutation<void, IScheduleOfWorksRequest>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationScheduleOfWorksOverview,
        QueryTags.ApplicationScheduleOfWorks,
      ],
      query: params => ({
        url: `/${params.applicationId}/scheduleOfWorks/${params.scheduleOfWorksId}/approve`,
        method: 'PUT',
        body: { isApproved: true },
      }),
    }),
  }),
});

export const {
  useGetScheduleOfWorksOverviewQuery,
  useGetScheduleOfWorksCostsQuery,
  useEditScheduleOfWorksCostsMutation,
  useGetScheduleOfWorksChecksDetailsQuery,
  useGetScheduleOfWorksChecksQuery,
  useEditScheduleOfWorksChecksMutation,
  useGetScheduleOfWorksReferralsQuery,
  useAddScheduleOfWorksReferralMutation,
  useCompleteScheduleOfWorksReferralMutation,
  useApproveScheduleOfWorksMutation,
} = scheduleOfWorksEndpoints;
