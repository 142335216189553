import { Fragment, useState } from 'react';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  useGetSignatoriesListQuery,
  useSetSignatoryStatusMutation,
} from 'api/application/signatoriesApi';
import { Loading } from 'common/components/loading/Loading';
import { useLocalSnackbar, useModalState } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { AddEditSignatoryDrawer } from 'pages/applicationPage/content/applicationSignatories/components/addEditSignatoryDrawer/AddEditSignatoryDrawer';
import { DeleteSignatoryDialog } from 'pages/applicationPage/content/applicationSignatories/components/deleteSignatoryDialog/DeleteSignatoryDialog';
import { NoSignatoriesComponent } from 'pages/applicationPage/content/applicationSignatories/components/noSignatoriesComponent/NoSignatoriesComponent';
import { RejectSignatoryDialog } from 'pages/applicationPage/content/applicationSignatories/components/rejectSignatoryDialog/RejectSignatoryDialog';
import { EApplicationSignatoriesMenuType } from 'pages/applicationPage/content/applicationSignatories/types/EApplicationSignatoriesMenuType';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import {
  ISignatoryGridListItem,
  ISignatoryStatus,
} from 'types/applications/ApplicationSignatoryTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const ApplicationSignatories = () => {
  const { applicationId, closed } = useApplicationContext();
  const request = applicationId;
  const signatoriesListQuery = useGetSignatoriesListQuery(request);
  const [setSignatoryStatus] = useSetSignatoryStatusMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const {
    isShowing: isAddEditSignatoryDrawerShowing,
    showModal: showAddEditSignatoryDrawer,
    hideModal: hideAddEditSignatoryDrawer,
  } = useModalState();

  const {
    isShowing: isDeleteSignatoryDialogShowing,
    showModal: showDeleteSignatoryDialog,
    hideModal: hideDeleteSignatoryDialog,
  } = useModalState();

  const {
    isShowing: isRejectSignatoryDialogShowing,
    showModal: showRejectSignatoryDialog,
    hideModal: hideRejectSignatoryDialog,
  } = useModalState();

  const getHeaderTableCell = (
    property: keyof ISignatoryGridListItem,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell>
        <TableSortLabel>{columnLabel}</TableSortLabel>
      </TableCell>
    );
  };

  const [selectedRowSignatoryId, setSelectedSignatoryId] = useState<
    string | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = (menuType: EApplicationSignatoriesMenuType) => {
    switch (menuType) {
      case EApplicationSignatoriesMenuType.EditSignatory:
        showAddEditSignatoryDrawer(true);
        break;
      case EApplicationSignatoriesMenuType.DeleteSignatory:
        showDeleteSignatoryDialog(true);
        break;
    }

    setAnchorEl(null);
  };

  const onPassFailClick = async (rowId: string, passed: boolean) => {
    if (passed) {
      const signatoryStatus: ISignatoryStatus = {
        id: rowId,
        applicationId: applicationId,
        isPassed: true,
      };

      await setSignatoryStatus(signatoryStatus)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar('Successfully set signatory status to passed');
        })
        .catch(error => {
          createErrorSnackbar(
            `An unexpected error occured while updating the status: ${error}`
          );
        });
    } else {
      setSelectedSignatoryId(rowId);
      // Show reject director dialog
      showRejectSignatoryDialog(true);
      setAnchorEl(null);
    }
  };

  const onEllipsisClick = (
    rowId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedSignatoryId(rowId);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title={'Maximum two signatories allowed'} arrow>
            <span>
              <Button
                variant="contained"
                onClick={showAddEditSignatoryDrawer}
                startIcon={<FontAwesomeIcon icon={faAdd} />}
                disabled={
                  (signatoriesListQuery.data &&
                    signatoriesListQuery.data.length >= 2) ||
                  closed
                }
              >
                Add signatory
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1">Signatories</Typography>
      </Grid>
      <Box
        display="flex"
        gap={2}
        justifyContent="flex-start"
        p={2}
        alignItems={'center'}
      >
        <Typography variant="body1" fontWeight={800}>
          Click here to navigate to PEP/Sanctions checks
        </Typography>
        <Button
          variant="contained"
          href="https://secure.veriphy.co.uk"
          target="_blank"
        >
          Veriphy
        </Button>
      </Box>
      <Loading isOpen={signatoriesListQuery.isLoading} />
      {signatoriesListQuery.data && signatoriesListQuery.data.length > 0 ? (
        <>
          <StyledGenericTable data-testid="Application-Signatories-Table">
            <TableHead>
              <TableRow>
                {getHeaderTableCell('firstName', 'First name')}
                {getHeaderTableCell('lastName', 'Last name')}
                {getHeaderTableCell('role', 'Role')}
                {getHeaderTableCell('emailAddress', 'Email Address')}
                {getHeaderTableCell('dateAdded', 'Date Added')}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {signatoriesListQuery.data?.map(row => (
                <Fragment key={'Subrow_1_' + row.id}>
                  <TableRow key={row.id}>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.role}</TableCell>
                    <TableCell>{row.emailAddress}</TableCell>
                    <TableCell>
                      {row.dateAdded
                        ? getUkDateStringFromJsonDateString(row.dateAdded)
                        : ''}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" gap={0.5}>
                        <ToggleButtonGroup
                          onChange={(_, val) => onPassFailClick(row.id, val)}
                          exclusive
                          value={row.isPassed}
                          disabled={closed}
                        >
                          <ToggleButton value={true}>Passed</ToggleButton>
                          <ToggleButton value={false}>Failed</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <>
                        {!closed && (
                          <IconButton
                            aria-label="edit"
                            name="edit"
                            onClick={event => onEllipsisClick(row.id, event)}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        )}
                        {selectedRowSignatoryId === row.id ? (
                          <Menu
                            id={`application-signatory-menu-${row.id}`}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                handleClose(
                                  EApplicationSignatoriesMenuType.EditSignatory
                                )
                              }
                            >
                              Edit signatory
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                handleClose(
                                  EApplicationSignatoriesMenuType.DeleteSignatory
                                )
                              }
                            >
                              Delete signatory
                            </MenuItem>
                          </Menu>
                        ) : null}
                      </>
                    </TableCell>
                  </TableRow>
                  {!row.isPassed && (
                    <TableRow key={'Subrow_2_' + row.id}>
                      <TableCell colSpan={5} style={{ borderTop: 'none' }}>
                        {row.failureReason}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </StyledGenericTable>
        </>
      ) : (
        <NoSignatoriesComponent
          showAddSignatoryOnClick={showAddEditSignatoryDrawer}
        />
      )}
      {isAddEditSignatoryDrawerShowing && (
        <AddEditSignatoryDrawer
          signatoryId={selectedRowSignatoryId}
          onSuccess={() => {
            hideAddEditSignatoryDrawer();
          }}
          onClose={() => {
            hideAddEditSignatoryDrawer();
          }}
        />
      )}
      {isDeleteSignatoryDialogShowing && selectedRowSignatoryId && (
        <DeleteSignatoryDialog
          signatoryId={selectedRowSignatoryId}
          onConfirm={() => {
            setSelectedSignatoryId(null);
            hideDeleteSignatoryDialog();
          }}
          onCancel={() => {
            setSelectedSignatoryId(null);
            hideDeleteSignatoryDialog();
          }}
        />
      )}
      {isRejectSignatoryDialogShowing && selectedRowSignatoryId && (
        <RejectSignatoryDialog
          signatoryId={selectedRowSignatoryId}
          onConfirm={() => {
            setSelectedSignatoryId(null);
            hideRejectSignatoryDialog();
            signatoriesListQuery.refetch();
          }}
          onCancel={() => {
            setSelectedSignatoryId(null);
            hideRejectSignatoryDialog();
          }}
        />
      )}
    </>
  );
};
