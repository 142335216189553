import { Alert, Box, Stack, Typography } from '@mui/material';
import { getDiff } from 'common/components/costSchedule';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { EVariationSection } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { useVariationContext } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationContext';
import {
  IVariationOverview,
  EVariationType,
  IVariationBoxProps,
  CostTypeVariation,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  Row,
  VariationOverviewBox,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/common';
import { useVariationOverview } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { getGbpAmount, isOfType } from 'util/AppUtils';

export const CostChangesBox = (props: IVariationBoxProps) => {
  const variation = props.data.variations.find(isOfType(EVariationType.Cost));
  const worksPackageTotalOriginal = props.data.worksPackageTotalOriginal;

  if (!variation) {
    return null;
  }

  return (
    <CostChangesBoxMain
      {...props}
      variation={variation}
      worksPackageTotalOriginal={worksPackageTotalOriginal}
    />
  );
};

const CostChangesBoxMain = ({
  data,
  isEditable = false,
  variation,
  worksPackageTotalOriginal,
}: IVariationBoxProps & {
  variation: CostTypeVariation;
  worksPackageTotalOriginal: number;
}) => {
  const { setSelectedItemId } = useVariationContext();
  const {
    isVariancePercentageGreaterThanThreshold,
    variationPercentage,
    variationAmount,
  } = useVariationOverview();

  return (
    <Box>
      <Stack gap={2}>
        <VariationOverviewBox
          title="Cost changes"
          onEditClick={
            !isEditable
              ? undefined
              : () => {
                  setSelectedItemId(EVariationSection.CostSchedule);
                }
          }
        >
          <Stack gap={2}>
            <Box sx={{ bgcolor: 'white', p: 2 }}>
              <Stack gap={2}>
                <Row
                  label="Current works package"
                  value={getGbpAmount({
                    value: variation.workPackageTotalCurrent,
                  })}
                />
                <Row
                  label="Variation"
                  value={getGbpAmount({
                    value: variation.workPackageVariationAmount,
                  })}
                />

                {variation.amendedVariationAmount ? (
                  <AmendedRow variation={variation} />
                ) : null}

                <Row
                  label="Requested works package"
                  labelSx={{ fontWeight: 600 }}
                  value={getGbpAmount({
                    value: variation.workPackageTotalNew,
                  })}
                  valueSx={{ fontWeight: 600 }}
                />
              </Stack>
            </Box>
            <Box
              sx={{
                p: 2,
                borderLeftStyle: 'solid',
                borderLeftWidth: '5px',
                borderLeftColor: isVariancePercentageGreaterThanThreshold
                  ? 'red'
                  : 'green',
                background: isVariancePercentageGreaterThanThreshold
                  ? '#f6d7d2'
                  : '#d3f6d2',
              }}
            >
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Box>
                  <Typography fontWeight={600}>Variance</Typography>
                  <GreyText value={'(original works package)'} />
                </Box>
                <Stack
                  direction={'row'}
                  justifyContent={'flex-end'}
                  flexGrow={1}
                  gap={1}
                >
                  <Typography
                    color={
                      isVariancePercentageGreaterThanThreshold ? 'red' : 'green'
                    }
                    component="span"
                    fontWeight={600}
                  >
                    {variationPercentage && variationPercentage > 0 ? '+' : ''}
                    {variationPercentage}%
                  </Typography>
                  <GreyText
                    component="span"
                    value={`(${getGbpAmount({
                      value: variationAmount,
                    })})`}
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </VariationOverviewBox>

        <VariationOverviewBox title="Evidence of variation request">
          {data.variationEvidenceFiles &&
          data.variationEvidenceFiles.length > 0 ? (
            data.variationEvidenceFiles.map(file => (
              <ApplicationFileDownloadWrapper
                key={file.id}
                fileId={file.id}
                fileName={file.name}
              />
            ))
          ) : (
            <Alert severity="warning">No evidence files found</Alert>
          )}
        </VariationOverviewBox>
      </Stack>
    </Box>
  );
};

export const AmendedRow = ({
  variation,
}: {
  variation: Extract<
    IVariationOverview['variations'][number],
    { type: EVariationType.Cost }
  >;
}) => {
  if (!variation.amendedVariationAmount) {
    return null;
  }

  const diff = getDiff(
    variation.workPackageVariationAmount,
    variation.amendedVariationAmount
  );

  return (
    <>
      <Row
        label="Amended Variation"
        value={getGbpAmount({
          value: variation.amendedVariationAmount,
        })}
      />
      <Row
        containerSx={{ mt: -2 }}
        label=""
        value={getGbpAmount({
          value: diff,
        })}
        valueSx={{
          fontSize: '0.9em',
          color: 'red',
        }}
      />
    </>
  );
};

const GreyText = ({
  value,
  component = 'p',
}: {
  value: string;
  component?: 'span' | 'p';
}) => {
  return (
    <Typography fontSize={'0.9em'} color={'grey.600'} component={component}>
      {value}
    </Typography>
  );
};
