import { useState } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import {
  useCreateSupportTicketMutation,
  useReferToRemediationAdviserMutation,
} from 'api/application';
import { useReferSupportRequestMutation } from 'api/application/applicationSupportApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { useLocalSnackbar, useModalState } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  INewSupportRequestData,
  IReferToRemediationAdviser,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';

interface IShowReferModalConfig {
  config:
    | {
        type: 'existingTicket';
        supportTicketId: string;
      }
    | {
        type: 'newTicket';
        newTicketData: INewSupportRequestData;
        onSuccess: () => void;
      }
    | {
        type: 'referToRemediationAdviser';
        supportTicketId: string;
        formData: IReferToRemediationAdviser;
        onSuccess: () => void;
      };
}

export const useReferSupportRequestModal = () => {
  const {
    isShowing: isReferModalShowing,
    showModal,
    hideModal,
  } = useModalState();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();
  const { applicationId } = useApplicationContext();
  const [refer, referStatus] = useReferSupportRequestMutation();
  const [referToRemediationAdviser, referToRemediationAdviserStatus] =
    useReferToRemediationAdviserMutation();
  const [createSupportRequest, createSupportRequestStatus] =
    useCreateSupportTicketMutation();
  const [params, setParams] = useState<IShowReferModalConfig | null>(null);

  const showReferModal = (params: IShowReferModalConfig) => {
    setParams(params);
    showModal();
  };

  const hideReferModal = () => {
    setParams(null);
    hideModal();
  };

  const handleReferClick = () => {
    if (!params) {
      createErrorSnackbar(
        'Could not get configuration. Please try again later.'
      );
      return;
    }

    const { config } = params;
    if (config.type === 'existingTicket') {
      // if  this is an existing ticket then we will pass the supportTicketId in
      refer({ applicationId, supportTicketId: config.supportTicketId })
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Support request has been referred`);
          hideReferModal();
        });
    }

    if (config.type === 'newTicket') {
      // If this is a new ticket then we first need to create the ticket
      // Then get the supportTicketId from the response and use that to refer
      createSupportRequest(config.newTicketData)
        .unwrap()
        .then(payload => {
          // The support request was created ok, now refer it
          refer({ applicationId, supportTicketId: payload.supportTicketId })
            .unwrap()
            .then(payload => {
              createSuccessSnackbar(`Support request has been referred`);
              hideReferModal();
              config.onSuccess();
            });
        })
        .catch(err => {
          createErrorSnackbar(
            'Something went wrong creating the support request. Please try again later.'
          );
        });
    }

    if (config.type === 'referToRemediationAdviser') {
      referToRemediationAdviser({
        supportTicketData: {
          applicationId,
          supportTicketId: config.supportTicketId,
        },
        formData: config.formData,
      })
        .unwrap()
        .then(() => {
          createSuccessSnackbar(`Support request has been referred`);
          hideReferModal();
          config.onSuccess();
        });
    }
  };

  const renderReferModal = () => {
    if (!params) {
      return null;
    }

    const { config } = params;
    const isLoading =
      referStatus.isLoading ||
      createSupportRequestStatus.isLoading ||
      referToRemediationAdviserStatus.isLoading;
    return (
      <ConfirmationModal
        isShowing={isReferModalShowing}
        confirmButtonText="Yes, Refer"
        cancelButtonText="Cancel"
        onConfirm={handleReferClick}
        onCancel={hideReferModal}
        dialogActionsSx={{ justifyContent: 'center', mb: 2 }}
        areButtonsLoading={isLoading}
      >
        {isLoading ? <LinearProgress /> : null}

        <Typography variant="body1" pt={1} fontWeight={600}>
          {config.type === 'referToRemediationAdviser'
            ? 'Are you sure you want to refer the support request'
            : 'Are you sure you want to refer the support request to Homes England'}
          ?
        </Typography>

        <AlertForQueryErrorOrNull
          isError={referStatus.isError}
          error={referStatus.error}
        />
        <AlertForQueryErrorOrNull
          isError={createSupportRequestStatus.isError}
          error={createSupportRequestStatus.error}
        />
        <AlertForQueryErrorOrNull
          isError={referToRemediationAdviserStatus.isError}
          error={referToRemediationAdviserStatus.error}
        />
      </ConfirmationModal>
    );
  };

  return {
    showReferModal,
    renderReferModal,
  };
};
