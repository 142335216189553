import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ESortDirection } from 'enums/ESortDirection';
import {
  IGetPullInProcessesPaginatedQuery,
  TPullInProcessGridListItem,
} from 'types/pullInProcess/pullInProcessTypes';
import { nameof } from 'util/formUtils';

export interface IpullInProcessState {
  gridState: IGetPullInProcessesPaginatedQuery;
}

const initialState: IpullInProcessState = {
  gridState: {
    skip: 0,
    take: 10,
    stage: [],
    statuses: [],
    searchValue: null,
    sortBy: nameof<TPullInProcessGridListItem>('creationDate'),
    sortDirection: ESortDirection.Asc,
  },
};

const pullInProcessSlice = createSlice({
  name: 'pullInProcess',
  initialState,
  reducers: {
    gridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetPullInProcessesPaginatedQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
  },
});

export const pullInProcessReducer = pullInProcessSlice.reducer;

export const { gridStateChanged: pullInProcessGridStateChanged } =
  pullInProcessSlice.actions;
