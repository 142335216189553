import { Button } from '@mui/material';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { useModalState } from 'hooks';
import { ReferApplicationModal } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication/ReferApplicationModal';

interface IReferApplicationButton {
  sectionType: EEligibilitySection;
  disabled?: boolean;
}

export const ReferApplicationButton = ({
  sectionType,
  disabled,
}: IReferApplicationButton) => {
  const { isShowing, showModal, hideModal } = useModalState();
  return (
    <>
      <Button
        variant="outlined"
        disabled={disabled}
        onClick={_ => showModal()}
      >
        Refer Application
      </Button>

      {isShowing ? (
        <ReferApplicationModal
          sectionType={sectionType}
          onConfirm={() => {
            hideModal();
          }}
          onCancel={() => {
            hideModal();
          }}
        />
      ) : null}
    </>
  );
};
