import { rest } from 'msw';
import { benchmarkFiguresHistoryData, currentBenchmarkFiguresData } from 'mocks/data/administration/administrationBenchmarkFiguresListData';

export const administrationBenchmarkFiguresServiceHandlers = [

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/benchmarkfigures/history`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(benchmarkFiguresHistoryData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/benchmarkfigures`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(currentBenchmarkFiguresData));
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/admin/benchmarkfigures`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
