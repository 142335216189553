import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, IconButton } from '@mui/material';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useApplicationSupportContext } from 'pages/applicationPage/content/support/context';
import { useGetSupportTicketInfo } from 'pages/applicationPage/content/support/supportHooks';

export const SupportOverviewBox = () => {
  const { setSelectedItemId } = useApplicationSupportContext();
  const {
    query: { isError, error, data, isLoading },
  } = useGetSupportTicketInfo();

  if (isLoading) {
    return <FormSkeleton />;
  }
  if (isError || !data) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }
  return (
    <RoundBorderBox sx={{ p: 2 }}>
      <Box mb={2}>
        <Typography
          color="grey.700"
          sx={{ marginBottom: 3, display: 'flex', alignItems: 'center' }}
          variant="h3"
          fontWeight={600}
          fontSize={'0.9em'}
        >
          <IconButton
            aria-label="edit"
            name="edit"
            onClick={event => setSelectedItemId(null)}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="xs" fixedWidth />
          </IconButton>
          Back to Support Overview
        </Typography>
        <Typography
          color="grey.700"
          sx={{ marginBottom: 3, marginLeft: 3 }}
          variant="h3"
          fontWeight={600}
          fontSize={'0.8em'}
        >
          # {data?.referenceNumber}
        </Typography>
        <Typography
          variant="h3"
          sx={{ marginLeft: 3 }}
          fontWeight={900}
          fontSize={'1.2em'}
        >
          Applicant requested support
        </Typography>
      </Box>
    </RoundBorderBox>
  );
};
