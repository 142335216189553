import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FormSkeleton } from 'common/components/skeletons';

interface IHandleLoadingOrErrorProps {
  isLoading: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export const HandleLoadingOrError = ({
  isLoading,
  isError,
  error,
}: IHandleLoadingOrErrorProps) => {
  return (
    <>
      {isLoading ? <FormSkeleton /> : null}
      <AlertForQueryErrorOrNull isError={isError} error={error} />
    </>
  );
};
