import { rest } from 'msw';
import { liveProjectListViewData, liveProjectOverviewData } from 'mocks/data/application/applicationLiveProjectData';

export const liveProjectHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/projectlive/overview`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(liveProjectOverviewData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/projectlive/list`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(liveProjectListViewData));
      }
      return req.passthrough();
    }
  ),
];
