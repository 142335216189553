import { Fragment, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  LinearProgress,
  Menu,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { ErrorAlert } from 'common/components/alerts';
import { EEligibilityQuestionLanguage } from 'enums/EEligibilityQuestion';
import { EEligibilitySectionLanguage } from 'enums/EEligibilitySection';
import {
  EReferralStatus,
  EReferralStatusLanguage,
} from 'enums/EReferralStatus';
import { EReferralTypeLanguage } from 'enums/EReferralType';
import { useEllipsisMenu, useModalState } from 'hooks';
import { CompleteReferralModal } from 'pages/applicationPage/content/applicationEligibility/components/ReferralsSection';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import { StyledGenericTable as Table } from 'styles/globalStyles/tables';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { extractErrorMessages } from 'util/ApiUtils';
import { getMenuItemConfig, getMenuItems } from 'util/AppUtils';

export enum EReferralsMenuType {
  Complete,
}

export const ReferralsGrid = () => {
  const { applicationEligibilityReferralsQuery } =
    useApplicationEligibilityContext();

  if (applicationEligibilityReferralsQuery.isLoading) {
    return <LinearProgress />;
  }

  if (applicationEligibilityReferralsQuery.isSuccess) {
    return <ReferralTable data={applicationEligibilityReferralsQuery.data} />;
  }

  if (applicationEligibilityReferralsQuery.isError) {
    return (
      <ErrorAlert
        msg={extractErrorMessages(applicationEligibilityReferralsQuery.error)}
      />
    );
  }

  return null;
};

const ReferralTable = ({
  data,
}: {
  data: ReturnType<
    typeof useApplicationEligibilityContext
  >['applicationEligibilityReferralsQuery']['data'];
}) => {
  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const [referralIdToComplete, setReferralIdToComplete] = useState<
    string | null
  >(null);

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Eligibility,
  });

  const { isShowing, showModal, hideModal } = useModalState();

  const handleModalClose = () => {
    setReferralIdToComplete(null);
    hideModal();
  };

  const handleMenuItemClick = (menuType: EReferralsMenuType) => {
    switch (menuType) {
      case EReferralsMenuType.Complete:
        setReferralIdToComplete(selectedId);
        showModal();
        break;
    }

    handleMenuClose();
  };

  return (
    <>
      <Table
        sx={{
          '& tr.completion-reason td': {
            borderTop: 0,
            pt: 0,
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Section</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data ? (
            // should update to a table skeleton helper, given x rows and x cells, it generates a loader for the table
            <Skeleton />
          ) : (
            data.map(referral => (
              <Fragment key={referral.id}>
                <TableRow>
                  <TableCell>
                    {EEligibilityQuestionLanguage[referral.question]}
                  </TableCell>
                  <TableCell>{referral.reason}</TableCell>
                  <TableCell>
                    {EReferralTypeLanguage[referral.referralType]}
                  </TableCell>
                  <TableCell>
                    {EEligibilitySectionLanguage[referral.section]}
                  </TableCell>
                  <TableCell>
                    {EReferralStatusLanguage[referral.status]}
                  </TableCell>
                  <TableCell aria-label="Actions">
                    {!isOnHoldForThisType &&
                    referral.status === EReferralStatus.Referred ? (
                      <>
                        <IconButton
                          onClick={event =>
                            handleEllipsisClick(referral.id, event)
                          }
                          aria-label="Actions"
                          name="Actions"
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                        {selectedId === referral.id ? (
                          <Menu
                            id={`referrals-menu-${referral.id}`}
                            anchorEl={ellipsisMenuAnchor}
                            open={Boolean(ellipsisMenuAnchor)}
                            onClose={handleMenuItemClick}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            {getMenuItems(
                              [
                                getMenuItemConfig(
                                  'Complete',
                                  EReferralsMenuType.Complete
                                ),
                              ],
                              handleMenuItemClick
                            )}
                          </Menu>
                        ) : null}
                      </>
                    ) : null}
                  </TableCell>
                </TableRow>
                {referral.status === EReferralStatus.Complete ? (
                  <TableRow className="completion-reason">
                    <TableCell colSpan={6}>
                      <Box
                        sx={{
                          p: 2,
                          backgroundColor: 'grey.100',
                          borderRadius: 2,
                          borderColor: 'grey.300',
                          borderWidth: 1,
                          borderStyle: 'solid',
                        }}
                      >
                        <Typography
                          variant="caption"
                          color={'grey.600'}
                          fontWeight={900}
                          fontSize="0.85em"
                        >
                          Completion Reason
                        </Typography>
                        <br />
                        {referral.completeReason}
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}
              </Fragment>
            ))
          )}
        </TableBody>
      </Table>

      {isShowing && referralIdToComplete ? (
        <CompleteReferralModal
          referralId={referralIdToComplete}
          onConfirm={handleModalClose}
          onCancel={handleModalClose}
        />
      ) : null}
    </>
  );
};
