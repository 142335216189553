import { Box, Typography } from '@mui/material';
import { BuildingDetailsForm } from 'pages/pullInProcessPage/components/buildingDetails/buildingDetailsForm';
import { StyledRoundBorderBox } from 'pages/pullInProcessPage/components/pullInProcessPageLayout';

export function PullInProcessPageBuildingDetails() {
  const _formId = 'pullBuildingDetailsForm';

  return (
    <>
      <StyledRoundBorderBox>
        <Box>
          <Typography component="h3" variant="h3" fontWeight={600}>
            Building Details
          </Typography>
        </Box>
        <BuildingDetailsForm formId={_formId} />
      </StyledRoundBorderBox>
    </>
  );
}
