import { Alert, SxProps } from '@mui/material';

export const AlertForChangedData = ({
  isDirty,
  sx,
}: {
  isDirty: boolean;
  sx?: SxProps;
}) => {
  return isDirty ? (
    <Alert severity="info" sx={{ m: 2, ...sx }}>
      There are unsaved changes in this section.
    </Alert>
  ) : null;
};
