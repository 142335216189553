import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

export const muiButtonTheme: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
} = {
  styleOverrides: {
    // <Button variant="outlined" />
    outlined: ({ theme }) => ({
      background: 'white',
      border: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.grey[700],
    }),
    // <Button variant="text" />
    text: {
      background: 'none',
      border: 'none',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
    root: {
      textTransform: 'none',
      padding: '0.75rem 1.5rem',
      boxShadow: 'none',
      height: '48px',
      letterSpacing: 'normal',
      wordSpacing: 'normal',
    },
  },
};
