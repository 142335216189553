import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  useAddCommunicationMutation,
  useGetApplicationCommunicationCategoriesQuery,
} from 'api/application/communicationsApi';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer } from 'styles/globalStyles/drawer';
import { StyledDrawerActions } from 'styles/globalStyles/largeDrawer';
import {
  ECommunicationCategory,
  ECommunicationType,
  IAddCommunication,
} from 'types/applications/ApplicationCommunicationTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IAddCommunication) =>
  nameof<IAddCommunication>(fieldName);

interface IAddCommunicationDrawerProps {
  onSuccess: () => void;
  onClose: () => void;
}

export function AddCommunicationDrawer({
  onClose,
  onSuccess,
}: IAddCommunicationDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">Add Communication</Typography>
        </DialogTitle>
        <AddCommunicationForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IAddCommunicationFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function AddCommunicationForm({
  onSuccess,
  onClose,
}: IAddCommunicationFormProps) {
  const { applicationId } = useApplicationContext();
  const { data: categoriesData } =
    useGetApplicationCommunicationCategoriesQuery();
  const [evidenceFileInvalid, setEvidenceFileInvalid] =
    useState<boolean>(false);
  const { uploadFile, uploadFileStatus } = useFileUpload();
  const [addCommunication, addCommunicationResult] =
    useAddCommunicationMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IAddCommunication>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zAddCommunicationSchema),
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = form;

  const validForm = (formData: IAddCommunication) => {
    let valid = true;
    setEvidenceFileInvalid(false);

    if (
      (formData.evidenceFileId === undefined ||
        formData.evidenceFileId === '') &&
      formData.communicationTypeId !== ECommunicationType.Phone
    ) {
      setEvidenceFileInvalid(true);
      valid = false;
    }
    return valid;
  };

  const onSubmit = async (formData: IAddCommunication) => {
    if (validForm(formData)) {
      try {
        formData.applicationId = applicationId;
        await addCommunication(formData)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`Communication added successfully`);
            onSuccess();
          })
          .catch(error => {
            if (error?.data?.generalError) {
              createErrorSnackbar(error?.data?.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to add communication`);
      }
    }
  };

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'CommunicationEvidence',
      onSuccess: payload => {
        form.setValue('evidenceFileId', payload.id);
      },
    });
  };

  const handleOnDeleteFileUpload = async () => {
    form.setValue('evidenceFileId', '');
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" fontWeight={600} fontSize="1em">
                Communication Type
              </Typography>
              <Controller
                defaultValue={null}
                name={getName('communicationTypeId')}
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => (
                  <FormControl sx={{ width: '50%' }}>
                    <ToggleButtonGroup
                      {...fieldProps}
                      onChange={(_, val) => {
                        if (val !== null) {
                          onChange(val);
                        }
                      }}
                      exclusive
                      fullWidth
                    >
                      <ToggleButton value={ECommunicationType.Phone}>
                        Phone
                      </ToggleButton>
                      <ToggleButton value={ECommunicationType.Letter}>
                        Letter
                      </ToggleButton>
                      <ToggleButton value={ECommunicationType.Email}>
                        Email
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {fieldState.error?.message ? (
                      <FormHelperText error>
                        {fieldState.error?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" fontWeight={600} fontSize="1em">
                Direction
              </Typography>
              <Controller
                defaultValue={null}
                name={getName('isInbound')}
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => (
                  <FormControl sx={{ width: '50%' }}>
                    <ToggleButtonGroup
                      {...fieldProps}
                      onChange={(_, val) => {
                        if (val !== null) {
                          onChange(val);
                        }
                      }}
                      exclusive
                      fullWidth
                    >
                      <ToggleButton value={true}>Inbound</ToggleButton>
                      <ToggleButton value={false}>Outbound</ToggleButton>
                    </ToggleButtonGroup>
                    {fieldState.error?.message ? (
                      <FormHelperText error>
                        {fieldState.error?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                rules={{ required: 'Category is required' }}
                control={control}
                name={getName('categoryId')}
                render={({ field, fieldState }) => (
                  <FormControl sx={{ minWidth: '100%', marginBottom: '1rem' }}>
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                      {...field}
                      value={field.value ?? ''}
                      fullWidth
                      error={!!fieldState.error}
                      labelId="category-label"
                      label="Category"
                      input={<OutlinedInput label="Category" />}
                    >
                      {categoriesData?.map(category => {
                        return (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {fieldState.error?.message ? (
                      <FormHelperText error>
                        {fieldState.error?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={getName('notes')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    fullWidth
                    error={!!fieldState.error}
                    required
                    rows={5}
                    label="Notes"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" fontWeight={600} fontSize="1em">
                Upload evidence of communication
              </Typography>
              <FileUploadInputSelect
                id="evidenceFileDocument"
                inputName="evidenceFileId"
                label="Click to upload or drag and drop file (max. 30MB)"
                subLabel="DOC, DOCX, PDF, XLS, XLSX, EML, PNG, JPG, JPEG or MSG"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.msg,.eml,.jpg,.jpeg,.png"
                onFileUpload={file => handleOnFileUpload(file)}
                onFileDelete={() => handleOnDeleteFileUpload()}
                isUploading={uploadFileStatus.isLoading}
                uploaded={uploadFileStatus.isSuccess}
              />
              {errors.evidenceFileId?.message ? (
                <FormHelperText error>
                  {errors.evidenceFileId?.message}
                </FormHelperText>
              ) : null}
              {evidenceFileInvalid && (
                <FormHelperText error>
                  Evidence file is required.
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={addCommunicationResult.isLoading}
            >
              Save
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
}

export const zAddCommunicationSchema = z.object({
  communicationTypeId: z.coerce
    .number()
    .gt(0, { message: 'Communication type is required' }),
  isInbound: z.boolean({
    required_error: 'Direction is required',
    invalid_type_error: 'Direction is required',
  }),
  categoryId: z.nativeEnum(ECommunicationCategory, {
    required_error: 'Category is required',
  }),
  notes: z
    .string()
    .nonempty('Note is required')
    .max(4000, '4000 characters is the maximum allowed.'),
  evidenceFileId: z.string().optional(),
});
