import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z, ZodType } from 'zod';
import { useDeleteApplicationInterventionMutation } from 'api/application';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDialog, StyledDialogTitle } from 'styles/globalStyles/dialog';
import { StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IDeleteApplicationIntervention } from 'types/applications/ApplicationTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IDeleteApplicationIntervention) =>
  nameof<IDeleteApplicationIntervention>(fieldName);

interface IDeleteInterventionCategoryDialogProps {
  interventionCategoryName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function DeleteInterventionCategoryDialog({
  interventionCategoryName,
  onConfirm,
  onCancel,
}: IDeleteInterventionCategoryDialogProps) {
  const { applicationId } = useApplicationContext();

  const [deleteApplicationIntervention, deleteApplicationInterventionResult] =
    useDeleteApplicationInterventionMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IDeleteApplicationIntervention>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zDeleteInterventionCategorySchema),
  });
  const { handleSubmit, control, reset } = form;

  const onSubmit = async (formData: IDeleteApplicationIntervention) => {
    try {
      await deleteApplicationIntervention({
        applicationId,
        comment: formData.comment,
      })
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Successfully removed intervention category'`);
          onConfirm();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to remove intervention category`);
    }
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        Remove Intervention Category{' '}
        <Typography variant="h3" textAlign="left">
          {interventionCategoryName}
        </Typography>
      </StyledDialogTitle>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={getName('comment')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="Comments"
                    helperText={fieldState.error?.message}
                    multiline
                    autoComplete="off"
                    inputProps={{ maxLength: 1000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={deleteApplicationInterventionResult.isLoading}
            >
              Remove Category
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </StyledDialog>
  );
}

export const zDeleteInterventionCategorySchema: ZodType<
  Pick<IDeleteApplicationIntervention, 'comment'>
> = z.object({
  comment: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});
