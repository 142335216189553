import { Box, Grid, Typography } from '@mui/material';
import { useGetEligibilitySummaryQuery } from 'api/application';
import { EQuestionType } from 'enums/EQuestionType';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ApplicationDetailsAnswerEditedIndicator } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsAnswerEditedIndicator';
import { ApplicationInfoPanelContextMenu } from 'pages/applicationPage/content/applicationDetails/components/ApplicationInfoPanelContextMenu';

export const ConfirmEligibilityInfoPanel = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetEligibilitySummaryQuery(applicationId);

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Building Details
        </Typography>
        {query.data ? (
          <>
            <Box>
              <Grid container gap={1} justifyContent={'space-between'}>
                <Grid item>
                  <DetailItem
                    label="Height"
                    value={
                      query.data.buildingHeightMetres
                        ? query.data.buildingHeightMetres + 'm'
                        : '-'
                    }
                  />
                </Grid>
                <Grid item gap={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                    }}
                  >
                    <ApplicationDetailsAnswerEditedIndicator
                      questionType={EQuestionType.BuildingHeight}
                    />
                    <ApplicationInfoPanelContextMenu
                      questionType={EQuestionType.BuildingHeight}
                      currentValue={
                        query.data.buildingHeightMetres
                          ? `${query.data.buildingHeightMetres}`
                          : '-'
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <DetailItem
              label="Local Authority"
              value={
                query.data.costCentreLocalAuthority
                  ? query.data.costCentreLocalAuthority
                  : '-'
              }
            />
            <DetailItem
              label="RE Type"
              value={
                query.data.responsibleEntityOrganisationType
                  ? query.data.responsibleEntityOrganisationType
                  : '-'
              }
            />
          </>
        ) : null}
      </ColumnContainer>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Grant Funding
        </Typography>
        {query.data ? (
          <>
            <Box>
              <Grid container gap={1} justifyContent={'space-between'}>
                <Grid item>
                  <DetailItem
                    label="Requested PTFS?"
                    value={
                      query.data.requestedPtfs !== undefined
                        ? query.data.requestedPtfs
                          ? 'Yes'
                          : 'No'
                        : '-'
                    }
                  />
                </Grid>

                <Grid item gap={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                    }}
                  >
                    <ApplicationDetailsAnswerEditedIndicator
                      questionType={EQuestionType.PtfsRequired}
                    />
                    <ApplicationInfoPanelContextMenu
                      questionType={EQuestionType.PtfsRequired}
                      currentValue={
                        query.data.requestedPtfs
                          ? `${query.data.requestedPtfs ? 'Yes' : 'No'}`
                          : '-'
                      }
                      readOnly={query.data.gfaComplete === true}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container gap={1} justifyContent={'space-between'}>
                <Grid item>
                  <DetailItem
                    label="PTS Funding Amount"
                    value={
                      query.data.ptsFundingAmount
                        ? `£${query.data.ptsFundingAmount.toLocaleString()}`
                        : '-'
                    }
                  />
                </Grid>
                <Grid item gap={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                    }}
                  >
                    <ApplicationDetailsAnswerEditedIndicator
                      questionType={EQuestionType.PtfsAmount}
                    />
                    <ApplicationInfoPanelContextMenu
                      questionType={EQuestionType.PtfsAmount}
                      currentValue={
                        query.data.ptsFundingAmount
                          ? `£${query.data.ptsFundingAmount.toLocaleString()}`
                          : '-'
                      }
                      readOnly={query.data.gfaComplete === true}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : null}
      </ColumnContainer>
    </>
  );
};
