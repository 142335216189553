import { IGetVariationCostsScheduleResponse } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  CostScheduleDescriptionProperties,
  ICostsSchedule,
} from 'types/applications/ApplicationCostScheduleTypes';

const tryGetCostScheduleProperty = (
  record: ICostsSchedule | null | undefined,
  propertyName: keyof ICostsSchedule
) => {
  if (!record || !record[propertyName]) {
    return undefined;
  }

  return record[propertyName];
};

export const getLatestAvailableDescription = (
  data: IGetVariationCostsScheduleResponse,
  descriptionFieldName: keyof CostScheduleDescriptionProperties
) => {
  return getCostScheduleDescription(
    [data.amended, data.variation, data.original],
    descriptionFieldName
  );
};

/**
 * Get the cost schedule description from the first element in the array which contains a value
 * @param records array of cost schedule record
 * @param descriptionFieldName name of property you want to retrieve
 * @returns string containing description value from first record in array which contains the value.
 * If no value can be found, returns a dash (`-`)
 */
export const getCostScheduleDescription = (
  records: (ICostsSchedule | null | undefined)[],
  descriptionFieldName: keyof CostScheduleDescriptionProperties
) => {
  return (
    records
      .map(record => tryGetCostScheduleProperty(record, descriptionFieldName))
      .find(item => !!item)
      ?.toString() ?? '-'
  );
};

export const getLatestCostSchedule = (
  data: IGetVariationCostsScheduleResponse
) => {
  return data.amended ?? data.variation ?? data.original;
};

export const getPreviousCostSchedule = (
  data: IGetVariationCostsScheduleResponse
) => {
  if (data.amended && data.variation) {
    return data.variation;
  }

  if (data.amended && data.original) {
    return data.original;
  }

  if (data.variation && data.original) {
    return data.original;
  }

  return null;
};
