import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Chip, Grid, Stack } from '@mui/material';
import { useGetApplicationSummaryQuery } from 'api/application';
import {
  useGetWorksPackageCostsScheduleQuery,
  useLazyGetWorksPackageCostsScheduleCsvQuery,
} from 'api/application/worksPackageApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import {
  FooterTotal,
  GridItemRight,
  VersionAndLabel,
  getDiffBetweenPreviousAndCurrent,
} from 'common/components/costSchedule';
import { useLocalSnackbar } from 'hooks';
import { useApplicationProjectPrepContext } from 'pages/applicationPage/content/projectPrep/context';

export const CostsScheduleFooter = () => {
  const { applicationId } = useApplicationProjectPrepContext();
  const applicationSummaryQuery = useGetApplicationSummaryQuery(applicationId);
  const { isSuccess, data } =
    useGetWorksPackageCostsScheduleQuery(applicationId);
  const hasPrevious = Boolean(data?.previous);

  const [triggerCsvDownload, triggerCsvDownloadStatus] =
    useLazyGetWorksPackageCostsScheduleCsvQuery();

  const { createSuccessSnackbar } = useLocalSnackbar();

  const handleCsvDownloadClick = () => {
    triggerCsvDownload({
      applicationId,
      fileName: `${
        applicationSummaryQuery.data?.referenceNumber ?? ''
      }WorksPackageCostsScheduleDownload.csv`,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Your file was downloaded');
      });
  };

  if (!isSuccess || !data.isAvailable) {
    return null;
  }

  return (
    <Grid
      container
      sx={{
        borderStyle: 'solid',
        borderColor: 'grey.300',
        borderWidth: '1px 0 0',
        mt: 3,
      }}
    >
      <Grid item xs={3.5} display={'flex'} flexDirection={'column'} p={3}>
        <Stack rowGap={2}>
          <LoadingButton
            variant="outlined"
            fullWidth
            startIcon={<FontAwesomeIcon icon={faDownload} />}
            loading={triggerCsvDownloadStatus.isLoading}
            onClick={handleCsvDownloadClick}
          >
            Download CSV
          </LoadingButton>

          <AlertForQueryErrorOrNull
            isError={triggerCsvDownloadStatus.isError}
            error={triggerCsvDownloadStatus.error}
          />
        </Stack>
      </Grid>
      <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
        <Grid container px={3}>
          <Grid
            item
            xs={hasPrevious ? 4 : 8}
            display={'flex'}
            alignItems={'center'}
          >
            <Stack direction={'row'} gap={1}>
              <Chip size="small" label={!!data.previous ? 'v2' : 'v1'} />
              {hasPrevious ? <Chip size="small" label={'Edited'} /> : null}
            </Stack>
          </Grid>
          {hasPrevious ? (
            <>
              <GridItemRight xs={2}>
                <FooterTotal
                  label="Total ineligible costs"
                  value={data.current.ineligibleAmount ?? 0}
                  color="red"
                />
              </GridItemRight>
              <GridItemRight xs={2}>
                <FooterTotal
                  label={<VersionAndLabel version={1} label={'Submitted'} />}
                  value={data.previous?.overallTotal ?? 0}
                  color="grey"
                />
              </GridItemRight>
              <GridItemRight xs={2}>
                <FooterTotal
                  label="Amendments"
                  value={
                    getDiffBetweenPreviousAndCurrent(
                      data.previous,
                      data.current,
                      'overallTotal'
                    ) ?? 0
                  }
                  color="grey"
                />
              </GridItemRight>
              <GridItemRight xs={2}>
                <FooterTotal
                  label={
                    <VersionAndLabel
                      version={data.currentVersion ? data.currentVersion : 2}
                      label={'Total costs'}
                    />
                  }
                  value={data.current.overallTotal}
                />
              </GridItemRight>
            </>
          ) : (
            <>
              <GridItemRight xs={2}>
                <FooterTotal
                  label="Total ineligible costs"
                  value={data.current.ineligibleAmount ?? 0}
                  color="red"
                />
              </GridItemRight>
              <GridItemRight xs={2}>
                <FooterTotal
                  label="Total eligible costs"
                  value={data.current.overallTotal}
                />
              </GridItemRight>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
