import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

export const OverviewSummaryItem = ({
  title,
  value,
  bottomBorder = true,
}: {
  title: string;
  value: ReactNode;
  bottomBorder?: boolean;
}) => {
  return (
    <Box
      sx={
        bottomBorder
          ? {
              borderColor: 'divider',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              pb: 1,
            }
          : null
      }
    >
      <Typography variant="body1" color={'grey.700'}>
        {title}
      </Typography>
      <Typography variant="body1" fontWeight={600} component={'div'}>
        {value}
      </Typography>
    </Box>
  );
};
