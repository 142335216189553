import { useSaveVariationIneligibleValuesMutation } from 'api/application/variationApi';
import {
  ECostScheduleParentType,
  IneligibleForm,
} from 'common/components/costSchedule/forms';
import { useFormWrapperDependencies } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection/forms';
import { IIneligibleForm } from 'types/applications/ApplicationCostScheduleTypes';

export const IneligibleFormWrapper = () => {
  const {
    handleUnwrappedSaveResult,
    queryParams,
    handleCancelClick,
    costScheduleData,
    previousCostScheduleData,
  } = useFormWrapperDependencies();

  const [save, saveStatus] = useSaveVariationIneligibleValuesMutation();

  const saveFn = (formData: IIneligibleForm) => {
    if (!queryParams) {
      return false;
    }

    handleUnwrappedSaveResult(save({ ...queryParams, formData }).unwrap());
  };

  if (!costScheduleData) {
    return null;
  }

  return (
    <IneligibleForm
      parentType={ECostScheduleParentType.Variation}
      originalData={previousCostScheduleData}
      data={costScheduleData}
      handleCancelClick={handleCancelClick}
      saveFn={saveFn}
      saveStatus={saveStatus}
    />
  );
};
