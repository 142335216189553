import { Box, Typography } from '@mui/material';
import { IWorksPackageOverview } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';

export const BuildingDetailsCard = ({
  title,
  data,
}: {
  title: string;
  data?: IWorksPackageOverview['buildingDetails'];
}) => {
  return (
    <Box
      mb={2}
      bgcolor={'grey.100'}
      sx={{
        width: '100%',
        padding: 2,
        paddingTop: 3,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h3"
        marginBottom={4}
        fontWeight={900}
        fontSize={'1.05em'}
      >
        {title}
      </Typography>

      <BuildingDetailsCardData
        data={data as IWorksPackageOverview['buildingDetails']}
      />
    </Box>
  );
};

export const BuildingDetailsCardData = ({
  data,
}: {
  data: IWorksPackageOverview['buildingDetails'];
}) => {
  const stackedData: [string, string][] = [
    [
      'Building height',
      `${data?.buildingHeightMetres?.toString()} metres` ?? '',
    ],
    ['Remediation type', data?.replacementType ?? ''],
    ['Responsible Entity type', data?.responsibleEntityType ?? ''],
  ];

  return (
    <>
      {stackedData.map(([title, value], i) => (
        <CardDetailsStacked key={i} title={title} value={value} />
      ))}
    </>
  );
};

const CardDetailsStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <CardDataTitle title={title} />

        <Typography fontSize={'0.8em'} fontWeight={600}>
          {value}
        </Typography>
      </Box>
    </>
  );
};

const CardDataTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="body1" fontWeight={'600'} fontSize={'0.8em'}>
      {title}
    </Typography>
  );
};
