export enum EScheduleOfWorksStatus {
  ToDo = 1,
  InProgress = 2,
  Submitted = 3,
  Approved = 4,
  Rejected = 5,
}

export const EScheduleOfWorksStatusLanguage: { [key in EScheduleOfWorksStatus]: string } = {
  [EScheduleOfWorksStatus.ToDo]: 'To Do',
  [EScheduleOfWorksStatus.InProgress]: 'In Progress',
  [EScheduleOfWorksStatus.Submitted]: 'Submitted',
  [EScheduleOfWorksStatus.Approved]: 'Approved',
  [EScheduleOfWorksStatus.Rejected]: 'Rejected',
};