import { rest } from 'msw';
import {
  DutyOfCareSentStatus,
  DutyOfCareSentSignedStatus,
  dutyOfCareSentCounterSignedStatus,
} from 'mocks/data/application/applicationDutyOfCareData';

export const dutyOfCareHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/dutyofCare/sent`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(DutyOfCareSentStatus));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/dutyofcare/signed`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(DutyOfCareSentSignedStatus));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/dutyofcare/countersigned`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(dutyOfCareSentCounterSignedStatus));
      }
      return req.passthrough();
    }
  ),
];
