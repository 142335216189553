import { Box, LinearProgress, Typography } from '@mui/material';

export const SectionProgress = ({
  currentStep,
  totalSteps,
}: {
  currentStep: number;
  totalSteps: number;
}) => {
  const progress = Math.round((100 / totalSteps) * currentStep);

  return (
    <Box
      border={2}
      borderRadius={0.75}
      borderColor="divider"
      bgcolor="grey.100"
      sx={{
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3" fontWeight={600} fontSize="1em">
          {`Progress (${progress}%)`}
        </Typography>
        <Typography variant="h3" fontWeight={600} fontSize="1em">
          {`${currentStep} / ${totalSteps}`}
        </Typography>
      </Box>
      <LinearProgress variant="determinate" value={progress} aria-label={`Progress ${progress} percent. Step ${currentStep} out of ${totalSteps}`} />
    </Box>
  );
};
