import { createContext, useContext } from 'react';
import { useModalState } from 'hooks';

export const PullInProcessesContext = createContext<
  ReturnType<typeof useModalState<string>> | undefined
>(undefined);

export function usePullInProcessesContext() {
  const context = useContext(PullInProcessesContext);
  if (context === undefined) {
    throw new Error(
      'usePullInProcessesContext must be used within an PullInProcessesContext'
    );
  }
  return context;
}
