import { Grid, Box, Button, Typography } from '@mui/material';
import {
  VariationContextProvider,
  VariationSectionOutlet,
  useVariationContext,
} from '.';
import { ReferralsSidebar } from 'common/components/referrals';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { ApplicationItemGroupNavigator } from 'pages/applicationPage/common/components';
import { EVariationSection } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { StatusChip } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components';
import {
  useVariationOverview,
  useVariationReferrals,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { CostsScheduleFooter } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import {
  formatDateDayShortMonthYear,
  getDifferenceInDays,
} from 'util/DateUtils';

const getDateString = (date: string) => {
  const daysAgo = Math.abs(getDifferenceInDays(new Date(date)));

  return `${formatDateDayShortMonthYear(new Date(date))} (${daysAgo} ${
    daysAgo === 1 ? 'day' : 'days'
  } ago)`;
};

const useCategories = () => {
  return [
    {
      categoryTitle: null,
      detailItems: [
        {
          id: EVariationSection.Overview,
          sectionName: 'Overview',
        },
        {
          id: EVariationSection.CostSchedule,
          sectionName: 'Cost Schedule',
        },
      ],
    },
    {
      categoryTitle: 'Confirm variation request',
      detailItems: [
        {
          id: EVariationSection.Checks,
          sectionName: 'Variation request checks',
        },
      ],
    },
  ];
};

export const VariationSection = () => {
  return (
    <VariationContextProvider>
      <VariationSectionMain />
    </VariationContextProvider>
  );
};

const VariationSectionMain = () => {
  const { setSelectedItem } = useApplicationLiveProjectContext();
  const {
    selectedItemId: selectedVariationItem,
    setSelectedItemId: setSelectedVariationItemId,
  } = useVariationContext();
  const categories = useCategories();
  const { hasCostType, query } = useVariationOverview();
  const { isSuccess, data } = query;

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={3.5}>
          <RoundBorderBox sx={{ p: 2, mb: 2 }}>
            <Button
              variant="text"
              onClick={() => setSelectedItem(null)}
              sx={{ pl: 0, mb: 0.5 }}
            >
              {'< Back to live project'}
            </Button>

            <CardTitle title={'Variation Request'} fontSize="1.2em" />

            {isSuccess ? <StatusChip status={data.status} /> : null}

            <Typography color={'grey.700'} sx={{ pt: 1 }}>
              Date submitted
            </Typography>
            <Typography>
              {data?.dateSubmitted ? getDateString(data.dateSubmitted) : '-'}
            </Typography>
          </RoundBorderBox>

          <RoundBorderBox sx={{ mb: 2 }}>
            <Box p={2}>
              <ApplicationItemGroupNavigator
                itemGroups={categories.map(category => {
                  return {
                    title: category.categoryTitle,
                    isEnabled: true,
                    items: category.detailItems
                      .filter(detailItem => {
                        if (
                          !hasCostType &&
                          detailItem.id === EVariationSection.CostSchedule
                        ) {
                          return false;
                        }

                        return true;
                      })
                      .map(detailItem => {
                        return {
                          title: detailItem.sectionName,
                          isSelected: selectedVariationItem === detailItem.id,
                          onClick: () => {
                            setSelectedVariationItemId(detailItem.id);
                          },
                        };
                      }),
                  };
                })}
              />
            </Box>
          </RoundBorderBox>

          <VariationReferralsSidebar />
        </Grid>
        <Grid item xs={8.5}>
          <RoundBorderBox sx={{ border: 0 }}>
            <VariationSectionOutlet />
          </RoundBorderBox>
        </Grid>
      </Grid>

      {selectedVariationItem === EVariationSection.CostSchedule ? (
        <CostsScheduleFooter />
      ) : null}
    </>
  );
};

const VariationReferralsSidebar = () => {
  const { query } = useVariationReferrals();
  return <ReferralsSidebar query={query} />;
};
