import { ZodType, z } from 'zod';
import { EPullInProcessContactType } from 'enums/EPullInProcessContactType';
import { TContactDetails } from 'types/pullInProcess/contactDetailsTypes';

export const zContactDetailsAddressSchema: ZodType<
  Pick<TContactDetails, 'address'>
> = z.object({
  address: z.object({
    id: z.string().optional(),
    postCode: z.string().min(1, 'Postcode is required'),
    line1: z
      .string({ required_error: 'Line 1 is required' })
      .min(1, 'Line 1 is required'),
    line2: z.string().optional(),
    townCity: z
      .string({ required_error: 'Town / City is required' })
      .min(1, 'Town / City is required'),
    county: z.string().optional(),
    country: z.object(
      {
        name: z.string(),
        id: z.number(),
      },
      {
        required_error: 'Country is required',
        invalid_type_error: 'Country is required',
      }
    ),
  }),
});

export const zContactTypeSchema: ZodType<
  Pick<TContactDetails, 'contactTypeId' | 'otherContactType'>
> = z
  .object({
    contactTypeId: z.nativeEnum(EPullInProcessContactType, {
      required_error: 'Contact type is required',
    }),
    otherContactType: z.string().optional(),
  })
  .refine(
    schema => {
      return schema.contactTypeId === EPullInProcessContactType.Other
        ? schema.otherContactType !== ''
        : true;
    },
    {
      message: 'Other contact type information is required',
      path: ['otherContactType'],
    }
  );

export const zDefaultContactSchema: ZodType<
  Pick<TContactDetails, 'communicationStageTypeId' | 'defaultContact'>
> = z
  .object({
    defaultContact: z.boolean(),
    communicationStageTypeId: z.number().optional(),
  })
  .refine(
    schema => {
      return schema.defaultContact ? !!schema.communicationStageTypeId : true;
    },
    {
      message: 'Communication stage is required for the default contact',
      path: ['communicationStageTypeId'],
    }
  );

export const zContactDetailsSchema: ZodType<
  Omit<
    TContactDetails,
    | 'contactTypeId'
    | 'otherContactType'
    | 'address'
    | 'communicationStageTypeId'
    | 'defaultContact'
  >
> = z.object({
  companyRegistrationNumber: z.string().optional(),
  companyName: z.string().optional(),
  firstName: z.string().optional(),
  surname: z.string().optional(),
  emailAddress: z.string().optional(),
  telephoneNumber: z.string().optional(),
});

export const zContactDetailsPart1Schema: ZodType<Partial<TContactDetails>> =
  z.intersection(zContactDetailsSchema, zContactDetailsAddressSchema);

export const zContactDetailsPart2Schema: ZodType<TContactDetails> =
  z.intersection(zContactTypeSchema, zDefaultContactSchema);

export const zContactDetailsFinalForm: ZodType<TContactDetails> =
  z.intersection(zContactDetailsPart1Schema, zContactDetailsPart2Schema);
