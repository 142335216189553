import { Typography } from '@mui/material';

interface INoResultsFoundTextProps {
  show: boolean;
  text?: string;
}

export const NoResultsFoundText = ({
  show,
  text = 'No results found',
}: INoResultsFoundTextProps) => {
  return <>{show && <Typography tabIndex={0}>{text}</Typography>}</>;
};
