import { MenuItem } from '@mui/material';
import { teamLanguageTuple } from 'enums/ETeam';

export const getTeamSelectItems = teamLanguageTuple.map(
  ([teamId, teamName]) => (
    <MenuItem key={teamId} value={teamId}>
      {teamName}
    </MenuItem>
  )
);
