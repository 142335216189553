import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { add, parseISO } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ZodType, z } from 'zod';
import { useEditPaymentAlreadyMadeMutation } from 'api/application/paymentApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { IPaymentAlreadyMade } from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { hasDecimal } from 'util/AppUtils';
import { getDateOnlyIsoString } from 'util/DateUtils';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IPaymentAlreadyMade) =>
  nameof<IPaymentAlreadyMade>(fieldName);

export const PaymentHasAlreadyBeenMadeSection = () => {
  const { applicationId } = useApplicationContext();

  const [editPaymentAlreadyMade, editPaymentAlreadyMadeResult] =
    useEditPaymentAlreadyMadeMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IPaymentAlreadyMade>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zPaymentAlreadyMadeSchema),
  });
  const { handleSubmit } = form;

  const onSubmit = async (formData: IPaymentAlreadyMade) => {
    try {
      formData.applicationId = applicationId;
      await editPaymentAlreadyMade(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Payment recommendation updated successfully`);
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit payment recommendation`);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        paddingTop: 3,
        border: '1px solid lightgray',
        borderRadius: 1,
        borderLeft: '5px solid black',
        borderLeftColor: 'black',
        bgcolor: grey[100],
      }}
    >
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={event => {
            event.stopPropagation();
            handleSubmit(onSubmit)(event);
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={800} mb={2}>
                Existing Payment details
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" mb={1}>
                How much was this payment?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" mb={1}>
                Payment date
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" mb={1}>
                Payment reference
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Controller
                name={getName('amount')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="number"
                    error={!!fieldState.error}
                    required
                    helperText={fieldState.error?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      inputProps: {
                        min: 1,
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name={getName('paymentDate')}
                render={({
                  field: { onChange, value, ...field },
                  formState: { errors },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      format="dd/MM/yyyy"
                      maxDate={add(new Date(), { days: -1 })}
                      value={value && value !== '' ? parseISO(value) : null}
                      onChange={val => {
                        if (typeof val === 'string') {
                          return '';
                        }

                        if (hasDecimal(Number(val))) return;

                        return val ? onChange(getDateOnlyIsoString(val)) : null;
                      }}
                      slotProps={{
                        textField: {
                          sx: { mr: '1rem' },
                          error: !!errors.paymentDate,
                          helperText: errors.paymentDate?.message,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={form.control}
                name="paymentReference"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    error={!!fieldState.error}
                    rows={3}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 0 }}
                    inputProps={{ maxLength: 20 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="outlined"
                type="submit"
                disabled={editPaymentAlreadyMadeResult.isLoading}
              >
                Confirm Payment
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

type PaymentFormZodSchema = Omit<IPaymentAlreadyMade, 'applicationId'>;

export const zPaymentAlreadyMadeSchema: ZodType<PaymentFormZodSchema> =
  z.object({
    amount: z.coerce
      .number()
      .gt(0, { message: 'Amount must be greater than 0' }),
    paymentDate: z.string().nonempty('Payment date is required'),
    paymentReference: z
      .string()
      .nonempty('Payment reference is required')
      .max(1000, '20 characters is the maximum allowed.'),
  });
