import {
  faKey,
  faSignOut,
  faUser,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Divider,
  Drawer,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import { DrawerHeader } from 'common/components/leftHandNav/LeftHandNavStyles';
import { useCurrentUser, useModalState } from 'hooks';
import { ResetMyPasswordDialog } from 'pages/myAccount/components/resetMyPasswordDialog/ResetMyPasswordDialog';

interface IMyAccountMenuHeader {
  onClick: () => void;
}

function MyAccountMenuHeader({ onClick }: IMyAccountMenuHeader) {
  const { name, initials } = useCurrentUser();
  return (
    <MenuItem disableGutters dense onClick={onClick} aria-label="Close menu">
      <Grid container display="flex" alignItems="center">
        <Avatar
          sx={({ palette }) => ({
            mr: '12px',
            backgroundColor: deepPurple[500],
            color: palette.getContrastText(palette.primary.main),
          })}
        >
          {initials}
        </Avatar>
        <Grid>
          <Typography
            variant="overline"
            color={({ palette }) => palette.common.black}
            fontWeight="600"
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </MenuItem>
  );
}

interface IMyAccountMenuItemProps {
  icon: IconDefinition;
  label: string;
  href?: string;
  onMenuItemClick: () => void;
}

function MyAccountMenuItem({
  icon,
  label,
  href,
  onMenuItemClick,
}: IMyAccountMenuItemProps) {
  return (
    <MenuItem disableGutters dense onClick={onMenuItemClick}>
      <ListItemIcon>
        <FontAwesomeIcon icon={icon} style={{ marginRight: '12px' }} />
      </ListItemIcon>
      <Typography>{label}</Typography>
    </MenuItem>
  );
}

interface IMyAccountMenuItems {
  onMenuItemClick: () => void;
}

const MyAccountMenuItems = ({ onMenuItemClick }: IMyAccountMenuItems) => {
  const authContext = useAuth();
  const navigate = useNavigate();

  const {
    isShowing: isResetMyPasswordDialogShowing,
    showModal: showResetMyPasswordDialog,
    hideModal: hideResetMyPasswordDialog,
  } = useModalState();

  const onResetMyPasswordMenuItemClick = () => {
    showResetMyPasswordDialog();
  };

  return (
    <>
      <MyAccountMenuItem
        icon={faUser}
        label="My Account"
        aria-label="My Account"
        onMenuItemClick={() => {
          navigate('/myAccount');
          onMenuItemClick();
        }}
      />
      <MyAccountMenuItem
        icon={faKey}
        label="Update Password"
        aria-label="Update Password"
        onMenuItemClick={() => {
          onResetMyPasswordMenuItemClick();
        }}
      />
      <MyAccountMenuItem
        icon={faSignOut}
        label="Logout"
        aria-label="Logout"
        href="/" // TODO: Add `href` for this route
        onMenuItemClick={() => {
          authContext.signOutRedirect();
        }}
      />
      {isResetMyPasswordDialogShowing && (
        <ResetMyPasswordDialog
          onConfirm={() => {
            hideResetMyPasswordDialog();
            onMenuItemClick();
          }}
          onCancel={() => {
            hideResetMyPasswordDialog();
            onMenuItemClick();
          }}
        />
      )}
    </>
  );
};

const MYACCOUNTMENUMINWIDTH = '17.5rem';

interface IMyAccountMenuProps {
  closeMenu: () => void;
  clearAnchorEl: () => void;
  isMenuOpen: boolean;
  anchorEl: HTMLElement | null;
  isMenuOrDrawer: 'menu' | 'drawer';
}

export function MyAccountMenu({
  closeMenu,
  clearAnchorEl,
  isMenuOpen,
  anchorEl,
  isMenuOrDrawer,
}: IMyAccountMenuProps) {
  const renderInnerMenu = () => (
    <div>
      <MyAccountMenuHeader onClick={closeMenu} />
      <Divider />
      <MyAccountMenuItems onMenuItemClick={closeMenu} />
    </div>
  );

  if (isMenuOrDrawer === 'menu') {
    return (
      <Menu
        MenuListProps={{
          'aria-labelledby': 'my-account-menu',
        }}
        anchorEl={anchorEl}
        id="my-account-menu"
        onClose={() => {
          closeMenu();
          clearAnchorEl();
        }}
        open={isMenuOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          style: { padding: '0.75rem 1.9rem', minWidth: MYACCOUNTMENUMINWIDTH },
        }}
      >
        <MyAccountMenuHeader onClick={closeMenu} />
        <Divider />
        <MyAccountMenuItems onMenuItemClick={closeMenu} />
      </Menu>
    );
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={isMenuOpen}
        onClose={() => closeMenu()}
        PaperProps={{ style: { padding: '16px', maxWidth: '90vw' } }}
      >
        <DrawerHeader />
        {renderInnerMenu()}
      </Drawer>
    </>
  );
}
