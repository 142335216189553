import { ERegion } from 'enums/ERegion';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IAdministrationAssessorPanelListFormDto,
  IAdministrationAssessorPanelListGridItem,
} from 'types/administration/AdministrationAssessorPanelListTypes';

export const administrationAssessorPanelListGridData: IPaginationResponse<IAdministrationAssessorPanelListGridItem> =
  {
    results: [
      {
        id: 1,
        emailAddress: 'test@test1.com',
        name: 'Test Company Name 1',
        regions:
          'South West, North East, London, North West, Yorkshire and the Humber, South East, East Midlands, West Midlands',
        telephone: '00000000000',
      },
      {
        id: 2,
        emailAddress: 'test@test2.com',
        name: 'Test Company Name 2',
        regions: 'All Regions',
        telephone: '10000000000',
      },
    ],
    count: 2,
  };

export const administrationAssessorPanelListFormData: IAdministrationAssessorPanelListFormDto =
  {
    id: 1,
    emailAddress: 'test@test1.com',
    name: 'Test Company Name 1',
    regions: [ERegion.EastMidlands, ERegion.NorthWest, ERegion.London],
    telephone: '00000000000',
  };
