import { v4 as uuidv4 } from 'uuid';
import { DataTableResponse } from 'common/types/DataTableResponse';
import {
  ECommunicationCategory,
  ECommunicationType,
  EGovNotificationStatusType,
  IApplicationCommunication,
  IApplicationCommunicationCategories,
} from 'types/applications/ApplicationCommunicationTypes';

export const applicationCommunicationCategoriesData: Array<IApplicationCommunicationCategories> =
  [
    {
      id: '1',
      name: 'Apply For Grant',
    },
    {
      id: '2',
      name: 'Grant Funding Agreement',
    },
    {
      id: '3',
      name: 'Works Package',
    },
    {
      id: '4',
      name: 'Works Started',
    },
    {
      id: '5',
      name: 'Works Complete',
    },
    {
      id: '6',
      name: 'Building Complete',
    },
    {
      id: '7',
      name: 'Closed',
    },
  ];

export const applicationCommunicationsData: DataTableResponse<IApplicationCommunication> =
  {
    results: [
      {
        communicationId: uuidv4(),
        recipientName: 'Homer Simpson',
        sentByName: 'Homer Simpson',
        addedOnDateTime: '2023-03-02 10:00:00',
        sentOnDateTime: '2023-03-02 10:00:00',
        communicationTypeId: ECommunicationType.Email,
        isAutomated: true,
        isInbound: true,
        notes: "Communication 1",
        govNotifyNotificationStatusType: EGovNotificationStatusType.Created,
        category: ECommunicationCategory.WorksPackage,
      },
      {
        communicationId: uuidv4(),
        recipientName: 'Marge Simpson',
        sentByName: 'Marge Simpson',
        addedOnDateTime: '2024-03-02 10:00:00',
        sentOnDateTime: '2024-03-02 10:00:00',
        communicationTypeId: ECommunicationType.Email,
        isAutomated: true,
        isInbound: false,
        notes: "Communication 2",
        govNotifyNotificationStatusType: EGovNotificationStatusType.Sending,
        category: ECommunicationCategory.WorksPackage,
      },
      {
        communicationId: uuidv4(),
        recipientName: 'List Simpson',
        sentByName: 'List Simpson',
        addedOnDateTime: '2024-04-29 10:00:00',
        sentOnDateTime: '2024-04-29 10:00:00',
        communicationTypeId: ECommunicationType.Letter,
        isAutomated: true,
        isInbound: false,
        notes: "Communication 3",
        govNotifyNotificationStatusType: EGovNotificationStatusType.Sending,
        category: ECommunicationCategory.WorksPackage,
      },
      {
        communicationId: uuidv4(),
        recipientName: 'Bart Simpson',
        sentByName: 'Bart Simpson',
        addedOnDateTime: '2024-03-29 10:00:00',
        sentOnDateTime: '2024-03-29 10:00:00',
        communicationTypeId: ECommunicationType.Note,
        isAutomated: false,
        isInbound: false,
        notes: "Communication 4",
        govNotifyNotificationStatusType: undefined,
        category: ECommunicationCategory.ApplyForGrant,
      },
      {
        communicationId: uuidv4(),
        recipientName: 'Maggie Simpson',
        sentByName: 'Maggie Simpson',
        addedOnDateTime: '2024-03-29 13:00:00',
        sentOnDateTime: '2024-03-29 15:00:00',
        communicationTypeId: ECommunicationType.Phone,
        isAutomated: false,
        isInbound: false,
        notes: "Communication 5",
        govNotifyNotificationStatusType: undefined,
        category: ECommunicationCategory.GrantFundingAgreement,
      },
    ],
    count: 5,
  };