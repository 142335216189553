import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { differenceInDays, parseISO } from 'date-fns';
import { useGetPaymentRequestOverviewQuery } from 'api/application/paymentRequestApi';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import {
  ApplicationItemGroupNavigator,
  ColumnContainer,
} from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { PaymentRequestSectionOutlet } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/PaymentRequestSectionOutlet';
import {
  PaymentRequestContextProvider,
  usePaymentRequestContext,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/context';
import {
  PaymentRequestReferralsSection,
  PaymentRequestReferralsSidebar,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/sections/Referrals';
import {
  EPaymentRecommendationStatus,
  EPaymentRequestSection,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import { formatDateDayShortMonthYear } from 'util/DateUtils';

const getDateString = (date: string) => {
  const daysAgo = differenceInDays(new Date(), parseISO(date));

  return `${formatDateDayShortMonthYear(parseISO(date))} (${daysAgo} ${
    daysAgo === 1 ? 'day' : 'days'
  } ago)`;
};

const getChipLabel = (status: number): string => {
  if (status === EPaymentRecommendationStatus.Todo) {
    return 'To Do';
  } else if (status === EPaymentRecommendationStatus.InProgress) {
    return 'In Progress';
  } else if (status === EPaymentRecommendationStatus.Submitted) {
    return 'Submitted';
  } else if (status === EPaymentRecommendationStatus.Sent) {
    return 'Sent';
  } else if (status === EPaymentRecommendationStatus.Approved) {
    return 'Approved ';
  } else if (status === EPaymentRecommendationStatus.Expired) {
    return 'Expired';
  } else if (status === EPaymentRecommendationStatus.Rejected) {
    return 'Rejected';
  } else if (status === EPaymentRecommendationStatus.Missed) {
    return 'Missed';
  } else if (status === EPaymentRecommendationStatus.Paid) {
    return 'Paid';
  }
  return 'Unknown Status';
};

const useCategories = () => {
  return [
    {
      categoryTitle: null,
      detailItems: [
        {
          id: EPaymentRequestSection.Overview,
          sectionName: 'Overview',
        },
        {
          id: EPaymentRequestSection.PaymentRequest,
          sectionName: 'Payment request',
        },
        {
          id: EPaymentRequestSection.VariationChecks,
          sectionName: 'Variation checks',
        },
        {
          id: EPaymentRequestSection.ProjectTeam,
          sectionName: 'Project team',
        },
      ],
    },
    {
      categoryTitle: 'Confirm payment request',
      detailItems: [
        {
          id: EPaymentRequestSection.PaymentRequestChecks,
          sectionName: 'Payment request  checks',
        },
      ],
    },
  ];
};

export const PaymentRequestSection = () => {
  return (
    <PaymentRequestContextProvider>
      <PaymentRequestSectionMain />
    </PaymentRequestContextProvider>
  );
};

const PaymentRequestSectionMain = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const { isReferralsSectionShowing, currentStatus } =
    usePaymentRequestContext();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const { data } = useGetPaymentRequestOverviewQuery(request);
  const { setSelectedItem } = useApplicationLiveProjectContext();

  const {
    selectedItemId: selectedPaymentRequestItemId,
    setSelectedItemId: setSelectedPaymentRequestItemId,
  } = usePaymentRequestContext();

  const categories = useCategories();

  const isSectionEnabled = (detailItemId: EPaymentRequestSection) => {
    if (detailItemId === EPaymentRequestSection.Overview) {
      return true;
    }

    if (
      currentStatus !== EPaymentRecommendationStatus.Todo &&
      currentStatus !== EPaymentRecommendationStatus.InProgress &&
      currentStatus !== EPaymentRecommendationStatus.Expired &&
      currentStatus !== EPaymentRecommendationStatus.Missed
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={3.5}>
          <RoundBorderBox sx={{ p: 2, mb: 2 }}>
            <Button
              variant="text"
              onClick={() => setSelectedItem(null)}
              sx={{ pl: 0, mb: 0.5 }}
            >
              {'< Back to Live Project'}
            </Button>
            <CardTitle
              title={`${data?.paymentMonth} payment request`}
              fontSize="1.2em"
            />
            <Chip
              label={
                data?.paymentRecommendation
                  ? getChipLabel(data.paymentRecommendation)
                  : 'Unknown Status'
              }
              sx={{
                mb: 2,
              }}
              color={
                data?.paymentRecommendation ===
                EPaymentRecommendationStatus.Rejected
                  ? 'error'
                  : 'default'
              }
            />
            {data?.submittedDate && (
              <Stack direction="column" gap={2}>
                <Typography fontWeight={600}>Date Submitted</Typography>
                <Typography>{getDateString(data.submittedDate)}</Typography>
              </Stack>
            )}
          </RoundBorderBox>

          <ColumnContainer sx={{ mb: 2 }}>
            <Box p={2}>
              <ApplicationItemGroupNavigator
                overrideSelected={isReferralsSectionShowing}
                itemGroups={categories.map((category, categoryIndex) => {
                  return {
                    title: category.categoryTitle,
                    isEnabled: true,
                    items: category.detailItems.map(
                      (detailItem, detailIndex) => {
                        const sectionEnabled = isSectionEnabled(detailItem.id);
                        return {
                          title: detailItem.sectionName,
                          isSelected:
                            selectedPaymentRequestItemId === detailItem.id,
                          onClick: () => {
                            if (sectionEnabled) {
                              setSelectedPaymentRequestItemId(detailItem.id);
                            }
                          },
                        };
                      }
                    ),
                  };
                })}
              />
            </Box>
          </ColumnContainer>

          <PaymentRequestReferralsSidebar />
        </Grid>
        <Grid item xs={8.5}>
          <ColumnContainer
            sx={{
              border: 'none',
            }}
          >
            <Box display={isReferralsSectionShowing ? 'none' : 'block'}>
              <PaymentRequestSectionOutlet />
            </Box>
            <Box display={isReferralsSectionShowing ? 'block' : 'none'}>
              <PaymentRequestReferralsSection />
            </Box>
          </ColumnContainer>
        </Grid>
      </Grid>
    </>
  );
};
