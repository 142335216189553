import { useAuth } from 'oidc-react';
import { getDecodedAccessToken } from 'util/TokenUtils';

export const useDecodedAccessToken = () => {
  const auth = useAuth();

  const decodedAccessToken = getDecodedAccessToken(
    auth.userData?.access_token ?? null
  );

  return { decodedAccessToken };
};
