export enum EPullInNotEligibleReason {
  SocialSectorScheme = 1,
  HighRiseLondon = 2,
  RAS = 3,
  WorkCommencedPriorJan22 = 4,
  NoWorkRequired = 5,
  SelfFunding = 6,
  NoQualifyingLease = 7,
  HeightRequirementNotMet = 8,
  Other = 9,
}

export const EPullInNotEligibleReasonTypeLanguage = {
  [EPullInNotEligibleReason.SocialSectorScheme]: 'Social Sector Scheme',
  [EPullInNotEligibleReason.HighRiseLondon]: 'High Rise London',
  [EPullInNotEligibleReason.RAS]: 'RAS',
  [EPullInNotEligibleReason.WorkCommencedPriorJan22]:
    'Work Commenced Prior Jan 22',
  [EPullInNotEligibleReason.NoWorkRequired]: 'No Work Required',
  [EPullInNotEligibleReason.SelfFunding]: 'Self Funding',
  [EPullInNotEligibleReason.NoQualifyingLease]: 'No Qualifying Lease',
  [EPullInNotEligibleReason.HeightRequirementNotMet]:
    'Height Requirement Not Met',
  [EPullInNotEligibleReason.Other]: 'Other',
} as const;
