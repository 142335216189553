import {
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { SortDirection } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import { useGetPaginatedBenchmarkFiguresQuery } from 'api/administration/benchmarkFigures';
import { Loading } from 'common/components/loading/Loading';
import { ESortDirection } from 'enums/ESortDirection';
import { useAppDispatch } from 'state';
import { selectAdministrationBenchmarkFiguresGridState } from 'state/selectors/administration/administrationBenchmarkFiguresSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationBenchmarkFiguresReducer';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { IAdministrationBenchmarkFiguresGridItem } from 'types/administration/AdministrationBenchmarkFiguresTypes';
import { getUkDateTimeStringFromJsonDateString } from 'util/AppUtils';

export function AdministrationBenchmarkFiguresHistory() {
  const dispatch = useAppDispatch();

  const administrationBenchmarkFiguresGridState = useSelector(
    selectAdministrationBenchmarkFiguresGridState
  );
  const {
    data: benchmarkFiguresData,
    isLoading: isBenchmarkFiguresGridLoading,
  } = useGetPaginatedBenchmarkFiguresQuery(
    administrationBenchmarkFiguresGridState
  );

  const { skip, take, sortBy, sortDirection } =
    administrationBenchmarkFiguresGridState;

  const currentPage = skip / take;

  const sortHandler = (
    property: keyof IAdministrationBenchmarkFiguresGridItem
  ): void => {
    dispatch(
      gridStateChanged({
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      })
    );
  };

  const getAriaSortDirection = (
    property: keyof IAdministrationBenchmarkFiguresGridItem,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getCurrentSortDirection = (
    property: keyof IAdministrationBenchmarkFiguresGridItem,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderSortTableCell = (
    property: keyof IAdministrationBenchmarkFiguresGridItem,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  return (
    <>
      <Loading isOpen={isBenchmarkFiguresGridLoading} />
      <StyledGenericTable data-testid="Administration-Benchmark-Figures-Table">
        <TableHead>
          <TableRow>
            {getHeaderSortTableCell('description', 'Description')}
            <TableCell>Updated Amount</TableCell>
            <TableCell>Previous Amount</TableCell>
            {getHeaderSortTableCell('dateChanged', 'Date Changed')}
            <TableCell>Changed By</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {benchmarkFiguresData?.results?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.description}</TableCell>
              <TableCell>{`£${row.updatedAmount.toLocaleString()}`}</TableCell>
              <TableCell>{`£${row.previousAmount.toLocaleString()}`}</TableCell>
              <TableCell>
                {getUkDateTimeStringFromJsonDateString(row.dateChanged)}
              </TableCell>
              <TableCell>{row.changedBy}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={benchmarkFiguresData?.count || 0}
              rowsPerPage={take}
              rowsPerPageOptions={[take]}
              page={currentPage}
              onPageChange={(e, newPage) => {
                const newSkip = newPage * take;
                dispatch(gridStateChanged({ skip: newSkip }));
              }}
              onRowsPerPageChange={e => {
                dispatch(
                  gridStateChanged({
                    take: +e.target.value,
                  })
                );
              }}
            />
          </TableRow>
        </TableFooter>
      </StyledGenericTable>
    </>
  );
}
