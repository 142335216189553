import {
  Box,
  Button,
  Grid,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLazyApplicationDetailsFileDownloadQuery } from 'api/application';
import { useGetProgressReportQuery } from 'api/application/projectPrepApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FileDownload } from 'common/components/fileDownload';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useLocalSnackbar } from 'hooks';
import {
  IProjectPrepProgressReport,
  QuestionFileArray,
  QuestionTable,
} from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';
import {
  CardTitle,
  OverviewSummaryItem,
  ProgressReportChip,
} from 'pages/applicationPage/content/projectPrep/common';
import { useApplicationProjectPrepContext } from 'pages/applicationPage/content/projectPrep/context';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import {
  getPrettyDateStringFromJsonDateString,
  getUkDateStringFromJsonDateString,
} from 'util/AppUtils';

export const ReportDetailsSection = () => {
  const { applicationId, selectedItem, setSelectedItem } =
    useApplicationProjectPrepContext();
  const key =
    !applicationId || !selectedItem
      ? skipToken
      : {
          applicationId,
          progressReportId: selectedItem.id,
        };
  const { isSuccess, isError, error, data, isLoading } =
    useGetProgressReportQuery(key);

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={3}>
          <RoundBorderBox sx={{ p: 2, mb: 2 }}>
            <Button
              variant="text"
              onClick={() => setSelectedItem(null)}
              sx={{ pl: 0, mb: 0.5 }}
            >
              {'< Back to Project Set-up'}
            </Button>
            {isSuccess ? (
              <CardTitle title={data.sectionTitle} fontSize="1.2em" />
            ) : null}
          </RoundBorderBox>

          {isLoading ? (
            <RoundBorderBox sx={{ p: 2 }}>
              <FormSkeleton />
            </RoundBorderBox>
          ) : null}

          {isSuccess ? (
            <RoundBorderBox sx={{ p: 2 }}>
              <Stack gap={2}>
                <OverviewSummaryItem
                  title="Date Opened"
                  value={getPrettyDateStringFromJsonDateString(data.dateOpened)}
                />
                <OverviewSummaryItem
                  title="Date Due"
                  value={getPrettyDateStringFromJsonDateString(data.dateDue)}
                />
                <OverviewSummaryItem
                  title="Status"
                  value={<ProgressReportChip status={data.status} />}
                />
              </Stack>
            </RoundBorderBox>
          ) : null}
        </Grid>
        <Grid item xs={9}>
          {isLoading ? <FormSkeleton /> : null}
          <AlertForQueryErrorOrNull isError={isError} error={error} />
          {isSuccess ? (
            <ReportBody
              subTitle={data.sectionSubTitle}
              questions={data.questions}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

const ReportBody = ({
  subTitle,
  questions,
}: {
  subTitle: string;
  questions: IProjectPrepProgressReport['questions'];
}) => {
  return (
    <RoundBorderBox>
      <Box
        borderBottom={'1px solid'}
        borderColor={'grey.400'}
        bgcolor={'grey.100'}
        p={3}
      >
        <CardTitle title={subTitle ?? 'Report Details'} fontSize="1.2em" />
      </Box>
      <Box p={3}>
        {questions.map((q, index) => {
          const isTable = q.type === 'table';
          const isFileArray = q.type === 'file-array';
          const isTableOrFileArray = isTable || isFileArray;

          return (
            <>
              {q.title.includes('support') &&
                !questions[index - 1].title.includes('support') &&
                index !== 0 && (
                  <Typography variant="body1" fontWeight={600} marginTop={3}>
                    Support
                  </Typography>
                )}
              <Box
                key={q.title}
                borderBottom={'1px solid'}
                borderColor={'grey.400'}
              >
                <Grid container py={3}>
                  <Grid item xs={isTableOrFileArray ? 12 : 8}>
                    <Typography variant="body1" fontWeight={600}>
                      {q.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={isTableOrFileArray ? 12 : 4}>
                    {q.type === 'string' ? q.value : null}

                    {q.type === 'string-array' ? q.value.join('; ') : null}

                    {q.type === 'date'
                      ? getUkDateStringFromJsonDateString(q.value)
                      : null}

                    {q.type === 'boolean' ? (q.value ? 'Yes' : 'No') : null}

                    {isFileArray ? <FileArrayValue value={q.value} /> : null}

                    {isTable ? <TableValue config={q.value} /> : null}
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        })}
      </Box>
    </RoundBorderBox>
  );
};

const FileArrayValue = ({ value }: { value: QuestionFileArray['value'] }) => {
  return (
    <>
      {value.map((file, i) => (
        <Box key={i}>
          <FileDownloadWrapper
            fileName={file.fileName}
            fileUri={file.fileUri}
          />
        </Box>
      ))}
    </>
  );
};

const FileDownloadWrapper = ({
  fileName,
  fileUri,
}: {
  fileName: string;
  fileUri: string;
}) => {
  const { applicationId } = useApplicationProjectPrepContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const [trigger, result] = useLazyApplicationDetailsFileDownloadQuery();

  const handleClick = () => {
    const [fileId] = fileUri.split('/').slice(-1);

    if (!applicationId || !fileId) {
      createErrorSnackbar(
        'Could not get file. applicationId or fileId is empty'
      );
      return;
    }

    trigger({ applicationId, fileId, fileName });
  };

  return (
    <FileDownload
      isError={result.isError}
      error={result.error}
      isLoading={result.isLoading}
      displayValue={fileName}
      handleClick={handleClick}
    />
  );
};

const TableValue = ({ config }: { config: QuestionTable['value'] }) => {
  return (
    <StyledGenericTable>
      <TableHead>
        <TableRow>
          {config.columnHeaders.map(th => (
            <TableCell key={th}>{th}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {config.rows.map((row, i) => (
          <TableRow key={i}>
            {row.map(td => (
              <TableCell key={td}>{td}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </StyledGenericTable>
  );
};
