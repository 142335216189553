import {
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
  Theme,
} from "@mui/material";

export const muiAvatarTheme: {
  defaultProps?: ComponentsProps["MuiAvatar"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiAvatar"];
  variants?: ComponentsVariants["MuiAvatar"];
} = {
  styleOverrides: {
    circular: {
      background: "white",
      color: "black",
      fontSize: "1rem",
    },
  },
};
