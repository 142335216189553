export enum EPermissionGroup {
  Tasklist = 1,
  PreApplications = 2,
  Applications = 3,
  Applicants = 4,
  Reporting = 5,
  Admin = 6,
  HelpCentre = 7,
  EligibilityChecks = 8,
  GrantFunding = 9,
  Signatory = 10,
  Documents = 11,
  PaymentRecommend = 12,
  PaymentRelease = 13,
  WorkPackage = 14,
  PullInProcess = 15,
}

export const EPermissionGroupLanguage: {
  [key in EPermissionGroup]: string;
} = {
  [EPermissionGroup.Tasklist]: 'Tasklist',
  [EPermissionGroup.PreApplications]: 'Pre-Applications',
  [EPermissionGroup.Applications]: 'Applications',
  [EPermissionGroup.Applicants]: 'Applicants',
  [EPermissionGroup.Reporting]: 'Reporting',
  [EPermissionGroup.Admin]: 'Admin',
  [EPermissionGroup.HelpCentre]: 'Help Centre',
  [EPermissionGroup.EligibilityChecks]: 'Eligibility Checks',
  [EPermissionGroup.GrantFunding]: 'Grant Funding',
  [EPermissionGroup.Signatory]: 'Signatory',
  [EPermissionGroup.Documents]: 'Documents',
  [EPermissionGroup.PaymentRecommend]: 'Payment Recommend',
  [EPermissionGroup.PaymentRelease]: 'Payment Release',
  [EPermissionGroup.WorkPackage]: 'Work Package',
  [EPermissionGroup.PullInProcess]: 'Pull In Process',
} as const;
