import { combineReducers } from '@reduxjs/toolkit';
import {
  administrationAssessorPanelListReducer,
  IAdministrationAssessorPanelListState,
} from 'state/slices/administration/administrationAssessorPanelListReducer';
import { administrationBenchmarkFiguresReducer, IAdministrationBenchmarkFiguresState } from 'state/slices/administration/administrationBenchmarkFiguresReducer';
import {
  administrationPTFSReducer,
  IAdministrationPTFSState,
} from 'state/slices/administration/administrationPTFSReducer';
import {
  administrationRolesReducer,
  IAdministrationRolesState,
} from 'state/slices/administration/administrationRolesReducer';
import {
  administrationSystemNotificationsReducer,
  IAdministrationSystemNotificationsState,
} from 'state/slices/administration/administrationSystemNotificationsReducer';
import {
  administrationUsersReducer,
  IAdministrationUsersState,
} from 'state/slices/administration/administrationUsersReducer';

export interface IAdministrationState {
  users: IAdministrationUsersState;
  roles: IAdministrationRolesState;
  ptfs: IAdministrationPTFSState;
  systemNotifications: IAdministrationSystemNotificationsState;
  assessorPanelList: IAdministrationAssessorPanelListState;
  benchmarkFigures: IAdministrationBenchmarkFiguresState;
}

export const administrationReducer = combineReducers({
  users: administrationUsersReducer,
  roles: administrationRolesReducer,
  ptfs: administrationPTFSReducer,
  systemNotifications: administrationSystemNotificationsReducer,
  assessorPanelList: administrationAssessorPanelListReducer,
  benchmarkFigures: administrationBenchmarkFiguresReducer,
});
