import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ theme }) => ({

}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  textAlign: 'center',
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
  paddingBottom: '25px',
  gap: '10px',
}));
