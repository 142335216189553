import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCurrentUserPermissions } from 'hooks';
import { AdministrationRolesGrid } from 'pages/administrationPage/components/administrationRolesTab/components';

export function AdministrationRolesTab() {
  const navigate = useNavigate();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminRoles = doesUserHaveSinglePermission('admin.roles');

  return (
    <>
      <Grid container>
        {hasAdminRoles && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate(`/admin/addEditRole`)}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
            >
              Add Role
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <AdministrationRolesGrid />
        </Grid>
      </Grid>
    </>
  );
}
