import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, UseFormWatch } from 'react-hook-form';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import { CoreInformationChecksDocumentsPanel } from 'pages/applicationPage/content/applicationEligibility/components/CoreInformationChecks/InfoPanels';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import { IApplicationCoreInformationChecks } from 'types/applications/ApplicationEligibilityTypes';
import { nameof } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationCoreInformationChecks) =>
  nameof<IApplicationCoreInformationChecks>(fieldName);

interface ICoreInformationExistingApplicationsProps {
  readOnly?: boolean;
  watch: UseFormWatch<IApplicationCoreInformationChecks>;
}

export const CoreInformationExistingApplications = ({
  readOnly,
  watch,
}: ICoreInformationExistingApplicationsProps) => {
  const { onReferralQuestion } = useApplicationEligibilityContext();

  const watchHasExistingApplication = watch('hasExistingApplication');

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.CoreInformation,
      question,
      referralType,
      refer
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={9} item>
        <Grid container spacing={1}>
          <Box px={2}>
            <Typography variant="h2" fontWeight={600}>
              Existing Applications
            </Typography>

            <Box p={2}>
              <Box mb={2}>
                <Typography variant="h3" fontWeight={600} fontSize="1em">
                  Are there any existing applications for this building?
                </Typography>
                <Controller
                  defaultValue={''}
                  name={getName('hasExistingApplication')}
                  render={({
                    fieldState,
                    field: { onChange, ...fieldProps },
                  }) => (
                    <FormControl>
                      <ToggleButtonGroup
                        {...fieldProps}
                        onChange={(_, val) => {
                          if (val !== null) {
                            onChange(val);
                          }
                          handleReferralQuestion(
                            EEligibilityQuestion.Core_HasExistingApplication,
                            EReferralType.HomesEngland,
                            val
                          );
                        }}
                        exclusive
                        disabled={readOnly}
                      >
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                      {fieldState.error?.message ? (
                        <FormHelperText error>
                          {fieldState.error?.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />
              </Box>
              <Box mb={2}>
                <Typography variant="h3" fontWeight={600} fontSize="1em">
                  {watchHasExistingApplication === true
                    ? 'Add a note'
                    : 'Add a note (Optional)'}
                </Typography>
                <Controller
                  name={getName('existingApplicationNotes')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      value={field.value ?? ''}
                      fullWidth
                      error={!!fieldState.error}
                      required={watchHasExistingApplication ? true : false}
                      label={
                        watchHasExistingApplication === true
                          ? 'Add a note'
                          : 'Add a note (Optional)'
                      }
                      helperText={fieldState.error?.message}
                      multiline
                      disabled={readOnly}
                    />
                  )}
                />
              </Box>
              {watchHasExistingApplication && (
                <ReferApplicationNotificationBanner
                  question={EEligibilityQuestion.Core_HasExistingApplication}
                  referralType={EReferralType.HomesEngland}
                  disabled={readOnly}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid xs={3} item>
        <CoreInformationChecksDocumentsPanel />
      </Grid>
    </Grid>
  );
};
