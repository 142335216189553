import { Box, Grid, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetClosingPaymentRequestClosingChecksQuery } from 'api/application/paymentRequestApi';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { IFile } from 'common/types/File';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';

export const ClosingChecksSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const { data, isLoading } =
    useGetClosingPaymentRequestClosingChecksQuery(request);

  return (
    <>
      {isLoading ? (
        <FormSkeleton />
      ) : (
        <RoundBorderBox>
          <Box sx={{ padding: 2, borderBottom: '1px solid lightgray' }}>
            <Typography variant="h3" fontWeight={800} fontSize="1.3em">
              Closing checks
            </Typography>
          </Box>
          <Box sx={{ padding: 2 }}>
            <Box
              mb={2}
              sx={{
                width: '100%',
                padding: 2,
                paddingTop: 2,
                borderRadius: 1,
              }}
            >
              <Grid container flexWrap="nowrap" columnGap={2}>
                <Grid item xs={6}>
                  {data?.practicalCompletionCertificateFiles && (
                    <FileDownloadCard
                      title="Evidence of project costs"
                      fileItem={data.projectCostEvidenceFiles}
                    />
                  )}
                  {data?.buildingRegulatoryApprovalFiles && (
                    <FileDownloadCard
                      title="Building regulatory approval"
                      fileItem={data.buildingRegulatoryApprovalFiles}
                    />
                  )}
                  {data?.practicalCompletionCertificateFiles && (
                    <FileDownloadCard
                      title="Practical completion certificate"
                      fileItem={data.practicalCompletionCertificateFiles}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {data?.exitFraewFiles && (
                    <FileDownloadCard
                      title="Exit FRAEW and Summary Letter"
                      fileItem={data.exitFraewFiles}
                    />
                  )}
                  {data?.leaseholderAndResidentEvidenceFiles && (
                    <FileDownloadCard
                      title="Leaseholder and resident evidence"
                      fileItem={data.leaseholderAndResidentEvidenceFiles}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </RoundBorderBox>
      )}
    </>
  );
};

const FileDownloadCard = ({
  title,
  fileItem,
}: {
  title: string;
  fileItem: Array<IFile>;
}) => (
  <Box bgcolor="grey.100" p={2} mb={2}>
    <CardTitle title={title} />
    {fileItem.map(item => (
      <ApplicationFileDownloadWrapper
        key={item.id}
        fileName={item.name}
        fileId={item.id}
        fileWrapperStyle={{
          backgroundColor: '#FAFAFA',
          marginTop: '1rem',
        }}
      />
    ))}
  </Box>
);

const CardTitle = ({ title }: { title: string }) => (
  <Typography fontWeight={600}>{title}</Typography>
);
