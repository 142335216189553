import { Table, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGenericTable = styled(Table)(({ theme }) => ({
  marginBottom: '1.25rem',
  '& tbody tr': {
    boxShadow: 'none',
    '& td': {
      borderTop: '1px solid rgba(200, 200, 200, 0.87)',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  '& tbody td:first-of-type': {
    borderTopLeftRadius: 'unset',
  },
  '& tbody td:last-of-type': {
    borderTopRightRadius: 'unset',
  },
  '& thead': {
    '& th': {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'none',
    },
  },
}));

export const StyledDrawerTable = styled(Table)(({ theme }) => ({
  marginBottom: '1.25rem',
  '& tbody tr': {
    boxShadow: 'none',
    '& td': {
      backgroundColor: theme.palette.grey[100],
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
}));

export const StyledDarkTable = styled(Table)(({ theme }) => ({
  '& tbody tr': {
    boxShadow: 'none',
    '& td': {
      backgroundColor: theme.palette.grey[100],
      paddingTop: '10px',
      paddingBottom: '10px',
      '& .MuiButtonBase-root': {
        background: '#fff',
        boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
        padding: '5px',
        height: '38px',
      },
    },
  },
}));

export const StyledLightTable = styled(Table)(({ theme }) => ({
  '& tbody tr': {
    boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
    '& td': {
      backgroundColor: '#fff',
    },
  },
}));

export const StyledSlimTableRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: 5,
    paddingBottom: 5,
  },
}));
