import { rest } from 'msw';
import {
  applicationClosingPaymentRequestChecksData,
  applicationClosingPaymentRequestChecksDetailData,
  applicationClosingPaymentRequestOverviewData,
  applicationPaymentRequestChecksData,
  applicationPaymentRequestChecksDetailData,
  applicationPaymentRequestCostsData,
  applicationPaymentRequestOverviewData,
  applicationPaymentRequestReferralsData,
  applicationPaymentRequestTeamData,
  applicationPaymentRequestVariationData,
  applicationPaymentRequestClosingChecks,
  referrals,
  singleReferral,
} from 'mocks/data/application/applicationPaymentRequestData';

export const paymentRequestHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/overview`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationPaymentRequestOverviewData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/closing/overview`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationClosingPaymentRequestOverviewData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/thirdpartyreferral/:thirdPartyReferralId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200), ctx.json(singleReferral));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/thirdpartyreferral/:thirdPartyReferralId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/thirdpartyreferrals`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200), ctx.json(referrals));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/applicationclosingchecks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationPaymentRequestClosingChecks)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/checks/details`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationPaymentRequestChecksDetailData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/closing/checks/details`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationClosingPaymentRequestChecksDetailData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/costs`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationPaymentRequestCostsData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/costs`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/projectteam`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationPaymentRequestTeamData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/checks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationPaymentRequestChecksData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/checks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/closing/checks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationClosingPaymentRequestChecksData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/closing/checks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/refer`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/referrals`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json({ results: applicationPaymentRequestReferralsData })
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/refer/:referralId/complete`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/approve`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/reject`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/recommend`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/variation`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationPaymentRequestVariationData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentRequest/:paymentRequestId/unlock`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
