import { IPaginationQuery } from 'types/PaginationQuery';

export interface IApplicationCommunicationContactDetails {
  firstName: string;
  lastName: string;
  emailAddress: string;
  contactNumber: string;
}

export interface IApplicationCommunicationCategories {
  id: string;
  name: string;
}

export interface IApplicationCommunication {
  communicationId: string;
  recipientName: string;
  sentByName?: string;
  addedOnDateTime: string;
  sentOnDateTime?: string;
  communicationTypeId: ECommunicationType;
  isAutomated: boolean;
  isInbound: boolean;
  notes: string;
  govNotifyNotificationStatusType?: EGovNotificationStatusType;
  category: ECommunicationCategory;
  fileId?: string;
  fileName?: string;
}

export interface IAddCommunication {
  applicationId: string;
  communicationTypeId: ECommunicationType;
  isInbound: boolean;
  categoryId: ECommunicationCategory;
  notes: string;
  evidenceFileId: string;
}

export interface IGetApplicationCommunicationsPaginatedQuery
  extends IPaginationQuery {
  applicationId: string;
  categories: string[];
}

export enum ECommunicationType {
  Email = 1,
  Letter = 2,
  Phone = 3,
  Note = 4,
}

export const ECommunicationTypeLanguage: {
  [key in ECommunicationType]: string;
} = {
  [ECommunicationType.Email]: 'Email',
  [ECommunicationType.Letter]: 'Letter',
  [ECommunicationType.Phone]: 'Phone',
  [ECommunicationType.Note]: 'Note',
} as const;

export enum ECommunicationCategory {
  ApplyForGrant = 1,
  GrantFundingAgreement = 2,
  WorksPackage = 3,
  WorksStarted = 4,
  WorksComplete = 5,
  BuildingComplete = 6,
  Closed = 7,
}

export const ECommunicationCategoryLanguage: {
  [key in ECommunicationCategory]: string;
} = {
  [ECommunicationCategory.ApplyForGrant]: 'Apply For Grant',
  [ECommunicationCategory.GrantFundingAgreement]: 'Grant Funding Agreement',
  [ECommunicationCategory.WorksPackage]: 'Works Package',
  [ECommunicationCategory.WorksStarted]: 'Works Started',
  [ECommunicationCategory.WorksComplete]: 'Works Complete',
  [ECommunicationCategory.BuildingComplete]: 'Building Complete',
  [ECommunicationCategory.Closed]: 'Closed',
} as const;

export enum EGovNotificationStatusType {
  Created = 1,
  Sending = 2,
  Delivered = 3,
  PermanentFailure = 4,
  TemporaryFailure = 5,
  TechnicalFailure = 6,
  LetterAccepted = 7,
  LetterReceived = 8,
  LetterCancelled = 9,
  LetterPendingVirusCheck = 10,
  LetterVirusScanFailed = 11,
  LetterValidationFailed = 12,
  LetterTechnicalFailure = 13,
  LetterPermanentFailure = 14,
}

export const EGovNotificationStatusTypeLanguage: {
  [key in EGovNotificationStatusType]: string;
} = {
  [EGovNotificationStatusType.Created]: 'Created',
  [EGovNotificationStatusType.Sending]: 'Sending',
  [EGovNotificationStatusType.Delivered]: 'Delivered',
  [EGovNotificationStatusType.PermanentFailure]: 'Permanent Failure',
  [EGovNotificationStatusType.TemporaryFailure]: 'Temporary Failure',
  [EGovNotificationStatusType.TechnicalFailure]: 'Technical Failure',
  [EGovNotificationStatusType.LetterAccepted]: 'Letter Accepted',
  [EGovNotificationStatusType.LetterReceived]: 'Letter Received',
  [EGovNotificationStatusType.LetterCancelled]: 'Letter Cancelled',
  [EGovNotificationStatusType.LetterPendingVirusCheck]:
    'Letter Pending Virus Check',
  [EGovNotificationStatusType.LetterVirusScanFailed]:
    'Letter Virus Scan Failed',
  [EGovNotificationStatusType.LetterValidationFailed]:
    'Letter Validation Failed',
  [EGovNotificationStatusType.LetterTechnicalFailure]:
    'Letter Technical Failure',
  [EGovNotificationStatusType.LetterPermanentFailure]:
    'Letter Permanent Failure',
} as const;
