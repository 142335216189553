import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { useGetApplicationHoldLatestQuery } from 'api/application/applicationHoldApi';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import {
  EHoldStatus,
  EOnHoldType,
} from 'types/applications/ApplicationHoldTypes';
import { formatDateTimeDayMonthYearHourMins } from 'util/DateUtils';

const titleMap = {
  [EOnHoldType.WorksPackage]: 'Works package',
  [EOnHoldType.ScheduleOfWorks]: 'Schedule of works',
  [EOnHoldType.PrimaryReport]: 'Primary report',
  [EOnHoldType.ClosingReport]: 'Closing report',
  [EOnHoldType.PaymentRequest]: 'Payment request',
  [EOnHoldType.Variation]: 'Variation',
  [EOnHoldType.GrantFundingAgreement]: 'Grant Funding Agreement ',
  [EOnHoldType.Eligibility]: 'Eligibility ',
};

interface IOnHoldBannerProps {
  holdType: EOnHoldType;
  sx?: SxProps;
}

export const OnHoldBanner = ({
  holdType,
  sx,
}: IOnHoldBannerProps) => {
  const { applicationId } = useApplicationContext();
  const query = useGetApplicationHoldLatestQuery(applicationId);

  if (!query.data) {
    return null;
  }

  const { holdStatus } = query.data;
  if (holdStatus !== EHoldStatus.OnHold) {
    return null;
  }

  if (holdStatus === EHoldStatus.OnHold && query.data.holdType !== holdType) {
    return null;
  }

  return (
    <RoundBorderBox sx={{ bgcolor: 'grey.100', mb: 4, p: 3, ...sx }} >
      <Stack direction={'row'} gap={3}>
        <Box>
          <FontAwesomeIcon icon={faTimesCircle} color="darkred" fontSize={20} />
        </Box>
        <Box>
          <CardTitle title={`${titleMap[holdType]} on hold`} />
          <Typography>
            This {titleMap[holdType].toLowerCase()} was put on hold by{' '}
            {query.data.user} at{' '}
            {formatDateTimeDayMonthYearHourMins(parseISO(query.data.date))}.
            Reason: {query.data.reason}{' '}
          </Typography>
        </Box>
      </Stack>
    </RoundBorderBox>
  );
};
