export enum EEligibilityQuestion {
  Core_DevelopersPledge = 1,
  Core_HasExistingApplication = 2,
  Core_HasLeaseholderPersonalDetails = 3,
  Core_HasAcceptableLeaseholderEngagementEvidence = 4,

  FRAEW_DoesBuildingAddressMatchApplication = 5,
  FRAEW_ValidReasonForIncorrectBuildingHeight = 6,
  FRAEW_IsBuildingHeightCorrect = 7,
  FRAEW_CorrectSafetyRiskStatus = 8,
  FRAEW_IsBothFilesOfCorrectType = 9,
  FRAEW_IsFRAEWReportsLinkedToCorrectApplication = 10,
  FRAEW_DoesSummaryDetailsMatchTheApplication = 11,
  FRAEW_DoesRecommendedActionMakeSense = 22,
  FRAEW_SubmittedBuildingHeight = 12,
  FRAEW_BuildingHeight = 13,
  FRAEW_BuildingHeightMatchFRAEWReport = 18,
  FRAEW_AssuranceEvidenceAvailable = 20,

  LandRegistry_IsResidentialLeaseLongTerm = 14,
  LandRegistry_HasNoPendingApplications = 15,
  LandRegistry_IsCorrectResponsibleEntity = 16,
  LandRegistry_HasAbilityToMakeChanges = 17,

  CostCentre_LocalAuthorityCode = 19,

  CompanyOrg_IsActive = 21,
  CompanyOrg_IsInMutualPublicRegister = 23,

  Veriphy_Required = 24,

  Risk_Status = 25,
}

export const EEligibilityQuestionLanguage: {
  [key in EEligibilityQuestion]: string;
} = {
  [EEligibilityQuestion.Core_DevelopersPledge]:
    'Is the developer signed up to a developer remediation contract?',
  [EEligibilityQuestion.Core_HasExistingApplication]:
    'Are there any existing applications for this building?',
  [EEligibilityQuestion.Core_HasLeaseholderPersonalDetails]:
    'Has the applicant uploaded leaseholder personal details?',
  [EEligibilityQuestion.Core_HasAcceptableLeaseholderEngagementEvidence]:
    'Has the applicant provided acceptable evidence of leaseholder engagment?',

  [EEligibilityQuestion.FRAEW_DoesBuildingAddressMatchApplication]:
    'Does the building address match the application?',
  [EEligibilityQuestion.FRAEW_ValidReasonForIncorrectBuildingHeight]:
    'Has the applicant provided a valid reason for incorrect building height?',
  [EEligibilityQuestion.FRAEW_IsBuildingHeightCorrect]:
    '  Have you checked the building height and are satisfied that the height is correct?',

  [EEligibilityQuestion.FRAEW_CorrectSafetyRiskStatus]:
    'Please enter the correct Safety Risk Status from the FRAEW report?',
  [EEligibilityQuestion.FRAEW_IsBothFilesOfCorrectType]:
    'Are both files uploaded and the correct type of document?',
  [EEligibilityQuestion.FRAEW_IsFRAEWReportsLinkedToCorrectApplication]:
    'Are both the FRAEW Report/Summary linked to the correct application number?',
  [EEligibilityQuestion.FRAEW_DoesSummaryDetailsMatchTheApplication]:
    'Do all the details in the summary match the application?',
  [EEligibilityQuestion.FRAEW_DoesRecommendedActionMakeSense]:
    'Does the recommended course of action make sense?',
  [EEligibilityQuestion.FRAEW_SubmittedBuildingHeight]:
    'Building height submitted in the application',
  [EEligibilityQuestion.FRAEW_BuildingHeight]:
    'What is the building height in the FRAEW report?',
  [EEligibilityQuestion.FRAEW_BuildingHeightMatchFRAEWReport]:
    'Check the building height matches the FRAEW report?',
  [EEligibilityQuestion.FRAEW_AssuranceEvidenceAvailable]:
    'Is the assurance evidence available now?',

  [EEligibilityQuestion.LandRegistry_IsResidentialLeaseLongTerm]:
    'Is the residential lease long term (over 21 years)?',
  [EEligibilityQuestion.LandRegistry_HasNoPendingApplications]:
    'Are there any pending applications on the buildings title?',
  [EEligibilityQuestion.LandRegistry_IsCorrectResponsibleEntity]:
    'Is the applicant the correct responsible entity and has a qualifying legal interest in the building?',
  [EEligibilityQuestion.LandRegistry_HasAbilityToMakeChanges]:
    'If the Responsible Entity has the practical and legal ability to make changes to the building?',

  [EEligibilityQuestion.CostCentre_LocalAuthorityCode]:
    'Select local authority code',

  [EEligibilityQuestion.CompanyOrg_IsActive]: 'Is company status Active?',
  [EEligibilityQuestion.CompanyOrg_IsInMutualPublicRegister]: 'Is In Mutual Public Register?',

  [EEligibilityQuestion.Veriphy_Required]: 'Veriphy Required?',

  [EEligibilityQuestion.Risk_Status]: 'Risk Status?',
} as const;
