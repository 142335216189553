import { useEffect } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { parseISO } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import {
  useGetRemediationAdviserDetailsQuery,
  useReferToRemediationAdviserMutation,
} from 'api/application';
import {
  useGetReferralTypesQuery,
  useGetRemediationAdvisersQuery,
} from 'api/support';
import { Loading } from 'common/components/loading';
import { FormSkeleton } from 'common/components/skeletons';
import { useLocalSnackbar } from 'hooks';
import { IReferToRemediationAdviser } from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { useApplicationSupportContext } from 'pages/applicationPage/content/support/context';
import { useReferSupportRequestModal } from 'pages/applicationPage/content/support/sections/SupportTicketSection/common/ReferSupportRequestModal';
import { useGetSupportTicketInfo } from 'pages/applicationPage/content/support/supportHooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';
import { getDateOnlyIsoString } from 'util/DateUtils';

const _formId = 'refer-to-remediation-adviser-form';

const _minDate = new Date(2023, 6, 24);

interface IReferToRemediationAdviserDrawerProps {
  editMode: boolean;
  isShowingRemediationAdviserDrawer: boolean;
  hideRemediationAdviserDrawer: () => void;
}

export const ReferToRemediationAdviserDrawer = ({
  editMode,
  isShowingRemediationAdviserDrawer,
  hideRemediationAdviserDrawer,
}: IReferToRemediationAdviserDrawerProps) => {
  const {
    query: { data: supportTicketInfo },
  } = useGetSupportTicketInfo();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();
  const { applicationId, selectedItemId } = useApplicationSupportContext();

  const { isLoading, data } = useGetRemediationAdviserDetailsQuery({
    applicationId: applicationId,
    supportTicketId: selectedItemId as string,
  });

  const [referToRemediationAdviser] = useReferToRemediationAdviserMutation();

  const form = useForm<IReferToRemediationAdviser>({
    defaultValues: {
      referralTypeId: undefined,
      remediationAdviserId: '',
      remediationAdviserStartDate: '',
      remediationAdviserEndDate: '',
      remediationAdviserCost: '',
    },
  });

  const { handleSubmit, control, reset, watch, setValue } = form;

  const [watchReferralTypeId, watchStartDate] = watch([
    'referralTypeId',
    'remediationAdviserStartDate',
  ]);

  const { renderReferModal, showReferModal } = useReferSupportRequestModal();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const onSubmit = async (values: IReferToRemediationAdviser) => {
    if (!selectedItemId) {
      createErrorSnackbar('Could not get selected support ticket id');
      return;
    }

    if (!editMode) {
      showReferModal({
        config: {
          type: 'referToRemediationAdviser',
          supportTicketId: selectedItemId,
          formData: values,
          onSuccess: () => {
            hideRemediationAdviserDrawer();
            reset();
          },
        },
      });
    } else {
      referToRemediationAdviser({
        supportTicketData: {
          applicationId,
          supportTicketId: selectedItemId,
        },
        formData: values,
      })
        .unwrap()
        .then(() => {
          createSuccessSnackbar(`Support request has been updated`);
          hideRemediationAdviserDrawer();
          reset();
        })
        .catch(err => {
          createErrorSnackbar(
            'Something went wrong updating the support request. Please try again later.'
          );
        });
    }
  };

  const { isLoading: isReferralTypesLoading, data: referralTypes } =
    useGetReferralTypesQuery();
  const referralTypeOptions =
    referralTypes?.map(referralType => {
      return {
        value: referralType.id,
        label: `${referralType.name}`,
      };
    }) ?? [];

  const { data: remediationAdvisers } = useGetRemediationAdvisersQuery(
    typeof watchReferralTypeId === 'number' ? watchReferralTypeId : skipToken
  );
  const remediationAdvisersOptions =
    remediationAdvisers?.map(adviser => {
      return {
        value: adviser.id,
        label: `${adviser.company}`,
      };
    }) ?? [];

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <StyledDrawer anchor="right" open={isShowingRemediationAdviserDrawer}>
        <Box>
          <DialogTitle component="div">
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h1" component="span">
                {supportTicketInfo?.raisedByHomesEngland
                  ? 'Refer to Third Party'
                  : 'Refer to Remediation Adviser'}
              </Typography>
              <IconButton
                onClick={hideRemediationAdviserDrawer}
                aria-label="Close Drawer"
                name="Close Drawer"
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {isReferralTypesLoading ? (
              <Box py={2}>
                <FormSkeleton />
              </Box>
            ) : null}

            <>
              <h3>
                {supportTicketInfo?.raisedByHomesEngland
                  ? 'Third Party Referral Details'
                  : 'Adviser Details'}
              </h3>

              <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name="referralTypeId"
                  rules={{ required: 'Referral Type is required' }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      label="Referral Type"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disabled={editMode}
                      onChange={e => {
                        setValue('referralTypeId', parseInt(e.target.value));
                        setValue('remediationAdviserId', '');
                      }}
                    >
                      {referralTypeOptions.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Controller
                  control={control}
                  name="remediationAdviserId"
                  rules={{ required: 'Remediation Adviser is required' }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      label={
                        supportTicketInfo?.raisedByHomesEngland
                          ? 'Third Party'
                          : 'Remediation Adviser'
                      }
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    >
                      {remediationAdvisersOptions.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="remediationAdviserStartDate"
                      rules={{
                        validate: value => {
                          if (!value) {
                            return 'Start Date is required';
                          }
                          try {
                            const dt = parseISO(value);
                            if (dt < _minDate) {
                              return `Earliest available date is ${getUkDateStringFromJsonDateString(
                                _minDate.toISOString()
                              )}`;
                            }
                          } catch {
                            return 'Error parsing date';
                          }

                          return true;
                        },
                      }}
                      render={({
                        field: { onChange, value, ...field },
                        fieldState,
                      }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              {...field}
                              format="dd/MM/yyyy"
                              label="Start Date"
                              minDate={_minDate}
                              value={
                                value && value !== '' ? parseISO(value) : null
                              }
                              onChange={val => {
                                if (typeof val === 'string') {
                                  return '';
                                }

                                return val
                                  ? onChange(getDateOnlyIsoString(val))
                                  : null;
                              }}
                              slotProps={{
                                textField: {
                                  sx: { mr: '1rem' },
                                  error: !!fieldState.error,
                                  helperText: fieldState.error?.message,
                                  fullWidth: true,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="remediationAdviserEndDate"
                      rules={{
                        validate: value => {
                          if (!value) {
                            return 'End Date is required';
                          }

                          try {
                            const dt = parseISO(value);
                            if (dt < _minDate) {
                              return `Earliest available date is ${getUkDateStringFromJsonDateString(
                                _minDate.toISOString()
                              )}`;
                            }

                            if (watchStartDate) {
                              const startDateDt = parseISO(watchStartDate);
                              if (dt <= startDateDt) {
                                return 'End Date must be later than Start Date';
                              }
                            }
                          } catch {
                            return 'Error parsing date';
                          }

                          return true;
                        },
                      }}
                      render={({
                        field: { onChange, value, ...field },
                        fieldState,
                      }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              {...field}
                              format="dd/MM/yyyy"
                              label="End Date"
                              value={
                                value && value !== '' ? parseISO(value) : null
                              }
                              onChange={val => {
                                if (typeof val === 'string') {
                                  return '';
                                }

                                return val
                                  ? onChange(getDateOnlyIsoString(val))
                                  : null;
                              }}
                              slotProps={{
                                textField: {
                                  sx: { mr: '1rem' },
                                  error: !!fieldState.error,
                                  helperText: fieldState.error?.message,
                                  fullWidth: true,
                                },
                              }}
                              minDate={_minDate}
                            />
                          </LocalizationProvider>
                        );
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="remediationAdviserCost"
                      rules={{
                        min: {
                          value: 0,
                          message: 'Must be greater than zero',
                        },
                        pattern: {
                          value: /^-?\d+(,\d{3})*(\.\d{1,2})?$/,
                          message: 'Please enter a number',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Cost (£)"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            </>
          </DialogContent>
          <StyledDrawerActions>
            <Grid container justifyContent="flex-end" gap={1}>
              <Button variant="outlined" onClick={hideRemediationAdviserDrawer}>
                Cancel
              </Button>

              <Button variant="contained" type="submit" form={_formId}>
                {editMode ? 'Update Request' : 'Refer Request'}
              </Button>
            </Grid>
          </StyledDrawerActions>
        </Box>
      </StyledDrawer>

      {renderReferModal()}
    </>
  );
};
