import { rest } from 'msw';
import {
  vendorPaymentData,
  vendorAccountSetupSummaryData,
  paymentRecommendationApplicationDetails,
  paymentRecommendationDetails,
  additionalPaymentRecommendationDetails,
} from 'mocks/data/application/applicationPaymentData';

export const paymentHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/payment/summary`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(vendorAccountSetupSummaryData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/payment/vendor`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(vendorPaymentData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/payment/file`,
    async (_, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.delay(2000),
          ctx.status(200),
          ctx.set('Content-Type', 'application/pdf'),
          ctx.body(new Blob(['testing'], { type: 'application/pdf' }))
        );
      }
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/payment/vendor`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(2000), ctx.status(204));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/payment/vendor/approval`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(2000), ctx.status(204));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentrecommendation/applicationdetails`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(paymentRecommendationApplicationDetails)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentrecommendation/details`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(paymentRecommendationDetails));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentrecommendation/additionalpaymentdetails`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(additionalPaymentRecommendationDetails));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/paymentrecommendation/:paymentRecommendationId/evidence`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(2000), ctx.status(204));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/payment/paymentAlreadyMade`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(2000), ctx.status(204));
      }
      return req.passthrough();
    }
  ),
];