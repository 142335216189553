import { ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetPaymentRequestChecksDetailsQuery } from 'api/application/paymentRequestApi';
import { FormSkeleton } from 'common/components/skeletons';
import { ColumnContainer } from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { VarianceCard } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common';
import { getPercentage } from 'util/AppUtils';

export const PaymentRequestChecksSectionInfoPanel = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const query = useGetPaymentRequestChecksDetailsQuery(request ?? skipToken);

  if (query.isError) {
    return <Typography>Failed getting payment checks details.</Typography>;
  }

  const { data, isLoading } = query;

  if (isLoading) {
    return <FormSkeleton />;
  }

  const paymentAmount = data?.recommendedAmount ?? data?.requestedAmount;

  return (
    <Stack>
      <Card title="Payment Details">
        <Box>
          {data?.requestedAmount !== undefined ? (
            <InnerCard title="Requested Amount">
              <Stack spacing={1}>
                <LabelValueContainer>
                  <Typography>{data?.paymentMonth} Payment</Typography>
                  <ValueAndSubLabel
                    value={
                      data?.requestedAmount !== undefined
                        ? `£${data?.requestedAmount.toLocaleString()}`
                        : ' - '
                    }
                    subLabel={
                      data?.requestedAmount !== undefined &&
                      data?.totalGrantFunding !== undefined
                        ? `${getPercentage(
                            data.requestedAmount,
                            data.totalGrantFunding
                          ).toFixed(1)}% of total grant funding`
                        : ''
                    }
                  />
                </LabelValueContainer>
                {data?.additionalRequestedAmount !== undefined &&
                  data.additionalRequestedAmount !== 0 && (
                    <>
                      <LabelValueContainer>
                        <Typography>
                          {data.additionalRequestedAmountTitle}
                        </Typography>
                        <Typography>
                          £{data.additionalRequestedAmount.toLocaleString()}
                        </Typography>
                      </LabelValueContainer>
                      <LabelValueContainer>
                        <Typography fontWeight={700}>Total</Typography>
                        <Typography fontWeight={700}>{`£${(
                          data.requestedAmount + data.additionalRequestedAmount
                        ).toLocaleString()}`}</Typography>
                      </LabelValueContainer>
                    </>
                  )}
              </Stack>
            </InnerCard>
          ) : null}
          {data?.scheduledAmount !== undefined ? (
            <InnerCard title="Scheduled Amount">
              <LabelValueContainer>
                <Typography>{data?.paymentMonth} Payment</Typography>
                <Typography fontWeight="600">
                  £{data?.scheduledAmount.toLocaleString()}
                </Typography>
              </LabelValueContainer>
            </InnerCard>
          ) : null}
          {data?.recommendedAmount !== undefined &&
          data.recommendedAmount !== data?.requestedAmount ? (
            <InnerCard title="Recommended Payment">
              <Stack spacing={1}>
                <LabelValueContainer>
                  <Typography>{data?.paymentMonth} Payment</Typography>
                  <Typography>
                    £{data?.recommendedAmount.toLocaleString()}
                  </Typography>
                </LabelValueContainer>
                {data?.additionalRecommendedAmount !== undefined &&
                  data.additionalRecommendedAmount !== 0 && (
                    <>
                      <LabelValueContainer>
                        <Typography>
                          {data.additionalRecommendedAmountTitle}
                        </Typography>
                        <Typography>
                          £{data.additionalRecommendedAmount.toLocaleString()}
                        </Typography>
                      </LabelValueContainer>
                      <LabelValueContainer>
                        <Typography fontWeight={700}>Total</Typography>
                        <Typography fontWeight={700}>{`£${(
                          data.recommendedAmount +
                          data.additionalRecommendedAmount
                        ).toLocaleString()}`}</Typography>
                      </LabelValueContainer>
                    </>
                  )}
              </Stack>
            </InnerCard>
          ) : null}
          <VarianceCard
            paymentAmount={paymentAmount ?? 0}
            scheduledAmount={data?.scheduledAmount ?? 0}
            title="Variance"
          />
          {data?.buildingName && (
            <InnerCard>
              <LabelValueContainer>
                <Typography>Building Name</Typography>
                <Typography fontWeight="600">{data?.buildingName}</Typography>
              </LabelValueContainer>
            </InnerCard>
          )}
        </Box>
      </Card>
    </Stack>
  );
};

const Card = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <ColumnContainer sx={{ p: 1, rowGap: 2 }}>
      <Typography variant="h2" mb={2}>
        {title}
      </Typography>
      {children}
    </ColumnContainer>
  );
};

const InnerCard = ({
  title,
  children,
}: {
  title?: string;
  children: ReactNode;
}) => {
  return (
    <Box pt={2} p={2} mb={2} bgcolor={'grey.100'}>
      {title && (
        <Typography variant="h3" fontWeight="600">
          {title}
        </Typography>
      )}
      <>{children}</>
    </Box>
  );
};

const ValueAndSubLabel = ({
  value,
  subLabel,
}: {
  value: string;
  subLabel: string;
}) => {
  return (
    <Stack>
      <Typography fontWeight="600" textAlign="right">{value}</Typography>
      <Typography fontSize="0.7em">{subLabel}</Typography>
    </Stack>
  );
};

const LabelValueContainer = ({ children }: { children: ReactNode }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
    {children}
  </Box>
);
