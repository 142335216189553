import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IFile, IFileUpload } from 'common/types/File';
import { customPrepareHeaders } from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
  credentials: 'include',
});

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery,
  endpoints: builder => ({
    uploadFile: builder.mutation<IFile, IFileUpload>({
      query: payload => {
        const data = new FormData();
        data.append('file', payload.file);
        data.append('uploadSection', payload.uploadSection);
        return {
          url: `/file`,
          method: 'POST',
          contentType: 'multipart/form-data',
          body: data,
        };
      },
    }),
  }),
});

export const { useUploadFileMutation } = fileApi;
