import { Button, Typography } from '@mui/material';
import {
  useDeleteDirectorMutation,
  useGetDirectorQuery,
} from 'api/application';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { IDeleteDirector } from 'types/applications/ApplicationEligibilityTypes';

interface IDeleteDirectorDialogProps {
  directorId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function DeleteDirectorDialog({
  directorId,
  onConfirm,
  onCancel,
}: IDeleteDirectorDialogProps) {
  const { applicationId } = useApplicationContext();
  const params = { applicationId, directorId };
  const { data, isLoading } = useGetDirectorQuery(params);
  const [deleteDirector] = useDeleteDirectorMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onDeleteDirector = async () => {
    try {
      let payloadDeleteDirector: IDeleteDirector = {
        id: directorId,
        applicationId: applicationId,
      };

      await deleteDirector(payloadDeleteDirector);
      createSuccessSnackbar('Successfully deleted director');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to delete director`);
    }
  };

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you want to delete this director: ${data?.firstName} ${data?.lastName}?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDeleteDirector}>
          Delete director
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
