import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetSystemNotificationsListQuery } from 'types/administration/AdministrationSystemNotificationTypes';

export interface IAdministrationSystemNotificationsState {
  gridState: IGetSystemNotificationsListQuery;
}

const initialState: IAdministrationSystemNotificationsState = {
  gridState: {
    skip: 0,
    take: 25,
  },
};

export const administrationSystemNotificationsSlice = createSlice({
  name: 'administrationSystemNotificationsSlice',
  initialState,
  reducers: {
    gridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetSystemNotificationsListQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
    userAdministrationSystemNotificationsPage: () => initialState,
  },
});

export const administrationSystemNotificationsReducer =
  administrationSystemNotificationsSlice.reducer;
export const { gridStateChanged, userAdministrationSystemNotificationsPage } =
  administrationSystemNotificationsSlice.actions;
