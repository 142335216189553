import { administrationApi } from 'api/administration';
import {
  IOrganisation,
  IOrganisationUser,
} from 'types/administration/AdministrationOrganisationTypes';

export const organisationEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getOrganisations: builder.query<Array<IOrganisation>, void>({
      query: () => `${process.env.REACT_APP_API_URL}/api/organisation/list`,
    }),
    getOrganisationUsers: builder.query<Array<IOrganisationUser>, number>({
      query: organisationId =>
        `${process.env.REACT_APP_API_URL}/api/organisation/${organisationId}/users`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetOrganisationsQuery, useGetOrganisationUsersQuery } =
  organisationEndpoints;
