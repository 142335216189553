import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateVariationReasonMutation } from 'api/application/variationApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { useLocalSnackbar, useModalState } from 'hooks';
import { IEditVariationReasonForm } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { useApplicationAndVariationIdOrNull } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';

const _formId = 'edit-reason-form';

export const useEditReasonDrawer = ({
  initialData,
}: {
  initialData: IEditVariationReasonForm;
}) => {
  const queryParams = useApplicationAndVariationIdOrNull();

  const {
    isShowing,
    showModal: showDrawer,
    hideModal: hideDrawer,
  } = useModalState();

  const form = useForm<IEditVariationReasonForm>({
    defaultValues: initialData,
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = form;

  const { createErrorSnackbar, createSuccessSnackbar } = useLocalSnackbar();

  const handleShowDrawer = () => {
    showDrawer();
  };

  const handleCancelClick = () => {
    hideDrawer();
    reset();
  };

  const [updateVariationReason, updateVariationReasonStatus] =
    useUpdateVariationReasonMutation();

  const onSubmit = async (formData: IEditVariationReasonForm) => {
    if (!queryParams) {
      createErrorSnackbar(
        'Could not get variation data. Please try again later.'
      );
      return;
    }

    updateVariationReason({
      ...queryParams,
      formData,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar(
          'The variation reason has been successfully edited'
        );
        hideDrawer();
        reset(formData);
      });
  };

  const renderDrawer = () => {
    if (!isShowing) {
      return null;
    }

    return (
      <>
        <StyledDrawer anchor="right" open={isShowing}>
          <Box>
            <DialogTitle component="div">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h1" component="span">
                  Edit Reason
                </Typography>
                <IconButton
                  onClick={handleCancelClick}
                  aria-label="Close Drawer"
                  name="Close Drawer"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack gap={2} mt={3}>
                  <Controller
                    control={control}
                    name={'reason'}
                    rules={{ required: 'Required' }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={'Reason for variation'}
                        multiline
                        rows={5}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />

                  <AlertForQueryErrorOrNull
                    isError={updateVariationReasonStatus.isError}
                    error={updateVariationReasonStatus.error}
                  />
                </Stack>
              </form>
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={handleCancelClick}>
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  form={_formId}
                  loading={updateVariationReasonStatus.isLoading}
                  disabled={!isDirty}
                >
                  Update
                </LoadingButton>
              </Grid>
            </StyledDrawerActions>
          </Box>
        </StyledDrawer>
      </>
    );
  };

  return {
    showDrawer: handleShowDrawer,
    renderDrawer,
  };
};
