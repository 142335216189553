import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { SortDirection } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import { useGetPaginatedPTFSListQuery } from 'api/administration/ptfs';
import { Loading } from 'common/components/loading/Loading';
import { ESortDirection } from 'enums/ESortDirection';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { EditBenchmarkDrawer } from 'pages/administrationPage/components/administrationPTFSTab/components/editBenchmarkDrawer/EditBenchmarkDrawer';
import { useAppDispatch } from 'state';
import { selectAdministrationPTFSGridState } from 'state/selectors/administration/administrationPTFSSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationPTFSReducer';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { IPTFSGridListItem } from 'types/administration/AdministrationPTFSTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export function AdministrationPTFSGrid() {
  const dispatch = useAppDispatch();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasPtfsUpdate = doesUserHaveSinglePermission('admin.ptfs.update');

  const administrationPTFSGridState = useSelector(
    selectAdministrationPTFSGridState
  );
  const ptfsListQuery = useGetPaginatedPTFSListQuery(
    administrationPTFSGridState
  );

  const { skip, take, sortBy, sortDirection } = administrationPTFSGridState;

  const {
    isShowing: isEditBenchmarkDrawerShowing,
    showModal: showViewEditBenchmarkDrawer,
    hideModal: hideViewEditBenchmarkDrawer,
  } = useModalState();

  const currentPage = skip / take;

  const sortHandler = (property: keyof IPTFSGridListItem): void => {
    dispatch(
      gridStateChanged({
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      })
    );
  };

  const getAriaSortDirection = (
    property: keyof IPTFSGridListItem,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getCurrentSortDirection = (
    property: keyof IPTFSGridListItem,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof IPTFSGridListItem,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  const currentBenchmark = ptfsListQuery?.data?.results
    ? ptfsListQuery?.data?.results[0]
    : null;

  return (
    <>
      {hasPtfsUpdate && (
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              onClick={showViewEditBenchmarkDrawer}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
            >
              Add benchmark figure
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography>Benchmark Figure</Typography>
      </Grid>
      <Loading isOpen={ptfsListQuery.isLoading} />
      <StyledGenericTable data-testid="Administration-Roles-Table">
        <TableHead>
          <TableRow>
            {getHeaderTableCell('amount', 'Amount')}
            {getHeaderTableCell('percentage', 'Benchmark Percent')}
            {getHeaderTableCell('startDate', 'Start Date')}
            {getHeaderTableCell('endDate', 'End Date')}
            {getHeaderTableCell('changeReason', 'Reason')}
            {getHeaderTableCell('changedBy', 'Added by')}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {ptfsListQuery.data?.results?.map(row => (
            <TableRow key={row.id}>
              <TableCell>{`£${row.amount}`}</TableCell>
              <TableCell>{`${row.percentage}%`}</TableCell>
              <TableCell>
                {row.startDate
                  ? getUkDateStringFromJsonDateString(
                      row.startDate.slice(0, -1)
                    )
                  : ''}
              </TableCell>
              <TableCell>
                {row.endDate
                  ? getUkDateStringFromJsonDateString(row.endDate.slice(0, -1))
                  : ''}
              </TableCell>
              <TableCell>{row.changeReason}</TableCell>
              <TableCell>{row.changedBy}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={ptfsListQuery.data?.results?.length || 0}
              rowsPerPage={take}
              rowsPerPageOptions={[take]}
              page={currentPage}
              onPageChange={(e, newPage) => {
                const newSkip = newPage * take;
                dispatch(gridStateChanged({ skip: newSkip }));
              }}
              onRowsPerPageChange={e => {
                dispatch(
                  gridStateChanged({
                    take: +e.target.value,
                  })
                );
              }}
            />
          </TableRow>
        </TableFooter>
      </StyledGenericTable>
      {isEditBenchmarkDrawerShowing && currentBenchmark && (
        <EditBenchmarkDrawer
          currentBenchmark={currentBenchmark}
          onSuccess={() => {
            hideViewEditBenchmarkDrawer();
            ptfsListQuery.refetch();
          }}
          onClose={() => {
            hideViewEditBenchmarkDrawer();
          }}
        />
      )}
    </>
  );
}
