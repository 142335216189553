import { Grid } from '@mui/material';
import { useGetPullInProcessOverviewQuery } from 'api/pullInProcess';
import { EPullInBuildingHeightTypeLanguage } from 'enums/EPullInBuildingHeightType';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { TitleValue } from 'pages/pullInProcessPage/components/titleValue';
import { stringAppendWithValueOrEmpty } from 'util/stringAppendWithValueOrEmpty';

export function OverviewSummaryPanel() {
  const { pullInProcessId } = usePullInProcessContext();
  const { isLoading, data, isSuccess } =
    useGetPullInProcessOverviewQuery(pullInProcessId);
  if (isLoading) return <div>loading data</div>;
  if (!isSuccess) return <div>Couldn't resolve api request.</div>;
  return (
    <Grid
      container
      spacing="1rem"
      sx={{
        width: 'inherit',
      }}
    >
      <Grid item xs={4}>
        <TitleValue
          title="Building address"
          value={`${stringAppendWithValueOrEmpty(
            data.address.buildingNameNumber,
            ','
          )} ${stringAppendWithValueOrEmpty(
            data.address.line1,
            ','
          )} ${stringAppendWithValueOrEmpty(
            data.address.line2,
            ','
          )} ${stringAppendWithValueOrEmpty(
            data.address.townCity,
            ','
          )} ${stringAppendWithValueOrEmpty(
            data.address.country?.name,
            ','
          )} ${stringAppendWithValueOrEmpty(data.address.postCode, '')}`}
        />
      </Grid>
      <Grid item xs={4}>
        <>
          <TitleValue
            title="Local authority"
            value={data.address?.localAuthority ?? ''}
          />
          <TitleValue
            title="Source of information"
            value={data.sourceOfInformation?.name ?? ''}
          />
        </>
      </Grid>
      <Grid item xs={4}>
        <TitleValue
          title="Building height"
          value={EPullInBuildingHeightTypeLanguage[data.buildingHeight]}
        />
      </Grid>
      <Grid item xs={4}>
        <TitleValue
          title="Freeholder"
          value={`${data.freeholder?.firstName ?? ''} ${
            data.freeholder?.surname ?? ''
          }`}
        />
      </Grid>
      <Grid item xs={4}>
        <TitleValue
          title="Managing agent"
          value={`${data.managingAgent?.firstName ?? ''} ${
            data.managingAgent?.surname ?? ''
          }`}
        />
      </Grid>
      <Grid item xs={4}>
        <TitleValue
          title="Responsible Entity"
          value={`${data.responsibleEntity?.firstName ?? ''} ${
            data.responsibleEntity?.surname ?? ''
          }`}
        />
      </Grid>
    </Grid>
  );
}
