import { Box, Divider } from '@mui/material';
import { SectionHeader } from 'common/components/sectionHeader/SectionHeader';
import { ClosingPaymentRequestReferralsGrid } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/sections/Referrals/ClosingPaymentRequestReferralsGrid';

export const ClosingPaymentRequestReferralsSection = () => {
  return (
    <>
      <SectionHeader title="Referrals" />
      <Divider />
      <Box p={2}>
        <ClosingPaymentRequestReferralsGrid />
      </Box>
    </>
  );
};
