export enum EApplicationInternalStatus {
  NotStarted = 1,
  ApplicationStarted = 2,
  InitialApplicationSubmitted = 3,
  FraewInstructed = 4,
  FraewUploaded = 5,
  FraewSubmitted = 6,
  FinalApplicationSubmitted = 7,
  EligibilityInProgress = 8,
  EligibilityInReview = 9,
  EscalatedEligibility = 10,
  ApplicationApproved = 11,
  ApplicationNotEligible = 12,
  ApplicationNotEligiblePostDays = 13,
  GfaAndDotIssued = 14,
  GfaAndDotReturned = 15,
  GfaAndDotSigned = 16,
  GfaInReview = 17,
  EscalatedGfa = 18,
  VendorSetup = 19,
  GfaComplete = 20,
  PreTenderSupportPaid = 21,
  PtfsPaymentEscalated = 22,
  WorksPackageInProgress = 23,
  WorksPackageSubmitted = 24,
  EscalatedWorksPackage = 25,
  WorksPackageApproved = 26,
  WorksPackageRejected = 27,
  OnHoldPrimaryReport = 28,
  PrimaryReportInProgress = 29,
  PrimaryReportDue = 30,
  PrimaryReportOverdue = 31,
  PrimaryReportSubmitted = 32,
  WorksPackageInReview = 33,
  OnHoldWorksPackage = 34,
  ScheduleOfWorksInProgress = 35,
  ScheduleOfWorksSubmitted = 36,
  ScheduleOfWorksInReview = 37,
  OnHoldScheduleOfWorks = 38,
  EscalatedScheduleOfWorks = 39,
  ScheduleOfWorksApproved = 40,
  PaymentRequestInProgress = 41,
  PaymentRequestSubmitted = 42,
  PaymentRequestInReview = 43,
  OnHoldPaymentRequest = 44,
  EscalatedPaymentRequest = 45,
  PaymentRequestApproved = 46,
  VariationInProgress = 47,
  VariationSubmitted = 48,
  VariationInReview = 49,
  OnHoldVariation = 50,
  EscalatedVariation = 51,
  VariationApproved = 52,
  ClosingReportInProgress = 53,
  ClosingReportSubmitted = 54,
  ClosingReportInReview = 55,
  OnHoldClosingReport = 56,
  EscalatedClosingReport = 57,
  ClosingReportApproved = 58,
  PaymentRequestRejected = 59,
  ClosingReportRejected = 60,
  WorksPackageDeedIssued = 61,
  WorksPackageDeedReturned = 62,
  WorksPackageDeedSigned = 63,
  VariationRejected = 64,
  WorksCompleted = 65,
  OnHoldEligibility = 66,
  OnHoldGfa = 67,
  WorksPackageDeedComplete = 68,
  ApplicationClosed = 69,
  ApplicationClosedReferToGla = 70
}

export const EApplicationInternalStatusLanguage: {
  [key in EApplicationInternalStatus]: string;
} = {
  [EApplicationInternalStatus.NotStarted]: '-',
  [EApplicationInternalStatus.ApplicationStarted]: 'Application Started',
  [EApplicationInternalStatus.InitialApplicationSubmitted]: 'Initial Application Submitted',
  [EApplicationInternalStatus.FraewInstructed]: 'FRAEW Instructed',
  [EApplicationInternalStatus.FraewUploaded]: 'FRAEW Uploaded',
  [EApplicationInternalStatus.FraewSubmitted]: 'FRAEW Submitted',
  [EApplicationInternalStatus.FinalApplicationSubmitted]: 'Final Application Submitted',
  [EApplicationInternalStatus.EligibilityInProgress]: 'Eligibility In Progress',
  [EApplicationInternalStatus.EligibilityInReview]: 'Eligibility In Review',
  [EApplicationInternalStatus.EscalatedEligibility]: 'Escalated (Eligibility)',
  [EApplicationInternalStatus.ApplicationApproved]: 'Application Approved',
  [EApplicationInternalStatus.ApplicationNotEligible]: 'Application Not Eligible',
  [EApplicationInternalStatus.ApplicationNotEligiblePostDays]: 'Application Not Eligible - Post 30 days -  Application Closed',
  [EApplicationInternalStatus.GfaAndDotIssued]: 'GFA and DOT Issued',
  [EApplicationInternalStatus.GfaAndDotReturned]: 'GFA and DOT Returned',
  [EApplicationInternalStatus.GfaAndDotSigned]: 'GFA and DOT Signed',
  [EApplicationInternalStatus.GfaInReview]: 'GFA In Review',
  [EApplicationInternalStatus.EscalatedGfa]: 'Escalated (GFA)',
  [EApplicationInternalStatus.VendorSetup]: 'Vendor Setup',
  [EApplicationInternalStatus.GfaComplete]: 'GFA Complete',
  [EApplicationInternalStatus.PreTenderSupportPaid]: 'Pre-Tender Support Paid',
  [EApplicationInternalStatus.PtfsPaymentEscalated]: 'Escalated (Payment)',
  [EApplicationInternalStatus.WorksPackageInProgress]: 'Works Package In Progress',
  [EApplicationInternalStatus.WorksPackageSubmitted]: 'Works Package Submitted',
  [EApplicationInternalStatus.EscalatedWorksPackage]: 'Escalated (Works package)',
  [EApplicationInternalStatus.WorksPackageApproved]: 'Works Package Approved',
  [EApplicationInternalStatus.WorksPackageRejected]: 'Works Package Rejected',
  [EApplicationInternalStatus.OnHoldPrimaryReport]: 'On hold (Primary Report)',
  [EApplicationInternalStatus.PrimaryReportInProgress]: 'Primary Report In Progress',
  [EApplicationInternalStatus.PrimaryReportDue]: 'Primary Report Due',
  [EApplicationInternalStatus.PrimaryReportOverdue]: 'Primary Report Overdue',
  [EApplicationInternalStatus.PrimaryReportSubmitted]: 'Primary Report Submitted',
  [EApplicationInternalStatus.WorksPackageInReview]: 'Works Package In Review',
  [EApplicationInternalStatus.OnHoldWorksPackage]: 'On Hold (Works package)',
  [EApplicationInternalStatus.ScheduleOfWorksInProgress]: 'Schedule of Works In Progress',
  [EApplicationInternalStatus.ScheduleOfWorksSubmitted]: 'Schedule of Works Submitted',
  [EApplicationInternalStatus.ScheduleOfWorksInReview]: 'Schedule of Works In Review',
  [EApplicationInternalStatus.OnHoldScheduleOfWorks]: 'On Hold (Schedule of works)',
  [EApplicationInternalStatus.EscalatedScheduleOfWorks]: 'Escalated (Schedule of works)',
  [EApplicationInternalStatus.ScheduleOfWorksApproved]: 'Schedule of Works Approved',
  [EApplicationInternalStatus.PaymentRequestInProgress]: 'Payment Request In Progress',
  [EApplicationInternalStatus.PaymentRequestSubmitted]: 'Payment Request Submitted',
  [EApplicationInternalStatus.PaymentRequestInReview]: 'Payment Request In Review',
  [EApplicationInternalStatus.OnHoldPaymentRequest]: 'On Hold (Payment Request)',
  [EApplicationInternalStatus.EscalatedPaymentRequest]: '	Escalated (Payment Request)',
  [EApplicationInternalStatus.PaymentRequestApproved]: 'Payment Request Approved',
  [EApplicationInternalStatus.VariationInProgress]: 'Variation in progress',
  [EApplicationInternalStatus.VariationSubmitted]: 'Variation submitted',
  [EApplicationInternalStatus.VariationInReview]: 'Variation in review',
  [EApplicationInternalStatus.OnHoldVariation]: 'On Hold (Variation)',
  [EApplicationInternalStatus.EscalatedVariation]: 'Escalated (Variation)',
  [EApplicationInternalStatus.VariationApproved]: 'Variation Approved',
  [EApplicationInternalStatus.ClosingReportInProgress]: 'Closing Report In Progress',
  [EApplicationInternalStatus.ClosingReportSubmitted]: 'Closing Report Submitted',
  [EApplicationInternalStatus.ClosingReportInReview]: 'Closing Report In Review',
  [EApplicationInternalStatus.OnHoldClosingReport]: 'On Hold (Closing report)',
  [EApplicationInternalStatus.EscalatedClosingReport]: 'Escalated (Closing report)',
  [EApplicationInternalStatus.ClosingReportApproved]: 'Closing Report Approved',
  [EApplicationInternalStatus.PaymentRequestRejected]: 'Payment Request Rejected',
  [EApplicationInternalStatus.ClosingReportRejected]: 'Closing Report Rejected',
  [EApplicationInternalStatus.WorksPackageDeedIssued]: 'Works Package Deed Issued',
  [EApplicationInternalStatus.WorksPackageDeedReturned]: 'Works Package Deed Returned',
  [EApplicationInternalStatus.WorksPackageDeedSigned]: 'Works Package Deed Signed',
  [EApplicationInternalStatus.VariationRejected]: 'Variation Rejected',
  [EApplicationInternalStatus.WorksCompleted]: 'Works Completed',
  [EApplicationInternalStatus.OnHoldEligibility]: 'On Hold (Eligibility)',
  [EApplicationInternalStatus.OnHoldGfa]: 'On Hold (GFA)',
  [EApplicationInternalStatus.WorksPackageDeedComplete]: 'Works Package Deed Complete',
  [EApplicationInternalStatus.ApplicationClosed]: 'Application Closed',
  [EApplicationInternalStatus.ApplicationClosedReferToGla]: 'Application Closed (Refer to GLA)',
} as const;
