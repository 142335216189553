export enum ECheckpointResponses {
  Yes = 1,
  No = 2,
  NA = 3,
  Complex = 4,
  NotSure = 5,

}

export const ECheckpointResponsesLanguage: { [key in ECheckpointResponses]: string } = {
  [ECheckpointResponses.Yes]: 'Yes',
  [ECheckpointResponses.No]: 'No',
  [ECheckpointResponses.NA]: 'Not Applicable',
  [ECheckpointResponses.Complex]: 'Complex Case',
  [ECheckpointResponses.NotSure]: 'Not Sure',
};
