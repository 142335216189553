import { Grid } from '@mui/material';
import { AdministrationAssessorPanelListGrid } from 'pages/administrationPage/components/administrationAssessorPanelListTab/components/AdministrationAssessorPanelListGrid';

export function AdministrationAssessorPanelListTab() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <AdministrationAssessorPanelListGrid />
      </Grid>
    </Grid>
  );
}
