import { Button, TextField } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSetSignatoryStatusMutation } from 'api/application';
import { useLocalSnackbar } from 'hooks/useLocalSnackbar';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { ISignatoryStatus } from 'types/applications/ApplicationSignatoryTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof ISignatoryStatus) =>
  nameof<ISignatoryStatus>(fieldName);

interface IRejectSignatoryDialogProps {
  signatoryId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function RejectSignatoryDialog({
  signatoryId,
  onConfirm,
  onCancel,
}: IRejectSignatoryDialogProps) {
  const { applicationId } = useApplicationContext();
  const [rejectSignatory] = useSetSignatoryStatusMutation();
  const { createSuccessSnackbar, createWarningSnackbar, createErrorSnackbar } =
    useLocalSnackbar();

  const form = useForm<ISignatoryStatus>();
  const { handleSubmit } = form;

  const onSubmit = async (formData: ISignatoryStatus) => {
    let applicationRejectSignatory: ISignatoryStatus = {
      id: signatoryId,
      applicationId: applicationId,
      isPassed: false,
      failureReason: formData.failureReason,
    };

    await rejectSignatory(applicationRejectSignatory)
      .unwrap()
      .then(payload => {
        createSuccessSnackbar('Successfully rejected signatory');
        onConfirm();
      })
      .catch(error => {
        if (error.data.propertyErrors) {
          setServerSideFormErrors(form, error.data);
          createWarningSnackbar(
            'Please correct any form validation errors shown, and then try again.'
          );
        } else {
          createErrorSnackbar(
            `An unexpected error occured while rejecting application: ${error}`
          );
        }
      });
  };

  return (
    <StyledDialog open>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledDialogTitle>Reject Signatory</StyledDialogTitle>
          <StyledDialogContent>
            <Controller
              name={getName('failureReason')}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  required
                  multiline
                  rows={4}
                  label="Reason For Rejection"
                  helperText={fieldState.error?.message}
                  style={{ width: '34rem' }}
                />
              )}
            />
          </StyledDialogContent>
          <StyledDialogActions>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Reject Signatory
            </Button>
          </StyledDialogActions>
        </form>
      </FormProvider>
    </StyledDialog>
  );
}
