import { Dispatch, SetStateAction, useState } from 'react';
import {
  Autocomplete,
  Box,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  useCloseApplicationMutation,
  useGetCloseApplicationReasonsQuery,
} from 'api/application';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ICloseApplicationReasons } from 'types/applications/ApplicationTypes';
import { extractErrorMessages } from 'util/ApiUtils';

interface ICloseApplicationModalProps {
  isShowing: boolean;
  hideModal: () => void;
}

export const CloseApplicationModal = ({
  isShowing,
  hideModal,
}: ICloseApplicationModalProps) => {
  const formId = 'CloseApplicationForm';
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  if (!isShowing) {
    return null;
  }

  return (
    <ConfirmationModal
      isShowing={isShowing}
      confirmButtonText="Close Application"
      cancelButtonText="Cancel"
      onCancel={hideModal}
      dialogActionsSx={{ m: 2 }}
      areButtonsLoading={isSubmitting}
      showActionsDivider
      fullWidth
      maxWidth="md"
      formId={formId}
      confirmButtonType="submit"
    >
      <Box p={3}>
        {isSubmitting ? <LinearProgress /> : null}

        <Typography variant="body1" fontWeight={600} fontSize={'1.2em'}>
          Close application
        </Typography>

        <Typography variant="body1" py={3} color={'grey.700'}>
          Are you sure you want to close this application?
        </Typography>

        <Stack gap={2}>
          <CloseApplicationForm
            formId={formId}
            onClose={hideModal}
            setIsSubmitting={setIsSubmitting}
          />
        </Stack>
      </Box>
    </ConfirmationModal>
  );
};

interface ICloseApplicationFormProps {
  formId: string;
  onClose: () => void;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

export const CloseApplicationForm = ({
  formId,
  onClose,
  setIsSubmitting,
}: ICloseApplicationFormProps) => {
  const { applicationId } = useApplicationContext();

  const { data: closeReasonsData, isLoading: closeReasonsLoading } =
    useGetCloseApplicationReasonsQuery();

  const [closeApplication, closeApplicationStatus] =
    useCloseApplicationMutation();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const form = useForm<{
    reason: ICloseApplicationReasons | null;
    otherReason: string;
  }>({
    defaultValues: {
      reason: undefined,
      otherReason: '',
    },
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const [otherReasonRequired, setOtherReasonRequired] =
    useState<boolean>(false);

  const handleClick = async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      return;
    }

    try {
      setIsSubmitting(true);
      const { reason, otherReason } = form.getValues();
      closeApplication({
        applicationId,
        reasonId: (reason as ICloseApplicationReasons).id,
        otherReason,
      })
        .unwrap()
        .then(_ => {
          createSuccessSnackbar('Application has been closed');
          onClose();
        })
        .catch(error => {
          createErrorSnackbar(extractErrorMessages(error));
        });
    } catch (err) {
      createErrorSnackbar(`Failed to close application`);
    }

    setIsSubmitting(false);
  };

  return closeReasonsLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <form
        id={formId}
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(handleClick)(event);
        }}
      >
        <Controller
          control={control}
          name="reason"
          rules={{ required: 'Required' }}
          defaultValue={null}
          render={({ field: { onChange, ...fieldRest } }) => {
            return (
              <Autocomplete
                options={closeReasonsData ?? []}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(_, value) => {
                  onChange(value);
                  form.setValue('otherReason', '');
                  setOtherReasonRequired(value?.isOther ?? false);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    {...fieldRest}
                    label="Reason (required)"
                    error={!!errors.reason}
                    helperText={errors.reason?.message}
                    multiline
                  />
                )}
              />
            );
          }}
        />
        {otherReasonRequired && (
          <Controller
            control={form.control}
            name="otherReason"
            rules={otherReasonRequired ? { required: 'Required' } : undefined}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                multiline
                fullWidth
                label="Provide reason"
                error={!!fieldState.error}
                rows={3}
                helperText={fieldState.error?.message}
                sx={{ mb: 0 }}
                inputProps={{ maxLength: 1000 }}
              />
            )}
          />
        )}
      </form>

      <AlertForQueryErrorOrNull
        isError={closeApplicationStatus.isError}
        error={closeApplicationStatus.error}
      />
    </>
  );
};
