import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { useGetApplicationQuestionHistoryQuery } from 'api/application/applicationEditApi';
import { EQuestionType } from 'enums/EQuestionType';
import { useApplicationContext } from 'pages/applicationPage/common/context';

interface IApplicationDetailsAnswerEditedIndicatorProps {
  questionType: EQuestionType;
}

export function ApplicationDetailsAnswerEditedIndicator({
  questionType,
}: IApplicationDetailsAnswerEditedIndicatorProps) {
  const { applicationId } = useApplicationContext();

  const { data, isLoading } = useGetApplicationQuestionHistoryQuery({
    applicationId: applicationId,
    questionType,
  });

  return (
    <Box>
      {data !== undefined && !isLoading && data.length > 0 ? (
        <FontAwesomeIcon icon={faUserPen} />
      ) : null}
    </Box>
  );
}
