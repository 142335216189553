export interface ICostsSchedule {
  fraewSurveyAmount?: number;
  fraewSurveyDescription?: string;
  unsafeCladdingRemovalAmount?: number;
  unsafeCladdingRemovalDescription?: string;
  unsafeCladdingTotal: number;
  newCladdingAmount?: number;
  newCladdingDescription?: string;
  externalWorksAmount?: number;
  externalWorksDescription?: string;
  internalWorksAmount?: number;
  internalWorksDescription?: string;
  installationTotal: number;
  mainContractorPreliminariesAmount: number;
  mainContractorPreliminariesDescription: string;
  accessAmount: number;
  accessDescription: string;
  mainContractorOverheadAmount: number;
  mainContractorOverheadDescription: string;
  contractorContingenciesAmount: number;
  contractorContingenciesDescription: string;
  preliminariesTotal: number;
  feasibilityStageAmount: number;
  feasibilityStageDescription: string;
  postTenderStageAmount: number;
  postTenderStageDescription: string;
  propertyManagerAmount: number;
  propertyManagerDescription: string;
  irrecoverableVatAmount: number;
  irrecoverableVatDescription: string;
  otherCostsTotal: number;
  ineligibleAmount?: number;
  ineligibleDescription?: string;
  overallTotal: number;
}

export type CostScheduleAmountProperties = {
  [K in keyof ICostsSchedule as Extract<
    K,
    `${string}Amount`
  >]: ICostsSchedule[K];
};

export type CostScheduleDescriptionProperties = {
  [K in keyof ICostsSchedule as Extract<
    K,
    `${string}Description`
  >]: ICostsSchedule[K];
};

export type CostScheduleTotalProperties = {
  [K in keyof ICostsSchedule as Extract<
    K,
    `${string}Total`
  >]: ICostsSchedule[K];
} & Pick<ICostsSchedule, 'ineligibleAmount'>;

export enum ECostsScheduleSection {
  RemovalOfUnsafeCladding,
  InstallationOfNewCladding,
  Preliminaries,
  OtherCosts,
  InelgibleCosts,
}

export const SectionTitles: { [key in ECostsScheduleSection]: string } = {
  [ECostsScheduleSection.RemovalOfUnsafeCladding]: 'Removal of unsafe cladding',
  [ECostsScheduleSection.InstallationOfNewCladding]:
    'Installation of new cladding to replace unsafe cladding',
  [ECostsScheduleSection.Preliminaries]:
    "Preliminaries, access costs, main contractor's overheads and profit",
  [ECostsScheduleSection.OtherCosts]: 'Other costs',
  [ECostsScheduleSection.InelgibleCosts]: 'Ineligible costs',
} as const;

export const FieldLabels = {
  [ECostsScheduleSection.RemovalOfUnsafeCladding]: {
    removal: 'Removal of unsafe cladding system or mitigation works in lieu',
  },
  [ECostsScheduleSection.InstallationOfNewCladding]: {
    newCladding:
      'New cladding including specification, insulation, and other associated components to be installed',
    external:
      'Any other eligible works to the external wall system recommended in the FRAEW/PAS9980 assessment (optional)',
    internal:
      'Any internal mitigation works recommended in the FRAEW/PAS9980 assessment in lieu of cladding removal (optional)',
  },
  [ECostsScheduleSection.Preliminaries]: {
    mainContractor: `Main contractor's preliminaries`,
    access: 'Access (e.g. scaffolding or mast climber)',
    overheads: `Main contractor's overheads and profit`,
    contingencies: 'Contractor contingencies',
  },
  [ECostsScheduleSection.OtherCosts]: {
    fraew: `FRAEW survey costs`,
    feasibilityFees:
      'Professional Fees - Feasibility Stage - e.g. statutory, planning, legal, building consent and other fees',
    postTenderFees: 'Professional Fees - Post Tender Stage',
    liaisonCosts: 'Property manager/leaseholder liaison costs',
    irrecoverableVatCosts: 'Irrecoverable VAT on works and associated fees',
  },
  [ECostsScheduleSection.InelgibleCosts]: {
    costs: 'Costs',
  },
} as const;

export interface UpdateWorksPackageValuesParams<T> {
  formData: T;
  applicationId: string;
}

export interface IRemovalForm {
  removalOfCladdingAmount: string;
  removalOfCladdingDescription: string;
}

export interface IInstallationForm {
  newCladdingAmount: string;
  newCladdingDescription: string;
  otherEligibleWorkToExternalWallAmount: string;
  otherEligibleWorkToExternalWallDescription: string;
  internalMitigationWorksAmount: string;
  internalMitigationWorksDescription: string;
}

export interface IPreliminariesForm {
  mainContractorPreliminariesAmount: string;
  mainContractorPreliminariesDescription: string;
  accessAmount: string;
  accessDescription: string;
  overheadsAndProfitAmount: string;
  overheadsAndProfitDescription: string;
  contractorContingenciesAmount: string;
  contractorContingenciesDescription: string;
}

export interface IOtherForm {
  fraewSurveyAmount: string;
  fraewSurveyDescription: string;
  feasibilityStageAmount: string;
  feasibilityStageDescription: string;
  postTenderStageAmount: string;
  postTenderStageDescription: string;
  propertyManagerAmount: string;
  propertyManagerDescription: string;
  irrecoverableVatAmount: string;
  irrecoverableVatDescription: string;
}

export interface IIneligibleForm {
  ineligibleAmount: string;
  ineligibleDescription: string;
}

export type CostScheduleAmountFields = Pick<
  IRemovalForm,
  'removalOfCladdingAmount'
> &
  Pick<
    IInstallationForm,
    | 'otherEligibleWorkToExternalWallAmount'
    | 'internalMitigationWorksAmount'
    | 'newCladdingAmount'
  > &
  Pick<
    IPreliminariesForm,
    | 'accessAmount'
    | 'contractorContingenciesAmount'
    | 'overheadsAndProfitAmount'
    | 'mainContractorPreliminariesAmount'
  > &
  Pick<
    IOtherForm,
    | 'feasibilityStageAmount'
    | 'fraewSurveyAmount'
    | 'irrecoverableVatAmount'
    | 'postTenderStageAmount'
    | 'propertyManagerAmount'
  > &
  Pick<IIneligibleForm, 'ineligibleAmount'>;

export type CostScheduleDescriptionFields = Pick<
  IRemovalForm,
  'removalOfCladdingDescription'
> &
  Pick<
    IInstallationForm,
    | 'otherEligibleWorkToExternalWallDescription'
    | 'internalMitigationWorksDescription'
    | 'newCladdingDescription'
  > &
  Pick<
    IPreliminariesForm,
    | 'accessDescription'
    | 'contractorContingenciesDescription'
    | 'overheadsAndProfitDescription'
    | 'mainContractorPreliminariesDescription'
  > &
  Pick<
    IOtherForm,
    | 'feasibilityStageDescription'
    | 'fraewSurveyDescription'
    | 'irrecoverableVatDescription'
    | 'postTenderStageDescription'
    | 'propertyManagerDescription'
  > &
  Pick<IIneligibleForm, 'ineligibleDescription'>;
