import { Box, Grid, Typography } from '@mui/material';

export const ReferApplicationHomesEnglandNotificationBanner = () => {
  return (
    <Box
      sx={{
        padding: 1,
        border: '1px solid lightgray',
        borderRadius: 1,
        borderLeft: '5px solid orange',
      }}
    >
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            Requires referral to Homes England
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body1" fontSize="1em">
            This application needs to be referred for a decision from Homes
            England
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
