import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBaseQuery } from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/profile`;

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: customFetchBaseQuery(baseUrl),
  endpoints: () => ({}),
});
