import { DataTableResponse } from 'common/types/DataTableResponse';
import { ETeam } from 'enums/ETeam';
import { EUserStatus } from 'enums/EUserStatus';
import {
  IUsersGridListItem,
  IUser,
  IUserItem,
} from 'types/administration/AdministrationUsersTypes';

export const administrationUsersData: DataTableResponse<IUsersGridListItem> = {
  results: [
    {
      id: '1',
      firstName: 'Mock Firstname 1',
      lastName: 'Mock Last Name 1',
      emailAddress: 'MockUser1@mock.com',
      organisation: 'Mock Homes England',
      role: 'Mock Admin',
      isBlocked: true,
      lastLogin: new Date().toJSON(),
      status: EUserStatus.Active,
      team: ETeam.DaviesOps,
    },
    {
      id: '2',
      firstName: 'Mock Firstname 2',
      lastName: 'Mock Last Name 2',
      emailAddress: 'MockUser2@mock.com',
      organisation: 'Mock Davies Group',
      role: 'Mock Viewer',
      isBlocked: false,
      lastLogin: new Date().toJSON(),
      status: EUserStatus.Inactive,
      team: ETeam.DaviesOps,
    },
    {
      id: '3',
      firstName: 'Mock Firstname 3',
      lastName: 'Mock Last Name 3',
      emailAddress: 'MockUser3@mock.com',
      organisation: 'Mock Homes England',
      role: 'Mock Editor',
      isBlocked: true,
      lastLogin: new Date().toJSON(),
      status: EUserStatus.Active,
      team: ETeam.DaviesOps,
    },
    {
      id: '4',
      firstName: 'Mock Firstname 4',
      lastName: 'Mock Last Name 4',
      emailAddress: 'MockUser4@mock.com',
      organisation: 'Mock Davies Group',
      role: 'Mock Admin',
      isBlocked: true,
      lastLogin: new Date().toJSON(),
      status: EUserStatus.Inactive,
      team: ETeam.DaviesOps,
    },
    {
      id: '5',
      firstName: 'Mock Firstname5',
      lastName: 'Mock Last Name 5',
      emailAddress: 'MockUser5@mock.com',
      organisation: 'Mock Homes England',
      role: 'Mock Editor',
      isBlocked: true,
      lastLogin: new Date().toJSON(),
      status: EUserStatus.Active,
      team: ETeam.DaviesOps,
    },
  ],
  count: 5,
};

export const userData: IUser = {
  id: '1',
  firstName: 'Mock Joe',
  lastName: 'MockBloggs',
  emailAddress: 'MockJbloggs@aol.com',
  roleId: '1',
  organisationId: 1,
  isBlocked: true,
};

export const userListData: IUserItem[] = [
  { id: '556c270b-b09f-4953-a351-47b8c0efabc0', name: 'Clem Fandango', teamId: 1 },
  { id: '556c270b-b09f-4953-a351-47b8c0efabc1', name: 'Ray Purchase', teamId: 1 },
  { id: '556c270b-b09f-4953-a351-47b8c0efabc2', name: 'Steven Toast', teamId: 2 },
];
