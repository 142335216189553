import { StrictMode } from 'react';
import { render } from 'react-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from 'App';
import { store } from 'state';
import { defaultTheme } from 'styles/defaultTheme';

function RenderRoot() {
  return (
    <StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <BrowserRouter>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </StrictMode>
  );
}

render(<RenderRoot />, document.getElementById('root'));
