import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from 'common/components/pageTitle';
import { SummaryItem } from 'common/components/summaryItem';
import { TPullInProcessSummary } from 'types/pullInProcess/addBuildingRecordTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';
import { stringAppendWithValueOrEmpty } from 'util/stringAppendWithValueOrEmpty';

export const TitleAndSummarySection = ({
  data,
}: {
  data: TPullInProcessSummary;
}) => {
  const navigate = useNavigate();
  return (
    <Grid container justifyContent="space-between" alignItems="center" mb={3}>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton
              size="medium"
              color="inherit"
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                m: 0,
                boxShadow: theme => theme.shadows[3],
              }}
              aria-label="Back to pull in processes"
              name="Back to pull in processes"
            >
              <FontAwesomeIcon icon={faChevronLeft} size="xs" fixedWidth />
            </IconButton>
          </Grid>
          <Grid item ml={2}>
            <PageTitle
              title={`${stringAppendWithValueOrEmpty(
                data.address.buildingNameNumber,
                ','
              )} ${stringAppendWithValueOrEmpty(
                data.address.line1,
                ','
              )} ${stringAppendWithValueOrEmpty(data.address.postCode)}`}
              gridMarginBottom={0}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ ml: { xs: 3, lg: 0 } }}>
        <Grid container spacing={6} columnSpacing={3}>
          <SummaryItem
            label="Date Created"
            value={getUkDateStringFromJsonDateString(data.creationDate)}
          />
          <SummaryItem
            label="Last Communication"
            value={
              data.lastCommunication
                ? getUkDateStringFromJsonDateString(data.lastCommunication)
                : ''
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
