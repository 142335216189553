import { DialogContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import {
  costScheduleEditFormId,
  getAmountString,
} from 'common/components/costSchedule';
import { ICostScheduleFormProps } from 'common/components/costSchedule/forms';
import {
  DescriptionField,
  EligibleCostField,
  FormActions,
  Label,
  OriginalDataAccordion,
  shouldValidateDescription,
} from 'common/components/costSchedule/forms/common';
import { getCostScheduleDescription } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections';
import {
  IOtherForm,
  FieldLabels,
  ECostsScheduleSection,
} from 'types/applications/ApplicationCostScheduleTypes';

export const OtherCostsForm = ({
  parentType,
  data,
  originalData,
  handleCancelClick,
  saveFn,
  saveStatus,
  hasFraewDescription = false,
}: ICostScheduleFormProps<IOtherForm> & { hasFraewDescription?: boolean }) => {
  const array = [data, originalData];
  const form = useForm<IOtherForm>({
    defaultValues: {
      fraewSurveyAmount: getAmountString(data.fraewSurveyAmount),
      fraewSurveyDescription: getCostScheduleDescription(
        array,
        'fraewSurveyDescription'
      ),
      feasibilityStageAmount: getAmountString(data.feasibilityStageAmount),
      feasibilityStageDescription: getCostScheduleDescription(
        array,
        'feasibilityStageDescription'
      ),
      postTenderStageAmount: getAmountString(data.postTenderStageAmount),
      postTenderStageDescription: getCostScheduleDescription(
        array,
        'postTenderStageDescription'
      ),
      propertyManagerAmount: getAmountString(data.propertyManagerAmount),
      propertyManagerDescription: getCostScheduleDescription(
        array,
        'propertyManagerDescription'
      ),
      irrecoverableVatAmount: getAmountString(data.irrecoverableVatAmount),
      irrecoverableVatDescription: getCostScheduleDescription(
        array,
        'irrecoverableVatDescription'
      ),
    },
  });
  const { handleSubmit, watch } = form;

  const [
    fraewSurveyAmount,
    feasibilityStageAmount,
    postTenderStageAmount,
    propertyManagerAmount,
    irrecoverableVatAmount,
  ] = watch([
    'fraewSurveyAmount',
    'feasibilityStageAmount',
    'postTenderStageAmount',
    'propertyManagerAmount',
    'irrecoverableVatAmount',
  ]);

  const onSubmit = (formData: IOtherForm) => {
    saveFn(formData);
  };
  return (
    <>
      <DialogContent>
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
            id={costScheduleEditFormId}
          >
            <Label
              value={FieldLabels[ECostsScheduleSection.OtherCosts].fraew}
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="fraewSurveyAmount"
            />

            <EligibleCostField
              fieldName="fraewSurveyAmount"
              originalAmount={data.fraewSurveyAmount}
              newAmount={fraewSurveyAmount}
              isRequired={false}
            />
            {hasFraewDescription ? (
              <DescriptionField fieldName="fraewSurveyDescription" />
            ) : null}

            <Label
              value={
                FieldLabels[ECostsScheduleSection.OtherCosts].feasibilityFees
              }
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="feasibilityStageAmount"
              descriptionFieldName="feasibilityStageDescription"
            />

            <EligibleCostField
              fieldName="feasibilityStageAmount"
              originalAmount={data.feasibilityStageAmount}
              newAmount={feasibilityStageAmount}
            />

            <DescriptionField
              fieldName="feasibilityStageDescription"
              shouldValidate={shouldValidateDescription(
                parentType,
                data.feasibilityStageAmount,
                feasibilityStageAmount
              )}
            />

            <Label
              value={
                FieldLabels[ECostsScheduleSection.OtherCosts].postTenderFees
              }
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="postTenderStageAmount"
              descriptionFieldName="postTenderStageDescription"
            />

            <EligibleCostField
              fieldName="postTenderStageAmount"
              originalAmount={data.postTenderStageAmount}
              newAmount={postTenderStageAmount}
            />

            <DescriptionField
              fieldName="postTenderStageDescription"
              shouldValidate={shouldValidateDescription(
                parentType,
                data.postTenderStageAmount,
                postTenderStageAmount
              )}
            />

            <Label
              value={FieldLabels[ECostsScheduleSection.OtherCosts].liaisonCosts}
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="propertyManagerAmount"
              descriptionFieldName="propertyManagerDescription"
            />

            <EligibleCostField
              fieldName="propertyManagerAmount"
              originalAmount={data.propertyManagerAmount}
              newAmount={propertyManagerAmount}
            />

            <DescriptionField
              fieldName="propertyManagerDescription"
              shouldValidate={shouldValidateDescription(
                parentType,
                data.propertyManagerAmount,
                propertyManagerAmount
              )}
            />

            <Label
              value={
                FieldLabels[ECostsScheduleSection.OtherCosts]
                  .irrecoverableVatCosts
              }
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="irrecoverableVatAmount"
              descriptionFieldName="irrecoverableVatDescription"
            />

            <EligibleCostField
              fieldName="irrecoverableVatAmount"
              originalAmount={data.irrecoverableVatAmount}
              newAmount={irrecoverableVatAmount}
            />

            <DescriptionField
              fieldName="irrecoverableVatDescription"
              shouldValidate={shouldValidateDescription(
                parentType,
                data.irrecoverableVatAmount,
                irrecoverableVatAmount
              )}
            />
          </form>
        </FormProvider>

        <AlertForQueryErrorOrNull
          isError={saveStatus.isError}
          error={saveStatus.error}
        />
      </DialogContent>

      <FormActions
        originalValue={data.preliminariesTotal ?? 0}
        newValues={[
          fraewSurveyAmount,
          feasibilityStageAmount,
          postTenderStageAmount,
          propertyManagerAmount,
          irrecoverableVatAmount,
        ]}
        isSaving={saveStatus.isLoading}
        handleCancelClick={handleCancelClick}
      />
    </>
  );
};
