import { Grid, Typography } from '@mui/material';

export const PaymentRequestCostsHeaderSection = ({
  paymentRequestTitle = '',
}: {
  paymentRequestTitle: string;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={2}
      wrap="nowrap"
      sx={{
        py: 1,
        pl: 8,
        pr: 2
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em" fontWeight={800}>
          Cost Type
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="subtitle1"
          fontSize="1em"
          fontWeight={800}
          textAlign="right"
        >
          Schedule of works (£)
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="subtitle1"
          fontSize="1em"
          fontWeight={800}
          textAlign="right"
        >
          {`${paymentRequestTitle} payment request (£)`}
        </Typography>
      </Grid>
    </Grid>
  );
};
