import { Button, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  useDeleteUserMutation,
  useGetUserQuery,
} from 'api/administration/users';
import { Loading } from 'common/components/loading/Loading';
import { useLocalSnackbar } from 'hooks';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IDeleteUserDialogProps {
  userId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function DeleteUserDialog({
  userId,
  onConfirm,
  onCancel,
}: IDeleteUserDialogProps) {
  const { data: userData, isLoading } = useGetUserQuery(userId ?? skipToken);
  const [deleteUser] = useDeleteUserMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onDeleteUser = async () => {
    try {
      await deleteUser(userId);
      createSuccessSnackbar('Successfully deleted user');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to delete user`);
    }
  };

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you want to delete this user: ${userData?.firstName} ${userData?.lastName}?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDeleteUser}>
          Delete user
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
