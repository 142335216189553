import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditFraewReportAuthorMutation } from 'api/application/applicationEditApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zFraewReportAuthorSchema } from 'types/applications/ApplicationEdit.zod';
import { IFraewReportAuthor } from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IFraewReportAuthor) =>
  nameof<IFraewReportAuthor>(fieldName);

interface IEditFraewReportAuthorDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
  currentValue: string;
}

export function EditFraewReportAuthorDrawer({
  onClose,
  onSuccess,
  currentValue,
}: IEditFraewReportAuthorDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit FRAEW Report Author
          </Typography>
          <Typography variant="h3">
            {currentValue}
          </Typography>
        </DialogTitle>
        <EditFraewReportAuthorForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditFraewReportAuthorFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewReportAuthorForm = ({
  onClose,
  onSuccess,
}: IEditFraewReportAuthorFormProps) => {
  const { applicationId } = useApplicationContext();

  const [editFraewReportAuthor, editFraewReportAuthorResult] = useEditFraewReportAuthorMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IFraewReportAuthor>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zFraewReportAuthorSchema),
  });
  const { handleSubmit, reset } = form;

  const onSubmit = async (formData: IFraewReportAuthor) => {
    try {
      formData.applicationId = applicationId;
      await editFraewReportAuthor(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`FRAEW report author updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          }
          else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit FRAEW report author`);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name={getName('reportAuthor')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="FRAEW Report Author"
                    helperText={fieldState.error?.message}
                    inputProps={{ maxLength: 150 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={getName('changeReason')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="Comments"
                    helperText={fieldState.error?.message}
                    multiline
                    autoComplete="off"
                    inputProps={{ maxLength: 1000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editFraewReportAuthorResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
