import { administrationApi } from 'api/administration';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IGetRolesListQuery,
  IRoleGridListItem,
  IRole,
  IAddEditRole,
} from 'types/administration/AdministrationRolesTypes';
import { QueryTags } from 'util/ApiUtils';
import { getUrlSearchParamFromQueryData } from 'util/QueryUtils';

const getPaginatedRolesQueryString = (query: IGetRolesListQuery) => {
  const queryParamsStr = getUrlSearchParamFromQueryData({
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  });

  return `/roles?${queryParamsStr.toString()}`;
};

const rolesEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getPaginatedRolesList: builder.query<
      IPaginationResponse<IRoleGridListItem>,
      IGetRolesListQuery
    >({
      providesTags: [QueryTags.Roles],
      query: query => {
        return getPaginatedRolesQueryString(query);
      },
    }),
    getRoles: builder.query<Array<IRole>, void>({
      providesTags: [QueryTags.Roles],
      query: () => `/roleslist`,
    }),
    getRole: builder.query<IAddEditRole, string>({
      providesTags: [QueryTags.Role],
      query: query => `/role/${query}`,
    }),
    addRole: builder.mutation<any, IAddEditRole>({
      invalidatesTags: [QueryTags.Roles],
      query: payload => ({
        url: `/role`,
        method: 'POST',
        body: payload,
      }),
    }),
    editRole: builder.mutation<any, IAddEditRole>({
      invalidatesTags: [QueryTags.Roles],
      query: payload => ({
        url: `/role/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaginatedRolesListQuery,
  useGetRolesQuery,
  useGetRoleQuery,
  useLazyGetRoleQuery,
  useAddRoleMutation,
  useEditRoleMutation,
} = rolesEndpoints;
