import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  Button,
  IconButton,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  useAddNoteMutation,
  useEditNoteMutation,
  useGetNoteQuery,
} from 'api/task';
import { ErrorAlert } from 'common/components/alerts';
import { FormSkeleton } from 'common/components/skeletons';
import { useTasksGridContext } from 'common/components/taskGrid';
import { AddEditTaskNoteForm } from 'common/components/taskNote';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { ECmsTaskStatus, TaskNote } from 'types/tasks/CmsTaskTypes';
import { extractErrorMessages } from 'util/ApiUtils';
import { getDateOnlyIsoString } from 'util/DateUtils';

const _formId = 'addEditTaskForm';

const _defaultNote: TaskNote = {
  assigneeUserId: '',
  notes: '',
  outcomeId: '',
  description: '',
  dueDate: getDateOnlyIsoString(new Date()),
  status: ECmsTaskStatus.NotStarted,
};

export const AddEditTaskNoteDrawer = () => {
  const {
    noteModalState: {
      parentTaskId,
      noteIdToEdit,
      isAddEditNoteDrawerShowing,
      closeNoteDrawer,
    },
  } = useTasksGridContext();

  const param =
    !parentTaskId || !noteIdToEdit
      ? skipToken
      : { taskId: parentTaskId, noteId: noteIdToEdit };
  const { isLoading, isError, error, isSuccess, data } = useGetNoteQuery(param);
  const [addFn, addNoteState] = useAddNoteMutation();
  const [editFn, editNoteState] = useEditNoteMutation();
  const isSaving = addNoteState.isLoading || editNoteState.isLoading;
  const note = isLoading || isError ? null : isSuccess ? data : _defaultNote;

  return (
    <StyledDrawer anchor="right" open={isAddEditNoteDrawerShowing}>
      <Box>
        <DialogTitle component="div">
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h1" component="span">
              {noteIdToEdit ? 'Edit' : 'Add'} Note
            </Typography>
            <IconButton
              onClick={closeNoteDrawer}
              name="Close drawer"
              aria-label="Close drawer"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box py={2}>
            {isLoading ? <FormSkeleton /> : null}

            {isError ? <ErrorAlert msg={extractErrorMessages(error)} /> : null}

            {note !== null ? (
              <AddEditTaskNoteForm
                formId={_formId}
                note={note}
                mutation={
                  noteIdToEdit
                    ? { type: 'Edit', fn: editFn }
                    : { type: 'Add', fn: addFn }
                }
              />
            ) : null}
          </Box>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button variant="outlined" onClick={closeNoteDrawer}>
              Cancel
            </Button>
            {note ? (
              <LoadingButton
                variant="contained"
                type="submit"
                form={_formId}
                loading={isSaving}
              >
                {`${noteIdToEdit ? 'Update' : 'Add'} Note`}
              </LoadingButton>
            ) : null}
          </Grid>
        </StyledDrawerActions>
      </Box>
    </StyledDrawer>
  );
};
