// Sourced from /HE.CMS/appsettings.json BlacklistFileExtensions
export const EXTENSIONS_BLACKLIST = [
  'heic',
  'heif',
  'exe',
  'bin',
  'app',
  'jar',
  'vb',
  'vbx',
  'vbe',
  'sh',
  'dmg',
];

export const handleFileDownloadFromWithinRtkQuery = (
  fileName: string,
  blob: Blob
) => {
  // This is hacky but quick and dirty
  // Needs re-visiting
  const hiddenElement = document.createElement('a');
  const url = window.URL || window.webkitURL;
  const blobFile = url.createObjectURL(blob);
  hiddenElement.href = blobFile;
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName;
  hiddenElement.click();
  return { data: null }; // Important, so that nothing (i.e. contents of file) gets added to the cache for this request
};
