import { applicationApi } from 'api/application/applicationApi';
import {
  IAdditionalPaymentRecommendationDetails,
  IAdditionalPaymentRecommendationUpdate,
  IPaymentAlreadyMade,
  IPaymentFileParams,
  IPaymentRecommendationApplicationDetails,
  IPaymentRecommendationDetails,
  IPaymentRecommendationEvidenceParams,
  IPaymentRecommendationUpdate,
  IPaymentReleaseApproval,
  IPaymentReleaseRejection,
  IUpdateVendorInfoSentStatus,
  IVendorAccountSetupSummary,
  IVendorPaymentDetailsApprovalParams,
  IVendorPaymentMain,
} from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { QueryTags } from 'util/ApiUtils';
import { handleFileDownloadFromWithinRtkQuery } from 'util/FileUtils';

export const paymentEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getVendorAccountSetupSummary: builder.query<
      IVendorAccountSetupSummary,
      string
    >({
      providesTags: [QueryTags.ApplicationVendorDetails],
      query: applicationId => `${applicationId}/payment/summary`,
    }),
    getVendorPaymentDetails: builder.query<IVendorPaymentMain, string>({
      providesTags: [QueryTags.ApplicationVendorDetails],
      query: applicationId => `${applicationId}/payment/vendor`,
    }),
    getVendorPaymentDetailsFile: builder.query<null, IPaymentFileParams>({
      queryFn: async (
        { applicationId, fileName },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `${applicationId}/payment/vendorpdf`,
          responseHandler: response => response.blob(),
        });

        if (result.error) {
          return { error: result.error };
        }

        return handleFileDownloadFromWithinRtkQuery(
          fileName,
          result.data as Blob
        );
      },
    }),
    updateVendorInfoSentStatus: builder.mutation<
      void,
      IUpdateVendorInfoSentStatus
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationVendorDetails,
      ],
      query: ({
        applicationId,
        detailsSentToHomesEngland,
        detailsSentEvidenceFileId,
      }) => {
        return {
          url: `${applicationId}/payment/Vendor`,
          method: 'PUT',
          body: { detailsSentToHomesEngland, detailsSentEvidenceFileId },
        };
      },
    }),
    updateVendorPaymentDetailsApprovalStatus: builder.mutation<
      void,
      IVendorPaymentDetailsApprovalParams
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationVendorDetails,
        QueryTags.ApplicationPaymentRecommendationDetails,
      ],
      query: ({ applicationId, approved, reason, approvalEvidenceFileId }) => {
        return {
          url: `${applicationId}/payment/vendor/approval`,
          method: 'POST',
          body: {
            approved,
            reason,
            approvalEvidenceFileId,
          },
        };
      },
    }),
    getPaymentRecommendationApplicationDetails: builder.query<
      IPaymentRecommendationApplicationDetails,
      string
    >({
      providesTags: [
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: applicationId =>
        `${applicationId}/paymentrecommendation/applicationdetails`,
    }),
    getPaymentRecommendationDetails: builder.query<
      IPaymentRecommendationDetails,
      string
    >({
      providesTags: [
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: applicationId => `${applicationId}/paymentrecommendation/details`,
    }),
    getAdditionalPaymentRecommendationDetails: builder.query<
      IAdditionalPaymentRecommendationDetails,
      string
    >({
      providesTags: [
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: applicationId =>
        `${applicationId}/paymentrecommendation/additionalpaymentdetails`,
    }),
    updatePaymentRecommendationEvidence: builder.mutation<
      void,
      IPaymentRecommendationEvidenceParams
    >({
      invalidatesTags: [QueryTags.ApplicationPaymentRecommendationDetails],
      query: ({ applicationId, paymentRecommendationId, evidenceFileId }) => {
        return {
          url: `${applicationId}/paymentrecommendation/${paymentRecommendationId}/evidence`,
          method: 'PUT',
          body: { evidenceFileId },
        };
      },
    }),
    updatePaymentRecommendation: builder.mutation<
      void,
      IPaymentRecommendationUpdate
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: ({ applicationId }) => {
        return {
          url: `${applicationId}/paymentrecommendation`,
          method: 'POST',
        };
      },
    }),
    updateAdditionalPaymentRecommendation: builder.mutation<
      void,
      IAdditionalPaymentRecommendationUpdate
    >({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: ( payload ) => {
        return {
          url: `${payload.applicationId}/additionalpaymentrecommendation`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    approvePaymentRelease: builder.mutation<void, IPaymentReleaseApproval>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: ({ applicationId, paymentRecommendationId }) => {
        return {
          url: `${applicationId}/paymentrelease/${paymentRecommendationId}/approve`,
          method: 'POST',
        };
      },
    }),
    rejectPaymentRelease: builder.mutation<void, IPaymentReleaseRejection>({
      invalidatesTags: [
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: ({ applicationId, reason, paymentRecommendationId }) => {
        return {
          url: `${applicationId}/paymentrelease/${paymentRecommendationId}/reject`,
          method: 'POST',
          body: {
            reason,
          },
        };
      },
    }),
    editPaymentAlreadyMade: builder.mutation<void, IPaymentAlreadyMade>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationPaymentRecommendationDetails,
        QueryTags.ApplicationPaymentReleaseDetails,
      ],
      query: payload => {
        return {
          url: `${payload.applicationId}/payment/paymentAlreadyMade`,
          method: 'POST',
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useGetVendorAccountSetupSummaryQuery,
  useGetVendorPaymentDetailsQuery,
  useLazyGetVendorPaymentDetailsFileQuery,
  useUpdateVendorInfoSentStatusMutation,
  useUpdateVendorPaymentDetailsApprovalStatusMutation,
  useRejectPaymentReleaseMutation,
  useApprovePaymentReleaseMutation,
  useGetPaymentRecommendationApplicationDetailsQuery,
  useGetPaymentRecommendationDetailsQuery,
  useGetAdditionalPaymentRecommendationDetailsQuery,
  useUpdatePaymentRecommendationEvidenceMutation,
  useUpdatePaymentRecommendationMutation,
  useUpdateAdditionalPaymentRecommendationMutation,
  useEditPaymentAlreadyMadeMutation,
} = paymentEndpoints;
