import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  TableCell,
  Grid,
  Button,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Menu,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetPaginatedAssessorsListQuery } from 'api/administration/assessorPanelList';
import { Loading } from 'common/components/loading';
import { useEllipsisMenu, useModalState } from 'hooks';
import { AdministrationAssessorPanelListDrawer } from 'pages/administrationPage/components/administrationAssessorPanelListTab/components/AdministrationAssessorPanelListDrawer';
import { DeleteAssessorPanelListDialog } from 'pages/administrationPage/components/administrationAssessorPanelListTab/components/DeleteAssessorPanelListDialog';
import { EAdminAssessorPanelListMenuType } from 'pages/administrationPage/components/administrationAssessorPanelListTab/types/EAdminAssessorPanelListMenuType';
import { useAppDispatch } from 'state';
import { selectAdministrationAssessorPanelListGridState } from 'state/selectors/administration/administrationAssessorPanelListSelectors';
import { assessorPanelListStateChanged } from 'state/slices/administration/administrationAssessorPanelListReducer';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { getMenuItemConfig, getMenuItems } from 'util/AppUtils';

export function AdministrationAssessorPanelListGrid() {
  const dispatch = useAppDispatch();

  const administrationAssessorPanelListGridState = useSelector(
    selectAdministrationAssessorPanelListGridState
  );

  const assessorPanelListQuery = useGetPaginatedAssessorsListQuery(
    administrationAssessorPanelListGridState
  );

  const { skip, take } = administrationAssessorPanelListGridState;
  const displayCount = assessorPanelListQuery.data?.count ?? 0;
  const currentPage = skip / take;

  const {
    isShowing: isAssessorDrawerShowing,
    showModal: showAssessorDrawer,
    hideModal: hideAssessorDrawer,
    modalData: selectedAssessorEditId,
  } = useModalState<number | undefined>({ initialModalState: false });

  const {
    isShowing: isDeleteAssessorDialogShowing,
    showModal: showDeleteAssessorDialog,
    hideModal: hideDeleteAssessorDialog,
    modalData: selectedAssessorDeleteId,
  } = useModalState<number | undefined>({ initialModalState: false });

  const {
    ellipsisMenuAnchor,
    handleEllipsisClick,
    handleMenuClose,
    selectedId,
  } = useEllipsisMenu();

  const handleMenuItemClick = (menuType: EAdminAssessorPanelListMenuType) => {
    switch (menuType) {
      case EAdminAssessorPanelListMenuType.EditAssessor:
        showAssessorDrawer(+selectedId);
        break;
      case EAdminAssessorPanelListMenuType.DeleteAssessor:
        showDeleteAssessorDialog(+selectedId);
        break;
      default:
        break;
    }

    handleMenuClose();
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    const newSkip = newPage * take;
    dispatch(assessorPanelListStateChanged({ skip: newSkip }));
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      assessorPanelListStateChanged({
        take: +e.target.value,
      })
    );
  };

  const handleConfirmDeleteAssessor = () => {
    hideDeleteAssessorDialog();
    assessorPanelListQuery.refetch();
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            onClick={() => showAssessorDrawer()}
            startIcon={<FontAwesomeIcon icon={faAdd} />}
          >
            Add Assessor Details
          </Button>
        </Grid>
      </Grid>

      <Loading isOpen={assessorPanelListQuery.isLoading} />
      <StyledGenericTable data-testid="Administration-Roles-Table">
        <TableHead>
          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>Regions Covered</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Telephone Number</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {assessorPanelListQuery.data?.results?.map(assessor => (
            <TableRow key={assessor.id}>
              <TableCell>{assessor.name}</TableCell>
              <TableCell>{assessor.regions}</TableCell>
              <TableCell>{assessor.emailAddress}</TableCell>
              <TableCell>{assessor.telephone}</TableCell>
              <TableCell style={{ width: '5em' }} aria-label="Actions">
                <IconButton
                  aria-label="edit"
                  name="edit"
                  onClick={event =>
                    handleEllipsisClick(assessor.id.toString(), event)
                  }
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
                {selectedId === assessor.id.toString() ? (
                  <Menu
                    id={`assessor-panel-list-menu-${assessor.id}`}
                    anchorEl={ellipsisMenuAnchor}
                    open={Boolean(ellipsisMenuAnchor)}
                    onClose={handleMenuItemClick}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {getMenuItems(
                      [
                        getMenuItemConfig(
                          'Edit',
                          EAdminAssessorPanelListMenuType.EditAssessor
                        ),
                        getMenuItemConfig(
                          'Delete',
                          EAdminAssessorPanelListMenuType.DeleteAssessor
                        ),
                      ],
                      handleMenuItemClick
                    )}
                  </Menu>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={displayCount}
              rowsPerPage={take}
              rowsPerPageOptions={[take]}
              page={currentPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </StyledGenericTable>
      <AdministrationAssessorPanelListDrawer
        isShowing={isAssessorDrawerShowing}
        onClose={hideAssessorDrawer}
        onSuccess={hideAssessorDrawer}
        selectedAssessorId={selectedAssessorEditId}
      />

      {selectedAssessorDeleteId != null ? (
        <DeleteAssessorPanelListDialog
          isShowing={isDeleteAssessorDialogShowing}
          selectedAssessorId={selectedAssessorDeleteId}
          onConfirm={handleConfirmDeleteAssessor}
          onCancel={hideDeleteAssessorDialog}
        />
      ) : null}
    </>
  );
}
