import { rest } from 'msw';
import {
  administrationUsersData,
  userData,
  userListData,
} from 'mocks/data/administration/administrationUsersData';

export const administrationUsersHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/users`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(administrationUsersData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/user/list`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(userListData));
      }
      return res(ctx.status(200), ctx.json(userListData));
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/user/:userId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(userData));
      }
      return res(ctx.status(200), ctx.json(userData));
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/admin/user`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/admin/user`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/admin/user/:userId/block`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/admin/user/:userId/requestUpdatePassword`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.delete(
    `${process.env.REACT_APP_API_URL}/api/admin/user/:userId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
