import { Button, Typography } from '@mui/material';
import { useResetMyPasswordMutation } from 'api/profile/profile';
import { useLocalSnackbar } from 'hooks';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IResetMyPasswordDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function ResetMyPasswordDialog({
  onConfirm,
  onCancel,
}: IResetMyPasswordDialogProps) {
  const [resetMyPassword] = useResetMyPasswordMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onResetMyPassword = async () => {
    try {
      resetMyPassword();
      createSuccessSnackbar('Reset password email has been sent');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to send reset password email`);
    }
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you want to request a reset password email?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onResetMyPassword}>
          Reset Password
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
