import { Box, Button, Grid } from '@mui/material';
import {
  useGetWorksPackageDeedCounterSignedStatusQuery,
  useGetWorksPackageDeedStatusQuery,
  useGetworksPackageDeedSignedStatusQuery,
} from 'api/application/worksPackageDeedApi';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { useLocalSnackbar } from 'hooks';
import {
  ApplicationItemGroupNavigator,
  ColumnContainer,
  SectionProgress,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  WorksPackageDeedContextProvider,
  useWorksPackageDeedContext,
} from 'pages/applicationPage/content//projectPrep/sections/WorksPackageDeedSection/context';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import { useApplicationProjectPrepContext } from 'pages/applicationPage/content/projectPrep/context';
import {
  EWorksPackageDeedSection,
  WorksPackageDeedSectionOutlet,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection';

export const WorksPackageDeedSection = () => {
  return (
    <WorksPackageDeedContextProvider>
      <WorksPackageDeedSectionMain />
    </WorksPackageDeedContextProvider>
  );
};

const useCategories = () => {
  const { applicationId } = useApplicationContext();
  const worksPackageDeedSentStatus =
    useGetWorksPackageDeedStatusQuery(applicationId);
  const worksPackageDeedSignedStatus =
    useGetworksPackageDeedSignedStatusQuery(applicationId);
  const worksPackageDeedCounterSignedStatus =
    useGetWorksPackageDeedCounterSignedStatusQuery(applicationId);
  return [
    {
      categoryTitle: 'Works package deed',
      detailItems: [
        {
          id: EWorksPackageDeedSection.sendWorksPackageDeedDeed,
          sectionName: 'Send works package deed',
          isComplete:
            worksPackageDeedSentStatus.data?.worksPackageDeedSent ?? false,
          isEnabled: true,
        },
        {
          id: EWorksPackageDeedSection.uploadDocuments,
          sectionName: 'Upload signed document',
          isComplete:
            worksPackageDeedSignedStatus.data?.worksPackageDeedSigned ?? false,
          isDisplayed: true,
          isEnabled: worksPackageDeedSentStatus.data?.worksPackageDeedSent,
        },
        {
          id: EWorksPackageDeedSection.uploadCounterSignedDocuments,
          sectionName: 'Upload counter signed deed',
          isComplete:
            worksPackageDeedCounterSignedStatus.data
              ?.workPackageDeedCounterSigned ?? false,
          isDisplayed: true,
          isEnabled: worksPackageDeedSignedStatus.data?.worksPackageDeedSigned,
        },
      ],
    },
  ];
};

export const WorksPackageDeedSectionMain = () => {
  const { setSelectedItem } = useApplicationProjectPrepContext();
  const { selectedItemId: packageId, setSelectedItemId: setPackageId } =
    useWorksPackageDeedContext();
  const categories = useCategories();
  const { createInfoSnackbar } = useLocalSnackbar();
  
  const stepsCompleted = categories.reduce<number>((counter, category) => {
    return (
      counter + category.detailItems.filter(item => item.isComplete).length
    );
  }, 0);

  const isSectionEnabled = (
    category: { categoryTitle?: string; detailItems: any; },
    currentIndex: number
  ) => {
    // if we are handling the first section, then it's always enabled as there are no dependencies
    if (currentIndex === 0) {
      return true;
    }

    // If we are handling a subsequent category, check all sections in the previous category are complete
    // If they are all complete, this category is enabled
    // If they are not all complete, this category is not enabled
    if (category.detailItems[currentIndex - 1].isComplete) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={3}>
          <RoundBorderBox sx={{ p: 2, mb: 2 }}>
            <Button
              variant="text"
              onClick={() => setSelectedItem(null)}
              sx={{ pl: 0, mb: 0.5 }}
            >
              {'< Back to Project Set-up'}
            </Button>

            <CardTitle title={'Works package deed'} fontSize="1.2em" />
          </RoundBorderBox>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <SectionProgress totalSteps={3} currentStep={stepsCompleted} />
          </ColumnContainer>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <Box p={2}>
              <ApplicationItemGroupNavigator
                itemGroups={categories.map((category, categoryIndex) => {
                  return {
                    title: category.categoryTitle,
                    isEnabled: true,
                    items: category.detailItems.map(
                      (detailItem, detailIndex) => {
                        const sectionEnabled = isSectionEnabled(
                          category,
                          detailIndex
                        );
                        return {
                          title: detailItem.sectionName,
                          isSelected: packageId === detailItem.id,
                          isComplete: detailItem.isComplete,
                          onClick: () => {
                            if (sectionEnabled) {
                              setPackageId(detailItem.id);
                            } else {
                              createInfoSnackbar(
                                `You must complete the "${
                                  category.detailItems[detailIndex - 1].sectionName
                                }" section before you can enter this section`,
                                { preventDuplicate: true }
                              );
                            }
                          },
                        };
                      }
                    ),
                  };
                })}
              />
            </Box>
          </ColumnContainer>
        </Grid>
        <Grid item xs={9}>
          <ColumnContainer>
            <WorksPackageDeedSectionOutlet />
          </ColumnContainer>
        </Grid>
      </Grid>
    </>
  );
};
