import { rest } from 'msw';
import {
  roleData,
  rolesGrid,
  rolesList,
} from 'mocks/data/administration/administrationRolesData';

export const administrationRolesServiceHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/roleslist`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(rolesList));
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/roles`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(rolesGrid));
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/role/:roleId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(roleData));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/admin/role`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/admin/role`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
