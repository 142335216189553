import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants,
  Theme
} from "@mui/material";

export const muiSelectTheme: {
  defaultProps?: ComponentsProps["MuiSelect"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiSelect"];
  variants?: ComponentsVariants["MuiSelect"];
} = {
  
};
