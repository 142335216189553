import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  TextField,
  Button,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useEditCostEffectiveCourseOfActionMutation } from 'api/application/applicationEditApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { FieldLabel } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zCostEffectiveCourseOfActionSchema } from 'types/applications/ApplicationEdit.zod';
import { ICostEffectiveCourseOfActionForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditCostEffectiveCourseOfActionDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
  currentValue?: string;
}

export const EditCostEffectiveCourseOfActionDrawer = ({
  onClose,
  onSuccess,
  currentValue = '-',
}: IEditCostEffectiveCourseOfActionDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit most effective course of action summary
          </Typography>
          <Typography variant="h3">{currentValue}</Typography>
        </DialogTitle>
        <EditCostEffectiveCourseOfActionForm
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Box>
    </StyledDrawer>
  );
};

interface IEditCostEffectiveCourseOfActionFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditCostEffectiveCourseOfActionForm = ({
  onClose,
  onSuccess,
}: IEditCostEffectiveCourseOfActionFormProps) => {
  const { applicationId } = useApplicationContext();

  const [
    editCostEffectiveCourseOfAction,
    editCostEffectiveCourseOfActionResult,
  ] = useEditCostEffectiveCourseOfActionMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<ICostEffectiveCourseOfActionForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zCostEffectiveCourseOfActionSchema),
  });
  const { handleSubmit, reset, control } = form;

  const onSubmit = async (formData: ICostEffectiveCourseOfActionForm) => {
    try {
      formData.applicationId = applicationId;
      await editCostEffectiveCourseOfAction(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar(
            `Cost effective course of action updated successfully`
          );
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit cost effective course of action`);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <FieldLabel label="Please summarise the most cost effective course of action from the options listed above that will remediate the life safety risk. This is the option that will be funded through the cladding scheme" />
              <Controller
                control={control}
                defaultValue=""
                name="summary"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editCostEffectiveCourseOfActionResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
