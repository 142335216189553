import { administrationApi } from 'api/administration';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IPTFSGridListItem,
  IViewEditBenchmark,
  IGetPTFSListQuery,
} from 'types/administration/AdministrationPTFSTypes';
import { QueryTags } from 'util/ApiUtils';
import { getUrlSearchParamFromQueryData } from 'util/QueryUtils';

const getPaginatedPTFSQueryString = (query: IGetPTFSListQuery) => {
  const queryParamsStr = getUrlSearchParamFromQueryData({
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  });

  return `/ptfs/benchmarkFigureHistory?${queryParamsStr.toString()}`;
};

const ptfsEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getPaginatedPTFSList: builder.query<
      IPaginationResponse<IPTFSGridListItem>,
      IGetPTFSListQuery
    >({
      providesTags: [QueryTags.PTFS],
      query: query => {
        return getPaginatedPTFSQueryString(query);
      },
    }),
    editBenchmark: builder.mutation<any, IViewEditBenchmark>({
      invalidatesTags: [QueryTags.PTFS],
      query: payload => ({
        url: `/ptfs/benchmarkFigure`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaginatedPTFSListQuery,
  useEditBenchmarkMutation,
} = ptfsEndpoints;
