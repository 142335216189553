import { ReactNode } from 'react';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { ErrorAlert } from 'common/components/alerts';
import { Loading } from 'common/components/loading';
import { EApplicationEligibilitySectionType } from 'pages/applicationPage/content/applicationEligibility/ApplicationEligibilityTypes';
import { CompaniesHouseChecksForm } from 'pages/applicationPage/content/applicationEligibility/components/CompaniesHouseChecks/CompaniesHouseChecksForm';
import { ConfirmEligibilityForm } from 'pages/applicationPage/content/applicationEligibility/components/ConfirmEligibility/ConfirmEligibilityForm';
import { CoreInformationChecksForm } from 'pages/applicationPage/content/applicationEligibility/components/CoreInformationChecks/CoreInformationChecksForm';
import { CostCentreValidationChecksForm } from 'pages/applicationPage/content/applicationEligibility/components/CostCentreValidationChecks/CostCentreValidationChecksForm';
import { EligibilitySectionHeader } from 'pages/applicationPage/content/applicationEligibility/components/EligibilitySectionHeader';
import { FRAEWChecksForm } from 'pages/applicationPage/content/applicationEligibility/components/FRAEWChecks/FRAEWChecksForm';
import { LandRegistryChecksForm } from 'pages/applicationPage/content/applicationEligibility/components/LandRegistryChecks/LandRegistryChecksForm';
import { PEPSanctionsChecksGrid } from 'pages/applicationPage/content/applicationEligibility/components/PEPSanctionsChecks';
import { VeriphyChecksForm } from 'pages/applicationPage/content/applicationEligibility/components/Veriphy/VeriphyForm';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import { getSectionId } from 'pages/applicationPage/content/applicationEligibility/utils';
import { IApplicationEligibilityItem } from 'types/applications/ApplicationEligibilityTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const ApplicationEligibilitySectionOutlet = () => {
  const {
    selectedItemId,
    selectedItem,
    applicationEligibilityCategoriesQuery,
  } = useApplicationEligibilityContext();

  if (!selectedItemId && applicationEligibilityCategoriesQuery.isLoading) {
    return <Loading isOpen />;
  }

  if (!selectedItem) {
    return (
      <ErrorAlert msg={`Could not find item with id: ${selectedItemId}`} />
    );
  }

  return (
    <>
      <Header
        title={selectedItem.sectionName}
        lastUpdated={selectedItem.lastUpdated ?? ''}
        isComplete={selectedItem.isComplete}
      />
      <Divider />
      <Form item={selectedItem} />
    </>
  );
};

const Header = ({
  title,
  lastUpdated,
  isComplete,
}: {
  title: string;
  lastUpdated: string;
  isComplete: boolean;
}) => {
  return (
    <EligibilitySectionHeader
      title={title}
      rightContent={
        <>
          {lastUpdated !== '' && (
            <SubHeaderItem
              label="Last Updated"
              value={getUkDateStringFromJsonDateString(lastUpdated)}
            />
          )}
          <Chip
            label={isComplete ? 'Complete' : 'Not Started'}
            color={isComplete ? 'success' : 'warning'}
            variant={isComplete ? 'filled' : 'outlined'}
          />
        </>
      }
    />
  );
};

const SubHeaderItem = ({
  label,
  value,
}: {
  label: string;
  value: ReactNode;
}) => {
  return (
    <Box display="flex" flexDirection="row" gap={2} alignItems="center">
      <Typography variant="body1" color="grey.800" fontSize="0.9em">
        {label}
      </Typography>

      {typeof value === 'string' ? (
        <Typography variant="body1" fontWeight={600} fontSize="0.9em">
          {value}
        </Typography>
      ) : (
        value
      )}
    </Box>
  );
};

type EligibilityItemConfig = {
  type: EApplicationEligibilitySectionType;
  content: ReactNode;
};
const items: EligibilityItemConfig[] = [
  {
    type: EApplicationEligibilitySectionType.CoreInformationChecks,
    content: <CoreInformationChecksForm />,
  },
  {
    type: EApplicationEligibilitySectionType.CostCentreValidationChecks,
    content: <CostCentreValidationChecksForm />,
  },
  {
    type: EApplicationEligibilitySectionType.FRAEWChecks,
    content: <FRAEWChecksForm />,
  },
  {
    type: EApplicationEligibilitySectionType.Veriphy,
    content: <VeriphyChecksForm />,
  },
  {
    type: EApplicationEligibilitySectionType.CompaniesHouse,
    content: <CompaniesHouseChecksForm />,
  },
  {
    type: EApplicationEligibilitySectionType.PEPSanctions,
    content: <PEPSanctionsChecksGrid />,
  },
  {
    type: EApplicationEligibilitySectionType.LandRegistry,
    content: <LandRegistryChecksForm />,
  },
  {
    type: EApplicationEligibilitySectionType.ApproveOrReject,
    content: <ConfirmEligibilityForm />,
  },
];

const Form = ({ item }: { item: IApplicationEligibilityItem }) => {
  const sectionId = getSectionId(item.sectionName);
  return (
    <>
      {items.map(item => (
        <Box
          key={item.type}
          sx={{ display: sectionId === item.type ? 'block' : 'none' }}
        >
          {item.content}
        </Box>
      ))}
    </>
  );
};
