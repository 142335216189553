import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useEditCostCentreValidationChecksMutation,
  useGetCostCentreValidationChecksQuery,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { CostCentreField } from 'common/components/costCentre/CostCentreField';
import { LeavePageGuard } from 'common/components/leavePageGuard/LeavePageGuard';
import { Loading } from 'common/components/loading';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import { useLocalSnackbar } from 'hooks';
import { CostCentreValidationChecksInfoPanel } from 'pages/applicationPage/content/applicationEligibility/components/CostCentreValidationChecks/CostCentreValidationChecksInfoPanel';
import { ReferApplicationButton } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import {
  IApplicationCostCentreValidationChecks,
  IApplicationEligibilityReferral,
  ICostCentreItem,
} from 'types/applications/ApplicationEligibilityTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { setServerSideFormErrors } from 'util/formUtils';

export const CostCentreValidationChecksForm = () => {
  const { applicationId } = useApplicationEligibilityContext();
  const query = useGetCostCentreValidationChecksQuery(applicationId);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData: IApplicationCostCentreValidationChecks = {
      ...data,
      referrals: data.referrals?.map(r => ({ ...r })) ?? [],
    };

    return <CostCentreValidationChecksFormMain initialData={initialData} />;
  }

  return null;
};

const CostCentreValidationChecksFormMain = ({
  initialData,
}: {
  initialData: IApplicationCostCentreValidationChecks;
}) => {
  const {
    applicationId,
    readOnly,
    onReferralQuestion,
    handleAutoShowReferApplicationDialog,
  } = useApplicationEligibilityContext();
  const { referrals } = useApplicationEligibilityContext();

  const [editCostCentreValidationChecks, editCostCentreValidationChecksStatus] =
    useEditCostCentreValidationChecksMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationCostCentreValidationChecks>({
    defaultValues: initialData,
  });
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty, isSubmitting },
  } = form;

  useEffect(() => {
    referrals
      .filter(item => item.section === EEligibilitySection.CostCentreValidation)
      .forEach((referral: IApplicationEligibilityReferral) => {
        form.setValue(`referrals.${referral.question}.reason`, referral.reason);
      });
  }, [form, referrals]);

  const [costCentreInEngland, setCostCentreInEngland] = useState<boolean>(
    initialData.isInEngland
  );

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Eligibility,
  });

  const getCostCentreValidationReferrals = () => {
    return referrals.filter(
      item => item.section === EEligibilitySection.CostCentreValidation
    );
  };

  const onSubmit = async () => {
    const formData = getValues();
    try {
      formData.id = applicationId;
      formData.referrals = getCostCentreValidationReferrals();
      await editCostCentreValidationChecks(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(
            `Cost Centre Validation Checks updated successfully`
          );
          reset({}, { keepValues: true });
          handleAutoShowReferApplicationDialog();
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit Cost Centre Validation checks`);
    }
  };

  const handleCostCentreChange = (item?: ICostCentreItem) => {
    setCostCentreInEngland(item?.isInEngland ? true : false);

    handleReferralQuestion(
      EEligibilityQuestion.CostCentre_LocalAuthorityCode,
      EReferralType.HomesEngland,
      item?.isInEngland ? false : true
    );
  };

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.CostCentreValidation,
      question,
      referralType,
      refer
    );
  };

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Cost Centre Validation Checks will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.Eligibility} />
        )}

        <form onSubmit={handleSubmit(async () => await onSubmit())}>
          <AlertForQueryErrorOrNull
            isError={editCostCentreValidationChecksStatus.isError}
            error={editCostCentreValidationChecksStatus.error}
          />

          <AlertForChangedData isDirty={isDirty} />

          <Grid container spacing={1}>
            <Grid xs={9} item>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Box p={3}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Select local authority code
                        </Typography>
                        <CostCentreField
                          readOnly={readOnly || isOnHoldForThisType}
                          onChange={handleCostCentreChange}
                        />
                      </Grid>
                    </Grid>
                    {costCentreInEngland !== undefined &&
                      !costCentreInEngland && (
                        <ReferApplicationNotificationBanner
                          question={
                            EEligibilityQuestion.CostCentre_LocalAuthorityCode
                          }
                          referralType={EReferralType.HomesEngland}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={3} item>
              <Box p={3}>
                <CostCentreValidationChecksInfoPanel />
              </Box>
            </Grid>
            {!readOnly && (
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    p={3}
                    bgcolor="grey.100"
                  >
                    <ReferApplicationButton
                      sectionType={EEligibilitySection.CostCentreValidation}
                      disabled={isOnHoldForThisType}
                    />
                    <OnOffHold holdType={EOnHoldType.Eligibility} />
                    <Box gap={2} display="flex">
                      <Button
                        variant="outlined"
                        disabled={isOnHoldForThisType}
                        onClick={() => {
                          reset(initialData);
                          setCostCentreInEngland(initialData.isInEngland);
                        }}
                      >
                        Cancel
                      </Button>

                      <LoadingButton
                        variant="contained"
                        disabled={isOnHoldForThisType}
                        type="submit"
                        loading={editCostCentreValidationChecksStatus.isLoading}
                      >
                        Confirm Details
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
