import { Box, Divider } from '@mui/material';
import { SectionHeader } from 'common/components/sectionHeader/SectionHeader';
import { ScheduleOfWorksReferralsGrid } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/Referrals/ScheduleOfWorksReferralsGrid';

export const ScheduleOfWorksReferralsSection = () => {
  return (
    <>
      <SectionHeader title="Referrals" />
      <Divider />
      <Box p={2}>
        <ScheduleOfWorksReferralsGrid />
      </Box>
    </>
  );
};
