import { Box, Grid } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetScheduleOfWorksOverviewQuery } from 'api/application/scheduleOfWorksApi';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { InfoCard } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/components';

const getMonthText = (dateInMonths: number | undefined) => {
  const stringifiedMonth = dateInMonths?.toLocaleString();
  if (dateInMonths === 1) {
    return `${stringifiedMonth} month`;
  }
  return `${stringifiedMonth} months`;
};

export const ScheduleOfWorksKpiSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        scheduleOfWorksId: selectedItem.id,
      }
    : skipToken;

  const { data } = useGetScheduleOfWorksOverviewQuery(request);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InfoCard
            title={'Total grant funding'}
            cost={`£${data?.totalGrantFunding.toLocaleString()}`}
          />
        </Grid>
        <Grid item xs={3}>
          <InfoCard
            title={'Total grant paid to date'}
            cost={`£${data?.totalGrantPaidToDate.toLocaleString()}`}
          />
        </Grid>
        <Grid item xs={3}>
          <InfoCard
            title={'Total unclaimed grant'}
            cost={`£${data?.totalUnClaimedGrant.toLocaleString()}`}
          />
        </Grid>
        <Grid item xs={3}>
          <InfoCard
            title={'Project duration'}
            cost={getMonthText(data?.projectDurationInMonths)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
