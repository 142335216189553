import { ComponentProps, useCallback } from 'react';
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetPaginatedApplicationsQuery } from 'api/application';
import { GridSearchField } from 'common/components/gridSearchField';
import { EApplicationInternalStatusLanguage } from 'enums/EApplicationInternalStatus';
import { EApplicationStageLanguage } from 'enums/EApplicationStage';
import { EApplicationStatusLanguage } from 'enums/EApplicationStatus';
import { useAppDispatch } from 'state';
import { selectApplicationsGridState } from 'state/selectors/applications/applicationsSelectors';
import { gridStateChanged } from 'state/slices/applications/applicationsReducer';
import {
  StyledFilter,
  StyledFiltersGridLeft,
  StyledFiltersGridRight,
} from 'styles/globalStyles/filterStyles';

const stageOptions = Object.entries(EApplicationStageLanguage).map(
  ([id, value]) => {
    return { id, value };
  }
);

const statusOptions = Object.entries(EApplicationStatusLanguage).map(
  ([id, value]) => {
    return { id, value };
  }
);

const internalStatusOptions = Object.entries(
  EApplicationInternalStatusLanguage
).map(([id, value]) => {
  return { id, value };
});

export const ApplicationsFilters = () => {
  const dispatch = useAppDispatch();
  const applicationsGridState = useSelector(selectApplicationsGridState);
  const { isLoading } = useGetPaginatedApplicationsQuery(applicationsGridState);

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      dispatch(
        gridStateChanged({
          searchValue,
          skip: 0,
        })
      );
    },
    [dispatch]
  );

  const createDefaultACValueFor = (
    key: 'stage' | 'status' | 'internalStatus',
    options: { id: string; value: string }[]
  ) => {
    const defaultValue = [];
    for (let i = 0; i < applicationsGridState[key].length; i++) {
      const val = options.find(
        ({ id }) => id === applicationsGridState[key][i].toString()
      ) ?? { id: '', value: '' };
      defaultValue.push(val);
    }
    return defaultValue;
  };

  return (
    <Grid container pt={4}>
      <StyledFiltersGridLeft item xs={4}>
        <StyledFilter>
          <GridSearchField
            isLoading={isLoading}
            defaultValue={applicationsGridState.searchValue}
            handleSearch={handleSearchCallback}
          />
        </StyledFilter>
      </StyledFiltersGridLeft>
      <StyledFiltersGridRight item xs={8}>
        <StyledFilter>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={applicationsGridState.showClosed}
                onChange={(_, val) => {
                  dispatch(
                    gridStateChanged({
                      showClosed: val,
                      skip: 0,
                    })
                  );
                }}
              ></Switch>
            }
            label="Include 'Closed' applications"
            labelPlacement="start"
          />
        </StyledFilter>
        <StyledFilter>
          <Autocomplete
            multiple
            data-testid="stage"
            disabled={isLoading}
            defaultValue={createDefaultACValueFor('stage', stageOptions)}
            onChange={(_, selectedValues) => {
              dispatch(
                gridStateChanged({
                  stage: selectedValues.map(selectedValue =>
                    parseInt(selectedValue.id)
                  ),
                  skip: 0,
                })
              );
            }}
            options={stageOptions}
            getOptionLabel={option => option.value}
            renderInput={params => (
              <FilterTextField params={params} label="Stage" />
            )}
            size="small"
          />
        </StyledFilter>
        <StyledFilter>
          <Autocomplete
            multiple
            data-testid="status"
            disabled={isLoading}
            defaultValue={createDefaultACValueFor('status', statusOptions)}
            onChange={(_, selectedValues) => {
              dispatch(
                gridStateChanged({
                  status: selectedValues.map(selectedValue =>
                    parseInt(selectedValue.id)
                  ),
                  skip: 0,
                })
              );
            }}
            options={statusOptions}
            getOptionLabel={option => option.value}
            renderInput={params => (
              <FilterTextField params={params} label="Status" />
            )}
            size="small"
          />
        </StyledFilter>
        <StyledFilter>
          <Autocomplete
            multiple
            data-testid="internalStatus"
            disabled={isLoading}
            defaultValue={createDefaultACValueFor(
              'internalStatus',
              internalStatusOptions
            )}
            onChange={(_, selectedValues) => {
              dispatch(
                gridStateChanged({
                  internalStatus: selectedValues.map(selectedValue =>
                    parseInt(selectedValue.id)
                  ),
                  skip: 0,
                })
              );
            }}
            options={internalStatusOptions}
            getOptionLabel={option => option.value}
            renderInput={params => (
              <FilterTextField params={params} label="Internal Status" />
            )}
            size="small"
          />
        </StyledFilter>
      </StyledFiltersGridRight>
    </Grid>
  );
};

// Get signature of renderInput property
type RenderInputType = ComponentProps<typeof Autocomplete>['renderInput'];

interface IFilterTextFieldProps {
  // Get params of RenderInputType
  params: Parameters<RenderInputType>[0];
  label: string;
}

const FilterTextField = ({ params, label }: IFilterTextFieldProps) => {
  const theme = useTheme();
  return (
    <TextField
      {...params}
      label={label}
      sx={{ background: theme.palette.grey[100] }}
    />
  );
};
