export enum EPullInBuildingHeightType {
  UnderElevenMetres = 1,
  ElevenToEighteenMetres = 2,
  EighteenMetresPlus = 3,
}

export const EPullInBuildingHeightTypeLanguage = {
  [EPullInBuildingHeightType.UnderElevenMetres]: 'Under 11 metres',
  [EPullInBuildingHeightType.ElevenToEighteenMetres]: '11-18 metres',
  [EPullInBuildingHeightType.EighteenMetresPlus]: '18 metres plus',
} as const;
