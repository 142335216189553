import { useAuth } from 'oidc-react';
import { useSelector } from 'react-redux';
import { AutoLogout } from 'common/components/autoLogout';
import { Header } from 'common/components/header';
import { LeftHandNav } from 'common/components/leftHandNav';
import { Loading } from 'common/components/loading';
import { AddEditTaskDrawer } from 'common/components/task';
import { IndexRoutes } from 'routing/IndexRoutes';
import { selectExpanded } from 'state/selectors/navigationBarSelectors';
import { StyledPageWrapper } from 'styles/pageWrapperBox';

function AppWrapper() {
  const navBarExpanded = useSelector(selectExpanded);
  const authContext = useAuth();

  return authContext.isLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <AutoLogout />
      <Header />
      <LeftHandNav />
      <StyledPageWrapper
        sx={() => ({
          marginLeft: navBarExpanded ? '240px' : '65px',
        })}
      >
        <IndexRoutes />
        <AddEditTaskDrawer />
      </StyledPageWrapper>
    </>
  );
}

export default AppWrapper;
