import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useAddSystemNotificationMutation,
  useEditSystemNotificationMutation,
  useLazyGetSystemNotificationQuery,
} from 'api/administration/systemNotifications';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { ISystemNotification } from 'types/administration/AdministrationSystemNotificationTypes';
import { getDateOnlyIsoString } from 'util/DateUtils';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof ISystemNotification) =>
  nameof<ISystemNotification>(fieldName);

interface IAddEditSystemNotificationDialogProps {
  systemNotificationId?: string;
  onSuccess: () => void;
  onClose: () => void;
}

const defaultSystemNotification: ISystemNotification = {
  id: '',
  message: '',
  startDate: '',
  endDate: '',
};

export function AddEditSystemNotificationDrawer({
  systemNotificationId,
  onSuccess,
  onClose,
}: IAddEditSystemNotificationDialogProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            {systemNotificationId ? 'Edit' : 'Add'} System Notification
          </Typography>
        </DialogTitle>
        <AddEditSystemNotificationForm
          systemNotificationId={systemNotificationId}
          onSuccess={onSuccess}
          onClose={onClose}
        />
      </Box>
    </StyledDrawer>
  );
}

interface IAddEditSystemNotificationFormProps {
  systemNotificationId?: string;
  onSuccess: () => void;
  onClose: () => void;
}

export const AddEditSystemNotificationForm = ({
  systemNotificationId,
  onSuccess,
  onClose,
}: IAddEditSystemNotificationFormProps) => {
  const [getSystemNotification, getSystemNotificationStatus] =
    useLazyGetSystemNotificationQuery();
  const [addSystemNotification, addSystemNotificationResult] =
    useAddSystemNotificationMutation();
  const [editSystemNotification, editSystemNotificationResult] =
    useEditSystemNotificationMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<ISystemNotification>({
    defaultValues: defaultSystemNotification,
  });
  const { control, handleSubmit, reset } = form;

  useEffect(() => {
    if (systemNotificationId) {
      getSystemNotification(systemNotificationId).then(result => {
        if (result && result.data) {
          reset(result.data);
        }
      });
    }
  }, [getSystemNotification, reset, systemNotificationId]);

  const onSubmit = async (formData: ISystemNotification) => {
    if (systemNotificationId === undefined) {
      try {
        await addSystemNotification(formData)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`New system notification added`);
            onSuccess();
          })
          .catch(error => {
            if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to add system notification`);
      }
    } else
      try {
        formData.id = systemNotificationId;
        await editSystemNotification(formData)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`System notification updated successfully`);
            onSuccess();
          })
          .catch(error => {
            if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit system notification`);
      }
  };

  const isFormLoading = getSystemNotificationStatus.isLoading;
  const isSubmitting =
    addSystemNotificationResult.isLoading ||
    editSystemNotificationResult.isLoading;

  return isFormLoading ? (
    <Loading isOpen />
  ) : (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={getName('message')}
                rules={{ required: 'Required' }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Message"
                    helperText={fieldState.error?.message}
                    multiline
                    InputProps={{
                      inputProps: {
                        maxLength: 1000,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={getName('startDate')}
                render={({
                  field: { onChange, value, ...field },
                  formState: { errors },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      format="dd/MM/yyyy"
                      label="Start Date"
                      value={value && value !== '' ? parseISO(value) : null}
                      onChange={val => {
                        if (typeof val === 'string') {
                          return '';
                        }

                        return val ? onChange(getDateOnlyIsoString(val)) : null;
                      }}
                      slotProps={{
                        textField: {
                          sx: { mr: '1rem' },
                          error: !!errors.startDate,
                          helperText: errors.startDate?.message,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={getName('endDate')}
                render={({
                  field: { onChange, value, ...field },
                  formState: { errors },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      format="dd/MM/yyyy"
                      label="End Date"
                      value={value && value !== '' ? parseISO(value) : null}
                      onChange={val => {
                        if (typeof val === 'string') {
                          return '';
                        }

                        return val ? onChange(getDateOnlyIsoString(val)) : null;
                      }}
                      slotProps={{
                        textField: {
                          sx: { mr: '1rem' },
                          error: !!errors.endDate,
                          helperText: errors.endDate?.message,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              {systemNotificationId ? 'Update' : 'Add'} System Notification
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
