import { applicationApi } from 'api/application/applicationApi';
import { IVariationReferralListItem } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  CloseHomesEnglandReferralMutationParams,
  CloseThirdPartyReferralMutationParams,
  EReferralRecordType,
  GetReferralsQueryParams,
  GetThirdPartyReferralParams,
  IReferralListItem,
  IThirdPartyReferral,
  IThirdPartyReferrer,
  ReferToThirdPartyMutationParams,
  UpdateThirdPartyReferralMutationParams,
} from 'types/applications/ReferralTypes';
import { QueryTags } from 'util/ApiUtils';

const getReferralListTagFromType = (type: EReferralRecordType) => {
  switch (type) {
    case EReferralRecordType.WorksPackage:
      return QueryTags.ApplicationWorksPackageReferralList;

    case EReferralRecordType.Variation:
      return QueryTags.ApplicationVariationReferralList;

    case EReferralRecordType.PaymentRequest:
      return QueryTags.ApplicationClosingPaymentReferralList;
  }
};

const getReferralSingleTagFromType = (type: EReferralRecordType) => {
  switch (type) {
    case EReferralRecordType.WorksPackage:
      return QueryTags.ApplicationWorksPackageReferral;

    case EReferralRecordType.Variation:
      return QueryTags.ApplicationVariationThirdPartyReferral;

    case EReferralRecordType.PaymentRequest:
      return QueryTags.ApplicationClosingPaymentThirdPartyReferral;
  }
};

export const referralEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getThirdPartyReferrers: builder.query<IThirdPartyReferrer[], void>({
      query: () =>
        `${process.env.REACT_APP_API_URL}/api/referrals/thirdpartyreferrers`,
    }),

    // Can't work out how to narrow the return type based on the query input here
    // If you need to narrow, wrap the call in a local hook and coerce via `as`
    // For example, see `useVariationReferrals()` in...
    // ...`applicationPage/liveProject/sections/VariationSection/hooks.ts`
    getReferralList: builder.query<
      | IReferralListItem[]
      | IVariationReferralListItem[]
      | { results: IReferralListItem[] },
      GetReferralsQueryParams
    >({
      providesTags: (_, ___, params) => {
        return [getReferralListTagFromType(params.type)];
      },
      query: params => {
        const { type, applicationId } = params;
        switch (type) {
          case EReferralRecordType.WorksPackage:
            return `${applicationId}/workpackage/referrals`;

          case EReferralRecordType.Variation:
            return `${applicationId}/variation/${params.variationId}/referrals`;

          case EReferralRecordType.PaymentRequest:
            return `${applicationId}/paymentRequest/${params.paymentRequestId}/thirdpartyreferrals`;
        }
      },
    }),

    referToThirdPartyGeneral: builder.mutation<
      void,
      ReferToThirdPartyMutationParams
    >({
      invalidatesTags: (_, __, params) => [
        getReferralListTagFromType(params.type),
      ],
      query: params => {
        const { type, applicationId, formData } = params;
        const base = { method: 'POST', body: formData };
        switch (type) {
          case EReferralRecordType.WorksPackage:
            return {
              ...base,
              url: `${applicationId}/workpackage/thirdpartyreferral`,
            };

          case EReferralRecordType.Variation:
            return {
              ...base,
              url: `${applicationId}/variation/${params.variationId}/thirdpartyreferral`,
            };

          case EReferralRecordType.PaymentRequest:
            return {
              ...base,
              url: `${applicationId}/paymentRequest/${params.paymentRequestId}/thirdpartyreferral`,
            };
        }
      },
    }),

    getThirdPartyReferral: builder.query<
      IThirdPartyReferral,
      GetThirdPartyReferralParams
    >({
      providesTags: (_, ___, params) => {
        return [
          {
            type: getReferralSingleTagFromType(params.type),
            id: params.thirdPartyReferralId,
          },
        ];
      },
      query: args => {
        const { type, applicationId, thirdPartyReferralId } = args;
        switch (type) {
          case EReferralRecordType.WorksPackage:
            return `${applicationId}/workpackage/thirdpartyreferral/${thirdPartyReferralId}`;

          case EReferralRecordType.Variation:
            return `${applicationId}/variation/${args.variationId}/thirdpartyreferral/${thirdPartyReferralId}`;

          case EReferralRecordType.PaymentRequest:
            return `${applicationId}/paymentRequest/${args.paymentRequestId}/thirdpartyreferral/${thirdPartyReferralId}`;

          default:
            throw new Error(
              `Could not work out third party referral query using type ${type}`
            );
        }
      },
    }),

    updateThirdPartyReferralGeneral: builder.mutation<
      void,
      UpdateThirdPartyReferralMutationParams
    >({
      invalidatesTags: (_, __, params) => [
        getReferralListTagFromType(params.type),
        {
          type: getReferralSingleTagFromType(params.type),
          id: params.thirdPartyReferralId,
        },
      ],
      query: params => {
        const { type, applicationId, thirdPartyReferralId, formData } = params;
        const base = { method: 'PUT', body: formData };
        switch (type) {
          case EReferralRecordType.WorksPackage:
            return {
              ...base,
              url: `${applicationId}/workpackage/thirdpartyreferral/${thirdPartyReferralId}`,
            };

          case EReferralRecordType.Variation:
            return {
              ...base,
              url: `${applicationId}/variation/${params.variationId}/thirdpartyreferral/${thirdPartyReferralId}`,
            };

          case EReferralRecordType.PaymentRequest:
            return {
              ...base,
              url: `${applicationId}/paymentRequest/${params.paymentRequestId}/thirdpartyreferral/${thirdPartyReferralId}`,
            };

          default:
            throw new Error(
              `Could not work out third party referral update mutation config using type ${type}`
            );
        }
      },
    }),

    closeThirdPartyReferralGeneral: builder.mutation<
      void,
      CloseThirdPartyReferralMutationParams
    >({
      invalidatesTags: (_, __, params) => [
        getReferralListTagFromType(params.type),
        {
          type: getReferralSingleTagFromType(params.type),
          id: params.thirdPartyReferralId,
        },
      ],
      query: params => {
        const { type, formData, applicationId, thirdPartyReferralId } = params;
        const base = { method: 'PUT', body: formData };
        switch (type) {
          case EReferralRecordType.WorksPackage:
            return {
              ...base,
              url: `${applicationId}/workpackage/thirdpartyreferral/${thirdPartyReferralId}/response`,
            };

          case EReferralRecordType.Variation:
            return {
              ...base,
              url: `${applicationId}/variation/${params.variationId}/thirdpartyreferral/${thirdPartyReferralId}/response`,
            };

          case EReferralRecordType.PaymentRequest:
            return {
              ...base,
              url: `${applicationId}/paymentRequest/${params.paymentRequestId}/thirdpartyreferral/${thirdPartyReferralId}/response`,
            };
        }
      },
    }),

    closeHomesEnglandReferralGeneral: builder.mutation<
      void,
      CloseHomesEnglandReferralMutationParams
    >({
      invalidatesTags: (_, __, params) => [
        getReferralListTagFromType(params.type),
        {
          type: getReferralSingleTagFromType(params.type),
          id: params.referralId,
        },
      ],
      query: params => {
        const { type, applicationId, referralId, formData } = params;
        const base = { method: 'PUT', body: formData };
        switch (type) {
          case EReferralRecordType.WorksPackage:
            return {
              ...base,
              url: `${applicationId}/workpackage/referToHomesEngland/${referralId}`,
            };

          case EReferralRecordType.Variation:
            return {
              ...base,
              url: `${applicationId}/variation/${params.variationId}/referToHomesEngland/${referralId}`,
            };

          case EReferralRecordType.PaymentRequest:
            return {
              method: 'PUT',
              body: { completeReason: formData.reason },
              url: `${applicationId}/paymentRequest/${params.paymentRequestId}/refer/${referralId}/complete`,
            };
        }
      },
    }),
  }),
});

export const {
  useGetReferralListQuery,
  useReferToThirdPartyGeneralMutation,
  useGetThirdPartyReferrersQuery,
  useGetThirdPartyReferralQuery,
  useUpdateThirdPartyReferralGeneralMutation,
  useCloseThirdPartyReferralGeneralMutation,
  useCloseHomesEnglandReferralGeneralMutation,
} = referralEndpoints;
