import { Box, Grid, Typography } from '@mui/material';

export const SendDeedOfTrustInformationBanner = () => {
  return (
    <Box
    sx={({ palette }) => ({
      padding: 1,
      border: '1px solid lightgray',
      borderRadius: 1,
      borderLeft: `5px solid ${palette.info.light}`,
    })}
    >
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            Send Deed Of Trust
          </Typography>
          <ol>
            <li>Proceed to the Grant Funding Template Folder</li>
            <li>
              Download document <strong>"REF-EXAMPLE"</strong>
            </li>
            <li>
              Go to Docusign to upload & send this document to the nominated
              signatories
            </li>
          </ol>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="h3" fontWeight={600} fontSize="1em">
            Bank Details
          </Typography>
          <ul>
            <li>Account Name</li>
            <li>Sort Code</li>
            <li>Name</li>
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
};
