import { AuthProviderProps } from 'oidc-react';

export const getOidcConfig = (): AuthProviderProps => {
  return {
    //UserInfo not used - managed by API
    loadUserInfo: false,
    authority: process.env.REACT_APP_IDP_URL,
    clientId: 'he-remediation-spa',
    responseType: 'code',
    scope: 'HE.Remediation.API offline_access profile openid',
    redirectUri: `${process.env.REACT_APP_SPA_URL}/callback`,
    automaticSilentRenew: true,
  };
};
