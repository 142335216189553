import { useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Control, Controller, useWatch } from 'react-hook-form';
import {
  IPaymentRequestCosts,
  IPaymentRequestFinalPaymentItem,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { PaymentRequestCostsSectionBox } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsSectionBox';
import { getGbpAmount, getPercentage, hasDecimal } from 'util/AppUtils';

export const PaymentRequestCostsFinalPaymentSection = ({
  finalPayment,
  control,
  isFinalPayment,
  readOnly = false,
  totalGrantFunding,
}: {
  finalPayment: IPaymentRequestFinalPaymentItem | null | undefined;
  control: Control<IPaymentRequestCosts, any>;
  isFinalPayment: boolean;
  readOnly?: boolean;
  totalGrantFunding: number;
}) => {
  const watchFinalScheduledPaymentConfirmedCost = useWatch({
    name: 'finalPayment.scheduledCost',
    control,
  });

  const watchFinalPaymentConfirmedCost = useWatch({
    name: 'finalPayment.confirmedCost',
    control,
  });

  return (
    <>
      <PaymentRequestCostsSectionBox>
        <Typography variant="h3" fontWeight={800} fontSize="1.3em">
          Final payment
        </Typography>
      </PaymentRequestCostsSectionBox>
      <Box
        p={isFinalPayment ? 2 : '16px 0'}
        sx={{ borderBottom: `${isFinalPayment && '1px solid lightgray'}` }}
      >
        <PaymentRequestFinalPaymentItemRow
          paymentRequestFinalPaymentItem={finalPayment}
          isFinalPayment={isFinalPayment}
          readOnly={readOnly}
          totalGrantFunding={totalGrantFunding}
        />
      </Box>
      {isFinalPayment && (
        <Box p={2}>
          <PaymentRequestFinalPaymentTotalRow
            finalScheduledPaymentTotal={
              !!watchFinalScheduledPaymentConfirmedCost
                ? parseFloat(watchFinalScheduledPaymentConfirmedCost.toString())
                : 0
            }
            finalPaymentTotal={
              !!watchFinalPaymentConfirmedCost
                ? parseFloat(watchFinalPaymentConfirmedCost.toString())
                : 0
            }
          />
        </Box>
      )}
    </>
  );
};

const PaymentRequestFinalPaymentItemRow = ({
  paymentRequestFinalPaymentItem,
  isFinalPayment,
  readOnly = false,
  totalGrantFunding,
}: {
  paymentRequestFinalPaymentItem:
    | IPaymentRequestFinalPaymentItem
    | null
    | undefined;
  isFinalPayment: boolean;
  readOnly?: boolean;
  totalGrantFunding: number;
}) => {
  const [percentageOfProjectFee, setPercentageOfProjectFee] = useState(
    getPercentage(
      Number(paymentRequestFinalPaymentItem?.confirmedCost),
      Number(totalGrantFunding)
    ).toFixed(2)
  );
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={2}
      wrap="nowrap"
      sx={{
        mb: 1,
        ml: isFinalPayment ? 4 : 0,
        width: isFinalPayment ? '100%' : 'auto',
        pr: isFinalPayment ? 0 : 2,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em">
          {isFinalPayment
            ? `Closing payment (${percentageOfProjectFee}%)`
            : paymentRequestFinalPaymentItem?.itemName}
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        textAlign={'right'}
        sx={{
          pr: isFinalPayment ? 0 : 1,
        }}
      >
        <Typography>
          {!!paymentRequestFinalPaymentItem?.scheduledCost
            ? getGbpAmount({
                value: paymentRequestFinalPaymentItem?.scheduledCost,
              })
            : '-'}
        </Typography>
      </Grid>
      <Grid
        item
        textAlign={'right'}
        xs={isFinalPayment ? false : 3}
        sx={{ maxWidth: isFinalPayment ? '20%' : 'auto' }}
      >
        <Box pl={isFinalPayment ? 4 : 0}>
          <Controller
            name={'finalPayment.confirmedCost'}
            render={({ field, fieldState }) => (
              <TextField
                sx={{
                  margin: 0,
                }}
                {...field}
                type="number"
                error={!!fieldState.error}
                required
                helperText={fieldState.error?.message}
                disabled={!isFinalPayment || readOnly}
                onChange={e => {
                  if (hasDecimal(Number(e.target.value))) return;
                  if (
                    Number(e.target.value) >
                    Number(paymentRequestFinalPaymentItem?.scheduledCost)
                  ) {
                    return;
                  }
                  setPercentageOfProjectFee(
                    getPercentage(
                      Number(e.target.value),
                      Number(totalGrantFunding)
                    ).toFixed(2)
                  );
                  return field.onChange(e);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            )}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const PaymentRequestFinalPaymentTotalRow = ({
  finalScheduledPaymentTotal,
  finalPaymentTotal,
}: {
  finalScheduledPaymentTotal: number;
  finalPaymentTotal: number;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={2}
      wrap="nowrap"
      sx={{
        mb: 1,
        ml: 4,
        width: '100%',
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em">
          Total
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign={'right'}>
        <Typography fontWeight={600}>
          {getGbpAmount({ value: finalScheduledPaymentTotal })}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign={'left'}>
        <Stack direction="row" spacing={1} justifyContent="left" pl={4}>
          <Typography fontWeight={600}>
            {/* Check if value is !== undefined rather than falsey as it could be 0 and that's okay */}
            {finalPaymentTotal !== undefined
              ? getGbpAmount({ value: finalPaymentTotal })
              : '-'}
          </Typography>
          <Typography>
            {finalPaymentTotal !== undefined &&
              finalScheduledPaymentTotal !== undefined &&
              finalPaymentTotal !== finalScheduledPaymentTotal &&
              `(${
                (finalPaymentTotal ?? 0) > (finalScheduledPaymentTotal ?? 0)
                  ? '+'
                  : '-'
              }${getGbpAmount({
                value:
                  finalScheduledPaymentTotal === 0
                    ? finalPaymentTotal
                    : Math.abs(finalPaymentTotal - finalScheduledPaymentTotal),
              })})`}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
