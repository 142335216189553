import { DataTableResponse } from 'common/types/DataTableResponse';
import { ISystemNotification, ISystemNotificationGridListItem } from 'types/administration/AdministrationSystemNotificationTypes';

export const systemNotificationsGrid: DataTableResponse<ISystemNotificationGridListItem> =
  {
    count: 3,
    results: [
      {
        id: '1',
        message: 'Mock System Notification 1',
        startDate: new Date('2023-12-01').toJSON(),
        endDate: new Date('2023-12-31').toJSON(),
        lastUpdatedBy: 'Joe Bloggs',
      },
      {
        id: '2',
        message: 'Mock System Notification 2',
        startDate: new Date('2024-01-01').toJSON(),
        endDate: new Date('2024-01-31').toJSON(),
        lastUpdatedBy: 'Joe Bloggs',
      },
      {
        id: '3',
        message: 'Mock System Notification 3',
        startDate: new Date('2024-02-01').toJSON(),
        endDate: new Date('2024-02-28').toJSON(),
        lastUpdatedBy: 'Joe Bloggs',
      },
    ],
  };

export const systemNotificationData: ISystemNotification = {
  id: '1',
  message: 'Mock System Notification 1',
  startDate: new Date('2023-12-01').toJSON(),
  endDate: new Date('2023-12-31').toJSON(),
};
