import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Chip,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useGetSupportRequestListQuery } from 'api/application/applicationSupportApi';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  ESupportTicketStatus,
  ESupportTicketStatusLanguage,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { useApplicationSupportContext } from 'pages/applicationPage/content/support/context';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { getGbpAmount } from 'util/AppUtils';
import { formatDateTime } from 'util/DateUtils';

export const SupportList = () => {
  const { applicationId } = useApplicationContext();
  const { setSelectedItemId } = useApplicationSupportContext();
  const { data } = useGetSupportRequestListQuery(applicationId);

  const noResults = data?.length === 0;

  return (
    <Box p={2}>
      <NoResultsFoundText show={noResults} text="No support items" />
      <StyledGenericTable data-testid="Application-Signatories-Table">
        <TableHead>
          <TableRow>
            <TableCell>Support ID</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(row => (
            <TableRow key={row.id}>
              <TableCell>
                <Typography color={'grey.700'} fontSize={'1em'}>
                  #{row.referenceNumber}
                </Typography>
                <Typography fontWeight={600}>{row.description}</Typography>
              </TableCell>
              <TableCell>
                {formatDateTime(new Date(row.startDate), 'HH:mm, dd MMM yyyy')}
              </TableCell>
              <TableCell>
                {getGbpAmount({
                  value: row.cost,
                  showIfValueNullOrUndefined: '-',
                })}
              </TableCell>
              <TableCell>
                <Chip
                  label={
                    row.status ===
                    ESupportTicketStatus.AppointedRemediationAdviser
                      ? `${ESupportTicketStatusLanguage[row.status]} - ${
                          row.remediationThirdPartyType
                        }`
                      : ESupportTicketStatusLanguage[row.status]
                  }
                  size="small"
                  sx={{
                    background:
                      row.status === ESupportTicketStatus.ToDo ? 'black' : '',
                    color:
                      row.status === ESupportTicketStatus.ToDo ? 'white' : '',
                  }}
                />
              </TableCell>
              <TableCell align="right">
                <IconButton
                  aria-label="edit"
                  name="edit"
                  onClick={event => setSelectedItemId(row.id)}
                >
                  <FontAwesomeIcon icon={faChevronRight} size="xs" fixedWidth />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledGenericTable>
    </Box>
  );
};
