import { Typography } from '@mui/material';
import { useGetEligibilitySummaryQuery } from 'api/application';
import {
  ColumnContainer,
  DetailItem,
  ApplicationFileDownloadWrapper,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const CoreInformationChecksApplicantsEvidencePanel = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetEligibilitySummaryQuery(applicationId);

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Applicants Evidence
        </Typography>
        {query.data ? (
          <>
            <DetailItem label="Lease holder engagement document">
              {query.data.leaseHolderEngagegmentFile ? (
                <ApplicationFileDownloadWrapper
                  fileName={query.data.leaseHolderEngagegmentFile.name}
                  fileId={query.data.leaseHolderEngagegmentFile.id}
                />
              ) : (
                '-'
              )}
            </DetailItem>
            <DetailItem label="Acceptable evidence">
              <ul>
                <li>Example evidence 1</li>
                <li>Example evidence 2</li>
                <li>Example evidence 3</li>
              </ul>
            </DetailItem>
          </>
        ) : null}
      </ColumnContainer>
    </>
  );
};
