import { DateRange } from '@mui/x-date-pickers-pro';
import { DataTableResponse } from 'common/types/DataTableResponse';
import { ETeam } from 'enums/ETeam';
import { ISelectOption } from 'types/GeneralTypes';
import { IPaginationWithSortQuery } from 'types/PaginationQuery';
import { tupleToSelectOption } from 'util/AppUtils';
import { nameof } from 'util/formUtils';

export interface IGetTaskTypesResult {
  taskTypes: ITaskType[];
}

export interface ITaskType {
  id: number;
  name: string;
  active: boolean;
  subtypes: ITaskSubtype[];
}

export interface ITaskSubtype {
  id: number;
  name: string;
  active: boolean;
  isDueDateEditable: boolean;
  topics: ITopicOption[];
}

export interface ITopicOption {
  id: number;
  name: string;
  slaDays: number;
}

export interface IGetOutcomesResult {
  outcomes: IOutcomeOption[];
}

interface IOutcomeOption {
  id: string;
  name: string;
}

export interface ITask {
  description: string;
  buildingName: string;
  applicant: string;
  createdBy: string;
  createdDate: string;
  taskTypeId: number;
  taskSubtypeId: number;
  topicId?: number;
  applicationReference: string;
  assignedToTeamId?: ETeam;
  assigneeUserId?: string;
  dueDate: string;
  status: ECmsTaskStatus;
  outcomeId?: string;
  notes?: string;
}

export interface IAddEditTask
  extends Omit<
    ITask,
    | 'taskTypeId'
    | 'taskSubtypeId'
    | 'topicId'
    | 'applicationReference'
    | 'assignedToTeamId'
  > {
  taskTypeId: number | '';
  taskSubtypeId: number | '';
  topicId: number | '';
  applicationRef: string;
  assignedToTeamId: number | '';
}

export type GetAdminTasksListResponse = DataTableResponse<IAdminTaskListItem>;

export interface IAdminTaskListItem
  extends Pick<
    ITask,
    'description' | 'buildingName' | 'applicant' | 'createdBy' | 'createdDate' |'applicationReference' | 'dueDate' | 'status'
  > {
  taskId: string;
  applicationId: string;
  assignedTo: string;
  assignedToTeamName: string;
  taskType: string;
  taskSubtype: string;
  topic: string;
  noteCount: number;
}

export type TaskListItemDefault = IAdminTaskListItem;

export type GetTaskListResponseDefault = DataTableResponse<TaskListItemDefault>;

export enum ECmsTaskStatus {
  NotStarted = 1,
  InProgress = 2,
  Done = 3,
}

export const ECmsTaskStatusLanguage: { [key in ECmsTaskStatus]: string } = {
  [ECmsTaskStatus.NotStarted]: 'Not Started',
  [ECmsTaskStatus.InProgress]: 'In Progress',
  [ECmsTaskStatus.Done]: 'Done',
} as const;

export const statusOptions = Object.entries(ECmsTaskStatusLanguage).map(
  tupleToSelectOption
);

export enum ECmsTaskView {
  Tasks = 1,
  Notes = 2,
}

export const ECmsTaskViewLanguage: { [key in ECmsTaskView]: string } = {
  [ECmsTaskView.Tasks]: 'Tasks',
  [ECmsTaskView.Notes]: 'Notes',
} as const;

export const taskViewOptions =
  Object.entries(ECmsTaskViewLanguage).map(tupleToSelectOption);

export interface ITaskStats {
  overdueCount: number;
  dueTodayCount: number;
  dueTomorrowCount: number;
  notStartedCount: number;
  inProgressCount: number;
}

export enum ETaskMenuType {
  EditTask,
  ChangeStatus,
  AddNote,
  OpenApplication,
}

export enum ENoteMenuType {
  EditNote,
}

const getTaskListSortByOptions = (options: readonly string[]) => {
  return options.reduce<Record<string, string>>((output, curr) => {
    let displayVal = '';
    switch (curr) {
      case 'dueDate':
        displayVal = 'Due Date with payments';
        break;

      case 'applicationReference':
        displayVal = 'Application Reference';
        break;

      case 'taskType':
        displayVal = 'Task Type';
        break;
    }
    output[curr] = displayVal;
    return output;
  }, {});
};

export const adminTaskListSortByTypes = [
  nameof<IAdminTaskListItem>('dueDate'),
  nameof<IAdminTaskListItem>('applicationReference'),
  nameof<IAdminTaskListItem>('taskType'),
] as const;

export const adminTaskListSortByOptions = getTaskListSortByOptions(
  adminTaskListSortByTypes
);

export type AdminTaskListSortByTypes =
  (typeof adminTaskListSortByTypes)[number];

export interface IAdminTaskListPaginatedQuery
  extends IPaginationWithSortQuery<AdminTaskListSortByTypes> {
  assigneeId: string[];
  taskType: number[];
  status: ECmsTaskStatus[];
  startDate: string;
  endDate: string;
  team: ETeam[];
}

export interface IAdminTaskListFilters {
  assigneeId: string[];
  status: ECmsTaskStatus[];
  dateRange: DateRange<Date>;
  taskType: number[];
}

export const taskListSortByTypesDefault = [
  nameof<TaskListItemDefault>('dueDate'),
  nameof<TaskListItemDefault>('applicationReference'),
  nameof<TaskListItemDefault>('taskType'),
] as const;

export const taskListSortByOptionsDefault = getTaskListSortByOptions(
  taskListSortByTypesDefault
);

export const sortOptionsDefault: ISelectOption[] = Object.entries(
  taskListSortByOptionsDefault
).map(([key, value]) => {
  return { value: key, label: value };
});

export type TaskListSortByTypesDefault =
  (typeof taskListSortByTypesDefault)[number];

export interface ITaskListPaginatedQueryDefault
  extends IPaginationWithSortQuery<TaskListSortByTypesDefault> {
  status: ECmsTaskStatus[];
  team: ETeam[];
  showCreatedByMe: boolean;
  viewType: ECmsTaskView;
}

export enum ETaskListConfigType {
  AdminTasks,
  CurrentUserTasks,
  ApplicationTasks,
}
export type TaskListConfig =
  | {
      type: ETaskListConfigType.AdminTasks;
      query: IAdminTaskListPaginatedQuery;
    }
  | {
      type: ETaskListConfigType.CurrentUserTasks;
      query: ITaskListPaginatedQueryDefault;
    }
  | {
      type: ETaskListConfigType.ApplicationTasks;
      query: ITaskListPaginatedQueryDefault;
      applicationId: string;
    };

export type TaskNote = Pick<
  ITask,
  | 'description'
  | 'assigneeUserId'
  | 'dueDate'
  | 'status'
  | 'outcomeId'
  | 'notes'
>;

export type TaskNoteView = { subtaskId: string } & Omit<
  TaskNote,
  'assigneeUserId' | 'outcomeId' | 'notes'
> & {
    assigneeName: string;
  };
