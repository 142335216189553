import { ReactNode } from 'react';
import { Box, Stack, Typography, LinearProgress } from '@mui/material';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import {
  CardTitle,
  OverviewSummaryItem,
} from 'pages/applicationPage/content/liveProject/common';

interface IProgressDataProp {
  leftSide: string;
  currentVal?: string;
  rightSide: string;
  progressBarData: number;
}

export const BoxWithDuration = ({
  title,
  isLoading,
  isSuccess,
  summaryItemTitle,
  summaryValue,
  progressData,
}: {
  title: string;
  isLoading: boolean;
  isSuccess: boolean;
  summaryItemTitle: string;
  summaryValue?: ReactNode;
  progressData: IProgressDataProp;
}) => {
  return (
    <RoundBorderBox sx={{ p: 2, mb: 2 }}>
      <CardTitle title={title} sx={{ mb: 3 }} />
      {isLoading && <FormSkeleton />}
      {isSuccess && (
        <Stack gap={2}>
          <OverviewSummaryItem
            title={summaryItemTitle}
            value={summaryValue}
            bottomBorder={false}
          />
          <LinearProgress
            value={progressData.progressBarData}
            variant="determinate"
            aria-label={`Progress ${progressData.progressBarData} out of 100`}
          />
          <Box display="flex" justifyContent="space-between">
            <Typography>{progressData.leftSide}</Typography>
            <Typography>{progressData.currentVal}</Typography>
            <Typography>{progressData.rightSide}</Typography>
          </Box>
        </Stack>
      )}
    </RoundBorderBox>
  );
};
