export enum EPullInProcessContactType {
  Freeholder = 1,
  ManagingAgent = 2,
  LocalAuthority = 3,
  Other = 4,
  RegisteredProvider = 5,
  RightToManageCompany = 6,
}

export const EPullInProcessContactTypeLanguage = {
  [EPullInProcessContactType.Freeholder]: 'Freeholder',
  [EPullInProcessContactType.ManagingAgent]: 'Managing agent',
  [EPullInProcessContactType.LocalAuthority]: 'Local Authority',
  [EPullInProcessContactType.RegisteredProvider]: 'Registered Provider',
  [EPullInProcessContactType.RightToManageCompany]: 'Right to manage company',
  [EPullInProcessContactType.Other]: 'Other',
} as const;
