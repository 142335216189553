import { rest } from 'msw';
import {
  worksPackageDeedCardDetail,
  worksPackageDeedDetail,
  worksPackageDeedSentCounterSignedStatus,
  worksPackageDeedSentSignedStatus,
  worksPackageDeedSentStatus,
} from 'mocks/data/application/applicationWorksPackageDeedData';

export const worksPackageDeedHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/deed/sent`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(worksPackageDeedSentStatus));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/deed/signed`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(worksPackageDeedSentSignedStatus));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/deed/cardDetail`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(worksPackageDeedCardDetail));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/deed/detail`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(worksPackageDeedDetail));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/deed/sent`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/deed/signed`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/deed/countersigned`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(worksPackageDeedSentCounterSignedStatus)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/deed/countersigned`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
