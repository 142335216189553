import { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Autocomplete,
  Collapse,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useGetCountriesQuery } from 'api/common/countriesApi';
import {
  buildingRecordsAddressApi,
  useLazyGetAddressByPostCodeQuery,
} from 'api/pullInProcess';
import { useAppDispatch } from 'state';
import {
  TContactDetailAddress,
  TContactDetails,
} from 'types/pullInProcess/contactDetailsTypes';
import { stringAppendWithValueOrEmpty } from 'util/stringAppendWithValueOrEmpty';

type TContactAddressFormProps = {
  modalData: string | null;
  readonly?: boolean;
};

export function ContactAddressForm({
  modalData,
  readonly,
}: TContactAddressFormProps) {
  const form = useFormContext<TContactDetails>();
  const {
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = form;

  const isEdit = modalData !== null;
  const hasMountedAddressRef = useRef(false);

  const dispatch = useAppDispatch();

  const [isManualUpdateAddress, setIsManualUpdateAddress] = useState(false);

  const { data: countries } = useGetCountriesQuery();

  const [getAddressByPostCode, { data, isLoading }] =
    useLazyGetAddressByPostCodeQuery();

  const [addressDropDownValue, setAddressDropDownValue] = useState<
    number | string
  >('');

  const postCodeInput = watch('address.postCode');

  function handlePostCodeFetch() {
    getAddressByPostCode({ postCode: postCodeInput, isBuildingLookup: false });
  }

  useEffect(() => {
    if (isEdit && !data && postCodeInput && !hasMountedAddressRef.current) {
      getAddressByPostCode({
        postCode: postCodeInput,
        isBuildingLookup: false,
      });
      hasMountedAddressRef.current = true;
    }
  }, [data, isEdit, getAddressByPostCode, postCodeInput]);

  useEffect(() => {
    const { address } = errors;
    if (
      (address?.country || address?.line1 || address?.townCity) &&
      !isManualUpdateAddress
    ) {
      setIsManualUpdateAddress(true);
    }
  }, [
    errors,
    errors.address?.country,
    errors.address?.line1,
    errors.address?.townCity,
    isManualUpdateAddress,
  ]);

  return (
    <>
      <Box display="flex" gap="1rem">
        <Controller
          control={control}
          name="address.postCode"
          defaultValue=""
          render={({ field: { onChange, ...fieldRest } }) => (
            <TextField
              fullWidth
              {...fieldRest}
              onChange={event => {
                if (data?.addresses.length) {
                  dispatch(buildingRecordsAddressApi.util.resetApiState());
                  reset();
                }
                onChange(event);
              }}
              label="Postcode (required)"
              error={!!errors.address?.postCode}
              helperText={errors.address?.postCode?.message}
              disabled={readonly}
            />
          )}
        />
        <LoadingButton
          variant="outlined"
          sx={{ minWidth: '160px', minHeight: '56px' }}
          disabled={!postCodeInput}
          onClick={() => handlePostCodeFetch()}
          loading={isLoading}
        >
          Find Postcode
        </LoadingButton>
      </Box>
      {data?.addresses ? (
        <TextField
          fullWidth
          select
          label="Address"
          defaultValue=""
          value={
            typeof addressDropDownValue === 'number' ? addressDropDownValue : ''
          }
          onChange={(...args) => {
            const event = args[0];
            const idx = Number(event.target.value);

            if (!Number.isNaN(idx)) {
              const address = data.addresses[idx];
              const { localAuthority, buildingNameNumber, ...newAddress } =
                address;
              setValue('address', newAddress as TContactDetailAddress, {
                shouldValidate: true,
              });
              setValue('address.country', newAddress.country, {
                shouldValidate: true,
              });
              setAddressDropDownValue(event.target.value);
            } else setAddressDropDownValue('');

            return args;
          }}
          disabled={readonly}
        >
          {data.addresses.length > 0 ? (
            data.addresses.map((address, idx) => (
              <MenuItem key={idx} value={idx}>
                {`${stringAppendWithValueOrEmpty(address.line1, ',')} 
                  ${stringAppendWithValueOrEmpty(address.line2, ',')}
                  ${stringAppendWithValueOrEmpty(address.townCity, ',')} 
                  ${stringAppendWithValueOrEmpty(address.country?.name, ',')} 
                  ${stringAppendWithValueOrEmpty(address.postCode, '')}`}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={'noResult'}>No results found</MenuItem>
          )}
        </TextField>
      ) : null}
      <>
        <Button
          type="button"
          variant="text"
          sx={{ p: 0, height: 'auto', mb: '1rem' }}
          onClick={() =>
            setIsManualUpdateAddress(previousState => !previousState)
          }
        >
          {!readonly && (
            <Typography component="u">Enter address manually</Typography>
          )}
        </Button>
        <Collapse in={isManualUpdateAddress} timeout={500} unmountOnExit>
          <>
            <Controller
              control={control}
              name="address.line1"
              defaultValue=""
              render={({ field: { onChange, ...fieldRest } }) => (
                <TextField
                  fullWidth
                  onChange={(...args) => {
                    setAddressDropDownValue('');
                    return onChange(...args);
                  }}
                  {...fieldRest}
                  label="Address line 1"
                  error={!!errors.address?.line1}
                  helperText={errors.address?.line1?.message}
                  disabled={readonly}
                />
              )}
            />
            <Controller
              control={control}
              name="address.line2"
              defaultValue=""
              render={({ field: { onChange, ...fieldRest } }) => (
                <TextField
                  fullWidth
                  onChange={(...args) => {
                    setAddressDropDownValue('');
                    return onChange(...args);
                  }}
                  {...fieldRest}
                  label="Address line 2"
                  error={!!errors.address?.line2}
                  helperText={errors.address?.line2?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="address.townCity"
              defaultValue=""
              render={({ field: { onChange, ...fieldRest } }) => (
                <TextField
                  fullWidth
                  onChange={(...args) => {
                    setAddressDropDownValue('');
                    return onChange(...args);
                  }}
                  {...fieldRest}
                  label="Town/City"
                  error={!!errors.address?.townCity}
                  helperText={errors.address?.townCity?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="address.county"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  label="County"
                  error={!!errors.address?.county}
                  helperText={errors.address?.county?.message}
                />
              )}
            />
            {countries && (
              <Controller
                key={isManualUpdateAddress.toString()}
                control={control}
                name="address.country"
                render={({ field }) => {
                  return (
                    <Autocomplete
                      options={countries}
                      getOptionLabel={option => option.name ?? ''}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value?.id;
                      }}
                      value={field.value || null}
                      onChange={(...args) => {
                        field.onChange(args[1]);
                        return args;
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Country"
                          error={!!errors.address?.country}
                          helperText={errors.address?.country?.message}
                        />
                      )}
                    />
                  );
                }}
              />
            )}
          </>
        </Collapse>
      </>
    </>
  );
}
