import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

export const muiAutocompleteTheme: {
  defaultProps?: ComponentsProps['MuiAutocomplete'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAutocomplete'];
  variants?: ComponentsVariants['MuiAutocomplete'];
} = {
  styleOverrides: {
    endAdornment: {
      top: 0,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    popupIndicator: {
      margin: 0,
    },
  },
};
