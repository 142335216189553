import { createContext, ReactNode, useContext } from 'react';
import { useGetCurrentUserQuery } from 'api/profile/profile';
import { FormSkeleton } from 'common/components/skeletons';
import { useGridQueryAndState } from 'pages/adminTasksPage/utils';

interface IAdminTasksGridContext {
  query: ReturnType<typeof useGridQueryAndState>['query'];
  state: ReturnType<typeof useGridQueryAndState>['state'];
  gridStateChanged: ReturnType<typeof useGridQueryAndState>['gridStateChanged'];
}

const AdminTasksGridContext = createContext<IAdminTasksGridContext | undefined>(
  undefined
);

interface IAdminTasksGridContextProviderProps {
  children: ReactNode;
}

export const AdminTasksGridContextProvider = ({
  children,
}: IAdminTasksGridContextProviderProps) => {
  const { isLoading, isSuccess } = useGetCurrentUserQuery();
  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isSuccess) {
    return (
      <AdminTasksGridContextProviderMain>
        {children}
      </AdminTasksGridContextProviderMain>
    );
  }

  return null;
};

const AdminTasksGridContextProviderMain = ({
  children,
}: IAdminTasksGridContextProviderProps) => {
  const value = useGridQueryAndState();
  return (
    <AdminTasksGridContext.Provider value={value}>
      {children}
    </AdminTasksGridContext.Provider>
  );
};

export const useAdminTasksGridContext = () => {
  const context = useContext(AdminTasksGridContext);
  if (context === undefined) {
    throw new Error(
      'useAdminTasksGridContext must be used within an AdminTasksGridContextProvider'
    );
  }
  return context;
};
