import { useState } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { useLocalSnackbar, useModalState } from 'hooks';
import { IMutationStatus } from 'types/GeneralTypes';

interface IShowReferModalConfig {
  handleSave: (onThirdPartyReferralSuccess: () => void) => void;
}

export const useReferToThirdPartyModal = () => {
  const {
    isShowing: isReferModalShowing,
    showModal,
    hideModal,
  } = useModalState();

  const { createErrorSnackbar } = useLocalSnackbar();
  const [params, setParams] = useState<IShowReferModalConfig | null>(null);

  const showReferModal = (params: IShowReferModalConfig) => {
    setParams(params);
    showModal();
  };

  const hideReferModal = () => {
    setParams(null);
    hideModal();
  };

  const handleReferClick = () => {
    if (!params) {
      createErrorSnackbar(
        'Could not get configuration. Please try again later.'
      );
      return;
    }

    params.handleSave(() => {
      hideReferModal();
    });
  };

  const renderReferModal = ({
    referStatus,
  }: {
    referStatus?: IMutationStatus;
  }) => {
    if (!params) {
      return null;
    }

    const isLoading = referStatus?.isLoading ?? false;
    return (
      <ConfirmationModal
        isShowing={isReferModalShowing}
        confirmButtonText="Yes, Refer"
        cancelButtonText="Cancel"
        onConfirm={handleReferClick}
        onCancel={hideReferModal}
        dialogActionsSx={{ justifyContent: 'center', mb: 2 }}
        areButtonsLoading={isLoading}
      >
        {isLoading ? <LinearProgress /> : null}

        <Typography pt={1} fontWeight={600} textAlign={'center'}>
          Are you sure you want to refer?
        </Typography>

        {referStatus ? (
          <AlertForQueryErrorOrNull
            isError={referStatus.isError}
            error={referStatus.error}
          />
        ) : null}
      </ConfirmationModal>
    );
  };

  return {
    showReferModal,
    renderReferModal,
  };
};
