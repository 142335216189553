import { Box, Divider } from '@mui/material';
import { EligibilitySectionHeader } from 'pages/applicationPage/content/applicationEligibility/components/EligibilitySectionHeader';
import { ReferralsGrid } from 'pages/applicationPage/content/applicationEligibility/components/ReferralsSection';

export const ReferralsSection = () => {
  return (
    <>
      <EligibilitySectionHeader title="Referrals" />
      <Divider />
      <Box p={2}>
        <ReferralsGrid />
      </Box>
    </>
  );
};
