import { applicationApi } from 'api/application';
import {
  IApplicationGrantFundingCategories,
  IApplicationGrantFundingCounterSignedChecks,
  IApplicationGrantFundingDetailsChecks,
  IApplicationGrantFundingSignedDocsChecks,
  IApplicationGrantFundingSummary,
} from 'types/applications/ApplicationGrantFundingTypes';
import { QueryTags } from 'util/ApiUtils';
import { sortByOrderAsc } from 'util/AppUtils';

export const grantFundingEndpoints = applicationApi.injectEndpoints({
  endpoints: builder => ({
    getApplicationGrantFundingCategories: builder.query<
      IApplicationGrantFundingCategories,
      string
    >({
      providesTags: [QueryTags.ApplicationGrantFunding],
      query: applicationId => `/${applicationId}/grantfunding/overview`,
      transformResponse: (response: IApplicationGrantFundingCategories) => {
        // Sort data before it hits the cache

        // First, sort top level categories
        response.categories.sort(sortByOrderAsc);

        // Then iterate each category and sort child items
        for (const category of response.categories) {
          category.detailItems.sort(sortByOrderAsc);

          for (const detailItem of category.detailItems) {
            detailItem.id = detailItem.sectionName.replace(/\s/g, '');
          }
        }

        return response;
      },
    }),
    getGrantFundingSummary: builder.query<
      IApplicationGrantFundingSummary,
      string
    >({
      providesTags: [QueryTags.ApplicationGrantFunding],
      query: applicationId => `/${applicationId}/grantfunding/details`,
    }),

    getGrantFundingDetailsChecks: builder.query<
      IApplicationGrantFundingDetailsChecks,
      string
    >({
      providesTags: [QueryTags.ApplicationGrantFunding],
      query: applicationId => `/${applicationId}/grantfunding/gfadetails`,
    }),
    editGrantFundingDetailsChecks: builder.mutation<
      any,
      IApplicationGrantFundingDetailsChecks
    >({
      invalidatesTags: [QueryTags.ApplicationStatus, QueryTags.ApplicationGrantFunding],
      query: payload => ({
        url: `/${payload.id}/grantfunding/gfadetails`,
        method: 'PUT',
        body: payload,
      }),
    }),

    getGrantFundingSignedDocsChecks: builder.query<
      IApplicationGrantFundingSignedDocsChecks,
      string
    >({
      providesTags: [QueryTags.ApplicationGrantFunding],
      query: applicationId =>
        `/${applicationId}/grantfunding/signedDocumentation`,
    }),
    editGrantFundingSignedDocsChecks: builder.mutation<
      any,
      IApplicationGrantFundingSignedDocsChecks
    >({
      invalidatesTags: [QueryTags.ApplicationStatus, QueryTags.ApplicationGrantFunding],
      query: payload => ({
        url: `/${payload.id}/grantfunding/signedDocumentation`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getGrantFundingCounterSignedChecks: builder.query<
      IApplicationGrantFundingCounterSignedChecks,
      string
    >({
      providesTags: [QueryTags.ApplicationGrantFunding],
      query: applicationId => `/${applicationId}/grantfunding/countersigned`,
    }),
    editGrantFundingCounterSignedChecks: builder.mutation<
      any,
      IApplicationGrantFundingCounterSignedChecks
    >({
      invalidatesTags: [QueryTags.ApplicationStatus, QueryTags.ApplicationGrantFunding],
      query: payload => ({
        url: `/${payload.id}/grantfunding/countersigned`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApplicationGrantFundingCategoriesQuery,
  useGetGrantFundingSummaryQuery,
  useGetGrantFundingDetailsChecksQuery,
  useEditGrantFundingDetailsChecksMutation,
  useGetGrantFundingSignedDocsChecksQuery,
  useEditGrantFundingSignedDocsChecksMutation,
  useGetGrantFundingCounterSignedChecksQuery,
  useEditGrantFundingCounterSignedChecksMutation,
} = grantFundingEndpoints;
