import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography } from '@mui/material';

interface IVeriphyStatusBannerProps {
  title: string;
}

export function VeriphyStatusBanner({ title }: IVeriphyStatusBannerProps) {
  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 1,
        bgcolor: 'grey.100',
      }}
    >
      <Box>
        <Stack direction="row" spacing={2}>
          <FontAwesomeIcon
            icon={solid('circle-question')}
            size="lg"
            style={{
              marginTop: '0.25rem',
            }}
          />

          <Box>
            <Typography fontSize="1em" fontWeight={700} color="black">
              Veriphy Status
            </Typography>
            <Typography>{title}</Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
