import { useFormContext } from 'react-hook-form';
import { useReferVariationToHomesEnglandMutation } from 'api/application/variationApi';
import { useReferToHomesEnglandModal } from 'common/components/referToHomesEnglandModal';
import { useReferToThirdPartyDrawer } from 'common/components/referrals/thirdPartyReferrals';
import { useCurrentUser, useInvalidateTasks, useLocalSnackbar } from 'hooks';
import { EVariationSection } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { useVariationContext } from 'pages/applicationPage/content/liveProject/sections/VariationSection';
import { VariationkChecksForm } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  useApplicationAndVariationIdOrNull,
  useVariationOverview,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { useRejectModal } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationChecksSection';
import { EReferralRecordType } from 'types/applications/ReferralTypes';

export const _formId = 'variationChecksConfirmationForm';

const doesFieldPassCheck = (
  isFieldCheckRequired: boolean,
  currentFieldValue: boolean | undefined,
  expectedFieldValue: boolean
) => {
  if (!isFieldCheckRequired) {
    return true;
  }

  if (currentFieldValue === expectedFieldValue) {
    return true;
  }

  return false;
};

export const useCanFormBeApproved = () => {
  const { hasTimescaleType, hasCostType, hasScopeType } =
    useVariationOverview();
  const { isHeUser } = useCurrentUser();

  const { watch } = useFormContext<VariationkChecksForm>();
  const form = watch();

  if (isHeUser) {
    // If user is HE user then they can approved no matter what the state of the questions is
    return true;
  }

  const canBeApproved = [
    doesFieldPassCheck(true, form.variationEvidenceReasonable, true),
    doesFieldPassCheck(
      hasTimescaleType,
      form.proposedExtensionGreaterThanTwoMonths,
      false
    ),
    doesFieldPassCheck(hasCostType, form.allCostsEligibleForGrantFunding, true),
    doesFieldPassCheck(hasScopeType, form.hasScopeMateriallyVaried, false),
  ].every(result => result === true);

  return canBeApproved;
};

export const useThirdPartyReferralLogic = () => {
  const queryParams = useApplicationAndVariationIdOrNull();
  const { createErrorSnackbar } = useLocalSnackbar();

  const thirdPartyReferralDrawer = useReferToThirdPartyDrawer();
  const handleReferToThirdPartyClick = () => {
    if (!queryParams) {
      createErrorSnackbar('Could not get variation id');
      return;
    }

    thirdPartyReferralDrawer.showReferDrawer({
      referralType: 'newReferral',
      referralRecordType: EReferralRecordType.Variation,
      variationId: queryParams.variationId,
    });
  };

  return { thirdPartyReferralDrawer, handleReferToThirdPartyClick };
};

export const useHomesEnglandReferralLogic = () => {
  const { setSelectedItemId } = useVariationContext();
  const queryParams = useApplicationAndVariationIdOrNull();
  const { invalidateTasks } = useInvalidateTasks();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const [referToHe, referToHeStatus] =
    useReferVariationToHomesEnglandMutation();

  const referToHeModal = useReferToHomesEnglandModal();

  const handleReferToHeClick = () => {
    referToHeModal.showReferToHomesEnglandModal();
  };

  const handleReferToHeConfirmClick = (referralComments: string) => {
    if (!queryParams) {
      createErrorSnackbar('Could not get variation data');
      return;
    }

    referToHe({
      ...queryParams,
      referralComments,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Variation has been referred to Homes England');
        invalidateTasks();
        referToHeModal.hideReferToHomesEnglandModal();
        setSelectedItemId(EVariationSection.Overview);
      });
  };

  return {
    referToHeModal,
    handleReferToHeClick,
    handleReferToHeConfirmClick,
    referToHeStatus,
  };
};

export type DaviesUserButtonsProps = {
  handleSave: (onSuccess: () => void) => void;
  saveStatus: { isLoading: boolean };
};

export const useSaveLogic = (
  handleSave: DaviesUserButtonsProps['handleSave']
) => {
  const { createSuccessSnackbar } = useLocalSnackbar();
  const handleSaveClick = () => {
    handleSave(() => {
      createSuccessSnackbar('Variation checks saved');
    });
  };

  return { handleSaveClick };
};

export const useRejectLogic = () => {
  const rejectModal = useRejectModal();
  const handleRejectClick = () => {
    rejectModal.showRejectModal();
  };

  return { rejectModal, handleRejectClick };
};
