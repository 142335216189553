import { Typography } from '@mui/material';
import { ApplicationDetailsGridRow } from 'pages/applicationPage/content/applicationDetails/components';

export const ApplicationDetailsSectionHeader = ({
  title,
}: {
  title: string;
}) => {
  return (
    <ApplicationDetailsGridRow
      col1={<HeaderText value={title} />}
      col2={null}
      col3={<HeaderText value="Status" />}
      hasBorder={false}
    />
  );
};

const HeaderText = ({ value }: { value: string }) => (
  <Typography fontWeight={700}>{value}</Typography>
);
