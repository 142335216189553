import { useSaveIneligibleValuesMutation } from 'api/application/worksPackageApi';
import {
  ECostScheduleParentType,
  IneligibleForm,
} from 'common/components/costSchedule/forms';
import { useFormWrapperDependencies } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CostsScheduleSection/forms/utils';
import { IIneligibleForm } from 'types/applications/ApplicationCostScheduleTypes';

export const IneligibleFormWrapper = () => {
  const {
    applicationId,
    costSchedulePermissionCheck,
    data,
    handleCancelClick,
    handleUnwrappedSaveResult,
  } = useFormWrapperDependencies();

  const [save, saveStatus] = useSaveIneligibleValuesMutation();

  const saveFn = (formData: IIneligibleForm) => {
    if (!costSchedulePermissionCheck()) {
      return;
    }

    handleUnwrappedSaveResult(save({ applicationId, formData }).unwrap());
  };

  if (!data) {
    return null;
  }

  return (
    <IneligibleForm
      parentType={ECostScheduleParentType.WorksPackage}
      data={data.current}
      handleCancelClick={handleCancelClick}
      saveFn={saveFn}
      saveStatus={saveStatus}
    />
  );
};
