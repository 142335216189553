export enum ERegion {
  EastMidlands = 1,
  EastOfEngland,
  London,
  NorthEast,
  NorthWest,
  SouthEast,
  SouthWest,
  WestMidlands,
  YorkshireAndTheHumber,
}
