import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useCompletePaymentRequestReferralMutation } from 'api/application/paymentRequestApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { useClosingPaymentRequestContext } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Closing/context';
import {
  IPaymentRequestCompleteReferral,
  IPaymentRequestCompleteReferralRequest,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { EReferralStatusLanguage } from 'types/applications/ReferralTypes';
import { extractErrorMessages } from 'util/ApiUtils';

const _formId = 'complete-referral';

export const ClosingPaymentRequestCompleteReferralModal = ({
  referralId,
  onConfirm,
  onCancel,
}: {
  referralId: string;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const { createSuccessSnackbar, createErrorSnackbar, createInfoSnackbar } =
    useLocalSnackbar();

  const form = useForm<IPaymentRequestCompleteReferral>({
    defaultValues: { completeReason: '' },
  });
  const { handleSubmit, control } = form;

  const [completeReferral, completeReferralState] =
    useCompletePaymentRequestReferralMutation();

  const { paymentRequestReferralsQuery } = useClosingPaymentRequestContext();
  const referral = paymentRequestReferralsQuery.data?.results.find(
    r => r.id === referralId
  );

  const onSubmit = async (formData: IPaymentRequestCompleteReferral) => {
    if (!selectedItem?.id) {
      createErrorSnackbar(`Error submitting. selected item Id not found`);
      return;
    }

    const completeReferralRequest: IPaymentRequestCompleteReferralRequest = {
      applicationId: applicationId,
      paymentRequestId: selectedItem.id,
      referralId,
      ...formData,
    };

    try {
      await completeReferral(completeReferralRequest)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Referral successfully Completed.`);
          onConfirm();
        })
        .catch(error => {
          createErrorSnackbar(extractErrorMessages(error));
        });
    } catch (err) {
      createErrorSnackbar(`Failed to complete referral`);
    }
  };

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>Complete Referral</DialogTitle>
      <Divider />
      <DialogContent>
        {referral !== undefined ? (
          <Box
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: 'grey.100',
              borderRadius: 2,
              borderColor: 'grey.300',
              borderWidth: 1,
              borderStyle: 'solid',
              display: 'flex',
              flexDirection: 'column',
              rowGap: 1,
            }}
          >
            <DataRow label="Reason" value={referral.reason} />
            <DataRow
              label="Status"
              value={EReferralStatusLanguage[referral.status]}
            />
          </Box>
        ) : null}

        <FormProvider {...form}>
          <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
            <Box pt={1}>
              <Controller
                control={control}
                name="completeReason"
                rules={{ required: 'Please enter a Completion Reason' }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Completion Reason"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </form>
        </FormProvider>
        <Alert severity="info">
          You are going to mark the selected referral as Complete. Are you sure
          you want to proceed?
        </Alert>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ px: 3, py: 2, justifyContent: 'space-between' }}>
        <Box>
          <Button
            variant="outlined"
            onClick={() => {
              navigator.clipboard.writeText(form.getValues().completeReason);
              createInfoSnackbar('Completion Reason copied to clipboard.');
            }}
          >
            Copy to clipboard
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            form={_formId}
            loading={completeReferralState.isLoading}
          >
            Complete Referral
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const DataRow = ({ label, value }: { label: string; value: string }) => {
  return (
    <Grid container flexWrap={'nowrap'}>
      <Typography fontWeight={600} sx={{ flexBasis: '15%', flexShrink: 0 }}>
        {label}
      </Typography>
      <Typography sx={{ flexGrow: 1 }}>{value}</Typography>
    </Grid>
  );
};
