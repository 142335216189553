import { ReferralsGrid } from 'common/components/referrals';
import { PaymentReferralsGridTitle } from 'pages/applicationPage/content/liveProject/common/paymentReferralGrid/PaymentReferralsGridTitle';
import {
  mapPaymentRequestHeReferrals,
  useInvalidatePaymentRequestReferrals,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/utils';
import { IPaymentRequestReferral } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { EReferralRecordType } from 'types/applications/ReferralTypes';

interface IPaymentReferralGridProps {
  selectedItemId: string;
  heReferrals: IPaymentRequestReferral[];
  showReferralsSection: () => void;
}

export const PaymentReferralGrid = ({ selectedItemId, heReferrals, showReferralsSection }: IPaymentReferralGridProps) => {

  const { invalidatePaymentRequestReferrals } =
  useInvalidatePaymentRequestReferrals();

  return (
    <ReferralsGrid
      referralRecordType={EReferralRecordType.PaymentRequest}
      paymentRequestId={selectedItemId}
      heReferrals={mapPaymentRequestHeReferrals(heReferrals)}
      onCompleteHeReferralSuccess={invalidatePaymentRequestReferrals}
      title={
        <PaymentReferralsGridTitle
          showReferralsSection={showReferralsSection}
        />
    }
  />
  )
}
