import { Button, Typography } from '@mui/material';
import {
  useDeleteAssessorMutation,
  useGetAssessorQuery,
} from 'api/administration/assessorPanelList';
import { Loading } from 'common/components/loading/Loading';
import { useLocalSnackbar } from 'hooks';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IDeleteAssessorPanelListDialogProps {
  isShowing: boolean;
  selectedAssessorId: number;
  onConfirm: () => void;
  onCancel: () => void;
}

export function DeleteAssessorPanelListDialog({
  isShowing,
  selectedAssessorId,
  onConfirm,
  onCancel,
}: IDeleteAssessorPanelListDialogProps) {
  const { data: assessorData, isLoading } = useGetAssessorQuery(
    selectedAssessorId.toString()
  );
  const [deleteAssessor] = useDeleteAssessorMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onDeleteAssessor = async () => {
    try {
      await deleteAssessor(selectedAssessorId.toString());
      createSuccessSnackbar('Successfully deleted assessor');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to delete assessor`);
    }
  };

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open={isShowing}>
      <StyledDialogTitle>
        {`Are you sure you want to delete this assessor: ${assessorData?.name}?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDeleteAssessor}>
          Delete assessor
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
