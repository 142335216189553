import { Box, Stack, Typography } from '@mui/material';

interface IProjectDateBoxProps {
  title: string;
  date: string;
  additionalInfo?: string;
  projectDateBoxStatus?: ProjectDateBoxStatus;
}

type ProjectDateBoxStatus = 'warning' | 'success';

export const ProjectDateBox = ({
  title,
  date,
  additionalInfo,
  projectDateBoxStatus,
}: IProjectDateBoxProps) => {
  const boxBackgroundColor = (status?: ProjectDateBoxStatus) => {
    switch (status) {
      case 'warning':
        return '#FFBF00';
      case 'success':
        return '#CCE2D8';
      default:
        return '#CCE2D8';
    }
  };

  const boxBorderColor = (status?: ProjectDateBoxStatus) => {
    switch (status) {
      case 'warning':
        return '#938200';
      case 'success':
        return '#005A30';
      default:
        return '#005A30';
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        paddingTop: 3,
        borderRadius: 1,
        borderLeft: '5px solid ' + boxBorderColor(projectDateBoxStatus),
        background: boxBackgroundColor(projectDateBoxStatus),
      }}
    >
      <Stack rowGap={2}>
        <Typography variant="h2" fontWeight={600} fontSize="1em">
          {title}
        </Typography>
        <Box>
          <Typography variant="h2" fontWeight={600} pb={1}>
            {date}
          </Typography>
          {additionalInfo !== undefined && (
            <Typography variant="body1" color={'grey.700'} fontSize="0.7em">
              {additionalInfo}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
