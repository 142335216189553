import { LinearProgress, Typography } from '@mui/material';
import { useApproveOrRejectMutation } from 'api/application/worksPackageApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { useLocalSnackbar, useModalState } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EWorksPackageApprovalStatus } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { extractErrorMessages } from 'util/ApiUtils';

export const useApproveModal = () => {
  const { isShowing, showModal, hideModal } = useModalState();

  const renderApproveModal = () => {
    return (
      <ApproveConfirmationModal isShowing={isShowing} handleClose={hideModal} />
    );
  };

  return {
    showApproveModal: showModal,
    hideApproveModal: hideModal,
    renderApproveModal,
  };
};

const ApproveConfirmationModal = ({
  isShowing,
  handleClose,
}: {
  isShowing: boolean;
  handleClose: () => void;
}) => {
  const { applicationId } = useApplicationContext();
  const [approve, approveStatus] = useApproveOrRejectMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const handleClick = async () => {
    try {
      await approve({
        applicationId,
        status: EWorksPackageApprovalStatus.Approved,
      })
        .unwrap()
        .then(_ => {
          createSuccessSnackbar('Works package has been successfully approved');
          handleClose();
        })
        .catch(error => {
          createErrorSnackbar(extractErrorMessages(error));
        });
    } catch (err) {
      createErrorSnackbar(`Failed to approve`);
    }
  };

  return (
    <ConfirmationModal
      isShowing={isShowing}
      confirmButtonText="Yes, Approve"
      cancelButtonText="Cancel"
      onConfirm={handleClick}
      onCancel={handleClose}
      dialogActionsSx={{ justifyContent: 'center', mb: 2 }}
      areButtonsLoading={approveStatus.isLoading}
    >
      {approveStatus.isLoading ? <LinearProgress /> : null}

      <Typography variant="body1" pt={1} fontWeight={600} textAlign={'center'}>
        Are you sure you want to approve this works package?
      </Typography>

      <AlertForQueryErrorOrNull
        isError={approveStatus.isError}
        error={approveStatus.error}
      />
    </ConfirmationModal>
  );
};
