import { Box, Chip, Typography } from '@mui/material';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context/ApplicationEligibilityContext';

export const ApplicationEligibilityReferrals = () => {
  const { referrals, showReferralsSection, isReferralsSectionShowing } =
    useApplicationEligibilityContext();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h3" fontWeight={600} fontSize="1em">
        Referrals
      </Typography>

      <Box>
        <Chip
          label={`${referrals.length} ${
            referrals.length > 0 ? isReferralsSectionShowing ? '' : '...Click here to view' : ''
          }`}
          color={'warning'}
          variant={isReferralsSectionShowing ? 'filled' : 'outlined'}
          sx={{ cursor: 'pointer', transition: 'all 250ms linear' }}
          onClick={() => {
            showReferralsSection();
          }}
        />
      </Box>
    </Box>
  );
};
