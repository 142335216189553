import {
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
  Theme,
} from "@mui/material";

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    first: true;
    second: true;
  }
}

export const muiChipTheme: {
  defaultProps?: ComponentsProps["MuiChip"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiChip"];
  variants?: ComponentsVariants["MuiChip"];
} = {
  variants: [
    // <Chip variant="first" />
    {
      props: { variant: "first" },
      style: ({ theme }) => ({
        background: theme.palette.chipPrimary?.main,
        color: "white",
      }),
    },
    // <Chip variant="second" />
    {
      props: { variant: "second" },
      style: ({ theme }) => ({
        background: theme.palette.chipSecondary?.main,
        color: "white",
      }),
    },
  ],
  styleOverrides: {
    root: {
      padding: "0.5rem",
    },
  },
};
