import { useRef, useEffect } from 'react';
import { MenuItem } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { IHasOrderProp, ISelectOption } from 'types/GeneralTypes';

export const getGuid = () => uuidv4();

export const getUkDateStringFromJsonDateString = (jsonDateString: string) => {
  return new Date(jsonDateString).toLocaleDateString('en-GB');
};

export const getUkDateTimeStringFromJsonDateString = (
  jsonDateString: string
) => {
  return new Date(jsonDateString).toLocaleString('en-GB');
};

export const getPrettyDateStringFromJsonDateString = (
  jsonDateString: string
) => {
  return new Date(jsonDateString).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
};

export const getGbpAmount = ({
  value,
  showIfValueNullOrUndefined = '',
  showCurrencySymbol = true,
}: {
  value?: number;
  showIfValueNullOrUndefined?: string;
  showCurrencySymbol?: boolean;
}) => {
  if (value === null || value === undefined) {
    return showIfValueNullOrUndefined;
  }

  return `${showCurrencySymbol ? '£' : ''}${value.toLocaleString()}`;
};

export const sortByOrderAsc = (a: IHasOrderProp, b: IHasOrderProp) => {
  if (a.order < b.order) {
    return -1;
  }

  if (a.order > b.order) {
    return 1;
  }

  return 0;
};

export const sortByValueAsc = (a: { value: string }, b: { value: string }) => {
  if (a.value.toLowerCase() < b.value.toLowerCase()) {
    return -1;
  }

  if (a.value.toLowerCase() > b.value.toLowerCase()) {
    return 1;
  }

  return 0;
};

export const getMenuItemConfig = (
  title: string,
  type: number,
  test?: boolean
) => {
  if (test !== undefined && !test) {
    return null;
  }

  return { title, type, test: test === undefined ? true : test };
};

export const getMenuItems = (
  menuItemConfigs: ReturnType<typeof getMenuItemConfig>[],
  handleMenuItemClick: (type: number) => void
) => {
  return menuItemConfigs.map((config, i) =>
    config && config.test ? (
      <MenuItem key={i} onClick={() => handleMenuItemClick(config.type)}>
        {config.title}
      </MenuItem>
    ) : null
  );
};

export const numToStr = (num: number) => num.toString();

export const tupleToSelectOption = ([key, value]: [
  string,
  string
]): ISelectOption => ({
  label: value,
  value: key.toString(),
});

export const useDocumentTitle = (
  title: string,
  prevailOnUnmount: boolean = false
) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [prevailOnUnmount]
  );
};

export const isNumeric = (val?: string | number | null) => {
  if (val === undefined) {
    return false;
  }

  if (val === null) {
    return false;
  }

  if (typeof val === 'number') {
    return true;
  }

  return !isNaN(parseFloat(val)) && !isNaN(+val);
};

type a11yPropsForTabProps = {
  value: string;
  currentSelectedTab: string;
};

export const a11yPropsForTab = ({
  value,
  currentSelectedTab,
}: a11yPropsForTabProps) => {
  return {
    id: `tab-${value}`,
    'aria-controls': `tabpanel-${value}`,
    'aria-selected': value === currentSelectedTab,
  };
};

export const hasDecimal = (numberToCheck: number) =>
  numberToCheck - Math.floor(numberToCheck) !== 0;

export function isOfType<
  GenericType extends string | number,
  Union extends { type: GenericType },
  SpecificType extends GenericType
>(val: SpecificType) {
  return (obj: Union): obj is Extract<Union, { type: SpecificType }> =>
    obj.type === val;
}

// Get a percentage such as 7%
export function getPercentage(num1: number, num2: number) {
  return (num1 / num2) * 100
}

// Get a percentage of a number such as 15% of 100,000
export function getPercentageOfNumber(number: number, percentage: number) {
  return (percentage / 100) * number
}

export function getPercentageDifference(originalNumber: number, num2: number) {
  const difference = originalNumber - num2;
  const percentage = (difference / originalNumber) * 100;
  return Number(percentage.toFixed(2));
}
