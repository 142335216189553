import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { IHoldReasons } from 'types/applications/HoldTypes';
import { customPrepareHeaders } from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/`;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
  credentials: 'include',
});

export const holdReasonsApi = createApi({
  reducerPath: 'holdReasonsApi',
  baseQuery,
  endpoints: builder => ({
    getApplicationOnHoldReasons: builder.query<
      Array<IHoldReasons>,
      EOnHoldType
    >({
      query: holdType => `hold/reasons/${holdType}`,
    }),
  }),
});

export const { useGetApplicationOnHoldReasonsQuery } = holdReasonsApi;
