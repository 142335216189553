import {
  PaletteColorOptions,
  PaletteOptions,
  PaletteColor,
} from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    chipPrimary?: PaletteColor;
    chipSecondary?: PaletteColor;
    warning: PaletteColor;
    increasePercentage?: PaletteColor;
    decreasePercentage?: PaletteColor;
    pieChartOne: PaletteColor;
    pieChartTwo?: PaletteColor;
    pieChartThree?: PaletteColor;
    pieChartFour?: PaletteColor;
    pieChartFive: PaletteColor;
    textDark: PaletteColor;
    textMid: PaletteColor;
    textLight: PaletteColor;
  }
  interface PaletteOptions {
    chipPrimary?: PaletteColorOptions;
    chipSecondary?: PaletteColorOptions;
    warning?: PaletteColorOptions; 
    increasePercentage?: PaletteColorOptions;
    decreasePercentage?: PaletteColorOptions;
    pieChartOne: PaletteColorOptions;
    pieChartTwo?: PaletteColorOptions;
    pieChartThree?: PaletteColorOptions;
    pieChartFour?: PaletteColorOptions;
    pieChartFive: PaletteColorOptions;
    textDark: PaletteColorOptions;
    textMid: PaletteColorOptions;
    textLight: PaletteColorOptions;
  }
}

export const palette: PaletteOptions = {
  primary: {
    main: '#114b5f',
  },
  secondary: {
    main: '#de633d',
  },
  warning: {
    main: '#B65302',
  },
  chipPrimary: {
    main: '#00ccff',
    contrastText: 'white',
  },
  chipSecondary: {
    main: '#003947',
    contrastText: 'white',
  },
  increasePercentage: {
    main: '#e1f7e8',
  },
  decreasePercentage: {
    main: '#edc4c0',
  },
  // TODO: Pie Chart colours to be determined
  pieChartOne: {
    main: '#6fb3a6d2',
  },
  pieChartTwo: {
    main: '#',
  },
  pieChartThree: {
    main: '#',
  },
  pieChartFour: {
    main: '#',
  },
  pieChartFive: {
    main: '#',
  },
  // Text Colors
  textDark: {
    main: '#111111',
  },
  textMid: {
    main: '#787B7A',
  },
  textLight: {
    main: '#F1F2F2',
  },
};
