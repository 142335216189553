import { TasksGrid } from 'common/components/taskGrid';
import {
  CurrentUserTasksDataGridNavigation,
  CurrentUserTasksGridContextProvider,
  useCurrentUserTasksGridContext,
} from 'pages/currentUserTasksPage';

export const CurrentUserTasksDataGrid = () => {
  return (
    <CurrentUserTasksGridContextProvider>
      <TasksGridMain />
    </CurrentUserTasksGridContextProvider>
  );
};

const TasksGridMain = () => {
  const {
    query,
    state: {
      gridState: { take, skip },
    },
    gridStateChanged,
  } = useCurrentUserTasksGridContext();
  return (
    <TasksGrid
      config={{
        type: 'CurrentUserTasks',
        query,
        navigationComponent: <CurrentUserTasksDataGridNavigation />,
        pagination: {
          gridStateChanged,
          take,
          skip,
        },
      }}
    />
  );
};
