import { useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import {
  useGetThirdPartyReferrersQuery,
  useReferToThirdPartyGeneralMutation,
  useUpdateThirdPartyReferralGeneralMutation,
} from 'api/application/referralApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { useReferToThirdPartyModal } from 'common/components/referrals/thirdPartyReferrals/ReferToThirdPartyModal';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useLocalSnackbar, useModalState } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import {
  EThirdPartyReferrerTypeLanguage,
  IReferToThirdPartyForm,
  IThirdPartyReferral,
  EReferralRecordType,
} from 'types/applications/ReferralTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';
import { getDateOnlyIsoString } from 'util/DateUtils';

const _formId = 'refer-to-third-party-form';

export const sortByNameAsc = (a: { name: string }, b: { name: string }) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }

  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }

  return 0;
};

const _minDate = new Date(2023, 6, 1);

const getDisplayName = (referralRecordType: EReferralRecordType) => {
  switch (referralRecordType) {
    case EReferralRecordType.WorksPackage:
      return 'Works Package';

    case EReferralRecordType.Variation:
      return 'Variation';

    default:
      return '';
  }
};

type ReferToThirdPartyDrawerConfig =
  | {
      referralType: 'newReferral';
      referralRecordType: EReferralRecordType.WorksPackage;
    }
  | {
      referralType: 'newReferral';
      referralRecordType: EReferralRecordType.PaymentRequest;
      paymentRequestId: string;
    }
  | {
      referralType: 'newReferral';
      referralRecordType: EReferralRecordType.Variation;
      variationId: string;
    }
  | {
      referralType: 'editReferral';
      referralRecordType: EReferralRecordType.WorksPackage;
      referral: IThirdPartyReferral;
    }
  | {
      referralType: 'editReferral';
      referralRecordType: EReferralRecordType.PaymentRequest;
      paymentRequestId: string;
      referral: IThirdPartyReferral;
    }
  | {
      referralType: 'editReferral';
      referralRecordType: EReferralRecordType.Variation;
      variationId: string;
      referral: IThirdPartyReferral;
    };

export const useReferToThirdPartyDrawer = () => {
  const { applicationId } = useApplicationContext();

  const {
    isShowing: isReferDrawerShowing,
    showModal: showReferDrawer,
    hideModal: hideReferDrawer,
  } = useModalState();

  const [config, setConfig] = useState<ReferToThirdPartyDrawerConfig | null>(
    null
  );

  const form = useForm<IReferToThirdPartyForm>({
    defaultValues: {
      thirdPartyReferrerId: '',
      startDate: '',
      endDate: '',
      cost: '',
      description: '',
    },
  });
  const { handleSubmit, control, reset, watch } = form;

  const { createErrorSnackbar, createSuccessSnackbar } = useLocalSnackbar();

  const [thirdPartyRefrerrerId, startDate] = watch([
    'thirdPartyReferrerId',
    'startDate',
  ]);

  const { isLoading, isSuccess, data, isError, error } =
    useGetThirdPartyReferrersQuery();

  const referralOptions =
    data
      ?.map(item => {
        return {
          value: item.id,
          label: `${item.name} (${EThirdPartyReferrerTypeLanguage[item.type]})`,
          name: item.name,
        };
      })
      .sort(sortByNameAsc) ?? [];

  const getThirdPartyReferrerIdFromCompanyName = (companyName: string) => {
    return referralOptions.find(item => item.name === companyName)?.value ?? '';
  };

  const handleShowReferDrawer = (config: ReferToThirdPartyDrawerConfig) => {
    setConfig(config);
    if (config.referralType === 'editReferral') {
      const referral = config.referral;
      const dataToEdit: IReferToThirdPartyForm = {
        thirdPartyReferrerId: getThirdPartyReferrerIdFromCompanyName(
          referral.companyName
        ),
        startDate: referral.startDate,
        endDate: referral.endDate,
        cost: referral.cost,
        description: referral.description,
      };
      reset(dataToEdit);
    }
    showReferDrawer();
  };

  const handleHideReferDrawer = () => {
    setConfig(null);
    hideReferDrawer();
    reset();
  };

  const referToThirdPartyModal = useReferToThirdPartyModal();
  const [initialReferral, initialReferralStatus] =
    useReferToThirdPartyGeneralMutation();
  const [updateReferral, updateReferralStatus] =
    useUpdateThirdPartyReferralGeneralMutation();

  const onSubmit = async (formData: IReferToThirdPartyForm) => {
    if (!config) {
      createErrorSnackbar(
        'Could not get configuration. Please try again later.'
      );
      return;
    }

    switch (config.referralType) {
      case 'newReferral':
        const getReferConfig = (): Parameters<typeof initialReferral>[0] => {
          const base = {
            applicationId,
            formData,
          };
          switch (config.referralRecordType) {
            case EReferralRecordType.WorksPackage:
              return {
                ...base,
                type: EReferralRecordType.WorksPackage,
              };
            case EReferralRecordType.PaymentRequest:
              return {
                ...base,
                type: EReferralRecordType.PaymentRequest,
                paymentRequestId: config.paymentRequestId,
              };

            case EReferralRecordType.Variation:
              return {
                ...base,
                type: EReferralRecordType.Variation,
                variationId: config.variationId,
              };
          }
        };
        referToThirdPartyModal.showReferModal({
          handleSave: (onThirdPartyReferralSuccess: () => void) => {
            initialReferral(getReferConfig())
              .unwrap()
              .then(() => {
                createSuccessSnackbar('Referral details saved');
                onThirdPartyReferralSuccess();
                handleHideReferDrawer();
              });
          },
        });
        break;

      case 'editReferral':
        const getMutationConfig = (): Parameters<typeof updateReferral>[0] => {
          const base = {
            applicationId,
            thirdPartyReferralId: config.referral.id,
            formData,
          };
          switch (config.referralRecordType) {
            case EReferralRecordType.WorksPackage:
              return {
                ...base,
                type: EReferralRecordType.WorksPackage,
              };
            case EReferralRecordType.PaymentRequest:
              return {
                ...base,
                type: EReferralRecordType.PaymentRequest,
                paymentRequestId: config.paymentRequestId,
              };

            case EReferralRecordType.Variation:
              return {
                ...base,
                type: EReferralRecordType.Variation,
                variationId: config.variationId,
              };
          }
        };

        updateReferral(getMutationConfig())
          .unwrap()
          .then(() => {
            createSuccessSnackbar('Referral details saved');
            handleHideReferDrawer();
          });

        break;
    }
  };

  const renderReferDrawer = () => {
    if (!config) {
      return null;
    }

    return (
      <>
        <StyledDrawer anchor="right" open={isReferDrawerShowing}>
          <Box>
            <DialogTitle component="div">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h1" component="span">
                  Refer {getDisplayName(config.referralRecordType)}
                </Typography>
                <IconButton
                  onClick={() => handleHideReferDrawer()}
                  aria-label="Close Drawer"
                  name="Close Drawer"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <AlertForQueryErrorOrNull isError={isError} error={error} />

              {isLoading ? (
                <Box py={2}>
                  <FormSkeleton />
                </Box>
              ) : null}

              {isSuccess ? (
                <>
                  <h3>Referral Details</h3>

                  <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
                    {referralOptions.length > 0 ? (
                      <Controller
                        control={control}
                        name="thirdPartyReferrerId"
                        rules={{ required: 'Company is required' }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            select
                            fullWidth
                            label="Company"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          >
                            {referralOptions.map((option, i) => (
                              <MenuItem key={i} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    ) : null}

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name="startDate"
                          rules={{
                            validate: value => {
                              if (!value) {
                                return 'Start Date is required';
                              }
                              try {
                                const dt = parseISO(value);
                                if (dt < _minDate) {
                                  return `Earliest available date is ${getUkDateStringFromJsonDateString(
                                    _minDate.toISOString()
                                  )}`;
                                }
                              } catch {
                                return 'Error parsing date';
                              }

                              return true;
                            },
                          }}
                          render={({
                            field: { onChange, value, ...field },
                            fieldState,
                          }) => {
                            return (
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  {...field}
                                  format="dd/MM/yyyy"
                                  label="Start Date"
                                  minDate={_minDate}
                                  value={
                                    value && value !== ''
                                      ? parseISO(value)
                                      : null
                                  }
                                  onChange={val => {
                                    if (typeof val === 'string') {
                                      return '';
                                    }

                                    return val
                                      ? onChange(getDateOnlyIsoString(val))
                                      : null;
                                  }}
                                  slotProps={{
                                    textField: {
                                      sx: { mr: '1rem' },
                                      error: !!fieldState.error,
                                      helperText: fieldState.error?.message,
                                      fullWidth: true,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name="endDate"
                          rules={{
                            validate: value => {
                              if (!value) {
                                return 'End Date is required';
                              }

                              try {
                                const dt = parseISO(value);
                                if (dt < _minDate) {
                                  return `Earliest available date is ${getUkDateStringFromJsonDateString(
                                    _minDate.toISOString()
                                  )}`;
                                }

                                if (startDate) {
                                  const startDateDt = parseISO(startDate);
                                  if (dt <= startDateDt) {
                                    return 'End Date must be later than Start Date';
                                  }
                                }
                              } catch {
                                return 'Error parsing date';
                              }

                              return true;
                            },
                          }}
                          render={({
                            field: { onChange, value, ...field },
                            fieldState,
                          }) => {
                            return (
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  {...field}
                                  format="dd/MM/yyyy"
                                  label="End Date"
                                  value={
                                    value && value !== ''
                                      ? parseISO(value)
                                      : null
                                  }
                                  onChange={val => {
                                    if (typeof val === 'string') {
                                      return '';
                                    }

                                    return val
                                      ? onChange(getDateOnlyIsoString(val))
                                      : null;
                                  }}
                                  slotProps={{
                                    textField: {
                                      sx: { mr: '1rem' },
                                      error: !!fieldState.error,
                                      helperText: fieldState.error?.message,
                                      fullWidth: true,
                                    },
                                  }}
                                  minDate={_minDate}
                                />
                              </LocalizationProvider>
                            );
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name="cost"
                          rules={{
                            required: 'Required',
                            min: {
                              value: 0,
                              message: 'Must be greater than zero',
                            },
                            pattern: {
                              value: /^-?\d+(,\d{3})*(\.\d{1,2})?$/,
                              message: 'Please enter a number',
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="Cost (Inc VAT)"
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="description"
                          rules={{
                            required: 'Required',
                            maxLength: {
                              value: 1000,
                              message:
                                '1000 characters is the maximum allowed.',
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              multiline
                              rows={5}
                              label="Reason for Referral"
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </form>

                  <ThirdPartySummary
                    thirdPartyReferrerId={thirdPartyRefrerrerId}
                  />
                </>
              ) : null}

              {config.referralType === 'editReferral' ? (
                <AlertForQueryErrorOrNull
                  isError={updateReferralStatus.isError}
                  error={updateReferralStatus.error}
                  sx={{ mt: 3 }}
                />
              ) : null}
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={hideReferDrawer}>
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  form={_formId}
                  loading={
                    config.referralType === 'editReferral' &&
                    updateReferralStatus.isLoading
                  }
                >
                  {config.referralType === 'newReferral'
                    ? 'Refer Request'
                    : config.referralType === 'editReferral'
                    ? 'Save'
                    : 'Unknown config type'}
                </LoadingButton>
              </Grid>
            </StyledDrawerActions>
          </Box>
        </StyledDrawer>
        {referToThirdPartyModal.renderReferModal({
          referStatus: initialReferralStatus,
        })}
      </>
    );
  };

  return {
    showReferDrawer: handleShowReferDrawer,
    hideReferDrawer: handleHideReferDrawer,
    renderReferDrawer,
  };
};

const ThirdPartySummary = ({
  thirdPartyReferrerId,
}: {
  thirdPartyReferrerId: number | string;
}) => {
  const { isSuccess, data } = useGetThirdPartyReferrersQuery();

  if (!thirdPartyReferrerId) {
    return null;
  }

  if (!isSuccess) {
    return null;
  }

  const referrer = data.find(a => a.id === thirdPartyReferrerId);
  if (!referrer) {
    return null;
  }

  return thirdPartyReferrerId ? (
    <RoundBorderBox sx={{ p: 3 }}>
      <Typography variant="body1" fontWeight={600} fontSize={'1.1em'} mb={2}>
        Company Details
      </Typography>

      <Stack gap={2}>
        <Box>
          <DataItem label="Company" value={referrer.name} />
        </Box>
        <Divider />
        <Box>
          <DataItem
            label="Type"
            value={EThirdPartyReferrerTypeLanguage[referrer.type]}
          />
        </Box>
        <Divider />
        <Stack direction={'row'}>
          <DataItem
            label="Email"
            value={referrer.email}
            sx={{ flexBasis: '50%' }}
          />
          <DataItem
            label="Phone"
            value={referrer.phone}
            sx={{ flexBasis: '50%' }}
          />
        </Stack>
      </Stack>
    </RoundBorderBox>
  ) : null;
};

const DataItem = ({
  label,
  value,
  sx,
}: {
  label: string;
  value: string;
  sx?: SxProps;
}) => {
  return (
    <Box sx={sx}>
      <Typography variant="body1" fontWeight={'600'}>
        {label}
      </Typography>
      <Typography variant="body1" sx={{ overflowWrap: 'anywhere' }}>
        {value}
      </Typography>
    </Box>
  );
};
