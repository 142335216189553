import {
  IDutyOfCareCounterSignedStatus,
  IDutyOfCareSentStatus,
  IDutyOfCareSignedStatus,
} from 'pages/applicationPage/content/dutyOfCare/DutyOfCareTypes';
import { getGuid } from 'util/AppUtils';

export const DutyOfCareSentStatus: IDutyOfCareSentStatus = {
  sentEvidenceFile: {
    name: 'Sent Duty of Care File',
    id: getGuid(),
    extension: 'pdf',
  },
  dutyOfCareSent: true,
  dateSent: new Date(),
  applicationId: '123',
};

export const DutyOfCareSentSignedStatus: IDutyOfCareSignedStatus = {
  signedDutyOfCareFile: {
    name: 'Signed Duty of Care File',
    id: getGuid(),
    extension: 'pdf',
  },
  dutyOfCareSigned: false,
  dateSigned: null,
  applicationId: '123',
};

export const dutyOfCareSentCounterSignedStatus: IDutyOfCareCounterSignedStatus = {
  counterSignedDutyOfCareFile: {
    name: 'Signed Duty of Care File',
    id: getGuid(),
    extension: 'pdf',
  },
  dutyOfCareCounterSigned: false,
  dateCounterSigned: null,
  applicationId: '123',
};