import { DialogContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import {
  costScheduleEditFormId,
  getAmountString,
} from 'common/components/costSchedule';
import {
  DescriptionField,
  EligibleCostField,
  FormActions,
  Label,
  OriginalDataAccordion,
  shouldValidateDescription,
} from 'common/components/costSchedule/forms/common';
import {
  ECostScheduleParentType,
  ICostScheduleFormProps,
} from 'common/components/costSchedule/forms/types';
import { getCostScheduleDescription } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections';
import {
  IInstallationForm,
  FieldLabels,
  ECostsScheduleSection,
} from 'types/applications/ApplicationCostScheduleTypes';

export const InstallationForm = ({
  parentType,
  data,
  originalData,
  handleCancelClick,
  saveFn,
  saveStatus,
}: ICostScheduleFormProps<IInstallationForm>) => {
  const array = [data, originalData];
  const form = useForm<IInstallationForm>({
    defaultValues: {
      newCladdingAmount: getAmountString(data.newCladdingAmount),
      newCladdingDescription: getCostScheduleDescription(
        array,
        'newCladdingDescription'
      ),
      otherEligibleWorkToExternalWallAmount: getAmountString(
        data.externalWorksAmount
      ),
      otherEligibleWorkToExternalWallDescription: getCostScheduleDescription(
        array,
        'externalWorksDescription'
      ),
      internalMitigationWorksAmount: getAmountString(data.internalWorksAmount),
      internalMitigationWorksDescription: getCostScheduleDescription(
        array,
        'internalWorksDescription'
      ),
    },
  });
  const { handleSubmit, watch } = form;

  const [newCladdingAmount, externalWorksAmount, internalWorksAmount] = watch([
    'newCladdingAmount',
    'otherEligibleWorkToExternalWallAmount',
    'internalMitigationWorksAmount',
  ]);

  const onSubmit = async (formData: IInstallationForm) => {
    saveFn(formData);
  };

  return (
    <>
      <DialogContent>
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
            id={costScheduleEditFormId}
          >
            <Label
              value={
                FieldLabels[ECostsScheduleSection.InstallationOfNewCladding]
                  .newCladding
              }
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="newCladdingAmount"
              descriptionFieldName="newCladdingDescription"
            />

            <EligibleCostField
              fieldName="newCladdingAmount"
              originalAmount={data.newCladdingAmount}
              newAmount={newCladdingAmount}
              isRequired={false}
            />

            <DescriptionField
              fieldName="newCladdingDescription"
              isValidationBasedOnAmountField={
                parentType === ECostScheduleParentType.WorksPackage
                  ? true
                  : false
              }
              shouldValidate={shouldValidateDescription(
                parentType,
                data.newCladdingAmount,
                newCladdingAmount
              )}
              associatedAmountFieldName="newCladdingAmount"
            />

            <Label
              value={
                FieldLabels[ECostsScheduleSection.InstallationOfNewCladding]
                  .external
              }
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="externalWorksAmount"
              descriptionFieldName="externalWorksDescription"
            />

            <EligibleCostField
              fieldName="otherEligibleWorkToExternalWallAmount"
              originalAmount={data.externalWorksAmount}
              newAmount={externalWorksAmount}
              isRequired={false}
            />

            <DescriptionField
              fieldName="otherEligibleWorkToExternalWallDescription"
              isValidationBasedOnAmountField={
                parentType === ECostScheduleParentType.WorksPackage
                  ? true
                  : false
              }
              shouldValidate={shouldValidateDescription(
                parentType,
                data.externalWorksAmount,
                externalWorksAmount
              )}
              associatedAmountFieldName="otherEligibleWorkToExternalWallAmount"
            />

            <Label
              value={
                FieldLabels[ECostsScheduleSection.InstallationOfNewCladding]
                  .internal
              }
            />

            <OriginalDataAccordion
              costSchedule={originalData}
              amountFieldName="internalWorksAmount"
              descriptionFieldName="internalWorksDescription"
            />

            <EligibleCostField
              fieldName="internalMitigationWorksAmount"
              originalAmount={data.internalWorksAmount}
              newAmount={internalWorksAmount}
              isRequired={false}
            />

            <DescriptionField
              fieldName="internalMitigationWorksDescription"
              isValidationBasedOnAmountField={
                parentType === ECostScheduleParentType.WorksPackage
                  ? true
                  : false
              }
              shouldValidate={shouldValidateDescription(
                parentType,
                data.internalWorksAmount,
                internalWorksAmount
              )}
              associatedAmountFieldName="internalMitigationWorksAmount"
            />
          </form>
        </FormProvider>

        <AlertForQueryErrorOrNull
          isError={saveStatus.isError}
          error={saveStatus.error}
        />
      </DialogContent>

      <FormActions
        originalValue={data.installationTotal ?? 0}
        newValues={[
          newCladdingAmount,
          externalWorksAmount,
          internalWorksAmount,
        ]}
        isSaving={saveStatus.isLoading}
        handleCancelClick={handleCancelClick}
      />
    </>
  );
};
