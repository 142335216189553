import { applicationApi } from 'api/application/applicationApi';
import { IGetLiveProjectListResponse, ILiveProjectOverview } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { QueryTags } from 'util/ApiUtils';

export const liveProjectEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getLiveProjectOverview: builder.query<ILiveProjectOverview, string>({
      providesTags: [QueryTags.ApplicationLiveProjectOverview],
      query: applicationId => `${applicationId}/projectlive/overview`,
      keepUnusedDataFor: 0,
    }),
    getLiveProjectReportList: builder.query<
      IGetLiveProjectListResponse,
      string
    >({
      providesTags: [QueryTags.ApplicationLiveProjectList],
      query: applicationId => `${applicationId}/projectlive/list`,
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetLiveProjectOverviewQuery,
  useGetLiveProjectReportListQuery,
} = liveProjectEndpoints;
