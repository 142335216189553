export enum EApplicationTabType {
  Overview = '1',
  TaskList = '2',
  Application = '3',
  Eligibility = '4',
  PreApplicationSupport = '5',
  GrantFunding = '6',
  WorkPackage = '7',
  WorkPackageChecks = '8',
  ProgressReports = '9',
  Completion = '10',
  Documents = '11',
  Payment = '12',
  Signatories = '13',
  Support = '14',
  ProjectPrep = '15',
  DutyOfCare = '16',
  LiveProject = '17',
  Communication = '18',
}

export interface IApplicationDetailsItemGroup {
  title: string | null;
  items: IApplicationDetailsGroupItem[];
  isEnabled?: boolean;
}

interface IApplicationDetailsGroupItem {
  title: string;
  isComplete?: boolean;
  isSelected: boolean;
  onClick: () => void;
}
