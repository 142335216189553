import { faBars, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUserTasksQuery } from 'api/task';
import DaviesGroupLogo from 'assets/Davies-RGB-White.png';
import HomesEnglandLogo from 'assets/HomesEnglandLogo.png';
import { AddTaskIcon } from 'common/components/addTaskIcon';
import { MyAccount } from 'common/components/myAccount/MyAccount';
import { initialState as myTaskListInitialState } from 'common/components/taskGrid/utils/taskGridUtils';
import { useCurrentUserPermissions } from 'hooks';
import { useAppDispatch } from 'state';
import { selectExpanded } from 'state/selectors/navigationBarSelectors';
import { setIsExpanded } from 'state/slices/navigationBarReducer';
import { HeAppBar } from 'styles/HeAppBar';
import { ETaskListConfigType } from 'types/tasks/CmsTaskTypes';

export function Header() {
  const dispatch = useAppDispatch();
  const navBarExpanded = useSelector(selectExpanded);

  const handleDrawerToggle = () => {
    dispatch(setIsExpanded(!navBarExpanded));
  };

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const canUserCreateTasks = doesUserHaveSinglePermission('tasklist.edit');

  return (
    <header>
      <HeAppBar sx={{ flexDirection: 'row' }} elevation={0}>
        <Toolbar
          sx={{
            paddingLeft: 2,
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <IconButton
              size="large"
              color="inherit"
              aria-label="Open navigation drawer"
              name="Open navigation drawer"
              onClick={() => {
                handleDrawerToggle();
              }}
              edge="start"
              sx={{
                pl: 0,
                ml: 0,
                mr: 1,
              }}
            >
              <FontAwesomeIcon icon={faBars} size="xs" fixedWidth />
            </IconButton>
            <Button variant="text">
              <Box
                component="img"
                src={DaviesGroupLogo}
                alt="Davies Group Logo"
                width="100%"
                sx={{
                  maxHeight: '1.5rem',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </Button>
            <Button variant="text">
              <Box
                component="img"
                src={HomesEnglandLogo}
                alt="Homes England Logo"
                width="100%"
                sx={{
                  maxHeight: '1.5rem',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </Button>
            <Typography
              style={{ marginLeft: '1rem' }}
              aria-label="Cladding Safety Scheme Management System"
            >
              Cladding Safety Scheme Management System
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Grid
              display="flex"
              alignItems="center"
              p={({ spacing }) => `0 ${spacing(2)}`}
            >
              <Grid display="flex" alignItems="center" gap={2} flexWrap="wrap">
                <Grid>
                  <BellIcon />
                </Grid>
                {canUserCreateTasks ? (
                  <Grid>
                    <AddTaskIcon />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Box
              display="flex"
              alignItems="center"
              p={({ spacing }) => `0 ${spacing(2)}`}
            >
              <MyAccount />
            </Box>
          </Box>
        </Toolbar>
      </HeAppBar>
    </header>
  );
}

const BellIcon = () => {
  const navigate = useNavigate();

  const query = useGetCurrentUserTasksQuery({
    type: ETaskListConfigType.CurrentUserTasks,
    query: myTaskListInitialState.gridState,
  });

  const badgeCount = query.data?.count ?? 0;

  return (
    <IconButton
      size="small"
      color="inherit"
      onClick={() => navigate('/')}
      aria-label="My tasks"
      name="My tasks"
    >
      <Badge badgeContent={badgeCount} color="error">
        <FontAwesomeIcon icon={faBell} />
      </Badge>
    </IconButton>
  );
};
