import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EProgressReportItemType } from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';

interface ISelectedItem {
  id: string;
  type: EProgressReportItemType;
}

interface IApplicationProjectPrepContext {
  applicationId: string;
  selectedItem: ISelectedItem | null;
  setSelectedItem: (item: ISelectedItem | null) => void;
  readOnly: boolean;
}

const ApplicationProjectPrepContext = createContext<
  IApplicationProjectPrepContext | undefined
>(undefined);

interface IApplicationProjectPrepContextProviderProps {
  children: ReactNode;
}

export const ApplicationProjectPrepContextProvider = ({
  children,
}: IApplicationProjectPrepContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();

  const [selectedItem, setSelectedItem] = useState<ISelectedItem | null>(null);

  const handleSetSelectedItem = (item: ISelectedItem | null) => {
    setSelectedItem(item);
  };

  const readOnly = closed;

  const value = useMemo(
    () => ({
      applicationId,
      selectedItem,
      setSelectedItem: handleSetSelectedItem,
      readOnly,
    }),
    [applicationId, readOnly, selectedItem]
  );

  return (
    <ApplicationProjectPrepContext.Provider value={value}>
      {children}
    </ApplicationProjectPrepContext.Provider>
  );
};

export const useApplicationProjectPrepContext = () => {
  const context = useContext(ApplicationProjectPrepContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationProjectPrepContext must be used within an ApplicationProjectPrepContextProvider'
    );
  }
  return context;
};
