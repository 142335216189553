import { ErrorAlert } from 'common/components/alerts';
import { EScheduleOfWorksSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { useScheduleOfWorksContext } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/context/ScheduleOfWorksContext';
import { CostProfileSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection';
import { LatestPaymentRequestSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/LatestPaymentRequestSection/LatestPaymentRequestSection';
import { ScheduleOfWorksChecksSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/ScheduleOfWorksChecksSection';
import { ScheduleOfWorksOverviewSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/ScheduleOfWorksOverviewSection';

export const ScheduleOfWorksSectionOutlet = () => {
  const { selectedItemId } = useScheduleOfWorksContext();

  switch (selectedItemId) {
    case EScheduleOfWorksSection.Overview:
      return <ScheduleOfWorksOverviewSection />;

    case EScheduleOfWorksSection.CostProfile:
      return <CostProfileSection />;

    case EScheduleOfWorksSection.LatestPaymentRequest:
      return <LatestPaymentRequestSection />;

    case EScheduleOfWorksSection.ScheduleOfWorksChecks:
      return <ScheduleOfWorksChecksSection />;

    default:
      return (
        <ErrorAlert
          msg={`Could not work out which section to display for id: ${selectedItemId}`}
        />
      );
  }
};
