import { FormHelperText, SxProps } from '@mui/material';
import { ControllerFieldState } from 'react-hook-form';

export const FieldErrorOrNull = ({
  fieldState,
  sx,
}: {
  fieldState: ControllerFieldState;
  sx?: SxProps;
}) => {
  return fieldState.error?.message ? (
    <FormHelperText error sx={sx}>
      {fieldState.error?.message}
    </FormHelperText>
  ) : null;
};
