import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EDutyOfCareSection } from 'pages/applicationPage/content/dutyOfCare';

interface IDutyOfCareContext {
  applicationId: string;
  selectedItemId: EDutyOfCareSection;
  setSelectedItemId: (itemId: EDutyOfCareSection) => void;
  readOnly: boolean;
}

const DutyOfCareContext = createContext<IDutyOfCareContext | undefined>(
  undefined
);

interface IDutyOfCareContextProviderProps {
  children: ReactNode;
}

export const DutyOfCareContextProvider = ({
  children,
}: IDutyOfCareContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();

  const [selectedItemId, setSelectedItemId] = useState<EDutyOfCareSection>(
    EDutyOfCareSection.SendDutyOfCareDeed
  );

  const handleSetSelectedItemId = (itemId: EDutyOfCareSection) => {
    setSelectedItemId(itemId);
  };

  const readOnly = closed;

  const value = useMemo(
    () => ({
      applicationId,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      readOnly,
    }),
    [applicationId, readOnly, selectedItemId]
  );

  return (
    <DutyOfCareContext.Provider value={value}>
      {children}
    </DutyOfCareContext.Provider>
  );
};

export const useDutyOfCareContext = () => {
  const context = useContext(DutyOfCareContext);
  if (context === undefined) {
    throw new Error(
      'useDutyOfCareContext must be used within an DutyOfCareContextProvider'
    );
  }
  return context;
};
