import { useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditCompaniesHouseChecksMutation,
  useGetCompaniesHouseChecksQuery,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { ECheckpointResponses } from 'enums/ECheckpointResponses';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import { useLocalSnackbar } from 'hooks';
import { CompaniesHouseChecksInfoPanel } from 'pages/applicationPage/content/applicationEligibility/components/CompaniesHouseChecks';
import { ReferApplicationButton } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import {
  IApplicationCompaniesHouseChecks,
  IApplicationEligibilityReferral,
} from 'types/applications/ApplicationEligibilityTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationCompaniesHouseChecks) =>
  nameof<IApplicationCompaniesHouseChecks>(fieldName);

export const CompaniesHouseChecksForm = () => {
  const { applicationId } = useApplicationEligibilityContext();
  const query = useGetCompaniesHouseChecksQuery(applicationId);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData = {
      ...data,
      referrals: data.referrals?.map(r => ({ ...r })) ?? [],
    };

    return <CompaniesHouseChecksFormMain initialData={initialData} />;
  }

  return null;
};

const CompaniesHouseChecksFormMain = ({
  initialData,
}: {
  initialData: IApplicationCompaniesHouseChecks;
}) => {
  const {
    applicationId,
    readOnly,
    onReferralQuestion,
    referrals,
    handleAutoShowReferApplicationDialog,
  } = useApplicationEligibilityContext();

  const [editCompaniesHouseChecks, editCompaniesHouseChecksStatus] =
    useEditCompaniesHouseChecksMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationCompaniesHouseChecks>({
    defaultValues: initialData,
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty, isSubmitting },
  } = form;

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Eligibility,
  });

  const watchIsActiveOrAcceptable = watch('isActiveOrAcceptable');
  const watchIsInMutualPublicRegister = watch('isInMutualPublicRegister');

  useEffect(() => {
    // Check for value of isActiveOrAcceptable
    // If it's not set to NA then set the value of isInMutualPublicRegister to null
    if (watchIsActiveOrAcceptable !== ECheckpointResponses.NA) {
      setValue('isInMutualPublicRegister', null);
    }
  }, [setValue, watchIsActiveOrAcceptable]);

  useEffect(() => {
    referrals
      .filter(item => item.section === EEligibilitySection.CompaniesHouse)
      .forEach((referral: IApplicationEligibilityReferral) => {
        form.setValue(`referrals.${referral.question}.reason`, referral.reason);
      });
  }, [form, referrals]);

  const getCompaniesHouseReferrals = () => {
    return referrals.filter(
      item => item.section === EEligibilitySection.CompaniesHouse
    );
  };

  const onSubmit = async (formData: IApplicationCompaniesHouseChecks) => {
    try {
      formData.id = applicationId;
      formData.referrals = getCompaniesHouseReferrals();
      await editCompaniesHouseChecks(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Companies House Checks updated successfully`);
          reset({}, { keepValues: true });
          handleAutoShowReferApplicationDialog();
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit Companies House checks`);
    }
  };

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.CompaniesHouse,
      question,
      referralType,
      refer
    );
  };

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Companies House Checks will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.Eligibility} />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertForQueryErrorOrNull
            isError={editCompaniesHouseChecksStatus.isError}
            error={editCompaniesHouseChecksStatus.error}
          />

          <AlertForChangedData isDirty={isDirty} />

          <Grid container spacing={1}>
            <Grid xs={9} item>
              <Box p={3}>
                <Grid container direction="column" spacing={1}>
                  <Grid item mb={2}>
                    <Typography variant="h3" fontWeight={600} fontSize="1em">
                      Is company status 'Active'?
                    </Typography>
                    <Controller
                      defaultValue={''}
                      name={getName('isActiveOrAcceptable')}
                      render={({
                        fieldState,
                        field: { onChange, ...fieldProps },
                      }) => (
                        <FormControl>
                          <ToggleButtonGroup
                            {...fieldProps}
                            onChange={(_, val) => {
                              if (val !== null) {
                                onChange(val);
                              }
                              handleReferralQuestion(
                                EEligibilityQuestion.CompanyOrg_IsActive,
                                EReferralType.HomesEngland,
                                val === ECheckpointResponses.No
                              );
                            }}
                            exclusive
                            disabled={readOnly || isOnHoldForThisType}
                          >
                            <ToggleButton
                              key={ECheckpointResponses.Yes}
                              value={ECheckpointResponses.Yes}
                            >
                              Yes
                            </ToggleButton>
                            <ToggleButton
                              key={ECheckpointResponses.No}
                              value={ECheckpointResponses.No}
                            >
                              No
                            </ToggleButton>
                            <ToggleButton
                              key={ECheckpointResponses.NA}
                              value={ECheckpointResponses.NA}
                            >
                              Not Applicable
                            </ToggleButton>
                          </ToggleButtonGroup>
                          {fieldState.error?.message ? (
                            <FormHelperText error>
                              {fieldState.error?.message}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {watchIsActiveOrAcceptable === ECheckpointResponses.No && (
                    <ReferApplicationNotificationBanner
                      question={EEligibilityQuestion.CompanyOrg_IsActive}
                      referralType={EReferralType.HomesEngland}
                      disabled={readOnly || isOnHoldForThisType}
                    />
                  )}
                  {watchIsActiveOrAcceptable === ECheckpointResponses.NA && (
                    <Grid item mb={2}>
                      <Typography
                        variant="h3"
                        fontWeight={600}
                        fontSize="1em"
                        width="35rem"
                      >
                        If the Applicant is a mutual benefit society or an
                        industrial and provident society, Are they in the Mutual
                        Public Register?
                      </Typography>
                      <Controller
                        defaultValue={''}
                        name={getName('isInMutualPublicRegister')}
                        render={({
                          fieldState,
                          field: { onChange, ...fieldProps },
                        }) => (
                          <FormControl>
                            <ToggleButtonGroup
                              {...fieldProps}
                              onChange={(_, val) => {
                                if (val !== null) onChange(val);
                                handleReferralQuestion(
                                  EEligibilityQuestion.CompanyOrg_IsInMutualPublicRegister,
                                  EReferralType.HomesEngland,
                                  val === ECheckpointResponses.No
                                );
                              }}
                              exclusive
                              disabled={readOnly || isOnHoldForThisType}
                            >
                              <ToggleButton
                                key={ECheckpointResponses.Yes}
                                value={ECheckpointResponses.Yes}
                              >
                                Yes
                              </ToggleButton>
                              <ToggleButton
                                key={ECheckpointResponses.No}
                                value={ECheckpointResponses.No}
                              >
                                No
                              </ToggleButton>
                              <ToggleButton
                                key={ECheckpointResponses.NA}
                                value={ECheckpointResponses.NA}
                              >
                                Not Applicable
                              </ToggleButton>
                            </ToggleButtonGroup>
                            {fieldState.error?.message ? (
                              <FormHelperText error>
                                {fieldState.error?.message}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  )}
                  {watchIsInMutualPublicRegister ===
                    ECheckpointResponses.No && (
                    <ReferApplicationNotificationBanner
                      question={
                        EEligibilityQuestion.CompanyOrg_IsInMutualPublicRegister
                      }
                      referralType={EReferralType.HomesEngland}
                      disabled={readOnly || isOnHoldForThisType}
                    />
                  )}
                </Grid>
                <Grid item mb={2}>
                  <>
                    <Typography
                      variant="h3"
                      fontWeight={600}
                      fontSize="1em"
                      width="35rem"
                    >
                      Registered Company Address
                    </Typography>
                    <Controller
                      name={'registeredAddress.nameNumber'}
                      rules={{
                        maxLength: {
                          value: 150,
                          message: '150 characters is the maximum allowed.',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          rows={5}
                          label="Address name number"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          disabled={readOnly || isOnHoldForThisType}
                        />
                      )}
                    />
                    <Controller
                      name={'registeredAddress.addressLine1'}
                      rules={{
                        required: 'Address Line 1 is required',
                        maxLength: {
                          value: 150,
                          message: '150 characters is the maximum allowed.',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          rows={5}
                          label="Address Line 1"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          disabled={readOnly || isOnHoldForThisType}
                        />
                      )}
                    />
                    <Controller
                      name={'registeredAddress.addressLine2'}
                      rules={{
                        maxLength: {
                          value: 150,
                          message: '150 characters is the maximum allowed.',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          rows={5}
                          label="Address Line 2"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          disabled={readOnly || isOnHoldForThisType}
                        />
                      )}
                    />
                    <Controller
                      name={'registeredAddress.townCity'}
                      rules={{
                        required: 'Town / City is required',
                        maxLength: {
                          value: 150,
                          message: '150 characters is the maximum allowed.',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          rows={5}
                          label="Town / City"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          disabled={readOnly || isOnHoldForThisType}
                        />
                      )}
                    />
                    <Controller
                      name={'registeredAddress.county'}
                      rules={{
                        maxLength: {
                          value: 150,
                          message: '150 characters is the maximum allowed.',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          rows={5}
                          label="County"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          disabled={readOnly || isOnHoldForThisType}
                        />
                      )}
                    />
                    <Controller
                      name={'registeredAddress.postcode'}
                      rules={{
                        required: 'Postcode is required',
                        maxLength: {
                          value: 20,
                          message: '20 characters is the maximum allowed.',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          rows={5}
                          label="Postcode"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          disabled={readOnly || isOnHoldForThisType}
                        />
                      )}
                    />
                  </>
                </Grid>
              </Box>
            </Grid>
            <Grid xs={3} item>
              <Box p={3}>
                <CompaniesHouseChecksInfoPanel />
              </Box>
            </Grid>
          </Grid>
          {!readOnly && (
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  p={3}
                  bgcolor="grey.100"
                >
                  <ReferApplicationButton
                    sectionType={EEligibilitySection.CompaniesHouse}
                    disabled={isOnHoldForThisType}
                  />
                  <OnOffHold holdType={EOnHoldType.Eligibility} />
                  <Box gap={2} display="flex">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        reset();
                      }}
                      disabled={isOnHoldForThisType}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      disabled={isOnHoldForThisType}
                      type="submit"
                    >
                      Confirm Details
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </form>
      </FormProvider>
    </>
  );
};
