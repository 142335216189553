import { Box, Grid, Stack, SxProps, Typography } from '@mui/material';
import { ICladdingSystem } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';

export const FraewDetailsSection = ({
  item,
  innerSx,
}: {
  item: ICladdingSystem;
  innerSx?: SxProps;
}) => {
  return (
    <Section
      title="FRAEW Details"
      items={[
        ['Cladding Type', item.currentCladdingSystemType],
        ['Cladding Manufacturer', item.currentCladdingManufacturer],
        ['Insulation Type', item.currentInsulationType],
        ['Insulation Manufacturer', item.currentInsulationManufacturer],
      ]}
      sx={{ bgcolor: 'grey.100' }}
      innerSx={innerSx}
    />
  );
};

export const Section = ({
  title,
  items,
  sx,
  innerSx,
}: {
  title: string;
  items: string[][];
  sx?: SxProps;
  innerSx?: SxProps;
}) => {
  return (
    <Box sx={sx}>
      <Box sx={{ px: 7, py: 3, ...innerSx }}>
        <SectionHeader value={title} />
        <Stack rowGap={1}>
          {items.map(([label, value], n) => (
            <Row key={n} label={label} value={value} />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

const SectionHeader = ({ value }: { value: string }) => {
  return (
    <Typography
      variant="h2"
      sx={{ fontSize: '1.05em', fontWeight: 600, mb: 4 }}
    >
      {value}
    </Typography>
  );
};

const Row = ({ label, value }: { label: string; value: string }) => {
  return (
    <Grid container>
      <Grid item xs={5}>
        <Typography variant="body1" fontWeight={'600'} fontSize={'0.9em'}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="body1" fontSize={'0.9em'} color="grey.800">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
