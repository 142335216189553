import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  useAddTaskMutation,
  useEditTaskMutation,
  useGetTaskQuery,
} from 'api/task';
import { ErrorAlert } from 'common/components/alerts';
import { FormSkeleton } from 'common/components/skeletons';
import { AddEditTaskForm } from 'common/components/task';
import { useAppDispatch } from 'state';
import { closeDrawer, useTaskDrawerState } from 'state/slices/task';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { ECmsTaskStatus, IAddEditTask } from 'types/tasks/CmsTaskTypes';
import { extractErrorMessages } from 'util/ApiUtils';
import { getDateOnlyIsoString } from 'util/DateUtils';

const _formId = 'addEditTaskForm';

const _defaultTask: IAddEditTask = {
  applicationRef: '',
  assigneeUserId: '',
  assignedToTeamId: '',
  notes: '',
  outcomeId: '',
  description: '',
  buildingName: '',
  applicant: '',
  createdBy: '',
  createdDate: '',
  dueDate: getDateOnlyIsoString(new Date()),
  status: ECmsTaskStatus.NotStarted,
  taskSubtypeId: '',
  taskTypeId: '',
  topicId: '',
};

export const AddEditTaskDrawer = () => {
  const dispatch = useAppDispatch();
  const handleCloseDrawerClick = () => {
    dispatch(closeDrawer());
  };

  const { isOpen, taskIdToEdit, applicationReference } = useTaskDrawerState();
  const { isLoading, isError, error, isSuccess, data } = useGetTaskQuery(
    taskIdToEdit ?? skipToken
  );
  const [addTaskFn, addTaskState] = useAddTaskMutation();
  const [editTaskFn, editTaskState] = useEditTaskMutation();
  const isSaving = addTaskState.isLoading || editTaskState.isLoading;

  const task: IAddEditTask | null =
    isLoading || isError
      ? null
      : isSuccess
      ? {
          ...data,
          assignedToTeamId: data.assignedToTeamId ?? '',
          applicationRef: data.applicationReference,
          topicId: data.topicId ?? '',
        }
      : {
          ..._defaultTask,
          applicationRef: applicationReference ?? '',
        };
  return (
    <StyledDrawer anchor="right" open={isOpen}>
      <Box>
        <DialogTitle component="div">
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h1" component="span">
              {taskIdToEdit ? 'Edit' : 'Add'} Task
            </Typography>
            <IconButton
              onClick={handleCloseDrawerClick}
              name="Close drawer"
              aria-label="Close drawer"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box py={2}>
            {isLoading ? <FormSkeleton /> : null}

            {isError ? <ErrorAlert msg={extractErrorMessages(error)} /> : null}

            {task !== null ? (
              <AddEditTaskForm
                formId={_formId}
                task={task}
                mutation={
                  taskIdToEdit
                    ? { type: 'Edit', fn: editTaskFn }
                    : { type: 'Add', fn: addTaskFn }
                }
              />
            ) : null}
          </Box>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(closeDrawer());
              }}
            >
              Cancel
            </Button>
            {task ? (
              <LoadingButton
                variant="contained"
                type="submit"
                form={_formId}
                loading={isSaving}
              >
                {`${taskIdToEdit ? 'Update' : 'Add'} Task`}
              </LoadingButton>
            ) : null}
          </Grid>
        </StyledDrawerActions>
      </Box>
    </StyledDrawer>
  );
};
