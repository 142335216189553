import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useAssigneeOptions } from 'common/components/task/utils';

export const AssigneeField = ({
  isRequired = true,
  selectedTeam,
}: {
  isRequired?: boolean;
  selectedTeam?: number | string;
}) => {
  const { control, getValues } = useFormContext();
  const { assigneeOptionsQuery, assigneeOptions: getAssigneeOptions } =
    useAssigneeOptions();

  if (assigneeOptionsQuery.isLoading) {
    return null;
  }
  const teamFilteredOptions = getAssigneeOptions.filter(assignee => assignee.teamId === selectedTeam)

  const filteredAssigneeOptions = selectedTeam ? teamFilteredOptions : getAssigneeOptions;

  const assigneeOptions = ['', ...filteredAssigneeOptions.map(a => a.id)];

  const getDisplayVal = (id: string) =>
    filteredAssigneeOptions.find(assigneeOption => assigneeOption.id === id)
      ?.value ?? '';

  return assigneeOptions.length > 0 ? (
    <Controller
      control={control}
      name="assigneeUserId"
      rules={isRequired ? { required: 'Assignee is required' } : undefined}
      render={({ field, formState: { errors } }) => (
        <Autocomplete
          value={getValues('assigneeUserId')}
          options={assigneeOptions}
          getOptionLabel={option => getDisplayVal(option)}
          onChange={(_, selectedValue) => {
            field.onChange(selectedValue ?? '');
          }}
          renderOption={(props, option) => {
            if (option === '') {
              return '';
            }
            return (
              <li {...props} key={option}>
                {getDisplayVal(option)}
              </li>
            );
          }}
          renderInput={params => {
            return (
              <TextField
                {...field}
                {...params}
                label="Assignee"
                error={!!errors.assigneeUserId}
                helperText={errors.assigneeUserId?.message}
              />
            );
          }}
        />
      )}
    />
  ) : null;
};
