export enum EEligibilitySection {
  CoreInformation = 1,
  Fraew = 2,
  LandRegistry = 3,
  PepSanctions = 4,
  ConfirmEligibility = 5,
  CompaniesHouse = 6,
  CostCentreValidation = 7,
  All = 8,
  Veriphy = 9
}

export const EEligibilitySectionLanguage: { [key in EEligibilitySection]: string } = {
  [EEligibilitySection.CoreInformation]: "Core Information",
  [EEligibilitySection.CostCentreValidation]: "Cost Centre Validation",
  [EEligibilitySection.Veriphy]: "Veriphy",
  [EEligibilitySection.CompaniesHouse]: "Companies House",
  [EEligibilitySection.PepSanctions]: "PEP Sanctions",
  [EEligibilitySection.LandRegistry]: "Land Registry",
  [EEligibilitySection.Fraew]: "FRAEW",
  [EEligibilitySection.ConfirmEligibility]: "Confirm Eligibility",
  [EEligibilitySection.All]: "All",
} as const;
