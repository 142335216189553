import { createTheme, ThemeOptions } from '@mui/material/styles';
import 'react-datepicker/dist/react-datepicker.css';
import { breakpoints } from 'styles/breakpoints';
import { muiAutocompleteTheme } from 'styles/muiComponentThemes/muiAutocompleteTheme';
import { muiAvatarTheme } from 'styles/muiComponentThemes/muiAvatarTheme';
import { muiButtonTheme } from 'styles/muiComponentThemes/muiButtonTheme';
import { muiChipTheme } from 'styles/muiComponentThemes/muiChipTheme';
import { muiCssBaselineTheme } from 'styles/muiComponentThemes/muiCssBaselineTheme';
import { muiDividerTheme } from 'styles/muiComponentThemes/muiDividerTheme';
import { muiIconButtonTheme } from 'styles/muiComponentThemes/muiIconButtonTheme';
import { muiSelectTheme } from 'styles/muiComponentThemes/muiSelectTheme';
import { muiTabTheme } from 'styles/muiComponentThemes/muiTabTheme';
import { muiTableTheme } from 'styles/muiComponentThemes/muiTableTheme';
import { muiTabsTheme } from 'styles/muiComponentThemes/muiTabsTheme';
import { muiTextFieldTheme } from 'styles/muiComponentThemes/muiTextFieldTheme';
import { muiToggleButtonGroupTheme } from 'styles/muiComponentThemes/muiToggleButtonGroupTheme';
import { muiToggleButtonTheme } from 'styles/muiComponentThemes/muiToggleButtonTheme';
import { palette } from 'styles/palette';
import { typographySettings } from 'styles/typography';

export const drawerWidth = 240;

const theme: ThemeOptions = {
  typography: pal => typographySettings(pal),
  palette,
  components: {
    MuiAutocomplete: muiAutocompleteTheme,
    MuiAvatar: muiAvatarTheme,
    MuiButton: muiButtonTheme,
    MuiChip: muiChipTheme,
    MuiCssBaseline: muiCssBaselineTheme,
    MuiDivider: muiDividerTheme,
    MuiIconButton: muiIconButtonTheme,
    MuiSelect: muiSelectTheme,
    MuiTab: muiTabTheme,
    MuiTabs: muiTabsTheme,
    MuiTable: muiTableTheme,
    MuiTextField: muiTextFieldTheme,
    MuiToggleButtonGroup: muiToggleButtonGroupTheme,
    MuiToggleButton: muiToggleButtonTheme,
  },
  breakpoints,
  zIndex: {
    appBar: 1001,
    drawer: 1000,
  },
};

export const defaultTheme = createTheme(theme);
