import { Grid } from '@mui/material';
import {
  BreadcrumbsBuilder,
  IBreadcrumbItem,
} from 'common/components/breadCrumb/breadCrumbBuilder';

export function Breadcrumbs({
  crumbs = [],
}: {
  crumbs: readonly IBreadcrumbItem[];
}) {
  return (
    <Grid width="100%" mb={3}>
      <BreadcrumbsBuilder crumbs={crumbs} />
    </Grid>
  );
}
