import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography } from '@mui/material';
import { RoundBorderBox } from 'common/components/roundBorderBox';

interface IVariationInProgressNotificationBannerProps {
  variationStartDate: string;
}

export function VariationInProgressNotificationBanner({
  variationStartDate,
}: IVariationInProgressNotificationBannerProps) {
  return (
    <RoundBorderBox sx={{ bgcolor: '#F9E0BB', mb: 2, p: 3 }}>
      <Box>
        <Stack direction="row" spacing={2}>
          <FontAwesomeIcon
            icon={solid('circle-exclamation')}
            size="lg"
            color={'#ED7D31'}
            style={{
              marginTop: '0.25rem',
            }}
          />
          <Box>
            <Typography fontSize="1em" color="black" fontWeight={600}>
              Variation In Progress
            </Typography>
            <Typography fontSize="1em" color="black">
              {`The applicant started a variation on ${variationStartDate}`}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </RoundBorderBox>
  );
}
