import { Typography } from '@mui/material';
import { useGetEligibilitySummaryQuery } from 'api/application';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const CostCentreValidationChecksInfoPanel = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetEligibilitySummaryQuery(applicationId);

  return (
    <ColumnContainer margin="10px">
      <Typography variant="h2" padding="16px">
        Application Details
      </Typography>
      {query.data ? (
        <>
          <DetailItem
            label="Local Authority"
            value={query.data.buildingAddress.localAuthority ? query.data.buildingAddress.localAuthority : '-'}
          />
        </>
      ) : null}
    </ColumnContainer>
  );
};
