import { Divider } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPullInProcessSummaryQuery } from 'api/pullInProcess';
import { ErrorAlert } from 'common/components/alerts';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { Loading } from 'common/components/loading';
import { MainContentContainer } from 'common/components/mainContentContainer';
import {
  PullInProcessContextProvider,
  usePullInProcessContext,
} from 'pages/pullInProcessPage/common/context';
import {
  PullInProcessPageLayout,
  TitleAndSummarySection,
} from 'pages/pullInProcessPage/components';
import { extractErrorMessages } from 'util/ApiUtils';

export const PullInProcessPage = () => {
  return (
    <PullInProcessContextProvider>
      <PullInProcessPageMain />
    </PullInProcessContextProvider>
  );
};

const PullInProcessPageMain = () => {
  const { pullInProcessId } = usePullInProcessContext();
  const query = useGetPullInProcessSummaryQuery(pullInProcessId ?? skipToken);

  const crumbs: readonly IBreadcrumbItem[] = [
    { text: 'Pull in process', url: 'undefined' },
    {
      text: query.data ? `${query.data.address.line1}` : '...',
      url: undefined,
    },
  ] as const;

  return (
    <>
      <MainContentContainer sx={{ overflow: 'hidden' }}>
        <Breadcrumbs crumbs={crumbs} />

        {query.isLoading ? <Loading isOpen /> : null}

        {query.isError ? (
          <ErrorAlert msg={extractErrorMessages(query.error)} />
        ) : null}

        {query.data ? (
          <>
            <TitleAndSummarySection data={query.data} />
            <Divider sx={{ m: '0 -1rem', width: '110%' }} />
            <PullInProcessPageLayout />
          </>
        ) : null}
      </MainContentContainer>
    </>
  );
};
