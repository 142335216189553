import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useGetDutyOfCareCounterSignedStatusQuery,
  useGetDutyOfCareStatusQuery,
  useUpdateDutyOfCareStatusMutation,
} from 'api/application/dutyOfCareApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { FormSkeleton } from 'common/components/skeletons';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { IDutyOfCareSentStatusForm } from 'pages/applicationPage/content/dutyOfCare/DutyOfCareTypes';
import { DutyOfCareSectionInfoPanel } from 'pages/applicationPage/content/dutyOfCare/sections/DutyOfCareSectionInfoPanel';
import { Intro } from 'pages/applicationPage/content/payments/common';
import { extractErrorMessages } from 'util/ApiUtils';

export const DeedConfirmationUploadSection = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetDutyOfCareStatusQuery(applicationId);

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isSuccess) {
    const initialData: IDutyOfCareSentStatusForm = {
      dutyOfCareSent: data?.dutyOfCareSent ?? false,
      sentEvidenceFile: data?.sentEvidenceFile,
      sentEvidenceFileId: data?.sentEvidenceFile?.id ?? '',
    };

    return <DeedConfirmationUploadSectionMain initialFormData={initialData} />;
  }

  return <AlertForQueryErrorOrNull isError={isError} error={error} />;
};

const _fileInputName = 'sentEvidenceFile';

const DeedConfirmationUploadSectionMain = ({
  initialFormData,
}: {
  initialFormData: IDutyOfCareSentStatusForm;
}) => {
  const { applicationId } = useApplicationContext();

  const { data } = useGetDutyOfCareCounterSignedStatusQuery(applicationId);

  const dutyOfCareCounterSigned = data?.dutyOfCareCounterSigned;

  const [updateStatus, updateStatusResult] =
    useUpdateDutyOfCareStatusMutation();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const { uploadFile, uploadFileStatus } = useFileUpload();

  const form = useForm<IDutyOfCareSentStatusForm>({
    defaultValues: initialFormData,
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting },
  } = form;

  const [watchDutyOfCareSentLocal, watchDutyOfCareSentEvidenceFile] = watch([
    'dutyOfCareSent',
    'sentEvidenceFile',
  ]);

  const onSubmit = async () => {
    const formData = getValues();

    await updateStatus({
      sentEvidenceFileId: formData.sentEvidenceFileId,
      dutyOfCareSent: formData.dutyOfCareSent,
      applicationId,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Status updated');
        reset({}, { keepValues: true });
      })
      .catch(error => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'DutyOfCareInfoSent',
      onSuccess: payload => {
        form.setValue(`${_fileInputName}Id`, payload.id);
        form.setValue(_fileInputName, payload);
      },
    });
  };

  const handleOnDeleteFileUpload = async () => {
    form.setValue(`${_fileInputName}Id`, '');
    form.setValue(_fileInputName, null);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={4} p={4}>
          <Grid item xs={8.5} display={'flex'} flexDirection={'column'} gap={3}>
            <LeavePageGuard
              blockNavigationIf={isDirty && !isSubmitting}
              message="Are you sure you want to leave? Any unsaved changes to the send Duty of Care section will be lost."
            />

            <Box>
              <Box>
                <Intro heading="Has the Duty of Care deed been sent to the nominated Grant Certifying Officer? " />
              </Box>

              <Box bgcolor="grey.100" p={2} mb={3} mt={1}>
                <Controller
                  control={control}
                  name={'dutyOfCareSent'}
                  render={({ field: { onChange, value, ...fieldProps } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...fieldProps}
                          checked={value || false}
                          onChange={(_, val) => {
                            if (val !== null) {
                              onChange(val);
                            }
                          }}
                          disabled={dutyOfCareCounterSigned ?? false}
                        />
                      }
                      label="I have sent the Duty of Care deed"
                    />
                  )}
                />
              </Box>
            </Box>

            {watchDutyOfCareSentLocal ? (
              <Controller
                control={control}
                name={'sentEvidenceFileId'}
                rules={{
                  required: 'Evidence that the file has been sent is required.',
                }}
                render={props => {
                  return (
                    <Box>
                      <Intro
                        heading={
                          props.field.value
                            ? 'Evidence of file being sent'
                            : 'Upload evidence'
                        }
                      />
                      {props.field.value &&
                      watchDutyOfCareSentEvidenceFile?.id ? (
                        <Box display="flex" flexDirection="row">
                          <ApplicationFileDownloadWrapper
                            fileName={
                              watchDutyOfCareSentEvidenceFile?.name ?? ''
                            }
                            fileId={watchDutyOfCareSentEvidenceFile?.id ?? ''}
                            fileWrapperStyle={{ mb: 0 }}
                          />
                          {!dutyOfCareCounterSigned ? (
                            <IconButton
                              size="small"
                              onClick={() => handleOnDeleteFileUpload()}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          ) : null}
                        </Box>
                      ) : (
                        <>
                          {!dutyOfCareCounterSigned ? (
                            <>
                              <FileUploadInputSelect
                                id={_fileInputName}
                                inputName={_fileInputName}
                                label="Click to upload or drag and drop file (max. 30MB)"
                                accept=".pdf,.docx,.xlsx,.msg,.eml,.jpg,.jpeg,.png"
                                onFileUpload={file => handleOnFileUpload(file)}
                                onFileDelete={() => handleOnDeleteFileUpload()}
                                isUploading={uploadFileStatus.isLoading}
                                uploaded={uploadFileStatus.isSuccess}
                              />

                              {props.fieldState.error && (
                                <FormHelperText error>
                                  {props.fieldState.error.message}
                                </FormHelperText>
                              )}
                            </>
                          ) : null}
                        </>
                      )}
                    </Box>
                  );
                }}
              />
            ) : null}

            <AlertForQueryErrorOrNull
              isError={updateStatusResult.isError}
              error={updateStatusResult.error}
            />
          </Grid>
          <Grid xs={3.5} item>
            <DutyOfCareSectionInfoPanel />
          </Grid>
        </Grid>
        {!dutyOfCareCounterSigned && (
          <Grid container mt={10} spacing={1}>
            <Grid xs={12} item>
              <Box
                display="flex"
                justifyContent="flex-end"
                p={3}
                bgcolor="grey.100"
              >
                <Box gap={2} display="flex">
                  <LoadingButton
                    loading={false}
                    onClick={() => reset()}
                    variant="outlined"
                  >
                    Cancel
                  </LoadingButton>

                  <LoadingButton
                    loading={updateStatusResult.isLoading}
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </form>
    </FormProvider>
  );
};
