import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { useVariationCostsSchedule } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { useCostsScheduleContext } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection/context';
import {
  PreliminariesFormWrapper,
  OtheFormWrapper,
  RemovalFormWrapper,
  IneligibleFormWrapper,
  InstallationFormWrapper,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection/forms';
import { StyledDrawer } from 'styles/globalStyles/drawer';
import {
  SectionTitles,
  ECostsScheduleSection,
} from 'types/applications/ApplicationCostScheduleTypes';

export const EditDrawer = () => {
  const { sectionToEdit } = useCostsScheduleContext();

  return (
    <StyledDrawer
      anchor="right"
      open={sectionToEdit !== null}
      onClose={() => {}}
    >
      {sectionToEdit === null ? null : <EditDrawerMain />}
    </StyledDrawer>
  );
};

const EditDrawerMain = () => {
  const { isSuccess, data } = useVariationCostsSchedule();
  const { sectionToEdit, setSectionToEdit } = useCostsScheduleContext();
  const handleCloseClick = () => {
    setSectionToEdit(null);
  };

  if (sectionToEdit === null) {
    return null;
  }

  return (
    <>
      <DialogTitle component="div">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h1" component="span" fontSize={'1em'}>
              {SectionTitles[sectionToEdit]}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={handleCloseClick}
              aria-label="Close drawer"
              name="Close drawer"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      {isSuccess && data ? <Forms /> : null}
    </>
  );
};

const Forms = () => {
  const { sectionToEdit } = useCostsScheduleContext();

  switch (sectionToEdit) {
    case ECostsScheduleSection.RemovalOfUnsafeCladding:
      return <RemovalFormWrapper />;

    case ECostsScheduleSection.InstallationOfNewCladding:
      return <InstallationFormWrapper />;

    case ECostsScheduleSection.Preliminaries:
      return <PreliminariesFormWrapper />;

    case ECostsScheduleSection.OtherCosts:
      return <OtheFormWrapper />;

    case ECostsScheduleSection.InelgibleCosts:
      return <IneligibleFormWrapper />;

    default:
      return null;
  }
};
