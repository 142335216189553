import { Box, Grid, Typography } from '@mui/material';
import { AdministrationBenchmarkFiguresCurrent } from 'pages/administrationPage/components/administrationBenchmarkFiguresTab/components/AdministrationBenchmarkFiguresCurrent';
import { AdministrationBenchmarkFiguresHistory } from 'pages/administrationPage/components/administrationBenchmarkFiguresTab/components/AdministrationBenchmarkFiguresHistory';
import { AdministrationBenchmarkFiguresHistoryFilter } from 'pages/administrationPage/components/administrationBenchmarkFiguresTab/components/AdministrationBenchmarkFiguresHistoryFilter';

export function AdministrationBenchmarkFiguresTab() {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <AdministrationBenchmarkFiguresCurrent />
          </Box>
          <Box mt={3}>
            <Typography variant="h2">Benchmark History</Typography>
            <AdministrationBenchmarkFiguresHistoryFilter />
            <AdministrationBenchmarkFiguresHistory />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
