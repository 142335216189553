import { Grid } from '@mui/material';
import { useGetApplicationsKpisQuery } from 'api/application';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { MainContentContainer } from 'common/components/mainContentContainer';
import { PageTitle } from 'common/components/pageTitle';
import { ApplicationsPageContent } from 'pages/applicationsPage/components';

const _crumbs: readonly IBreadcrumbItem[] = [
  { text: 'Applications', url: undefined },
] as const;

export const ApplicationsPage = () => {
  const query = useGetApplicationsKpisQuery();
  return (
    <MainContentContainer>
      <Breadcrumbs crumbs={_crumbs} />

      <PageTitle
        title="Applications"
        count={query.data ? query.data.totalApplicationCount : undefined}
      />

      <Grid item xs={12}>
        <ApplicationsPageContent />
      </Grid>
    </MainContentContainer>
  );
};
