import { ComponentProps, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionClasses,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  SxProps,
} from '@mui/material';
import { useGetVariationCostsScheduleQuery } from 'api/application/variationApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import {
  GridItemRight,
  HeaderLabel,
  getAmountFromCostsSchedule,
  getDiffBetweenPreviousAndCurrent,
} from 'common/components/costSchedule';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { SummaryCardRow } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components';
import {
  useApplicationAndVariationIdOrSkipToken,
  useIsVariationReadOnly,
  useVariationOverview,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import {
  getLatestCostSchedule,
  EditDrawer,
  getLatestAvailableDescription,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection';
import {
  CostsScheduleContextProvider,
  useCostsScheduleContext,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection/context';
import {
  CostScheduleAmountProperties,
  CostScheduleDescriptionProperties,
  CostScheduleTotalProperties,
  ECostsScheduleSection,
  FieldLabels,
  SectionTitles,
} from 'types/applications/ApplicationCostScheduleTypes';
import { getGbpAmount } from 'util/AppUtils';

export const VariationCostScheduleSection = () => {
  return (
    <Stack gap={2}>
      <SummaryCardRow />
      <CostsScheduleContextProvider>
        <CostsScheduleSectionMain />
      </CostsScheduleContextProvider>
    </Stack>
  );
};

const CostsScheduleSectionMain = () => {
  const queryParams = useApplicationAndVariationIdOrSkipToken();
  const { isSuccess, isError, error, isLoading } =
    useGetVariationCostsScheduleQuery(queryParams);
  const { hasAmended } = useCostsScheduleContext();
  const { query } = useVariationOverview();

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  if (isSuccess) {
    return (
      <>
        <Stack rowGap={2}>
          <Grid container columns={hasAmended ? 14 : 12} px={3}>
            <Grid item xs={6} />

            <GridItemRight xs={2}>
              <HeaderLabel label={'Works package'} />
            </GridItemRight>
            <GridItemRight xs={2}>
              <HeaderLabel
                label={`Variation ${query.data?.variationNumber ?? ''}`}
              />
            </GridItemRight>
            {hasAmended ? (
              <GridItemRight xs={2}>
                <HeaderLabel label={'Amendment'} />
              </GridItemRight>
            ) : null}
            <GridItemRight item xs={2}>
              <HeaderLabel label={'Total'} />
            </GridItemRight>
          </Grid>

          <Section
            section={ECostsScheduleSection.RemovalOfUnsafeCladding}
            rows={[
              {
                title:
                  FieldLabels[ECostsScheduleSection.RemovalOfUnsafeCladding]
                    .removal,
                descriptionFieldName: 'unsafeCladdingRemovalDescription',
                amountFieldName: 'unsafeCladdingRemovalAmount',
              },
            ]}
            totalFieldName="unsafeCladdingTotal"
          />

          <Section
            section={ECostsScheduleSection.InstallationOfNewCladding}
            rows={[
              {
                title:
                  FieldLabels[ECostsScheduleSection.InstallationOfNewCladding]
                    .newCladding,
                descriptionFieldName: 'newCladdingDescription',
                amountFieldName: 'newCladdingAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.InstallationOfNewCladding]
                    .external,
                descriptionFieldName: 'externalWorksDescription',
                amountFieldName: 'externalWorksAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.InstallationOfNewCladding]
                    .internal,
                descriptionFieldName: 'internalWorksDescription',
                amountFieldName: 'internalWorksAmount',
              },
            ]}
            totalFieldName="installationTotal"
          />

          <Section
            section={ECostsScheduleSection.Preliminaries}
            rows={[
              {
                title:
                  FieldLabels[ECostsScheduleSection.Preliminaries]
                    .mainContractor,
                descriptionFieldName: 'mainContractorPreliminariesDescription',
                amountFieldName: 'mainContractorPreliminariesAmount',
              },
              {
                title: FieldLabels[ECostsScheduleSection.Preliminaries].access,
                descriptionFieldName: 'accessDescription',
                amountFieldName: 'accessAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.Preliminaries].overheads,
                descriptionFieldName: 'mainContractorOverheadDescription',
                amountFieldName: 'mainContractorOverheadAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.Preliminaries]
                    .contingencies,
                descriptionFieldName: 'contractorContingenciesDescription',
                amountFieldName: 'contractorContingenciesAmount',
              },
            ]}
            totalFieldName="preliminariesTotal"
          />

          <Section
            section={ECostsScheduleSection.OtherCosts}
            rows={[
              {
                title: FieldLabels[ECostsScheduleSection.OtherCosts].fraew,
                descriptionFieldName: 'fraewSurveyDescription',
                amountFieldName: 'fraewSurveyAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.OtherCosts].feasibilityFees,
                descriptionFieldName: 'feasibilityStageDescription',
                amountFieldName: 'feasibilityStageAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.OtherCosts].postTenderFees,
                descriptionFieldName: 'postTenderStageDescription',
                amountFieldName: 'postTenderStageAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.OtherCosts].liaisonCosts,
                descriptionFieldName: 'propertyManagerDescription',
                amountFieldName: 'propertyManagerAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.OtherCosts]
                    .irrecoverableVatCosts,
                descriptionFieldName: 'irrecoverableVatDescription',
                amountFieldName: 'irrecoverableVatAmount',
              },
            ]}
            totalFieldName="otherCostsTotal"
          />

          <Section
            section={ECostsScheduleSection.InelgibleCosts}
            rows={[
              {
                title: FieldLabels[ECostsScheduleSection.InelgibleCosts].costs,
                amountFieldName: 'ineligibleAmount',
                descriptionFieldName: 'ineligibleDescription',
              },
            ]}
            totalLabel="Total ineligible cost"
            totalFieldName="ineligibleAmount"
            sx={{ backgroundColor: 'grey.200' }}
          />
        </Stack>

        <EditDrawer />
      </>
    );
  }

  return null;
};

type SectionProps = {
  section: ECostsScheduleSection;
  rows: SectionRow[];
  totalLabel?: string;
  sx?: SxProps;
  totalFieldName: keyof CostScheduleTotalProperties;
};

type SectionRow = {
  title: string;
  amountFieldName: keyof CostScheduleAmountProperties;
  descriptionFieldName?: keyof CostScheduleDescriptionProperties;
};

const Section = ({
  section,
  rows,
  totalLabel = 'Total eligible cost',
  totalFieldName,
  sx,
}: SectionProps) => {
  const { data, hasAmended, setSectionToEdit } = useCostsScheduleContext();
  if (!data) {
    return null;
  }

  return (
    <RoundBorderBox>
      <Stack sx={{ ...sx, p: 3, pb: 1 }}>
        <Grid container mb={1}>
          <Grid item xs={9}>
            <SectionTitle title={SectionTitles[section]} />
          </Grid>
          <GridItemRight xs={3}>
            <EditButton onClick={() => setSectionToEdit(section)} />
          </GridItemRight>
        </Grid>
        {rows.map(item => (
          <CostsSchduleItemRow key={item.title} {...item} />
        ))}
      </Stack>
      <Divider />
      <Grid container sx={{ px: 3, py: 2 }} columns={hasAmended ? 14 : 12}>
        <Grid item xs={6}>
          <TotalText val={totalLabel} />
        </Grid>
        <GridItemRight xs={2}>
          <TotalText
            isGrey
            val={getAmountFromCostsSchedule(data.original, totalFieldName)}
          />
        </GridItemRight>
        <GridItemRight xs={2}>
          <TotalText
            isGrey
            val={getDiffBetweenPreviousAndCurrent(
              data.original,
              data.variation,
              totalFieldName
            )}
          />
        </GridItemRight>
        {hasAmended ? (
          <GridItemRight xs={2}>
            <TotalText
              isGrey
              val={getDiffBetweenPreviousAndCurrent(
                data.variation || data.original,
                data.amended,
                totalFieldName
              )}
            />
          </GridItemRight>
        ) : null}
        <GridItemRight xs={2}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, fontSize: '0.95em' }}
          >
            {getAmountFromCostsSchedule(
              getLatestCostSchedule(data),
              totalFieldName
            )}
          </Typography>
        </GridItemRight>
      </Grid>
    </RoundBorderBox>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="body1" sx={{ fontSize: '0.95em', fontWeight: 600 }}>
      {title}
    </Typography>
  );
};

const EditButton = ({
  onClick,
}: {
  onClick: ComponentProps<typeof Button>['onClick'];
}) => {
  const { isVariationReadOnly } = useIsVariationReadOnly();

  if (isVariationReadOnly) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      size="small"
      sx={{ py: 0.25, px: 1.5, minWidth: 'unset', height: 'unset' }}
      onClick={onClick}
    >
      Edit
    </Button>
  );
};

const CostsSchduleItemRow = ({
  title,
  amountFieldName,
  descriptionFieldName,
}: SectionRow) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, hasAmended } = useCostsScheduleContext();

  if (!data) {
    return null;
  }

  const diffBetweenOriginalAndVariation = getDiffBetweenPreviousAndCurrent(
    data.original,
    data.variation,
    amountFieldName
  );

  const diffBetweendVariationAndAmendment = hasAmended
    ? getDiffBetweenPreviousAndCurrent(
        data.variation || data.original,
        data.amended,
        amountFieldName
      )
    : null;

  return (
    <Accordion
      expanded={isExpanded}
      elevation={0}
      sx={{
        [`&.${accordionClasses.root}:before`]: {
          background: 'none',
        },
        background: 'none',
      }}
    >
      <AccordionSummary
        sx={{
          p: 0,
          pointerEvents: 'none',
          '& .MuiAccordionSummary-content': {
            minWidth: 0,
            my: 0,
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          columnSpacing={2}
          wrap="nowrap"
          columns={hasAmended ? 14 : 12}
        >
          <Grid
            item
            flexBasis={'30px'}
            sx={isExpanded ? { alignSelf: 'flex-start' } : null}
          >
            <IconButton
              onClick={e => {
                setIsExpanded(isExpandedVal => !isExpandedVal);
              }}
              sx={{
                pointerEvents: 'auto',
                flexGrow: 1,
                background: 'grey.100',
                p: 1,
                m: 0,
                padding: 0,
              }}
            >
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Typography fontSize={'0.9em'}>{title}</Typography>
          </Grid>

          <GridItemRight xs={2}>
            {getAmountFromCostsSchedule(data.original, amountFieldName)}
          </GridItemRight>

          <GridItemRight xs={2}>
            <DiffText val={diffBetweenOriginalAndVariation} />
          </GridItemRight>

          {hasAmended ? (
            <GridItemRight xs={2}>
              <DiffText val={diffBetweendVariationAndAmendment} />
            </GridItemRight>
          ) : null}

          <GridItemRight xs={2}>
            {getAmountFromCostsSchedule(
              getLatestCostSchedule(data),
              amountFieldName
            )}
          </GridItemRight>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography fontSize={'0.9em'}>
          {descriptionFieldName
            ? getLatestAvailableDescription(data, descriptionFieldName)
            : '-'}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const DiffText = ({ val }: { val: number | null }) => {
  if (!val) {
    return <Typography>-</Typography>;
  }

  return (
    <Typography sx={val < 0 ? { color: 'red' } : null}>
      {getGbpAmount({ value: val })}
    </Typography>
  );
};

const TotalText = ({
  val,
  isGrey = false,
}: {
  val: string | number | null;
  isGrey?: boolean;
}) => {
  return (
    <Typography
      sx={{
        fontWeight: 600,
        fontSize: '0.95em',
        ...(isGrey ? { color: 'grey.500' } : null),
      }}
    >
      {typeof val === 'string'
        ? val
        : val === null
        ? '-'
        : getGbpAmount({ value: val })}
    </Typography>
  );
};
