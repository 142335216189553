import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Container, Stack, Typography } from '@mui/material';
import { RoundBorderBox } from 'common/components/roundBorderBox';

interface IClosedReasonNotificationBannerProps {
  closedReason: string;
}

export function ClosedReasonNotificationBanner({
  closedReason,
}: IClosedReasonNotificationBannerProps) {
  return (
    <Container maxWidth={false}>
      <RoundBorderBox sx={{ bgcolor: '#F1D6D6', mb: 1, p: 3 }}>
        <Box>
          <Stack direction="row" spacing={2}>
            <FontAwesomeIcon
              icon={solid('circle-exclamation')}
              size="lg"
              color={'red'}
              style={{
                marginTop: '0.25rem',
              }}
            />
            <Box>
              <Typography fontSize="1em" color="black">
                {`Application Closed - ${closedReason}`}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </RoundBorderBox>
    </Container>
  );
}
