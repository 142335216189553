import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useAddUserMutation,
  useEditUserMutation,
  useGetOrganisationsQuery,
  useGetRolesQuery,
  useLazyGetUserQuery,
} from 'api/administration';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IAddEditUserForm } from 'types/administration/AdministrationUsersTypes';
import { getTeamSelectItems } from 'util/TeamUtils';
import { setServerSideFormErrors } from 'util/formUtils';

interface IAddEditUserDrawerProps {
  userId?: string;
  onClose: () => void;
  onSuccess: () => void;
}

const defaultUser: IAddEditUserForm = {
  emailAddress: '',
  firstName: '',
  isBlocked: false,
  lastName: '',
  organisationId: '',
  roleId: '',
  team: '',
};

export function AddEditUserDrawer({
  userId,
  onClose,
  onSuccess,
}: IAddEditUserDrawerProps) {
  const {
    data: rolesData,
    isLoading: rolesLoading,
    isSuccess: rolesLoaded,
  } = useGetRolesQuery();
  const {
    data: organisationsData,
    isLoading: orgsLoading,
    isSuccess: organisationsLoaded,
  } = useGetOrganisationsQuery();
  const [getUser, getUserStatus] = useLazyGetUserQuery();
  const [addUser, addUserResult] = useAddUserMutation();
  const [editUser, editUserResult] = useEditUserMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IAddEditUserForm>({ defaultValues: defaultUser });
  const { control, handleSubmit, reset } = form;

  useEffect(() => {
    if (rolesLoaded && organisationsLoaded && userId) {
      getUser(userId).then(result => {
        if (result && result.data) {
          reset(result.data);
        }
      });
    }
  }, [getUser, organisationsLoaded, reset, rolesLoaded, userId]);

  const onSubmit = async (formData: IAddEditUserForm) => {
    if (userId === undefined) {
      try {
        await addUser(formData)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`New User added`);
            onSuccess();
          })
          .catch(error => {
            if (
              error?.data?.generalError?.errorMessage.includes('Username') &&
              error?.data?.generalError?.errorMessage.includes(
                'is already taken'
              )
            ) {
              createWarningSnackbar('E-mail address is already taken.');
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to add user`);
      }
    } else
      try {
        formData.id = userId;
        await editUser(formData)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`User updated successfully`);
            onSuccess();
          })
          .catch(error => {
            if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit user`);
      }
  };

  const isFormLoading = rolesLoading || orgsLoading || getUserStatus.isLoading;
  const isSubmitting = addUserResult.isLoading || editUserResult.isLoading;

  return isFormLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            {userId ? 'Edit' : 'Add'} User:{' '}
            {userId
              ? getUserStatus.data?.firstName +
                ' ' +
                getUserStatus.data?.lastName
              : ''}
          </Typography>
        </DialogTitle>

        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <DialogContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} paddingBottom={2}>
                  <Typography variant="h3">Personal Details</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={'firstName'}
                    rules={{ required: 'Required' }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        label="First Name"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={'lastName'}
                    rules={{ required: 'Required' }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        label="Last Name"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={'emailAddress'}
                    rules={{ required: 'Required' }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        label="Email Address"
                        disabled={userId !== undefined}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} paddingBottom={2}>
                  <Typography variant="h3">Role Information</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={'roleId'}
                    rules={{ required: 'Required' }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Role"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        select
                      >
                        {rolesData?.map(role => {
                          return (
                            <MenuItem key={role.id} value={role.id}>
                              {role.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={'organisationId'}
                    rules={{ required: 'Required' }}
                    render={({ field, fieldState }) => (
                      <>
                        <TextField
                          {...field}
                          label="Organisation"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          select
                        >
                          {organisationsData?.map(organisation => {
                            return (
                              <MenuItem
                                key={organisation.id}
                                value={organisation.id}
                              >
                                {organisation.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={'team'}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Team"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        select
                      >
                        {getTeamSelectItems}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                >
                  {userId ? 'Update' : 'Add'} User
                </LoadingButton>
              </Grid>
            </StyledDrawerActions>
          </form>
        </FormProvider>
      </Box>
    </StyledDrawer>
  );
}
