import { useState } from 'react';
import { faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCloseThirdPartyReferralGeneralMutation } from 'api/application/referralApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect';
import { IFile } from 'common/types/File';
import { useFileUpload, useLocalSnackbar, useModalState } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import {
  EReferralRecordType,
  ICloseThirdPartyReferralForm,
} from 'types/applications/ReferralTypes';

const _formId = 'close-third-party-referral-form';
const _fileInputName = 'communicationEvidenceFile';

type CloseThirdPartyReferralDrawerConfig =
  | {
      referralRecordType: EReferralRecordType.WorksPackage;
      thirdPartyReferralId: string;
    }
  | {
      referralRecordType: EReferralRecordType.PaymentRequest;
      thirdPartyReferralId: string;
      paymentId: string;
    }
  | {
      referralRecordType: EReferralRecordType.Variation;
      thirdPartyReferralId: string;
      variationId: string;
    };

export const useCloseReferralToThirdPartyDrawer = () => {
  const {
    isShowing: isCloseReferralDrawerShowing,
    showModal: showCloseReferralDrawer,
    hideModal: hideCloseReferralDrawer,
  } = useModalState();

  const [config, setConfig] =
    useState<CloseThirdPartyReferralDrawerConfig | null>(null);

  const [evidenceFile, setEvidenceFile] = useState<IFile | null>(null);

  const { uploadFile, uploadFileStatus } = useFileUpload();

  const form = useForm<ICloseThirdPartyReferralForm>({
    defaultValues: {
      response: '',
      responseComments: '',
      responseFileId: '',
    },
  });
  const { handleSubmit, control, reset } = form;

  const handleOnDeleteFileUpload = async () => {
    form.setValue(`responseFileId`, '');
    setEvidenceFile(null);
  };

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'WorksPackageThirdPartyReferralResponse',
      onSuccess: payload => {
        form.setValue('responseFileId', payload.id);
        setEvidenceFile(payload);
      },
    });
  };

  const { createErrorSnackbar, createSuccessSnackbar } = useLocalSnackbar();

  const handleShowCloseReferralDrawer = (
    referralConfig: CloseThirdPartyReferralDrawerConfig
  ) => {
    setConfig(referralConfig);
    showCloseReferralDrawer();
  };

  const handleHideReferDrawer = () => {
    setConfig(null);
    hideCloseReferralDrawer();
    reset();
  };

  const { applicationId } = useApplicationContext();
  const [closeReferral, closeReferralStatus] =
    useCloseThirdPartyReferralGeneralMutation();

  const onSubmit = async (formData: ICloseThirdPartyReferralForm) => {
    if (!config) {
      createErrorSnackbar('Could not get referral. Please try again later.');
      return;
    }

    const getMutationConfig = (): Parameters<typeof closeReferral>[0] => {
      const base = {
        applicationId,
        thirdPartyReferralId: config.thirdPartyReferralId,
        formData,
      };
      switch (config.referralRecordType) {
        case EReferralRecordType.WorksPackage:
          return {
            ...base,
            type: EReferralRecordType.WorksPackage,
          };
        case EReferralRecordType.PaymentRequest:
          return {
            ...base,
            type: EReferralRecordType.PaymentRequest,
            paymentRequestId: config.paymentId,
          };

        case EReferralRecordType.Variation:
          return {
            ...base,
            type: EReferralRecordType.Variation,
            variationId: config.variationId,
          };
      }
    };

    closeReferral(getMutationConfig())
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Referral was closed');
        handleHideReferDrawer();
      });
  };

  const renderCloseReferralDrawer = () => {
    if (!config) {
      return null;
    }

    return (
      <>
        <StyledDrawer anchor="right" open={isCloseReferralDrawerShowing}>
          <Box>
            <DialogTitle component="div">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h1" component="span">
                  Close referral
                </Typography>
                <IconButton
                  onClick={() => handleHideReferDrawer()}
                  aria-label="Close Drawer"
                  name="Close Drawer"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Stack gap={2}>
                <CardTitle title="Response details" sx={{ mb: 2 }} />

                <Box>
                  <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      control={control}
                      name="response"
                      rules={{ required: 'Required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          label="Response"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        >
                          {['Cancelled', 'Marked as Complete'].map(
                            (option, i) => (
                              <MenuItem key={i} value={option}>
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      )}
                    />

                    <Controller
                      control={control}
                      name="responseComments"
                      rules={{
                        required: 'Required',
                        maxLength: {
                          value: 1000,
                          message: '1000 characters is the maximum allowed.',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          multiline
                          fullWidth
                          rows={5}
                          label="Provide further explanation"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={'responseFileId'}
                      rules={{
                        required: 'Evidence of communication file is required.',
                      }}
                      render={props => {
                        return (
                          <Box>
                            <CardTitle
                              title={
                                props.field.value
                                  ? 'Communication evidence'
                                  : 'Upload evidence of communication'
                              }
                            />

                            {props.field.value && evidenceFile ? (
                              <Box display="flex" flexDirection="row">
                                <ApplicationFileDownloadWrapper
                                  fileName={evidenceFile.name ?? ''}
                                  fileId={evidenceFile.id ?? ''}
                                  fileWrapperStyle={{ mb: 0 }}
                                />

                                <IconButton
                                  size="small"
                                  onClick={() => handleOnDeleteFileUpload()}
                                  aria-label="Delete File"
                                  name="Delete File"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </IconButton>
                              </Box>
                            ) : (
                              <>
                                <FileUploadInputSelect
                                  id={_fileInputName}
                                  inputName={_fileInputName}
                                  label="Click to upload or drag and drop file (max. 30MB)"
                                  accept=".pdf,.msg"
                                  onFileUpload={file =>
                                    handleOnFileUpload(file)
                                  }
                                  onFileDelete={() =>
                                    handleOnDeleteFileUpload()
                                  }
                                  isUploading={uploadFileStatus.isLoading}
                                  uploaded={uploadFileStatus.isSuccess}
                                />

                                {props.fieldState.error && (
                                  <FormHelperText error>
                                    {props.fieldState.error.message}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          </Box>
                        );
                      }}
                    />
                  </form>
                </Box>
                <AlertForQueryErrorOrNull
                  isError={closeReferralStatus.isError}
                  error={closeReferralStatus.error}
                />
              </Stack>
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={hideCloseReferralDrawer}>
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  form={_formId}
                  loading={closeReferralStatus.isLoading}
                >
                  Save
                </LoadingButton>
              </Grid>
            </StyledDrawerActions>
          </Box>
        </StyledDrawer>
      </>
    );
  };

  return {
    showCloseReferralDrawer: handleShowCloseReferralDrawer,
    renderCloseReferralDrawer,
  };
};
