import {
  EVendorAccountSetupStatus,
  IVendorPaymentMain,
  IVendorAccountApproval,
  IVendorAccountSetupSummary,
  IVendorPaymentDetails,
  IPaymentRecommendationApplicationDetails,
  EResponsibleEntityType,
  IPaymentRecommendationDetails,
  EPaymentStatus,
  IAdditionalPaymentRecommendationDetails,
} from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { getGuid } from 'util/AppUtils';

export const vendorAccountSetupSummaryData: IVendorAccountSetupSummary = {
  status: EVendorAccountSetupStatus.Complete,
  paymentDetailsApproved: true,
};

const vendorPaymentDetailsData: IVendorPaymentDetails = {
  companyRegistrationNumber: '19134831',
  telephoneNumber: '07777 111881',
  email: 'example@email.co.uk',
  address: ['123 Example Rd', 'London', 'SW1 1AB'],
  name: 'Example',
  reference: 'CSS12912',
  vatRegistrationNumber: '12983361',
  bankDetails: {
    sortCode: '53-32-21',
    accountNumber: '23019102',
    accountName: 'Jane Smith',
    bankName: 'Natwest',
    bankBranch: '23 Oxford St, London, SW2 2AN',
  },
};

const vendorPaymentDetailsApprovalHistory: IVendorAccountApproval[] = [
  {
    approved: true,
    reason: 'This and that',
    approvalEvidenceFile: {
      id: getGuid(),
      name: 'FileName.pdf',
      extension: 'pdf',
    },
    date: '2023-05-24',
  },
  {
    approved: false,
    reason: 'Some reason or another',
    approvalEvidenceFile: null,
    date: '2023-05-23',
  },
];

export const vendorPaymentData: IVendorPaymentMain = {
  vendor: vendorPaymentDetailsData,
  vendorPdfUri: 'https://path.to.file/something.pdf',
  detailsSentToHomesEngland: true,
  detailsSentEvidenceFile: {
    id: getGuid(),
    name: 'This test file.pdf',
    extension: 'pdf',
  },
  approvals: vendorPaymentDetailsApprovalHistory,
};

export const paymentRecommendationApplicationDetails: IPaymentRecommendationApplicationDetails =
  {
    buildingAddress: {
      addressLine1: 'Soho Studios',
      addressLine2: 'Soho',
      city: 'London',
      county: '',
      localAuthority: 'London LA',
      nameNumber: '1',
      postcode: 'NW1 1ST',
    },
    buildingName: 'Big Tower 1',
    counterSignedGfaFile: {
      name: 'Counter Signed GFA File',
      id: getGuid(),
      extension: 'pdf',
    },
    signedGfaFile: {
      name: 'Signed GFA File',
      id: getGuid(),
      extension: 'pdf',
    },
    responsibleEntityType: EResponsibleEntityType.Representative,
    isPaymentGoingToRepresentative: true,
  };

export const paymentRecommendationDetails: IPaymentRecommendationDetails = {
  paymentRecommendationId: getGuid(),
  moneyProtectionSchemeEvidenceFile: {
    id: getGuid(),
    name: 'Evidence File',
    extension: 'pdf',
  },
  paymentAmount: 2500,
  recommendationSent: false,
  dateRecommendationSent: new Date(),
  isApproved: undefined,
  dateRecommendationApproved: new Date(),
  rejectionReason: 'Poor evidence',
  ptfsClaimAmount: 0.0,
  ptfsSupportRequired: false,
  paymentStatus: EPaymentStatus.Requested,
  reference: 'ABC123456789',
  paymentReleaseDate: new Date(),
  isManual: false
};

export const additionalPaymentRecommendationDetails: IAdditionalPaymentRecommendationDetails = {
  paymentRecommendationId: getGuid(),
  evidenceFile: {
    id: getGuid(),
    name: 'Evidence File',
    extension: 'pdf',
  },
  paymentAmount: 2500,
  recommendationSent: false,
  dateRecommendationSent: new Date(),
  isApproved: undefined,
  dateRecommendationApproved: new Date(),
  rejectionReason: 'Poor evidence',
  ptfsSupportRequired: false,
  paymentStatus: EPaymentStatus.Requested,
  paymentReleaseDate: new Date(),
};
