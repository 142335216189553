import { Box, Divider, Typography } from '@mui/material';
import { useGetPaymentRecommendationApplicationDetailsQuery } from 'api/application/paymentApi';
import * as roundBorderBox from 'common/components/roundBorderBox';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { IRecommendPaymentMain } from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';

export const ApplicationOverviewSection = () => {
  const { applicationId } = useApplicationContext();
  const query =
    useGetPaymentRecommendationApplicationDetailsQuery(applicationId);

  return (
    <roundBorderBox.RoundBorderBox sx={{ p: 2 }}>
      <Box mb={2}>
        <Typography variant="h3" fontWeight={900} fontSize={'1.05em'}>
          Application Details
        </Typography>
      </Box>
      <ApplicationOverviewSectionData
        data={query.data as IRecommendPaymentMain['recommend']}
      />
    </roundBorderBox.RoundBorderBox>
  );
};

export const ApplicationOverviewSectionData = ({
  data,
}: {
  data: IRecommendPaymentMain['recommend'];
}) => {
  const stackedData: [string, string][] = [
    ['Building Name', data?.buildingName ?? ''],
    [
      'Building Address',
      data?.buildingAddress && data?.buildingAddress?.nameNumber
        ? `${data.buildingAddress?.nameNumber} ${data.buildingAddress?.addressLine1}, ${data.buildingAddress?.postcode}`
        : '-',
    ],
  ];

  return (
    <>
      {stackedData.map(([title, value], i) => (
        <PaymentDetailsSectionStacked key={i} title={title} value={value} />
      ))}
    </>
  );
};

const PaymentDetailsSectionStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <>
      <Box>
        <DataTitle title={title} />
      </Box>
      <Box>
        <Typography variant="body1" fontWeight={600}>
          {value}
        </Typography>
      </Box>
      <Divider sx={{ my: 1.5 }} />
    </>
  );
};

const DataTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      variant="body1"
      color="grey.700"
      fontWeight={'600'}
      fontSize={'0.95em'}
    >
      {title}
    </Typography>
  );
};
