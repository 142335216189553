export interface IPullinProcessCommunication {
  communicationId: string;
  communicationTypeId: EPullInCommunicationType;
  recipientName?: string;
  sentByName?: string;
  isAutomated: boolean;
  isInbound?: boolean;
  addedOnDateTime: Date;
  sentOnDateTime?: Date;
  text?: string;
  communicationStageTypeId: 1 | 2;
}
// export interface IPullinProcessCommunication {
//   communicationTypeId: number;
//   dateTimeCommunicationReceived: Date;
//   dateTimeCommunicationRecorded: Date;
//   communicationAddedByUserId: string;
//   notes: string;
//   attachmentFileId: string;
//   isAutomated: boolean;
//   isInbound: boolean;
//   communicationStageTypeId: number;
//   communicationTypeSequence: number;
//   govNotifyNotificationId: string;
//   gotNotifyNotificationReference: string;
//   govNotifyNotificationTypeId: number;
//   govNotifyNotificationStatusTypeId: number;
// }

export type TCommunicationsBase = {
  communicationId?: string;
  communicationTypeId: EPullInCommunicationType;
  communicationReceivedDateTime: string;
  notes: string;
  isInbound: boolean;
};

export type TCommunicationsForm = TCommunicationsBase & {
  attachmentFileId: string;
  fileTitle?: string;
};
export type TCommunication = TCommunicationsBase & {
  attachmentFile: { id: string; name: string };
};

export enum EGovNotificationStatusType {
  Created = 1,
  Sending = 2,
  Delivered = 3,
  PermanentFailure = 4,
  TemporaryFailure = 5,
  TechnicalFailure = 6,
  LetterAccepted = 7,
  LetterReceived = 8,
  LetterCancelled = 9,
  LetterPendingVirusCheck = 10,
  LetterVirusScanFailed = 11,
  LetterValidationFailed = 12,
  LetterTechnicalFailure = 13,
  LetterPermanentFailure = 14,
}

export const EGovNotificationStatusTypeLanguage: {
  [key in EGovNotificationStatusType]: string;
} = {
  [EGovNotificationStatusType.Created]: 'Created',
  [EGovNotificationStatusType.Sending]: 'Sending',
  [EGovNotificationStatusType.Delivered]: 'Delivered',
  [EGovNotificationStatusType.PermanentFailure]: 'Permanent Failure',
  [EGovNotificationStatusType.TemporaryFailure]: 'Temporary Failure',
  [EGovNotificationStatusType.TechnicalFailure]: 'Technical Failure',
  [EGovNotificationStatusType.LetterAccepted]: 'Letter Accepted',
  [EGovNotificationStatusType.LetterReceived]: 'Letter Received',
  [EGovNotificationStatusType.LetterCancelled]: 'Letter Cancelled',
  [EGovNotificationStatusType.LetterPendingVirusCheck]:
    'Letter Pending Virus Check',
  [EGovNotificationStatusType.LetterVirusScanFailed]:
    'Letter Virus Scan Failed',
  [EGovNotificationStatusType.LetterValidationFailed]:
    'Letter Validation Failed',
  [EGovNotificationStatusType.LetterTechnicalFailure]:
    'Letter Technical Failure',
  [EGovNotificationStatusType.LetterPermanentFailure]:
    'Letter Permanent Failure',
} as const;

export enum EGovNotificationType {
  Email = 1,
  Letter = 2,
}

export const EGovNotificationTypeLanguage: {
  [key in EGovNotificationType]: string;
} = {
  [EGovNotificationType.Email]: 'Email',
  [EGovNotificationType.Letter]: 'Letter',
} as const;

export enum EPullInCommunicationType {
  Phone = 1,
  Letter = 2,
  Email = 3,
  Note = 4,
}

export const EPullInCommunicationTypeLanguage: {
  [key in EPullInCommunicationType]: string;
} = {
  [EPullInCommunicationType.Phone]: 'Phone',
  [EPullInCommunicationType.Letter]: 'Letter',
  [EPullInCommunicationType.Email]: 'Email',
  [EPullInCommunicationType.Note]: 'Note',
} as const;
