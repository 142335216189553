import { rest } from 'msw';
import { addBuildingRecordData } from 'mocks/data/pullInProcess/addBuildingRecordData';

export const addBuildingRecordHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/address/:postcode`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        // const postCode = req.params.postcode;
        return res(ctx.status(200), ctx.json(addBuildingRecordData));
      }
      return req.passthrough();
    }
  ),
];
