import { Typography } from '@mui/material';
import { useGetEligibilitySummaryQuery } from 'api/application';
import {
  ColumnContainer,
  DetailItem,
  ApplicationFileDownloadWrapper,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const LandRegistryChecksInfoPanel = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetEligibilitySummaryQuery(applicationId);

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Application Details
        </Typography>
        {query.data ? (
          <>
            <DetailItem
              label="Building name"
              value={query.data.buildingName ? query.data.buildingName : '-'}
            />
            <DetailItem
              label="Address of building"
              value={
                query.data.buildingAddress &&
                query.data.buildingAddress.nameNumber
                  ? `${query.data.buildingAddress.nameNumber} ${query.data.buildingAddress.addressLine1}, ${query.data.buildingAddress.postcode}`
                  : '-'
              }
            />
            <DetailItem label="Company Name" value={query.data.companyName} />
            <DetailItem
              label="Company Address"
              value={
                query.data.companyAddress &&
                query.data.companyAddress.nameNumber
                  ? `${query.data.companyAddress.nameNumber} ${query.data.companyAddress.addressLine1}, ${query.data.companyAddress.postcode}`
                  : '-'
              }
            />
            <DetailItem
              label="CRN"
              value={query.data.companyNumber ? query.data.companyNumber : '-'}
            />
            <DetailItem label="Responsible Entity Evidence">
              {query.data.responsibleEntityEvidenceFile ? (
                <ApplicationFileDownloadWrapper
                  fileName={query.data.responsibleEntityEvidenceFile.name}
                  fileId={query.data.responsibleEntityEvidenceFile.id}
                />
              ) : (
                '-'
              )}
            </DetailItem>
          </>
        ) : null}
      </ColumnContainer>
    </>
  );
};
