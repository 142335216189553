import { useState } from 'react';
import { faAdd, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Grid,
  IconButton,
  SortDirection,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetApplicationDocumentsQuery } from 'api/application/documentsApi';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import { ESortDirection } from 'enums/ESortDirection';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { DeleteDocumentDialog } from 'pages/applicationPage/content/documents/DeleteDocumentDialog';
import { DocumentsFilters } from 'pages/applicationPage/content/documents/DocumentsFilters';
import { UploadDocumentDialog } from 'pages/applicationPage/content/documents/UploadDocumentDialog';
import { useAppDispatch } from 'state';
import { selectApplicationDocumentsGridState } from 'state/selectors/applications/applicationsSelectors';
import { documentsGridStateChanged } from 'state/slices/applications';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { IApplicationDocument } from 'types/applications/ApplicationDocumentTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const Documents = () => {
  const dispatch = useAppDispatch();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasUploadDocuments = doesUserHaveSinglePermission('documents.upload');
  const hasDeleteDocuments = doesUserHaveSinglePermission('documents.delete');

  const applicationDocumentsGridState = useSelector(
    selectApplicationDocumentsGridState
  );

  const query = useGetApplicationDocumentsQuery(applicationDocumentsGridState);

  const noResultsFound =
    query.data?.count === 0 && !!applicationDocumentsGridState.searchValue;

  const { skip, take, sortBy, sortDirection } = applicationDocumentsGridState;

  const currentPage = skip / take;

  const sortHandler = (property: keyof IApplicationDocument): void => {
    dispatch(
      documentsGridStateChanged({
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      })
    );
  };

  const getAriaSortDirection = (
    property: keyof IApplicationDocument,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getButtonAriaSortDirection = (
    property: keyof IApplicationDocument,
    direction: ESortDirection
  ): 'ascending' | 'descending' => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'ascending'
      : 'descending';
  };

  const getCurrentSortDirection = (
    property: keyof IApplicationDocument,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof IApplicationDocument,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          aria-sort={getButtonAriaSortDirection(property, sortDirection)}
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  const {
    isShowing: isUploadDocumentDialogShowing,
    showModal: showUploadDocumentDialog,
    hideModal: hideUploadDocumentDialog,
  } = useModalState();

  const {
    isShowing: isDeleteDocumentDialogShowing,
    showModal: showDeleteDocumentDialog,
    hideModal: hideDeleteDocumentDialog,
  } = useModalState();

  const [selectedRowDocumentId, setSelectedRowDocumentId] = useState<
    string | null
  >(null);

  const onDeleteDocumentClick = (rowId: string) => {
    setSelectedRowDocumentId(rowId);
    showDeleteDocumentDialog(true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" fontWeight={900}>
            Documents ({query.data?.count})
          </Typography>
        </Grid>
        {hasUploadDocuments && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              onClick={showUploadDocumentDialog}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
            >
              Upload Document
            </Button>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid item xs={12}>
            <DocumentsFilters />
          </Grid>
          <NoResultsFoundText show={noResultsFound} text="No Documents." />
          <StyledGenericTable data-testid="Application-Documents-Table">
            <TableHead>
              <TableRow>
                {getHeaderTableCell('isKeyDocument', 'Key Document')}
                {getHeaderTableCell('filename', 'File Name')}
                {getHeaderTableCell('documentType', 'Document Type')}
                {getHeaderTableCell('categoryName', 'Category Name')}
                {getHeaderTableCell('uploadDate', 'Upload Date')}
                {getHeaderTableCell('uploadedBy', 'Uploaded By')}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {query.data?.results?.map(row => (
                <TableRow key={row.fileId}>
                  <TableCell style={{ width: '3rem' }}>
                    {row.isKeyDocument ? (
                      <FontAwesomeIcon icon={faStar} />
                    ) : null}
                  </TableCell>
                  <TableCell>{row.filename}</TableCell>
                  <TableCell>{row.documentType}</TableCell>
                  <TableCell>{row.categoryName}</TableCell>
                  <TableCell>
                    {row.uploadDate
                      ? getUkDateStringFromJsonDateString(row.uploadDate)
                      : ''}
                  </TableCell>
                  <TableCell>{row.uploadedBy}</TableCell>
                  <TableCell align="right" aria-label="Download Action">
                    <ApplicationFileDownloadWrapper
                      showIconOnly
                      fileName={row.filename}
                      fileId={row.fileId}
                    />
                  </TableCell>
                  <TableCell align="right" aria-label="Delete Action">
                    {hasDeleteDocuments && row.canDelete && (
                      <>
                        <IconButton
                          aria-label="Delete File"
                          name="Delete File"
                          onClick={event => onDeleteDocumentClick(row.fileId)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={query.data?.count || 0}
                  rowsPerPage={take}
                  rowsPerPageOptions={[take]}
                  page={currentPage}
                  onPageChange={(e, newPage) => {
                    const newSkip = newPage * take;
                    dispatch(documentsGridStateChanged({ skip: newSkip }));
                  }}
                  onRowsPerPageChange={e => {
                    dispatch(
                      documentsGridStateChanged({
                        take: +e.target.value,
                      })
                    );
                  }}
                />
              </TableRow>
            </TableFooter>
          </StyledGenericTable>
        </Grid>
      </Grid>

      {isUploadDocumentDialogShowing && (
        <UploadDocumentDialog
          onConfirm={() => {
            hideUploadDocumentDialog();
            query.refetch();
          }}
          onCancel={() => {
            hideUploadDocumentDialog();
          }}
        />
      )}
      {isDeleteDocumentDialogShowing && selectedRowDocumentId && (
        <DeleteDocumentDialog
          documentId={selectedRowDocumentId}
          onConfirm={() => {
            setSelectedRowDocumentId(null);
            hideDeleteDocumentDialog();
            query.refetch();
          }}
          onCancel={() => {
            setSelectedRowDocumentId(null);
            hideDeleteDocumentDialog();
          }}
        />
      )}
    </>
  );
};
