import { Chip, Grid, Typography } from '@mui/material';
import { IScheduleOfWorksPaymentItem } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { CostProfileSectionBox } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileSectionBox';
import { getGbpAmount } from 'util/AppUtils';

export const CostProfileGrantPaidToDateSection = ({
  grantPaidToDate,
}: {
  grantPaidToDate?: Array<IScheduleOfWorksPaymentItem> | null;
}) => {
  return (
    <>
      <CostProfileSectionBox>
        <Typography variant="h3" fontWeight={800} fontSize="1.3em">
          Grant paid to date
        </Typography>
      </CostProfileSectionBox>
      <CostProfileSectionBox>
        {grantPaidToDate && grantPaidToDate.length > 0 ? (
          grantPaidToDate?.map((row, index) => (
            <ScheduleOfWorksPaymentItemRow
              key={index}
              scheduleOfWorksPaymentItem={row}
            />
          ))
        ) : (
          <Typography variant="subtitle1" fontSize="1em">
            No Grant Paid To Date
          </Typography>
        )}
      </CostProfileSectionBox>
      {grantPaidToDate && grantPaidToDate.length > 0 && (
        <CostProfileSectionBox>
          <ScheduleOfWorksPaymentTotalRow
            total={grantPaidToDate?.reduce(
              (sum, current) => sum + current.amount,
              0
            )}
          />
        </CostProfileSectionBox>
      )}
    </>
  );
};

const ScheduleOfWorksPaymentItemRow = ({
  scheduleOfWorksPaymentItem,
}: {
  scheduleOfWorksPaymentItem: IScheduleOfWorksPaymentItem;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={2}
      wrap="nowrap"
      sx={{
        mb: 1,
      }}
    >
      <Grid item xs={8}>
        <Typography variant="subtitle1" fontSize="1em">
          {scheduleOfWorksPaymentItem.itemName}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {!!scheduleOfWorksPaymentItem.amount
          ? getGbpAmount({ value: scheduleOfWorksPaymentItem.amount })
          : '-'}
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        <Chip
          label={scheduleOfWorksPaymentItem.paid ? 'Paid' : 'Not Paid'}
          color={scheduleOfWorksPaymentItem.paid ? 'success' : 'warning'}
          variant={scheduleOfWorksPaymentItem.paid ? 'filled' : 'outlined'}
        />
      </Grid>
    </Grid>
  );
};

const ScheduleOfWorksPaymentTotalRow = ({ total }: { total?: number }) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={2}
      wrap="nowrap"
      sx={{
        mb: 1,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em">
          Total
        </Typography>
      </Grid>
      <Grid item xs={4} textAlign={'right'}>
        {!!total ? getGbpAmount({ value: total }) : '-'}
      </Grid>
    </Grid>
  );
};
