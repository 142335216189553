import { useCallback } from 'react';
import { useAuth } from 'oidc-react';
import { useLocalSnackbar } from 'hooks';

export const useLogout = () => {
  const authContext = useAuth();
  const { createSuccessSnackbar } = useLocalSnackbar();
  const logout = useCallback(() => {
    authContext.signOutRedirect();
    createSuccessSnackbar('Logged out successfully');
  }, [authContext, createSuccessSnackbar]);

  return { logout };
};
