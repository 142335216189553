import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  GetApplicationGrantFundingCategoriesQueryResult,
  useGetApplicationGrantFundingCategoriesQuery,
} from 'api/application';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  IApplicationGrantFundingCategory,
  IApplicationGrantFundingItem,
} from 'types/applications/ApplicationGrantFundingTypes';

interface IApplicationGrantFundingContext {
  applicationId: string;
  applicationGrantFundingCategoriesQuery: GetApplicationGrantFundingCategoriesQueryResult;
  selectedItemId: string | null;
  setSelectedItemId: (itemId: string) => void;
  selectedItem: IApplicationGrantFundingItem | undefined;
  readOnly: boolean | undefined;
}

const ApplicationGrantFundingContext = createContext<
  IApplicationGrantFundingContext | undefined
>(undefined);

interface IApplicationGrantFundingContextProviderProps {
  children: ReactNode;
}

export const ApplicationGrantFundingContextProvider = ({
  children,
}: IApplicationGrantFundingContextProviderProps) => {
  const { applicationId, hasApplicationsGrantFundingEdit, closed } =
    useApplicationContext();
  const applicationGrantFundingCategoriesQuery =
    useGetApplicationGrantFundingCategoriesQuery(applicationId);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const flatItems = getFlattenedItems(
    applicationGrantFundingCategoriesQuery.data?.categories ?? []
  );

  useDefaultItemId(flatItems, selectedItemId, setSelectedItemId);

  const selectedItem = flatItems.find(item => item.id === selectedItemId);

  const handleSetSelectedItemId = (itemId: string) => {
    setSelectedItemId(itemId);
  };

  const isGrantFundingCompleted = flatItems.every(item => item.isComplete);

  const readOnly =
    !hasApplicationsGrantFundingEdit || isGrantFundingCompleted || closed;

  const value = useMemo(
    () => ({
      applicationId,
      applicationGrantFundingCategoriesQuery,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      selectedItem,
      readOnly,
    }),
    [
      applicationId,
      applicationGrantFundingCategoriesQuery,
      selectedItemId,
      selectedItem,
      readOnly,
    ]
  );

  return (
    <ApplicationGrantFundingContext.Provider value={value}>
      {children}
    </ApplicationGrantFundingContext.Provider>
  );
};

export const useApplicationGrantFundingContext = () => {
  const context = useContext(ApplicationGrantFundingContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationGrantFundingContext must be used within an ApplicationGrantFundingContextProvider'
    );
  }
  return context;
};

const useDefaultItemId = (
  flatItems: IApplicationGrantFundingItem[],
  selectedItemId: IApplicationGrantFundingContext['selectedItemId'],
  setSelectedItemId: IApplicationGrantFundingContext['setSelectedItemId']
) => {
  useEffect(() => {
    if (!selectedItemId && flatItems.length > 0) {
      // There is no selected id but we have some items so use the first one
      setSelectedItemId(flatItems[0].id);
    }
  }, [flatItems, selectedItemId, setSelectedItemId]);
};

const getFlattenedItems = (categories: IApplicationGrantFundingCategory[]) => {
  return categories.reduce<IApplicationGrantFundingItem[]>((prev, curr) => {
    return [...prev, ...curr.detailItems];
  }, []);
};
