import { IFile } from 'common/types/File';

export enum EPaymentSection {
  VendorSetup = '1',
  RecommendPayment = '2',
  ReleasePayment = '3',
  AdditionalRecommendPayment = '4',
  AdditionalReleasePayment = '5',
}

export enum EPaymentStatus {
  Requested = 1,
  Processed = 2,
  Failed = 3,
}

export enum EVendorAccountSetupStatus {
  NotStarted = 0,
  Complete = 1,
}

export const EVendorAccountSetupStatusLanguage: {
  [key in EVendorAccountSetupStatus]: string;
} = {
  [EVendorAccountSetupStatus.NotStarted]: 'Not Started',
  [EVendorAccountSetupStatus.Complete]: 'Complete',
} as const;

export const EPaymentStatusLanguage: {
  [key in EPaymentStatus]: string;
} = {
  [EPaymentStatus.Requested]: 'Requested',
  [EPaymentStatus.Processed]: 'Processed',
  [EPaymentStatus.Failed]: 'Failed',
} as const;

export enum EResponsibleEntityType {
  ResponsibleEntity = 1,
  Representative = 2,
}

export enum EPTFSSupportType {
  NotSupported = 0,
  Supported = 1,
}

export interface IVendorAccountSetupSummary {
  status: EVendorAccountSetupStatus;
  paymentDetailsApproved?: boolean;
}

export interface IVendorPaymentDetails {
  name: string;
  reference: string;
  address: string[];
  telephoneNumber: string;
  vatRegistrationNumber: string;
  companyRegistrationNumber: string;
  email: string;
  bankDetails: IVendorBankDetails;
}

export interface IVendorPaymentMain {
  vendor: IVendorPaymentDetails;
  vendorPdfUri: string;
  detailsSentToHomesEngland: boolean | null;
  detailsSentEvidenceFile: IFile | null;
  approvals: IVendorAccountApproval[];
}

export interface IVendorRecommendDetails {
  buildingName: string;
  buildingAddress: {
    nameNumber: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    localAuthority: string;
  };
}

export interface IRecommendPaymentMain {
  recommend: IVendorRecommendDetails;
}

export interface IVendorAccountApproval {
  date: string;
  approved: boolean;
  reason: string;
  approvalEvidenceFile: IFile | null;
}

interface IVendorBankDetails {
  sortCode: string;
  accountNumber: string;
  accountName: string;
  bankName: string;
  bankBranch: string;
}

export interface IPaymentFileParams {
  fileName: string;
  applicationId: string;
}

export interface IUpdateVendorInfoSentStatus {
  applicationId: string;
  detailsSentToHomesEngland: boolean;
  detailsSentEvidenceFileId: string;
}

export interface IVendorInfoSentStatusForm {
  detailsSentToHomesEngland: boolean;
  detailsSentEvidenceFile: IFile | null;
  detailsSentEvidenceFileId: string;
}

export interface IVendorPaymentDetailsApprovalForm {
  paymentDetailsApproved: boolean | '';
  paymentApprovalReason: string;
  paymentApprovalEvidenceFile: IFile | null;
  paymentApprovalEvidenceFileId: string;
}

export interface IVendorPaymentDetailsApprovalParams {
  applicationId: string;
  approved: boolean;
  reason: string;
  approvalEvidenceFileId: string;
}

export interface IPaymentRecommendationApplicationDetails {
  buildingName: string;
  buildingAddress: {
    nameNumber: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    localAuthority: string;
  };
  signedGfaFile: IFile;
  counterSignedGfaFile: IFile;
  responsibleEntityType: EResponsibleEntityType;
  isPaymentGoingToRepresentative: boolean;
}

export interface IPaymentRecommendationDetails {
  paymentRecommendationId: string;
  moneyProtectionSchemeEvidenceFile: IFile | null;
  paymentAmount: number;
  dateRecommendationSent?: Date;
  recommendationSent: boolean;
  isApproved?: boolean;
  dateRecommendationApproved?: Date;
  rejectionReason?: string;
  ptfsClaimAmount: number;
  ptfsSupportRequired: boolean;
  paymentStatus: EPaymentStatus;
  reference: string;
  paymentReleaseDate?: Date;
  isManual: boolean;
}

export interface IAdditionalPaymentRecommendationDetails {
  paymentRecommendationId: string;
  evidenceFile: IFile | null;
  paymentAmount?: number;
  dateRecommendationSent?: Date;
  recommendationSent: boolean;
  isApproved?: boolean;
  dateRecommendationApproved?: Date;
  rejectionReason?: string;
  ptfsSupportRequired: boolean;
  paymentStatus: EPaymentStatus;
  paymentReleaseDate?: Date;
}

export interface IPaymentRecommendationEvidenceParams {
  applicationId: string;
  paymentRecommendationId: string;
  evidenceFileId: string;
}

export interface IPaymentRecommendationUpdate {
  applicationId: string;
}

export interface IAdditionalPaymentRecommendationUpdate {
  applicationId: string;
  paymentAmount: number;
  evidenceFileId: string,
}
export interface IPaymentReleaseApproval {
  applicationId: string;
  paymentRecommendationId: string;
}

export interface IPaymentRecommendationRejection {
  paymentRecommendationId: string;
  applicationId: string;
}

export interface IPaymentReleaseRejection {
  applicationId: string;
  reason: string;
  paymentRecommendationId: string;
}

export interface IPaymentAlreadyMade {
  applicationId: string;
  amount: number;
  paymentDate: string;
  paymentReference: string;
}