import { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useGetGrantFundingSummaryQuery } from 'api/application';
import { EQuestionType } from 'enums/EQuestionType';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ApplicationDetailsAnswerEditedIndicator } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsAnswerEditedIndicator';
import { ApplicationInfoPanelContextMenu } from 'pages/applicationPage/content/applicationDetails/components/ApplicationInfoPanelContextMenu';

export const GrantFundingCounterSignedDocumentationChecksInfoPanel = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetGrantFundingSummaryQuery(applicationId);

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Secretariat Details
        </Typography>
        {query.data ? (
          <>
            <DetailItem
              label="Secretariat Email"
              value={
                query.data.secretarialEmail ? query.data.secretarialEmail : '-'
              }
            />
          </>
        ) : null}
      </ColumnContainer>
      <ColumnContainer margin="10px">
        <Grid container gap={1} justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="h2" padding="16px">
              Bank Details
            </Typography>
          </Grid>
          <Grid item gap={1}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '2px'
              }}
            >
              <ApplicationDetailsAnswerEditedIndicator
                questionType={EQuestionType.UkBankAccountDetails}
              />
              <ApplicationInfoPanelContextMenu
                questionType={EQuestionType.UkBankAccountDetails}
              />
            </Box>
          </Grid>
        </Grid>
        {query.data ? (
          query.data?.bankDetails && query.data?.bankDetails.length > 0 ? (
            <>
              {query.data?.bankDetails.map((details, index) => {
                return (
                  <Fragment key={index}>
                    <DetailItem
                      label="Sort Code"
                      value={details.sortCode ? details.sortCode : '-'}
                      key={details.sortCode}
                    />
                    <DetailItem
                      label="Account Number"
                      value={
                        details.accountNumber ? details.accountNumber : '-'
                      }
                      key={details.accountNumber}
                    />
                    <DetailItem
                      label="Name"
                      value={details.name ? details.name : '-'}
                    />
                  </Fragment>
                );
              })}
            </>
          ) : (
            <DetailItem label="Bank Details" value="None found" />
          )
        ) : null}
      </ColumnContainer>
    </>
  );
};
