import { Dispatch, SetStateAction, useState } from 'react';
import {
  Autocomplete,
  Box,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { usePutApplicationOnHoldMutation } from 'api/application/applicationHoldApi';
import { useGetApplicationOnHoldReasonsQuery } from 'api/common/holdReasonsApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { IHoldReasons } from 'types/applications/HoldTypes';
import { extractErrorMessages } from 'util/ApiUtils';

interface IPutOnHoldModalProps {
  isShowing: boolean;
  hideModal: () => void;
  holdType: EOnHoldType;
}

export const PutOnHoldModal = ({
  isShowing,
  hideModal,
  holdType,
}: IPutOnHoldModalProps) => {
  const formId = 'PutOnHoldForm';
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  if (!isShowing) {
    return null;
  }

  return (
    <ConfirmationModal
      isShowing={isShowing}
      confirmButtonText="Put On Hold"
      cancelButtonText="Cancel"
      onCancel={hideModal}
      dialogActionsSx={{ m: 2 }}
      areButtonsLoading={isSubmitting}
      showActionsDivider
      fullWidth
      maxWidth="md"
      formId={formId}
      confirmButtonType='submit'
    >
      <Box p={3}>
        {isSubmitting ? (
          <LinearProgress />
        ) : null}

        <Typography variant="body1" fontWeight={600} fontSize={'1.2em'}>
          Put application on hold
        </Typography>

        <Typography variant="body1" py={3} color={'grey.700'}>
          Please ensure you have created a task to track this activity.
        </Typography>

        <Stack gap={2}>
          <PutOnHoldForm
            formId={formId}
            holdType={holdType}
            onClose={hideModal}
            setIsSubmitting={setIsSubmitting}
          />
        </Stack>
      </Box>
    </ConfirmationModal>
  );
};

interface IPutOnHoldFormProps {
  formId: string;
  holdType: EOnHoldType;
  onClose: () => void;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

export const PutOnHoldForm = ({
  formId,
  holdType,
  onClose,
  setIsSubmitting,
}: IPutOnHoldFormProps) => {
  const { applicationId } = useApplicationContext();

  const { data: holdReasonsData, isLoading: holdReasonsLoading } =
    useGetApplicationOnHoldReasonsQuery(holdType);

  const [putOnHold, putOnHoldStatus] = usePutApplicationOnHoldMutation();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const form = useForm<{ reason: IHoldReasons; otherReason: string }>({
    defaultValues: {
      reason: undefined,
      otherReason: '',
    },
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const [otherReasonRequired, setOtherReasonRequired] =
    useState<boolean>(false);

  const handleClick = async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      return;
    }

    try {
      setIsSubmitting(true);
      const { reason, otherReason } = form.getValues();
      putOnHold({
        applicationId,
        reasonId: (reason as IHoldReasons).id,
        otherReason,
        holdType,
      })
        .unwrap()
        .then(_ => {
          createSuccessSnackbar('Application has been put on hold');
          onClose();
        })
        .catch(error => {
          createErrorSnackbar(extractErrorMessages(error));
        });
    } catch (err) {
      createErrorSnackbar(`Failed to put application on hold`);
    }

    setIsSubmitting(false);
  };

  return holdReasonsLoading ? (
    <Loading isOpen />
  ) : (
    <>
      <form
        id={formId}
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(handleClick)(event);
        }}
      >
        <Controller
          control={control}
          name="reason"
          rules={{ required: 'Required' }}
          defaultValue={undefined}
          render={({ field: { onChange, ...fieldRest } }) => {
            return (
              <Autocomplete
                options={holdReasonsData ?? []}
                getOptionLabel={option => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(_, value) => {
                  onChange(value);
                  form.setValue('otherReason', '');
                  setOtherReasonRequired(value?.isOther ?? false);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    {...fieldRest}
                    label="Reason (required)"
                    error={!!errors.reason}
                    helperText={errors.reason?.message}
                  />
                )}
              />
            );
          }}
        />
        {otherReasonRequired && (
          <Controller
            control={form.control}
            name="otherReason"
            rules={otherReasonRequired ? { required: 'Required' } : undefined}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                multiline
                fullWidth
                label="Provide reason"
                error={!!fieldState.error}
                rows={3}
                helperText={fieldState.error?.message}
                sx={{ mb: 0 }}
                inputProps={{ maxLength: 250 }}
              />
            )}
          />
        )}
      </form>

      <AlertForQueryErrorOrNull
        isError={putOnHoldStatus.isError}
        error={putOnHoldStatus.error}
      />
    </>
  );
};
