import { useCallback } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetOrganisationsQuery } from 'api/administration/organisations';
import { useGetRolesQuery } from 'api/administration/roles';
import { GridSearchField } from 'common/components/gridSearchField';
import { useAppDispatch } from 'state';
import {
  selectAdministrationUsersGridState,
  selectIsAdministrationUsersGridLoading,
} from 'state/selectors/administration/administrationUsersSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationUsersReducer';
import {
  StyledFilter,
  StyledFiltersGridLeft,
  StyledFiltersGridRight,
} from 'styles/globalStyles/filterStyles';

export function AdministrationUsersFilters() {
  const dispatch = useAppDispatch();
  const organisationsQuery = useGetOrganisationsQuery();
  const rolesQuery = useGetRolesQuery();

  const administrationUsersGridState = useSelector(
    selectAdministrationUsersGridState
  );

  const isAdministrationUsersGridLoading = useSelector(
    selectIsAdministrationUsersGridLoading
  );

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      dispatch(
        gridStateChanged({
          searchValue,
          skip: 0,
        })
      );
    },
    [dispatch]
  );

  return (
    <Grid container pt={4}>
      <StyledFiltersGridLeft item xs={8}>
        <StyledFilter>
          <GridSearchField
            isLoading={isAdministrationUsersGridLoading}
            defaultValue={administrationUsersGridState.searchValue}
            handleSearch={handleSearchCallback}
          />
        </StyledFilter>
      </StyledFiltersGridLeft>
      <StyledFiltersGridRight item xs={4}>
        <StyledFilter>
          <Autocomplete
            multiple
            data-testid="role"
            disabled={isAdministrationUsersGridLoading}
            onChange={(_, selectedValues) => {
              dispatch(
                gridStateChanged({
                  roleId: selectedValues.map(selectedValue => selectedValue.id),
                  skip: 0,
                })
              );
            }}
            options={rolesQuery.data ? rolesQuery.data : []}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label="Role" />}
            size="small"
          />
        </StyledFilter>
        <StyledFilter>
          <Autocomplete
            multiple
            data-testid="organisation"
            disabled={isAdministrationUsersGridLoading}
            onChange={(_, selectedValues) => {
              dispatch(
                gridStateChanged({
                  organisationId: selectedValues.map(
                    selectedValue => selectedValue.id
                  ),
                  skip: 0,
                })
              );
            }}
            options={organisationsQuery.data ? organisationsQuery.data : []}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField {...params} label="Organisation" />
            )}
            size="small"
          />
        </StyledFilter>
      </StyledFiltersGridRight>
    </Grid>
  );
}
