import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetPaginatedSystemNotificationsListQuery } from 'api/administration/systemNotifications';
import { Loading } from 'common/components/loading/Loading';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { AddEditSystemNotificationDrawer } from 'pages/administrationPage/components/administrationSystemNotificationsTab/components/addEditSystemNotificationPage/AddEditSystemNotificationDrawer';
import { DeleteSystemNotificationDialog } from 'pages/administrationPage/components/administrationSystemNotificationsTab/components/deleteSystemNotificationDialog/DeleteSystemNotificationDialog';
import { EAdminSystemNotificationsMenuType } from 'pages/administrationPage/components/administrationSystemNotificationsTab/types/EAdminSystemNotificationsMenuType';
import { useAppDispatch } from 'state';
import { selectAdministrationSystemNotificationsGridState } from 'state/selectors/administration/administrationSystemNotificationsSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationSystemNotificationsReducer';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export function AdministrationSystemNotificationsGrid() {
  const dispatch = useAppDispatch();

  const administrationSystemNotificationsGridState = useSelector(
    selectAdministrationSystemNotificationsGridState
  );
  const {
    data: systemNotificationsData,
    isLoading: isSystemNotificationsGridLoading,
    refetch: systemNotificationsRefetch,
  } = useGetPaginatedSystemNotificationsListQuery(
    administrationSystemNotificationsGridState
  );

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasAdminSystemNotifications = doesUserHaveSinglePermission(
    'admin.system-notification'
  );

  const { skip, take } = administrationSystemNotificationsGridState;

  const currentPage = skip / take;

  const getHeaderTableCell = (columnLabel: string): JSX.Element => {
    return <TableCell>{columnLabel}</TableCell>;
  };

  const {
    isShowing: isAddEditSystemNotificationDrawerShowing,
    showModal: showAddEditSystemNotificationDrawer,
    hideModal: hideAddEditSystemNotificationDrawer,
  } = useModalState();

  const {
    isShowing: isDeleteSystemNotificationDialogShowing,
    showModal: showDeleteSystemNotificationDialog,
    hideModal: hideDeleteSystemNotificationDialog,
  } = useModalState();

  const [selectedRowSystemNotificationId, setSelectedRowSystemNotificationId] =
    useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = (menuType: EAdminSystemNotificationsMenuType) => {
    switch (menuType) {
      case EAdminSystemNotificationsMenuType.EditSystemNotification:
        showAddEditSystemNotificationDrawer(true);
        break;
      case EAdminSystemNotificationsMenuType.DeleteSystemNotification:
        showDeleteSystemNotificationDialog(true);
        break;
    }

    setAnchorEl(null);
  };

  const onEllipsisClick = (
    rowId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedRowSystemNotificationId(rowId);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Loading isOpen={isSystemNotificationsGridLoading} />
      <StyledGenericTable data-testid="Administration-SystemNotifications-Table">
        <TableHead>
          <TableRow>

            {getHeaderTableCell('Notification')}
            {getHeaderTableCell('Start Date')}
            {getHeaderTableCell('End Date')}
            {getHeaderTableCell('Last Updated By')}
          </TableRow>
        </TableHead>
        <TableBody>
          {systemNotificationsData?.results?.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.message}</TableCell>
              <TableCell>
                {getUkDateStringFromJsonDateString(row.startDate)}
              </TableCell>
              <TableCell>
                {getUkDateStringFromJsonDateString(row.endDate)}
              </TableCell>
              <TableCell>{row.lastUpdatedBy}</TableCell>
              <TableCell align="right" aria-label="Actions">
                {hasAdminSystemNotifications && (
                  <>
                    <IconButton
                      aria-label="edit"
                      name="edit"
                      onClick={event => onEllipsisClick(row.id, event)}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                    {selectedRowSystemNotificationId === row.id ? (
                      <Menu
                        id={`admin-user-menu-${row.id}`}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem
                          onClick={() =>
                            handleClose(
                              EAdminSystemNotificationsMenuType.EditSystemNotification
                            )
                          }
                        >
                          Edit System Notification
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleClose(
                              EAdminSystemNotificationsMenuType.DeleteSystemNotification
                            )
                          }
                        >
                          Delete System Notification
                        </MenuItem>
                      </Menu>
                    ) : null}
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={systemNotificationsData?.count || 0}
              rowsPerPage={take}
              rowsPerPageOptions={[take]}
              page={currentPage}
              onPageChange={(e, newPage) => {
                const newSkip = newPage * take;
                dispatch(gridStateChanged({ skip: newSkip }));
              }}
              onRowsPerPageChange={e => {
                dispatch(
                  gridStateChanged({
                    take: +e.target.value,
                  })
                );
              }}
            />
          </TableRow>
        </TableFooter>
      </StyledGenericTable>
      {isAddEditSystemNotificationDrawerShowing &&
        selectedRowSystemNotificationId && (
          <AddEditSystemNotificationDrawer
            systemNotificationId={selectedRowSystemNotificationId}
            onSuccess={() => {
              setSelectedRowSystemNotificationId(null);
              hideAddEditSystemNotificationDrawer();
            }}
            onClose={() => {
              setSelectedRowSystemNotificationId(null);
              hideAddEditSystemNotificationDrawer();
            }}
          />
        )}
      {isDeleteSystemNotificationDialogShowing &&
        selectedRowSystemNotificationId && (
          <DeleteSystemNotificationDialog
            systemNotificationId={selectedRowSystemNotificationId}
            onConfirm={() => {
              setSelectedRowSystemNotificationId(null);
              hideDeleteSystemNotificationDialog();
              systemNotificationsRefetch();
            }}
            onCancel={() => {
              setSelectedRowSystemNotificationId(null);
              hideDeleteSystemNotificationDialog();
            }}
          />
        )}
    </>
  );
}
