import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, TabProps } from '@mui/material';
import { a11yPropsForTab } from 'util/AppUtils';

interface CustomTabProps extends TabProps {
  currentSelectedTab: string;
  showWarningIcon?: boolean;
}

export function CustomTab(props: CustomTabProps) {
  const { currentSelectedTab, showWarningIcon = false, ...rest } = props;

  const { value } = rest;

  const tabHover = {
    '&:hover': {
      border: '1px solid 1px solid rgba(0, 0, 0, 0.12)',
      color: '#474747',
      backgroundColor: '#eceff1',
    },
  };

  return (
    <Tab
      sx={tabHover}
      {...a11yPropsForTab({
        value,
        currentSelectedTab,
      })}
      {...rest}
      icon={
        showWarningIcon ? (
          <FontAwesomeIcon
            icon={faExclamation}
            size="xs"
            fixedWidth
            style={{
              marginLeft: '0.3rem',
              background: 'rgba(255, 0, 0, 0.22)',
              borderRadius: '22px',
              padding: '3px',
              color: '#d32f2f',
              width: '10px',
              height: '10px',
            }}
          />
        ) : undefined
      }
      iconPosition="end"
    />
  );
}
