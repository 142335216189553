import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { parseISO, differenceInCalendarMonths } from 'date-fns';
import { useGetScheduleOfWorksChecksDetailsQuery } from 'api/application/scheduleOfWorksApi';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { WorksContractInfoPanel } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/ScheduleOfWorksChecksSection/WorksContractInfoPanel';
import { formatDateMonthYear } from 'util/DateUtils';

const boxWithBorderBottom: SxProps<Theme> = {
  padding: '8px 14px 8px 14px',
  overflowWrap: 'anywhere',
  borderBottom: '1px solid',
  borderColor: 'grey.300',
};

const startDisplayDate = (date: string): string => {
  const dateDiffFromNow: number = differenceInCalendarMonths(
    new Date(date),
    new Date()
  );

  const isOneMonthAgo = dateDiffFromNow === 1;

  return `${formatDateMonthYear(parseISO(date))} (${
    Math.sign(dateDiffFromNow) === 1 ? '+' : '-'
  }  ${Math.abs(dateDiffFromNow)} ${isOneMonthAgo ? 'month' : 'months'})`;
};

const endDisplayDate = (date: string): string => {
  const dateDiffFromNow: number = differenceInCalendarMonths(
    new Date(date),
    new Date()
  ) + 1;

  return `${formatDateMonthYear(parseISO(date))} (${
    Math.sign(dateDiffFromNow) === 1 ? '+' : '-'
  }  ${Math.abs(dateDiffFromNow)} months)`;
};

export const ScheduleOfWorksChecksSectionInfoPanel = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        scheduleOfWorksId: selectedItem.id,
      }
    : skipToken;

  const query = useGetScheduleOfWorksChecksDetailsQuery(request ?? skipToken);

  return (
    <Stack rowGap={2}>
      <Card title="Projected dates">
        {query.data ? (
          <>
            <DetailItem
              label="Start date"
              value={
                query.data.startDate ? startDisplayDate(query.data.startDate) : '-'
              }
              sx={boxWithBorderBottom}
            />
            <DetailItem
              label="End date"
              value={query.data.endDate ? endDisplayDate(query.data.endDate) : '-'}
            />
          </>
        ) : null}
      </Card>

      <Card title="Project costs">
        {query.data ? (
          <>
            <DetailItem
              label="Eligible costs"
              value={
                query.data.eligibleCosts
                  ? `£${query.data.eligibleCosts.toLocaleString()}`
                  : '-'
              }
              sx={boxWithBorderBottom}
            />
            <DetailItem
              label="Other costs"
              value={
                query.data.otherCosts
                  ? `£${query.data.otherCosts.toLocaleString()}`
                  : '-'
              }
              sx={boxWithBorderBottom}
            />
            <DetailItem
              label="Total costs"
              value={
                query.data.totalCosts
                  ? `£${query.data.totalCosts.toLocaleString()}`
                  : '-'
              }
            />
          </>
        ) : null}
      </Card>

      <Card title="Other information">
        {query.data ? (
          <>
            <DetailItem
              label="Lead Contractor Company"
              value={
                query.data.leadContractorCompany
                  ? query.data.leadContractorCompany
                  : '-'
              }
              sx={boxWithBorderBottom}
            />
            <DetailItem
              label="Building Height"
              value={
                query.data.buildingHeightMetres
                  ? query.data.buildingHeightMetres + ' metres'
                  : '-'
              }
            />
            <WorksContractInfoPanel
              data={query.data?.worksContractDocuments}
              title="Works contract"
            />
          </>
        ) : null}
      </Card>
    </Stack>
  );
};

const Card = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <ColumnContainer>
      <Typography variant="h2" p={2}>
        {title}
      </Typography>
      {children}
    </ColumnContainer>
  );
};
