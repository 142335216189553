import { useSaveVariationPreliminariesValuesMutation } from 'api/application/variationApi';
import {
  ECostScheduleParentType,
  PreliminariesForm,
} from 'common/components/costSchedule/forms';
import { useFormWrapperDependencies } from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationCostScheduleSection/forms';
import { IPreliminariesForm } from 'types/applications/ApplicationCostScheduleTypes';

export const PreliminariesFormWrapper = () => {
  const {
    queryParams,
    handleCancelClick,
    costScheduleData,
    handleUnwrappedSaveResult,
    previousCostScheduleData,
  } = useFormWrapperDependencies();

  const [save, saveStatus] = useSaveVariationPreliminariesValuesMutation();

  const saveFn = (formData: IPreliminariesForm) => {
    if (!queryParams) {
      return false;
    }

    handleUnwrappedSaveResult(save({ ...queryParams, formData }).unwrap());
  };

  if (!costScheduleData) {
    return null;
  }

  return (
    <PreliminariesForm
      parentType={ECostScheduleParentType.Variation}
      data={costScheduleData}
      originalData={previousCostScheduleData}
      handleCancelClick={handleCancelClick}
      saveFn={saveFn}
      saveStatus={saveStatus}
    />
  );
};
