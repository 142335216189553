import { HttpException } from 'api/exceptions';
import { A_TOKEN_KEY } from 'common/components/tokenRefresher/constants';
import {
  TransactionError,
  TransactionResult,
} from 'common/types/TransactionResult';

export const getHeaders = (contentType = 'application/json') => {
  const token = localStorage.getItem(A_TOKEN_KEY);
  return token
    ? new Headers({
        'Content-Type': contentType,
        Authorization: `Bearer ${token}`,
      })
    : new Headers({
        'Content-Type': contentType,
      });
};

const isContentTypeJSON = (headers: Headers) =>
  headers.get('content-type')?.includes('application/json');

const createTransactionResultFrom = <T>(
  response: Response
): TransactionResult<T> => ({
  ok: response.ok,
  status: response.status,
  data: null,
  error: null,
});

export const pullInProcessQueryTags = {
  PullInProcessGrid: 'PullInProcessGrid',
  PullInProcessKPI: 'PullInProcessKPI',
  AddBuildingRecord: 'AddBuildingRecord',
  PullInProcessSourceOfInformation: 'PullInProcessSourceOfInformation',
  GetAddress: 'GetAddress',
  PullInProcessSummary: 'PullInProcessSummary',
  PullInProcessOverview: 'PullInProcessOverview',
  PullInProcessContacts: 'PullInProcessContacts',
  PullInProcessContactDetails: 'PullInProcessContactDetails',
  AddPullInProcessContactDetails: 'AddPullInProcessContactDetails',
  UpdatePullInProcessContactDetails: 'UpdatePullInProcessContactDetails',
  PullInProcessDocuments: 'PullInProcessDocuments',
  PullInProcessCommunications: 'PullInProcessCommunications',
  PullInProcessBuildingDetails: 'PullInProcessBuildingDetails',
};

export const variationQueryTags = {
  ApplicationVariationOverview: 'ApplicationVariationOverview',
  ApplicationVariationCostSchedule: 'ApplicationVariationCostSchedule',
  ApplicationVariationCostScheduleOverview:
    'ApplicationVariationCostScheduleOverview',
  ApplicationVariationChecks: 'ApplicationVariationChecks',
  ApplicationVariationReferralList: 'ApplicationVariationReferralList',
  ApplicationVariationReferral: 'ApplicationVariationReferral',
  ApplicationVariationThirdPartyReferral:
    'ApplicationVariationThirdPartyReferral',
};

export const variationQueryTagsArray = Object.entries(variationQueryTags).map(
  kvp => kvp[1]
);

export const QueryTags = {
  Users: 'Users',
  User: 'User',
  Roles: 'Roles',
  Role: 'Role',
  SystemNotifications: 'SystemNotifications',
  AssessorPanelList: 'AssessorPanelLists',
  BenchmarkFigures: 'BenchmarkFigures',
  Tasks: 'Tasks',
  Notes: 'Notes',
  PTFS: 'PTFS',
  ApplicationDetails: 'ApplicationDetails',
  ApplicationStatus: 'ApplicationStatus',
  ApplicationIntervention: 'ApplicationIntervention',
  ApplicationRagRating: 'ApplicationRagRating',
  ApplicationEligibility: 'ApplicationEligibility',
  ApplicationGrantFunding: 'ApplicationGrantFunding',
  ApplicationSignatories: 'ApplicationSignatories',
  PEPSanctions: 'PEPSanctions',
  ApplicationDocuments: 'ApplicationDocuments',
  ApplicationCommunications: 'ApplicationCommunications',
  ApplicationVendorDetails: 'ApplicationVendorDetails',
  ApplicationPaymentRecommendationDetails:
    'ApplicationPaymentRecommendationDetails',
  ApplicationPaymentReleaseDetails: 'ApplicationPaymentReleaseDetails',
  ApplicationSupportList: 'ApplicationSupportList',
  ApplicationSupportTicket: 'ApplicationSupportTicket',
  ApplicationSupportResolutionReasons: 'ApplicationSupportResolutionReasons',
  ApplicationDutyOfCareDetails: 'ApplicationDutyOfCareDetails',
  ApplicationProjectPrepOverview: 'ApplicationProjectPrepOverview',
  ApplicationProjectPrepList: 'ApplicationProjectPrepList',
  ApplicationProjectPrepProgressReport: 'ApplicationProjectPrepProgressReport',
  ApplicationWorksPackageOverview: 'ApplicationWorksPackageOverview',
  ApplicationWorksPackageChecks: 'ApplicationWorksPackageChecks',
  ApplicationWorksPackageCladding: 'ApplicationWorksPackageCladding',
  ApplicationWorksPackageCostsSchedule: 'ApplicationWorksPackageCostsSchedule',
  ApplicationWorksPackageReferralList: 'ApplicationWorksPackageReferralList',
  ApplicationWorksPackageReferral: 'ApplicationWorksPackageReferral',
  ApplicationClosingPaymentReferral: 'ApplicationClosingPaymentReferral',
  ApplicationLiveProjectOverview: 'ApplicationLiveProjectOverview',
  ApplicationLiveProjectList: 'ApplicationLiveProjectList',
  ApplicationScheduleOfWorks: 'ApplicationScheduleOfWorks',
  ApplicationScheduleOfWorksOverview: 'ApplicationScheduleOfWorksOverview',
  ApplicationPaymentRequest: 'ApplicationPaymentRequest',
  ApplicationPaymentRequestOverview: 'ApplicationPaymentRequestOverview',
  ApplicationClosingPaymentReferralList:
    'ApplicationClosingPaymentReferralList',
  ApplicationClosingPaymentThirdPartyReferral:
    'ApplicationClosingPaymentThirdPartyReferral',
  ApplicationClosingPaymentRequest: 'ApplicationClosingPaymentRequest',
  ApplicationClosingPaymentRequestOverview:
    'ApplicationClosingPaymentRequestOverview',
  ApplicationWorksPackageDeedDetails: 'ApplicationWorksPackageDeedDetails',
  ApplicationWorksPackageBuildingDetails:
    'ApplicationWorksPackageBuildingDetails',
  ApplicationOnHoldLatest: 'ApplicationOnHoldLatest',
  ApplicationDetailsEdit: 'ApplicationDetailsEdit',
  Countries: 'Countrie',
  ...variationQueryTags,
  ...pullInProcessQueryTags,
} as const;

export const isTransactionError = (error: any) => {
  return (
    error &&
    'data' in error &&
    error.data &&
    ('generalError' in error.data || 'propertyErrors' in error.data)
  );
};

export const instanceOfTransactionErrorWithPropertyErrors = (
  error: any
): error is { data: TransactionError } => {
  return isTransactionError(error) && error?.data?.propertyErrors?.length > 0;
};

export const extractErrorMessages = (error: any) => {
  // We don't know exactly what the error shape will be at this point
  // This method attempts to make some deductions and tries to return something useful

  // Check if the error was part of a TransactionResult
  // If so, try to extract the error info from the various possible locations
  if (isTransactionError(error)) {
    const transactionError = error.data as TransactionError;
    if (transactionError.generalError?.errorMessage) {
      return transactionError.generalError.errorMessage;
    }

    if (transactionError.propertyErrors?.length > 0) {
      return transactionError.propertyErrors
        .map(propertyError => propertyError.errorMessage)
        .join('; ');
    }
  }

  if (error.status) {
    return `Received status: ${error.status}`;
  }

  return 'Could not extract error details';
};

export const genericGet = async <TResponse>(
  url: string,
  signal?: AbortSignal
): Promise<TransactionResult<TResponse>> => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaders(),
      signal,
    });

    const transactionResult = createTransactionResultFrom<TResponse>(response);

    const isJson = isContentTypeJSON(response.headers);

    if (response.status >= 200 && response.status <= 299) {
      transactionResult.data = isJson ? await response.json() : null;
    }

    if (response.status >= 400 && response.status <= 499) {
      transactionResult.error = isJson ? await response.json() : null;
      if (response.status === 404) {
        // needs a rethink
        throw new HttpException(404, 'Not Found');
      }
    }

    return transactionResult;
  } catch (reason) {
    // eslint-disable-next-line no-console
    console.log(`Could not connect to backend: ${reason}`);
    throw new HttpException(-1, reason as string);
  }
};

export const genericPost = async <TRequestBody, TResponse>(
  url: string,
  signal: AbortSignal,
  body?: TRequestBody
): Promise<TransactionResult<TResponse>> => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: body ? JSON.stringify(body) : null,
      headers: getHeaders(),
      signal,
    });

    const transactionResult = createTransactionResultFrom<TResponse>(response);

    const isJson = isContentTypeJSON(response.headers);

    if (response.status >= 200 && response.status <= 299) {
      transactionResult.data = isJson ? await response.json() : null;
    }

    if (response.status >= 400 && response.status <= 499) {
      transactionResult.error = isJson ? await response.json() : null;
      if (response.status === 404) {
        // needs a rethink
        throw new HttpException(404, 'Not Found');
      }
    }
    return transactionResult;
  } catch (reason) {
    throw new HttpException(-1, reason as string);
  }
};

export const genericPut = async <TRequestBody, TResponse>(
  url: string,
  signal?: AbortSignal,
  body?: TRequestBody
): Promise<TransactionResult<TResponse>> => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: body ? JSON.stringify(body) : null,
      headers: getHeaders(),
      signal,
    });

    const transactionResult = createTransactionResultFrom<TResponse>(response);

    const isJson = isContentTypeJSON(response.headers);

    if (response.status >= 200 && response.status <= 299) {
      transactionResult.data = isJson ? await response.json() : null;
    }

    if (response.status >= 400 && response.status <= 499) {
      transactionResult.error = isJson ? await response.json() : null;
      if (response.status === 404) {
        // needs a rethink
        throw new HttpException(404, 'Not Found');
      }
    }
    return transactionResult;
  } catch (reason) {
    throw new HttpException(-1, reason as string);
  }
};

export const genericDelete = async <TRequestBody>(
  url: string,
  signal: AbortSignal,
  body?: TRequestBody
): Promise<TransactionResult<boolean>> => {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: getHeaders(),
      signal,
    });

    const transactionResult = createTransactionResultFrom<boolean>(response);

    const isJson = isContentTypeJSON(response.headers);

    if (response.status >= 200 && response.status <= 299) {
      transactionResult.data = isJson ? await response.json() : null;
    }

    if (response.status >= 400 && response.status <= 499) {
      transactionResult.error = isJson ? await response.json() : null;
      if (response.status === 404) {
        // needs a rethink
        throw new HttpException(404, 'Not Found');
      }
    }
    return transactionResult;
  } catch (reason) {
    throw new HttpException(-1, reason as string);
  }
};
