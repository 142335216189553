export enum EPullInProcessStatus {
  NoContactDetails = 1,
  Live = 2,
  NotEligible = 3,
  ProgressedToApplication = 4,
  Duplicate = 5,
}

export const EPullInProcessStatusLanguage = {
  [EPullInProcessStatus.NoContactDetails]: 'No contact details',
  [EPullInProcessStatus.Live]: 'Live',
  [EPullInProcessStatus.NotEligible]: 'Not eligible',
  [EPullInProcessStatus.ProgressedToApplication]: 'Progressed to application',
  [EPullInProcessStatus.Duplicate]: 'Duplicate',
} as const;
