import { Chip } from '@mui/material';
import { useGetApplicationRagRatingQuery } from 'api/application';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const ApplicationRagRating = () => {
  const { applicationId } = useApplicationContext();
  const { data: applicationRagRating } =
    useGetApplicationRagRatingQuery(applicationId);

  const ragRatingBackgroundColor = (ratingName: string): string => {
    switch (ratingName) {
      case 'High':
        return '#8b0000';
      case 'Medium':
        return '#ff8c00';
      case 'Low':
        return '#2e7d32';
      default:
        return '#D3D3D3';
    }
  };

  const ragRatingForegroundColor = (ratingName: string): string => {
    switch (ratingName) {
      case 'High':
        return '#FFFFFF';
      case 'Medium':
        return '#000000';
      case 'Low':
        return '#FFFFFF';
      default:
        return '#000000';
    }
  };

  return (
    <>
      {applicationRagRating?.ratingName ? (
        <Chip
          sx={{
            backgroundColor: ragRatingBackgroundColor(
              applicationRagRating?.ratingName
            ),
            color: ragRatingForegroundColor(applicationRagRating?.ratingName),
          }}
          label={applicationRagRating?.ratingName}
        />
      ) : null}
    </>
  );
};
