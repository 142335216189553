import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';
import OpenSansBoldWoff from 'assets/fonts/OpenSans-Bold.woff';
import OpenSansBoldWoff2 from 'assets/fonts/OpenSans-Bold.woff2';
import OpenSansLightWoff from 'assets/fonts/OpenSans-Light.woff';
import OpenSansLightWoff2 from 'assets/fonts/OpenSans-Light.woff2';
import OpenSansRegularWoff from 'assets/fonts/OpenSans-Regular.woff';
import OpenSansRegularWoff2 from 'assets/fonts/OpenSans-Regular.woff2';
import OpenSansSemiBoldWoff from 'assets/fonts/OpenSans-SemiBold.woff';
import OpenSansSemiBoldWoff2 from 'assets/fonts/OpenSans-SemiBold.woff2';

export const muiCssBaselineTheme: {
  defaultProps?: ComponentsProps['MuiCssBaseline'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCssBaseline'];
  variants?: ComponentsVariants['MuiCssBaseline'];
} = {
  styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          src: local('OpenSans'), 
          local('OpenSans-Light'), 
          url(${OpenSansLightWoff2}) format('woff2'),
          url(${OpenSansLightWoff}) format('woff');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Open Sans';
          src: local('OpenSans'), 
          local('OpenSans-Regular'), 
          url(${OpenSansRegularWoff2}) format('woff2'),
          url(${OpenSansRegularWoff}) format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Open Sans';
          src: local('OpenSans'), 
          local('OpenSans-SemiBold'), 
          url(${OpenSansSemiBoldWoff2}) format('woff2'),
          url(${OpenSansSemiBoldWoff}) format('woff');
          font-weight: 600;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Open Sans';
          src: local('OpenSans'), 
          local('OpenSans-Bold'), 
          url(${OpenSansBoldWoff2}) format('woff2'),
          url(${OpenSansBoldWoff}) format('woff');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }
      `,
};
