import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ESortDirection } from 'enums/ESortDirection';
import { IGetPTFSListQuery , IPTFSGridListItem } from 'types/administration/AdministrationPTFSTypes';
import { nameof } from 'util/formUtils';

export const ptfsSortByTypes = [
  nameof<IPTFSGridListItem>('amount'),
  nameof<IPTFSGridListItem>('startDate'),
  nameof<IPTFSGridListItem>('endDate'),
  nameof<IPTFSGridListItem>('changedBy'),
  nameof<IPTFSGridListItem>('changeReason'),
  nameof<IPTFSGridListItem>('dateChanged'),
] as const;
export type PTFSSortByTypes =
  (typeof ptfsSortByTypes)[number];

export interface IAdministrationPTFSState {
  gridState: IGetPTFSListQuery;
}

const initialState: IAdministrationPTFSState = {
  gridState: {
    skip: 0,
    take: 25,
    sortBy: nameof<IPTFSGridListItem>('amount'),
    sortDirection: ESortDirection.Asc,
  },
};

const administrationPTFSSlice = createSlice({
  name: 'administrationPTFS',
  initialState,
  reducers: {
    gridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetPTFSListQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
    userAdministrationPTFSPage: () => initialState,
  },
});

export const administrationPTFSReducer = administrationPTFSSlice.reducer;
export const { gridStateChanged, userAdministrationPTFSPage } =
administrationPTFSSlice.actions;
