import { InputAdornment, TextField } from '@mui/material';
import { Controller, UseFormReturn, useFormContext } from 'react-hook-form';
import { IPaymentRequestCosts } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { hasDecimal } from 'util/AppUtils';

interface IPaymentRequestConfirmedCostFieldProps {
  arrayName: string;
  form: UseFormReturn<IPaymentRequestCosts, any>;
  index: number;
  scheduledCost: number;
  readOnly: boolean;
}

export function PaymentRequestConfirmedCostField({
  arrayName,
  form,
  index,
  scheduledCost,
  readOnly,
}: IPaymentRequestConfirmedCostFieldProps) {
  const { watch } = useFormContext();

  const watchCurrentValue = watch(`${arrayName}[${index}].confirmedCost`);
  const isValueEqual = watchCurrentValue?.toString() === scheduledCost.toString() || readOnly;

  return (
    <Controller
      name={`${arrayName}[${index}].confirmedCost`}
      render={({ field, fieldState }) => {
        return (
          <TextField
            sx={{
              borderLeft: isValueEqual ? '' : '4px solid red',
            }}
            {...field}
            type="number"
            error={!!fieldState.error}
            required
            helperText={fieldState.error?.message}
            disabled={readOnly}
            onChange={e => {
              if (hasDecimal(Number(e.target.value))) {
                return;
              }
              if (arrayName === 'monthlyCosts') {
                form.setValue(`monthlyCosts.${index}.isDirty`, true);
              } else {
                form.setValue(`currentPayments.${index}.isDirty`, true);
              }
              return field.onChange(e);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
              inputProps: {
                min: 0,
              },
            }}
          />
        );
      }}
    />
  );
}
