export enum EScheduleOfWorksReferralStatus {
  InProgress = 1,
  Overdue = 2,
  Complete = 3,
}

export const EScheduleOfWorksReferralLanguage: { [key in EScheduleOfWorksReferralStatus]: string } = {
  [EScheduleOfWorksReferralStatus.InProgress]: 'In Progress',
  [EScheduleOfWorksReferralStatus.Overdue]: 'Overdue',
  [EScheduleOfWorksReferralStatus.Complete]: 'Complete',
};
