import {
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
  Theme,
} from "@mui/material";

export const muiToggleButtonGroupTheme: {
  defaultProps?: ComponentsProps['MuiToggleButtonGroup'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiToggleButtonGroup'];
  variants?: ComponentsVariants['MuiToggleButtonGroup'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      background: "white",
      color: theme.palette.grey[600],
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px;',
      fontSize: '14px',
      wordSpacing: 'normal',
      letterSpacing: 'normal',
    }),
  },
};
