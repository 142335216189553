import { Button, Typography } from '@mui/material';
import {
  useGetUserQuery,
  useToggleUserBlockMutation,
} from 'api/administration/users';
import { Loading } from 'common/components/loading/Loading';
import { useLocalSnackbar } from 'hooks';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IToggleUserBlockDialogProps {
  userId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ToggleUserBlockDialog({
  userId,
  onConfirm,
  onCancel,
}: IToggleUserBlockDialogProps) {
  const { data: userData, isLoading } = useGetUserQuery(userId);
  const [toggleUserBlock] = useToggleUserBlockMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onToggleUserBlockUser = async () => {
    try {
      toggleUserBlock({
        id: userId,
        payload: { isActive: userData?.isBlocked },
      });
      createSuccessSnackbar(
        `Successfully ${userData?.isBlocked ? 'blocked' : 'unblock'} user`
      );
      onConfirm();
    } catch (err) {
      createErrorSnackbar(
        `Failed to ${userData?.isBlocked ? 'blocked' : 'unblock'} user`
      );
    }
  };

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you want to ${
          userData?.isBlocked ? 'unblock' : 'block'
        } this user: ${userData?.firstName} ${userData?.lastName}?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>You can update this setting at anytime.</Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onToggleUserBlockUser}>
          {userData?.isBlocked ? 'Unblock' : 'Block'} user
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
