import { Alert, Typography } from '@mui/material';
import { CardTitle } from 'common/components/cardTitle';
import {
  useVariationOverview,
  useVariationReferrals,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { formatDateTimeDayMonthYearHourMins } from 'util/DateUtils';

export const HomesEnglandReferralBanner = () => {
  const { query } = useVariationOverview();
  const { openHeReferral } = useVariationReferrals();

  if (!openHeReferral) {
    return null;
  }

  if (!query.data) {
    return null;
  }

  return (
    <Alert severity="info">
      <CardTitle
        title={`Variation ${query.data.variationNumber ?? ''} request referred`}
        fontSize="1.1em"
      />
      <Typography>
        This variation request was referred by {openHeReferral.referredBy} at{' '}
        {formatDateTimeDayMonthYearHourMins(openHeReferral.referredDateTime)}
      </Typography>
    </Alert>
  );
};
