import { Chip, Typography } from '@mui/material';
import { useGetFRAEWChecksQuery } from 'api/application';
import { ERiskStatusLanguage } from 'enums/ERiskStatus';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const FRAEWChecksSafetyRiskPanel = () => {
  const { applicationId } = useApplicationContext();

  const fraewQuery = useGetFRAEWChecksQuery(applicationId);

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Safety Risk Status
        </Typography>
        {fraewQuery.data ? (
          <>
            <DetailItem label="Status">
              <Chip
                label={
                  fraewQuery.data.correctSafetyRiskStatus
                    ? `${
                        ERiskStatusLanguage[
                          fraewQuery.data.correctSafetyRiskStatus
                        ]
                      } Safety Risk`
                    : '-'
                }
              />
            </DetailItem>
          </>
        ) : null}
      </ColumnContainer>
    </>
  );
};
