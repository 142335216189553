import { rest } from 'msw';
import {
  progressReportData,
  projectPrepListViewData,
  projectPrepOverviewData,
} from 'mocks/data/application/applicationProjectPrepData';

export const projectPrepHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/projectprep/overview`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(projectPrepOverviewData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/projectprep/list`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(projectPrepListViewData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/progressreport/:progressReportId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(progressReportData));
      }
      return req.passthrough();
    }
  ),
];
