import { Chip, Stack } from '@mui/material';
import {
  EVariationTypeLanguage,
  IVariationBoxProps,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { useEditTypesDrawer } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/VariationTypesBox/EditVariationTypesDrawer';
import { VariationOverviewBox } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/common';

export const VariationTypesBox = ({
  data,
  isEditable = false,
}: IVariationBoxProps) => {
  const editTypesDrawer = useEditTypesDrawer({
    initialTypes: data.variations.map(variation => variation.type),
  });
  return (
    <VariationOverviewBox
      title="Variation Type"
      onEditClick={
        !isEditable
          ? undefined
          : () => {
              editTypesDrawer.showDrawer();
            }
      }
    >
      <Stack direction={'row'} gap={2}>
        {data.variations.map((variation, i) => {
          return (
            <Chip
              key={i}
              label={EVariationTypeLanguage[variation.type]}
              size="small"
              sx={{ fontSize: '0.9em' }}
            />
          );
        })}
      </Stack>
      {editTypesDrawer.renderDrawer()}
    </VariationOverviewBox>
  );
};
