import { createContext, ReactNode, useContext, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useParams } from 'react-router-dom';
import { useGetApplicationSummaryQuery } from 'api/application/applicationApi';
import { EApplicationStatus } from 'enums/EApplicationStatus';
import { useCurrentUserPermissions } from 'hooks';

interface IApplicationContext {
  applicationId: string;
  applicationReference: string;
  hasApplicationsEligibilityView: boolean;
  hasApplicationsEligibilityEdit: boolean;
  hasApplicationEligibilityConfirm: boolean;
  hasApplicationsGrantFundingView: boolean;
  hasPaymentPermission: boolean;
  hasApplicationsGrantFundingEdit: boolean;
  hasApplicationsSignatoriesView: boolean;
  rejected: boolean;
  closed: boolean;
  isVendorPaymentApprovalWaiting: boolean;
  isScheduleOfWorksSubmitted: boolean;
}

const ApplicationContext = createContext<IApplicationContext | undefined>(
  undefined
);

interface IApplicationContextProviderProps {
  children: ReactNode;
}

export const ApplicationContextProvider = ({
  children,
}: IApplicationContextProviderProps) => {
  const { applicationId } = useParams();
  if (!applicationId) {
    throw new Error('Could not get application id');
  }

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasApplicationsEligibilityView = doesUserHaveSinglePermission(
    'applications.eligibility.view'
  );

  const hasApplicationsEligibilityEdit = doesUserHaveSinglePermission(
    'applications.eligibility.edit'
  );

  const hasApplicationEligibilityConfirm = doesUserHaveSinglePermission(
    'applications.eligibility.confirm'
  );

  const hasApplicationsGrantFundingView = doesUserHaveSinglePermission(
    'applications.grantfunding.view'
  );

  const hasApplicationsGrantFundingEdit = doesUserHaveSinglePermission(
    'applications.grantfunding.edit'
  );

  const hasPaymentPermission = doesUserHaveSinglePermission(
    'applications.payments'
  );

  const hasApplicationsSignatoriesView = doesUserHaveSinglePermission(
    'applications.admin.signatory'
  );

  const applicationSummary = useGetApplicationSummaryQuery(
    applicationId ?? skipToken
  );

  const rejected =
    applicationSummary.data?.status === EApplicationStatus.Rejected ||
    applicationSummary.data?.status ===
      EApplicationStatus.ApplicationNotEligible;

  const closed =
    applicationSummary.data?.status === EApplicationStatus.ApplicationClosed ||
    applicationSummary.data?.status ===
      EApplicationStatus.ApplicationClosedReferToGla;

  const isVendorPaymentApprovalWaiting =
    applicationSummary.data?.isVendorPaymentApprovalWaiting ?? false;

  const isScheduleOfWorksSubmitted =
    applicationSummary.data?.isScheduleOfWorksSubmitted ?? false;

  const value = useMemo(
    () => ({
      applicationId,
      applicationReference: applicationSummary.data?.referenceNumber ?? '',
      hasApplicationsEligibilityEdit,
      hasApplicationsEligibilityView,
      hasApplicationEligibilityConfirm,
      hasApplicationsGrantFundingEdit,
      hasApplicationsGrantFundingView,
      hasApplicationsSignatoriesView,
      hasPaymentPermission,
      rejected,
      closed,
      isVendorPaymentApprovalWaiting,
      isScheduleOfWorksSubmitted,
    }),
    [
      applicationId,
      applicationSummary.data?.referenceNumber,
      hasApplicationsEligibilityEdit,
      hasApplicationsEligibilityView,
      hasApplicationEligibilityConfirm,
      hasApplicationsGrantFundingEdit,
      hasApplicationsGrantFundingView,
      hasApplicationsSignatoriesView,
      hasPaymentPermission,
      rejected,
      closed,
      isVendorPaymentApprovalWaiting,
      isScheduleOfWorksSubmitted,
    ]
  );

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationContext must be used within an ApplicationContextProvider'
    );
  }
  return context;
};
