import { rest } from 'msw';
import { fileData } from 'mocks/data/file/fileData';

export const fileHandlers = [
  rest.post(`${process.env.REACT_APP_API_URL}/api/file`, (req, res, ctx) => {
    if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
      return res(ctx.delay(1000), ctx.status(200), ctx.json(fileData));
    }
    return req.passthrough();
  }),
];
