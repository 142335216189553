import { useEffect } from 'react';
import { usePrevious } from 'hooks';
import { EApplicationEligibilitySectionType } from 'pages/applicationPage/content/applicationEligibility/ApplicationEligibilityTypes';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';

const _sectionTitleMap = {
  core: EApplicationEligibilitySectionType.CoreInformationChecks,
  cost: EApplicationEligibilitySectionType.CostCentreValidationChecks,
  fraew: EApplicationEligibilitySectionType.FRAEWChecks,
  veriphy: EApplicationEligibilitySectionType.Veriphy,
  companies: EApplicationEligibilitySectionType.CompaniesHouse,
  pep: EApplicationEligibilitySectionType.PEPSanctions,
  land: EApplicationEligibilitySectionType.LandRegistry,
  approve: EApplicationEligibilitySectionType.ApproveOrReject,
} as const;

export const getSectionId = (sectionName: string | null) => {
  if (!sectionName) {
    return null;
  }

  for (const [key, value] of Object.entries(_sectionTitleMap)) {
    if (sectionName.toLocaleLowerCase().includes(key)) {
      // Found a matching section, return the value
      return value;
    }
  }

  // No section found, return null
  return null;
};

export const useEligibilitySectionOnOpen = (
  type: EApplicationEligibilitySectionType,
  fn: () => void
) => {
  const { selectedItemId } = useApplicationEligibilityContext();
  const previousSelectedItemId = usePrevious(selectedItemId);
  useEffect(() => {
    if (
      getSectionId(selectedItemId) === type &&
      getSectionId(previousSelectedItemId) !== type
    ) {
      fn();
    }
  }, [fn, previousSelectedItemId, selectedItemId, type]);
};
