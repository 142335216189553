import { v4 as uuidv4 } from 'uuid';
import { DataTableResponse } from 'common/types/DataTableResponse';
import { IApplicationDocument, IApplicationDocumentCategories, IApplicationDocumentTypes } from 'types/applications/ApplicationDocumentTypes';

export const applicationDocumentCategoriesData: Array<IApplicationDocumentCategories> =
  [
    {
      id: '1',
      name: 'Submit Application',
    },
    {
      id: '2',
      name: 'Eligibility checks',
    },
    {
      id: '3',
      name: 'Miscellaneous',
    },
  ];

  export const applicationDocumentTypesData: Array<IApplicationDocumentTypes> =
  [
    {
      id: '1',
      name: 'FRAEW Report',
      categoryId: 1
    },
    {
      id: '2',
      name: 'Counter signed GFA',
      categoryId: 2
    },
    {
      id: '3',
      name: 'Signed Deed of Trust',
      categoryId: 2
    },
  ];

export const applicationDocumentsData: DataTableResponse<IApplicationDocument> = {
  results:[{
    fileId: uuidv4(),
    filename: 'Mock Document 1.pdf',
    categoryName: 'Mock Document 1',
    documentType: 'Mock Document Type 1',
    uploadDate: '2023-03-02 10:00:00',
    uploadedBy: 'Homer Simpson',
    isKeyDocument : false,
    canDelete : true,
  },
  {
    fileId: uuidv4(),
    filename: 'Mock Document 2.pdf',
    categoryName: 'Mock Document 2',
    documentType: 'Mock Document Type 2',
    uploadDate: '2023-03-10 16:00:00',
    uploadedBy: 'Bart Simpson',
    isKeyDocument: true,
    canDelete : false,
  }],
  count: 2
};
