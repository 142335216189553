import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  DialogContent,
  Grid,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Button,
  Box,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditBsrCodeMutation } from 'api/application/applicationEditApi';
import { FieldErrorOrNull } from 'common/components/fieldErrorOrNull';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { FieldLabel } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zBsrCodeSchema } from 'types/applications/ApplicationEdit.zod';
import { IBsrCodeForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditBsrCodeDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditBsrCodeDrawer = ({
  onClose,
  onSuccess,
}: IEditBsrCodeDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit Do you have a Building Safety Regulator registration code?
          </Typography>
          {/* <Typography variant="h3">{currentValue}</Typography> */}
        </DialogTitle>
        <EditBsrCodeForm onClose={onClose} onSuccess={onSuccess} />
      </Box>
    </StyledDrawer>
  );
};

interface IEditBsrCodeFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditBsrCodeForm = ({
  onClose,
  onSuccess,
}: IEditBsrCodeFormProps) => {
  const { applicationId } = useApplicationContext();

  const [editBsrCode, editBsrCodeResult] = useEditBsrCodeMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IBsrCodeForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zBsrCodeSchema),
  });
  const { handleSubmit, reset, control } = form;

  const onSubmit = async (formData: IBsrCodeForm) => {
    try {
      formData.applicationId = applicationId;
      await editBsrCode(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`BSR Code updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit BSR code`);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} mb={2}>
              <FieldLabel label="Do you have a Building Safety Regulator registration code?" />
              <Controller
                control={control}
                name="hasBsrCode"
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => (
                  <>
                    <FormControl>
                      <ToggleButtonGroup
                        {...fieldProps}
                        onChange={(_, val) => {
                          onChange(val);
                        }}
                        exclusive
                      >
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                    <FieldErrorOrNull
                      fieldState={fieldState}
                      sx={{ ml: '14px' }}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editBsrCodeResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
