import { SxProps } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetReferralListQuery } from 'api/application/referralApi';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  EReferralRecordType,
  EReferralType,
  EThirdPartyReferrerType,
  EThirdPartyReferrerTypeLanguage,
  IReferralListItem,
  EReferralStatus,
} from 'types/applications/ReferralTypes';

type ReferralListItem = { type: EReferralType } & (
  | {
      thirdPartyReferrerType?: EThirdPartyReferrerType;
    }
  | { thirdPartyType?: EThirdPartyReferrerType }
);

export const getReferredToString = (
  item: ReferralListItem,
  defaultValue?: string
) => {
  if (item.type === EReferralType.HomesEngland) {
    return 'HE';
  }

  if (item.type === EReferralType.ThirdParty || item.type === undefined) {
    const value =
      'thirdPartyReferrerType' in item && item.thirdPartyReferrerType
        ? EThirdPartyReferrerTypeLanguage[item.thirdPartyReferrerType]
        : 'thirdPartyType' in item && item.thirdPartyType
        ? EThirdPartyReferrerTypeLanguage[item.thirdPartyType]
        : null;

    return value ?? defaultValue ?? 'Error with 3rd party type';
  }

  return 'Error with referral type';
};

export type ReferralsGridSx = {
  sx?: SxProps;
};

export type ReferralsGridProps = (
  | {
      referralRecordType: EReferralRecordType.WorksPackage;
    }
  | {
      referralRecordType: EReferralRecordType.PaymentRequest;
      heReferrals?: IReferralListItem[];
      onCompleteHeReferralSuccess?: () => void;
      paymentRequestId: string;
    }
  | {
      referralRecordType: EReferralRecordType.Variation;
      variationId: string;
    }
) &
  ReferralsGridSx;

export const useGetReferralsQueryParams = (
  props: ReferralsGridProps
): Parameters<typeof useGetReferralListQuery>[0] => {
  const { applicationId } = useApplicationContext();
  const base = { applicationId };
  switch (props.referralRecordType) {
    case EReferralRecordType.WorksPackage:
      return {
        ...base,
        type: props.referralRecordType,
      };

    case EReferralRecordType.Variation:
      return {
        ...base,
        type: props.referralRecordType,
        variationId: props.variationId,
      };

    case EReferralRecordType.PaymentRequest:
      if (!props.paymentRequestId) {
        return skipToken;
      }

      return {
        ...base,
        type: props.referralRecordType,
        paymentRequestId: props.paymentRequestId,
      };
  }
};

export const useReferralsList = (props: ReferralsGridProps) => {
  const queryParams = useGetReferralsQueryParams(props);
  const query = useGetReferralListQuery(queryParams);
  return { query };
};

export type ReferralDataResponse =
  | ReturnType<typeof useReferralsList>['query']['data']
  | undefined;

export const getReferralsArray = (data: ReferralDataResponse) => {
  if (!data) {
    return [];
  }

  if (Array.isArray(data)) {
    return data;
  }

  if ('results' in data) {
    return data.results;
  }

  return [];
};

export const getNumberOfActiveReferrals = (referrals: IReferralListItem[]) =>
  referrals.filter(item => item.status !== EReferralStatus.Complete).length;
