import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Typography, Chip, IconButton } from '@mui/material';
import { useGetApplicationSummaryForSupportTicketQuery } from 'api/application';
import { useGetApplicationSummaryQuery } from 'api/application/applicationApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FormSkeleton } from 'common/components/skeletons';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ISupportApplicationSummary } from 'pages/applicationPage/content/support/ApplicationSupportTypes';

export const AddSupportTicketApplicationSummary = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  return (
    <>
      <Box mb={2} sx={{ p: 2 }}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
        >
          <Typography
            variant="h3"
            marginBottom={4}
            fontWeight={700}
            fontSize={'1.2em'}
          >
            Application Details
          </Typography>
          <IconButton
            size="small"
            aria-label="Close add support drawer"
            name="Close add support drawer"
            onClick={() => {
              onClose();
            }}
          >
            <FontAwesomeIcon icon={faTimes} size="xs" fixedWidth />
          </IconButton>
        </Box>

        <ApplicationDrawerContent />
      </Box>
    </>
  );
};

const ApplicationDrawerContent = () => {
  const { applicationId } = useApplicationContext();
  const applicationDetails = useGetApplicationSummaryQuery(applicationId);
  const { isSuccess, isLoading, data, error, isError } =
    useGetApplicationSummaryForSupportTicketQuery({
      applicationId,
    });

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  if (isSuccess) {
    return (
      <Box>
        <Box sx={{ display: 'flex', marginBottom: '25px' }}>
          <Typography
            variant="body1"
            color="grey.700"
            fontWeight={'700'}
            fontSize={'0.95em'}
          >
            {[
              data.buildingAddress.nameNumber,
              data.buildingAddress.addressLine1,
            ]
              .filter(Boolean)
              .join(', ')}{' '}
            ({applicationDetails.data?.referenceNumber})
          </Typography>
        </Box>

        <ApplicationDrawerData data={data} />
      </Box>
    );
  }

  return null;
};

const ApplicationDrawerData = ({
  data,
}: {
  data: ISupportApplicationSummary;
}) => {
  const ad = data.buildingAddress;
  const address = [
    ad.nameNumber,
    ad.addressLine1,
    ad.addressLine2,
    ad.townCity,
    ad.county,
    ad.postcode,
    ad.country,
  ]
    .filter(Boolean)
    .join(', ');

  const stackedData: [string, string][] = [
    ['Applicant', data.applicantName],
    ['Email', data.emailAddress],
    ['Phone', data.contactNumber],
    ['Address of Building', address],
    ['Responsible Entity type', data.responsibleEntityType],
  ];

  return (
    <>
      {stackedData.map(([title, value], i) => (
        <SupportDetailsSectionStacked key={i} title={title} value={value} />
      ))}
    </>
  );
};

const SupportDetailsSectionStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <>
      <Box>
        <DataTitle title={title} />
      </Box>
      <Box>
        {title === 'Support Status' ? (
          <Chip
            label={value}
            size="small"
            sx={{
              color: 'grey.700',
              marginTop: '5px',
              fontWeight: '700',
            }}
          />
        ) : (
          <Typography variant="body1" marginTop={'5px'} fontWeight={600}>
            {value}
          </Typography>
        )}
      </Box>
      <Divider sx={{ my: 1.5 }} />
    </>
  );
};

const DataTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      variant="body1"
      color="grey.700"
      fontWeight={'600'}
      fontSize={'0.95em'}
    >
      {title}
    </Typography>
  );
};
