import { Typography } from '@mui/material';
import { IVariationBoxProps } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { useEditReasonDrawer } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/ReasonBox';
import { VariationOverviewBox } from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/common';

export const ReasonBox = ({ data, isEditable = false }: IVariationBoxProps) => {
  const reasonDrawer = useEditReasonDrawer({
    initialData: { reason: data.variationReason ?? '' },
  });

  return (
    <VariationOverviewBox
      title="Reason for variation"
      onEditClick={
        !isEditable
          ? undefined
          : () => {
              reasonDrawer.showDrawer();
            }
      }
    >
      <Typography>{data.variationReason}</Typography>
      {reasonDrawer.renderDrawer()}
    </VariationOverviewBox>
  );
};
