import { useGetReferralListQuery } from 'api/application/referralApi';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import { EReferralRecordType } from 'types/applications/ReferralTypes';

export const useWorksPackageReferrals = () => {
  const { applicationId } = useWorksPackageContext();
  const query = useGetReferralListQuery({
    type: EReferralRecordType.WorksPackage,
    applicationId,
  });
  return { query };
};
