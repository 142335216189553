import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'state';

export const selectNavigationBarRoot = (state: AppState) => state.navigationBar;

export const selectExpanded = createSelector(
  [selectNavigationBarRoot],
  navigationBar => navigationBar.expanded
);

