import { DutyOfCare } from 'pages/applicationPage/content/dutyOfCare';
import { EProgressReportItemType } from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';
import {
  ApplicationProjectPrepContextProvider,
  useApplicationProjectPrepContext,
} from 'pages/applicationPage/content/projectPrep/context';
import {
  OverviewSection,
  ReportDetailsSection,
} from 'pages/applicationPage/content/projectPrep/sections';
import { WorksPackageDeedSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageDeedSection';
import { WorksPackageSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection';

export const ProjectPrep = () => {
  return (
    <ApplicationProjectPrepContextProvider>
      <ProjectPrepMain />
    </ApplicationProjectPrepContextProvider>
  );
};
const ProjectPrepMain = () => {
  const { selectedItem } = useApplicationProjectPrepContext();

  if (!selectedItem) {
    return <OverviewSection />;
  }

  switch (selectedItem.type) {
    case EProgressReportItemType.WorkPackage:
      return <WorksPackageSection />;

    case EProgressReportItemType.MonthlyReport:
      return <ReportDetailsSection />;

    case EProgressReportItemType.DutyOfCare:
      return <DutyOfCare />;

    case EProgressReportItemType.WorkPackageDeed:
      return <WorksPackageDeedSection />;

    default:
      return null;
  }
};
