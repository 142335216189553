import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, useFormContext } from 'react-hook-form';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { ECheckpointResponses } from 'enums/ECheckpointResponses';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import { useFileUpload } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { CoreInformationChecksDocumentsPanel } from 'pages/applicationPage/content/applicationEligibility/components/CoreInformationChecks/InfoPanels';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context/ApplicationEligibilityContext';
import { IApplicationCoreInformationChecks } from 'types/applications/ApplicationEligibilityTypes';
import { nameof } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationCoreInformationChecks) =>
  nameof<IApplicationCoreInformationChecks>(fieldName);

interface ICoreInformationDevelopersPledgeProps {
  data?: IApplicationCoreInformationChecks;
  readOnly?: boolean;
  developersPledgeDocumentFileInvalid: boolean;
}

export const CoreInformationDevelopersPledge = ({
  data,
  readOnly,
  developersPledgeDocumentFileInvalid,
}: ICoreInformationDevelopersPledgeProps) => {
  const { onReferralQuestion } = useApplicationEligibilityContext();
  const { uploadFile, uploadFileStatus } = useFileUpload();
  const { watch, setValue } =
    useFormContext<IApplicationCoreInformationChecks>();

  const watchHasDevelopersPledge = watch('hasDevelopersPledge');
  const watchDevelopersPledgeDocumentFileId = watch(
    'developersPledgeDocumentFileId'
  );

  const uploadDevelopersPledgeFile = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'EligibilityCore',
      onSuccess: payload => {
        setValue(`developersPledgeDocumentFileId`, payload.id);
      },
    });
  };

  const deleteDevelopersPledgeFile = async () => {
    setValue(`developersPledgeDocumentFileId`, '');
  };

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.CoreInformation,
      question,
      referralType,
      refer
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={9} item>
        <Box p={2}>
          <Typography variant="h2" fontWeight={600}>
            Developers Pledge
          </Typography>

          <Typography variant="body1">
            Explanation around how the company maybe a subsidiary of a company
            on the list
          </Typography>

          <Box mb={2}>
            <Typography variant="h3" fontWeight={600} fontSize="1em">
              Is the developer signed up to a developer remediation contract?
            </Typography>
            <Controller
              defaultValue={''}
              name={getName('hasDevelopersPledge')}
              render={({ fieldState, field: { onChange, ...fieldProps } }) => (
                <FormControl>
                  <ToggleButtonGroup
                    {...fieldProps}
                    onChange={(_, val) => {
                      if (val !== null) {
                        onChange(val);
                      }
                      handleReferralQuestion(
                        EEligibilityQuestion.Core_DevelopersPledge,
                        EReferralType.HomesEngland,
                        val === ECheckpointResponses.Yes ||
                          val === ECheckpointResponses.NotSure
                          ? true
                          : false
                      );
                    }}
                    exclusive
                    disabled={readOnly}
                  >
                    <ToggleButton
                      key={ECheckpointResponses.Yes}
                      value={ECheckpointResponses.Yes}
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      key={ECheckpointResponses.No}
                      value={ECheckpointResponses.No}
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      key={ECheckpointResponses.NotSure}
                      value={ECheckpointResponses.NotSure}
                    >
                      Not Sure
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {fieldState.error?.message ? (
                    <FormHelperText error>
                      {fieldState.error?.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            />
          </Box>

          {(watchHasDevelopersPledge === ECheckpointResponses.Yes ||
            watchHasDevelopersPledge === ECheckpointResponses.NotSure) && (
            <>
              <Box mb={2}>
                <Typography variant="h3" fontWeight={600} fontSize="1em">
                  Add a note (Optional)
                </Typography>
                <Controller
                  name={getName('developersPledgeNotes')}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      value={field.value ?? ''}
                      fullWidth
                      error={!!fieldState.error}
                      label="Add a note (Optional)"
                      helperText={fieldState.error?.message}
                      multiline
                      disabled={readOnly}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Typography variant="h3" fontWeight={600} fontSize="1em">
                  Upload a document
                </Typography>
                {readOnly ||
                (watchDevelopersPledgeDocumentFileId !== undefined &&
                  watchDevelopersPledgeDocumentFileId ===
                    data?.developersPledgeDocument?.id) ? (
                  <Box display="flex" flexDirection="row">
                    <ApplicationFileDownloadWrapper
                      fileName={data?.developersPledgeDocument?.name ?? ''}
                      fileId={data?.developersPledgeDocument?.id ?? ''}
                    />
                    {!readOnly && (
                      <IconButton
                        size="small"
                        onClick={deleteDevelopersPledgeFile}
                        aria-label="Delete File"
                        name="Delete File"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    )}
                  </Box>
                ) : (
                  <>
                    <FileUploadInputSelect
                      id="developersPledgeDocument"
                      accept=".pdf"
                      inputName="developersPledgeDocumentFileId"
                      onFileUpload={file => uploadDevelopersPledgeFile(file)}
                      onFileDelete={deleteDevelopersPledgeFile}
                      isUploading={uploadFileStatus.isLoading}
                      uploaded={uploadFileStatus.isSuccess}
                    />
                    {developersPledgeDocumentFileInvalid && (
                      <FormHelperText error>
                        Developers pledge document is required.
                      </FormHelperText>
                    )}
                  </>
                )}
              </Box>
              <ReferApplicationNotificationBanner
                question={EEligibilityQuestion.Core_DevelopersPledge}
                referralType={EReferralType.HomesEngland}
                disabled={readOnly}
              />
            </>
          )}
        </Box>
      </Grid>
      <Grid xs={3} item>
        <CoreInformationChecksDocumentsPanel />
      </Grid>
    </Grid>
  );
};
