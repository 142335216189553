import { Box, Typography } from '@mui/material';

const getVariant = (requestedAmount: number, scheduledAmount: number) => {
  let variance: number = requestedAmount - scheduledAmount;
  return variance;
};

export const VarianceCard = ({
  paymentAmount,
  scheduledAmount,
  title,
}: {
  paymentAmount: number;
  scheduledAmount: number;
  title: string;
}) => {
  const varianceAmount = getVariant(paymentAmount, scheduledAmount);
  const isVariant = varianceAmount !== 0;
  const isVariancePositive = varianceAmount > 0;

  return (
    <>
      {isVariant ? (
        <Box
          borderLeft={isVariancePositive ? '3px solid red' : '3px solid green'}
          mb={2}
          sx={{
            borderLeft: isVariancePositive
              ? '3px solid  #942514'
              : '3px solid  #174419',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 2,
            }}
            bgcolor={isVariancePositive ? '#faeeeb' : '#edfcee'}
          >
            <Typography fontWeight="600">{title}</Typography>
            <Typography
              fontWeight="600"
              sx={{
                color: isVariancePositive ? '#ff0000' : '#123613',
              }}
            >{`${`${isVariancePositive ? '+' : '-'}£${
              varianceAmount < 0
                ? (varianceAmount * -1).toLocaleString()
                : varianceAmount.toLocaleString()
            }`}`}</Typography>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
