import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useLazyGetSignatoryQuery,
  useAddSignatoryMutation,
  useEditSignatoryMutation,
} from 'api/application/signatoriesApi';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IAddEditSignatory } from 'types/applications/ApplicationSignatoryTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IAddEditSignatory) =>
  nameof<IAddEditSignatory>(fieldName);

interface IAddEditSignatoryDrawerProps {
  signatoryId?: string | null;
  onClose: () => void;
  onSuccess: () => void;
}

export function AddEditSignatoryDrawer({
  signatoryId,
  onClose,
  onSuccess,
}: IAddEditSignatoryDrawerProps) {
  const { applicationId } = useApplicationContext();

  const [getSignatory] = useLazyGetSignatoryQuery();
  const [addSignatory, addSignatoryResult] = useAddSignatoryMutation();
  const [editSignatory, editSignatoryResult] = useEditSignatoryMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const [signatory, setSignatory] = useState<IAddEditSignatory>();
  const [formLoading, setFormLoading] = useState(true);

  const form = useForm<IAddEditSignatory>({ defaultValues: signatory });
  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(signatory);
  }, [signatory, reset]);

  useEffect(() => {
    if (signatoryId && formLoading) {
      getSignatory({ applicationId, signatoryId }).then(result => {
        if (result && result.data) {
          setSignatory(result.data);
          setFormLoading(false);
        }
      });
    } else {
      setFormLoading(false);
    }
  }, [applicationId, signatoryId, formLoading, getSignatory, reset]);

  const onSubmit = async (formData: IAddEditSignatory) => {
    const addEditSignatoryRequest: IAddEditSignatory = {
      applicationId: applicationId,
      id: signatoryId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      role: formData.role,
      emailAddress: formData.emailAddress,
    };

    if (signatoryId === undefined || signatoryId === null) {
      try {
        await addSignatory(addEditSignatoryRequest)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`New signatory added`);
            onSuccess();
          })
          .catch(error => {
            if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to add signatory`);
      }
    } else
      try {
        formData.id = signatoryId;
        await editSignatory(addEditSignatoryRequest)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`Signatory updated successfully`);
            onSuccess();
          })
          .catch(error => {
            if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit signatory`);
      }
  };

  return formLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            {signatoryId ? 'Edit Signatory' : 'Add Signatory'}
          </Typography>
        </DialogTitle>

        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <DialogContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name={getName('firstName')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="First name"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={getName('lastName')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Last name"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={getName('role')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Role"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={getName('emailAddress')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        multiline
                        fullWidth
                        error={!!fieldState.error}
                        required
                        label="Email address"
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    addSignatoryResult.isLoading ||
                    editSignatoryResult.isLoading
                  }
                >
                  {signatoryId ? 'Update Signatory' : 'Add Signatory'}
                </Button>
              </Grid>
            </StyledDrawerActions>
          </form>
        </FormProvider>
      </Box>
    </StyledDrawer>
  );
}
