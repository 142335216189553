import { ReactNode, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  useGetCladdingSystemTypesQuery,
  useGetInsulationtypesQuery,
  useGetManufacturersQuery,
  useGetWorksPackageCladdingQuery,
  useUpdateCladdingMutation,
} from 'api/application/worksPackageApi';
import { AlertForQueryErrorOrNull, ErrorAlert } from 'common/components/alerts';
import { FormSkeleton } from 'common/components/skeletons';
import {
  useCurrentUserPermissions,
  useLocalSnackbar,
  useModalState,
} from 'hooks';
import {
  ICladdingSystem,
  EditCladdingForm as EditCladdingFormType,
  IGenericOption,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import { FraewDetailsSection } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CladdingSection/common';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';

type CladdingDrawerConfig = {
  claddingId: string;
  claddingSystemNumber: number;
};

type HandleSubmitFunc = (values: EditCladdingFormType) => void;

const useGetCladdingOptions = () => {
  const cs = useGetCladdingSystemTypesQuery();
  const m = useGetManufacturersQuery();
  const it = useGetInsulationtypesQuery();

  return {
    isSuccess: cs.isSuccess && m.isSuccess && it.isSuccess,
    isFetching: cs.isFetching || m.isFetching || it.isFetching,
    claddingSystemTypeOptions: cs.data?.claddingSystems ?? [],
    manufacturerOptions: m.data?.claddingManufacturers ?? [],
    insulationTypeOptions: it.data?.insulationTypes ?? [],
  };
};

const _formName = 'edit-cladding-form';

export const useEditCladdingDrawer = () => {
  const { isShowing: isDrawerShowing, showModal, hideModal } = useModalState();

  const [claddingConfig, setCladdingConfig] =
    useState<CladdingDrawerConfig | null>(null);

  const showEditCladdingDrawer = (config: CladdingDrawerConfig) => {
    setCladdingConfig(config);
    showModal();
  };

  const [update, updateStatus] = useUpdateCladdingMutation();

  const hideEditDrawer = () => {
    setCladdingConfig(null);
    hideModal();
    updateStatus.reset();
  };

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const { applicationId } = useWorksPackageContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetWorksPackageCladdingQuery(applicationId);

  const claddingSystem =
    data?.claddingSystemReplacementDetails.find(
      item =>
        item.workPackageCostsScheduleCladdingSystemsId ===
        claddingConfig?.claddingId
    ) ?? null;

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();

  const options = useGetCladdingOptions();

  const handleSubmit = (values: EditCladdingFormType) => {
    if (!doesUserHaveSinglePermission('workpackage.cladding.edit')) {
      createErrorSnackbar('You do not have permission');
      return;
    }

    if (!claddingConfig) {
      createErrorSnackbar(
        'Could not get selected cladding details. Please try again later.'
      );
      return;
    }
    update({
      applicationId,
      fireRiskCladdingSystemId: claddingConfig.claddingId,
      ...values,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar(`Cladding system updated successfully`);
        hideEditDrawer();
      });
  };

  const renderEditDrawer = () => {
    if (!claddingConfig) {
      return null;
    }

    return (
      <>
        <StyledDrawer anchor="right" open={isDrawerShowing}>
          <Box>
            <DialogTitle component="div">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h1" component="span">
                  Cladding System {claddingConfig.claddingSystemNumber}
                </Typography>
                <IconButton onClick={hideModal} aria-label="Close Drawer" name="Close Drawer">
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <AlertForQueryErrorOrNull isError={isError} error={error} />

              {isLoading ? (
                <Box py={2}>
                  <FormSkeleton />
                </Box>
              ) : null}

              {isSuccess && claddingSystem ? (
                <EditCladdingSection
                  claddingSystem={claddingSystem}
                  options={options}
                  handleSubmit={handleSubmit}
                />
              ) : null}

              {isSuccess && !claddingSystem ? (
                <ErrorAlert
                  msg={`Could not find cladding system with id ${claddingConfig.claddingId}`}
                />
              ) : null}

              <AlertForQueryErrorOrNull
                isError={updateStatus.isError}
                error={updateStatus.error}
              />
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={hideEditDrawer}>
                  Close
                </Button>
                <LoadingButton
                  type="submit"
                  form={_formName}
                  disabled={!options.isSuccess}
                  variant="contained"
                  loading={updateStatus.isLoading}
                >
                  Update
                </LoadingButton>
              </Grid>
            </StyledDrawerActions>
          </Box>
        </StyledDrawer>
      </>
    );
  };

  return {
    showEditCladdingDrawer,
    renderEditDrawer,
  };
};

const getIdFromOption = (options: IGenericOption[], value: string) => {
  return options.find(o => o.name === value)?.id.toString() ?? '';
};

const EditCladdingSection = ({
  claddingSystem,
  handleSubmit,
  options,
}: {
  claddingSystem: ICladdingSystem;
  handleSubmit: HandleSubmitFunc;
  options: CladdingFormOptions;
}) => {
  const claddingValues = options.isSuccess
    ? {
        replacementCladdingSystemTypeId: getIdFromOption(
          options.claddingSystemTypeOptions,
          claddingSystem.replacingCladdingSystemType
        ),
        replacementCladdingManufacturerId: getIdFromOption(
          options.manufacturerOptions,
          claddingSystem.replacingCladdingManufacturer
        ),
        replacementInsulationTypeId: getIdFromOption(
          options.insulationTypeOptions,
          claddingSystem.replacingInsulationType
        ),
        replacementInsulationManufacturerId: getIdFromOption(
          options.manufacturerOptions,
          claddingSystem.replacingInsulationManufacturer
        ),
      }
    : null;

  return (
    <Stack rowGap={5}>
      <FraewDetailsSection item={claddingSystem} innerSx={{ px: 3 }} />
      <Stack rowGap={2}>
        <Typography fontWeight={600} fontSize={'1.2em'}>
          Replacement Cladding System
        </Typography>

        {options.isFetching ? <FormSkeleton /> : null}

        {options.isSuccess && claddingValues ? (
          <EditCladdingForm
            options={options}
            claddingValues={claddingValues}
            handleSubmit={handleSubmit}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

type CladdingFormOptions = ReturnType<typeof useGetCladdingOptions>;
const EditCladdingForm = ({
  claddingValues,
  options,
  handleSubmit,
}: {
  claddingValues: EditCladdingFormType;
  options: CladdingFormOptions;
  handleSubmit: HandleSubmitFunc;
}) => {
  const form = useForm<EditCladdingFormType>({
    defaultValues: claddingValues,
  });

  return (
    <FormProvider {...form}>
      <form id={_formName} onSubmit={form.handleSubmit(handleSubmit)}>
        <Section title="Cladding">
          <CladdingSelectField
            name="replacementCladdingSystemTypeId"
            label="Cladding Type"
            options={options.claddingSystemTypeOptions}
          />
          <CladdingSelectField
            name="replacementCladdingManufacturerId"
            label="Cladding Manufacturer"
            options={options.manufacturerOptions}
          />
        </Section>
        <Section title="Insulation">
          <CladdingSelectField
            name="replacementInsulationTypeId"
            label="Insulation Material"
            options={options.insulationTypeOptions}
          />
          <CladdingSelectField
            name="replacementInsulationManufacturerId"
            label="Insulation Manufacturer"
            options={options.manufacturerOptions}
          />
        </Section>
      </form>
    </FormProvider>
  );
};

const getMenuItemsForOptions = (options: IGenericOption[]) => {
  return options.map(option => (
    <MenuItem key={option.id} value={option.id.toString()}>
      {option.name}
    </MenuItem>
  ));
};

const CladdingSelectField = ({
  name,
  options,
  label,
}: {
  name: keyof EditCladdingFormType;
  options: IGenericOption[];
  label: string;
}) => {
  const { control } = useFormContext<EditCladdingFormType>();
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: 'Required' }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          select
          fullWidth
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        >
          {getMenuItemsForOptions(options)}
        </TextField>
      )}
    />
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <Box>
      <Typography fontWeight={600}>{title}</Typography>
      <Box pt={3}>{children}</Box>
    </Box>
  );
};
