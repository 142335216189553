import { administrationApi } from 'api/administration/administrationApi';
import { IPaginationQuery } from 'types/PaginationQuery';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IAdministrationAssessorPanelListFormDto,
  IAdministrationAssessorPanelListGridItem,
  IAssessorListDto,
} from 'types/administration/AdministrationAssessorPanelListTypes';
import { QueryTags } from 'util/ApiUtils';
import { getSkipTakeFromQueryData } from 'util/QueryUtils';

const getPaginatedAssessorPanelQueryString = (query: IPaginationQuery) => {
  const queryParamsStr = getSkipTakeFromQueryData({
    skip: query.skip,
    take: query.take,
  });

  return `/fraewAssessor/grid?${queryParamsStr.toString()}`;
};

const assessorPanelListEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getPaginatedAssessorsList: builder.query<
      IPaginationResponse<IAdministrationAssessorPanelListGridItem>,
      IPaginationQuery
    >({
      providesTags: [QueryTags.AssessorPanelList],
      query: query => {
        return getPaginatedAssessorPanelQueryString(query);
      },
    }),
    getAssessor: builder.query<IAdministrationAssessorPanelListFormDto, string>(
      {
        providesTags: [QueryTags.AssessorPanelList],
        query: query => `/fraewAssessor/${query}`,
      }
    ),
    getAssessorList: builder.query<Array<IAssessorListDto>, void>({
      query: () => `${process.env.REACT_APP_API_URL}/api/firerisk/assessors`,
    }),
    addAssessor: builder.mutation<
      unknown,
      IAdministrationAssessorPanelListFormDto
    >({
      invalidatesTags: [QueryTags.AssessorPanelList],
      query: payload => ({
        url: `/fraewAssessor`,
        method: 'POST',
        body: payload,
      }),
    }),
    editAssessor: builder.mutation<
      unknown,
      { formData: IAdministrationAssessorPanelListFormDto; id: number }
    >({
      invalidatesTags: [QueryTags.AssessorPanelList],
      query: ({ formData, id }) => ({
        url: `/fraewAssessor/${id}`,
        method: 'PUT',
        body: formData,
      }),
    }),
    deleteAssessor: builder.mutation<void, string>({
      invalidatesTags: [QueryTags.AssessorPanelList],
      query: payload => ({
        url: `/fraewAssessor/${payload}`,
        method: 'DELETE',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddAssessorMutation,
  useDeleteAssessorMutation,
  useEditAssessorMutation,
  useLazyGetAssessorQuery,
  useGetAssessorQuery,
  useGetAssessorListQuery,
  useGetPaginatedAssessorsListQuery,
} = assessorPanelListEndpoints;
