import { Box, useTheme } from '@mui/material';
import { useGetTaskStatsQuery } from 'api/task';
import { StatWidgets } from 'common/components/statWidgets';
import { AdminTasksDataGrid } from 'pages/adminTasksPage';
import { ITaskStats } from 'types/tasks/CmsTaskTypes';

const _statsTopSpacing = 6 as const;

export const AdminTasksPageContent = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
      px={3}
      mt={_statsTopSpacing}
    >
      <Stats />
      <AdminTasksDataGrid />
    </Box>
  );
};

const Stats = () => {
  const { data, isLoading } = useGetTaskStatsQuery();
  return (
    <StatWidgets
      isLoading={isLoading}
      topSpacing={_statsTopSpacing}
      stats={getStatsFromData(data)}
    />
  );
};

const getStatsFromData = (data: ITaskStats | undefined) => {
  return data
    ? [
        {
          statValue: data.overdueCount,
          title: 'Tasks Overdue',
        },
        {
          statValue: data.dueTodayCount,
          title: 'Due Today',
        },
        {
          statValue: data.dueTomorrowCount,
          title: 'Due Tomorrow',
        },
        {
          statValue: data.notStartedCount,
          title: 'Not Started',
        },
        {
          statValue: data.inProgressCount,
          title: 'In Progress',
        },
      ]
    : [];
};
