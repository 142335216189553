import { ErrorAlert } from 'common/components/alerts';
import { EVariationSection } from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { useVariationContext } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationContext';
import {
  VariationOverviewSection,
  VariationCostScheduleSection,
  VariationChecksSection,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections';

export const VariationSectionOutlet = () => {
  const { selectedItemId } = useVariationContext();

  switch (selectedItemId) {
    case EVariationSection.Overview:
      return <VariationOverviewSection />;

    case EVariationSection.CostSchedule:
      return <VariationCostScheduleSection />;

    case EVariationSection.Checks:
      return <VariationChecksSection />;

    default:
      return (
        <ErrorAlert
          msg={`Could not work out which section to display for id: ${selectedItemId}`}
        />
      );
  }
};
