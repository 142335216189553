import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import {
  IScheduleOfWorksCostItem,
  IScheduleOfWorksCosts,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { upfrontPercentageFieldItemId } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/CostProfileSection/CostProfileSection';
import { getGbpAmount, hasDecimal } from 'util/AppUtils';

export const CostProfileInitialPaymentsSection = ({
  initialPaymentsFields,
  control,
  readOnly = false,
}: {
  initialPaymentsFields?: Array<
    IScheduleOfWorksCostItem & { key: string }
  > | null;
  control: Control<IScheduleOfWorksCosts, any>;
  readOnly?: boolean;
}) => {
  return (
    <Box p={2}>
      <Typography variant="h3" fontWeight={800} fontSize="1.3em">
        Initial Payment
      </Typography>
      {initialPaymentsFields?.map((row, index) => (
        <ScheduleOfWorksCostItemRow
          key={row.key}
          index={index}
          scheduleOfWorksCostItem={row}
          readOnly={readOnly}
        />
      ))}
    </Box>
  );
};

const ScheduleOfWorksCostItemRow = ({
  index,
  scheduleOfWorksCostItem,
  readOnly = false,
}: {
  index: number;
  scheduleOfWorksCostItem: IScheduleOfWorksCostItem;
  readOnly?: boolean;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={8}
      wrap="nowrap"
      sx={{
        mb: 1,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em">
          {scheduleOfWorksCostItem.itemName}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign={'right'}>
        {!!scheduleOfWorksCostItem.submittedCost &&
        scheduleOfWorksCostItem.id !== upfrontPercentageFieldItemId
          ? getGbpAmount({ value: scheduleOfWorksCostItem.submittedCost })
          : '-'}
      </Grid>
      <Grid item xs={3} textAlign={'right'}>
        <Controller
          name={`initialPayments[${index}].confirmedCost`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="number"
              error={!!fieldState.error}
              required
              helperText={fieldState.error?.message}
              disabled={
                readOnly ||
                scheduleOfWorksCostItem.id === upfrontPercentageFieldItemId
              }
              onChange={e => {
                if (hasDecimal(Number(e.target.value))) return;
                return field.onChange(e);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
                inputProps: {
                  min: 0,
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
