import { ReactNode } from 'react';
import { Stack, Box, Button, SxProps, Typography } from '@mui/material';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { CardTitle } from 'pages/applicationPage/content/projectPrep/common';

export const VariationOverviewBox = ({
  title,
  onEditClick,
  children,
}: {
  title: string;
  children: ReactNode;
  onEditClick?: () => void;
}) => {
  return (
    <RoundBorderBox sx={{ bgcolor: 'grey.100', p: 2, borderWidth: 0 }}>
      <Stack gap={3}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <CardTitle title={title} />

          {onEditClick ? (
            <Box>
              <Button
                size="small"
                variant="outlined"
                onClick={onEditClick}
                sx={{ height: 'unset', py: 0, px: 0, m: 0 }}
              >
                Edit
              </Button>
            </Box>
          ) : null}
        </Stack>
        <Box>{children}</Box>
      </Stack>
    </RoundBorderBox>
  );
};

export const Row = ({
  containerSx,
  label,
  value,
  labelSx,
  valueSx,
}: {
  containerSx?: SxProps;
  label: string;
  labelSx?: SxProps;
  value: string;
  valueSx?: SxProps;
}) => {
  return (
    <Stack direction="row" justifyContent={'space-between'} sx={containerSx}>
      <Typography sx={labelSx}>{label}</Typography>
      <Typography sx={valueSx}>{value}</Typography>
    </Stack>
  );
};
