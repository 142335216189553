import { useReducer } from 'react';
import { ESortDirection } from 'enums/ESortDirection';
import { ETeam } from 'enums/ETeam';
import {
  ECmsTaskStatus,
  ECmsTaskView,
  ITaskListPaginatedQueryDefault,
} from 'types/tasks/CmsTaskTypes';

interface ITaskListStateDefault {
  gridState: ITaskListPaginatedQueryDefault;
}

export const initialState: ITaskListStateDefault = {
  gridState: {
    skip: 0,
    take: 25,
    searchValue: null,
    sortBy: 'dueDate',
    sortDirection: ESortDirection.Asc,
    status: [ECmsTaskStatus.InProgress, ECmsTaskStatus.NotStarted],
    team: [ETeam.DaviesOps, ETeam.HomesEnglandOperations],
    showCreatedByMe: false,
    viewType: ECmsTaskView.Tasks,
  },
};

type ActionType = {
  type: 'gridStateChanged';
  payload: Partial<ITaskListPaginatedQueryDefault>;
};

const reducer = (state: typeof initialState, action: ActionType) => {
  switch (action.type) {
    case 'gridStateChanged':
      return { gridState: { ...state.gridState, ...action.payload } };

    default:
      throw new Error('invalid action.type');
  }
};

export const useTaskListGridStateDefault = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridStateChanged = (newGridState: ActionType['payload']) => {
    dispatch({ type: 'gridStateChanged', payload: newGridState });
  };

  return { state, dispatch, gridStateChanged };
};
