import { useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditGrantFundingCounterSignedChecksMutation,
  useGetGrantFundingCounterSignedChecksQuery,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { ReferApplicationHomesEnglandNotificationBanner } from 'pages/applicationPage/content/applicationGrantFunding/components';
import { GrantFundingCounterSignedDocumentationChecksInfoPanel } from 'pages/applicationPage/content/applicationGrantFunding/components/counterSignedDocumentation/GrantFundingCounterSignedDocumentationChecksInfoPanel';
import { useApplicationGrantFundingContext } from 'pages/applicationPage/content/applicationGrantFunding/context';
import { IApplicationGrantFundingCounterSignedChecks } from 'types/applications/ApplicationGrantFundingTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

type FileInputPropName = keyof Pick<
  IApplicationGrantFundingCounterSignedChecks,
  'gfaCounterSignedEvidence'
>;

type FileIdInputName = keyof Pick<
  IApplicationGrantFundingCounterSignedChecks,
  'gfaCounterSignedEvidenceFileId'
>;

const getName = (
  fieldName: keyof IApplicationGrantFundingCounterSignedChecks
) => nameof<IApplicationGrantFundingCounterSignedChecks>(fieldName);

export const GrantFundingCounterSignedDocumentationChecksForm = () => {
  const { applicationId } = useApplicationGrantFundingContext();
  const query = useGetGrantFundingCounterSignedChecksQuery(applicationId);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData: IApplicationGrantFundingCounterSignedChecks = {
      ...data,
      gfaCounterSignedEvidenceFileId: data.gfaCounterSignedEvidence?.id ?? '',
    };

    return (
      <GrantFundingCounterSignedDocumentationChecksFormMain
        initialData={initialData}
      />
    );
  }

  return null;
};

const GrantFundingCounterSignedDocumentationChecksFormMain = ({
  initialData,
}: {
  initialData: IApplicationGrantFundingCounterSignedChecks;
}) => {
  const {
    applicationId,
    readOnly: isGfaReadOnly,
    selectedItem,
  } = useApplicationGrantFundingContext();

  const [
    editGrantFundingCounterSignedChecks,
    editGrantFundingCounterSignedChecksStatus,
  ] = useEditGrantFundingCounterSignedChecksMutation();
  const { uploadFile, uploadFileStatus } = useFileUpload();

  const [data] = useState<
    IApplicationGrantFundingCounterSignedChecks | undefined
  >(initialData);

  const [
    gfaCounterSignedEvidenceFileInvalid,
    setGfaCounterSignedEvidenceFileInvalid,
  ] = useState<boolean>(false);

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationGrantFundingCounterSignedChecks>({
    defaultValues: initialData,
  });
  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting },
  } = form;

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.GrantFundingAgreement,
  });

  const watchGfaCounterSigned = watch('gfaCounterSigned');
  const watchGfaCounterSignedEvidence = watch('gfaCounterSignedEvidence');
  const watchGfaCounterSignedEvidenceFileId = watch(
    'gfaCounterSignedEvidenceFileId'
  );

  const validForm = (formData: IApplicationGrantFundingCounterSignedChecks) => {
    let valid = true;
    setGfaCounterSignedEvidenceFileInvalid(false);

    if (
      formData.gfaCounterSigned === true &&
      (formData.gfaCounterSignedEvidenceFileId === undefined ||
        formData.gfaCounterSignedEvidenceFileId === '')
    ) {
      setGfaCounterSignedEvidenceFileInvalid(true);
      valid = false;
    }

    if (!valid) {
      createWarningSnackbar(
        'Please correct any form validation errors shown, and then try again.'
      );
    }

    return valid;
  };

  const onSubmit = async () => {
    const formData = getValues();
    if (validForm(formData)) {
      try {
        formData.id = applicationId;
        await editGrantFundingCounterSignedChecks(formData)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(
              `Grant Funding Counter Signed Checks updated successfully`
            );
            reset({}, { keepValues: true });
            form.setValue(`gfaResent`, false);
          })
          .catch(error => {
            if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(
          `Failed to edit Grant Funding Counter Signed checks`
        );
      }
    }
  };

  const handleOnFileUpload = async (
    file: File,
    fileInputPropName: FileInputPropName,
    fileIdInputName: FileIdInputName
  ) => {
    await uploadFile({
      file,
      uploadSection: 'GrantFundingCounterSigned',
      onSuccess: payload => {
        form.setValue(fileInputPropName, payload);
        form.setValue(fileIdInputName, payload.id);
      },
    });
  };

  const handleOnDeleteFileUpload = async (
    fileInputPropName: FileInputPropName,
    fileIdInputName: FileIdInputName
  ) => {
    form.setValue(fileInputPropName, null);
    form.setValue(fileIdInputName, '');
  };

  const isSectionComplete = selectedItem?.isComplete;
  const isReadOnly = isGfaReadOnly;

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Grant Funding Counter Signed Doc checks form will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.GrantFundingAgreement} />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertForQueryErrorOrNull
            isError={editGrantFundingCounterSignedChecksStatus.isError}
            error={editGrantFundingCounterSignedChecksStatus.error}
          />
          <AlertForChangedData isDirty={isDirty} />
          <Grid container spacing={1}>
            <Grid xs={9} item>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Box p={3}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Has the Grant Funding Agreement been counter signed?
                        </Typography>
                        <Controller
                          defaultValue={undefined}
                          name={getName('gfaCounterSigned')}
                          render={({
                            fieldState,
                            field: { onChange, ...fieldProps },
                          }) => (
                            <FormControl>
                              <ToggleButtonGroup
                                {...fieldProps}
                                onChange={(_, val) => {
                                  if (val !== null) {
                                    onChange(val);
                                  }
                                }}
                                exclusive
                                disabled={isReadOnly || isOnHoldForThisType}
                              >
                                <ToggleButton value={true}>Yes</ToggleButton>
                                <ToggleButton value={false}>No</ToggleButton>
                              </ToggleButtonGroup>
                              {fieldState.error?.message ? (
                                <FormHelperText error>
                                  {fieldState.error?.message}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Upload Grant Funding Agreement evidence
                        </Typography>
                        {(watchGfaCounterSignedEvidenceFileId !== undefined &&
                          watchGfaCounterSignedEvidenceFileId ===
                            data?.gfaCounterSignedEvidence?.id) ||
                        isSectionComplete ? (
                          <Box display="flex" flexDirection="row">
                            <ApplicationFileDownloadWrapper
                              fileName={
                                watchGfaCounterSignedEvidence?.name ??
                                'Download'
                              }
                              fileId={watchGfaCounterSignedEvidenceFileId}
                            />
                            {!isSectionComplete ? (
                              <IconButton
                                size="small"
                                disabled={isOnHoldForThisType}
                                onClick={() =>
                                  handleOnDeleteFileUpload(
                                    'gfaCounterSignedEvidence',
                                    'gfaCounterSignedEvidenceFileId'
                                  )
                                }
                                aria-label="Delete File"
                                name="Delete File"
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </IconButton>
                            ) : null}
                          </Box>
                        ) : (
                          <>
                            <FileUploadInputSelect
                              id="gfaCounterSignedEvidenceFile"
                              accept=".pdf"
                              inputName="gfaCounterSignedEvidenceFileId"
                              onFileUpload={file =>
                                handleOnFileUpload(
                                  file,
                                  'gfaCounterSignedEvidence',
                                  'gfaCounterSignedEvidenceFileId'
                                )
                              }
                              onFileDelete={() =>
                                handleOnDeleteFileUpload(
                                  'gfaCounterSignedEvidence',
                                  'gfaCounterSignedEvidenceFileId'
                                )
                              }
                              disabled={isOnHoldForThisType}
                              isUploading={uploadFileStatus.isLoading}
                              uploaded={uploadFileStatus.isSuccess}
                            />
                            {gfaCounterSignedEvidenceFileInvalid && (
                              <FormHelperText error>
                                Grant Funding Agreement counter signed document
                                is required.
                              </FormHelperText>
                            )}
                          </>
                        )}
                        {data?.gfaCounterSignedEvidenceUploadDate && (
                          <Typography align="right" paddingRight={5}>
                            {`GFA Evidence Uploaded Date: ${new Date(
                              data?.gfaCounterSignedEvidenceUploadDate
                            )?.toLocaleString('en-GB')}`}
                          </Typography>
                        )}
                      </Grid>
                      {watchGfaCounterSigned === false && (
                        <>
                          <Grid item mb={2}>
                            <Typography
                              variant="h3"
                              fontWeight={600}
                              fontSize="1em"
                            >
                              Re-send Grant Funding Agreement to counter
                              signatories
                            </Typography>
                            <Controller
                              defaultValue={false}
                              name={getName('gfaResent')}
                              render={({
                                fieldState,
                                field: { onChange, value, ...fieldProps },
                              }) => (
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        {...fieldProps}
                                        checked={value}
                                        onChange={(_, val) => {
                                          if (val !== null) onChange(val);
                                        }}
                                        disabled={
                                          isReadOnly || isOnHoldForThisType
                                        }
                                      />
                                    }
                                    label="I have resent Grant Funding Agreement"
                                  />
                                  {fieldState.error?.message ? (
                                    <FormHelperText error>
                                      {fieldState.error?.message}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                              )}
                            />
                            {initialData !== undefined &&
                              initialData?.gfaResendAttempts > 0 && (
                                <Typography
                                  color={({ palette }) => palette.warning.main}
                                >
                                  {`Grant Funding Agreement already re-sent: ${initialData?.gfaResendAttempts}`}
                                </Typography>
                              )}
                          </Grid>
                          <ReferApplicationHomesEnglandNotificationBanner />
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={3} item>
              <Box p={3}>
                <GrantFundingCounterSignedDocumentationChecksInfoPanel />
              </Box>
            </Grid>
            {!isReadOnly && (
              <Grid container p={3} bgcolor="grey.100">
                <Grid xs={6} item>
                  <Box display="flex" justifyContent="right">
                    <OnOffHold holdType={EOnHoldType.GrantFundingAgreement} />
                  </Box>
                </Grid>
                <Grid xs={6} item>
                  <Box display="flex" justifyContent="right" gap={2}>
                    <Button
                      variant="outlined"
                      onClick={() => reset()}
                      disabled={isOnHoldForThisType}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      disabled={isOnHoldForThisType}
                      type="submit"
                      loading={
                        editGrantFundingCounterSignedChecksStatus.isLoading
                      }
                    >
                      {watchGfaCounterSigned === false
                        ? 'Save and Refer Application'
                        : 'Save'}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
