import { Typography } from '@mui/material';
import { useGetApplicationCommunicationContactDetailsQuery } from 'api/application/communicationsApi';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const CommunicationsInfoPanel = () => {
  const { applicationId } = useApplicationContext();
  const query =
    useGetApplicationCommunicationContactDetailsQuery(applicationId);

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Contact Details
        </Typography>
        {query.data ? (
          <>
            <DetailItem
              label="Name"
              value={
                query.data.firstName || query.data.lastName
                  ? `${query.data.firstName} ${query.data.lastName}`
                  : '-'
              }
            />
            <DetailItem
              label="Email"
              value={query.data.emailAddress ? query.data.emailAddress : '-'}
            />
            <DetailItem
              label="Number"
              value={query.data.contactNumber ? query.data.contactNumber : '-'}
            />
          </>
        ) : null}
      </ColumnContainer>
    </>
  );
};
