import { EPermissionGroup } from 'enums/EPermissionGroup';
import { IPermission } from 'types/administration/AdministrationPermissionsTypes';

export const administrationPermissionsData: Array<IPermission> = [
  {
    name: 'tasklist.edit',
    description: 'User to create and edit their own tasks',
    permissionGroup: EPermissionGroup.Tasklist,
    granted: false,
  },
  {
    name: 'tasklist.view-own',
    description: 'User can only see their tasks',
    permissionGroup: EPermissionGroup.Tasklist,
    granted: false,
  },
  {
    name: 'tasklist.view-all',
    description: 'User can see anyones tasks - option to filter',
    permissionGroup: EPermissionGroup.Tasklist,
    granted: false,
  },
  {
    name: 'preapp.view',
    description:
      'View Pre-Applications: User can only view Pre-Applications data',
    permissionGroup: EPermissionGroup.PreApplications,
    granted: false,
  },
  {
    name: 'preapp.edit',
    description:
      'Edit Pre-Applications: User can edit Pre-Applications details',
    permissionGroup: EPermissionGroup.PreApplications,
    granted: false,
  },
  {
    name: 'preapp.admin',
    description:
      'Admin Pre-Applications: User has all Pre-application permissions',
    permissionGroup: EPermissionGroup.PreApplications,
    granted: false,
  },
  {
    name: 'applications.view',
    description: 'View Applications: User can only view application data',
    permissionGroup: EPermissionGroup.Applications,
    granted: false,
  },
  {
    name: 'applications.edit',
    description: 'Edit Applications: User can edit application details',
    permissionGroup: EPermissionGroup.Applications,
    granted: false,
  },
  {
    name: 'applications.admin',
    description: 'Admin Applications: User has all application permissions',
    permissionGroup: EPermissionGroup.Applications,
    granted: false,
  },
];
