import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  DialogContent,
  Grid,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Button,
  Box,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditFraewFireRescueServiceAccessRestrictionsMutation,
  useGetFraewFireRescueServiceAccessRestrictionsQuery,
} from 'api/application/applicationEditApi';
import { FieldErrorOrNull } from 'common/components/fieldErrorOrNull';
import { EAccessRestriction } from 'enums/EAccessRestriction';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { FieldLabel } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ConfirmSection';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zFraewFireRescueServiceAccessRestrictionsSchema } from 'types/applications/ApplicationEdit.zod';
import { IFraewFireRescueServiceAccessRestrictionsForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditFraewFireRescueServiceAccessRestrictionsDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewFireRescueServiceAccessRestrictionsDrawer = ({
  onClose,
  onSuccess,
}: IEditFraewFireRescueServiceAccessRestrictionsDrawerProps) => {
  return (
    <StyledDrawer anchor="right" open onClose={onClose}>
      <Box>
        <DialogTitle>
          <Typography variant="h1">
            Edit Does the building have any fire & rescue service access
            restrictions?
          </Typography>
          {/* <Typography variant="h3">{currentValue}</Typography> */}
        </DialogTitle>
        <EditFraewFireRescueServiceAccessRestrictionsForm
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Box>
    </StyledDrawer>
  );
};

interface IEditFraewFireRescueServiceAccessRestrictionsFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewFireRescueServiceAccessRestrictionsForm = ({
  onClose,
  onSuccess,
}: IEditFraewFireRescueServiceAccessRestrictionsFormProps) => {
  const { applicationId } = useApplicationContext();

  const options: { id: EAccessRestriction; name: string }[] = [
    {
      id: EAccessRestriction.Yes,
      name: 'Yes',
    },
    {
      id: EAccessRestriction.No,
      name: 'No',
    },
    {
      id: EAccessRestriction.DontKnow,
      name: "Don't know",
    },
  ];

  const { data, isLoading } =
    useGetFraewFireRescueServiceAccessRestrictionsQuery(applicationId);

  const [
    editFraewFireRescueServiceAccessRestrictions,
    editFraewFireRescueServiceAccessRestrictionsResult,
  ] = useEditFraewFireRescueServiceAccessRestrictionsMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IFraewFireRescueServiceAccessRestrictionsForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: data || undefined,
    resolver: zodResolver(zFraewFireRescueServiceAccessRestrictionsSchema),
  });
  const { handleSubmit, reset, control, watch, setValue } = form;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const onSubmit = async (
    formData: IFraewFireRescueServiceAccessRestrictionsForm
  ) => {
    try {
      formData.applicationId = applicationId;
      await editFraewFireRescueServiceAccessRestrictions(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(
            `FRAEW fire and rescue service access restrictions updated successfully`
          );
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(
        `Failed to edit FRAEW fire and rescue service access restrictions`
      );
    }
  };

  return isLoading ? (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} mb={2}>
              <FieldLabel label="Does the building have any fire & rescue service access restrictions?" />
              <Controller
                control={control}
                name="doesHaveAccessRestrictions"
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => (
                  <>
                    <FormControl>
                      <ToggleButtonGroup
                        {...fieldProps}
                        onChange={(_, val) => {
                          onChange(val);
                          setValue('accessRestrictions', '');
                        }}
                        exclusive
                      >
                        {options.map(opt => (
                          <ToggleButton key={opt.id} value={opt.id}>
                            {opt.name}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </FormControl>
                    <FieldErrorOrNull
                      fieldState={fieldState}
                      sx={{ ml: '14px' }}
                    />
                  </>
                )}
              />
            </Grid>

            {watch('doesHaveAccessRestrictions') === EAccessRestriction.Yes ? (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="accessRestrictions"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      label="If yes, what are the fire & rescue service access restrictions?"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={
                editFraewFireRescueServiceAccessRestrictionsResult.isLoading
              }
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
