import { useMemo, useState } from 'react';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SortDirection,
  TableSortLabel,
  IconButton,
  Menu,
  Box,
  Button,
  Typography,
} from '@mui/material';
import {
  useDeletePullInProcessDocumentByIdMutation,
  useGetPullInProcessDocumentsQuery,
} from 'api/pullInProcess';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { ESortDirection } from 'enums/ESortDirection';
import { useEllipsisMenu, useLocalSnackbar, useModalState } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { pullInDocumentContext } from 'pages/pullInProcessPage/common/context/pullInDocumentContext';
import { AddEditDocument } from 'pages/pullInProcessPage/components/documents';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import {
  TBuildingDocument,
  EPullInDocumentTypeLanguage,
} from 'types/pullInProcess/documentDetailsTypes';
import {
  getMenuItems,
  getMenuItemConfig,
  getPrettyDateStringFromJsonDateString,
} from 'util/AppUtils';

enum ContactContextMenu {
  Edit = 1,
  Delete = 2,
}

export function DocumentDetails() {
  const { pullInProcessId } = usePullInProcessContext();
  const { isLoading, data, isSuccess } =
    useGetPullInProcessDocumentsQuery(pullInProcessId);

  const [deleteDocument] = useDeletePullInProcessDocumentByIdMutation();
  const modalState = useModalState<string | null>();
  const { showModal } = modalState;

  const {
    isShowing: deleteModalIsShowing,
    hideModal: hideDeleteModal,
    showModal: showDeleteModal,
    modalData: deleteModalData,
  } = useModalState<string | null>();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const handleMenuItemClick = (menuType: ContactContextMenu) => {
    switch (menuType) {
      case ContactContextMenu.Edit:
        showModal(selectedId);
        break;
      case ContactContextMenu.Delete:
        showDeleteModal(selectedId);
        break;
    }

    handleMenuClose();
  };

  function deleteSelectedDocument() {
    deleteModalData &&
      deleteDocument({
        buildingId: pullInProcessId,
        documentId: deleteModalData,
      })
        .then(_ => createSuccessSnackbar('Document successfully deleted'))
        .catch(e =>
          createErrorSnackbar(
            `Something went wrong - ${e instanceof Error ? e.message : e}`
          )
        )
        .finally(() => hideDeleteModal());
  }

  const [sortBy, setSortBy] =
    useState<keyof TBuildingDocument>('attachmentFile');
  const [sortDirection, setSortDirection] = useState<ESortDirection>(
    ESortDirection.Asc
  );

  const getAriaSortDirection = (
    property: keyof TBuildingDocument,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getButtonAriaSortDirection = (
    property: keyof TBuildingDocument,
    direction: ESortDirection
  ): 'ascending' | 'descending' => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'ascending'
      : 'descending';
  };

  const getCurrentSortDirection = (
    property: keyof TBuildingDocument,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof TBuildingDocument,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          active={sortBy === property}
          aria-sort={getButtonAriaSortDirection(property, sortDirection)}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => {
            if (property === sortBy) {
              setSortDirection(prev =>
                prev === ESortDirection.Asc
                  ? ESortDirection.Desc
                  : ESortDirection.Asc
              );
            } else {
              setSortBy(property);
            }
          }}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  const sortedData = useMemo(() => {
    if (!isSuccess || data?.length === 0) return undefined;
    const newData = structuredClone(data);
    const res = newData.sort((a, b) => {
      const leftValue = sortBy === 'uploadDate' ? +a[sortBy] : a[sortBy];
      const rightValue = sortBy === 'uploadDate' ? +b[sortBy] : b[sortBy];
      const [left, right] =
        sortDirection === ESortDirection.Asc
          ? [leftValue, rightValue]
          : [rightValue, leftValue];
      if (left < right) return -1;
      if (left > right) return 1;
      return 0;
    });
    return res;
  }, [isSuccess, data, sortBy, sortDirection]);

  if (isLoading) return <div>loading data</div>;
  if (!isSuccess) return <div>issue found</div>;

  return (
    <pullInDocumentContext.Provider value={modalState}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <Typography fontWeight={600}>Uploaded documents</Typography>
        {sortedData !== undefined ? (
          <Button
            onClick={() => showModal()}
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
          >
            Add new document
          </Button>
        ) : null}
      </Box>
      {sortedData === undefined ? (
        <Box bgcolor={'#f0f0f0'} textAlign="center" p="1rem" mt="0.5rem">
          <Typography fontWeight={600}>
            There are no uploaded documents to show
          </Typography>
          <Typography>You can upload a document below</Typography>
          <Button
            size="small"
            variant="outlined"
            onClick={() => showModal()}
            sx={{ mt: '0.5rem' }}
          >
            Add a new document
          </Button>
        </Box>
      ) : (
        <StyledGenericTable data-testid="Documents-Table">
          <TableHead>
            <TableRow>
              {getHeaderTableCell('attachmentFile', 'File')}
              {getHeaderTableCell('uploadDate', 'Upload date')}
              {getHeaderTableCell('uploadedBy', 'Uploaded by')}
              {getHeaderTableCell('documentTypeId', 'Type')}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map(
              ({
                documentId,
                uploadDate,
                uploadedBy,
                documentTypeId,
                attachmentFile,
              }) => (
                <TableRow key={`${documentId}${uploadDate}`}>
                  <TableCell>{attachmentFile?.name}</TableCell>
                  <TableCell>
                    {uploadDate &&
                      getPrettyDateStringFromJsonDateString(
                        uploadDate.toString()
                      )}
                  </TableCell>
                  <TableCell>{uploadedBy}</TableCell>
                  <TableCell>
                    {EPullInDocumentTypeLanguage[documentTypeId]}
                  </TableCell>
                  <TableCell style={{ width: '5em' }} aria-label="Actions">
                    <IconButton
                      aria-label="edit"
                      name="edit"
                      onClick={event => handleEllipsisClick(documentId, event)}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                    {selectedId === documentId ? (
                      <Menu
                        id={`pull-in-process-contact-menu-${documentId}`}
                        anchorEl={ellipsisMenuAnchor}
                        open={Boolean(ellipsisMenuAnchor)}
                        onClose={handleMenuItemClick}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {getMenuItems(
                          [
                            getMenuItemConfig('Edit', ContactContextMenu.Edit),
                            getMenuItemConfig(
                              'Delete',
                              ContactContextMenu.Delete
                            ),
                          ],
                          handleMenuItemClick
                        )}
                      </Menu>
                    ) : null}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </StyledGenericTable>
      )}
      <AddEditDocument />
      <ConfirmationModal
        isShowing={deleteModalIsShowing}
        onCancel={hideDeleteModal}
        confirmButtonType="button"
        onConfirm={deleteSelectedDocument}
      >
        Are you sure you want to delete this document?
      </ConfirmationModal>
    </pullInDocumentContext.Provider>
  );
}
