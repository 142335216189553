import { IFile } from 'common/types/File';
import {
  ICostsSchedule,
  UpdateWorksPackageValuesParams,
} from 'types/applications/ApplicationCostScheduleTypes';
import { IReferralListItem } from 'types/applications/ReferralTypes';

export interface IVariationQueryParams {
  applicationId: string;
  variationId: string;
}

export type UpdateVariationValuesParams<T> = UpdateWorksPackageValuesParams<T> &
  IVariationQueryParams;

export interface IGetVariationCsvParams extends IVariationQueryParams {
  fileName: string;
}

type ScopeTypeVariation = {
  type: EVariationType.Scope;
  scopeChangeDescription: string;
};

type TimescaleTypeVariation = {
  type: EVariationType.Timescale;
  completionDateCurrent?: string;
  completionDateVaried?: string;
  projectDurationMonthsVaried?: number;
  projectDurationMonthsVariation?: number;
};

export type CostTypeVariation = {
  type: EVariationType.Cost;
  workPackageTotalCurrent: number;
  workPackageVariationAmount: number;
  workPackageTotalNew: number;
  amendedVariationAmount?: number;
  costVariancePercentage: number;
};

export interface IVariationOverview {
  variations: (
    | ScopeTypeVariation
    | TimescaleTypeVariation
    | CostTypeVariation
  )[];
  worksPackageTotalOriginal: number;
  worksPackageTotalCurrent: number;
  worksPackageVariationAmount: number;
  worksPackageTotalNew: number;
  status: EVariationStatus;
  approvalRejectionReason?: string;
  approvalRejectionDate?: string;
  approvalRejectionUser?: string;
  variationReason: string;
  dateSubmitted: string;
  variationEvidenceFiles: IFile[];
  variationNumber: number;
}

export enum EVariationType {
  Scope = 1,
  Timescale = 2,
  Cost = 3,
}

export const EVariationTypeLanguage: {
  [key in EVariationType]: string;
} = {
  [EVariationType.Scope]: 'Scope',
  [EVariationType.Timescale]: 'Timescale',
  [EVariationType.Cost]: 'Cost',
} as const;

export enum EVariationStatus {
  ToDo = 1,
  InProgress = 2,
  Submitted = 3,
  Approved = 4,
  Rejected = 5,
}

export const EVariationStatusLanguage: {
  [key in EVariationStatus]: string;
} = {
  [EVariationStatus.ToDo]: 'To Do',
  [EVariationStatus.InProgress]: 'In Progress',
  [EVariationStatus.Submitted]: 'Submitted',
  [EVariationStatus.Approved]: 'Approved',
  [EVariationStatus.Rejected]: 'Rejected',
} as const;

export interface IGetVariationCostsScheduleResponse {
  currentVersion?: number;
  original: ICostsSchedule;
  variation?: ICostsSchedule;
  amended?: ICostsSchedule;
}

export const booleanProps = [
  'variationEvidenceReasonable',
  'proposedExtensionGreaterThanTwoMonths',
  'allCostsEligibleForGrantFunding',
  'hasScopeMateriallyVaried',
] as const;

export type VariationChecksBooleans = Record<
  (typeof booleanProps)[number],
  boolean
>;

type VariationChecksComments = {
  [Property in keyof VariationChecksBooleans as `${Property}Comments`]: string;
};

export type VariationkChecksForm = VariationChecksBooleans &
  VariationChecksComments;

export interface ISaveVariationChecksParams extends IVariationQueryParams {
  formData: Partial<VariationkChecksForm>;
}

export interface IVariationBoxProps {
  data: IVariationOverview;
  isEditable?: boolean;
}

export type IApproveOrRejectVariationParams = IVariationQueryParams &
  (
    | {
        status: EVariationStatus.Approved;
      }
    | {
        status: EVariationStatus.Rejected;
        reason: string;
      }
  );

export interface IReferVariationToHomesEnglandParams
  extends IVariationQueryParams {
  referralComments: string;
}

export interface IGetVariationThirdPartyReferralParams
  extends IVariationQueryParams {
  thirdPartyReferralId: string;
}

export interface IVariationReferralListItem extends IReferralListItem {
  referredBy: string;
  referredDateTime: string;
}

export interface IEditVariationTypesForm {
  types: EVariationType[];
}

export interface IEditVariationTypesMutationParams
  extends IVariationQueryParams {
  formData: IEditVariationTypesForm;
}

export interface IEditVariationScopeForm {
  scope: string;
}

export interface IEditVariationScopeMutationParams
  extends IVariationQueryParams {
  formData: IEditVariationScopeForm;
}

export interface IEditVariationTimescaleForm {
  dateEnd: string;
}

export interface IEditVariationTimescaleMutationParams
  extends IVariationQueryParams {
  formData: IEditVariationTimescaleForm;
}

export interface IEditVariationReasonForm {
  reason: string;
}

export interface IEditVariationReasonMutationParams
  extends IVariationQueryParams {
  formData: IEditVariationReasonForm;
}
