import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, useFormContext } from 'react-hook-form';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import { ERiskStatus } from 'enums/ERiskStatus';
import { FRAEWChecksSafetyRiskPanel } from 'pages/applicationPage/content/applicationEligibility/components/FRAEWChecks/InfoPanels';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context/ApplicationEligibilityContext';
import { IApplicationFRAEWChecks } from 'types/applications/ApplicationEligibilityTypes';
import { nameof } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationFRAEWChecks) =>
  nameof<IApplicationFRAEWChecks>(fieldName);

interface IFRAEWLifeSafetyRiskStatusProps {
  readOnly?: boolean;
}

export const FRAEWLifeSafetyRiskStatus = ({
  readOnly,
}: IFRAEWLifeSafetyRiskStatusProps) => {
  const { onReferralQuestion } = useApplicationEligibilityContext();

  const { watch } = useFormContext<IApplicationFRAEWChecks>();

  const [
    watchDoesSafetyRiskStatusMatchFRAEWReport,
    watchValidReasonForIncorrectSafetyRisk,
    watchCorrectSafetyRiskStatus,
  ] = watch([
    'doesSafetyRiskStatusMatchFRAEWReport',
    'validReasonForIncorrectSafetyRisk',
    'correctSafetyRiskStatus',
  ]);

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.Fraew,
      question,
      referralType,
      refer
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={9} item>
        <Grid container spacing={1}>
          <Box p={2}>
            <Grid xs={12} item>
              <Typography variant="h2" fontWeight={600}>
                Life Safety Risk Status
              </Typography>
            </Grid>
            <Box p={2}>
              <Grid xs={12} item>
                <Grid item mb={2}>
                  <Typography variant="h3" fontWeight={600} fontSize="1em">
                    Does the safety status match the FRAEW report?
                  </Typography>
                  <Controller
                    defaultValue={''}
                    name={getName('doesSafetyRiskStatusMatchFRAEWReport')}
                    render={({
                      fieldState,
                      field: { onChange, ...fieldProps },
                    }) => (
                      <FormControl>
                        <ToggleButtonGroup
                          {...fieldProps}
                          onChange={(_, val) => {
                            if (val !== null) onChange(val);
                          }}
                          exclusive
                          disabled={readOnly}
                        >
                          <ToggleButton value={true}>Pass</ToggleButton>
                          <ToggleButton value={false}>Fail</ToggleButton>
                        </ToggleButtonGroup>
                        {fieldState.error?.message ? (
                          <FormHelperText error>
                            {fieldState.error?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              {watchDoesSafetyRiskStatusMatchFRAEWReport === false && (
                <>
                  <Grid item mb={2}>
                    <Typography variant="h3" fontWeight={600} fontSize="1em">
                      Contact applicant to confirm safety risk
                    </Typography>
                    <Controller
                      defaultValue={''}
                      name={getName('applicantConfirmedSafetyRisk')}
                      render={({
                        fieldState,
                        field: { onChange, value, ...fieldProps },
                      }) => (
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...fieldProps}
                                checked={value}
                                onChange={(_, val) => {
                                  if (val !== null) onChange(val);
                                }}
                                disabled={readOnly}
                              />
                            }
                            label="I have contacted the applicant"
                          />
                          {fieldState.error?.message ? (
                            <FormHelperText error>
                              {fieldState.error?.message}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Grid item mb={2}>
                      <Typography variant="h3" fontWeight={600} fontSize="1em">
                        Has the applicant provided a valid reason for incorrect
                        safety risk?
                      </Typography>
                      <Controller
                        defaultValue={''}
                        name={getName('validReasonForIncorrectSafetyRisk')}
                        render={({
                          fieldState,
                          field: { onChange, ...fieldProps },
                        }) => (
                          <FormControl>
                            <ToggleButtonGroup
                              {...fieldProps}
                              onChange={(_, val) => {
                                if (val !== null) onChange(val);
                              }}
                              exclusive
                              disabled={readOnly}
                            >
                              <ToggleButton value={true}>Pass</ToggleButton>
                              <ToggleButton value={false}>Fail</ToggleButton>
                            </ToggleButtonGroup>
                            {fieldState.error?.message ? (
                              <FormHelperText error>
                                {fieldState.error?.message}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {watchValidReasonForIncorrectSafetyRisk && (
                    <Grid item mb={2}>
                      <Typography variant="h3" fontWeight={600} fontSize="1em">
                        Provide Reason
                      </Typography>
                      <Controller
                        name={getName('reasonForIncorrectSafetyRisk')}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            fullWidth
                            error={!!fieldState.error}
                            required
                            label="Reason For Incorrect Safety Risk"
                            helperText={fieldState.error?.message}
                            multiline
                            disabled={readOnly}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}
              <Grid xs={12} item>
                <Grid item mb={2}>
                  <Typography variant="h3" fontWeight={600} fontSize="1em">
                    Please enter the correct Safety Risk Status from the FRAEW
                    report
                  </Typography>
                  <Controller
                    defaultValue={''}
                    name={getName('correctSafetyRiskStatus')}
                    render={({
                      fieldState,
                      field: { onChange, ...fieldProps },
                    }) => (
                      <FormControl>
                        <ToggleButtonGroup
                          {...fieldProps}
                          onChange={(_, val) => {
                            if (val !== null) {
                              onChange(val);
                            }
                            handleReferralQuestion(
                              EEligibilityQuestion.FRAEW_CorrectSafetyRiskStatus,
                              EReferralType.HomesEngland,
                              val === ERiskStatus.Low ||
                                val === ERiskStatus.MediumTolerable
                                ? true
                                : false
                            );
                          }}
                          exclusive
                          disabled={readOnly}
                        >
                          <ToggleButton
                            key={ERiskStatus.High}
                            value={ERiskStatus.High}
                          >
                            High Risk
                          </ToggleButton>
                          <ToggleButton
                            key={ERiskStatus.MediumActionRequired}
                            value={ERiskStatus.MediumActionRequired}
                          >
                            Medium Risk (Action Required)
                          </ToggleButton>
                          <ToggleButton
                            key={ERiskStatus.MediumTolerable}
                            value={ERiskStatus.MediumTolerable}
                          >
                            Medium Risk (Tolerable)
                          </ToggleButton>
                          <ToggleButton
                            key={ERiskStatus.Low}
                            value={ERiskStatus.Low}
                          >
                            Low Risk
                          </ToggleButton>
                        </ToggleButtonGroup>
                        {fieldState.error?.message ? (
                          <FormHelperText error>
                            {fieldState.error?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              {watchCorrectSafetyRiskStatus === ERiskStatus.Low ||
              watchCorrectSafetyRiskStatus === ERiskStatus.MediumTolerable ? (
                <ReferApplicationNotificationBanner
                  question={EEligibilityQuestion.FRAEW_CorrectSafetyRiskStatus}
                  referralType={EReferralType.HomesEngland}
                />
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid xs={3} item>
        <FRAEWChecksSafetyRiskPanel />
      </Grid>
    </Grid>
  );
};
