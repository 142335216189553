import { z } from 'zod';
import { EPullInDocumentType } from 'types/pullInProcess/documentDetailsTypes';

export const zDocumentTypeSchema = z
  .object({
    documentTypeId: z.nativeEnum(EPullInDocumentType, {
      required_error: 'Document type is required',
    }),
    otherDocumentType: z.string().optional(),
    fileId: z
      .string({ required_error: 'File required' })
      .min(1, 'File required'),
    title: z.string().optional(),
  })
  .refine(
    schema => {
      return schema.documentTypeId === EPullInDocumentType.Other
        ? schema.otherDocumentType !== ''
        : true;
    },
    {
      message: 'Other document type information is required',
      path: ['otherDocumentType'],
    }
  );
