export enum EApplicationStage {
  ApplyForGrant = 1,
  GrantFundingAgreement = 2,
  WorksPackage = 3,
  WorksDelivery = 4,
  WorksCompleted = 5,
  CounterSignInProgress = 6,
  BuildingComplete = 7,
  Closed = 8
}

export const EApplicationStageLanguage: { [key in EApplicationStage]: string } =
  {
    [EApplicationStage.ApplyForGrant]: 'Apply For Grant',
    [EApplicationStage.GrantFundingAgreement]: 'Grant Funding Agreement',
    [EApplicationStage.WorksPackage]: 'Works Package',
    [EApplicationStage.WorksDelivery]: 'Works Delivery',
    [EApplicationStage.WorksCompleted]: 'Works Completed',
    [EApplicationStage.CounterSignInProgress]: 'Counter Sign In Progress',
    [EApplicationStage.BuildingComplete]: 'Building Complete',
    [EApplicationStage.Closed]: 'Closed',
  } as const;
