import { ReactNode, ComponentProps } from 'react';
import { Box, useTheme } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export const ColumnContainer = ({
  children,
  margin,
  sx,
}: {
  children: ReactNode;
  margin?: ComponentProps<typeof Box>['margin'];
  sx?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  return (
    <Box
      borderRadius={1}
      border={1}
      borderColor={theme.palette.divider}
      margin={margin}
      sx={sx}
    >
      {children}
    </Box>
  );
};
