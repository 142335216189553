import {
  ApplicationSupportContextProvider,
  useApplicationSupportContext,
} from 'pages/applicationPage/content/support/context';
import {
  OverviewSection,
  SupportTicketSection,
} from 'pages/applicationPage/content/support/sections';

export const Support = () => {
  return (
    <ApplicationSupportContextProvider>
      <SupportMain />
    </ApplicationSupportContextProvider>
  );
};
const SupportMain = () => {
  const { selectedItemId } = useApplicationSupportContext();
  return selectedItemId ? <SupportTicketSection /> : <OverviewSection />;
};
