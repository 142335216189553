import { createContext, ReactNode, useContext } from 'react';
import { useGridQueryAndState } from 'pages/currentUserTasksPage/utils';

interface ICurrentUserTasksGridContext {
  query: ReturnType<typeof useGridQueryAndState>['query'];
  state: ReturnType<typeof useGridQueryAndState>['state'];
  gridStateChanged: ReturnType<typeof useGridQueryAndState>['gridStateChanged'];
}

const CurrentUserTasksGridContext = createContext<
  ICurrentUserTasksGridContext | undefined
>(undefined);

interface ICurrentUserTasksGridContextProviderProps {
  children: ReactNode;
}

export const CurrentUserTasksGridContextProvider = ({
  children,
}: ICurrentUserTasksGridContextProviderProps) => {
  const value = useGridQueryAndState();
  return (
    <CurrentUserTasksGridContext.Provider value={value}>
      {children}
    </CurrentUserTasksGridContext.Provider>
  );
};

export const useCurrentUserTasksGridContext = () => {
  const context = useContext(CurrentUserTasksGridContext);
  if (context === undefined) {
    throw new Error(
      'useCurrentUserTasksGridContext must be used within an CurrentUserTasksGridContextProvider'
    );
  }
  return context;
};
