import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { parseISO } from 'date-fns';
import { useGetPaymentRequestOverviewQuery } from 'api/application/paymentRequestApi';
import { Loading } from 'common/components/loading';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { EPaymentRecommendationStatus } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { formatDateTimeDayMonthYearHourMins } from 'util/DateUtils';

interface IPaymentRequestNotificationBannerProps {
  status: EPaymentRecommendationStatus;
}

export function PaymentRequestNotificationBanner({
  status,
}: IPaymentRequestNotificationBannerProps) {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;

  const { isLoading, data } = useGetPaymentRequestOverviewQuery(request);

  const getRecommendedBy = () => {
    return data?.paymentRecommendationBy ? data?.paymentRecommendationBy : '';
  };

  const getRecommendedDate = () => {
    return data?.paymentRecommendationDate
      ? formatDateTimeDayMonthYearHourMins(
          parseISO(data.paymentRecommendationDate.toString())
        )
      : '';
  };

  const getNotificationTitle = () => {
    switch (status) {
      case EPaymentRecommendationStatus.Sent:
        return 'Payment recommendation sent';
      case EPaymentRecommendationStatus.Approved:
        return 'Payment recommendation approved';
      case EPaymentRecommendationStatus.Rejected:
        return 'Payment recommendation rejected';
      case EPaymentRecommendationStatus.Expired:
        return 'Payment request expired';
      default:
        throw new Error('invalid status');
    }
  };

  const getNotificationSubTitle = () => {
    switch (status) {
      case EPaymentRecommendationStatus.Sent:
        return `The recommendation was submitted by ${getRecommendedBy()} at ${getRecommendedDate()}`;
      case EPaymentRecommendationStatus.Approved:
        return `The recommendation was approved by ${getRecommendedBy()} at ${getRecommendedDate()}`;
      case EPaymentRecommendationStatus.Rejected:
        return `The recommendation was rejected ${
          data?.paymentRecommendationBy ? `by ${getRecommendedBy()}` : ''
        }${
          data?.paymentRecommendationDate ? `at ${getRecommendedDate()}` : ''
        }`;
      case EPaymentRecommendationStatus.Expired:
        return `The recommendation expired on ${getRecommendedDate()}`;
      default:
        throw new Error('invalid status');
    }
  };

  const getBannerIcon = () => {
    switch (status) {
      case EPaymentRecommendationStatus.Approved:
        return solid('check-circle');
      case EPaymentRecommendationStatus.Sent:
        return solid('circle-question');
      case EPaymentRecommendationStatus.Rejected:
        return solid('circle-xmark');
      case EPaymentRecommendationStatus.Expired:
        return solid('clock');
      default:
        return solid('check-circle');
    }
  };

  const showBannerIcon =
    status === EPaymentRecommendationStatus.Rejected ||
    status === EPaymentRecommendationStatus.Approved ||
    status === EPaymentRecommendationStatus.Sent ||
    status === EPaymentRecommendationStatus.Expired;

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 1,
        bgcolor: 'grey.100',
      }}
    >
      <Box>
        {isLoading ? (
          <Loading isOpen />
        ) : (
          <Stack direction="row" spacing={2}>
            {showBannerIcon ? (
              <FontAwesomeIcon
                icon={getBannerIcon()}
                size="lg"
                color={
                  status === EPaymentRecommendationStatus.Rejected
                    ? 'red'
                    : 'black'
                }
                style={{
                  marginTop: '0.25rem',
                }}
              />
            ) : null}
            <Box>
              <Typography fontSize="1em" fontWeight={700} color="black">
                {getNotificationTitle()}
              </Typography>
              <Typography>{getNotificationSubTitle()}</Typography>
              {data?.paymentRecommendationReason && (
                <Typography>{data?.paymentRecommendationReason}</Typography>
              )}
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
