import { Box, Typography } from '@mui/material';
import { useGetSubContractorQuery } from 'api/application/worksPackageApi';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { SubContractorCard } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/SubContractorsSection';

export const SubContractorsSection = () => {
  const { applicationId } = useApplicationContext();
  const { isSuccess, isLoading, data } =
    useGetSubContractorQuery(applicationId);

  return (
    <RoundBorderBox>
      <Box
        bgcolor={'grey.100'}
        sx={{ padding: 2, borderBottom: '1px solid lightgray' }}
      >
        <Typography variant="h3" fontWeight={800} fontSize="1.3em">
          Sub-contractors
        </Typography>
      </Box>

      {isLoading ? <FormSkeleton sx={{ m: 1 }} /> : null}

      {isSuccess ? <SubContractorCard data={data} /> : null}
    </RoundBorderBox>
  );
};
