import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import {
  EVariationSection,
  ISelectedLiveProjectItem,
} from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';

interface IVariationContext {
  applicationId: string;
  selectedItemId: EVariationSection;
  setSelectedItemId: (itemId: EVariationSection) => void;
  variation: ISelectedLiveProjectItem | null;
}

const VariationContext = createContext<IVariationContext | undefined>(
  undefined
);

interface IVariationContextProviderProps {
  children: ReactNode;
}

export const VariationContextProvider = ({
  children,
}: IVariationContextProviderProps) => {
  const { applicationId, selectedItem: variation } =
    useApplicationLiveProjectContext();

  const [selectedItemId, setSelectedItemId] = useState<EVariationSection>(
    EVariationSection.Overview
  );

  const handleSetSelectedItemId = (itemId: EVariationSection) => {
    setSelectedItemId(itemId);
  };

  const value = useMemo(
    () => ({
      applicationId,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      variation,
    }),
    [applicationId, selectedItemId, variation]
  );

  return (
    <VariationContext.Provider value={value}>
      {children}
    </VariationContext.Provider>
  );
};

export const useVariationContext = () => {
  const context = useContext(VariationContext);
  if (context === undefined) {
    throw new Error(
      'useVariationContext must be used within an VariationContextProvider'
    );
  }
  return context;
};
