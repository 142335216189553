import { IFile } from 'common/types/File';

export enum EWorksPackageDeedSection {
  sendWorksPackageDeedDeed = '1',
  uploadDocuments = '2',
  uploadCounterSignedDocuments = '3',
}

export interface IWorksPackageDeedSentStatus {
  applicationId: string;
  worksPackageDeedSent: boolean | null;
  dateSent: Date | null;
  sentEvidenceFile: IFile | null;
}

export interface IWorksPackageDeedSend {
  applicationId: string;
  worksPackageDeedSent: boolean | null;
  sentEvidenceFileId: IFile | string | null;
}

export interface IWorksPackageDeedSignedStatus {
  applicationId: string;
  worksPackageDeedSigned: boolean | null;
  dateSigned: Date | null;
  signedWorksPackageDeedFile: IFile | null;
}

export interface IWorksPackageDeedCounterSignedStatus {
  applicationId: string;
  workPackageDeedCounterSigned: boolean | null;
  dateCounterSigned: Date | null;
  counterSignedWorkPackageDeedFile: IFile | null;
}

export interface IWorksPackageDeedSign {
  applicationId: string;
  worksPackageDeedSigned: boolean | null;
  signedWorksPackageDeedFileId: IFile | string | null;
}

export interface IWorksPackageDeedCounterSign {
  applicationId: string;
  counterSigned: boolean | null;
  counterSignedFileId: IFile | string | null;
}

export interface IWorksPackageDeedSentStatusForm {
  worksPackageDeedSent: boolean | null;
  sentEvidenceFile: IFile | null;
  sentEvidenceFileId: string;
}

export interface IWorksPackageDeedSignedStatusForm {
  worksPackageDeedSigned: boolean | null;
  signedWorksPackageDeedFile: IFile | null;
  signedWorksPackageDeedFileId: string;
}

export interface IWorksPackageDeedCounterSignedStatusForm {
  counterSigned: boolean | null;
  counterSignedFile: IFile | null;
  counterSignedFileId: string;
}

export enum EWorkPackageDeedStatus {
  InProgress = 1,
  Sent = 2,
  Signed = 3,
}

export const EWorkPackageDeedStatusLanguage: {
  [key in EWorkPackageDeedStatus]: string;
} = {
  [EWorkPackageDeedStatus.InProgress]: 'In Progress',
  [EWorkPackageDeedStatus.Sent]: 'Sent',
  [EWorkPackageDeedStatus.Signed]: 'Signed',
} as const;

interface EntityDetail {
  name: string;
  email: string;
}

interface ProjectDetail {
  startDate?: string;
  endDate?: string;
  approvedGrantAmount: number;
  duration: string;
}
export interface IWorksPackageDeedCardDetail {
  startDate: Date | null;
  dueDate: Date | null;
  status: EWorkPackageDeedStatus;
}
export interface IWorksPackageDeedDetail {
  responsibleEntity: EntityDetail;
  dateLastChecked?: string;
  signatoryChanges: boolean;
  representative: EntityDetail;
  projectDetail: ProjectDetail;
}
