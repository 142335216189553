import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ESortDirection } from 'enums/ESortDirection';
import { IGetApplicationCommunicationsPaginatedQuery } from 'types/applications/ApplicationCommunicationTypes';
import {
  IApplicationDocument,
  IGetApplicationDocumentsPaginatedQuery,
} from 'types/applications/ApplicationDocumentTypes';
import {
  IApplicationGridListItem,
  IGetApplicationsPaginatedQuery,
} from 'types/applications/ApplicationTypes';
import { nameof } from 'util/formUtils';

export interface IApplicationsState {
  gridState: IGetApplicationsPaginatedQuery;
  documentsGridState: IGetApplicationDocumentsPaginatedQuery;
  communicationsGridState: IGetApplicationCommunicationsPaginatedQuery;
}

const initialState: IApplicationsState = {
  gridState: {
    skip: 0,
    take: 25,
    showClosed: false,
    stage: [],
    status: [],
    internalStatus: [],
    searchValue: null,
    sortBy: nameof<IApplicationGridListItem>('referenceNumber'),
    sortDirection: ESortDirection.Asc,
  },
  documentsGridState: {
    applicationId: '',
    skip: 0,
    take: 25,
    categories: [],
    types: [],
    searchValue: null,
    keyDocumentsOnly: false,
    sortBy: nameof<IApplicationDocument>('uploadDate'),
    sortDirection: ESortDirection.Desc,
  },
  communicationsGridState: {
    applicationId: '',
    skip: 0,
    take: 25,
    categories: [],
    searchValue: null,
  },
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    gridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetApplicationsPaginatedQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
    documentsGridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetApplicationDocumentsPaginatedQuery>>
    ) => {
      state.documentsGridState = {
        ...state.documentsGridState,
        ...action.payload,
      };
    },
    communicationsGridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetApplicationCommunicationsPaginatedQuery>>
    ) => {
      state.communicationsGridState = {
        ...state.communicationsGridState,
        ...action.payload,
      };
    },
    resetApplicationsState: () => initialState,
  },
});

export const applicationsReducer = applicationsSlice.reducer;

export const {
  gridStateChanged,
  documentsGridStateChanged,
  communicationsGridStateChanged,
  resetApplicationsState,
} = applicationsSlice.actions;
