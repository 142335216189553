import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useCurrentUserPermissions, useModalState } from 'hooks';

interface ITasksGridContext {
  noteModalState: ReturnType<typeof useNoteModal>;
  canUserCreateTasks: boolean;
}

const TasksGridContext = createContext<ITasksGridContext | undefined>(
  undefined
);

interface ITasksGridContextProviderProps {
  children: ReactNode;
}

export const TasksGridContextProvider = ({
  children,
}: ITasksGridContextProviderProps) => {
  const noteModalState = useNoteModal();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const canUserCreateTasks = useMemo(() => {
    return doesUserHaveSinglePermission('tasklist.edit');
  }, [doesUserHaveSinglePermission]);

  const value = {
    noteModalState,
    canUserCreateTasks,
  };
  return (
    <TasksGridContext.Provider value={value}>
      {children}
    </TasksGridContext.Provider>
  );
};

export const useTasksGridContext = () => {
  const context = useContext(TasksGridContext);
  if (context === undefined) {
    throw new Error(
      'useTasksGridContext must be used within an TasksGridContextProvider'
    );
  }
  return context;
};

const useNoteModal = () => {
  const [noteIdToEdit, setNoteIdToEdit] = useState<string | null>(null);
  const [parentTaskId, setParentTaskId] = useState<string | null>(null);
  const {
    isShowing: isAddEditNoteDrawerShowing,
    showModal: showAddEditNoteDrawer,
    hideModal: hideAddEditNoteDrawer,
  } = useModalState();

  const addNote = (taskId: string) => {
    setParentTaskId(taskId);
    showAddEditNoteDrawer();
  };

  const editNote = ({ taskId, noteId }: { taskId: string; noteId: string }) => {
    setParentTaskId(taskId);
    setNoteIdToEdit(noteId);
    showAddEditNoteDrawer();
  };

  const closeNoteDrawer = () => {
    setParentTaskId(null);
    setNoteIdToEdit(null);
    hideAddEditNoteDrawer();
  };

  return {
    parentTaskId,
    noteIdToEdit,
    isAddEditNoteDrawerShowing,
    closeNoteDrawer,
    addNote,
    editNote,
  };
};
