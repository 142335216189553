import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface IEmailContactRequiredProps {
  label: string;
}

interface IEmailContactOptionalProps {
  value?: string | number;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

interface IEmailContactProps
  extends IEmailContactRequiredProps,
    IEmailContactOptionalProps {}

const defaultStyleProps: IEmailContactOptionalProps = {
  sx: { padding: '8px 14px 8px 14px', overflowWrap: 'anywhere' },
};

export const EmailContact = (props: IEmailContactProps) => {
  const { label, value, children, sx } = props;

  return (
    <Box display="flex" flexDirection="column" alignItems="start" sx={sx}>
      <Typography variant="body1" fontWeight={600} fontSize="0.9em">
        {label}
      </Typography>
      {value && (
        <Typography variant="body1" color="grey.700" fontSize="0.9em">
          {value}
        </Typography>
      )}
      {children}
    </Box>
  );
};

EmailContact.defaultProps = defaultStyleProps;
