import { Typography } from '@mui/material';
import { useGetEligibilitySummaryQuery } from 'api/application';
import {
  ColumnContainer,
  DetailItem,
  ApplicationFileDownloadWrapper,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const FRAEWChecksDocumentsPanel = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetEligibilitySummaryQuery(applicationId);

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          FRAEW Documents
        </Typography>
        {query.data ? (
          <>
            <DetailItem label="FRAEW Report">
              {query.data.fraewReportFile ? (
                <ApplicationFileDownloadWrapper
                  fileName={query.data.fraewReportFile.name}
                  fileId={query.data.fraewReportFile.id}
                />
              ) : (
                '-'
              )}
            </DetailItem>
            <DetailItem label="FRAEW Summary">
              {query.data.fraewSummaryFile ? (
                <ApplicationFileDownloadWrapper
                  fileName={query.data.fraewSummaryFile.name}
                  fileId={query.data.fraewSummaryFile.id}
                />
              ) : (
                '-'
              )}
            </DetailItem>
          </>
        ) : null}
      </ColumnContainer>
    </>
  );
};
