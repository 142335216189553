import { Box, Typography } from '@mui/material';

export const QuestionAndAnswer = ({
  question,
  value,
}: {
  question: string;
  value: string;
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      p: '0.5rem 0',
      gap: '1rem',
    }}
  >
    <Typography>{question}</Typography>
    <Typography>{value}</Typography>
  </Box>
);
