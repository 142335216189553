import { CSSProperties } from 'react';
import { Palette } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    panelToggleNumber: CSSProperties;
    panelToggleText: CSSProperties;
    widgetNumber: CSSProperties;
    widgetText: CSSProperties;
  }
  interface TypographyVariantsOptions {
    panelToggleNumber?: CSSProperties;
    panelToggleText?: CSSProperties;
    widgetNumber?: CSSProperties;
    widgetText?: CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    panelToggleNumber: true;
    panelToggleText: true;
    widgetNumber: true;
    widgetText: true;
  }
}

type TypographySettings = (palette: Palette) => TypographyOptions;

export const typographySettings: TypographySettings = palette => ({
  htmlFontSize: 10,
  fontFamily: 'Open Sans, Arial',
  fontSize: 10,
  h1: {
    fontSize: 27,
    fontWeight: 600,
  },
  h2: {
    fontSize: 20,
    fontWeight: 400,
  },
  h3: {
    fontSize: 18,
    fontWeight: 400,
    margin: '0 0 0.5rem',
  },
  h4: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
  h6: {
    fontWeight: 600,
  },
  body1: {
    fontSize: '16px',
  },
  body2: {
    fontSize: '14px',
  },
  subtitle2: {
    fontSize: 13,
    color: palette.grey[700],
  },
  panelToggleNumber: {
    fontSize: '2.5rem',
  },
  panelToggleText: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: 'grey',
  },
  widgetNumber: {
    display: 'block',
    fontSize: 28,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  widgetText: {
    display: 'block',
    fontSize: 14,
    textTransform: 'uppercase',
    opacity: 0.7,
  },
});
