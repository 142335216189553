import { Box, Stack, Typography } from '@mui/material';

export const InfoCard = ({
  title,
  cost,
  additionalInfo,
  isVarianceTolerable,
}: {
  title: string;
  cost?: string;
  additionalInfo?: string;
  isVarianceTolerable?: boolean;
}) => {
  const background =
    isVarianceTolerable !== undefined
      ? isVarianceTolerable
        ? '#cce2d8'
        : '#f6d7d2'
      : 'grey.100';

  const border =
    isVarianceTolerable !== undefined
      ? isVarianceTolerable
        ? '5px solid #005a30'
        : '5px solid #942514'
      : '5px solid black';
  return (
    <Box
      bgcolor={cost && Number(cost) === 0 ? 'grey.100' : background}
      sx={{
        padding: 2,
        paddingTop: 3,
        borderRadius: 1,
        borderLeft: `${
          cost && Number(cost) === 0 ? '5px solid black' : border
        }`,
      }}
    >
      <Stack rowGap={3}>
        <Typography variant="h3" fontWeight={600} fontSize="1em">
          {title}
        </Typography>

        <Stack direction="row" alignItems={'center'} gap={2}>
          <Typography variant="body1" fontWeight={600} fontSize="1em">
            {cost}
          </Typography>
          {additionalInfo && (
            <Typography variant="body1" color={'grey.700'} fontSize="0.7em">
              ({additionalInfo})
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
