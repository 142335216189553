import {
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants,
  Theme,
} from "@mui/material";

export const muiDividerTheme: {
  defaultProps?: ComponentsProps["MuiDivider"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiDivider"];
  variants?: ComponentsVariants["MuiDivider"];
} = {
  defaultProps: {
    color: "white",
  },
};
