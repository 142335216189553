import { Box, LinearProgress, TextField, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTakeApplicationOffHoldMutation } from 'api/application/applicationHoldApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { extractErrorMessages } from 'util/ApiUtils';

interface ITakeOffHoldModalProps {
  isShowing: boolean;
  hideModal: () => void;
}

export const TakeOffHoldModal = ({
  isShowing,
  hideModal,
}: ITakeOffHoldModalProps) => {
  const { applicationId } = useApplicationContext();
  const [takeOffHold, takeOffHoldStatus] = useTakeApplicationOffHoldMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const form = useForm<{ reason: string }>({
    defaultValues: { reason: '' },
  });

  const handleClick = async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      return;
    }

    try {
      const { reason } = form.getValues();
      takeOffHold({
        applicationId,
        reason,
      })
        .unwrap()
        .then(_ => {
          createSuccessSnackbar('Application has been taken off hold');
          hideModal();
          form.reset();
        })
        .catch(error => {
          createErrorSnackbar(extractErrorMessages(error));
        });
    } catch (err) {
      createErrorSnackbar(`Failed to take application off hold`);
    }
  };

  if (!isShowing) {
    return null;
  }

  return (
    <ConfirmationModal
      isShowing={isShowing}
      confirmButtonText="Remove Hold"
      cancelButtonText="Cancel"
      onConfirm={handleClick}
      onCancel={hideModal}
      dialogActionsSx={{ m: 2 }}
      areButtonsLoading={takeOffHoldStatus.isLoading}
      showActionsDivider
      fullWidth
      maxWidth="md"
    >
      <Box p={3}>
        {takeOffHoldStatus.isLoading ? <LinearProgress /> : null}

        <Typography variant="body1" fontWeight={600} fontSize={'1.2em'}>
          Remove from hold
        </Typography>

        <Typography variant="body1" py={3} color={'grey.700'}>
          Please ensure the task is updated
        </Typography>

        <FormProvider {...form}>
          <Controller
            control={form.control}
            name={`reason`}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                multiline
                fullWidth
                label="Provide reason"
                error={!!fieldState.error}
                rows={3}
                helperText={fieldState.error?.message}
                sx={{ mb: 0 }}
                inputProps={{ maxLength: 250 }}
              />
            )}
          />
        </FormProvider>

        <AlertForQueryErrorOrNull
          isError={takeOffHoldStatus.isError}
          error={takeOffHoldStatus.error}
        />
      </Box>
    </ConfirmationModal>
  );
};
