import { Button, TextField } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSetDirectorStatusMutation } from 'api/application';
import { useLocalSnackbar } from 'hooks/useLocalSnackbar';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { IDirectorStatus } from 'types/applications/ApplicationEligibilityTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IDirectorStatus) =>
  nameof<IDirectorStatus>(fieldName);

interface IRejectDirectorDialogProps {
  directorId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function RejectDirectorDialog({
  directorId,
  onConfirm,
  onCancel,
}: IRejectDirectorDialogProps) {
  const { applicationId } = useApplicationContext();
  const [rejectDirector] = useSetDirectorStatusMutation();
  const { createSuccessSnackbar, createWarningSnackbar, createErrorSnackbar } =
    useLocalSnackbar();

  const form = useForm<IDirectorStatus>();
  const { handleSubmit } = form;

  const onSubmit = async (formData: IDirectorStatus) => {
    let applicationRejectDirector: IDirectorStatus = {
      id: directorId,
      applicationId: applicationId,
      isPassed: false,
      failureReason: formData.failureReason,
    };

    await rejectDirector(applicationRejectDirector)
      .unwrap()
      .then(payload => {
        createSuccessSnackbar('Successfully rejected director');
        onConfirm();
      })
      .catch(error => {
        if (error.data.propertyErrors) {
          setServerSideFormErrors(form, error.data);
          createWarningSnackbar(
            'Please correct any form validation errors shown, and then try again.'
          );
        } else {
          createErrorSnackbar(
            `An unexpected error occured while rejecting application: ${error}`
          );
        }
      });
  };

  return (
    <StyledDialog open>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledDialogTitle>Reject Director</StyledDialogTitle>
          <StyledDialogContent>
            <Controller
              name={getName('failureReason')}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  required
                  multiline
                  rows={4}
                  label="Reason For Rejection"
                  helperText={fieldState.error?.message}
                  style={{ width: '34rem' }}
                />
              )}
            />
          </StyledDialogContent>
          <StyledDialogActions>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Reject Director
            </Button>
          </StyledDialogActions>
        </form>
      </FormProvider>
    </StyledDialog>
  );
}
