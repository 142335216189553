import {
  IOrganisation,
  IOrganisationUser,
} from 'types/administration/AdministrationOrganisationTypes';

export const administrationOrganisationData: Array<IOrganisation> = [
  {
    id: 1,
    name: 'Mock Organisation 1',
  },
  {
    id: 2,
    name: 'Mock Organisation 2',
  },
  {
    id: 3,
    name: 'Mock Organisation 3',
  },
];

export const organisationUsersData: IOrganisationUser[] = [
  { id: '556c270b-b09f-4953-a351-47b8c0efabc0', fullName: 'Clem Fandango' },
  { id: '556c270b-b09f-4953-a351-47b8c0efabc1', fullName: 'Ray Purchase' },
  { id: '556c270b-b09f-4953-a351-47b8c0efabc2', fullName: 'Steven Toast' },
];
