import { rest } from 'msw';
import {
  administrationAssessorPanelListFormData,
  administrationAssessorPanelListGridData,
} from 'mocks/data/administration/administrationAssessorPanelListData';

export const administrationAssessorPanelListHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/fraewAssessor/grid`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(administrationAssessorPanelListGridData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/fraewAssessor/:fraewAssessorId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(administrationAssessorPanelListFormData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/admin/fraewAssessor`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(204));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/admin/fraewAssessor/:fraewAssessorId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(204));
      }
      return req.passthrough();
    }
  ),
  rest.delete(
    `${process.env.REACT_APP_API_URL}/api/admin/fraewAssessor/:fraewAssessorId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(204));
      }
      return req.passthrough();
    }
  ),
];
