import { Box, Grid, Stack, Typography } from '@mui/material';
import { Control, UseFormReturn, useWatch } from 'react-hook-form';
import {
  IPaymentRequestCostItem,
  IPaymentRequestCosts,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { PaymentRequestConfirmedCostField } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestConfirmedCostField';
import { getGbpAmount } from 'util/AppUtils';

export const PaymentRequestCostsCurrentPaymentsSection = ({
  currentPaymentsFields,
  form,
  control,
  readOnly = false,
}: {
  currentPaymentsFields?: Array<
    IPaymentRequestCostItem & { key: string }
  > | null;
  form: UseFormReturn<IPaymentRequestCosts, any>;
  control: Control<IPaymentRequestCosts, any>;
  readOnly?: boolean;
}) => {
  const watchCurrentPayments = useWatch({
    name: 'currentPayments',
    control,
  });

  return (
    <>
      <Box p={2}>
        <Typography variant="h3" fontWeight={800} fontSize="1.3em">
          Current payment request
        </Typography>
        {currentPaymentsFields?.map((row, index) => (
          <div key={`div.currentPayments.${index}`}>
            <input
              key={`currentPayments.${index}`}
              type="hidden"
              {...form.register(`currentPayments.${index}.isDirty`)}
            />
            <PaymentRequestCostItemRow
              key={row.key}
              form={form}
              index={index}
              paymentRequestCostItem={row}
              readOnly={
                readOnly || row.itemName === 'Additional 15% of project costs'
              }
            />
          </div>
        ))}
      </Box>
      <Box
        p={2}
        sx={{
          borderTop: 1,
          borderTopColor: '#E0E0E0',
        }}
      >
        <PaymentRequestCostTotalRow
          scheduledCostTotal={currentPaymentsFields?.reduce(
            (sum, current) => sum + current.scheduledCost,
            0
          )}
          confirmedCostTotal={watchCurrentPayments
            ?.filter(
              item =>
                item.confirmedCost !== undefined &&
                item.confirmedCost.toString() !== ''
            )
            .reduce(
              (sum, current) =>
                sum + parseFloat(current.confirmedCost?.toString() ?? '0'),
              0
            )}
        />
      </Box>
    </>
  );
};

const PaymentRequestCostItemRow = ({
  index,
  form,
  paymentRequestCostItem,
  readOnly = false,
}: {
  index: number;
  form: UseFormReturn<IPaymentRequestCosts, any>;
  paymentRequestCostItem: IPaymentRequestCostItem;
  readOnly?: boolean;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={8}
      wrap="nowrap"
      sx={{
        mb: 1,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em">
          {paymentRequestCostItem.itemName}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign={'right'}>
        {!!paymentRequestCostItem.scheduledCost
          ? getGbpAmount({ value: paymentRequestCostItem.scheduledCost })
          : '-'}
      </Grid>
      <Grid item xs={3} textAlign={'right'}>
        <PaymentRequestConfirmedCostField
          form={form}
          arrayName={'currentPayments'}
          index={index}
          scheduledCost={paymentRequestCostItem.scheduledCost}
          readOnly={readOnly}
        />
      </Grid>
    </Grid>
  );
};

const PaymentRequestCostTotalRow = ({
  scheduledCostTotal,
  confirmedCostTotal,
}: {
  scheduledCostTotal?: number;
  confirmedCostTotal?: number;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={8}
      wrap="nowrap"
      sx={{
        mb: 1,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" fontSize="1em">
          Total
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign={'right'}>
        {!!scheduledCostTotal
          ? getGbpAmount({ value: scheduledCostTotal })
          : '-'}
      </Grid>
      <Grid item xs={3} textAlign={'right'}>
        <Stack direction="row" spacing={1} justifyContent="right">
          <Typography>
            {/* Check if value is !== undefined rather than falsey as it could be 0 and that's okay */}
            {confirmedCostTotal !== undefined
              ? getGbpAmount({ value: confirmedCostTotal })
              : '-'}
          </Typography>
          <Typography color={'#FF0000'}>
            {confirmedCostTotal !== undefined &&
              scheduledCostTotal !== undefined &&
              confirmedCostTotal !== scheduledCostTotal &&
              `(${
                (confirmedCostTotal ?? 0) > (scheduledCostTotal ?? 0)
                  ? '+'
                  : '-'
              }                           ${getGbpAmount({
                value:
                  scheduledCostTotal === 0
                    ? confirmedCostTotal
                    : Math.abs(confirmedCostTotal - scheduledCostTotal),
              })})`}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
