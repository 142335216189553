import { ReactNode } from 'react';
import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';

export const StatWidgets = ({
  topSpacing,
  isLoading,
  stats,
}: {
  topSpacing: number;
  isLoading: boolean;
  stats: IStatWidget[];
}) => {
  return (
    <Grid
      container
      mt={topSpacing * -1}
      mb={2}
      columnSpacing={2}
      columns={stats.length > 0 ? stats.length * 2 : 14}
    >
      {isLoading ? <StatWidgetsSkeleton /> : null}
      {!isLoading && stats.length > 0
        ? stats.map((stat, i) => <StatWidget key={i} {...stat} />)
        : null}
    </Grid>
  );
};

export interface IStatWidget {
  statValue: number;
  title: string;
  statPercentage?: number;
}

const StatWidgetsSkeleton = () => {
  return (
    <>
      {Array.from(Array(7), (_, i) => (
        <StatWidgetWrapper key={i}>
          <Skeleton variant="text" height={35} />
          <Skeleton variant="text" height={25} />
        </StatWidgetWrapper>
      ))}
    </>
  );
};

const StatWidgetWrapper = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  return (
    <Grid item sm={2}>
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: 1,
          border: 1,
          borderColor: theme.palette.divider,
          padding: 2,
          textAlign: 'center',
          height: '100%',
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

const StatWidget = ({ statValue, title, statPercentage }: IStatWidget) => {
  return (
    <StatWidgetWrapper>
      <Typography variant="widgetNumber" mb={!statPercentage ? 1 : 0}>
        {statValue}
      </Typography>
      {statPercentage !== undefined ? (
        <Typography variant="widgetText" fontSize="12px">
          ({statPercentage}%)
        </Typography>
      ) : null}
      <Typography variant="widgetText" mt="0.25rem">
        {title}
      </Typography>
    </StatWidgetWrapper>
  );
};
