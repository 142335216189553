import { Box, Typography, Chip } from '@mui/material';

export const ContentCard = ({
  title,
  reason,
  chipText,
}: {
  title: string;
  reason?: string;
  chipText: string;
}) => {
  const shouldDisplayReason: boolean = reason !== '' && reason !== undefined;
  return (
    <>
      <Box
        bgcolor={'grey.100'}
        sx={{
          width: '100%',
          padding: 2,
          paddingTop: 3,
          borderRadius: 1,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" fontWeight={700} fontSize={'0.9em'}>
            {title}
          </Typography>

          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Chip
              size="small"
              label={chipText}
              color={'default'}
              variant={'filled'}
            />
          </Box>
        </Box>
        {shouldDisplayReason && (
          <>
            <Typography
              mt={2}
              variant="h3"
              color={'grey.700'}
              fontWeight={600}
              fontSize={'0.8em'}
            >
              Reason
            </Typography>
            <Typography mt={1} variant="h3" fontSize={'0.8em'}>
              {reason}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};
