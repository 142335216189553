import { rest } from 'msw';
import {
  applicationCompaniesHouseChecksData,
  applicationConfirmEligibilityChecksData,
  applicationCoreInformationChecksData,
  applicationCostCentreCalidationChecksData,
  applicationEligibilityData,
  applicationEligibilityReferralsData,
  applicationEligibilitySummaryData,
  applicationFRAEWChecksData,
  applicationLandRegistryChecksData,
  applicationReferEligibilitySectionData,
  applicationVeriphyChecksData,
  costCentresData,
  directorData,
  pepSanctionDirectorPaginatedData,
} from 'mocks/data/application/applicationEligibilityData';

export const eligibilityHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/overview`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationEligibilityData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/details`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationEligibilitySummaryData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/referrals`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationEligibilityReferralsData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/coreinformation`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationCoreInformationChecksData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/coreinformation`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/costcentres`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(costCentresData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/costcentre`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationCostCentreCalidationChecksData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/costcentre`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/fraew`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(applicationFRAEWChecksData));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/fraew`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/veriphychecks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationVeriphyChecksData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/veriphychecks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/veriphychecks/report`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/companieshouse`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationCompaniesHouseChecksData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/companieshouse`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/landregistry`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationLandRegistryChecksData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/landregistry`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(2000), ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/confirmeligibility`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationConfirmEligibilityChecksData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/confirmeligibility/save`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/confirmeligibility/approve`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/confirmeligibility/reject`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/confirmeligibility/refertoGLA`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/pepsanctions/directors`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(pepSanctionDirectorPaginatedData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/pepsanctions/director/:directorId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(directorData));
      }
      return res(ctx.status(200), ctx.json(directorData));
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/pepsanctions/director`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/pepsanctions/director/:directorId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.delete(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/pepsanctions/director/:directorId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/pepsanctions/director/:directorId/status`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/pepsanctions`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/refer`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/refer/:referId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/refer/:sectionType`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationReferEligibilitySectionData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/eligibility/refer`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.status(200),
          ctx.json(applicationReferEligibilitySectionData)
        );
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/refer/:referralId/complete`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
