import {
  EApplicationDetailsItemStatus,
  IApplicationDetailsCategories,
  IApplicationSection,
} from 'types/applications/ApplicationDetailTypes';
import { getGuid } from 'util/AppUtils';

export const applicationDetailsData: IApplicationDetailsCategories = {
  categories: [
    {
      categoryTitle: '1. Agree before you start',
      order: 1,
      detailItems: [
        {
          id: getGuid(),
          sectionName: 'Leaseholder engagement agreement',
          taskStatus: EApplicationDetailsItemStatus.Completed,
          order: 1,
        },
      ],
    },
    {
      categoryTitle: '2. Prepare Application',
      order: 2,
      detailItems: [
        {
          id: getGuid(),
          sectionName: 'Building Details',
          taskStatus: EApplicationDetailsItemStatus.Completed,
          order: 1,
        },
        {
          id: getGuid(),
          sectionName: 'Responsible Entity details',
          taskStatus: EApplicationDetailsItemStatus.Completed,
          order: 2,
        },
        {
          id: getGuid(),
          sectionName: 'Alternative routes of funding',
          taskStatus: EApplicationDetailsItemStatus.Completed,
          order: 3,
        },
        {
          id: getGuid(),
          sectionName: 'Bank account information',
          taskStatus: EApplicationDetailsItemStatus.Completed,
          order: 4,
        },
        {
          id: getGuid(),
          sectionName: 'Declaration',
          taskStatus: EApplicationDetailsItemStatus.InProgress,
          order: 5,
        },
      ],
    },
    {
      categoryTitle: '3. Get a fire risk appraisal',
      order: 3,
      detailItems: [
        {
          id: getGuid(),
          sectionName: 'Fire Risk Appraisal',
          taskStatus: EApplicationDetailsItemStatus.NotStarted,
          order: 1,
        },
      ],
    },
    {
      categoryTitle: '4. Submit Application',
      order: 4,
      detailItems: [
        {
          id: getGuid(),
          sectionName: 'Submit',
          taskStatus: EApplicationDetailsItemStatus.CannotStartYet,
          order: 1,
        },
      ],
    },
  ],
};

export const applicationSectionData: IApplicationSection = {
  sectionTitle: 'Building Details',
  status: EApplicationDetailsItemStatus.Completed,
  lastUpdated: '2023-01-02',
  questions: [
    {
      title: 'Individual or Company?',
      type: 'string',
      value: 'Company',
      answeredDate: '2023-01-04',
    },
    {
      title: 'How many units?',
      type: 'number',
      value: 28,
      answeredDate: '2023-01-04',
    },
    {
      title: 'Any non-residential units?',
      type: 'boolean',
      value: false,
      answeredDate: '2023-01-03',
    },
    {
      title: 'Date you instructed the company',
      type: 'date',
      value: '2023-01-09T10:00:00.000Z',
      answeredDate: '2023-01-03',
    },
    {
      title: 'Address?',
      type: 'string-array',
      value: ['Unit 10 Market Road', 'London', 'SW14 3DT'],
      answeredDate: '2023-01-04',
    },
    {
      title: 'Upload evidence that you can represent the Responsible Entity',
      type: 'file-array',
      value: [
        {
          fileName: 'responsible-entity-evidence',
          fileUri: 'https://path.to.file.pdf',
        },
      ],
      answeredDate: '2023-01-05',
    },
    {
      title: 'Fire Appraisal Evidence',
      type: 'file-array',
      value: [
        {
          fileName: 'fire-appraisal-evidence1',
          fileUri: 'https://path.to.file.pdf',
        },
        {
          fileName: 'fire-appraisal-evidence2',
          fileUri: 'https://path.to.file2.pdf',
        },
      ],
      answeredDate: '2023-01-05',
    },
  ],
};
