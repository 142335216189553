import { Box, useTheme } from '@mui/material';
import { useGetPullInProcessesKpisQuery } from 'api/pullInProcess';
import { IStatWidget, StatWidgets } from 'common/components/statWidgets';
import {
  PullInProcessesDataGrid,
  PullInProcessesFilters,
} from 'pages/pullInProcessesPage/components';
import { TPullInProcessKpis } from 'types/pullInProcess/pullInProcessTypes';

const _kpiTopSpacing = 6 as const;

export const PullInProcessesPageContent = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
      px={3}
      mt={_kpiTopSpacing}
    >
      <Stats />
      <PullInProcessesFilters />
      <PullInProcessesDataGrid />
    </Box>
  );
};

const Stats = () => {
  const { data, isLoading } = useGetPullInProcessesKpisQuery();
  return (
    <StatWidgets
      isLoading={isLoading}
      topSpacing={_kpiTopSpacing}
      stats={getStatsFromData(data)}
    />
  );
};

const getStatsFromData = (
  data: TPullInProcessKpis | undefined
): IStatWidget[] => {
  return data
    ? [
        {
          statValue: data.preApplications,
          title: 'Pre application',
        },
        {
          statValue: data.noContactDetails.totalCount,
          statPercentage: data.noContactDetails.totalPercentage,
          title: 'No contact details',
        },
        {
          statValue: data.live.totalCount,
          statPercentage: data.live.totalPercentage,
          title: 'Live',
        },
        {
          statValue: data.notEligible.totalCount,
          statPercentage: data.notEligible.totalPercentage,
          title: 'Not eligible',
        },
      ]
    : [];
};
