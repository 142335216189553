import { useDecodedAccessToken } from 'hooks';

export const useUserId = () => {
  const { decodedAccessToken } = useDecodedAccessToken();
  const userId = decodedAccessToken?.sub;

  if (!userId) {
    throw new Error('Could not get user id from access token');
  }

  return { userId };
};
