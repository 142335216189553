import { ComponentProps, useCallback } from 'react';
import { Autocomplete, Grid, TextField, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetPaginatedPullInProcessesQuery } from 'api/pullInProcess';
import { GridSearchField } from 'common/components/gridSearchField';
import { EPullInProcessStatusLanguage } from 'enums/EPullInProcessStatus';
import { useAppDispatch } from 'state';
import { selectPullInProcessesGridState } from 'state/selectors/pullInProcessSelectors/pullInProcessSelectors';
import { pullInProcessGridStateChanged } from 'state/slices/pullInProcess';
import {
  StyledFilter,
  StyledFiltersGridLeft,
  StyledFiltersGridRight,
} from 'styles/globalStyles/filterStyles';

const statusOptions = Object.entries(EPullInProcessStatusLanguage).map(
  ([id, value]) => {
    return { id, value };
  }
);

export const PullInProcessesFilters = () => {
  const dispatch = useAppDispatch();
  const pullInProcessesGridState = useSelector(selectPullInProcessesGridState);
  const { isLoading } = useGetPaginatedPullInProcessesQuery(
    pullInProcessesGridState
  );

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      dispatch(
        pullInProcessGridStateChanged({
          searchValue,
          skip: 0,
        })
      );
    },
    [dispatch]
  );

  return (
    <Grid container pt={4}>
      <StyledFiltersGridLeft item xs={8}>
        <StyledFilter>
          <GridSearchField
            isLoading={isLoading}
            defaultValue={pullInProcessesGridState.searchValue}
            handleSearch={handleSearchCallback}
          />
        </StyledFilter>
      </StyledFiltersGridLeft>
      <StyledFiltersGridRight item xs={4}>
        <StyledFilter>
          <Autocomplete
            multiple
            data-testid="status"
            disabled={isLoading}
            onChange={(_, selectedValues) => {
              dispatch(
                pullInProcessGridStateChanged({
                  statuses: selectedValues.map(
                    selectedValue => selectedValue.id
                  ),
                  skip: 0,
                })
              );
            }}
            options={statusOptions}
            getOptionLabel={option => option.value}
            renderInput={params => (
              <FilterTextField params={params} label="Status" />
            )}
            size="small"
          />
        </StyledFilter>
      </StyledFiltersGridRight>
    </Grid>
  );
};

// Get signature of renderInput property
type RenderInputType = ComponentProps<typeof Autocomplete>['renderInput'];

interface IFilterTextFieldProps {
  // Get params of RenderInputType
  params: Parameters<RenderInputType>[0];
  label: string;
}

const FilterTextField = ({ params, label }: IFilterTextFieldProps) => {
  const theme = useTheme();
  return (
    <TextField
      {...params}
      label={label}
      sx={{ background: theme.palette.grey[100] }}
    />
  );
};
