import { applicationApi } from 'api/application';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IAddApplicationDocument,
  IApplicationDocument,
  IApplicationDocumentCategories,
  IApplicationDocumentTypes,
  IDeleteApplicationDocument,
  IGetApplicationDocumentsPaginatedQuery,
} from 'types/applications/ApplicationDocumentTypes';
import { QueryTags } from 'util/ApiUtils';
import { getUrlSearchParamFromQueryData } from 'util/QueryUtils';

const getPaginatedApplicationDocumentsString = (
  query: IGetApplicationDocumentsPaginatedQuery
) => {
  const queryParamsStr = getUrlSearchParamFromQueryData({
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  });

  queryParamsStr.append('keyDocumentsOnly', `${query.keyDocumentsOnly}`)

  if (query.categories.length > 0) {
    for (const category of query.categories) {
      queryParamsStr.append('categories', `${category}`);
    }
  }

  if (query.types.length > 0) {
    for (const type of query.types) {
      queryParamsStr.append('types', `${type}`);
    }
  }

  return `${process.env.REACT_APP_API_URL}/api/application/${query.applicationId}/document?${queryParamsStr.toString()}`;
};

export const documentsEndpoints = applicationApi.injectEndpoints({
  endpoints: builder => ({
    getApplicationDocumentCategories: builder.query<
      Array<IApplicationDocumentCategories>,
      void
    >({
      query: () => `/document/categories`,
    }),
    getApplicationDocumentTypes: builder.query<
    Array<IApplicationDocumentTypes>,
    void
  >({
    query: () => `/document/types`,
  }),
    getApplicationDocuments: builder.query<
      IPaginationResponse<IApplicationDocument>,
      IGetApplicationDocumentsPaginatedQuery
    >({
      providesTags: [QueryTags.ApplicationDocuments],
      query: query => {
        return getPaginatedApplicationDocumentsString(query);
      },
    }),
    addApplicationDocument: builder.mutation<any, IAddApplicationDocument>({
      invalidatesTags: [QueryTags.ApplicationDocuments],
      query: payload => ({
        url: `/${payload.applicationId}/document`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteDocument: builder.mutation<void, IDeleteApplicationDocument>({
      invalidatesTags: [QueryTags.ApplicationDocuments],
      query: payload => ({
        url: `/${payload.applicationId}/document/${payload.fileId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApplicationDocumentCategoriesQuery,
  useGetApplicationDocumentTypesQuery,
  useGetApplicationDocumentsQuery,
  useAddApplicationDocumentMutation,
  useDeleteDocumentMutation,
} = documentsEndpoints;
