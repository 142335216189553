import { Box, Typography, Chip, Divider, useTheme } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPullInProcessSummaryQuery } from 'api/pullInProcess';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import {
  EPullInProcessStatus,
  EPullInProcessStatusLanguage,
} from 'enums/EPullInProcessStatus';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';

type BoxWrapperProps = {
  render: JSX.Element;
};

function BoxWrapper({ render }: BoxWrapperProps) {
  return <Box mt="0.5rem">{render}</Box>;
}

export function BuildingDetailsPanel() {
  const { pullInProcessId } = usePullInProcessContext();
  const { data } = useGetPullInProcessSummaryQuery(
    pullInProcessId ?? skipToken
  );

  const { palette } = useTheme();

  return (
    <RoundBorderBox borderRadius="8px" p="1rem">
      <Typography component="h3" variant="h3" fontWeight="600">
        Building Details
      </Typography>
      <BoxWrapper render={<Typography color="GrayText">Stage</Typography>} />
      <BoxWrapper render={<Chip size="small" label={data?.stage ?? ''} />} />
      <BoxWrapper render={<Divider />} />
      <BoxWrapper render={<Typography color="GrayText">Status</Typography>} />
      <BoxWrapper
        render={
          <Chip
            size="small"
            label={
              data?.status ? EPullInProcessStatusLanguage[data?.status] : ''
            }
            sx={{
              backgroundColor:
                data?.status === EPullInProcessStatus.NotEligible
                  ? palette.error.light
                  : palette.chipPrimary?.light,
            }}
          />
        }
      />
      <BoxWrapper render={<Divider />} />
      <BoxWrapper
        render={<Typography color="GrayText">Unique ID</Typography>}
      />
      <BoxWrapper render={<Typography>{data?.uniqueId ?? ''}</Typography>} />
      {data?.status === EPullInProcessStatus.ProgressedToApplication &&
      data?.progressedToApplicationReference ? (
        <>
          <BoxWrapper
            render={
              <Typography color="GrayText">Linked Application</Typography>
            }
          />
          <BoxWrapper
            render={
              <Typography>{data.progressedToApplicationReference}</Typography>
            }
          />
        </>
      ) : null}
    </RoundBorderBox>
  );
}
