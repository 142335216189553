import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface IDetailItemRequiredProps {
  label: string;
}

interface IDetailItemOptionalProps {
  value?: string | number;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

interface IDetailItemProps
  extends IDetailItemRequiredProps,
    IDetailItemOptionalProps {}

const defaultStyleProps: IDetailItemOptionalProps = {
  sx: { padding: '8px 14px 8px 14px', overflowWrap: 'anywhere' },
};

export const DetailItem = (props: IDetailItemProps) => {
  const { label, value, children, sx } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="start"
      sx={sx}
    >
      <Typography variant="body1" color="grey.800" fontSize="0.9em">
        {label}
      </Typography>
      {value && (
        <Typography variant="body1" fontWeight={600} fontSize="0.9em">
          {value}
        </Typography>
      )}
      {children}
    </Box>
  );
};

DetailItem.defaultProps = defaultStyleProps;
