import { ComponentProps, ReactNode } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  SxProps,
  Theme,
} from '@mui/material';

interface IConfirmationModalBaseProps {
  title?: ReactNode;
  children: ReactNode;
  isShowing: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  dialogActionsSx?: SxProps<Theme>;
  areButtonsLoading?: boolean;
  showActionsDivider?: boolean;
  fullWidth?: boolean;
  maxWidth?: ComponentProps<typeof Dialog>['maxWidth'];
}

interface IConfirmButtonType {
  confirmButtonType?: 'button';
}

interface ISubmitButtonType {
  confirmButtonType?: 'submit';
  formId: string;
}

interface IConfirmationModalSubmitProps
  extends IConfirmationModalBaseProps,
    ISubmitButtonType {}
interface IConfirmationModalButtonProps
  extends IConfirmationModalBaseProps,
    IConfirmButtonType {
  formId?: string;
}

export function ConfirmationModal({
  title,
  children,
  isShowing,
  onCancel,
  onConfirm,
  confirmButtonType = 'button',
  formId = undefined,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  dialogActionsSx,
  areButtonsLoading = false,
  showActionsDivider = false,
  fullWidth = true,
  maxWidth = 'sm',
}: IConfirmationModalSubmitProps | IConfirmationModalButtonProps) {
  return isShowing ? (
    <Dialog
      data-testid="confirmation-modal"
      open={isShowing}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      <DialogContent>{children}</DialogContent>
      {showActionsDivider ? <Divider /> : null}
      <DialogActions sx={dialogActionsSx}>
        <LoadingButton
          variant="outlined"
          onClick={onCancel}
          loading={areButtonsLoading}
        >
          {cancelButtonText}
        </LoadingButton>
        <LoadingButton
          type={confirmButtonType}
          variant="contained"
          onClick={() => onConfirm && onConfirm()}
          form={formId}
          loading={areButtonsLoading}
        >
          {confirmButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  ) : null;
}
