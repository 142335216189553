import { useLocalSnackbar } from 'hooks';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import { useCostsScheduleContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CostsScheduleSection/context';
import { extractErrorMessages } from 'util/ApiUtils';

export const useCostScheduleEditPermissionCheck = () => {
  const { canEditCostSchedule } = useCostsScheduleContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const costSchedulePermissionCheck = () => {
    if (!canEditCostSchedule) {
      createErrorSnackbar(
        'You do not have permission to edit the Cost Schedule'
      );
      return false;
    }

    return true;
  };

  return { costSchedulePermissionCheck };
};

export const useFormWrapperDependencies = () => {
  const { applicationId } = useWorksPackageContext();
  const { data, handleSuccessfulSave, setSectionToEdit } =
    useCostsScheduleContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const { costSchedulePermissionCheck } = useCostScheduleEditPermissionCheck();
  const handleCancelClick = () => setSectionToEdit(null);
  const handleUnwrappedSaveResult = (unwrappedSaveResult: Promise<void>) => {
    unwrappedSaveResult
      .then(() => {
        handleSuccessfulSave();
      })
      .catch(error => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  return {
    applicationId,
    handleSuccessfulSave,
    createErrorSnackbar,
    handleCancelClick,
    costSchedulePermissionCheck,
    handleUnwrappedSaveResult,
    data,
  };
};
