import { MenuItem, TextField } from '@mui/material';
import { ISelectOption } from 'types/GeneralTypes';

export const FilterSelect = ({
  name,
  label,
  value,
  onChange,
  options,
  multiple,
}: {
  name: string;
  label: string;
  value: string | string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: ISelectOption[];
  multiple: boolean;
}) => {
  return (
    <TextField
      name={name}
      label={label}
      select
      SelectProps={{
        multiple: multiple,
        sx: { '& .MuiSelect-select': { p: '12.5px 12.5px 10px' } },
      }}
      sx={{ minWidth: '215px', p: 0 }}
      value={value}
      onChange={onChange}
    >
      {options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
