import { rest } from 'msw';
import {
  claddingData,
  costsSchedulingData,
  workPackagesOverviewData,
  subContractorData,
  projectTeamData,
  checksDataPartial,
  referrals,
  singleReferral,
  claddingSystems,
  claddingManufacturers,
  claddingInsulationtypes,
  buildingDetails,
  grantCertifyingOfficerAuthorisedSignatories,
  regulatoryCompliance,
} from 'mocks/data/application/applicationWorksPackageData';

export const worksPackageHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/overview`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(workPackagesOverviewData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/authorisedSignatories`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(grantCertifyingOfficerAuthorisedSignatories));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/regulatoryCompliance`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(regulatoryCompliance));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/subcontractor`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(subContractorData));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/projectteam`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(projectTeamData));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/cladding`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(claddingData));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/costscheduling`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(costsSchedulingData));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/checks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(checksDataPartial));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/checks`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(1000), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.post(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/thirdpartyreferral`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/referrals`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200), ctx.json(referrals));
      }
      return req.passthrough();
    }
  ),

  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/thirdpartyreferral/:thirdPartyReferralId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200), ctx.json(singleReferral));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/referToHomesEngland/:referralId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),

  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/approvalRejection`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/cladding/systems`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200), ctx.json(claddingSystems));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/cladding/manufacturers`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.delay(500),
          ctx.status(200),
          ctx.json(claddingManufacturers)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/cladding/insulationtypes`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.delay(500),
          ctx.status(200),
          ctx.json(claddingInsulationtypes)
        );
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/buildingDetails`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200), ctx.json(buildingDetails));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/grant`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.delay(500), ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/application/:applicationId/workpackage/costscheduling/csv`,
    async (_, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(
          ctx.delay(2000),
          ctx.status(200),
          ctx.set('Content-Type', 'application/pdf'),
          ctx.body(new Blob(['testing'], { type: 'application/pdf' }))
        );
      }
    }
  ),
];
