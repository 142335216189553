import { ReactNode } from 'react';
import { Box } from '@mui/material';

export const CostProfileSectionBox = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box sx={{ padding: 2, borderBottom: '1px solid lightgray' }}>
      {children}
    </Box>
  );
};
