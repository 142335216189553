import { Box, Typography } from '@mui/material';
import { IWorksPackageOverview } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { formatDateMonthYear } from 'util/DateUtils';

export const KeyDatesCard = ({
  title,
  data,
}: {
  title: string;
  data?: IWorksPackageOverview['keyDates'];
}) => {
  return (
    <Box
      mb={2}
      bgcolor={'grey.100'}
      sx={{
        width: '100%',
        padding: 2,
        paddingTop: 3,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h3"
        marginBottom={4}
        fontWeight={900}
        fontSize={'1.05em'}
      >
        {title}
      </Typography>

      <KeyDatesCardData data={data as IWorksPackageOverview['keyDates']} />
    </Box>
  );
};

export const KeyDatesCardData = ({
  data,
}: {
  data: IWorksPackageOverview['keyDates'];
}) => {
  const dateComparison = () => {
    if (data?.expectedDateForCompletion && data?.startDate) {
      const startYear = new Date(data.startDate.toString()).getFullYear();
      const startMonth = new Date(data.startDate.toString()).getMonth();
      const endYear = new Date(
        data.expectedDateForCompletion?.toString()
      ).getFullYear();
      const endMonth = new Date(
        data.expectedDateForCompletion.toString()
      ).getMonth();
      const comparisonResult =
        (endYear - startYear) * 12 + (endMonth - startMonth + 1);
      const numberOfMonths = Math.abs(comparisonResult);
      return `${
        numberOfMonths === 1
          ? `${numberOfMonths} month`
          : `${numberOfMonths} months`
      }`;
    }
  };

  const stackedData: [string, string][] = [
    [
      'Start on site date',
      data?.startDate ? formatDateMonthYear(data.startDate) : '',
    ],
    [
      'Completion date',
      data?.expectedDateForCompletion
        ? formatDateMonthYear(data.expectedDateForCompletion)
        : '',
    ],
    [
      'Date to remove cladding',
      data?.unsafeCladdingRemovalDate
        ? formatDateMonthYear(data.unsafeCladdingRemovalDate)
        : '',
    ],
    [
      'Project duration',
      `${
        data?.startDate && data?.expectedDateForCompletion
          ? dateComparison()
          : ''
      }`,
    ],
  ];

  return (
    <>
      {stackedData.map(([title, value], i) => (
        <PaymentDetailsSectionStacked key={i} title={title} value={value} />
      ))}
    </>
  );
};

const PaymentDetailsSectionStacked = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <DataTitle title={title} />

        <Typography fontSize={'0.8em'} fontWeight={600}>
          {value}
        </Typography>
      </Box>
    </>
  );
};

const DataTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="body1" fontWeight={'600'} fontSize={'0.8em'}>
      {title}
    </Typography>
  );
};
