export enum ESectionType {
  Building = 'building-details',
  ResponsibleEntity = 'responsible-entity',
  LeaseholderAgreement = 'leaseholder-agreement',
  AlternateFunding = 'alternate-funding',
  BankAccount = 'bank-account',
  Declaration = 'declaration',
  FireRisk = 'fire-risk',
}

export enum EApplicationDetailsMenuType {
  ViewItem,
}

export enum EApplicationDetailsQuestionMenuType {
  EditItem,
  ViewHistory
}