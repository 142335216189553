import { Box, Grid, Typography } from '@mui/material';
import {
  EEligibilityQuestion,
  EEligibilityQuestionLanguage,
} from 'enums/EEligibilityQuestion';
import {
  DetailItem,
  ApplicationFileDownloadWrapper,
} from 'pages/applicationPage/common/components';
import {
  IApplicationEligibilityReferral,
  IApplicationEligibilitySummary,
} from 'types/applications/ApplicationEligibilityTypes';

interface IReferralInformationPanelProps {
  referral: IApplicationEligibilityReferral;
  summary: IApplicationEligibilitySummary;
}

export const ReferralInformationPanel = ({
  referral,
  summary,
}: IReferralInformationPanelProps) => {
  return (
    <Box
      sx={{
        marginBottom: 2,
        padding: 1,
        border: '1px solid lightgray',
        borderRadius: 1,
        borderLeft: '5px solid orange',
      }}
    >
      <Grid container>
        <Grid
          xs={12}
          item
          sx={{
            borderBottom: '1px solid lightgray',
            marginBottom: 2,
          }}
        >
          <Typography variant="h3" fontWeight={900} fontSize="1em">
            {EEligibilityQuestionLanguage[referral.question]}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="h3" fontWeight={550} fontSize="1em">
            Reason
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body1" fontSize="1em">
            {referral.reason}
          </Typography>
        </Grid>
        {referral.question ===
          EEligibilityQuestion.FRAEW_IsBuildingHeightCorrect && (
          <Grid xs={12} item>
            <DetailItem label="Building Height Evidence">
              {summary.buildingHeightEvidenceFile ? (
                <ApplicationFileDownloadWrapper
                  fileName={summary.buildingHeightEvidenceFile.name}
                  fileId={summary.buildingHeightEvidenceFile.id}
                />
              ) : (
                '-'
              )}
            </DetailItem>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
