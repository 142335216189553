import { EQuestionType } from 'enums/EQuestionType';
import { IHasOrderProp } from 'types/GeneralTypes';

export interface IApplicationDetailsCategories {
  categories: IApplicationDetailsCategory[];
}

export interface IApplicationDetailsCategory extends IHasOrderProp {
  categoryTitle: string;
  detailItems: IApplicationDetailsItem[];
}

export interface IApplicationDetailsItem extends IHasOrderProp {
  id: string;
  sectionName: string;
  taskStatus: EApplicationDetailsItemStatus;
}

export enum EApplicationDetailsItemStatus {
  CannotStartYet = 0,
  NotStarted = 1,
  InProgress = 2,
  Completed = 3,
}

export const EApplicationDetailsItemStatusLanguage: {
  [key in EApplicationDetailsItemStatus]: string;
} = {
  [EApplicationDetailsItemStatus.CannotStartYet]: 'Cannot start yet',
  [EApplicationDetailsItemStatus.NotStarted]: 'Not Started',
  [EApplicationDetailsItemStatus.InProgress]: 'In Progress',
  [EApplicationDetailsItemStatus.Completed]: 'Completed',
} as const;

export interface IApplicationSection {
  sectionTitle: string;
  lastUpdated: string;
  status: EApplicationDetailsItemStatus;
  questions: IApplicationSectionQuestion[];
}

interface IApplicationSectionQuestion {
  title: string;
  value: ApplicationSectionQuestionValue;
  type: ApplicationSectionQuestionValueType;
  answeredDate: string;
  questionType?: EQuestionType;
}

type ApplicationSectionQuestionValue =
  | string
  | string[]
  | number
  | boolean
  | { fileName: string; fileUri: string }[];

type ApplicationSectionQuestionValueType =
  | 'string'
  | 'date'
  | 'string-array'
  | 'number'
  | 'boolean'
  | 'file-array';

export interface IApplicationSectionQueryParams {
  applicationId: string;
  sectionId: string;
}

export interface IApplicationDetailsFileParams {
  applicationId: string;
  fileId: string;
  fileName: string;
}