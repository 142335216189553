import { ComponentProps, ReactNode } from 'react';
import { Alert } from '@mui/material';

interface IErrorAlertProps {
  msg: ReactNode;
  action?: ComponentProps<typeof Alert>['action'];
  sx?: ComponentProps<typeof Alert>['sx'];
}

export const ErrorAlert = ({ msg, action, sx }: IErrorAlertProps) => {
  return (
    <Alert role="alert" severity="error" action={action} sx={sx}>
      {msg}
    </Alert>
  );
};
