import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@mui/material';
import { FormSkeleton } from 'common/components/skeletons';
import { usePaymentRepresentativeLogic } from 'pages/applicationPage/content/payments/utils/paymentUtils';

type RecommendationCheckListItem = {
  text: string;
  show: boolean;
};

const useRecommendationChecklist = () => {
  const {
    isLoading,
    isSuccess,
    isRepresentativeAndPaymentGoingToRepresentative,
  } = usePaymentRepresentativeLogic();

  const recommendationCheckList: RecommendationCheckListItem[] = [
    { text: 'Grant funding agreement is acceptable', show: true },
    { text: 'Counter grant funding agreement is acceptable', show: true },
    {
      text: 'Deed of trust has been signed',
      show: !isRepresentativeAndPaymentGoingToRepresentative,
    },
  ].filter(item => item.show);

  return {
    isLoading,
    isSuccess,
    recommendationCheckList,
  };
};

export const FundingSection = () => {
  const { isLoading, isSuccess, recommendationCheckList } =
    useRecommendationChecklist();
  return (
    <Box>
      <Grid container flexDirection={'column'} rowGap={1}>
        <Box>
          {isLoading ? <FormSkeleton /> : null}
          {isSuccess
            ? recommendationCheckList.map(listItem => {
                return (
                  <Box
                    key={listItem.text}
                    display="flex"
                    gap={4}
                    sx={{
                      borderRadius: 1,
                      bgcolor: 'grey.100',
                      borderColor: 'grey.600',
                      p: 2,
                      mt: '5px',
                      mb: 1,
                      width: '100%',
                      transition: 'all 250ms linear',
                      ':hover': {
                        bgcolor: 'grey.50',
                        borderColor: 'grey.600',
                      },
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <FontAwesomeIcon
                        icon={solid('check-circle')}
                        color={'grey.600'}
                        size="lg"
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography fontWeight={600} fontSize={'0.8em'}>
                        {listItem.text}
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            : null}
        </Box>
      </Grid>
    </Box>
  );
};
