import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useGetPaymentRecommendationApplicationDetailsQuery } from 'api/application/paymentApi';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const PaymentDocumentsSection = () => {
  return (
    <RoundBorderBox sx={{ p: 2, mt: 2 }}>
      <Box mb={2}>
        <Typography variant="h3" fontWeight={900} fontSize={'1.05em'}>
          Payment Uploads
        </Typography>
      </Box>
      <PaymentDocumentsSectionContent />
    </RoundBorderBox>
  );
};

const PaymentDocumentsSectionContent = () => {
  const { applicationId } = useApplicationContext();
  const { data } =
    useGetPaymentRecommendationApplicationDetailsQuery(applicationId);

  return (
    <Grid container flexDirection={'column'} rowGap={2}>
      <Box>
        {data?.signedGfaFile && (
          <Box
            key={data?.signedGfaFile?.id}
            display="flex"
            gap={2}
            sx={{
              borderRadius: 1,
              border: '1px solid',
              borderColor: grey[200],
              p: 2,
              mt: '5px',
              mb: 1,
              width: '100%',
              transition: 'all 250ms linear',
              ':hover': {
                bgcolor: 'grey.50',
                borderColor: grey[600],
                cursor: 'pointer',
              },
            }}
          >
            <>
              <Box display="flex" alignItems="center">
                <FontAwesomeIcon
                  icon={solid('file-download')}
                  color={grey[600]}
                  size="lg"
                />
              </Box>
              <Box display="flex" alignItems="center">
                <Typography fontWeight={600} fontSize={'0.7em'}>
                  {data?.signedGfaFile?.name}
                </Typography>
              </Box>
            </>
          </Box>
        )}

        {data?.counterSignedGfaFile && (
          <Box
            key={data?.counterSignedGfaFile?.id}
            display="flex"
            gap={2}
            sx={{
              borderRadius: 1,
              border: '1px solid',
              borderColor: grey[200],
              p: 2,
              mt: '5px',
              mb: 1,
              width: '100%',
              transition: 'all 250ms linear',
              ':hover': {
                bgcolor: 'grey.50',
                borderColor: grey[600],
                cursor: 'pointer',
              },
            }}
          >
            <>
              <Box display="flex" alignItems="center">
                <FontAwesomeIcon
                  icon={solid('file-download')}
                  color={grey[600]}
                  size="lg"
                />
              </Box>
              <Box display="flex" alignItems="center">
                <Typography fontWeight={600} fontSize={'0.7em'}>
                  {data?.counterSignedGfaFile?.name}
                </Typography>
              </Box>
            </>
          </Box>
        )}
      </Box>
    </Grid>
  );
};
