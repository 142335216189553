import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useGetAssessorListQuery } from 'api/administration/assessorPanelList';
import { useEditFraewSurveyCompanyMutation } from 'api/application/applicationEditApi';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IAssessorListDto } from 'types/administration/AdministrationAssessorPanelListTypes';
import { zFraewSurveyCompanySchema } from 'types/applications/ApplicationEdit.zod';
import { IFraewSurveyCompany } from 'types/applications/ApplicationEditTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IFraewSurveyCompany) =>
  nameof<IFraewSurveyCompany>(fieldName);

interface IEditFraewSurveyCompanyDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
  currentValue: string;
}

export function EditFraewSurveyCompanyDrawer({
  onClose,
  onSuccess,
  currentValue,
}: IEditFraewSurveyCompanyDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">Edit FRAEW Survey Company</Typography>
          <Typography variant="h3">{currentValue}</Typography>
        </DialogTitle>
        <EditFraewSurveyCompanyForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditFraewSurveyCompanyFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditFraewSurveyCompanyForm = ({
  onClose,
  onSuccess,
}: IEditFraewSurveyCompanyFormProps) => {
  const { applicationId } = useApplicationContext();

  const { data: holdReasonsData, isLoading: holdReasonsLoading } =
    useGetAssessorListQuery();

  const [editFraewSurveyCompany, editFraewSurveyCompanyResult] =
    useEditFraewSurveyCompanyMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IFraewSurveyCompany>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zFraewSurveyCompanySchema),
  });
  const { handleSubmit, reset, control } = form;

  const onSubmit = async (formData: IFraewSurveyCompany) => {
    try {
      await editFraewSurveyCompany({
        applicationId,
        surveyCompanyId: (formData.surveyCompany as IAssessorListDto).id,
        changeReason: formData.changeReason,
    })
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`FRAEW survey company updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit FRAEW survey company`);
    }
  };

  return holdReasonsLoading ? (
    <Loading isOpen />
  ) : (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="surveyCompany"
                rules={{ required: 'Required' }}
                defaultValue={undefined}
                render={({ field: { onChange, ...fieldRest }, fieldState }) => {
                  return (
                    <Autocomplete
                      options={holdReasonsData ?? []}
                      getOptionLabel={option => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      onChange={(_, value) => {
                        onChange(value);
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          {...fieldRest}
                          label="FRAEW survey company required"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={getName('changeReason')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="Comments"
                    helperText={fieldState.error?.message}
                    multiline
                    autoComplete="off"
                    inputProps={{ maxLength: 1000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editFraewSurveyCompanyResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
