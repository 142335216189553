import { administrationApi } from 'api/administration';
import { IPermission } from 'types/administration/AdministrationPermissionsTypes';

export const permissionsEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getPermissions: builder.query<Array<IPermission>, void>({
      query: () => `/permissions`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetPermissionsQuery, useLazyGetPermissionsQuery } = permissionsEndpoints;