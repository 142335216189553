import { IFile } from 'common/types/File';

export enum EReferralMenuType {
  ViewReferral,
  EditReferral,
  CloseReferral,
}

export interface IThirdPartyReferrer {
  id: number;
  name: string;
  type: EThirdPartyReferrerType;
  email: string;
  phone: string;
}

export enum EThirdPartyReferrerType {
  Legal = 1,
  CostConsultant = 2,
}

export const EThirdPartyReferrerTypeLanguage: {
  [key in EThirdPartyReferrerType]: string;
} = {
  [EThirdPartyReferrerType.CostConsultant]: 'Cost Consultant',
  [EThirdPartyReferrerType.Legal]: 'Legal',
} as const;

export interface IReferToThirdPartyForm {
  thirdPartyReferrerId: string | number;
  startDate: string;
  endDate: string;
  cost: string | number;
  description?: string;
}

export interface IThirdPartyReferral {
  id: string;
  status: EReferralStatus;
  startDate: string;
  endDate: string;
  cost: number;
  description: string;
  response?: string;
  responseComments?: string;
  responseFile?: IFile;
  responseUser?: string;
  dateCreated: string;
  dateResponded?: string;
  companyName: string;
  companyEmail: string;
  companyPhone: string;
  thirdPartyType: EThirdPartyReferrerType;
  createdByUser: string;
}

export enum EReferralType {
  HomesEngland = 1,
  ThirdParty = 2,
}

export enum EReferralStatus {
  InProgress = 1,
  Overdue = 2,
  Complete = 3,
}

export const EReferralStatusLanguage: {
  [key in EReferralStatus]: string;
} = {
  [EReferralStatus.InProgress]: 'In Progress',
  [EReferralStatus.Overdue]: 'Overdue',
  [EReferralStatus.Complete]: 'Complete',
} as const;

export interface ICloseThirdPartyReferralForm {
  response: string;
  responseComments: string;
  responseFileId: string;
}

export interface ICloseHomesEnglandReferralForm {
  reason: string;
}

export interface IReferralListItem {
  id: string;
  type: EReferralType;
  thirdPartyReferrerType?: EThirdPartyReferrerType;
  companyName?: string;
  startDate?: string;
  endDate?: string;
  cost?: number;
  status: EReferralStatus;
}

export interface IGetThirdPartyReferralParams {
  applicationId: string;
  thirdPartyReferralId: string;
}

export enum EReferralRecordType {
  WorksPackage,
  Variation,
  PaymentRequest,
}

export type GetThirdPartyReferralParams =
  | ({
      type: EReferralRecordType.WorksPackage;
    } & IGetThirdPartyReferralParams)
  | ({
      type: EReferralRecordType.PaymentRequest;
      paymentRequestId: string;
    } & IGetThirdPartyReferralParams)
  | ({
      type: EReferralRecordType.Variation;
      variationId: string;
    } & IGetThirdPartyReferralParams);

type UpdateThirdPartyReferralMutationParamsAdditional = {
  thirdPartyReferralId: string;
  applicationId: string;
  formData: IReferToThirdPartyForm;
};

export type UpdateThirdPartyReferralMutationParams =
  | ({
      type: EReferralRecordType.WorksPackage;
    } & UpdateThirdPartyReferralMutationParamsAdditional)
  | ({
      type: EReferralRecordType.PaymentRequest;
      paymentRequestId: string;
    } & UpdateThirdPartyReferralMutationParamsAdditional)
  | ({
      type: EReferralRecordType.Variation;
      variationId: string;
    } & UpdateThirdPartyReferralMutationParamsAdditional);

export interface ICloseThirdPartyReferralMutationParams {
  applicationId: string;
  thirdPartyReferralId: string;
  formData: ICloseThirdPartyReferralForm;
}

export type CloseThirdPartyReferralMutationParams =
  | ({
      type: EReferralRecordType.WorksPackage;
    } & ICloseThirdPartyReferralMutationParams)
  | ({
      type: EReferralRecordType.PaymentRequest;
      paymentRequestId: string;
    } & ICloseThirdPartyReferralMutationParams)
  | ({
      type: EReferralRecordType.Variation;
      variationId: string;
    } & ICloseThirdPartyReferralMutationParams);

export interface ICloseHomesEngladReferralMutationParams {
  applicationId: string;
  referralId: string;
  formData: ICloseHomesEnglandReferralForm;
}

export type CloseHomesEnglandReferralMutationParams =
  | ({
      type: EReferralRecordType.WorksPackage;
    } & ICloseHomesEngladReferralMutationParams)
  | ({
      type: EReferralRecordType.PaymentRequest;
      paymentRequestId: string;
    } & ICloseHomesEngladReferralMutationParams)
  | ({
      type: EReferralRecordType.Variation;
      variationId: string;
    } & ICloseHomesEngladReferralMutationParams);

export interface IReferToThirdPartyMutationParams {
  applicationId: string;

  formData: IReferToThirdPartyForm;
}

export type ReferToThirdPartyMutationParams =
  | ({
      type: EReferralRecordType.WorksPackage;
    } & IReferToThirdPartyMutationParams)
  | ({
      type: EReferralRecordType.PaymentRequest;
      paymentRequestId: string;
    } & IReferToThirdPartyMutationParams)
  | ({
      type: EReferralRecordType.Variation;
      variationId: string;
    } & IReferToThirdPartyMutationParams);

interface IGetReferralsQueryParams {
  applicationId: string;
}

export type GetReferralsQueryParams =
  | ({
      type: EReferralRecordType.WorksPackage;
    } & IGetReferralsQueryParams)
  | ({
      type: EReferralRecordType.PaymentRequest;
      paymentRequestId?: string;
    } & IGetReferralsQueryParams)
  | ({
      type: EReferralRecordType.Variation;
      variationId: string;
    } & IGetReferralsQueryParams);
