import { useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCloseHomesEnglandReferralGeneralMutation } from 'api/application/referralApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { useLocalSnackbar, useModalState } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import {
  EReferralRecordType,
  ICloseHomesEnglandReferralForm,
} from 'types/applications/ReferralTypes';

const _formId = 'close-homes-england-referral-form';

type CloseHomesEnglandReferralDrawerConfig = (
  | {
      referralRecordType: EReferralRecordType.WorksPackage;
      referralId: string;
    }
  | {
      referralRecordType: EReferralRecordType.PaymentRequest;
      referralId: string;
      paymentId: string;
    }
  | {
      referralRecordType: EReferralRecordType.Variation;
      referralId: string;
      variationId: string;
    }
) & {
  onSuccess?: () => void;
};

export const useCloseReferralToHomesEnglandDrawer = () => {
  const {
    isShowing: isCloseReferralDrawerShowing,
    showModal: showCloseReferralDrawer,
    hideModal: hideCloseReferralDrawer,
  } = useModalState();

  const [config, setConfig] =
    useState<CloseHomesEnglandReferralDrawerConfig | null>(null);

  const form = useForm<ICloseHomesEnglandReferralForm>({
    defaultValues: {
      reason: '',
    },
  });
  const { handleSubmit, control, reset } = form;

  const { createErrorSnackbar, createSuccessSnackbar } = useLocalSnackbar();

  const handleShowCloseReferralDrawer = (
    config: CloseHomesEnglandReferralDrawerConfig
  ) => {
    setConfig(config);
    showCloseReferralDrawer();
  };

  const handleHideReferDrawer = () => {
    setConfig(null);
    hideCloseReferralDrawer();
    reset();
  };

  const { applicationId } = useApplicationContext();
  const [closeReferral, closeReferralStatus] =
    useCloseHomesEnglandReferralGeneralMutation();

  const onSubmit = async (formData: ICloseHomesEnglandReferralForm) => {
    if (!config) {
      createErrorSnackbar('Could not get referral. Please try again later.');
      return;
    }

    const getMutationConfig = (): Parameters<typeof closeReferral>[0] => {
      const base = {
        applicationId,
        referralId: config.referralId,
        formData,
      };
      switch (config.referralRecordType) {
        case EReferralRecordType.WorksPackage:
          return {
            ...base,
            type: EReferralRecordType.WorksPackage,
          };
        case EReferralRecordType.PaymentRequest:
          return {
            ...base,
            type: EReferralRecordType.PaymentRequest,
            paymentRequestId: config.paymentId,
          };

        case EReferralRecordType.Variation:
          return {
            ...base,
            type: EReferralRecordType.Variation,
            variationId: config.variationId,
          };
      }
    };

    closeReferral(getMutationConfig())
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Referral was closed');
        if (config.onSuccess) {
          config.onSuccess();
        }
        handleHideReferDrawer();
      });
  };

  const renderCloseReferralDrawer = () => {
    if (!config) {
      return null;
    }

    return (
      <>
        <StyledDrawer anchor="right" open={isCloseReferralDrawerShowing}>
          <Box>
            <DialogTitle component="div">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h1" component="span">
                  Close referral
                </Typography>
                <IconButton
                  onClick={() => handleHideReferDrawer()}
                  aria-label="Close Drawer"
                  name="Close Drawer"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <>
                <form id={_formId} onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    control={control}
                    name="reason"
                    rules={{
                      required: 'Required',
                      maxLength: {
                        value: 1000,
                        message: '1000 characters is the maximum allowed.',
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        multiline
                        fullWidth
                        rows={5}
                        label="Reason"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{ mt: 2 }}
                      />
                    )}
                  />
                </form>
              </>

              <AlertForQueryErrorOrNull
                isError={closeReferralStatus.isError}
                error={closeReferralStatus.error}
              />
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={hideCloseReferralDrawer}>
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  form={_formId}
                  loading={closeReferralStatus.isLoading}
                >
                  Save
                </LoadingButton>
              </Grid>
            </StyledDrawerActions>
          </Box>
        </StyledDrawer>
      </>
    );
  };

  return {
    showCloseReferralDrawer: handleShowCloseReferralDrawer,
    renderCloseReferralDrawer,
  };
};
