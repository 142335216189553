import { Chip } from '@mui/material';
import {
  EWorksPackageStatus,
  EWorksPackageStatusLanguage,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';

export const StatusChip = ({
  status,
}: {
  status: undefined | EWorksPackageStatus;
}) => {
  if (!status) {
    return null;
  }
  return (
    <Chip
      size="small"
      variant="filled"
      color={
        status === EWorksPackageStatus.InProgress
          ? 'info'
          : status === EWorksPackageStatus.Approved
          ? 'success'
          : status === EWorksPackageStatus.Rejected
          ? 'warning'
          : 'default'
      }
      label={EWorksPackageStatusLanguage[status] ?? 'Unknown Status'}
      sx={{ fontSize: '0.9em' }}
    />
  );
};
