import { ReactNode } from 'react';
import { SxProps } from '@mui/material';
import { RoundBorderBox } from 'common/components/roundBorderBox';

export const ListViewBoxContainer = ({
  sx,
  children,
  onClick,
}: {
  sx: SxProps;
  children: ReactNode;
  onClick: () => void;
}) => {
  return (
    <RoundBorderBox
      sx={{
        ...sx,
        transition: 'all 150ms linear',
        cursor: 'pointer',
        ':hover': {
          boxShadow: '0 3px 15px rgba(0, 0, 0, 0.15)',
        },
      }}
      onClick={onClick}
    >
      {children}
    </RoundBorderBox>
  );
};
