import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  IReferralType,
  IResolutionReason,
  ISupportRemediationAdviser,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { QueryTags } from 'util/ApiUtils';
import { customPrepareHeaders } from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/support`;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
  credentials: 'include',
});

export const supportApi = createApi({
  reducerPath: 'supportApi',
  baseQuery,
  tagTypes: [QueryTags.ApplicationSupportResolutionReasons],
  endpoints: builder => ({
    getReferralTypes: builder.query<IReferralType[], void>({
      query: () => `referralTypes`,
    }),
    getResolutionReasons: builder.query<IResolutionReason[], void>({
      providesTags: [QueryTags.ApplicationSupportResolutionReasons],
      query: () => `resolutionReasons`,
    }),
    getRemediationAdvisers: builder.query<ISupportRemediationAdviser[], number>({
      query: referralTypeId => `remediationadvisers/${referralTypeId}`,
    }),
  }),
});

export const { useGetReferralTypesQuery, useGetResolutionReasonsQuery, useGetRemediationAdvisersQuery } =
  supportApi;
