import { Box, LinearProgress, TextField, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useApproveOrRejectMutation } from 'api/application/worksPackageApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { useLocalSnackbar, useModalState } from 'hooks';
import { EWorksPackageApprovalStatus } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import { extractErrorMessages } from 'util/ApiUtils';

export const useRejectModal = () => {
  const { isShowing, showModal, hideModal } = useModalState();

  const renderRejectModal = () => {
    return <RejectModal isShowing={isShowing} hideModal={hideModal} />;
  };

  return {
    showRejectModal: showModal,
    hideRejectModal: hideModal,
    renderRejectModal,
  };
};

export const RejectModal = ({
  isShowing,
  hideModal,
}: {
  isShowing: boolean;
  hideModal: () => void;
}) => {
  const { applicationId } = useWorksPackageContext();
  const [reject, status] = useApproveOrRejectMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const form = useForm<{ reason: string }>({
    defaultValues: { reason: '' },
  });

  const handleClick = async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      return;
    }

    try {
      const { reason } = form.getValues();
      await reject({
        applicationId,
        status: EWorksPackageApprovalStatus.Rejected,
        reason,
      })
        .unwrap()
        .then(_ => {
          createSuccessSnackbar('Works package has been successfully rejected');
          hideModal();
          form.reset();
        })
        .catch(error => {
          createErrorSnackbar(extractErrorMessages(error));
        });
    } catch (err) {
      createErrorSnackbar(`Failed to approve`);
    }
  };

  if (!isShowing) {
    return null;
  }

  return (
    <ConfirmationModal
      isShowing={isShowing}
      confirmButtonText="Reject Works Package"
      cancelButtonText="Cancel"
      onConfirm={handleClick}
      onCancel={hideModal}
      dialogActionsSx={{ m: 2 }}
      areButtonsLoading={status.isLoading}
      showActionsDivider
      fullWidth
      maxWidth="md"
    >
      <Box p={3}>
        {status.isLoading ? <LinearProgress /> : null}

        <Typography variant="body1" fontWeight={600} fontSize={'1.2em'}>
          Reject Works Package
        </Typography>

        <Typography variant="body1" py={3} color={'grey.700'}>
          Please ensure you have notified the applicant that this works package
          has been rejected before updating this status in this platform as the
          works package status will be updated on the applicant journey.
        </Typography>

        <FormProvider {...form}>
          <Controller
            control={form.control}
            name={`reason`}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                multiline
                fullWidth
                label="Provide reason"
                error={!!fieldState.error}
                rows={3}
                helperText={fieldState.error?.message}
                sx={{ mb: 0 }}
              />
            )}
          />
        </FormProvider>

        <AlertForQueryErrorOrNull
          isError={status.isError}
          error={status.error}
        />
      </Box>
    </ConfirmationModal>
  );
};
